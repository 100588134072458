/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { bool, func, number, object, string } from 'prop-types';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Space, Tooltip } from 'antd';

import { getActivityLogs } from '../../api/activityLog';
import { columns } from '../../components/configs/tables/activityLog.json';
import { hideLoading, showLoading, showModal } from '../../redux/actions/ui';
import { handleNotifications } from '../../utils/notifications';
import TableComponent from '../../components/ui/TableComponent';
import { accessRules } from '../../utils/accessRules';
import {
  getCurrentPageFromLocation,
  HeaderRenderer,
  objectToFormData
} from '../../utils/utils';
import { dueDiligenceOptions } from '../../utils/constants';
import {
  PrinterOutlined,
  EditOutlined,
  DeleteOutlined,
  MessageOutlined,
  ContainerOutlined
} from '@ant-design/icons';
import {
  resetSearchResults,
  switchAdvancedSearchMode,
  onSearch
} from '../../redux/actions/search';

const List = ({
  refresh,
  onDeleteSuccess,
  role,
  searchParams,
  searchResults,
  onSetRefresh,
  onShowLoading,
  onHideLoading,
  onShowModal
}) => {
  const [smallBusinesses, setSmallBusinesses] = useState([]);
  const [currentFilters, setCurrentFilters] = useState([]);
  const [currentTableProps, setCurrentTableProps] = useState({
    pagination: { current: 1, size: 10, total: 0 }
  });
  const innerWidth = window.innerWidth;

  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (refresh) {
      setFiltersFromSearchParams(searchParams);
      fetchSmallBusinesses({ currentTableProps, filters: [...currentFilters] });
    }
  }, [refresh]);

  useDeepCompareEffect(() => {
    setFiltersFromSearchParams(searchParams);
  }, [searchParams]);

  useDeepCompareEffect(() => {
    fetchSmallBusinesses({
      currentTableProps,
      filters: [...currentFilters]
    });
  }, [
    currentFilters,
    currentTableProps.pagination.current,
    currentTableProps.pagination.size
  ]);

  useEffect(
    () => () => {
      setCurrentFilters([]);
      // onSetRefresh(false);
    },
    []
  );

  const setFiltersFromSearchParams = searchParams => {
    const searchFilters = searchParams?.fields
      ? searchParams.fields.map(field => ({
          [field]: searchParams['keyword']
        }))
      : [];
    setCurrentFilters(searchFilters);
  };


  const filterData = arr => {
    arr = arr.map(item => ({
      ...item,
      key: item['id'],
      naics_codes: item['naics_codes']?.length
        ? item['naics_codes'].map(subItem => subItem['codes']).join(', ')
        : '-',
      county: item['address']?.length
        ? item['address'].map(subItem => subItem['county']).join(', ')
        : '-',
      state: item['address']?.length
        ? item['address'].map(subItem => subItem['state']).join(', ')
        : '-',
      due_diligence: item['due_diligence']
        ? dueDiligenceOptions[item['due_diligence']]
        : '-'
    }));
    return arr;
  };

  /* Render Actions Column */
  const renderActions = (text, record) => {
    return <Space size="small"></Space>;
  };

  const tableColumns = HeaderRenderer(columns, innerWidth);

  // tableColumns.push({
  //   title: 'Action',
  //   key: 'action',
  //   displayName: 'action',
  //   render: renderActions
  // });

  const fetchSmallBusinesses = ({ currentTableProps, filters }) => {
    const query = {};
    if (currentTableProps?.pagination) {
      query['page'] = filters.length ? 1 : currentTableProps.pagination.current;
      query['limit'] = currentTableProps.pagination.size;
    }
    if (filters) {
      query['filters'] = filters;
    }

    onShowLoading();
    // onSetRefresh(false);

    getActivityLogs(query)
      .then(({ data }) => {
        if (data.success) {
          let arr = filterData(data.data);
          setSmallBusinesses(arr);
          setCurrentTableProps({
            ...currentTableProps,
            pagination: {
              ...currentTableProps.pagination,
              total: data?.pagination.total
            }
          });
          onHideLoading();
        } else {
          setSmallBusinesses([]);
          onHideLoading();
          handleNotifications('error', 'Failed', data.message);
        }
      })
      .catch(error => {
        console.log(error);
        setSmallBusinesses([]);
        onHideLoading();
        handleNotifications('error', 'Failed', error.message);
      });
  };

  const manageDataSource = ({ current, pageSize }, filters) => {
    let tableProps = { ...currentTableProps };
    if (current) {
      tableProps = {
        ...tableProps,
        pagination: { ...tableProps.pagination, current }
      };
    }
    if (pageSize) {
      tableProps = {
        ...tableProps,
        pagination: { ...tableProps.pagination, size: pageSize }
      };
    }
    setCurrentTableProps({ ...tableProps });
  };

  const onClickRow = record => {
    return {
      onClick: () => {
        if (
          accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'view'
          ]
        ) {
          onShowModal();
          history.push(`${match.path}/view/${record.id}`);
        }
      }
    };
  };

  return (
    <>
      <TableComponent
        className="table-hoverable"
        columns={tableColumns}
        tableData={smallBusinesses}
        onRow={onClickRow}
        pagination={{
          position: ['bottomCenter'],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          hideOnSinglePage: true,
          ...currentTableProps.pagination
        }}
        manageDataSource={manageDataSource}
      />
    </>
  );
};

const mapStateToProps = state => ({
  refresh: state.smallBusiness.refresh,
  onDeleteSuccess: state.smallBusiness.deleteSuccess,
  role: state.auth.role,
  businessId: state.auth?.user?.businessId,
  searchParams: state.search.smallBusiness,
  searchResults: state.search.searchResults
});

const mapDispatchToProps = dispatch => ({
  onShowModal: () => dispatch(showModal()),
  onHideLoading: () => dispatch(hideLoading()),
  onShowLoading: () => dispatch(showLoading())
});

List.propTypes = {
  refresh: bool,
  onDeleteSuccess: bool,
  role: string,
  businessId: number,
  searchParams: object,
  // onSetRefresh: func,
  onShowModal: func,
  onShowLoading: func,
  onHideLoading: func
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
