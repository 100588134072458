import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Button, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportData = ({ exportData = [], onExportButtonClick }) => {
  const [finish, setFinish] = useState(false);
  const [exportType, setExportType] = useState(null); // Track the export type
  const csvComponent = useRef();
  const excelComponent = useRef();

  const buildIndustrySpecializationData = item => {
    if (item && item.length) {
      return item.reduce((prev, curr) => {
        if (typeof curr === 'object' && !Array.isArray(curr)) {
          prev = `${prev}, ${curr.props.children}`;
        }
        return prev;
      }, '');
    }
    return '';
  };

  const buildData = useMemo(() => {
    return exportData.reduce((prev, curr) => {
      prev = [
        ...prev,
        {
          'Company Name': curr.company_name,
          'NAICS Code': curr.naics_codes,
          State: curr.state,
          'Verification Status': curr.verification_step_id,
          'Industry Specialization': buildIndustrySpecializationData(
            curr.industry_specialization
          ),
          'Account Number': curr.id
        }
      ];

      return prev;
    }, []);
  }, [exportData]);

  useEffect(() => {
    if (exportData && exportData.length > 0) {
      setFinish(true);
    }
  }, [exportData]);

  useEffect(() => {
    if (finish && exportType === 'csv') {
      csvComponent.current.link.click(); // Trigger CSV download
    } else if (finish && exportType === 'excel') {
      excelComponent.current.handleDownload(); // Trigger Excel download
    }
  }, [finish, exportType]);

  // Function to generate the current date in the format YYYY-MM-DD
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        {finish ? (
          <CSVLink
            data={buildData}
            filename={`small-business-${getCurrentDate()}.csv`} // Append the current date to the filename
            ref={csvComponent}
          >
            Export CSV
          </CSVLink>
        ) : (
          <span
            onClick={() => {
              onExportButtonClick();
              setExportType('csv');
            }}
          >
            Export CSV
          </span>
        )}
      </Menu.Item>

      <Menu.Item key="2">
        {finish ? (
          <ExcelFile
            ref={excelComponent}
            element={<span>Export Excel</span>}
            filename={`small-business-excel-${getCurrentDate()}`}
          >
            <ExcelSheet data={buildData} name="Small Business">
              <ExcelColumn label="Company Name" value="Company Name" />
              <ExcelColumn label="NAICS Code" value="NAICS Code" />
              <ExcelColumn label="State" value="State" />
              <ExcelColumn
                label="Verification Status"
                value="Verification Status"
              />
              <ExcelColumn
                label="Industry Specialization"
                value="Industry Specialization"
              />
              <ExcelColumn label="Account Number" value="Account Number" />
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <span
            onClick={() => {
              onExportButtonClick();
              setExportType('excel');
            }}
          >
            Export Excel
          </span>
        )}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button type="primary" size="small">
        <span>Export </span>
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default ExportData;
