import {
  SMALL_BUSINESS_REFRESH,
  DELETE_SMALL_BUSINESS_SUCCESS,
  IMPORT_SMALL_BUSINESS_SUCCESS,
  SET_USER_INFO
} from '../actionTypes';
import {
  addSmallBusiness,
  deleteSmallBusiness,
  updateSmallBusiness,
  verifyCertificates,
  importSmallBusinesses
} from '../../api/smallBusiness';
import { credentialManager, findUserByEmail } from '../../api/adminUpdates';
import { hideLoading, hideModal, showLoading } from './ui';
import { handleNotifications } from '../../utils/notifications';
import { redirect } from './redirect';
import { updateAllowed } from './auth';
import {
  getFromLocalStorage,
  setToLocalStorage
} from '../../utils/localStorage';

export const setSmallBusinessRefresh = payload => ({
  type: SMALL_BUSINESS_REFRESH,
  payload
});

export const deleteSmallBusinessSuccess = () => ({
  type: DELETE_SMALL_BUSINESS_SUCCESS
});

export const successImport = () => ({
  type: IMPORT_SMALL_BUSINESS_SUCCESS
});

export const updateUserinfo = payload => ({
  type: SET_USER_INFO,
  payload
});

export const onAddSmallBusiness = payload => dispatch => {
  dispatch(showLoading());
  addSmallBusiness(payload)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data.success) {
        handleNotifications('success', 'Success', data.message);
        dispatch(hideModal());
        dispatch(redirect('/small-business'));
        dispatch(setSmallBusinessRefresh(true));
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onUpdateSmallBusiness =
  (smallBusinessId, payload, role = null) =>
  dispatch => {
    dispatch(showLoading());
    let allowed = getFromLocalStorage('allowed');
    updateSmallBusiness(smallBusinessId, payload)
      .then(({ data }) => {
        dispatch(hideLoading());
        if (data.success) {
          dispatch(hideModal());
          if (!allowed) {
            setToLocalStorage('allowed', { allowed: true });
            dispatch(updateAllowed(true));
          }
          handleNotifications('success', 'Success', data.message);
          dispatch(setSmallBusinessRefresh(true));
          role === 'admin'
            ? dispatch(redirect('/small-business'))
            : dispatch(redirect('/dashboard'));
        } else {
          handleNotifications('error', 'Failed', data.message);
        }
      })
      .catch(error => {
        dispatch(hideLoading());
        handleNotifications('error', 'Failed', error.message);
        console.log(error);
      });
  };

export const verifyCertificatesAction =
  (smallBusinessId, payload) => dispatch => {
    dispatch(showLoading());
    verifyCertificates(smallBusinessId, payload)
      .then(({ data }) => {
        dispatch(hideLoading());
        if (data.success) {
          handleNotifications('success', 'Success', data.message);
        } else {
          handleNotifications('error', 'Failed', data.message);
        }
      })
      .catch(error => {
        dispatch(hideLoading());
        handleNotifications('error', 'Failed', error.message);
        console.log(error);
      });
  };

export const onDeleteSmallBusiness = smallBusinessId => dispatch => {
  dispatch(showLoading());
  deleteSmallBusiness(smallBusinessId)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data.success) {
        dispatch(hideModal());
        handleNotifications('success', 'Success', data.message);
        dispatch(redirect('/small-business'));
        dispatch(setSmallBusinessRefresh(true));
        dispatch(deleteSmallBusinessSuccess());
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onImportSmallBusinesses = importPayload => dispatch => {
  dispatch(showLoading());
  importSmallBusinesses(importPayload)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data.success) {
        handleNotifications('success', 'Success', data.message);
        dispatch(hideModal());
        dispatch(redirect('/small-business'));
        dispatch(setSmallBusinessRefresh(true));
        dispatch(successImport());
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const onUpdateUserCredentials = payload => dispatch => {
  dispatch(showLoading());
  credentialManager(payload)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data.success) {
        handleNotifications('success', 'Success', data.message);
        dispatch(hideModal());
        // dispatch(redirect('/site-settings'));
        dispatch(setSmallBusinessRefresh(true));
        dispatch(updateUserinfo(null));
        // dispatch(successImport());
      } else {
        handleNotifications('error', 'Failed', data.message);
        dispatch(updateUserinfo(null));
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};

export const findUser = email => dispatch => {
  dispatch(showLoading());
  findUserByEmail(email)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data.success) {
        handleNotifications('success', 'Success', data.message);
        dispatch(updateUserinfo(data.data));
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      handleNotifications('error', 'Failed', error.message);
    });
};
