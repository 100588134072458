import {
  createActivityLog,
  updateActivityLog,
  deleteActivityLog
} from '../../api/activityLog';
import { SET_ACTIVITY_LOGS, ADD_ACTIVITY_LOG } from '../actionTypes';
import { hideLoading, showLoading } from './ui';

export const setActivityLogs = payload => dispatch => {
  dispatch({ type: SET_ACTIVITY_LOGS, payload });
};

export const onAddActivityLog = payload => dispatch => {
  dispatch(showLoading());
  createActivityLog(payload)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data.success) {
        console.log('success', data.message);
        dispatch({ type: ADD_ACTIVITY_LOG, data });
      } else {
        console.log('success', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading());
      console.log('error', error.message);
    });
};
