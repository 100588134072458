import React from 'react';
import { array, func, number, object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { StarOutlined, StarFilled } from '@ant-design/icons';
import { objectToFormData } from '../../utils/utils';
import {
    onUpdateSmallBusiness,
  } from '../../redux/actions/smallBusiness';

const Favourite = ({
    item,
    index,
    arr,
    setSmallBusinesses,
    filterData
}) => {
    const dispatch = useDispatch();
    const role = useSelector(({ auth }) => auth.role);

    const handleFavouriteClick = (e) => {
        e.stopPropagation();
        const itemCopy = {
          ...item,
          favourite: !item.favourite,
        };

        const updatedArr = filterData([...arr.slice(0, index), itemCopy, ...arr.slice(index+1)])
    
        setSmallBusinesses(updatedArr);

        try {
            dispatch(onUpdateSmallBusiness(itemCopy.id, objectToFormData(itemCopy), role))
        } catch(e) {
            console.error(e);
        }
    }

    if (item.favourite) {
        return (
            <StarFilled style={{ color: '#eb2f96', fontSize: '20px' }} onClick={handleFavouriteClick} />
        );
    }

    return (
        <StarOutlined style={{ fontSize: '20px' }} onClick={handleFavouriteClick} />
    );
};

Favourite.prototype = {
    item: object,
    index: number,
    arr: array,
    setSmallBusinesses: func,
    filterData: func
}

export default Favourite;
