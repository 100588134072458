import React from 'react';
import { Tabs } from 'antd';
import SiteLogo from './SiteLogo';
import VerificationSteps from './VerificationSteps';
import CredentialsManager from './CredentialsManager'
import Stats from './Stats'
import ModalComponent from '../../components/ui/ModalComponent';
import { Route, Switch } from 'react-router-dom';
import Create from './Create';
// import CreateVideo from './videos/create';
import Update from './Update';
import View from './View';

const { TabPane } = Tabs;

const SettingsContainer = ({ match }) => {
  return (
    <div style={{ padding: 10 }}>
      <ModalComponent size="sm">
        <Switch>
          <Route exact path={`${match.path}/create`} component={Create} />
          {/* <Route
            exact
            path={`${match.path}/video/create`}
            component={CreateVideo}
          />
          <Route
            exact
            path={`${match.path}/video/edit/:id`}
            component={CreateVideo}
          />
          <Route path={`${match.path}/edit/:agreementId`} component={Update} /> */}
          <Route path={`${match.path}/preview/:stepId`} component={View} />
          <Route path={`${match.path}/edit/:stepId`} component={Update} />
        </Switch>
      </ModalComponent>
      <Tabs defaultActiveKey="1">
        <TabPane tab={<span>Site Logo</span>} key="1">
          <SiteLogo />
        </TabPane>
        <TabPane tab={<span>Verification Steps</span>} key="2">
          <VerificationSteps />
        </TabPane>
        <TabPane tab={<span>Credentials Manager</span>} key="3">
          <CredentialsManager />
        </TabPane>
        <TabPane tab={<span>Stats</span>} key="4">
          <Stats />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default SettingsContainer;
