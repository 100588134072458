import React from 'react';
import { any, bool, func, object } from 'prop-types';
import { debounce } from 'lodash';
import { Input, InputNumber } from 'antd';

import ImageUpload from './FormInputs/ImageUpload';
import Address from './FormInputs/Address';
import Dropdowns from './FormInputs/Dropdowns';
import FileUpload from './FormInputs/FileUpload';
import DynamicForm from './FormInputs/DynamicForm';
import NestedMultiSelect from './FormInputs/NestedMultiSelect';
import Checkboxes from './FormInputs/Checkboxes';
import DateInput from './FormInputs/DateInput';
import TimeInput from './FormInputs/TimeInput';
import RadioComponent from './FormInputs/Radio';

const { TextArea } = Input;

const FormElement = ({
  config,
  editMode,
  valueProps,
  microApiHandler,
  parentFormHandler
}) => {
  const debouncedInput = debounce((e, key) => {
    parentFormHandler(key, {
      [key]: e && e.target ? e.target.value : e
    });
  }, 500);

  let formElement = null;

  switch (config.type) {
    case 'text':
      formElement = (
        <Input
          name={config.key}
          placeholder={config.label}
          type="text"
          defaultValue={editMode ? valueProps : ''}
          onChange={e => {
            e.persist();
            debouncedInput(e, config.key);
          }}
        />
      );
      break;
    case 'text-box':
      formElement = (
        <TextArea
          name={config.key}
          placeholder={config.label}
          rows={5}
          maxLength={config.max}
          defaultValue={editMode ? valueProps : ''}
          onChange={e => {
            e.persist();
            debouncedInput(e, config.key);
          }}
        />
      );
      break;
    case 'number':
      formElement = (
        <Input
          name={config.key}
          placeholder={config.label}
          type="number"
          defaultValue={editMode ? valueProps : ''}
          onChange={e => {
            e.persist();
            debouncedInput(e, config.key);
          }}
        />
      );
      break;
    case 'amount':
      formElement = (
        <InputNumber
          name={config.key}
          placeholder={config.label}
          defaultValue={editMode ? valueProps : ''}
          style={{ width: '100%' }}
          formatter={value =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
          onChange={value => {
            debouncedInput(value, config.key);
          }}
        />
      );
      break;
    case 'phone':
      formElement = (
        <Input
          name={config.key}
          placeholder={`${config.label} (10-digit)`}
          type="tel"
          maxLength={10}
          defaultValue={editMode ? valueProps : ''}
          onChange={e => {
            e.persist();
            debouncedInput(e, config.key);
          }}
        />
      );
      break;
    case 'email':
      formElement = (
        <Input
          name={config.key}
          placeholder={config.label}
          type="email"
          defaultValue={editMode ? valueProps : ''}
          onChange={e => {
            e.preventDefault();
            parentFormHandler(config.key, { [config['key']]: e.target.value });
          }}
        />
      );
      break;
    case 'url':
      formElement = (
        <Input
          name={config.key}
          placeholder={config.label}
          type="url"
          defaultValue={editMode ? valueProps : ''}
          onChange={e => {
            parentFormHandler(config.key, { [config['key']]: e.target.value });
          }}
        />
      );
      break;
    case 'date':
      formElement = (
        <DateInput
          name={config.key}
          keyName={config.key}
          mode={config.mode}
          limit={config.limit}
          valueProps={editMode ? valueProps : ''}
          parentHandler={parentFormHandler}
          picker={config.picker}
          showTime={config.showTime}
        />
      );
      break;
    case 'time':
      formElement = (
        <TimeInput
          name={config.key}
          timeMode={config.mode}
          keyName={config.key}
          valueProps={editMode ? valueProps : ''}
          nestedHandler={parentFormHandler}
        />
      );
      break;
    case 'image':
      formElement = (
        <ImageUpload
          name={config.key}
          placeholder={config.label}
          keyName={config.key}
          max={config.max}
          microApiHandler={microApiHandler}
          nestedHandler={parentFormHandler}
        />
      );
      break;
    case 'file':
      formElement = (
        <FileUpload
          keyName={config.key}
          parentHandler={parentFormHandler}
          label={config.label ? config.label : ''}
        />
      );
      break;
    case 'address':
      formElement = (
        <Address
          keyName={config.key}
          microApiHandler={config.useMicroApi ? microApiHandler : null}
          valueProps={editMode ? valueProps : null}
          parentHandler={parentFormHandler}
          hideStreet={config.hideStreet}
        />
      );
      break;
    case 'dropdown':
      formElement = (
        <Dropdowns
          keyName={config.key}
          label={config.label}
          multiSelect={false}
          valueProps={editMode ? valueProps : ''}
          onChange={(value) => parentFormHandler(config.key,value)}
          useKey={config.useKey || false}
        />
      );
      break;
    case 'checkbox':
      formElement = (
        <Checkboxes
          keyName={config.key}
          parentHandler={parentFormHandler}
          valueProps={editMode ? valueProps : ''}
        />
      );
      break;
    case 'dropdownMulti':
      formElement = (
        <Dropdowns
          keyName={config.key}
          label={config.label}
          multiSelect={true}
          valueProps={editMode ? valueProps : ''}
          onChange={(value) => parentFormHandler(config.key,value)}
        />
      );
      break;
    case 'tree':
      formElement = (
        <NestedMultiSelect
          keyName={config.key}
          label={config.label}
          nestedHandler={parentFormHandler}
        />
      );
      break;
    case 'dynamicForm':
      formElement = (
        <DynamicForm
          keyName={config.key}
          label={config.label}
          min={config.min}
          max={config.max}
          proof={config.proof ? config.proof : false}
          microApiHandler={microApiHandler}
          nestedHandler={parentFormHandler}
        />
      );
      break;
    case 'radio':
      formElement = (
        <RadioComponent
          keyName={config.key}
          parentHandler={parentFormHandler}
          valueProps={editMode ? valueProps : ''}
        />
      );
      break;
    default:
      formElement = (
        <Input
          name={config.key}
          placeholder={config.label}
          type="text"
          defaultValue={editMode ? valueProps : ''}
          onChange={e => {
            e.persist();
            debouncedInput(e, config.key);
          }}
        />
      );
      break;
  }

  return formElement;
};

FormElement.propTypes = {
  config: object,
  editMode: bool,
  valueProps: any,
  microApiHandler: object,
  parentFormHandler: func
};

export default FormElement;
