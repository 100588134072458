import dayjs from 'dayjs';
import { basePermission } from '../../utils/constants';
import { setToLocalStorage } from '../../utils/localStorage';
import * as actionTypes from '../actionTypes';

const initialState = {
  loggedIn: false,
  isValidPayment: null,
  resetPassword: false,
  updatePassword: false,
  permissions: [],
  features: [],
  user: null,
  role: null,
  auth_token: null,
  fromRegister: false,
  registerData: {},
  updateAllowed: false
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REGISTER_SUCCESS:
      return { ...state, fromRegister: true, registerData: action.payload };
    case actionTypes.LOGIN_SUCCESS:
      const {
        user_id: userId,
        id,
        company_admin,
        account_expiry_date: accExpDate,
        email,
        name,
        role,
        free_user,
        auth_token: authToken,
        token_object: tokenObject,
        business_id: businessId,
        package_features
      } = action.payload;
      package_features && calculatePermission(state.features, package_features, free_user);
      return {
        ...state,
        loggedIn: true,
        isValidPayment: validatePayment(accExpDate),
        user: { user_id: userId, id, email, name, businessId: businessId },
        company_admin,
        role,
        free_user,
        auth_token: { ...tokenObject, token: authToken },
      };
    case actionTypes.LOGIN_FAIL:
      return { ...state, error: action.payload };
    case actionTypes.LOGOUT:
      return {
        ...state,
        loggedIn: false,
        user: null,
        role: null,
        auth_token: null
      };
    case actionTypes.SET_FEATURES: 
      return {
        ...state,
        features: action.payload.data
      }
    case actionTypes.PAYMENT_SUCCESSFUL:
      return { ...state, isValidPayment: validatePayment(action.payload || new Date())};
    case actionTypes.GET_PERMISSIONS:
      calculatePermission(state.features, action.payload, state.user.free_user);
      return { ...state}
    case actionTypes.UPDATE_ALLOWED: 
      return {
        ...state,
        updateAllowed: action.payload
      }
    default:
      return { ...state };
  }
};

const validatePayment = (accExpDate) => {
  let currDate = dayjs(new Date());
  let expDate = dayjs(accExpDate);
  return expDate.diff(currDate) > 0;
}

const operation = (list1, list2, isUnion = false) => list1?.filter(
      (set => a => isUnion === set.has(a.id))(new Set(list2.map(b => b.feature_id)))
  );

const calculatePermission = (list1, list2, free_user) => {
  let _arr = operation(list1, list2, true);
  let obj = { ...basePermission[!free_user ? 'non-free' : 'free'] };
  _arr && _arr.forEach(item=>{
    obj[item['codename']] = item;
  })
  setToLocalStorage('permissions', obj);
}