import connection from '../utils/api';

/* POST Small Business Add API */
export const addSmallBusiness = smallBusinessPayload =>
  connection.post('small_business/create', smallBusinessPayload);

export const verifyCertificates = (id, payload) =>
  connection.put(`/small_business/${id}/validate_cert`, payload);

/* GET Small Business List API */
export const getSmallBusinesses = ({
  page,
  limit,
  filters,
  exportData = false
}) => {
  let queryString = '';
  const queryParams = [];

  if (exportData) {
    // If exportData is true, add the export action query parameter
    queryParams.push('action=export');
  }
  if (page) queryParams.push(`page=${page}`);
  if (limit) queryParams.push(`limit=${limit}`);
  if (filters?.length)
    filters.forEach(filter => {
      for (const [key, value] of Object.entries(filter)) {
        queryParams.push(`${key}=${value}`);
      }
    });

  if (queryParams.length) queryString = `?` + queryParams.join('&');

  return connection.get(`small_business/manage${queryString}`);
};
export const fetchAdvancedSearchResults = ({
  query,
  currentTableProps,
  filters,
  exportData = false
}) => {
  const params = {
    page: filters.length ? 1 : currentTableProps.pagination.current,
    limit: currentTableProps.pagination.size
  };

  if (exportData) {
    params.action = 'export';
  }

  return connection.post('small_business/adv_search', query, { params });
};

/* GET Small Business by Id */
export const getSmallBusiness = smallBusinessId =>
  connection.get(`small_business/manage/${smallBusinessId}`);

/* PUT Update Small Business by Id */
export const updateSmallBusiness = (smallBusinessId, smallBusinessPayload) =>
  connection.put(
    `small_business/manage/${smallBusinessId}`,
    smallBusinessPayload
  );

/* POST Import RFP-RFQ Event */
export const importSmallBusinesses = payload =>
  connection.post(`small_business/import`, payload);

/* DELETE Small Business by Id */
export const deleteSmallBusiness = smallBusinessId =>
  connection.delete(`small_business/manage/${smallBusinessId}`);
