import React, { useEffect, useState } from 'react';
import {
  Link,
  useHistory,
  useRouteMatch
} from 'react-router-dom';
import { connect } from 'react-redux';
import { func, object, string } from 'prop-types';
import { Table, Button, Space, Popconfirm, Tooltip } from 'antd';
import { EyeOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import { getVerificationSteps } from '../../api/verificationSteps';

import {
  setSteps,
  onDeleteStep
} from '../../redux/actions/verificationSteps';
import { hideLoading, showLoading, showModal } from '../../redux/actions/ui';
import { clearRedirect } from '../../redux/actions/redirect';
import { getCurrentPageFromLocation, HeaderRenderer } from '../../utils/utils';
import { accessRules } from '../../utils/accessRules';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: false,
    width: '20%'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    sorter: false,
    width: '20%'
  },
  {
    title: 'Created on',
    dataIndex: 'created_on',
    sorter: true,
    width: '20%'
  },
  {
    title: 'Last updated on',
    dataIndex: 'updated_on',
    sorter: true,
    width: '20%'
  }
];

const VerificationSteps = ({
  role,
  steps,
  redirect,
  onShowModal,
  onClearRedirect,
  onSetSteps,
  onDelete
}) => {
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const innerWidth = window.innerWidth;

  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    fetch({ pagination });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (redirect.path) {
      history.push(redirect.path);
      onClearRedirect();
    }
    // eslint-disable-next-line
  }, [redirect.path]);

  const handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters
    });
  };

  const fetch = (params = {}) => {
    setLoading(true);
    getVerificationSteps(params)
      .then(({ data }) => {
        onSetSteps(data['data']);
        setPagination({ ...params.pagination, total: data.pagination.total });
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  /* Render Actions Column */
  const renderActions = (text, record) => (
    <Space size="small">
      {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
        'view'
      ] ? (
        <Tooltip
          title="Preview"
          onClick={() => {
            onShowModal();
          }}
        >
          <Link
            style={{ marginRight: '5px' }}
            to={`${match.path}/preview/${record.id}`}
          >
            <EyeOutlined style={{ fontSize: '18px' }} />
          </Link>
        </Tooltip>
      ) : null}
      {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
        'edit'
      ] ? (
        <Tooltip
          title="Edit"
          onClick={() => {
            onShowModal();
          }}
        >
          <Link
            style={{ marginRight: '5px' }}
            to={`${match.path}/edit/${record.id}`}
          >
            <EditOutlined style={{ fontSize: '18px' }} />
          </Link>
        </Tooltip>
      ) : null}
      {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
        'delete'
      ] ? (
        <Popconfirm
          title="Are you sure to delete this center?"
          onConfirm={() => onDelete(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <Tooltip title="Delete">
            <span style={{ marginRight: '5px' }}>
              <DeleteOutlined style={{ fontSize: '18px', color: 'crimson' }} />
            </span>
          </Tooltip>
        </Popconfirm>
      ) : null}
    </Space>
  );

  const tableColumns = HeaderRenderer(columns, innerWidth);

  tableColumns.push({
    title: 'Action',
    key: 'action',
    displayName: 'action',
    render: renderActions
  });

  return (
    <>
      <Space
        direction="horizontal"
        style={{
          width: '100%',
          display: 'flex',
          padding: '8px',
          flexDirection: 'row',
          justifyContent: 'space-between'
          // alignItems: 'center'
        }}
      >
        <Space style={{ alignSelf: 'flex-start' }}>Verification Steps</Space>

        <Space style={{ alignSelf: 'flex-end' }}>
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'add'
          ] ? (
            <Button
              type="primary"
              size="small"
              onClick={e => {
                e.preventDefault();
                onShowModal();
              }}
            >
              <Link to={`${match.path}/create`}>Create New</Link>
            </Button>
          ) : null}
        </Space>
      </Space>
      <div className="divider"></div>

      <Table
        className="table-striped-rows"
        columns={tableColumns}
        rowKey={record => record.id}
        dataSource={steps}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  redirect: state.redirect,
  steps: state.verificationSteps.verificationSteps
});

const mapDispatchToProps = dispatch => ({
  onShowModal: () => dispatch(showModal()),
  onClearRedirect: () => dispatch(clearRedirect()),
  onShowLoading: () => dispatch(showLoading()),
  onHideLoading: () => dispatch(hideLoading()),
  onSetSteps: payload => dispatch(setSteps(payload)),
  onDelete: id => dispatch(onDeleteStep(id))
});

VerificationSteps.propTypes = {
  role: string,
  redirect: object,
  onShowModal: func,
  onClearRedirect: func,
  onDelete: func
};

export default connect(mapStateToProps, mapDispatchToProps)(VerificationSteps);
