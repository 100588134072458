import React, { useState, useEffect } from 'react';
import { Row, Col, Layout, Button, Typography } from 'antd';
import { showModal } from '../../redux/actions/ui';
import { useDispatch, useSelector, connect } from 'react-redux';
import {
  lookAhead,
  rfprfq,
  recent_bookmarks,
  dummy
} from '../../components/configs/tables/dashboard.json';
import TableComponent from '../../components/ui/TableComponent';
import Announcement from './announcement';
import {
  setActiveComponents,
  fetchSmallBusinesses
} from '../../redux/actions/dashboard';
import lookaheadLogo from '../../assets/images/LookAhead.png';
import newMembersLogo from '../../assets/images/NewMembers.png';
import rfpLogo from '../../assets/images/RFP_Bid.png';
import activityLogo from '../../assets/images/Activity.png';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';

const { Title } = Typography;

const NewCompany = ({ item, index }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <div className="company-container">
      {index === 0 && item['naics_codes'] && <h3>Diverse Business</h3>}
      <div className="company-details">
        <span>
          <p id="title">{item.company_name || '-'}</p>
          <p>
            <strong>Industry : </strong>
            {item.industry_specialization || item.company_specialization || '-'}
          </p>
        </span>
        <span>
          <Button
            size="small"
            onClick={() => {
              dispatch(showModal());
              history.push(`/small-business/view/${item.id}`, { goBack: true });
            }}
          >
            View
          </Button>
          <Button
            size="small"
            onClick={() => {
              dispatch(showModal());
              history.push(`/small-business/edit/${item.id}`, { goBack: true });
            }}
          >
            Edit
          </Button>
        </span>
      </div>
    </div>
  );
};

const LookAheadDash = ({ item }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <div className="lookahead-dash-container">
      <div>
        <strong>
          {dayjs(item.proposed_advertising_date).format('MMM, YYYY') || 'N/A'}
        </strong>
        {' - '}
        <span className="title">{item.agency}</span>
        <br />
        <span className="para">
          <strong>
            <small>Type : </small>
          </strong>
          {item.type_of_work}
        </span>
        <br />
        <span className="para">
          <strong>
            <small>Scope : </small>
          </strong>
          {item.scope}
        </span>
      </div>
      <Button
        size="small"
        onClick={() => {
          dispatch(showModal());
          history.push(`look-ahead/view/${item.id}`, { goBack: true });
        }}
      >
        View
      </Button>
    </div>
  );
};

const BookMarkDash = ({ item }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <p className="bookmark_dash">
      <span id="content">
        <strong>
          {item.date_end ? dayjs(item.date_end).format('MM/DD/YYYY') : '-'} :
        </strong>{' '}
        {item.title || ''} {item.agency || ''}
      </span>
      <Button
        size="small"
        onClick={() => {
          dispatch(showModal());
          history.push(`/calendar/view/${item.id}`, { goBack: true });
        }}
      >
        View
      </Button>
    </p>
  );
};

const UserDashboard = ({ role, loading }) => {
  const [currentTableProps] = useState({
    pagination: { current: 1, total: 0 }
  });

  const { activeComps, lookAheads, rfp_rfqs, calendars, smallBusiness } =
    useSelector(({ dashboard }) => ({
      activeComps: dashboard.activeComps,
      lookAheads: dashboard.lookAheads,
      rfp_rfqs: dashboard.rfp_rfqs,
      calendars: dashboard.calendars,
      smallBusiness: dashboard.smallBusiness,
      primes: dashboard.primes
    }));
  const { businessId } = useSelector(({ auth }) => ({
    businessId: auth.user?.businessId
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (businessId) {
      dispatch(setActiveComponents());
    }
    dispatch(fetchSmallBusinesses({ currentTableProps, filters: [] }));
    // eslint-disable-next-line
  }, []);

  const getColumns = type => {
    switch (type) {
      case 'RFP/Bid Closing Soon':
        return rfprfq.columns.map(item => ({ ...item, ellipsis: true }));
      case 'Look Ahead Calendar':
      case 'Present Month Look Ahead':
        return lookAhead.columns.map(item => ({ ...item }));
      case 'Calendar Events':
      case 'Recent Companies Viewed':
        return recent_bookmarks.columns.map(item => ({
          ...item,
          ellipsis: true
        }));
      default:
        return dummy.columns.map(item => ({ ...item, width: 160 }));
    }
  };

  const getImage = type => {
    switch (type) {
      case 'RFP/Bid Closing Soon':
        return rfpLogo;
      case 'Look Ahead Calendar':
        return lookaheadLogo;
      case 'Calendar Events':
        return activityLogo;
      default:
        return newMembersLogo;
    }
  };

  const getTableData = type => {
    switch (type) {
      case 'RFP/Bid Closing Soon':
        return rfp_rfqs?.map(item => ({
          id: item.id,
          agency: item.agency || '-',
          description: item.scope || '-',
          due_date: dayjs(item.due_date).format('MM/DD/YYYY')
        }));
      case 'Look Ahead Calendar':
        return lookAheads?.map(item => ({
          nofield: <LookAheadDash item={item} />
        }));
      case 'Calendar Events':
        return calendars?.map(item => ({
          bookMark_desc: <BookMarkDash item={item} />
        }));
      default:
        return smallBusiness.map((item, i) => ({
          name: <NewCompany item={item} index={i} />
        }));
    }
  };

  return (
    <Layout className="dashboard-container">
      <Row>
        <Col xs={24} md={24}>
          <Announcement />
        </Col>
      </Row>
      {role === 'small-business' && (
        <Row>
          <Col xs={24} md={24}>
            <div className="float-chat">
              <Title level={4}>
                How to get started on your business profile:{" "}
                <a
                  href="https://bechtel-assets.s3.us-east-2.amazonaws.com/Bechtel_Instructions_PDF.pdf"
                  target="_blank"
                >
                  See Instructions
                </a>
              </Title>
            </div>
          </Col>
        </Row>
      )}
      {role !== 'small-business' ? (
        <Row className="tables-container">
          {activeComps?.map((item, i) => (
            <Col
              key={i}
              xs={24}
              sm={24}
              md={activeComps.length === 1 ? 24 : 12}
              xl={24 / activeComps.length}
            >
              <div style={{ padding: 10 }}>
                <div className="heading-block">
                  <img
                    src={getImage(item.name)}
                    alt="icon"
                    width="35"
                    height="30"
                  />
                  <h3>{item.name}</h3>
                </div>
                <div
                  className="custom-scroll"
                  style={{
                    height: 400,
                    background: 'white',
                    overflowY: 'auto'
                  }}
                >
                  <div id="ghost-bar"></div>

                  <TableComponent
                    columns={getColumns(item.name)}
                    tableData={getTableData(item.name)}
                    size="small"
                    className={`${
                      ['Look Ahead Calendar', 'Welcome New Members'].includes(
                        item.name
                      )
                        ? 'no-header'
                        : ''
                    }`}
                    pagination={false}
                  />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      ) : null}
    </Layout>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  loading: state.ui.loading
});

export default connect(mapStateToProps, null)(UserDashboard);
