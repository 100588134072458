import {
  SEARCH_SET_QUERY,
  SEARCH_CLEAR_QUERY,
  SET_ACTIVE_ADVANCE_SEARCH,
  SEARCH_RESULTS,
  CLEAR_ADVANCE_SEARCH,
  SAVE_ADVANCE_FORM_VALUES,
  ADVANCED_SEARCH_SWITCH,
  SET_IS_LANDED_TRUE,
  SET_IS_LANDED_FALSE,
  RESET_TO_INIT
} from '../actionTypes';

const initialState = {
  advanceSearchMode: false,
  smallBusiness: {},
  primes: {},
  calendar: {},
  rfpRfq: {},
  lookAhead: {},
  association: {},
  'message-center': {},
  activeAdvanceSearch: null,
  searchResults: [],
  searchPagination: { current: 1, size: 10, total: 0 },
  clearAdvanceSearch: false,
  formValues: {},
  users: {},
  isLanded:false
};

export const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADVANCED_SEARCH_SWITCH:
      return {
        ...state,
        advanceSearchMode: action.payload
      };
    case SEARCH_SET_QUERY:
      return {
        ...state,
        [action.payload.component]: {
          keyword: action.payload.keyword,
          fields: [...action.payload.fields]
        }
      };
    case SET_ACTIVE_ADVANCE_SEARCH:
      return {
        ...state,
        activeAdvanceSearch: action.payload
      };
    case SEARCH_CLEAR_QUERY:
      return { ...state, [action.payload.component]: {} };
    case SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload.data,
        searchPagination: action.payload.pagination
      };
    case SAVE_ADVANCE_FORM_VALUES:
      return { ...state, formValues: action.payload };
    case CLEAR_ADVANCE_SEARCH:
      return {
        ...state,
        clearAdvanceSearch: action.payload.clearAdvanceSearch,
        advanceSearchMode: false,
        searchResults: [],
        searchPagination: { current: 1, size: 10, total: 0 }
      };
      case SET_IS_LANDED_TRUE:
        return{
        ...state,
        isLanded:true
      };
      case SET_IS_LANDED_FALSE:
        return{
        ...state,
        isLanded:false
      }
      case RESET_TO_INIT:
        return{
        ...state,
        smallBusiness:{},
        
      }
    default:
      return { ...state };
  }
};
