import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bool, func, number, object, string } from 'prop-types';
import { Space, Tooltip } from 'antd';

import List from './List';
import View from './View';

import { hideLoading, showLoading, showModal } from '../../redux/actions/ui';

const ActivityLog = ({}) => {
  return (
    <div className="container">
      <Space
        direction="horizontal"
        style={{
          width: '100%',
          padding: '8px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '10px'
        }}
      >
        <h3 className="section-headers">Activity Log</h3>
      </Space>
      <div className="divider" style={{ marginBottom: 0 }}></div>
      <List />
    </div>
  );
};

const mapStateToProps = state => ({
  // refresh: state.rfpRfq.refresh,
  // onDeleteSuccess: state.association.deleteSuccess,
  // fetchAssociationSuccess: state.association.fetchAssociation,
  // role: state.auth.role,
  // businessId: state.auth?.user?.businessId,
  // searchParams: state.search.association
});

const mapDispatchToProps = dispatch => ({
  // onShowModal: () => dispatch(showModal()),
  // onSetRefresh: value => dispatch(setAssociationsRefresh(value)),
  onShowLoading: () => dispatch(showLoading()),
  onHideLoading: () => dispatch(hideLoading())
  // fetchAssociationAction: value => dispatch(fetchAssociationAction(value))
});

ActivityLog.propTypes = {
  // refresh: bool,
  // fetchAssociationSuccess: bool,
  // onDeleteSuccess: bool,
  // role: string,
  // businessId: number,
  // searchParams: object,
  // onSetRefresh: func,
  // onShowModal: func,
  onShowLoading: func,
  onHideLoading: func
};

export default connect()(ActivityLog);
