import { SET_RECIPIENTS } from '../actionTypes';

const initialState = {
  sbRecipients: []
};

export const massEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RECIPIENTS:
      return {
        ...state,
        sbRecipients: action.payload
      };
    default:
      return { ...state };
  }
};
