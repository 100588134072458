import React, { useState } from 'react';
import { Form, Button } from 'antd';
import { connect } from 'react-redux';
import { onSetGlobalVar } from '../../redux/actions/globalVars';
import { useDispatch } from 'react-redux';
import { objectToFormData } from '../../utils/utils';
import ImageUpload from '../../components/common/Form/FormInputs/ImageUpload';
import { useRouteMatch } from 'react-router-dom';
import { object } from 'prop-types';

const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 24
  }
};

const SiteLogo = ({ globalVars }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const inputChangedHandler = (key, value) => {
    setFormValues({ ...formValues, [key]: value });
  };

  const onFinish = () => {
    setLoading(true);
    dispatch(
      onSetGlobalVar(
        objectToFormData({ site_value: formValues['logo'], is_deleted: false })
      )
    );
    form.resetFields();
    setFormValues({});
    setLoading(false);
  };

  return (
    <div>
      <Form
        form={form}
        {...layout}
        initialValues={formValues}
        layout="vertical"
        onFinish={onFinish}
      >
        {/* Company Logo */}
        <Form.Item key="logo" name="logo" hasFeedback>
          <ImageUpload
            keyName="logo"
            label="Logo"
            max={1}
            nestedHandler={(key, value) => {
              console.log(key, value);
              inputChangedHandler(key, value[key]);
            }}
          />
        </Form.Item>
        <Form.Item>
          <Button disabled={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
        <div>
          <img src={globalVars['logo']} height={100} width={100} />
        </div>
      </Form>
    </div>
  );
};

const mapStateToProps = state => ({
  globalVars: state.globalVars.globalVars
});

SiteLogo.propTypes = {
  globalVars: object
};

export default connect(mapStateToProps, null)(SiteLogo);
