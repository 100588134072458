import { 
    SET_NOTES,
    ADD_NOTE,
    FILTER_NOTES,
    UPDATE_NOTE
  } from '../actionTypes';
  
  const initialState = {
    notes: []
  };
  
  export const notesReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_NOTES:
        return { ...state, notes: action.payload };
      case ADD_NOTE:
        return { ...state, notes: [...state.notes, action.payload] };
      case UPDATE_NOTE:
        const newArray = state.notes.map(note => note.id === action.payload.id ? action.payload : note)
        return { ...state, notes: newArray };
      case FILTER_NOTES:
        return { ...state, notes: state.notes.filter(c => c.id !== action.noteId) };
      default:
        return { ...state };
    }
  };