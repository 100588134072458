/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { bool, func, number, object, string } from 'prop-types';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Space, Tooltip, Image, Typography } from 'antd';
import {
  getSmallBusinesses,
  fetchAdvancedSearchResults
} from '../../api/smallBusiness';
import { columns } from '../../components/configs/tables/smallBusiness.json';
import { setSmallBusinessRefresh } from '../../redux/actions/smallBusiness';
import { hideLoading, showLoading, showModal } from '../../redux/actions/ui';
import { handleNotifications } from '../../utils/notifications';
import TableComponent from '../../components/ui/TableComponent';
import { accessRules } from '../../utils/accessRules';
import {
  getCurrentPageFromLocation,
  HeaderRenderer,
  objectToFormData
} from '../../utils/utils';
import { dueDiligenceOptions } from '../../utils/constants';
import {
  PrinterOutlined,
  EditOutlined,
  DeleteOutlined,
  MessageOutlined,
  ContainerOutlined
} from '@ant-design/icons';
import { resetSearchResults } from '../../redux/actions/search';
import { RESET_TO_INIT, SET_RECIPIENTS } from '../../redux/actionTypes';
import Favourite from './Favourite';

const { Paragraph } = Typography;

const List = ({
  refresh,
  onDeleteSuccess,
  clearAdvanceSearch,
  role,
  searchParams,
  searchResults,
  searchPagination,
  onSetRefresh,
  onShowLoading,
  onHideLoading,
  onShowModal,
  companyVerificationSteps,
  advanceSearchMode,
  advanceSearchQuery,
  handleSearch,
  notesCount,
  smallBusinesses,
  setSmallBusinesses,
  setQuery,
  setAdvancedSearch
}) => {
  const appliedFilters = searchParams => {
    const searchFilters = searchParams?.fields
      ? searchParams.fields.map(field => ({
          [field]: encodeURIComponent(searchParams['keyword'])
        }))
      : [];

    return searchFilters;
  };

  const setFiltersFromSearchParams = searchParams => {
    const searchFilters = appliedFilters(searchParams);
    setCurrentFilters(searchFilters);
    setCurrentTableProps({
      ...currentTableProps,
      pagination: {
        current: 1,
        size: 10,
        total: 0
      }
    });
  };

  const [currentFilters, setCurrentFilters] = useState(
    appliedFilters(searchParams)
  );
  const [currentTableProps, setCurrentTableProps] = useState({
    pagination: { current: 1, size: 10, total: 0 }
  });
  const innerWidth = window.innerWidth;
  const { isLanded } = useSelector(store => store.search);

  //console.log(isLanded,"in list bali page")

  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  useDeepCompareEffect(() => {
    setFiltersFromSearchParams(searchParams);
  }, [searchParams]);

  useEffect(() => {
    if (refresh) {
      setFiltersFromSearchParams(searchParams);
      fetchSmallBusinesses({ currentTableProps, filters: [...currentFilters] });
    }
  }, [refresh]);

  useEffect(() => {
    if (onDeleteSuccess || clearAdvanceSearch) {
      fetchSmallBusinesses({ currentTableProps, filters: [...currentFilters] });
      dispatch(resetSearchResults({ clearAdvanceSearch: false }));
    }
  }, [onDeleteSuccess, clearAdvanceSearch]);

  // when a note is added/deleted
  useEffect(() => {
    fetchSmallBusinesses({ currentTableProps, filters: [...currentFilters] });
  }, [notesCount]);

  useDeepCompareEffect(() => {
    if (searchResults && searchResults.length) {
      let arr = filterData(searchResults);
      setSmallBusinesses(arr);

      setCurrentTableProps({
        ...currentTableProps,
        pagination: {
          current: currentTableProps.pagination.current,
          size: currentTableProps.pagination.size || 10,
          ...searchPagination,
          total: searchPagination.total || currentTableProps.pagination.total
        }
      });
    }
  }, [searchResults]);

  useDeepCompareEffect(() => {
    if (currentTableProps.pagination.current) {
      if (advanceSearchMode) {
        // console.log(currentTableProps, 'on useeffect')
        if (currentTableProps.pagination.current === 1) {
          return fetchSmallBusinesses({
            currentTableProps,
            filters: [...currentFilters]
          });
        }
        if (searchPagination.page !== currentTableProps.pagination.current) {
          fetchSmallBusinesses({
            currentTableProps,
            filters: [...currentFilters]
          });
        }
      } else {
        fetchSmallBusinesses({
          currentTableProps,
          filters: [...currentFilters]
        });
      }
    }
  }, [
    currentFilters,
    currentTableProps.pagination.current,
    currentTableProps.pagination.size
  ]);

  const buildNaics = item => {
    if (item['naics_codes']?.length) {
      const naicsToTake = item['naics_codes'].slice(0, 5);

      let naics = naicsToTake.map(subItem => subItem['codes']).join(', ');

      if (!naics) {
        return '-';
      }

      if (item['naics_codes'].length > 5) {
        naics = `${naics} More...`;
      }

      return naics;
    }

    return '-';
  };

  const buildIndustrySpecialization = item => {
    if (item['industry_specialization']?.length) {
      const isMoreThanFive = item['industry_specialization']?.length > 5;

      const industrySpecializationToTake = item['industry_specialization']
        .slice(0, 5)
        .map((list, idx) => (
          <Paragraph
            style={{ marginBottom: 0, lineHeight: 1.5 }}
            key={`industrySpecialization-${idx}`}
          >
            {list}
          </Paragraph>
        ));

      if (isMoreThanFive) {
        industrySpecializationToTake.push(
          <Paragraph
            style={{ marginBottom: 0, lineHeight: 1.5 }}
            key="industrySpecialization-more"
          >
            More...
          </Paragraph>
        );
      }

      return industrySpecializationToTake;
    }
    return ['-'];
  };

  const filterData = arr => {
    const arrCopy = arr.map((item, idx) => ({
      ...item,
      key: item['id'],
      naics_codes: buildNaics(item),
      county: item['address']?.length
        ? item['address'].map(subItem => subItem['county']).join(', ')
        : '-',
      state: item['address']?.length
        ? item['address'].map(subItem => subItem['state']).join(', ')
        : '-',
      verification_step_id: item['verification_step_id']
        ? companyVerificationSteps.find(
            x => x.id === item['verification_step_id']
          )?.name
        : 'Not Verified',
      due_diligence: item['due_diligence']
        ? dueDiligenceOptions[item['due_diligence']]
        : '-',
      industry_specialization: buildIndustrySpecialization(item),
      company_photo: (
        <Image
          width={50}
          src="https://dummyimage.com/50"
          alt={item.company_name}
        />
      ),
      favourite: (
        <Favourite
          item={item}
          arr={arr}
          index={idx}
          setSmallBusinesses={setSmallBusinesses}
          filterData={filterData}
        />
      )
    }));
    return arrCopy;
  };

  useEffect(() => {
    if (isLanded) {
      setCurrentFilters([]);
      onSetRefresh(false);

      return () => {
        dispatch({
          type: SET_RECIPIENTS,
          payload: []
        });
      };
    }
  }, [isLanded]);

  /* Render Actions Column */
  const renderActions = (text, record) => {
    return (
      <Space size="small">
        {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
          'chat'
        ] ? (
          <Tooltip
            title="Chat"
            onClick={e => {
              e.stopPropagation();
              onShowModal();
            }}
          >
            <Link
              style={{ marginRight: '5px' }}
              to={`${match.path}/chat/${record.id}/SmallBusiness`}
            >
              <MessageOutlined style={{ fontSize: '18px' }} />
            </Link>
          </Tooltip>
        ) : null}

        {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
          'notes'
        ] ? (
          <Tooltip
            title="Notes"
            onClick={e => {
              e.stopPropagation();
              onShowModal();
            }}
          >
            <Link
              style={{ marginRight: '5px' }}
              to={`${match.path}/notes/${record.id}`}
            >
              <ContainerOutlined style={{ fontSize: '18px' }} />
            </Link>
          </Tooltip>
        ) : null}

        {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
          'print-view'
        ] ? (
          <Tooltip
            title="Print Profile"
            onClick={e => {
              e.stopPropagation();
              onShowModal();
            }}
          >
            <Link
              style={{ marginRight: '5px' }}
              to={`${match.path}/print-view/${record.id}`}
            >
              <PrinterOutlined style={{ fontSize: '18px' }} />
            </Link>
          </Tooltip>
        ) : null}

        {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
          'edit'
        ] ? (
          <Tooltip
            title="Edit"
            onClick={e => {
              e.stopPropagation();
              onShowModal();
            }}
          >
            <Link
              style={{ marginRight: '5px' }}
              to={`${match.path}/edit/${record.id}`}
            >
              <EditOutlined style={{ fontSize: '18px' }} />
            </Link>
          </Tooltip>
        ) : null}

        {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
          'delete'
        ] ? (
          <Tooltip
            title="Delete"
            onClick={e => {
              e.stopPropagation();
              onShowModal();
            }}
          >
            <Link
              style={{ marginRight: '5px' }}
              to={{
                pathname: `${match.path}/delete/${record.id}`,
                businessName: record['company_name']
              }}
            >
              <DeleteOutlined style={{ fontSize: '18px', color: 'crimson' }} />
            </Link>
          </Tooltip>
        ) : null}
      </Space>
    );
  };

  const tableColumns = HeaderRenderer(columns, innerWidth);

  tableColumns.push({
    title: 'Action',
    key: 'action',
    displayName: 'action',
    render: renderActions
  });

  const fetchSmallBusinesses = ({ currentTableProps, filters }) => {
    // console.log(currentTableProps, 'currenttableprops on top')
    const query = {};
    if (currentTableProps?.pagination) {
      query['page'] = currentTableProps.pagination.current;
      query['limit'] = currentTableProps.pagination.size;
    }
    if (filters) {
      query['filters'] = filters;
    }
    setQuery(query);
    onShowLoading();
    onSetRefresh(false);
    if (advanceSearchMode) {
      setQuery({
        query: objectToFormData(advanceSearchQuery),
        currentTableProps,
        filters
      });
      setAdvancedSearch(true);
      // console.log(currentTableProps, 'currenttableprops')
      fetchAdvancedSearchResults({
        query: objectToFormData(advanceSearchQuery),
        currentTableProps,
        filters
      })
        .then(({ data }) => {
          if (data.success) {
            let arr = filterData(data.data);
            let arr2 = arr.slice(0, 5);
            setSmallBusinesses(arr);
            setCurrentTableProps({
              ...currentTableProps,
              pagination: {
                ...currentTableProps.pagination,
                size: currentTableProps.pagination.size || 10,
                total: data?.pagination.total
              }
            });
            onHideLoading();
          } else {
            setSmallBusinesses([]);
            onHideLoading();
            handleNotifications('error', 'Failed', data.message);
          }
        })
        .catch(error => {
          setSmallBusinesses([]);
          onHideLoading();
          handleNotifications('error', 'Failed', error.message);
        });
      // console.log("currentTableProps:", currentTableProps);
      // console.log("query:", query);
    } else {
      getSmallBusinesses(query)
        .then(({ data }) => {
          if (data.success) {
            let arr = filterData(data.data);
            setSmallBusinesses(arr);
            setCurrentTableProps({
              ...currentTableProps,
              pagination: {
                ...currentTableProps.pagination,
                size: currentTableProps.pagination.size || 10,
                total: data?.pagination.total
              }
            });
            onHideLoading();
          } else {
            setSmallBusinesses([]);
            onHideLoading();
            handleNotifications('error', 'Failed', data.message);
          }
        })
        .catch(error => {
          setSmallBusinesses([]);
          onHideLoading();
          handleNotifications('error', 'Failed', error.message);
        });
    }
  };

  const manageDataSource = ({ current, pageSize }, filters) => {
    let tableProps = { ...currentTableProps };
    if (current) {
      // console.log(current, "btn number in list page")
      tableProps = {
        ...tableProps,
        pagination: { ...tableProps.pagination, current }
      };
      // console.log(tableProps, 'tableprps on pagination');
    }
    if (pageSize) {
      // console.log(pageSize,'pagesize on btn')
      tableProps = {
        ...tableProps,
        pagination: { ...tableProps.pagination, size: pageSize }
      };
    }
    setCurrentTableProps({ ...tableProps });
  };

  // console.log(currentTableProps,'outside')

  const onClickRow = record => {
    return {
      onClick: () => {
        if (
          accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'view'
          ]
        ) {
          onShowModal();
          history.push(`${match.path}/view/${record.id}`);
        }
      }
    };
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      dispatch({
        type: SET_RECIPIENTS,
        payload: selectedRows.map(({ id }) => id)
      });
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name
    })
  };

  return (
    <>
      <TableComponent
        className="table-hoverable"
        columns={tableColumns}
        tableData={smallBusinesses}
        onRow={onClickRow}
        pagination={{
          position: ['bottomCenter'],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          hideOnSinglePage: true,
          ...currentTableProps.pagination
        }}
        manageDataSource={manageDataSource}
        rowSelection={rowSelection}
      />
    </>
  );
};

const mapStateToProps = state => ({
  refresh: state.smallBusiness.refresh,
  onDeleteSuccess: state.smallBusiness.deleteSuccess,
  clearAdvanceSearch: state.search.clearAdvanceSearch,
  role: state.auth.role,
  businessId: state.auth?.user?.businessId,
  searchParams: state.search.smallBusiness,
  searchResults: state.search.searchResults,
  searchPagination: state.search.searchPagination,
  companyVerificationSteps: state.verificationSteps.verificationSteps,
  advanceSearchMode: state.search.advanceSearchMode,
  advanceSearchQuery: state.search.formValues,
  notesCount: state.notes.notes
});

const mapDispatchToProps = dispatch => ({
  onShowModal: () => dispatch(showModal()),
  onHideLoading: () => dispatch(hideLoading()),
  onSetRefresh: payload => dispatch(setSmallBusinessRefresh(payload)),
  onShowLoading: () => dispatch(showLoading())
});

List.propTypes = {
  refresh: bool,
  onDeleteSuccess: bool,
  role: string,
  businessId: number,
  searchParams: object,
  onSetRefresh: func,
  onShowModal: func,
  onShowLoading: func,
  onHideLoading: func,
  advanceSearchMode: bool,
  callAdvanceSearch: func
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
