import React, { useEffect, useState } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bool, func, object, string } from 'prop-types';
import { Button, Space } from 'antd';
import { showModal } from '../../redux/actions/ui';
import { clearRedirect } from '../../redux/actions/redirect';
import { accessRules } from '../../utils/accessRules';
import { getCurrentPageFromLocation } from '../../utils/utils';
import ModalComponent from '../../components/ui/ModalComponent';
import List from './List';
import View from './View';
import SmallBusinessAdd from '../../components/customForms/SmallBusinessAdd';
import SmallBusinessEdit from '../../components/customForms/SmallBusinessEdit';
import SmallBusinessView from '../../components/customForms/SmallBusinessView';
import Delete from './Delete';
import Import from './Import';
import Notes from './Notes';
import MassEmailView from '../MassEmail/MassEmailView';
import ExportData from './ExportData';
import {
  fetchAdvancedSearchResults,
  getSmallBusinesses
} from '../../api/smallBusiness';
import Paragraph from 'antd/lib/skeleton/Paragraph';
import { hideLoading, showLoading } from '../../redux/actions/ui';

const SmallBusiness = ({
  role,
  redirect,
  history,
  match,
  onShowModal,
  onClearRedirect,
  sendEmailFlag,
  companyVerificationSteps,
  onShowLoading,
  onHideLoading
}) => {
  const [smallBusinesses, setSmallBusinesses] = useState([]);
  const [query, setQuery] = useState({});
  const [exportData, setExportData] = useState([]);
  const [advancedSearch, setAdvancedSearch] = useState(false);

  useEffect(() => {
    if (redirect.path) {
      history.push(redirect.path);
      onClearRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect.path]);

  // Function to handle export button clicks
  const handleExportButtonClick = async () => {
    if (advancedSearch) {
      try {
        onShowLoading();
        // Call getSmallBusinesses function with the desired parameters
        const response = await fetchAdvancedSearchResults({
          ...query,
          exportData: true
        });
        if (response.data.success) {
          onHideLoading();
          // Extract and set the small businesses data in the state
          const exportData = response.data.data.data;

          const arrCopy = exportData.map((item, idx) => ({
            ...item,
            key: item['id'],
            naics_codes: buildNaics(item),
            state: item['address']?.length
              ? item['address'].map(subItem => subItem['state']).join(', ')
              : '-',
            verification_step_id: item['verification_step_id']
              ? companyVerificationSteps.find(
                  x => x.id === item['verification_step_id']
                )?.name
              : 'Not Verified',
            industry_specialization: buildIndustrySpecialization(item)
          }));

          setExportData(arrCopy);
        } else {
          onHideLoading();
          // Handle any errors here
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        // Handle any network or other errors here
        console.error('Error fetching data:', error);
      }
    } else {
      try {
        onShowLoading();
        // Call getSmallBusinesses function with the desired parameters
        const response = await getSmallBusinesses({
          ...query,
          exportData: true
        });

        if (response.data.success) {
          // Extract and set the small businesses data in the state
          onHideLoading();
          const exportData = response.data.data.data;
          const arrCopy = exportData.map((item, idx) => ({
            ...item,
            key: item['id'],
            naics_codes: buildNaics(item),
            state: item['address']?.length
              ? item['address'].map(subItem => subItem['state']).join(', ')
              : '-',
            verification_step_id: item['verification_step_id']
              ? companyVerificationSteps.find(
                  x => x.id === item['verification_step_id']
                )?.name
              : 'Not Verified',
            industry_specialization: buildIndustrySpecialization(item)
          }));

          setExportData(arrCopy);
        } else {
          onHideLoading();
          // Handle any errors here
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        onHideLoading();
        // Handle any network or other errors here
        console.error('Error fetching data:', error);
      }
    }
  };

  const buildNaics = item => {
    if (item['naics_codes']?.length) {
      const naicsToTake = item['naics_codes'].slice(0, 5);

      let naics = naicsToTake.map(subItem => subItem['codes']).join(', ');

      if (!naics) {
        return '-';
      }

      if (item['naics_codes'].length > 5) {
        naics = `${naics} More...`;
      }

      return naics;
    }

    return '-';
  };
  const buildIndustrySpecialization = item => {
    if (item['industry_specialization']?.length) {
      const isMoreThanFive = item['industry_specialization']?.length > 5;

      const industrySpecializationToTake = item['industry_specialization']
        .slice(0, 5)
        .map((list, idx) => (
          <Paragraph
            style={{ marginBottom: 0, lineHeight: 1.5 }}
            key={`industrySpecialization-${idx}`}
          >
            {list}
          </Paragraph>
        ));

      if (isMoreThanFive) {
        industrySpecializationToTake.push(
          <Paragraph
            style={{ marginBottom: 0, lineHeight: 1.5 }}
            key="industrySpecialization-more"
          >
            More...
          </Paragraph>
        );
      }

      return industrySpecializationToTake;
    }
    return ['-'];
  };
  return (
    <div className="container">
      <ModalComponent goBack={true}>
        <Switch>
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'add'
          ] ? (
            <Route
              path={`${match.path}/add`}
              render={() => <SmallBusinessAdd />}
            />
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'view'
          ] ? (
            <Route
              path={`${match.path}/view/:smallBusinessId`}
              render={() => <SmallBusinessView />}
            />
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'import'
          ] ? (
            <Route path={`${match.path}/import`} component={Import} />
          ) : null}
          {/* {accessRules[role][getCurrentPageFromLocation(match.path)][
            'actions'
          ]['chat'] ? (
            <Route
              path={`${match.path}/chat/:companyId/:companyType`}
              render={() => <SuperChat />}
            />
          ) : null} */}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'notes'
          ] ? (
            <Route
              path={`${match.path}/notes/:smallBusinessId`}
              render={() => <Notes />}
            />
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'print-view'
          ] ? (
            <Route
              path={`${match.path}/print-view/:smallBusinessId`}
              render={() => <View />}
            />
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'edit'
          ] ? (
            <Route
              path={`${match.path}/edit/:smallBusinessId`}
              render={() => <SmallBusinessEdit selfMode={false} />}
            />
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'delete'
          ] ? (
            <Route
              path={`${match.path}/delete/:businessId`}
              component={Delete}
            />
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'send-email'
          ] ? (
            <Route
              path={`${match.path}/send-email`}
              component={MassEmailView}
            />
          ) : null}
        </Switch>
      </ModalComponent>
      <Space
        direction="horizontal"
        style={{
          width: '100%',
          padding: '8px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '10px'
        }}
      >
        <h3 className="section-headers">Diverse Business Companies</h3>
        <Space direction="horizontal">
          <ExportData
            exportData={exportData}
            onExportButtonClick={handleExportButtonClick}
          />
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'send-email'
          ] && sendEmailFlag ? (
            <>
              <Button
                type="primary"
                size="small"
                onClick={e => {
                  e.preventDefault();
                  onShowModal();
                }}
              >
                <Link to={`${match.path}/send-email`}>Send Email</Link>
              </Button>
            </>
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'add'
          ] ? (
            <>
              <Button
                type="primary"
                size="small"
                onClick={e => {
                  e.preventDefault();
                  onShowModal();
                }}
              >
                <Link to={`${match.path}/add`}>Add Diverse Business</Link>
              </Button>
            </>
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'import'
          ] ? (
            <>
              <Button
                type="primary"
                size="small"
                onClick={e => {
                  e.preventDefault();
                  onShowModal();
                }}
              >
                <Link to={`${match.path}/import`}>
                  Import Diverse Businesses
                </Link>
              </Button>{' '}
            </>
          ) : null}
        </Space>
      </Space>
      <div className="divider" style={{ marginBottom: 0 }}></div>
      <List
        smallBusinesses={smallBusinesses}
        setSmallBusinesses={setSmallBusinesses}
        setQuery={setQuery}
        setAdvancedSearch={setAdvancedSearch}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  redirect: state.redirect,
  sendEmailFlag: state.massEmail.sbRecipients.length > 0,
  companyVerificationSteps: state.verificationSteps.verificationSteps
});

const mapDispatchToProps = dispatch => ({
  onShowModal: () => dispatch(showModal()),
  onClearRedirect: () => dispatch(clearRedirect()),
  onShowLoading: () => dispatch(showLoading()),
  onHideLoading: () => dispatch(hideLoading())
});

SmallBusiness.propTypes = {
  role: string,
  redirect: object,
  match: object,
  history: object,
  editMode: bool,
  onShowModal: func,
  onClearRedirect: func,
  sendEmailFlag: bool,
  onShowLoading: func,
  onHideLoading: func
};

export default connect(mapStateToProps, mapDispatchToProps)(SmallBusiness);
