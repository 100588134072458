import React, { useState, useEffect } from 'react';
import { Button, Input } from 'antd';
import { handleNotifications } from '../../utils/notifications';
import { addField, updateField } from '../../api/primesFields';
import { objectToFormData } from '../../utils/utils';

const ProjectContact = ({ disabled, valueProps, parentHandler, index }) => {
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (valueProps) {
      setDetails({ ...valueProps });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e, key) => {
    e.persist();
    setDetails(prev => ({ ...prev, [key]: e.target?.value }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    let { name, title, phone_number, email, id } = details;
    let payload = { name, title, phone_number, email };
    setLoading(true);
    try {
      let res;
      if (id) {
        res = await updateField(
          'project_contact',
          id,
          objectToFormData(payload)
        );
      } else {
        res = await addField('project_contact', objectToFormData(payload));
      }
      if (res.data && res.data.success) {
        handleNotifications(
          'success',
          'success',
          `Project contact successfully ${id ? 'updated' : 'added'}.`
        );
        parentHandler && parentHandler(res.data.data?.id || id, index);
      } else {
        handleNotifications(
          'error',
          'error',
          'Project contact error occured !!'
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleNotifications('error', 'error', 'Project contact error occured !!');
    }
  };

  return (
    <React.Fragment>
      <Input
        placeholder="Name"
        style={{ marginLeft: '-7px', width: '101%' }}
        onChange={e => handleChange(e, 'name')}
        disabled={disabled}
        defaultValue={valueProps?.['name']}
      />
      <Input
        placeholder="Title"
        style={{ marginLeft: '-7px', width: '101%' }}
        onChange={e => handleChange(e, 'title')}
        disabled={disabled}
        defaultValue={valueProps?.['title']}
      />
      <Input
        placeholder="Phone"
        type="tel"
        // pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
        maxLength={10}
        style={{ marginLeft: '-7px', width: '101%' }}
        onChange={e => handleChange(e, 'phone_number')}
        disabled={disabled}
        defaultValue={valueProps?.['phone_number']}
      />
      <Input
        placeholder="Email"
        type="email"
        style={{ marginLeft: '-7px', width: '101%' }}
        onChange={e => handleChange(e, 'email')}
        disabled={disabled}
        defaultValue={valueProps?.['email']}
      />
      {!disabled ? (
        <Button
          type="warning"
          style={{
            float: 'right',
            marginRight: '0px',
            marginTop: '0px',
            backgroundColor: '#82CF8F',
            color: 'white'
          }}
          disabled={disabled || loading}
          onClick={handleSubmit}
        >
          Save
        </Button>
      ) : null}
    </React.Fragment>
  );
};

export default ProjectContact;
