import React, { useState, useEffect } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import { fileFormats } from '../../utils/constants';

const Attachments = ({ onFileUpload, allowedTypes }) => {
  const [fileList, setFileList] = useState([]);

  const isFileAllowed = size => {
    return size / 1024 / 1024 < 1;
  };
  const maxCount = 5;
  const config = {
    maxCount,
    onChange(info) {
      if (isFileAllowed(info.file.size))
        setFileList(info.fileList.filter(file => isFileAllowed(file.size)));
    },
    beforeUpload(file) {
      if (isFileAllowed(file.size)) {
        return true;
      }
      message.error('File must smaller than 5MB!');
      return false;
    },
    customRequest({ onSuccess }) {
      onSuccess('ok');
    }
  };

  useEffect(() => {
    onFileUpload(fileList);
  }, [fileList]);

  return (
    <Upload {...config} fileList={fileList} accept={fileFormats[allowedTypes]}>
      <Button icon={<UploadOutlined />}>Upload (Max: {maxCount})</Button>
    </Upload>
  );
};

export default Attachments;
