import React, { useEffect, useState } from 'react';
import { Row, Col, Input, Button, Form, Typography, Checkbox } from 'antd';
import Dropdowns from '../../components/common/Form/FormInputs/Dropdowns';
import { connect, useSelector } from 'react-redux';
import { onSendMassEmail } from '../../redux/actions/massEmail';
import Attachments from './Attachments';
import { InfoCircleOutlined } from '@ant-design/icons';

const EmailForm = ({ sendMassEmail }) => {
  const [recipients, setRecipients] = useState([]);
  const [body, setBody] = useState('');
  const [sender, setSender] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [sendAll, setsendAll] = useState(false);

  const { sbRecipients } = useSelector(state => state.massEmail);

  const senders = [
    { key: 'Stepfone', value: 'stmontgomery@mssstratpartners.com' },
    { key: 'Jenny', value: 'jayala@mssstratpartners.com' },
    { key: 'Support', value: 'info@diversebusinessbechtel.com' }
  ];

  const sendEmail = () => {
    const requestPayload = {
      sender,
      recipient: sendAll ? 'all' : recipients,
      recipient_type: sbRecipients.length > 0 ? 'id' : 'email',
      text: body,
      subject: 'Hello',
      sender_name: 'Diverse Business Bechtel',
      files: attachments.map(({ originFileObj }) => originFileObj)
      // password: '9$bvY3MrRTQ#@TPxWsRv5@R9MDB3sxgH8TDgxr27'
    };

    const formData = new FormData();
    Object.keys(requestPayload).forEach(key => {
      if (key === 'files') {
        requestPayload[key].forEach(file => {
          formData.append('files', file);
        });
      } else {
        formData.append(key, requestPayload[key]);
      }
    });

    sendMassEmail(formData).then(({ data }) => {
      console.log(data);
      setRecipients([]);
      setAttachments([]);
      setsendAll(false);
      setSender('');
      setBody('');
    });
  };

  useEffect(() => {
    setRecipients(sbRecipients);
  }, [sbRecipients]);

  return (
    <div className="email__form">
      <Form layout="vertical" onFinish={sendEmail} onFinishFailed={() => {}}>
        <Row justify="center">
          <Col span={16}>
            <Typography.Title level={2}>Send Mass Email</Typography.Title>
          </Col>
          <Col span={16}>
            <Form.Item
              label={`Recipients (${
                sendAll
                  ? 'All'
                  : recipients.length > 0
                  ? recipients.length
                  : 'No'
              } recipients Selected)`}
              name="recipients"
            >
              <Dropdowns
                keyName="recipients"
                label="Recipients"
                placeholder="Recipients"
                disabled={sbRecipients.length > 0 || sendAll}
                multiSelect
                mode
                valueProps={recipients}
                onChange={v => setRecipients(v)}
              />
              <div>
                <Checkbox onChange={e => setsendAll(e.target.checked)}>
                  Send to all
                </Checkbox>
              </div>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              label="Body"
              name="body"
              tooltip={{
                title:
                  'Write {name} instead actual name, if you selects multiple recipients',
                placement: 'right',
                icon: <InfoCircleOutlined />
              }}
              validateTrigger={['onChange', 'onBlur']}
              rules={[
                {
                  required: true,
                  message: 'Body is required.'
                }
              ]}
            >
              <Input.TextArea
                value={body}
                onChange={e => setBody(e.target.value)}
                rows={4}
                placeholder="Write here..."
              />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item label="Attachments" name="attachments">
              <Attachments
                onFileUpload={setAttachments}
                allowedTypes="email_attachment"
              />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              label="Sender"
              name="sender"
              required={true}
              validateTrigger={['onChange', 'onBlur']}
              rules={[
                {
                  validator: async (_, sender) => {
                    if (Boolean(sender) === false || sender?.length === 0) {
                      return Promise.reject(new Error('Sender is required.'));
                    }
                  }
                }
              ]}
            >
              <Dropdowns
                keyName="sender"
                label="Sender"
                placeholder="Select Sender"
                valueProps={sender}
                onChange={v => setSender(v)}
                dropDownOptions={senders}
              />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Button type="primary" htmlType="submit">
              Send
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  sendMassEmail: payload => dispatch(onSendMassEmail(payload))
});

export default connect(null, mapDispatchToProps)(EmailForm);
