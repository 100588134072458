import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { array } from 'prop-types';

import PluggableForm from '../Form/PluggableForm';
import { objectToFormData } from '../../../utils/utils';
import configs from '../../configs/forms/advanceSearch.json';
import {
  setActiveAdvanceSearch,
  onSearch,
  saveAdvanceFormValues,
  switchAdvancedSearchMode
} from '../../../redux/actions/search';

const AdvanceSearch = ({
  title,
  search,
  hideAdvanceSearch,
  handleSearch,
  saveForm,
  toggleAdvancedSearch,
  redirectToDiverseBusiness
}) => {
  const [form, setForm] = useState([]);
  const [reset, setReset] = useState(false);
  const [clearAdvanceSearch, formValues] = useSelector(({ search }) => [
    search.clearAdvanceSearch,
    search.formValues
  ]);

  useEffect(() => {
    return () => {
      hideAdvanceSearch();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clearAdvanceSearch) {
      setReset(true);
      saveForm({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearAdvanceSearch]);

  useEffect(() => {
    switch (search) {
      case 'association':
        setForm(configs.association);
        break;
      case 'smallBusiness':
        setForm(configs.smallBusiness);
        break;
      case 'primes':
        setForm(configs.primes);
        break;
      default:
        break;
    }
  }, [search]);

  const onSubmit = values => {
    values = { ...values };
    const payload = Object.keys(values).reduce(
      (accumulator, item) => ({
        ...accumulator,
        ...(typeof values[item] === 'object' && !Array.isArray(values[item])
          ? values[item]
          : { [item]: values[item] })
      }),
      {}
    );
    Object.keys(payload).forEach(key => {
      if (payload[key] === undefined || payload[key].length === 0) {
        delete payload[key];
      }
    });
    saveForm(payload);
    handleSearch({
      query: objectToFormData(payload),
      currentTableProps: {
        pagination: { current: 1, size: 10 },
        filters: {}
      },
      filters: []
    });
    toggleAdvancedSearch(true);

    if (redirectToDiverseBusiness) {
      redirectToDiverseBusiness();
    }
  };

  const submitFail = errors => {
    console.log(errors);
  };

  return (
    <>
      <h1 className="heading-title">{title}</h1>
      {/* <div className='plug_form'>  */}
      <PluggableForm
        config={form}
        formConfig={{
          layout: 'vertical',
          name: 'add-look-ahead',
          labelCol: { span: 24 },
          wrapperCol: { span: 24 }
        }}
        onFinish={onSubmit}
        onFinishFailed={submitFail}
        editMode={true}
        // valueProps={formValues}
        reset={reset}
      />
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  hideAdvanceSearch: payload => dispatch(setActiveAdvanceSearch(payload)),
  handleSearch: payload => dispatch(onSearch(payload)),
  saveForm: payload => dispatch(saveAdvanceFormValues(payload)),
  toggleAdvancedSearch: payload => dispatch(switchAdvancedSearchMode(payload))
});

AdvanceSearch.propTypes = { config: array };

export default connect(null, mapDispatchToProps)(AdvanceSearch);
