import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SearchForm from '../../components/searchForms/Search';
import ModalComponent from '../../components/ui/ModalComponent';
import AdvanceSearch from '../../components/common/SearchSidebar/AdvanceSearch';
import { setActiveAdvanceSearch } from '../../redux/actions/search';
import { hideModal } from '../../redux/actions/ui';

const Search = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const closeAdvanceSearch = () => {
    dispatch(setActiveAdvanceSearch(null));
  };

  const redirectToDiverseBusiness = () => {
    if (location.pathname === '/search') {
      dispatch(hideModal());
      history.push('/small-business');
    }
  };

  return (
    <>
      <ModalComponent onCloseModalSuccess={closeAdvanceSearch}>
        <AdvanceSearch
          title="Diverse Business Search"
          search="smallBusiness"
          redirectToDiverseBusiness={redirectToDiverseBusiness}
        />
      </ModalComponent>
      <SearchForm
        name="smallBusiness"
        redirectToDiverseBusiness={redirectToDiverseBusiness}
      />
    </>
  );
};

export default Search;
