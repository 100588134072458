import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { array, func, object, string } from 'prop-types';
import { Button, Input, Typography } from 'antd';
import { getStats } from '../../api/stats';

const { Title } = Typography;

const Stats = ({steps}) => {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = () => {
    getStats().then(res => {
      setStats(res.data);
    });
  };

  return (
    <>
      {stats ? (
        <div>
          <Title level={4}>Stats</Title>
          {stats.map(stat => {
            return (
              <p>
                {steps.find(item => item.id === stat[0])?.name} - {stat[1]}
              </p>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = state => ({
  steps: state.verificationSteps.verificationSteps
});

Stats.propTypes = {
  steps: array
};

export default connect(mapStateToProps, null)(Stats);
