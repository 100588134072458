import connection from '../utils/api';

export const getActivityLogs = ({ page, limit, filters }) => {
  let queryString = '';
  const queryParams = [];

  if (page) queryParams.push(`page=${page}`);
  if (limit) queryParams.push(`limit=${limit}`);
  if (filters?.length)
    filters.forEach(filter => {
      for (const [key, value] of Object.entries(filter)) {
        queryParams.push(`${key}=${value}`);
      }
    });
  if (queryParams.length) queryString = `?` + queryParams.join('&');
  return connection.get(`activity-log${queryString}`);
};

export const createActivityLog = payload =>
  connection.post(`activity-log`, payload);

export const updateActivityLog = (logId, payload) =>
  connection.put(`activity-log/${logId}`, payload);

export const deleteActivityLog = logId =>
  connection.delete(`activity-log/${logId}`);
