import React, { useEffect, useState } from 'react';
import { any, bool, func, string } from 'prop-types';
import { Select } from 'antd';

import { getDropdownOptions } from '../../../../api/formOptions';

const { Option } = Select;

const Dropdowns = ({
  keyName,
  label,
  useKey,
  multiSelect,
  valueProps,
  disabled,
  onChange,
  dropDownOptions,
  mode
}) => {
  const [options, setOptions] = useState(null);
  useEffect(() => {
    if (dropDownOptions) {
      return setOptions(dropDownOptions);
    }
    if (keyName) {
      getDropdownOptions(keyName)
        .then(({ data }) => {
          if (data['success']) {
            setOptions(
              data['data'][keyName].map(item => {
                const node = {};
                for (const [key, value] of Object.entries(item)) {
                  node['key'] = key;
                  node['value'] = value;
                }
                return node;
              })
            );
          } else {
            setOptions([]);
          }
        })
        .catch(error => {
          setOptions([]);
          console.log(error);
        });
    }
  }, [keyName, dropDownOptions]);

  const getMode = () =>
    multiSelect ? (mode ? 'tags' : 'multiple') : null;

  return (
    <>
      {options ? (
        <Select
          placeholder={`Select ${label}`}
          onChange={onChange}
          style={{ width: '100%' }}
          mode={getMode()}
          defaultValue={valueProps}
          value={valueProps}
          disabled={disabled}
          showArrow
        >
          {!multiSelect && <Option disabled value=''>Select {label}</Option>}
          {options.map(item => (
            <Option
              key={item['key']}
              value={useKey ? item['key'] : item['value']}
            >
              {item['value']}
            </Option>
          ))}
        </Select>
      ) : null}
    </>
  );
};

Dropdowns.propTypes = {
  keyName: string,
  label: string,
  value: any,
  multiSelect: bool,
  withInput: bool,
  onChange: func,
  disabled: bool,
  valueProps: string
};

export default Dropdowns;
