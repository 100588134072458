import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bool, func, object } from 'prop-types';
import { Form, Input, Button, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import { emailPattern } from '../../utils/constants';
import { onLogin } from '../../redux/actions/auth';

const { Title } = Typography;

const LoginForm = ({ isLoggedIn, onLogin }) => {
  const history = useHistory();

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/dashboard');
    }
  });

  const onFinish = values => {
    onLogin({...values, email: values.email.toLowerCase()});
  };

  return (
    <>
      <Title>Login</Title>
      <Form
        layout="vertical"
        name="login-form"
        className="login-form"
        onFinish={onFinish}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          name="email"
          rules={[
            { required: true, message: 'Email is required.' },
            {
              pattern: emailPattern,
              message: 'Please enter a valid email.'
            }
          ]}
          hasFeedback
        >
          <Input type="email" placeholder="Email" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Password is required' }]}
          hasFeedback
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Link to="/forgot-password">Forgot Password?</Link>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            <ArrowRightOutlined />
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

const mapStateToProps = state => ({
  isLoggedIn: state.auth.loggedIn
});

const mapDispatchToProps = dispatch => ({
  onLogin: payload => dispatch(onLogin(payload))
});

LoginForm.propTypes = {
  onLogin: func,
  isLoggedIn: bool,
  history: object
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
