import { resetPassword, updatePassword, changePassword } from '../../api/auth';
import connection from '../../utils/api';
import { checkTokenExpired, getUser } from '../../utils/checkLogin';
import {
  removeFromLocalStorage,
  setToLocalStorage
} from '../../utils/localStorage';
import { handleNotifications } from '../../utils/notifications';
import * as actionTypes from '../actionTypes';
import { hideLoading, showLoading } from './ui';
import { redirect } from '../../redux/actions/redirect';

const businessId = {
  prime: 'prime_id',
  'small-business': 'small_business_id',
  association: 'association_id'
};

const registerSuccess = payload => ({
  type: actionTypes.REGISTER_SUCCESS,
  payload
});
// const registerFail = () => ({ type: actionTypes.REGISTER_FAIL });

export const onRegister =
  ({
    firstName,
    lastName,
    email,
    companyName,
    password,
    accountType,
    phone,
    website
  }) =>
  dispatch => {
    dispatch(showLoading());
    let payload = {
      first_name: firstName,
      last_name: lastName,
      email,
      company_name: companyName,
      password,
      role: accountType,
      website,
      business_phone: phone
    };
    connection
      .post('register', payload)
      .then(({ data }) => {
        dispatch(hideLoading());
        if (data.success) {
          payload = {
            ...payload,
            ...data.data
          };
          dispatch(registerSuccess(payload));
          handleNotifications(
            'success',
            'Registration successful',
            data.message,
            8
          );
          dispatch(redirect('/'));
        } else {
          // dispatch(registerFail());
          handleNotifications('error', 'Registration Failed', data.message);
        }
      })
      .catch(error => {
        dispatch(hideLoading());
        // dispatch(registerFail());
        handleNotifications('error', 'Server Error', error.message);
      });
  };

const loginSuccess = user => ({
  type: actionTypes.LOGIN_SUCCESS,
  payload: user
});

const loginFail = message => ({
  type: actionTypes.LOGIN_FAIL,
  payload: message
});

export const fetchFeatures = () => dispatch => {
  connection
    .get(`features`)
    .then(({ data }) => {
      dispatch({ type: actionTypes.SET_FEATURES, payload: data });
    })
    .catch(err => {
      console.log(err);
    });
};

export const fetchPermissions = () => async dispatch => {
  await dispatch(fetchFeatures());
  connection
    .get(`me`)
    .then(({ data }) => {
      dispatch({
        type: actionTypes.GET_PERMISSIONS,
        payload: data.data && data.data.package_features
      });
    })
    .catch(err => {
      console.log(err);
    });
};

export const onLogin =
  ({ email, useremail, password }) =>
  dispatch => {
    dispatch(showLoading());
    dispatch(fetchFeatures());
    connection
      .post('login', { email, useremail, password })
      .then(({ data }) => {
        dispatch(hideLoading());
        if (data.success) {
          const user = {
            user_id: data.data.user_id,
            email: data.data.email,
            name: `${data.data.first_name} ${data.data.last_name}`,
            company_admin: data.data.company_admin,
            role: data.data.role,
            free_user: data.data.free_user,
            auth_token: data.data.auth_token,
            token_object: data.data.token_object,
            account_expiry_date: data.data.account_expiry_date,
            id: data.data.id,
            package_features: data.data.package_features
          };
          user['business_id'] = data['data'][businessId[user.role]];
          dispatch(loginSuccess(user));
          connection.defaults.headers.common['Authorization'] = user.auth_token;
          delete user.package_features;
          setToLocalStorage('user', user);
          handleNotifications('success', 'Logged in successfully.', '');
        } else {
          dispatch(loginFail(data.message));
          handleNotifications('error', 'Login Failed', data.message);
        }
      })
      .catch(error => {
        dispatch(hideLoading());
        // dispatch(loginFail());
      });
  };

export const onLoginFromLocal = () => dispatch => {
  const user = getUser();
  let validLogin = false;
  if (!user) {
    return validLogin;
  }
  dispatch(showLoading());
  if (user.auth_token && user.token_object) {
    if (checkTokenExpired(user.token_object.exp)) {
      handleNotifications(
        'error',
        'Login Expired',
        'Authentication token expired. Please login to continue...'
      );
      dispatch(onLogout());
    } else {
      dispatch(loginSuccess(user));
      setToLocalStorage('user', user);
      connection.defaults.headers.common['Authorization'] = user.auth_token;
      validLogin = true;
    }
  }
  dispatch(hideLoading());
  return validLogin;
};

const logout = () => ({ type: actionTypes.LOGOUT });
const resetDashboard = () => ({ type: actionTypes.RESET_DASHBOARD });

export const onLogout = () => dispatch => {
  dispatch(logout());
  dispatch(resetDashboard());
  connection.defaults.headers.common['Authorization'] = null;
  removeFromLocalStorage('user');
  removeFromLocalStorage('permissions');
  removeFromLocalStorage('allowed');
};

export const onResetPassword =
  ({ email }) =>
  dispatch => {
    dispatch(showLoading());
    resetPassword(email)
      .then(({ data }) => {
        dispatch(hideLoading());
        if (data?.['success']) {
          handleNotifications(
            'success',
            'Success',
            'Reset link sent, please check your mail.'
          );
        } else {
          handleNotifications('error', 'Failed', data?.['message']);
        }
      })
      .catch(error => {
        console.log(error);
        dispatch(hideLoading());
        handleNotifications('error', 'Error', error?.['message']);
      });
  };

export const updatePasswordAction = payload => dispatch => {
  dispatch(showLoading());
  updatePassword(payload)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data?.['success']) {
        handleNotifications(
          'success',
          'Success',
          'Password updated successfully.'
        );
      } else {
        handleNotifications('error', 'Failed', data?.['message']);
      }
    })
    .catch(error => {
      console.log(error);
      dispatch(hideLoading());
      handleNotifications('error', 'Error', error?.['message']);
    });
};

export const changePasswordAction = payload => dispatch => {
  dispatch(showLoading());
  changePassword(payload)
    .then(({ data }) => {
      dispatch(hideLoading());
      if (data?.['success']) {
        handleNotifications(
          'success',
          'Success',
          'Password updated successfully.'
        );
      } else {
        handleNotifications('error', 'Failed', data?.['message']);
      }
    })
    .catch(error => {
      console.log(error);
      dispatch(hideLoading());
      handleNotifications('error', 'Error', error?.['message']);
    });
};

export const updateAllowed = payload => ({
  type: actionTypes.UPDATE_ALLOWED,
  payload
});
