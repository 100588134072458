import { SET_ACTIVE_COMP } from '../actionTypes';
import { hideLoading, showLoading } from './ui';
import { getLookAheads } from '../../api/lookAhead';
import { getEvents } from '../../api/rfpRfq';
import { getEvents as getCalendarEvents } from '../../api/calendar';
import { getSmallBusinesses } from '../../api/smallBusiness';
import { getPrimes } from '../../api/primes';
import { handleNotifications } from '../../utils/notifications';

export const setActiveComponents = payload => ({
  type: SET_ACTIVE_COMP,
  payload: payload
});

export const fetchLookAhead =
  ({ currentTableProps, filters }) =>
  dispatch => {
    const query = {};
    if (currentTableProps?.pagination) {
      query['page'] = currentTableProps.pagination.current;
      query['limit'] = 20;
    }
    if (filters) {
      query['filters'] = filters;
    }

    dispatch(showLoading());
    getLookAheads(query)
      .then(({ data }) => {
        if (data['success']) {
          dispatch({ type: 'GET_DASHBOARD_LOOK_AHEAD', payload: data['data'] });
        } else {
          dispatch({ type: 'GET_DASHBOARD_LOOK_AHEAD', payload: [] });
          handleNotifications('error', 'Failed', data.message);
        }
        dispatch(hideLoading());
      })
      .catch(error => {
        dispatch(hideLoading());
        handleNotifications('error', 'Failed', error.message);
        console.log(error);
      });
  };

export const fetchRfpEvents =
  ({ currentTableProps, filters }) =>
  dispatch => {
    const query = {};
    if (currentTableProps && currentTableProps.pagination) {
      query['page'] = currentTableProps.pagination.current;
      query['limit'] = 20;
    }
    if (filters) {
      query['filters'] = filters;
    }

    getEvents(query)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: 'GET_DASHBOARD_RFP_RFQ', payload: data.data });
        } else {
          dispatch({ type: 'GET_DASHBOARD_RFP_RFQ', payload: [] });
          handleNotifications('error', 'Failed', data['message']);
        }
      })
      .catch(error => {
        handleNotifications('error', 'Failed', error['message']);
        console.log(error);
      });
  };

export const fetchCalendarEvents =
  ({ currentTableProps, range }) =>
  dispatch => {
    const query = {};
    if (currentTableProps) {
      query['page'] = currentTableProps.pagination.current;
      query['limit'] = 20;
    }
    if (range) {
      query['range'] = range;
    }

    getCalendarEvents(query)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: 'GET_DASHBOARD_CALENDAR', payload: data.data });
        } else {
          dispatch({ type: 'GET_DASHBOARD_CALENDAR', payload: [] });
          handleNotifications('error', 'Unable to fetch.', data.message);
        }
      })
      .catch(error => {
        handleNotifications('error', 'Error', error.message);
        console.log(error);
      });
  };

export const fetchSmallBusinesses =
  ({ currentTableProps, filters }) =>
  dispatch => {
    const query = {};
    if (currentTableProps?.pagination) {
      query['page'] = currentTableProps.pagination.current;
      query['limit'] = 20;
    }

    query['filters'] = [{ for_dashboard: true }];

    dispatch(showLoading());
    getSmallBusinesses(query)
      .then(({ data }) => {
        if (data.success) {
          dispatch({
            type: 'GET_DASHBOARD_SMALL_BUSINESS',
            payload: data.data
          });
        } else {
          dispatch({ type: 'GET_DASHBOARD_SMALL_BUSINESS', payload: [] });
          handleNotifications('error', 'Failed', data.message);
        }
        dispatch(hideLoading());
      })
      .catch(error => {
        dispatch(hideLoading());
        console.log(error);
        handleNotifications('error', 'Failed', error.message);
      });
  };

export const fetchPrimes =
  ({ currentTableProps, filters }) =>
  dispatch => {
    const query = {};
    if (currentTableProps?.pagination) {
      query['page'] = currentTableProps.pagination.current;
      query['limit'] = 20;
    }
    if (filters) {
      query['filters'] = filters;
    }

    getPrimes(query)
      .then(({ data }) => {
        if (data['success']) {
          dispatch({ type: 'GET_DASHBOARD_PRIMES', payload: data.data });
        } else {
          dispatch({ type: 'GET_DASHBOARD_PRIMES', payload: [] });
          handleNotifications('error', 'Failed', data['message']);
        }
      })
      .catch(error => {
        handleNotifications('error', 'Failed', error['message']);
        console.log(error);
      });
  };
