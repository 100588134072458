import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';
import { Space } from 'antd';
import { clearRedirect } from '../../redux/actions/redirect';
import List from './List';

const Users = ({ redirect, history, onClearRedirect }) => {
  useEffect(() => {
    if (redirect.path) {
      history.push(redirect.path);
      onClearRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect.path]);

  return (
    <div className="container">
      <Space
        direction="horizontal"
        style={{
          width: '100%',
          padding: '8px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '10px'
        }}
      >
        <h3 className="section-headers">Users Database</h3>
      </Space>
      <div className="divider" style={{ marginBottom: 0 }}></div>
      <List />
    </div>
  );
};

const mapStateToProps = state => ({
  redirect: state.redirect,
  advanceSearch: state.search.activeAdvanceSearch
});

const mapDispatchToProps = dispatch => ({
  onClearRedirect: () => dispatch(clearRedirect())
});

Users.propTypes = {
  redirect: object,
  history: object,
  onClearRedirect: func
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
