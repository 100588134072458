export const emailPattern = new RegExp(
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/
);
export const websitePattern = new RegExp(
  '^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?$',
  'i'
);
// export const zipcodePattern = new RegExp()

export const fileFormats = {
  logo: '.png, .jpeg, .jpg',
  address: '.doc, .docx, .pdf, .png, .jpeg, .jpg',
  revenues: '.doc, .docx, .pdf, .png, .jpeg, .jpg',
  number_of_employees: '.doc, .docx, .pdf, .png, .jpeg, .jpg',
  licenses: '.pdf, .png, .jpeg, .jpg',
  certifications: '.pdf, .png, .jpeg, .jpg',
  recommendations: '.doc, .docx, .pdf, .png, .jpeg, .jpg',
  soq: '.doc, .docx, .pdf, .png, .jpeg, .jpg',
  team_photo: '.png, .jpeg, .jpg',
  import_events: '.csv',
  rfp_event_file: '.csv',
  look_ahead_file: '.csv',
  email_attachment: '.csv, .xlsx, .doc, .docx, .pdf, .png, .jpeg, .jpg'
};

export const rfpRfqTypes = {
  rfp: 'RFP',
  rfq: 'RFQ',
  preProposal: 'Pre-Proposal'
};

export const OldNaicsCodes = [
  {
    value: '221112',
    label: 'Fossil Fuel Electric Power Generation'
  },
  {
    value: '221113',
    label: 'Nuclear Electric Power Generation'
  },
  {
    value: '221119',
    label: 'Other Electric Power Generation'
  },
  {
    value: '221121',
    label: 'Electric Bulk Power Transmission and Control'
  },
  {
    value: '221122',
    label: 'Electric Power Distribution'
  },
  {
    value: '221210',
    label: 'Natural Gas Distribution'
  },
  {
    value: '221310',
    label: 'Water Supply and Irrigation Systems'
  },
  {
    value: '221320',
    label: 'Sewage Treatment Facilities'
  },
  {
    value: '221330',
    label: 'Steam and Air-Conditioning Supply'
  },
  {
    value: '236115',
    label: 'New Single-Family Housing Construction (except For-Sale Builders)'
  },
  {
    value: '236116',
    label: 'New Multifamily Housing Construction (except For-Sale Builders)'
  },
  { value: '236117', label: 'New Housing For-Sale Builders' },
  { value: '236118', label: 'Residential Remodelers' },
  { value: '236210', label: 'Industrial Building Construction' },
  {
    value: '236220',
    label: 'Commercial and Institutional Building Construction'
  },
  {
    value: '237110',
    label: 'Water and Sewer Line and Related Structures Construction'
  },
  {
    value: '237120',
    label: 'Oil and Gas Pipeline and Related Structures Construction'
  },
  {
    value: '237130',
    label: 'Power and Communication Line and Related Structures Construction'
  },
  { value: '237210', label: 'Land Subdivision' },
  { value: '237310', label: 'Highway, Street, and Bridge Construction' },
  { value: '237990', label: 'Other Heavy and Civil Engineering Construction' },
  {
    value: '238110',
    label: 'Poured Concrete Foundation and Structure Contractors'
  },
  {
    value: '238120',
    label: 'Structural Steel and Precast Concrete Contractors'
  },
  { value: '238130', label: 'Framing Contractors' },
  { value: '238140', label: 'Masonry Contractors' },
  { value: '238150', label: 'Glass and Glazing Contractors' },
  { value: '238160', label: 'Roofing Contractors' },
  { value: '238170', label: 'Siding Contractors' },
  {
    value: '238190',
    label: 'Other Foundation, Structure, and Building Exterior Contractors'
  },
  {
    value: '238210',
    label: 'Electrical Contractors and Other Wiring Installation Contractors'
  },
  {
    value: '238220',
    label: 'Plumbing, Heating, and Air-Conditioning Contractors'
  },
  { value: '238290', label: 'Other Building Equipment Contractors' },
  { value: '238310', label: 'Drywall and Insulation Contractors' },
  { value: '238320', label: 'Painting and Wall Covering Contractors' },
  { value: '238330', label: 'Flooring Contractors' },
  { value: '238340', label: 'Tile and Terrazzo Contractors' },
  { value: '238350', label: 'Finish Carpentry Contractors' },
  { value: '238390', label: 'Other Building Finishing Contractors' },
  { value: '238910', label: 'Site Preparation Contractors' },
  { value: '238990', label: 'All Other Specialty Trade Contractors' },
  { value: '311111', label: 'Dog and Cat Food Manufacturing' },
  { value: '311119', label: 'Other Animal Food Manufacturing' },
  { value: '311211', label: 'Flour Milling' },
  { value: '311212', label: 'Rice Milling' },
  { value: '311213', label: 'Malt Manufacturing' },
  { value: '311221', label: 'Wet Corn Milling and Starch Manufacturing' },
  { value: '311224', label: 'Soybean and Other Oilseed Processing' },
  { value: '311225', label: 'Fats and Oils Refining and Blending' },
  { value: '311230', label: 'Breakfast Cereal Manufacturing' },
  { value: '311313', label: 'Beet Sugar Manufacturing' },
  { value: '311314', label: 'Cane Sugar Manufacturing' },
  { value: '311340', label: 'Nonchocolate Confectionery Manufacturing' },
  {
    value: '311351',
    label: 'Chocolate and Confectionery Manufacturing from Cacao Beans'
  },
  {
    value: '311352',
    label: 'Confectionery Manufacturing from Purchased Chocolate'
  },
  {
    value: '311411',
    label: 'Frozen Fruit, Juice, and Vegetable Manufacturing'
  },
  { value: '311412', label: 'Frozen Specialty Food Manufacturing' },
  { value: '311421', label: 'Fruit and Vegetable Canning' },
  { value: '311422', label: 'Specialty Canning' },
  { value: '311423', label: 'Dried and Dehydrated Food Manufacturing' },
  { value: '311511', label: 'Fluid Milk Manufacturing' },
  { value: '311512', label: 'Creamery Butter Manufacturing' },
  { value: '311513', label: 'Cheese Manufacturing' },
  {
    value: '311514',
    label: 'Dry, Condensed, and Evaporated Dairy Product Manufacturing'
  },
  { value: '311520', label: 'Ice Cream and Frozen Dessert Manufacturing' },
  { value: '311611', label: 'Animal (except Poultry) Slaughtering' },
  { value: '311612', label: 'Meat Processed from Carcasses' },
  { value: '311613', label: 'Rendering and Meat Byproduct Processing' },
  { value: '311615', label: 'Poultry Processing' },
  { value: '311710', label: 'Seafood Product Preparation and Packaging' },
  { value: '311811', label: 'Retail Bakeries' },
  { value: '311812', label: 'Commercial Bakeries' },
  {
    value: '311813',
    label: 'Frozen Cakes, Pies, and Other Pastries Manufacturing'
  },
  { value: '311821', label: 'Cookie and Cracker Manufacturing' },
  {
    value: '311824',
    label:
      'Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour'
  },
  { value: '311830', label: 'Tortilla Manufacturing' },
  { value: '311911', label: 'Roasted Nuts and Peanut Butter Manufacturing' },
  { value: '311919', label: 'Other Snack Food Manufacturing' },
  { value: '311920', label: 'Coffee and Tea Manufacturing' },
  { value: '311930', label: 'Flavoring Syrup and Concentrate Manufacturing' },
  {
    value: '311941',
    label: 'Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing'
  },
  { value: '311942', label: 'Spice and Extract Manufacturing' },
  { value: '311991', label: 'Perishable Prepared Food Manufacturing' },
  { value: '311999', label: 'All Other Miscellaneous Food Manufacturing' },
  { value: '312111', label: 'Soft Drink Manufacturing' },
  { value: '312112', label: 'Bottled Water Manufacturing' },
  { value: '312113', label: 'Ice Manufacturing' },
  { value: '312120', label: 'Breweries' },
  { value: '312130', label: 'Wineries' },
  { value: '312140', label: 'Distilleries' },
  { value: '312230', label: 'Tobacco Manufacturing' },
  { value: '313110', label: 'Fiber, Yarn, and Thread Mills' },
  { value: '313210', label: 'Broadwoven Fabric Mills' },
  {
    value: '313220',
    label: 'Narrow Fabric Mills and Schiffli Machine Embroidery'
  },
  { value: '313230', label: 'Nonwoven Fabric Mills' },
  { value: '313240', label: 'Knit Fabric Mills' },
  { value: '313310', label: 'Textile and Fabric Finishing Mills' },
  { value: '313320', label: 'Fabric Coating Mills' },
  { value: '314110', label: 'Carpet and Rug Mills' },
  { value: '314120', label: 'Curtain and Linen Mills' },
  { value: '314910', label: 'Textile Bag and Canvas Mills' },
  {
    value: '314994',
    label: 'Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills'
  },
  { value: '314999', label: 'All Other Miscellaneous Textile Product Mills' },
  { value: '315120', label: 'Apparel Knitting Mills' },
  { value: '315210', label: 'Cut and Sew Apparel Contractors' },
  {
    value: '315250',
    label: 'Cut and Sew Apparel Manufacturing (except Contractors)'
  },
  {
    value: '315990',
    label: 'Apparel Accessories and Other Apparel Manufacturing'
  },
  { value: '316110', label: 'Leather and Hide Tanning and Finishing' },
  { value: '316210', label: 'Footwear Manufacturing' },
  { value: '316990', label: 'Other Leather and Allied Product Manufacturing' },
  { value: '321113', label: 'Sawmills' },
  { value: '321114', label: 'Wood Preservation' },
  { value: '321211', label: 'Hardwood Veneer and Plywood Manufacturing' },
  { value: '321212', label: 'Softwood Veneer and Plywood Manufacturing' },
  { value: '321215', label: 'Engineered Wood Member Manufacturing' },
  { value: '321219', label: 'Reconstituted Wood Product Manufacturing' },
  { value: '321911', label: 'Wood Window and Door Manufacturing' },
  { value: '321912', label: 'Cut Stock, Resawing Lumber, and Planing' },
  { value: '321918', label: 'Other Millwork (including Flooring)' },
  { value: '321920', label: 'Wood Container and Pallet Manufacturing' },
  { value: '321991', label: 'Manufactured Home (Mobile Home) Manufacturing' },
  { value: '321992', label: 'Prefabricated Wood Building Manufacturing' },
  {
    value: '321999',
    label: 'All Other Miscellaneous Wood Product Manufacturing'
  },
  { value: '322110', label: 'Pulp Mills' },
  { value: '322120', label: 'Paper Mills' },
  { value: '322130', label: 'Paperboard Mills' },
  { value: '322211', label: 'Corrugated and Solid Fiber Box Manufacturing' },
  { value: '322212', label: 'Folding Paperboard Box Manufacturing' },
  { value: '322219', label: 'Other Paperboard Container Manufacturing' },
  {
    value: '322220',
    label: 'Paper Bag and Coated and Treated Paper Manufacturing'
  },
  { value: '322230', label: 'Stationery Product Manufacturing' },
  { value: '322291', label: 'Sanitary Paper Product Manufacturing' },
  { value: '322299', label: 'All Other Converted Paper Product Manufacturing' },
  { value: '323111', label: 'Commercial Printing (except Screen and Books)' },
  { value: '323113', label: 'Commercial Screen Printing' },
  { value: '323117', label: 'Books Printing' },
  { value: '323120', label: 'Support Activities for Printing' },
  { value: '324110', label: 'Petroleum Refineries' },
  { value: '324121', label: 'Asphalt Paving Mixture and Block Manufacturing' },
  {
    value: '324122',
    label: 'Asphalt Shingle and Coating Materials Manufacturing'
  },
  {
    value: '324191',
    label: 'Petroleum Lubricating Oil and Grease Manufacturing'
  },
  {
    value: '324199',
    label: 'All Other Petroleum and Coal Products Manufacturing'
  },
  { value: '325110', label: 'Petrochemical Manufacturing' },
  { value: '325120', label: 'Industrial Gas Manufacturing' },
  { value: '325130', label: 'Synthetic Dye and Pigment Manufacturing' },
  { value: '325180', label: 'Other Basic Inorganic Chemical Manufacturing' },
  { value: '325193', label: 'Ethyl Alcohol Manufacturing' },
  {
    value: '325194',
    label: 'Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing'
  },
  { value: '325199', label: 'All Other Basic Organic Chemical Manufacturing' },
  { value: '325211', label: 'Plastics Material and Resin Manufacturing' },
  { value: '325212', label: 'Synthetic Rubber Manufacturing' },
  {
    value: '325220',
    label: 'Artificial and Synthetic Fibers and Filaments Manufacturing'
  },
  { value: '325311', label: 'Nitrogenous Fertilizer Manufacturing' },
  { value: '325312', label: 'Phosphatic Fertilizer Manufacturing' },
  { value: '325314', label: 'Fertilizer (Mixing Only) Manufacturing' },
  { value: '325315', label: 'Compost Manufacturing' },
  {
    value: '325320',
    label: 'Pesticide and Other Agricultural Chemical Manufacturing'
  },
  { value: '325411', label: 'Medicinal and Botanical Manufacturing' },
  { value: '325412', label: 'Pharmaceutical Preparation Manufacturing' },
  { value: '325413', label: 'In-Vitro Diagnostic Substance Manufacturing' },
  {
    value: '325414',
    label: 'Biological Product (except Diagnostic) Manufacturing'
  },
  { value: '325510', label: 'Paint and Coating Manufacturing' },
  { value: '325520', label: 'Adhesive Manufacturing' },
  { value: '325611', label: 'Soap and Other Detergent Manufacturing' },
  { value: '325612', label: 'Polish and Other Sanitation Good Manufacturing' },
  { value: '325613', label: 'Surface Active Agent Manufacturing' },
  { value: '325620', label: 'Toilet Preparation Manufacturing' },
  { value: '325910', label: 'Printing Ink Manufacturing' },
  { value: '325920', label: 'Explosives Manufacturing' },
  { value: '325991', label: 'Custom Compounding of Purchased Resins' },
  {
    value: '325992',
    label:
      'Photographic Film, Paper, Plate, Chemical, and Copy Toner Manufacturing'
  },
  {
    value: '325998',
    label:
      'All Other Miscellaneous Chemical Product and Preparation Manufacturing'
  },
  { value: '326111', label: 'Plastics Bag and Pouch Manufacturing' },
  {
    value: '326112',
    label:
      'Plastics Packaging Film and Sheet (including Laminated) Manufacturing'
  },
  {
    value: '326113',
    label:
      'Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing'
  },
  {
    value: '326121',
    label: 'Unlaminated Plastics Profile Shape Manufacturing'
  },
  { value: '326122', label: 'Plastics Pipe and Pipe Fitting Manufacturing' },
  {
    value: '326130',
    label:
      'Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing'
  },
  { value: '326140', label: 'Polystyrene Foam Product Manufacturing' },
  {
    value: '326150',
    label: 'Urethane and Other Foam Product (except Polystyrene) Manufacturing'
  },
  { value: '326160', label: 'Plastics Bottle Manufacturing' },
  { value: '326191', label: 'Plastics Plumbing Fixture Manufacturing' },
  { value: '326199', label: 'All Other Plastics Product Manufacturing' },
  { value: '326211', label: 'Tire Manufacturing (except Retreading)' },
  { value: '326212', label: 'Tire Retreading' },
  {
    value: '326220',
    label: 'Rubber and Plastics Hoses and Belting Manufacturing'
  },
  { value: '326291', label: 'Rubber Product Manufacturing for Mechanical Use' },
  { value: '326299', label: 'All Other Rubber Product Manufacturing' },
  {
    value: '327110',
    label: 'Pottery, Ceramics, and Plumbing Fixture Manufacturing'
  },
  {
    value: '327120',
    label: 'Clay Building Material and Refractories Manufacturing'
  },
  { value: '327211', label: 'Flat Glass Manufacturing' },
  {
    value: '327212',
    label: 'Other Pressed and Blown Glass and Glassware Manufacturing'
  },
  { value: '327213', label: 'Glass Container Manufacturing' },
  {
    value: '327215',
    label: 'Glass Product Manufacturing Made of Purchased Glass'
  },
  { value: '327310', label: 'Cement Manufacturing' },
  { value: '327320', label: 'Ready-Mix Concrete Manufacturing' },
  { value: '327331', label: 'Concrete Block and Brick Manufacturing' },
  { value: '327332', label: 'Concrete Pipe Manufacturing' },
  { value: '327390', label: 'Other Concrete Product Manufacturing' },
  { value: '327410', label: 'Lime Manufacturing' },
  { value: '327420', label: 'Gypsum Product Manufacturing' },
  { value: '327910', label: 'Abrasive Product Manufacturing' },
  { value: '327991', label: 'Cut Stone and Stone Product Manufacturing' },
  {
    value: '327992',
    label: 'Ground or Treated Mineral and Earth Manufacturing'
  },
  { value: '327993', label: 'Mineral Wool Manufacturing' },
  {
    value: '327999',
    label: 'All Other Miscellaneous Nonmetallic Mineral Product Manufacturing'
  },
  {
    value: '331110',
    label: 'Iron and Steel Mills and Ferroalloy Manufacturing'
  },
  {
    value: '331210',
    label: 'Iron and Steel Pipe and Tube Manufacturing from Purchased Steel'
  },
  { value: '331221', label: 'Rolled Steel Shape Manufacturing' },
  { value: '331222', label: 'Steel Wire Drawing' },
  {
    value: '331313',
    label: 'Alumina Refining and Primary Aluminum Production'
  },
  { value: '331314', label: 'Secondary Smelting and Alloying of Aluminum' },
  { value: '331315', label: 'Aluminum Sheet, Plate, and Foil Manufacturing' },
  { value: '331318', label: 'Other Aluminum Rolling, Drawing, and Extruding' },
  {
    value: '331410',
    label: 'Nonferrous Metal (except Aluminum) Smelting and Refining'
  },
  {
    value: '331420',
    label: 'Copper Rolling, Drawing, Extruding, and Alloying'
  },
  {
    value: '331491',
    label:
      'Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding'
  },
  {
    value: '331492',
    label:
      'Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum)'
  },
  { value: '331511', label: 'Iron Foundries' },
  { value: '331512', label: 'Steel Investment Foundries' },
  { value: '331513', label: 'Steel Foundries (except Investment)' },
  { value: '331523', label: 'Nonferrous Metal Die-Casting Foundries' },
  { value: '331524', label: 'Aluminum Foundries (except Die-Casting)' },
  {
    value: '331529',
    label: 'Other Nonferrous Metal Foundries (except Die-Casting)'
  },
  { value: '332111', label: 'Iron and Steel Forging' },
  { value: '332112', label: 'Nonferrous Forging' },
  { value: '332114', label: 'Custom Roll Forming' },
  { value: '332117', label: 'Powder Metallurgy Part Manufacturing' },
  {
    value: '332119',
    label: 'Metal Crown, Closure, and Other Metal Stamping (except Automotive)'
  },
  {
    value: '332215',
    label:
      'Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing'
  },
  { value: '332216', label: 'Saw Blade and Handtool Manufacturing' },
  {
    value: '332311',
    label: 'Prefabricated Metal Building and Component Manufacturing'
  },
  { value: '332312', label: 'Fabricated Structural Metal Manufacturing' },
  { value: '332313', label: 'Plate Work Manufacturing' },
  { value: '332321', label: 'Metal Window and Door Manufacturing' },
  { value: '332322', label: 'Sheet Metal Work Manufacturing' },
  {
    value: '332323',
    label: 'Ornamental and Architectural Metal Work Manufacturing'
  },
  { value: '332410', label: 'Power Boiler and Heat Exchanger Manufacturing' },
  { value: '332420', label: 'Metal Tank (Heavy Gauge) Manufacturing' },
  { value: '332431', label: 'Metal Can Manufacturing' },
  { value: '332439', label: 'Other Metal Container Manufacturing' },
  { value: '332510', label: 'Hardware Manufacturing' },
  { value: '332613', label: 'Spring Manufacturing' },
  { value: '332618', label: 'Other Fabricated Wire Product Manufacturing' },
  { value: '332710', label: 'Machine Shops' },
  { value: '332721', label: 'Precision Turned Product Manufacturing' },
  {
    value: '332722',
    label: 'Bolt, Nut, Screw, Rivet, and Washer Manufacturing'
  },
  { value: '332811', label: 'Metal Heat Treating' },
  {
    value: '332812',
    label:
      'Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers'
  },
  {
    value: '332813',
    label: 'Electroplating, Plating, Polishing, Anodizing, and Coloring'
  },
  { value: '332911', label: 'Industrial Valve Manufacturing' },
  {
    value: '332912',
    label: 'Fluid Power Valve and Hose Fitting Manufacturing'
  },
  { value: '332913', label: 'Plumbing Fixture Fitting and Trim Manufacturing' },
  {
    value: '332919',
    label: 'Other Metal Valve and Pipe Fitting Manufacturing'
  },
  { value: '332991', label: 'Ball and Roller Bearing Manufacturing' },
  { value: '332992', label: 'Small Arms Ammunition Manufacturing' },
  { value: '332993', label: 'Ammunition (except Small Arms) Manufacturing' },
  {
    value: '332994',
    label: 'Small Arms, Ordnance, and Ordnance Accessories Manufacturing'
  },
  { value: '332996', label: 'Fabricated Pipe and Pipe Fitting Manufacturing' },
  {
    value: '332999',
    label: 'All Other Miscellaneous Fabricated Metal Product Manufacturing'
  },
  { value: '333111', label: 'Farm Machinery and Equipment Manufacturing' },
  {
    value: '333112',
    label:
      'Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing'
  },
  { value: '333120', label: 'Construction Machinery Manufacturing' },
  { value: '333131', label: 'Mining Machinery and Equipment Manufacturing' },
  {
    value: '333132',
    label: 'Oil and Gas Field Machinery and Equipment Manufacturing'
  },
  { value: '333241', label: 'Food Product Machinery Manufacturing' },
  { value: '333242', label: 'Semiconductor Machinery Manufacturing' },
  {
    value: '333243',
    label: 'Sawmill, Woodworking, and Paper Machinery Manufacturing'
  },
  { value: '333248', label: 'All Other Industrial Machinery Manufacturing' },
  {
    value: '333310',
    label: 'Commercial and Service Industry Machinery Manufacturing'
  },
  {
    value: '333413',
    label:
      'Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing'
  },
  {
    value: '333414',
    label: 'Heating Equipment (except Warm Air Furnaces) Manufacturing'
  },
  {
    value: '333415',
    label:
      'Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing'
  },
  { value: '333511', label: 'Industrial Mold Manufacturing' },
  {
    value: '333514',
    label: 'Special Die and Tool, Die Set, Jig, and Fixture Manufacturing'
  },
  {
    value: '333515',
    label: 'Cutting Tool and Machine Tool Accessory Manufacturing'
  },
  { value: '333517', label: 'Machine Tool Manufacturing' },
  {
    value: '333519',
    label: 'Rolling Mill and Other Metalworking Machinery Manufacturing'
  },
  {
    value: '333611',
    label: 'Turbine and Turbine Generator Set Units Manufacturing'
  },
  {
    value: '333612',
    label: 'Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing'
  },
  {
    value: '333613',
    label: 'Mechanical Power Transmission Equipment Manufacturing'
  },
  { value: '333618', label: 'Other Engine Equipment Manufacturing' },
  { value: '333912', label: 'Air and Gas Compressor Manufacturing' },
  {
    value: '333914',
    label: 'Measuring, Dispensing, and Other Pumping Equipment Manufacturing'
  },
  { value: '333921', label: 'Elevator and Moving Stairway Manufacturing' },
  { value: '333922', label: 'Conveyor and Conveying Equipment Manufacturing' },
  {
    value: '333923',
    label: 'Overhead Traveling Crane, Hoist, and Monorail System Manufacturing'
  },
  {
    value: '333924',
    label:
      'Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing'
  },
  { value: '333991', label: 'Power-Driven Handtool Manufacturing' },
  { value: '333992', label: 'Welding and Soldering Equipment Manufacturing' },
  { value: '333993', label: 'Packaging Machinery Manufacturing' },
  {
    value: '333994',
    label: 'Industrial Process Furnace and Oven Manufacturing'
  },
  { value: '333995', label: 'Fluid Power Cylinder and Actuator Manufacturing' },
  { value: '333996', label: 'Fluid Power Pump and Motor Manufacturing' },
  {
    value: '333998',
    label: 'All Other Miscellaneous General Purpose Machinery Manufacturing'
  },
  { value: '334111', label: 'Electronic Computer Manufacturing' },
  { value: '334112', label: 'Computer Storage Device Manufacturing' },
  {
    value: '334118',
    label:
      'Computer Terminal and Other Computer Peripheral Equipment Manufacturing'
  },
  { value: '334210', label: 'Telephone Apparatus Manufacturing' },
  {
    value: '334220',
    label:
      'Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing'
  },
  { value: '334290', label: 'Other Communications Equipment Manufacturing' },
  { value: '334310', label: 'Audio and Video Equipment Manufacturing' },
  { value: '334412', label: 'Bare Printed Circuit Board Manufacturing' },
  { value: '334413', label: 'Semiconductor and Related Device Manufacturing' },
  {
    value: '334416',
    label:
      'Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing'
  },
  { value: '334417', label: 'Electronic Connector Manufacturing' },
  {
    value: '334418',
    label: 'Printed Circuit Assembly (Electronic Assembly) Manufacturing'
  },
  { value: '334419', label: 'Other Electronic Component Manufacturing' },
  {
    value: '334510',
    label: 'Electromedical and Electrotherapeutic Apparatus Manufacturing'
  },
  {
    value: '334511',
    label:
      'Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing'
  },
  {
    value: '334512',
    label:
      'Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use'
  },
  {
    value: '334513',
    label:
      'Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables'
  },
  {
    value: '334514',
    label: 'Totalizing Fluid Meter and Counting Device Manufacturing'
  },
  {
    value: '334515',
    label:
      'Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals'
  },
  { value: '334516', label: 'Analytical Laboratory Instrument Manufacturing' },
  { value: '334517', label: 'Irradiation Apparatus Manufacturing' },
  {
    value: '334519',
    label: 'Other Measuring and Controlling Device Manufacturing'
  },
  {
    value: '334610',
    label: 'Manufacturing and Reproducing Magnetic and Optical Media'
  },
  {
    value: '335131',
    label: 'Residential Electric Lighting Fixture Manufacturing'
  },
  {
    value: '335132',
    label:
      'Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing'
  },
  {
    value: '335139',
    label: 'Electric Lamp Bulb and Other Lighting Equipment Manufacturing'
  },
  { value: '335210', label: 'Small Electrical Appliance Manufacturing' },
  { value: '335220', label: 'Major Household Appliance Manufacturing' },
  {
    value: '335311',
    label: 'Power, Distribution, and Specialty Transformer Manufacturing'
  },
  { value: '335312', label: 'Motor and Generator Manufacturing' },
  {
    value: '335313',
    label: 'Switchgear and Switchboard Apparatus Manufacturing'
  },
  { value: '335314', label: 'Relay and Industrial Control Manufacturing' },
  { value: '335910', label: 'Battery Manufacturing' },
  { value: '335921', label: 'Fiber Optic Cable Manufacturing' },
  {
    value: '335929',
    label: 'Other Communication and Energy Wire Manufacturing'
  },
  { value: '335931', label: 'Current-Carrying Wiring Device Manufacturing' },
  { value: '335932', label: 'Noncurrent-Carrying Wiring Device Manufacturing' },
  { value: '335991', label: 'Carbon and Graphite Product Manufacturing' },
  {
    value: '335999',
    label:
      'All Other Miscellaneous Electrical Equipment and Component Manufacturing'
  },
  {
    value: '336110',
    label: 'Automobile and Light Duty Motor Vehicle Manufacturing'
  },
  { value: '336120', label: 'Heavy Duty Truck Manufacturing' },
  { value: '336211', label: 'Motor Vehicle Body Manufacturing' },
  { value: '336212', label: 'Truck Trailer Manufacturing' },
  { value: '336213', label: 'Motor Home Manufacturing' },
  { value: '336214', label: 'Travel Trailer and Camper Manufacturing' },
  {
    value: '336310',
    label: 'Motor Vehicle Gasoline Engine and Engine Parts Manufacturing'
  },
  {
    value: '336320',
    label: 'Motor Vehicle Electrical and Electronic Equipment Manufacturing'
  },
  {
    value: '336330',
    label:
      'Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing'
  },
  { value: '336340', label: 'Motor Vehicle Brake System Manufacturing' },
  {
    value: '336350',
    label: 'Motor Vehicle Transmission and Power Train Parts Manufacturing'
  },
  {
    value: '336360',
    label: 'Motor Vehicle Seating and Interior Trim Manufacturing'
  },
  { value: '336370', label: 'Motor Vehicle Metal Stamping' },
  { value: '336390', label: 'Other Motor Vehicle Parts Manufacturing' },
  { value: '336411', label: 'Aircraft Manufacturing' },
  { value: '336412', label: 'Aircraft Engine and Engine Parts Manufacturing' },
  {
    value: '336413',
    label: 'Other Aircraft Parts and Auxiliary Equipment Manufacturing'
  },
  { value: '336414', label: 'Guided Missile and Space Vehicle Manufacturing' },
  {
    value: '336415',
    label:
      'Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing'
  },
  {
    value: '336419',
    label:
      'Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing'
  },
  { value: '336510', label: 'Railroad Rolling Stock Manufacturing' },
  { value: '336611', label: 'Ship Building and Repairing' },
  { value: '336612', label: 'Boat Building' },
  { value: '336991', label: 'Motorcycle, Bicycle, and Parts Manufacturing' },
  {
    value: '336992',
    label: 'Military Armored Vehicle, Tank, and Tank Component Manufacturing'
  },
  {
    value: '336999',
    label: 'All Other Transportation Equipment Manufacturing'
  },
  {
    value: '337110',
    label: 'Wood Kitchen Cabinet and Countertop Manufacturing'
  },
  { value: '337121', label: 'Upholstered Household Furniture Manufacturing' },
  {
    value: '337122',
    label: 'Nonupholstered Wood Household Furniture Manufacturing'
  },
  {
    value: '337126',
    label: 'Household Furniture (except Wood and Upholstered) Manufacturing'
  },
  { value: '337127', label: 'Institutional Furniture Manufacturing' },
  { value: '337211', label: 'Wood Office Furniture Manufacturing' },
  {
    value: '337212',
    label: 'Custom Architectural Woodwork and Millwork Manufacturing'
  },
  { value: '337214', label: 'Office Furniture (except Wood) Manufacturing' },
  {
    value: '337215',
    label: 'Showcase, Partition, Shelving, and Locker Manufacturing'
  },
  { value: '337910', label: 'Mattress Manufacturing' },
  { value: '337920', label: 'Blind and Shade Manufacturing' },
  { value: '339112', label: 'Surgical and Medical Instrument Manufacturing' },
  { value: '339113', label: 'Surgical Appliance and Supplies Manufacturing' },
  { value: '339114', label: 'Dental Equipment and Supplies Manufacturing' },
  { value: '339115', label: 'Ophthalmic Goods Manufacturing' },
  { value: '339116', label: 'Dental Laboratories' },
  { value: '339910', label: 'Jewelry and Silverware Manufacturing' },
  { value: '339920', label: 'Sporting and Athletic Goods Manufacturing' },
  { value: '339930', label: 'Doll, Toy, and Game Manufacturing' },
  { value: '339940', label: 'Office Supplies (except Paper) Manufacturing' },
  { value: '339950', label: 'Sign Manufacturing' },
  {
    value: '339991',
    label: 'Gasket, Packing, and Sealing Device Manufacturing'
  },
  { value: '339992', label: 'Musical Instrument Manufacturing' },
  { value: '339993', label: 'Fastener, Button, Needle, and Pin Manufacturing' },
  { value: '339994', label: 'Broom, Brush, and Mop Manufacturing' },
  { value: '339995', label: 'Burial Casket Manufacturing' },
  { value: '339999', label: 'All Other Miscellaneous Manufacturing' },
  {
    value: '423110',
    label: 'Automobile and Other Motor Vehicle Merchant Wholesalers'
  },
  {
    value: '423120',
    label: 'Motor Vehicle Supplies and New Parts Merchant Wholesalers'
  },
  { value: '423130', label: 'Tire and Tube Merchant Wholesalers' },
  { value: '423140', label: 'Motor Vehicle Parts (Used) Merchant Wholesalers' },
  { value: '423210', label: 'Furniture Merchant Wholesalers' },
  { value: '423220', label: 'Home Furnishing Merchant Wholesalers' },
  {
    value: '423310',
    label: 'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers'
  },
  {
    value: '423320',
    label:
      'Brick, Stone, and Related Construction Material Merchant Wholesalers'
  },
  {
    value: '423330',
    label: 'Roofing, Siding, and Insulation Material Merchant Wholesalers'
  },
  {
    value: '423390',
    label: 'Other Construction Material Merchant Wholesalers'
  },
  {
    value: '423410',
    label: 'Photographic Equipment and Supplies Merchant Wholesalers'
  },
  { value: '423420', label: 'Office Equipment Merchant Wholesalers' },
  {
    value: '423430',
    label:
      'Computer and Computer Peripheral Equipment and Software Merchant Wholesalers'
  },
  { value: '423440', label: 'Other Commercial Equipment Merchant Wholesalers' },
  {
    value: '423450',
    label:
      'Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers'
  },
  { value: '423460', label: 'Ophthalmic Goods Merchant Wholesalers' },
  {
    value: '423490',
    label: 'Other Professional Equipment and Supplies Merchant Wholesalers'
  },
  {
    value: '423510',
    label: 'Metal Service Centers and Other Metal Merchant Wholesalers'
  },
  {
    value: '423520',
    label: 'Coal and Other Mineral and Ore Merchant Wholesalers'
  },
  {
    value: '423610',
    label:
      'Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers'
  },
  {
    value: '423620',
    label:
      'Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers'
  },
  {
    value: '423690',
    label: 'Other Electronic Parts and Equipment Merchant Wholesalers'
  },
  { value: '423710', label: 'Hardware Merchant Wholesalers' },
  {
    value: '423720',
    label:
      'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers'
  },
  {
    value: '423730',
    label:
      'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers'
  },
  {
    value: '423740',
    label: 'Refrigeration Equipment and Supplies Merchant Wholesalers'
  },
  {
    value: '423810',
    label:
      'Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers'
  },
  {
    value: '423820',
    label: 'Farm and Garden Machinery and Equipment Merchant Wholesalers'
  },
  {
    value: '423830',
    label: 'Industrial Machinery and Equipment Merchant Wholesalers'
  },
  { value: '423840', label: 'Industrial Supplies Merchant Wholesalers' },
  {
    value: '423850',
    label: 'Service Establishment Equipment and Supplies Merchant Wholesalers'
  },
  {
    value: '423860',
    label:
      'Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers'
  },
  {
    value: '423910',
    label: 'Sporting and Recreational Goods and Supplies Merchant Wholesalers'
  },
  {
    value: '423920',
    label: 'Toy and Hobby Goods and Supplies Merchant Wholesalers'
  },
  { value: '423930', label: 'Recyclable Material Merchant Wholesalers' },
  {
    value: '423940',
    label:
      'Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers'
  },
  {
    value: '423990',
    label: 'Other Miscellaneous Durable Goods Merchant Wholesalers'
  },
  { value: '424110', label: 'Printing and Writing Paper Merchant Wholesalers' },
  {
    value: '424120',
    label: 'Stationery and Office Supplies Merchant Wholesalers'
  },
  {
    value: '424130',
    label: 'Industrial and Personal Service Paper Merchant Wholesalers'
  },
  {
    value: '424210',
    label: "Drugs and Druggists' Sundries Merchant Wholesalers"
  },
  {
    value: '424310',
    label: 'Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers'
  },
  { value: '424340', label: 'Footwear Merchant Wholesalers' },
  {
    value: '424350',
    label: 'Clothing and Clothing Accessories Merchant Wholesalers'
  },
  { value: '424410', label: 'General Line Grocery Merchant Wholesalers' },
  { value: '424420', label: 'Packaged Frozen Food Merchant Wholesalers' },
  {
    value: '424430',
    label: 'Dairy Product (except Dried or Canned) Merchant Wholesalers'
  },
  {
    value: '424440',
    label: 'Poultry and Poultry Product Merchant Wholesalers'
  },
  { value: '424450', label: 'Confectionery Merchant Wholesalers' },
  { value: '424460', label: 'Fish and Seafood Merchant Wholesalers' },
  { value: '424470', label: 'Meat and Meat Product Merchant Wholesalers' },
  { value: '424480', label: 'Fresh Fruit and Vegetable Merchant Wholesalers' },
  {
    value: '424490',
    label: 'Other Grocery and Related Products Merchant Wholesalers'
  },
  { value: '424510', label: 'Grain and Field Bean Merchant Wholesalers' },
  { value: '424520', label: 'Livestock Merchant Wholesalers' },
  {
    value: '424590',
    label: 'Other Farm Product Raw Material Merchant Wholesalers'
  },
  {
    value: '424610',
    label: 'Plastics Materials and Basic Forms and Shapes Merchant Wholesalers'
  },
  {
    value: '424690',
    label: 'Other Chemical and Allied Products Merchant Wholesalers'
  },
  { value: '424710', label: 'Petroleum Bulk Stations and Terminals' },
  {
    value: '424720',
    label:
      'Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)'
  },
  { value: '424810', label: 'Beer and Ale Merchant Wholesalers' },
  {
    value: '424820',
    label: 'Wine and Distilled Alcoholic Beverage Merchant Wholesalers'
  },
  { value: '424910', label: 'Farm Supplies Merchant Wholesalers' },
  {
    value: '424920',
    label: 'Book, Periodical, and Newspaper Merchant Wholesalers'
  },
  {
    value: '424930',
    label: "Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers"
  },
  {
    value: '424940',
    label: 'Tobacco Product and Electronic Cigarette Merchant Wholesalers'
  },
  {
    value: '424950',
    label: 'Paint, Varnish, and Supplies Merchant Wholesalers'
  },
  {
    value: '424990',
    label: 'Other Miscellaneous Nondurable Goods Merchant Wholesalers'
  },
  { value: '425120', label: 'Wholesale Trade Agents and Brokers' },
  { value: '441110', label: 'New Car Dealers' },
  { value: '441120', label: 'Used Car Dealers' },
  { value: '441210', label: 'Recreational Vehicle Dealers' },
  { value: '441222', label: 'Boat Dealers' },
  {
    value: '441227',
    label: 'Motorcycle, ATV, and All Other Motor Vehicle Dealers'
  },
  { value: '441330', label: 'Automotive Parts and Accessories Retailers' },
  { value: '441340', label: 'Tire Dealers' },
  { value: '444110', label: 'Home Centers' },
  { value: '444120', label: 'Paint and Wallpaper Retailers' },
  { value: '444140', label: 'Hardware Retailers' },
  { value: '444180', label: 'Other Building Material Dealers' },
  { value: '444230', label: 'Outdoor Power Equipment Retailers' },
  {
    value: '444240',
    label: 'Nursery, Garden Center, and Farm Supply Retailers'
  },
  {
    value: '445110',
    label:
      'Supermarkets and Other Grocery Retailers (except Convenience Retailers)'
  },
  { value: '445131', label: 'Convenience Retailers' },
  { value: '445132', label: 'Vending Machine Operators' },
  { value: '445230', label: 'Fruit and Vegetable Retailers' },
  { value: '445240', label: 'Meat Retailers' },
  { value: '445250', label: 'Fish and Seafood Retailers' },
  { value: '445291', label: 'Baked Goods Retailers' },
  { value: '445292', label: 'Confectionery and Nut Retailers' },
  { value: '445298', label: 'All Other Specialty Food Retailers' },
  { value: '445320', label: 'Beer, Wine, and Liquor Retailers' },
  { value: '449110', label: 'Furniture Retailers' },
  { value: '449121', label: 'Floor Covering Retailers' },
  { value: '449122', label: 'Window Treatment Retailers' },
  { value: '449129', label: 'All Other Home Furnishings Retailers' },
  { value: '449210', label: 'Electronics and Appliance Retailers' },
  { value: '455110', label: 'Department Stores' },
  { value: '455211', label: 'Warehouse Clubs and Supercenters' },
  { value: '455219', label: 'All Other General Merchandise Retailers' },
  { value: '456110', label: 'Pharmacies and Drug Retailers' },
  {
    value: '456120',
    label: 'Cosmetics, Beauty Supplies, and Perfume Retailers'
  },
  { value: '456130', label: 'Optical Goods Retailers' },
  { value: '456191', label: 'Food (Health) Supplement Retailers' },
  { value: '456199', label: 'All Other Health and Personal Care Retailers' },
  { value: '457110', label: 'Gasoline Stations with Convenience Stores' },
  { value: '457120', label: 'Other Gasoline Stations' },
  { value: '457210', label: 'Fuel Dealers' },
  { value: '458110', label: 'Clothing and Clothing Accessories Retailers' },
  { value: '458210', label: 'Shoe Retailers' },
  { value: '458310', label: 'Jewelry Retailers' },
  { value: '458320', label: 'Luggage and Leather Goods Retailers' },
  { value: '459110', label: 'Sporting Goods Retailers' },
  { value: '459120', label: 'Hobby, Toy, and Game Retailers' },
  { value: '459130', label: 'Sewing, Needlework, and Piece Goods Retailers' },
  { value: '459140', label: 'Musical Instrument and Supplies Retailers' },
  { value: '459210', label: 'Book Retailers and News Dealers' },
  { value: '459310', label: 'Florists' },
  { value: '459410', label: 'Office Supplies and Stationery Retailers' },
  { value: '459420', label: 'Gift, Novelty, and Souvenir Retailers' },
  { value: '459510', label: 'Used Merchandise Retailers' },
  { value: '459910', label: 'Pet and Pet Supplies Retailers' },
  { value: '459920', label: 'Art Dealers' },
  { value: '459930', label: 'Manufactured (Mobile) Home Dealers' },
  {
    value: '459991',
    label: 'Tobacco, Electronic Cigarette, and Other Smoking Supplies Retailers'
  },
  { value: '459999', label: 'All Other Miscellaneous Retailers' },
  { value: '481111', label: 'Scheduled Passenger Air Transportation' },
  { value: '481112', label: 'Scheduled Freight Air Transportation' },
  {
    value: '481211',
    label: 'Nonscheduled Chartered Passenger Air Transportation'
  },
  {
    value: '481212',
    label: 'Nonscheduled Chartered Freight Air Transportation'
  },
  { value: '481219', label: 'Other Nonscheduled Air Transportation' },
  { value: '482111', label: 'Line-Haul Railroads' },
  { value: '482112', label: 'Short Line Railroads' },
  { value: '483111', label: 'Deep Sea Freight Transportation' },
  { value: '483112', label: 'Deep Sea Passenger Transportation' },
  { value: '483113', label: 'Coastal and Great Lakes Freight Transportation' },
  {
    value: '483114',
    label: 'Coastal and Great Lakes Passenger Transportation'
  },
  { value: '483211', label: 'Inland Water Freight Transportation' },
  { value: '483212', label: 'Inland Water Passenger Transportation' },
  { value: '484110', label: 'General Freight Trucking, Local' },
  {
    value: '484121',
    label: 'General Freight Trucking, Long-Distance, Truckload'
  },
  {
    value: '484122',
    label: 'General Freight Trucking, Long-Distance, Less Than Truckload'
  },
  { value: '484210', label: 'Used Household and Office Goods Moving' },
  {
    value: '484220',
    label: 'Specialized Freight (except Used Goods) Trucking, Local'
  },
  {
    value: '484230',
    label: 'Specialized Freight (except Used Goods) Trucking, Long-Distance'
  },
  { value: '485111', label: 'Mixed Mode Transit Systems' },
  { value: '485112', label: 'Commuter Rail Systems' },
  { value: '485113', label: 'Bus and Other Motor Vehicle Transit Systems' },
  { value: '485119', label: 'Other Urban Transit Systems' },
  { value: '485210', label: 'Interurban and Rural Bus Transportation' },
  { value: '485310', label: 'Taxi and Ridesharing Services' },
  { value: '485320', label: 'Limousine Service' },
  { value: '485410', label: 'School and Employee Bus Transportation' },
  { value: '485510', label: 'Charter Bus Industry' },
  { value: '485991', label: 'Special Needs Transportation' },
  {
    value: '485999',
    label: 'All Other Transit and Ground Passenger Transportation'
  },
  { value: '486110', label: 'Pipeline Transportation of Crude Oil' },
  { value: '486210', label: 'Pipeline Transportation of Natural Gas' },
  {
    value: '486910',
    label: 'Pipeline Transportation of Refined Petroleum Products'
  },
  { value: '486990', label: 'All Other Pipeline Transportation' },
  { value: '487110', label: 'Scenic and Sightseeing Transportation, Land' },
  { value: '487210', label: 'Scenic and Sightseeing Transportation, Water' },
  { value: '487990', label: 'Scenic and Sightseeing Transportation, Other' },
  { value: '488111', label: 'Air Traffic Control' },
  { value: '488119', label: 'Other Airport Operations' },
  { value: '488190', label: 'Other Support Activities for Air Transportation' },
  { value: '488210', label: 'Support Activities for Rail Transportation' },
  { value: '488310', label: 'Port and Harbor Operations' },
  { value: '488320', label: 'Marine Cargo Handling' },
  { value: '488330', label: 'Navigational Services to Shipping' },
  {
    value: '488390',
    label: 'Other Support Activities for Water Transportation'
  },
  { value: '488410', label: 'Motor Vehicle Towing' },
  {
    value: '488490',
    label: 'Other Support Activities for Road Transportation'
  },
  { value: '488510', label: 'Freight Transportation Arrangement' },
  { value: '488991', label: 'Packing and Crating' },
  { value: '488999', label: 'All Other Support Activities for Transportation' },
  { value: '491110', label: 'Postal Service' },
  { value: '492110', label: 'Couriers and Express Delivery Services' },
  { value: '492210', label: 'Local Messengers and Local Delivery' },
  { value: '493110', label: 'General Warehousing and Storage' },
  { value: '493120', label: 'Refrigerated Warehousing and Storage' },
  { value: '493130', label: 'Farm Product Warehousing and Storage' },
  { value: '493190', label: 'Other Warehousing and Storage' },
  { value: '512110', label: 'Motion Picture and Video Production' },
  { value: '512120', label: 'Motion Picture and Video Distribution' },
  { value: '512131', label: 'Motion Picture Theaters (except Drive-Ins)' },
  { value: '512132', label: 'Drive-In Motion Picture Theaters' },
  {
    value: '512191',
    label: 'Teleproduction and Other Postproduction Services'
  },
  { value: '512199', label: 'Other Motion Picture and Video Industries' },
  { value: '512230', label: 'Music Publishers' },
  { value: '512240', label: 'Sound Recording Studios' },
  { value: '512250', label: 'Record Production and Distribution' },
  { value: '512290', label: 'Other Sound Recording Industries' },
  { value: '513110', label: 'Newspaper Publishers' },
  { value: '513120', label: 'Periodical Publishers' },
  { value: '513130', label: 'Book Publishers' },
  { value: '513140', label: 'Directory and Mailing List Publishers' },
  { value: '513191', label: 'Greeting Card Publishers' },
  { value: '513199', label: 'All Other Publishers' },
  { value: '513210', label: 'Software Publishers' },
  { value: '516110', label: 'Radio Broadcasting Stations' },
  { value: '516120', label: 'Television Broadcasting Stations' },
  {
    value: '516210',
    label:
      'Media Streaming Distribution Services, Social Networks, and Other Media Networks and Content Providers'
  },
  { value: '517111', label: 'Wired Telecommunications Carriers' },
  {
    value: '517112',
    label: 'Wireless Telecommunications Carriers (except Satellite)'
  },
  { value: '517121', label: 'Telecommunications Resellers' },
  { value: '517122', label: 'Agents for Wireless Telecommunications Services' },
  { value: '517410', label: 'Satellite Telecommunications' },
  { value: '517810', label: 'All Other Telecommunications' },
  {
    value: '518210',
    label:
      'Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services'
  },
  { value: '519210', label: 'Libraries and Archives' },
  {
    value: '519290',
    label: 'Web Search Portals and All Other Information Services'
  },
  { value: '521110', label: 'Monetary Authorities-Central Bank' },
  { value: '522110', label: 'Commercial Banking' },
  { value: '522130', label: 'Credit Unions' },
  {
    value: '522180',
    label: 'Savings Institutions and Other Depository Credit Intermediation'
  },
  { value: '522210', label: 'Credit Card Issuing' },
  { value: '522220', label: 'Sales Financing' },
  { value: '522291', label: 'Consumer Lending' },
  { value: '522292', label: 'Real Estate Credit' },
  {
    value: '522299',
    label:
      'International, Secondary Market, and All Other Nondepository Credit Intermediation'
  },
  { value: '522310', label: 'Mortgage and Nonmortgage Loan Brokers' },
  {
    value: '522320',
    label:
      'Financial Transactions Processing, Reserve, and Clearinghouse Activities'
  },
  {
    value: '522390',
    label: 'Other Activities Related to Credit Intermediation'
  },
  {
    value: '523150',
    label: 'Investment Banking and Securities Intermediation'
  },
  { value: '523160', label: 'Commodity Contracts Intermediation' },
  { value: '523210', label: 'Securities and Commodity Exchanges' },
  { value: '523910', label: 'Miscellaneous Intermediation' },
  { value: '523940', label: 'Portfolio Management and Investment Advice' },
  { value: '523991', label: 'Trust, Fiduciary, and Custody Activities' },
  { value: '523999', label: 'Miscellaneous Financial Investment Activities' },
  { value: '524113', label: 'Direct Life Insurance Carriers' },
  { value: '524114', label: 'Direct Health and Medical Insurance Carriers' },
  { value: '524126', label: 'Direct Property and Casualty Insurance Carriers' },
  { value: '524127', label: 'Direct Title Insurance Carriers' },
  {
    value: '524128',
    label: 'Other Direct Insurance (except Life, Health, and Medical) Carriers'
  },
  { value: '524130', label: 'Reinsurance Carriers' },
  { value: '524210', label: 'Insurance Agencies and Brokerages' },
  { value: '524291', label: 'Claims Adjusting' },
  {
    value: '524292',
    label:
      'Pharmacy Benefit Management and Other Third Party Administration of Insurance and Pension Funds'
  },
  { value: '524298', label: 'All Other Insurance Related Activities' },
  { value: '525110', label: 'Pension Funds' },
  { value: '525120', label: 'Health and Welfare Funds' },
  { value: '525190', label: 'Other Insurance Funds' },
  { value: '525910', label: 'Open-End Investment Funds' },
  { value: '525920', label: 'Trusts, Estates, and Agency Accounts' },
  { value: '525990', label: 'Other Financial Vehicles' },
  { value: '531110', label: 'Lessors of Residential Buildings and Dwellings' },
  {
    value: '531120',
    label: 'Lessors of Nonresidential Buildings (except Miniwarehouses)'
  },
  {
    value: '531130',
    label: 'Lessors of Miniwarehouses and Self-Storage Units'
  },
  { value: '531190', label: 'Lessors of Other Real Estate Property' },
  { value: '531210', label: 'Offices of Real Estate Agents and Brokers' },
  { value: '531311', label: 'Residential Property Managers' },
  { value: '531312', label: 'Nonresidential Property Managers' },
  { value: '531320', label: 'Offices of Real Estate Appraisers' },
  { value: '531390', label: 'Other Activities Related to Real Estate' },
  { value: '532111', label: 'Passenger Car Rental' },
  { value: '532112', label: 'Passenger Car Leasing' },
  {
    value: '532120',
    label:
      'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing'
  },
  { value: '532210', label: 'Consumer Electronics and Appliances Rental' },
  { value: '532281', label: 'Formal Wear and Costume Rental' },
  { value: '532282', label: 'Video Tape and Disc Rental' },
  { value: '532283', label: 'Home Health Equipment Rental' },
  { value: '532284', label: 'Recreational Goods Rental' },
  { value: '532289', label: 'All Other Consumer Goods Rental' },
  { value: '532310', label: 'General Rental Centers' },
  {
    value: '532411',
    label:
      'Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing'
  },
  {
    value: '532412',
    label:
      'Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing'
  },
  {
    value: '532420',
    label: 'Office Machinery and Equipment Rental and Leasing'
  },
  {
    value: '532490',
    label:
      'Other Commercial and Industrial Machinery and Equipment Rental and Leasing'
  },
  {
    value: '533110',
    label:
      'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)'
  },
  { value: '541110', label: 'Offices of Lawyers' },
  { value: '541120', label: 'Offices of Notaries' },
  { value: '541191', label: 'Title Abstract and Settlement Offices' },
  { value: '541199', label: 'All Other Legal Services' },
  { value: '541211', label: 'Offices of Certified Public Accountants' },
  { value: '541213', label: 'Tax Preparation Services' },
  { value: '541214', label: 'Payroll Services' },
  { value: '541219', label: 'Other Accounting Services' },
  { value: '541310', label: 'Architectural Services' },
  { value: '541320', label: 'Landscape Architectural Services' },
  { value: '541330', label: 'Engineering Services' },
  { value: '541340', label: 'Drafting Services' },
  { value: '541350', label: 'Building Inspection Services' },
  { value: '541360', label: 'Geophysical Surveying and Mapping Services' },
  {
    value: '541370',
    label: 'Surveying and Mapping (except Geophysical) Services'
  },
  { value: '541380', label: 'Testing Laboratories and Services' },
  { value: '541410', label: 'Interior Design Services' },
  { value: '541420', label: 'Industrial Design Services' },
  { value: '541430', label: 'Graphic Design Services' },
  { value: '541490', label: 'Other Specialized Design Services' },
  { value: '541511', label: 'Custom Computer Programming Services' },
  { value: '541512', label: 'Computer Systems Design Services' },
  { value: '541513', label: 'Computer Facilities Management Services' },
  { value: '541519', label: 'Other Computer Related Services' },
  {
    value: '541611',
    label:
      'Administrative Management and General Management Consulting Services'
  },
  { value: '541612', label: 'Human Resources Consulting Services' },
  { value: '541613', label: 'Marketing Consulting Services' },
  {
    value: '541614',
    label: 'Process, Physical Distribution, and Logistics Consulting Services'
  },
  { value: '541618', label: 'Other Management Consulting Services' },
  { value: '541620', label: 'Environmental Consulting Services' },
  {
    value: '541690',
    label: 'Other Scientific and Technical Consulting Services'
  },
  { value: '541713', label: 'Research and Development in Nanotechnology' },
  {
    value: '541714',
    label:
      'Research and Development in Biotechnology (except Nanobiotechnology)'
  },
  {
    value: '541715',
    label:
      'Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)'
  },
  {
    value: '541720',
    label: 'Research and Development in the Social Sciences and Humanities'
  },
  { value: '541810', label: 'Advertising Agencies' },
  { value: '541820', label: 'Public Relations Agencies' },
  { value: '541830', label: 'Media Buying Agencies' },
  { value: '541840', label: 'Media Representatives' },
  { value: '541850', label: 'Indoor and Outdoor Display Advertising' },
  { value: '541860', label: 'Direct Mail Advertising' },
  { value: '541870', label: 'Advertising Material Distribution Services' },
  { value: '541890', label: 'Other Services Related to Advertising' },
  { value: '541910', label: 'Marketing Research and Public Opinion Polling' },
  { value: '541921', label: 'Photography Studios, Portrait' },
  { value: '541922', label: 'Commercial Photography' },
  { value: '541930', label: 'Translation and Interpretation Services' },
  { value: '541940', label: 'Veterinary Services' },
  {
    value: '541990',
    label: 'All Other Professional, Scientific, and Technical Services'
  },
  { value: '551111', label: 'Offices of Bank Holding Companies' },
  { value: '551112', label: 'Offices of Other Holding Companies' },
  {
    value: '551114',
    label: 'Corporate, Subsidiary, and Regional Managing Offices'
  },
  { value: '561110', label: 'Office Administrative Services' },
  { value: '561210', label: 'Facilities Support Services' },
  { value: '561311', label: 'Employment Placement Agencies' },
  { value: '561312', label: 'Executive Search Services' },
  { value: '561320', label: 'Temporary Help Services' },
  { value: '561330', label: 'Professional Employer Organizations' },
  { value: '561410', label: 'Document Preparation Services' },
  { value: '561421', label: 'Telephone Answering Services' },
  { value: '561422', label: 'Telemarketing Bureaus and Other Contact Centers' },
  { value: '561431', label: 'Private Mail Centers' },
  {
    value: '561439',
    label: 'Other Business Service Centers (including Copy Shops)'
  },
  { value: '561440', label: 'Collection Agencies' },
  { value: '561450', label: 'Credit Bureaus' },
  { value: '561491', label: 'Repossession Services' },
  { value: '561492', label: 'Court Reporting and Stenotype Services' },
  { value: '561499', label: 'All Other Business Support Services' },
  { value: '561510', label: 'Travel Agencies' },
  { value: '561520', label: 'Tour Operators' },
  { value: '561591', label: 'Convention and Visitors Bureaus' },
  {
    value: '561599',
    label: 'All Other Travel Arrangement and Reservation Services'
  },
  {
    value: '561611',
    label: 'Investigation and Personal Background Check Services'
  },
  { value: '561612', label: 'Security Guards and Patrol Services' },
  { value: '561613', label: 'Armored Car Services' },
  { value: '561621', label: 'Security Systems Services (except Locksmiths)' },
  { value: '561622', label: 'Locksmiths' },
  { value: '561710', label: 'Exterminating and Pest Control Services' },
  { value: '561720', label: 'Janitorial Services' },
  { value: '561730', label: 'Landscaping Services' },
  { value: '561740', label: 'Carpet and Upholstery Cleaning Services' },
  { value: '561790', label: 'Other Services to Buildings and Dwellings' },
  { value: '561910', label: 'Packaging and Labeling Services' },
  { value: '561920', label: 'Convention and Trade Show Organizers' },
  { value: '561990', label: 'All Other Support Services' },
  { value: '562111', label: 'Solid Waste Collection' },
  { value: '562112', label: 'Hazardous Waste Collection' },
  { value: '562119', label: 'Other Waste Collection' },
  { value: '562211', label: 'Hazardous Waste Treatment and Disposal' },
  { value: '562212', label: 'Solid Waste Landfill' },
  { value: '562213', label: 'Solid Waste Combustors and Incinerators' },
  { value: '562219', label: 'Other Nonhazardous Waste Treatment and Disposal' },
  { value: '562910', label: 'Remediation Services' },
  { value: '562920', label: 'Materials Recovery Facilities' },
  { value: '562991', label: 'Septic Tank and Related Services' },
  {
    value: '562998',
    label: 'All Other Miscellaneous Waste Management Services'
  },
  { value: '611110', label: 'Elementary and Secondary Schools' },
  { value: '611210', label: 'Junior Colleges' },
  {
    value: '611310',
    label: 'Colleges, Universities, and Professional Schools'
  },
  { value: '611410', label: 'Business and Secretarial Schools' },
  { value: '611420', label: 'Computer Training' },
  {
    value: '611430',
    label: 'Professional and Management Development Training'
  },
  { value: '611511', label: 'Cosmetology and Barber Schools' },
  { value: '611512', label: 'Flight Training' },
  { value: '611513', label: 'Apprenticeship Training' },
  { value: '611519', label: 'Other Technical and Trade Schools' },
  { value: '611610', label: 'Fine Arts Schools' },
  { value: '611620', label: 'Sports and Recreation Instruction' },
  { value: '611630', label: 'Language Schools' },
  { value: '611691', label: 'Exam Preparation and Tutoring' },
  { value: '611692', label: 'Automobile Driving Schools' },
  { value: '611699', label: 'All Other Miscellaneous Schools and Instruction' },
  { value: '611710', label: 'Educational Support Services' },
  {
    value: '621111',
    label: 'Offices of Physicians (except Mental Health Specialists)'
  },
  {
    value: '621112',
    label: 'Offices of Physicians, Mental Health Specialists'
  },
  { value: '621210', label: 'Offices of Dentists' },
  { value: '621310', label: 'Offices of Chiropractors' },
  { value: '621320', label: 'Offices of Optometrists' },
  {
    value: '621330',
    label: 'Offices of Mental Health Practitioners (except Physicians)'
  },
  {
    value: '621340',
    label:
      'Offices of Physical, Occupational and Speech Therapists, and Audiologists'
  },
  { value: '621391', label: 'Offices of Podiatrists' },
  {
    value: '621399',
    label: 'Offices of All Other Miscellaneous Health Practitioners'
  },
  { value: '621410', label: 'Family Planning Centers' },
  {
    value: '621420',
    label: 'Outpatient Mental Health and Substance Abuse Centers'
  },
  { value: '621491', label: 'HMO Medical Centers' },
  { value: '621492', label: 'Kidney Dialysis Centers' },
  {
    value: '621493',
    label: 'Freestanding Ambulatory Surgical and Emergency Centers'
  },
  { value: '621498', label: 'All Other Outpatient Care Centers' },
  { value: '621511', label: 'Medical Laboratories' },
  { value: '621512', label: 'Diagnostic Imaging Centers' },
  { value: '621610', label: 'Home Health Care Services' },
  { value: '621910', label: 'Ambulance Services' },
  { value: '621991', label: 'Blood and Organ Banks' },
  {
    value: '621999',
    label: 'All Other Miscellaneous Ambulatory Health Care Services'
  },
  { value: '622110', label: 'General Medical and Surgical Hospitals' },
  { value: '622210', label: 'Psychiatric and Substance Abuse Hospitals' },
  {
    value: '622310',
    label: 'Specialty (except Psychiatric and Substance Abuse) Hospitals'
  },
  {
    value: '623110',
    label: 'Nursing Care Facilities (Skilled Nursing Facilities)'
  },
  {
    value: '623210',
    label: 'Residential Intellectual and Developmental Disability Facilities'
  },
  {
    value: '623220',
    label: 'Residential Mental Health and Substance Abuse Facilities'
  },
  { value: '623311', label: 'Continuing Care Retirement Communities' },
  { value: '623312', label: 'Assisted Living Facilities for the Elderly' },
  { value: '623990', label: 'Other Residential Care Facilities' },
  { value: '624110', label: 'Child and Youth Services' },
  {
    value: '624120',
    label: 'Services for the Elderly and Persons with Disabilities'
  },
  { value: '624190', label: 'Other Individual and Family Services' },
  { value: '624210', label: 'Community Food Services' },
  { value: '624221', label: 'Temporary Shelters' },
  { value: '624229', label: 'Other Community Housing Services' },
  { value: '624230', label: 'Emergency and Other Relief Services' },
  { value: '624310', label: 'Vocational Rehabilitation Services' },
  { value: '624410', label: 'Child Care Services' },
  { value: '711110', label: 'Theater Companies and Dinner Theaters' },
  { value: '711120', label: 'Dance Companies' },
  { value: '711130', label: 'Musical Groups and Artists' },
  { value: '711190', label: 'Other Performing Arts Companies' },
  { value: '711211', label: 'Sports Teams and Clubs' },
  { value: '711212', label: 'Racetracks' },
  { value: '711219', label: 'Other Spectator Sports' },
  {
    value: '711310',
    label:
      'Promoters of Performing Arts, Sports, and Similar Events with Facilities'
  },
  {
    value: '711320',
    label:
      'Promoters of Performing Arts, Sports, and Similar Events without Facilities'
  },
  {
    value: '711410',
    label:
      'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures'
  },
  { value: '711510', label: 'Independent Artists, Writers, and Performers' },
  { value: '712110', label: 'Museums' },
  { value: '712120', label: 'Historical Sites' },
  { value: '712130', label: 'Zoos and Botanical Gardens' },
  { value: '712190', label: 'Nature Parks and Other Similar Institutions' },
  { value: '713110', label: 'Amusement and Theme Parks' },
  { value: '713120', label: 'Amusement Arcades' },
  { value: '713210', label: 'Casinos (except Casino Hotels)' },
  { value: '713290', label: 'Other Gambling Industries' },
  { value: '713910', label: 'Golf Courses and Country Clubs' },
  { value: '713920', label: 'Skiing Facilities' },
  { value: '713930', label: 'Marinas' },
  { value: '713940', label: 'Fitness and Recreational Sports Centers' },
  { value: '713950', label: 'Bowling Centers' },
  { value: '713990', label: 'All Other Amusement and Recreation Industries' },
  { value: '721110', label: 'Hotels (except Casino Hotels) and Motels' },
  { value: '721120', label: 'Casino Hotels' },
  { value: '721191', label: 'Bed-and-Breakfast Inns' },
  { value: '721199', label: 'All Other Traveler Accommodation' },
  { value: '721211', label: 'RV (Recreational Vehicle) Parks and Campgrounds' },
  {
    value: '721214',
    label: 'Recreational and Vacation Camps (except Campgrounds)'
  },
  {
    value: '721310',
    label: "Rooming and Boarding Houses, Dormitories, and Workers' Camps"
  },
  { value: '722310', label: 'Food Service Contractors' },
  { value: '722320', label: 'Caterers' },
  { value: '722330', label: 'Mobile Food Services' },
  { value: '722410', label: 'Drinking Places (Alcoholic Beverages)' },
  { value: '722511', label: 'Full-Service Restaurants' },
  { value: '722513', label: 'Limited-Service Restaurants' },
  { value: '722514', label: 'Cafeterias, Grill Buffets, and Buffets' },
  { value: '722515', label: 'Snack and Nonalcoholic Beverage Bars' },
  { value: '811111', label: 'General Automotive Repair' },
  { value: '811114', label: 'Specialized Automotive Repair' },
  {
    value: '811121',
    label: 'Automotive Body, Paint, and Interior Repair and Maintenance'
  },
  { value: '811122', label: 'Automotive Glass Replacement Shops' },
  { value: '811191', label: 'Automotive Oil Change and Lubrication Shops' },
  { value: '811192', label: 'Car Washes' },
  { value: '811198', label: 'All Other Automotive Repair and Maintenance' },
  {
    value: '811210',
    label: 'Electronic and Precision Equipment Repair and Maintenance'
  },
  {
    value: '811310',
    label:
      'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance'
  },
  {
    value: '811411',
    label: 'Home and Garden Equipment Repair and Maintenance'
  },
  { value: '811412', label: 'Appliance Repair and Maintenance' },
  { value: '811420', label: 'Reupholstery and Furniture Repair' },
  { value: '811430', label: 'Footwear and Leather Goods Repair' },
  {
    value: '811490',
    label: 'Other Personal and Household Goods Repair and Maintenance'
  },
  { value: '812111', label: 'Barber Shops' },
  { value: '812112', label: 'Beauty Salons' },
  { value: '812113', label: 'Nail Salons' },
  { value: '812191', label: 'Diet and Weight Reducing Centers' },
  { value: '812199', label: 'Other Personal Care Services' },
  { value: '812210', label: 'Funeral Homes and Funeral Services' },
  { value: '812220', label: 'Cemeteries and Crematories' },
  { value: '812310', label: 'Coin-Operated Laundries and Drycleaners' },
  {
    value: '812320',
    label: 'Drycleaning and Laundry Services (except Coin-Operated)'
  },
  { value: '812331', label: 'Linen Supply' },
  { value: '812332', label: 'Industrial Launderers' },
  { value: '812910', label: 'Pet Care (except Veterinary) Services' },
  { value: '812921', label: 'Photofinishing Laboratories (except One-Hour)' },
  { value: '812922', label: 'One-Hour Photofinishing' },
  { value: '812930', label: 'Parking Lots and Garages' },
  { value: '812990', label: 'All Other Personal Services' },
  { value: '813110', label: 'Religious Organizations' },
  { value: '813211', label: 'Grantmaking Foundations' },
  { value: '813212', label: 'Voluntary Health Organizations' },
  { value: '813219', label: 'Other Grantmaking and Giving Services' },
  { value: '813311', label: 'Human Rights Organizations' },
  {
    value: '813312',
    label: 'Environment, Conservation and Wildlife Organizations'
  },
  { value: '813319', label: 'Other Social Advocacy Organizations' },
  { value: '813410', label: 'Civic and Social Organizations' },
  { value: '813910', label: 'Business Associations' },
  { value: '813920', label: 'Professional Organizations' },
  { value: '813930', label: 'Labor Unions and Similar Labor Organizations' },
  { value: '813940', label: 'Political Organizations' },
  {
    value: '813990',
    label:
      'Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)'
  },
  { value: '814110', label: 'Private Households' },
  { value: '921110', label: 'Executive Offices' },
  { value: '921120', label: 'Legislative Bodies' },
  { value: '921130', label: 'Public Finance Activities' },
  { value: '921140', label: 'Executive and Legislative Offices, Combined' },
  {
    value: '921150',
    label: 'American Indian and Alaska Native Tribal Governments'
  },
  { value: '921190', label: 'Other General Government Support' },
  { value: '922110', label: 'Courts' },
  { value: '922120', label: 'Police Protection' },
  { value: '922130', label: 'Legal Counsel and Prosecution' },
  { value: '922140', label: 'Correctional Institutions' },
  { value: '922150', label: 'Parole Offices and Probation Offices' },
  { value: '922160', label: 'Fire Protection' },
  {
    value: '922190',
    label: 'Other Justice, Public Order, and Safety Activities'
  },
  { value: '923110', label: 'Administration of Education Programs' },
  { value: '923120', label: 'Administration of Public Health Programs' },
  {
    value: '923130',
    label:
      "Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs)"
  },
  { value: '923140', label: "Administration of Veterans' Affairs" },
  {
    value: '924110',
    label:
      'Administration of Air and Water Resource and Solid Waste Management Programs'
  },
  { value: '924120', label: 'Administration of Conservation Programs' },
  { value: '925110', label: 'Administration of Housing Programs' },
  {
    value: '925120',
    label:
      'Administration of Urban Planning and Community and Rural Development'
  },
  { value: '926110', label: 'Administration of General Economic Programs' },
  {
    value: '926120',
    label: 'Regulation and Administration of Transportation Programs'
  },
  {
    value: '926130',
    label:
      'Regulation and Administration of Communications, Electric, Gas, and Other Utilities'
  },
  {
    value: '926140',
    label: 'Regulation of Agricultural Marketing and Commodities'
  },
  {
    value: '926150',
    label:
      'Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors'
  },
  { value: '927110', label: 'Space Research and Technology' },
  { value: '928110', label: 'National Security' },
  { value: '928120', label: 'International Affairs' }
];

export const NaicsCodes = [
  { value: '111110', label: 'Soybean Farming' },
  { value: '111120', label: 'Oilseed (except Soybean) Farming' },
  { value: '111130', label: 'Dry Pea and Bean Farming' },
  { value: '111140', label: 'Wheat Farming' },
  { value: '111150', label: 'Corn Farming' },
  { value: '111160', label: 'Rice Farming' },
  { value: '111191', label: 'Oilseed and Grain Combination Farming' },
  { value: '111199', label: 'All Other Grain Farming' },
  { value: '111211', label: 'Potato Farming' },
  {
    value: '111219',
    label: 'Other Vegetable (except Potato) and Melon Farming'
  },
  { value: '111310', label: 'Orange Groves' },
  { value: '111320', label: 'Citrus (except Orange) Groves' },
  { value: '111331', label: 'Apple Orchards' },
  { value: '111332', label: 'Grape Vineyards' },
  { value: '111333', label: 'Strawberry Farming' },
  { value: '111334', label: 'Berry (except Strawberry) Farming' },
  { value: '111335', label: 'Tree Nut Farming' },
  { value: '111336', label: 'Fruit and Tree Nut Combination Farming' },
  { value: '111339', label: 'Other Noncitrus Fruit Farming' },
  { value: '111411', label: 'Mushroom Production' },
  { value: '111419', label: 'Other Food Crops Grown Under Cover' },
  { value: '111421', label: 'Nursery and Tree Production' },
  { value: '111422', label: 'Floriculture Production' },
  { value: '111910', label: 'Tobacco Farming' },
  { value: '111920', label: 'Cotton Farming' },
  { value: '111930', label: 'Sugarcane Farming' },
  { value: '111940', label: 'Hay Farming' },
  { value: '111991', label: 'Sugar Beet Farming' },
  { value: '111992', label: 'Peanut Farming' },
  { value: '111998', label: 'All Other Miscellaneous Crop Farming' },
  { value: '112111', label: 'Beef Cattle Ranching and Farming' },
  { value: '112112', label: 'Cattle Feedlots' },
  { value: '112120', label: 'Dairy Cattle and Milk Production' },
  { value: '112130', label: 'Dual-Purpose Cattle Ranching and Farming' },
  { value: '112210', label: 'Hog and Pig Farming' },
  { value: '112310', label: 'Chicken Egg Production' },
  { value: '112320', label: 'Broilers and Other Meat Type Chicken Production' },
  { value: '112330', label: 'Turkey Production' },
  { value: '112340', label: 'Poultry Hatcheries' },
  { value: '112390', label: 'Other Poultry Production' },
  { value: '112410', label: 'Sheep Farming' },
  { value: '112420', label: 'Goat Farming' },
  { value: '112511', label: 'Finfish Farming and Fish Hatcheries' },
  { value: '112512', label: 'Shellfish Farming' },
  { value: '112519', label: 'Other Aquaculture' },
  { value: '112910', label: 'Apiculture' },
  { value: '112920', label: 'Horses and Other Equine Production' },
  { value: '112930', label: 'Fur-Bearing Animal and Rabbit Production' },
  { value: '112990', label: 'All Other Animal Production' },
  { value: '113110', label: 'Timber Tract Operations' },
  {
    value: '113210',
    label: 'Forest Nurseries and Gathering of Forest Products'
  },
  { value: '113310', label: 'Logging' },
  { value: '114111', label: 'Finfish Fishing' },
  { value: '114112', label: 'Shellfish Fishing' },
  { value: '114119', label: 'Other Marine Fishing' },
  { value: '114210', label: 'Hunting and Trapping' },
  { value: '115111', label: 'Cotton Ginning' },
  { value: '115112', label: 'Soil Preparation, Planting, and Cultivating' },
  { value: '115113', label: 'Crop Harvesting, Primarily by Machine' },
  {
    value: '115114',
    label: 'Postharvest Crop Activities (except Cotton Ginning)'
  },
  { value: '115115', label: 'Farm Labor Contractors and Crew Leaders' },
  { value: '115116', label: 'Farm Management Services' },
  { value: '115210', label: 'Support Activities for Animal Production' },
  { value: '115310', label: 'Support Activities for Forestry' },
  { value: '211120', label: 'Crude Petroleum Extraction' },
  { value: '211130', label: 'Natural Gas Extraction' },
  { value: '212114', label: 'Surface Coal Mining' },
  { value: '212115', label: 'Underground Coal Mining' },
  { value: '212210', label: 'Iron Ore Mining' },
  { value: '212220', label: 'Gold Ore and Silver Ore Mining' },
  { value: '212230', label: 'Copper, Nickel, Lead, and Zinc Mining' },
  { value: '212290', label: 'Other Metal Ore Mining' },
  { value: '212311', label: 'Dimension Stone Mining and Quarrying' },
  {
    value: '212312',
    label: 'Crushed and Broken Limestone Mining and Quarrying'
  },
  { value: '212313', label: 'Crushed and Broken Granite Mining and Quarrying' },
  {
    value: '212319',
    label: 'Other Crushed and Broken Stone Mining and Quarrying'
  },
  { value: '212321', label: 'Construction Sand and Gravel Mining' },
  { value: '212322', label: 'Industrial Sand Mining' },
  {
    value: '212323',
    label: 'Kaolin, Clay, and Ceramic and Refractory Minerals Mining'
  },
  { value: '212390', label: 'Other Nonmetallic Mineral Mining and Quarrying' },
  { value: '213111', label: 'Drilling Oil and Gas Wells' },
  { value: '213112', label: 'Support Activities for Oil and Gas Operations' },
  { value: '213113', label: 'Support Activities for Coal Mining' },
  { value: '213114', label: 'Support Activities for Metal Mining' },
  {
    value: '213115',
    label: 'Support Activities for Nonmetallic Minerals (except Fuels) Mining'
  },
  { value: '221111', label: 'Hydroelectric Power Generation' },
  { value: '221112', label: 'Fossil Fuel Electric Power Generation' },
  { value: '221113', label: 'Nuclear Electric Power Generation' },
  { value: '221114', label: 'Solar Electric Power Generation' },
  { value: '221115', label: 'Wind Electric Power Generation' },
  { value: '221116', label: 'Geothermal Electric Power Generation' },
  { value: '221117', label: 'Biomass Electric Power Generation' },
  { value: '221118', label: 'Other Electric Power Generation' },
  { value: '221121', label: 'Electric Bulk Power Transmission and Control' },
  { value: '221122', label: 'Electric Power Distribution' },
  { value: '221210', label: 'Natural Gas Distribution' },
  { value: '221310', label: 'Water Supply and Irrigation Systems' },
  { value: '221320', label: 'Sewage Treatment Facilities' },
  { value: '221330', label: 'Steam and Air-Conditioning Supply' },
  {
    value: '236115',
    label: 'New Single-Family Housing Construction (except For-Sale Builders)'
  },
  {
    value: '236116',
    label: 'New Multifamily Housing Construction (except For-Sale Builders)'
  },
  { value: '236117', label: 'New Housing For-Sale Builders' },
  { value: '236118', label: 'Residential Remodelers' },
  { value: '236210', label: 'Industrial Building Construction' },
  {
    value: '236220',
    label: 'Commercial and Institutional Building Construction'
  },
  {
    value: '237110',
    label: 'Water and Sewer Line and Related Structures Construction'
  },
  {
    value: '237120',
    label: 'Oil and Gas Pipeline and Related Structures Construction'
  },
  {
    value: '237130',
    label: 'Power and Communication Line and Related Structures Construction'
  },
  { value: '237210', label: 'Land Subdivision' },
  { value: '237310', label: 'Highway, Street, and Bridge Construction' },
  { value: '237990', label: 'Other Heavy and Civil Engineering Construction' },
  {
    value: '238110',
    label: 'Poured Concrete Foundation and Structure Contractors'
  },
  {
    value: '238120',
    label: 'Structural Steel and Precast Concrete Contractors'
  },
  { value: '238130', label: 'Framing Contractors' },
  { value: '238140', label: 'Masonry Contractors' },
  { value: '238150', label: 'Glass and Glazing Contractors' },
  { value: '238160', label: 'Roofing Contractors' },
  { value: '238170', label: 'Siding Contractors' },
  {
    value: '238190',
    label: 'Other Foundation, Structure, and Building Exterior Contractors'
  },
  {
    value: '238210',
    label: 'Electrical Contractors and Other Wiring Installation Contractors'
  },
  {
    value: '238220',
    label: 'Plumbing, Heating, and Air-Conditioning Contractors'
  },
  { value: '238290', label: 'Other Building Equipment Contractors' },
  { value: '238310', label: 'Drywall and Insulation Contractors' },
  { value: '238320', label: 'Painting and Wall Covering Contractors' },
  { value: '238330', label: 'Flooring Contractors' },
  { value: '238340', label: 'Tile and Terrazzo Contractors' },
  { value: '238350', label: 'Finish Carpentry Contractors' },
  { value: '238390', label: 'Other Building Finishing Contractors' },
  { value: '238910', label: 'Site Preparation Contractors' },
  { value: '238990', label: 'All Other Specialty Trade Contractors' },
  { value: '311111', label: 'Dog and Cat Food Manufacturing' },
  { value: '311119', label: 'Other Animal Food Manufacturing' },
  { value: '311211', label: 'Flour Milling' },
  { value: '311212', label: 'Rice Milling' },
  { value: '311213', label: 'Malt Manufacturing' },
  { value: '311221', label: 'Wet Corn Milling and Starch Manufacturing' },
  { value: '311224', label: 'Soybean and Other Oilseed Processing' },
  { value: '311225', label: 'Fats and Oils Refining and Blending' },
  { value: '311230', label: 'Breakfast Cereal Manufacturing' },
  { value: '311313', label: 'Beet Sugar Manufacturing' },
  { value: '311314', label: 'Cane Sugar Manufacturing' },
  { value: '311340', label: 'Nonchocolate Confectionery Manufacturing' },
  {
    value: '311351',
    label: 'Chocolate and Confectionery Manufacturing from Cacao Beans'
  },
  {
    value: '311352',
    label: 'Confectionery Manufacturing from Purchased Chocolate'
  },
  {
    value: '311411',
    label: 'Frozen Fruit, Juice, and Vegetable Manufacturing'
  },
  { value: '311412', label: 'Frozen Specialty Food Manufacturing' },
  { value: '311421', label: 'Fruit and Vegetable Canning' },
  { value: '311422', label: 'Specialty Canning' },
  { value: '311423', label: 'Dried and Dehydrated Food Manufacturing' },
  { value: '311511', label: 'Fluid Milk Manufacturing' },
  { value: '311512', label: 'Creamery Butter Manufacturing' },
  { value: '311513', label: 'Cheese Manufacturing' },
  {
    value: '311514',
    label: 'Dry, Condensed, and Evaporated Dairy Product Manufacturing'
  },
  { value: '311520', label: 'Ice Cream and Frozen Dessert Manufacturing' },
  { value: '311611', label: 'Animal (except Poultry) Slaughtering' },
  { value: '311612', label: 'Meat Processed from Carcasses' },
  { value: '311613', label: 'Rendering and Meat Byproduct Processing' },
  { value: '311615', label: 'Poultry Processing' },
  { value: '311710', label: 'Seafood Product Preparation and Packaging' },
  { value: '311811', label: 'Retail Bakeries' },
  { value: '311812', label: 'Commercial Bakeries' },
  {
    value: '311813',
    label: 'Frozen Cakes, Pies, and Other Pastries Manufacturing'
  },
  { value: '311821', label: 'Cookie and Cracker Manufacturing' },
  {
    value: '311824',
    label:
      'Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour'
  },
  { value: '311830', label: 'Tortilla Manufacturing' },
  { value: '311911', label: 'Roasted Nuts and Peanut Butter Manufacturing' },
  { value: '311919', label: 'Other Snack Food Manufacturing' },
  { value: '311920', label: 'Coffee and Tea Manufacturing' },
  { value: '311930', label: 'Flavoring Syrup and Concentrate Manufacturing' },
  {
    value: '311941',
    label: 'Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing'
  },
  { value: '311942', label: 'Spice and Extract Manufacturing' },
  { value: '311991', label: 'Perishable Prepared Food Manufacturing' },
  { value: '311999', label: 'All Other Miscellaneous Food Manufacturing' },
  { value: '312111', label: 'Soft Drink Manufacturing' },
  { value: '312112', label: 'Bottled Water Manufacturing' },
  { value: '312113', label: 'Ice Manufacturing' },
  { value: '312120', label: 'Breweries' },
  { value: '312130', label: 'Wineries' },
  { value: '312140', label: 'Distilleries' },
  { value: '312230', label: 'Tobacco Manufacturing' },
  { value: '313110', label: 'Fiber, Yarn, and Thread Mills' },
  { value: '313210', label: 'Broadwoven Fabric Mills' },
  {
    value: '313220',
    label: 'Narrow Fabric Mills and Schiffli Machine Embroidery'
  },
  { value: '313230', label: 'Nonwoven Fabric Mills' },
  { value: '313240', label: 'Knit Fabric Mills' },
  { value: '313310', label: 'Textile and Fabric Finishing Mills' },
  { value: '313320', label: 'Fabric Coating Mills' },
  { value: '314110', label: 'Carpet and Rug Mills' },
  { value: '314120', label: 'Curtain and Linen Mills' },
  { value: '314910', label: 'Textile Bag and Canvas Mills' },
  {
    value: '314994',
    label: 'Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills'
  },
  { value: '314999', label: 'All Other Miscellaneous Textile Product Mills' },
  { value: '315120', label: 'Apparel Knitting Mills' },
  { value: '315210', label: 'Cut and Sew Apparel Contractors' },
  {
    value: '315250',
    label: 'Cut and Sew Apparel Manufacturing (except Contractors)'
  },
  {
    value: '315990',
    label: 'Apparel Accessories and Other Apparel Manufacturing'
  },
  { value: '316110', label: 'Leather and Hide Tanning and Finishing' },
  { value: '316210', label: 'Footwear Manufacturing' },
  { value: '316990', label: 'Other Leather and Allied Product Manufacturing' },
  { value: '321113', label: 'Sawmills' },
  { value: '321114', label: 'Wood Preservation' },
  { value: '321211', label: 'Hardwood Veneer and Plywood Manufacturing' },
  { value: '321212', label: 'Softwood Veneer and Plywood Manufacturing' },
  { value: '321215', label: 'Engineered Wood Member Manufacturing' },
  { value: '321219', label: 'Reconstituted Wood Product Manufacturing' },
  { value: '321911', label: 'Wood Window and Door Manufacturing' },
  { value: '321912', label: 'Cut Stock, Resawing Lumber, and Planing' },
  { value: '321918', label: 'Other Millwork (including Flooring)' },
  { value: '321920', label: 'Wood Container and Pallet Manufacturing' },
  { value: '321991', label: 'Manufactured Home (Mobile Home) Manufacturing' },
  { value: '321992', label: 'Prefabricated Wood Building Manufacturing' },
  {
    value: '321999',
    label: 'All Other Miscellaneous Wood Product Manufacturing'
  },
  { value: '322110', label: 'Pulp Mills' },
  { value: '322120', label: 'Paper Mills' },
  { value: '322130', label: 'Paperboard Mills' },
  { value: '322211', label: 'Corrugated and Solid Fiber Box Manufacturing' },
  { value: '322212', label: 'Folding Paperboard Box Manufacturing' },
  { value: '322219', label: 'Other Paperboard Container Manufacturing' },
  {
    value: '322220',
    label: 'Paper Bag and Coated and Treated Paper Manufacturing'
  },
  { value: '322230', label: 'Stationery Product Manufacturing' },
  { value: '322291', label: 'Sanitary Paper Product Manufacturing' },
  { value: '322299', label: 'All Other Converted Paper Product Manufacturing' },
  { value: '323111', label: 'Commercial Printing (except Screen and Books)' },
  { value: '323113', label: 'Commercial Screen Printing' },
  { value: '323117', label: 'Books Printing' },
  { value: '323120', label: 'Support Activities for Printing' },
  { value: '324110', label: 'Petroleum Refineries' },
  { value: '324121', label: 'Asphalt Paving Mixture and Block Manufacturing' },
  {
    value: '324122',
    label: 'Asphalt Shingle and Coating Materials Manufacturing'
  },
  {
    value: '324191',
    label: 'Petroleum Lubricating Oil and Grease Manufacturing'
  },
  {
    value: '324199',
    label: 'All Other Petroleum and Coal Products Manufacturing'
  },
  { value: '325110', label: 'Petrochemical Manufacturing' },
  { value: '325120', label: 'Industrial Gas Manufacturing' },
  { value: '325130', label: 'Synthetic Dye and Pigment Manufacturing' },
  { value: '325180', label: 'Other Basic Inorganic Chemical Manufacturing' },
  { value: '325193', label: 'Ethyl Alcohol Manufacturing' },
  {
    value: '325194',
    label: 'Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing'
  },
  { value: '325199', label: 'All Other Basic Organic Chemical Manufacturing' },
  { value: '325211', label: 'Plastics Material and Resin Manufacturing' },
  { value: '325212', label: 'Synthetic Rubber Manufacturing' },
  {
    value: '325220',
    label: 'Artificial and Synthetic Fibers and Filaments Manufacturing'
  },
  { value: '325311', label: 'Nitrogenous Fertilizer Manufacturing' },
  { value: '325312', label: 'Phosphatic Fertilizer Manufacturing' },
  { value: '325314', label: 'Fertilizer (Mixing Only) Manufacturing' },
  { value: '325315', label: 'Compost Manufacturing' },
  {
    value: '325320',
    label: 'Pesticide and Other Agricultural Chemical Manufacturing'
  },
  { value: '325411', label: 'Medicinal and Botanical Manufacturing' },
  { value: '325412', label: 'Pharmaceutical Preparation Manufacturing' },
  { value: '325413', label: 'In-Vitro Diagnostic Substance Manufacturing' },
  {
    value: '325414',
    label: 'Biological Product (except Diagnostic) Manufacturing'
  },
  { value: '325510', label: 'Paint and Coating Manufacturing' },
  { value: '325520', label: 'Adhesive Manufacturing' },
  { value: '325611', label: 'Soap and Other Detergent Manufacturing' },
  { value: '325612', label: 'Polish and Other Sanitation Good Manufacturing' },
  { value: '325613', label: 'Surface Active Agent Manufacturing' },
  { value: '325620', label: 'Toilet Preparation Manufacturing' },
  { value: '325910', label: 'Printing Ink Manufacturing' },
  { value: '325920', label: 'Explosives Manufacturing' },
  { value: '325991', label: 'Custom Compounding of Purchased Resins' },
  {
    value: '325992',
    label:
      'Photographic Film, Paper, Plate, Chemical, and Copy Toner Manufacturing'
  },
  {
    value: '325998',
    label:
      'All Other Miscellaneous Chemical Product and Preparation Manufacturing'
  },
  { value: '326111', label: 'Plastics Bag and Pouch Manufacturing' },
  {
    value: '326112',
    label:
      'Plastics Packaging Film and Sheet (including Laminated) Manufacturing'
  },
  {
    value: '326113',
    label:
      'Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing'
  },
  {
    value: '326121',
    label: 'Unlaminated Plastics Profile Shape Manufacturing'
  },
  { value: '326122', label: 'Plastics Pipe and Pipe Fitting Manufacturing' },
  {
    value: '326130',
    label:
      'Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing'
  },
  { value: '326140', label: 'Polystyrene Foam Product Manufacturing' },
  {
    value: '326150',
    label: 'Urethane and Other Foam Product (except Polystyrene) Manufacturing'
  },
  { value: '326160', label: 'Plastics Bottle Manufacturing' },
  { value: '326191', label: 'Plastics Plumbing Fixture Manufacturing' },
  { value: '326199', label: 'All Other Plastics Product Manufacturing' },
  { value: '326211', label: 'Tire Manufacturing (except Retreading)' },
  { value: '326212', label: 'Tire Retreading' },
  {
    value: '326220',
    label: 'Rubber and Plastics Hoses and Belting Manufacturing'
  },
  { value: '326291', label: 'Rubber Product Manufacturing for Mechanical Use' },
  { value: '326299', label: 'All Other Rubber Product Manufacturing' },
  {
    value: '327110',
    label: 'Pottery, Ceramics, and Plumbing Fixture Manufacturing'
  },
  {
    value: '327120',
    label: 'Clay Building Material and Refractories Manufacturing'
  },
  { value: '327211', label: 'Flat Glass Manufacturing' },
  {
    value: '327212',
    label: 'Other Pressed and Blown Glass and Glassware Manufacturing'
  },
  { value: '327213', label: 'Glass Container Manufacturing' },
  {
    value: '327215',
    label: 'Glass Product Manufacturing Made of Purchased Glass'
  },
  { value: '327310', label: 'Cement Manufacturing' },
  { value: '327320', label: 'Ready-Mix Concrete Manufacturing' },
  { value: '327331', label: 'Concrete Block and Brick Manufacturing' },
  { value: '327332', label: 'Concrete Pipe Manufacturing' },
  { value: '327390', label: 'Other Concrete Product Manufacturing' },
  { value: '327410', label: 'Lime Manufacturing' },
  { value: '327420', label: 'Gypsum Product Manufacturing' },
  { value: '327910', label: 'Abrasive Product Manufacturing' },
  { value: '327991', label: 'Cut Stone and Stone Product Manufacturing' },
  {
    value: '327992',
    label: 'Ground or Treated Mineral and Earth Manufacturing'
  },
  { value: '327993', label: 'Mineral Wool Manufacturing' },
  {
    value: '327999',
    label: 'All Other Miscellaneous Nonmetallic Mineral Product Manufacturing'
  },
  {
    value: '331110',
    label: 'Iron and Steel Mills and Ferroalloy Manufacturing'
  },
  {
    value: '331210',
    label: 'Iron and Steel Pipe and Tube Manufacturing from Purchased Steel'
  },
  { value: '331221', label: 'Rolled Steel Shape Manufacturing' },
  { value: '331222', label: 'Steel Wire Drawing' },
  {
    value: '331313',
    label: 'Alumina Refining and Primary Aluminum Production'
  },
  { value: '331314', label: 'Secondary Smelting and Alloying of Aluminum' },
  { value: '331315', label: 'Aluminum Sheet, Plate, and Foil Manufacturing' },
  { value: '331318', label: 'Other Aluminum Rolling, Drawing, and Extruding' },
  {
    value: '331410',
    label: 'Nonferrous Metal (except Aluminum) Smelting and Refining'
  },
  {
    value: '331420',
    label: 'Copper Rolling, Drawing, Extruding, and Alloying'
  },
  {
    value: '331491',
    label:
      'Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding'
  },
  {
    value: '331492',
    label:
      'Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum)'
  },
  { value: '331511', label: 'Iron Foundries' },
  { value: '331512', label: 'Steel Investment Foundries' },
  { value: '331513', label: 'Steel Foundries (except Investment)' },
  { value: '331523', label: 'Nonferrous Metal Die-Casting Foundries' },
  { value: '331524', label: 'Aluminum Foundries (except Die-Casting)' },
  {
    value: '331529',
    label: 'Other Nonferrous Metal Foundries (except Die-Casting)'
  },
  { value: '332111', label: 'Iron and Steel Forging' },
  { value: '332112', label: 'Nonferrous Forging' },
  { value: '332114', label: 'Custom Roll Forming' },
  { value: '332117', label: 'Powder Metallurgy Part Manufacturing' },
  {
    value: '332119',
    label: 'Metal Crown, Closure, and Other Metal Stamping (except Automotive)'
  },
  {
    value: '332215',
    label:
      'Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing'
  },
  { value: '332216', label: 'Saw Blade and Handtool Manufacturing' },
  {
    value: '332311',
    label: 'Prefabricated Metal Building and Component Manufacturing'
  },
  { value: '332312', label: 'Fabricated Structural Metal Manufacturing' },
  { value: '332313', label: 'Plate Work Manufacturing' },
  { value: '332321', label: 'Metal Window and Door Manufacturing' },
  { value: '332322', label: 'Sheet Metal Work Manufacturing' },
  {
    value: '332323',
    label: 'Ornamental and Architectural Metal Work Manufacturing'
  },
  { value: '332410', label: 'Power Boiler and Heat Exchanger Manufacturing' },
  { value: '332420', label: 'Metal Tank (Heavy Gauge) Manufacturing' },
  { value: '332431', label: 'Metal Can Manufacturing' },
  { value: '332439', label: 'Other Metal Container Manufacturing' },
  { value: '332510', label: 'Hardware Manufacturing' },
  { value: '332613', label: 'Spring Manufacturing' },
  { value: '332618', label: 'Other Fabricated Wire Product Manufacturing' },
  { value: '332710', label: 'Machine Shops' },
  { value: '332721', label: 'Precision Turned Product Manufacturing' },
  {
    value: '332722',
    label: 'Bolt, Nut, Screw, Rivet, and Washer Manufacturing'
  },
  { value: '332811', label: 'Metal Heat Treating' },
  {
    value: '332812',
    label:
      'Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers'
  },
  {
    value: '332813',
    label: 'Electroplating, Plating, Polishing, Anodizing, and Coloring'
  },
  { value: '332911', label: 'Industrial Valve Manufacturing' },
  {
    value: '332912',
    label: 'Fluid Power Valve and Hose Fitting Manufacturing'
  },
  { value: '332913', label: 'Plumbing Fixture Fitting and Trim Manufacturing' },
  {
    value: '332919',
    label: 'Other Metal Valve and Pipe Fitting Manufacturing'
  },
  { value: '332991', label: 'Ball and Roller Bearing Manufacturing' },
  { value: '332992', label: 'Small Arms Ammunition Manufacturing' },
  { value: '332993', label: 'Ammunition (except Small Arms) Manufacturing' },
  {
    value: '332994',
    label: 'Small Arms, Ordnance, and Ordnance Accessories Manufacturing'
  },
  { value: '332996', label: 'Fabricated Pipe and Pipe Fitting Manufacturing' },
  {
    value: '332999',
    label: 'All Other Miscellaneous Fabricated Metal Product Manufacturing'
  },
  { value: '333111', label: 'Farm Machinery and Equipment Manufacturing' },
  {
    value: '333112',
    label:
      'Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing'
  },
  { value: '333120', label: 'Construction Machinery Manufacturing' },
  { value: '333131', label: 'Mining Machinery and Equipment Manufacturing' },
  {
    value: '333132',
    label: 'Oil and Gas Field Machinery and Equipment Manufacturing'
  },
  { value: '333241', label: 'Food Product Machinery Manufacturing' },
  { value: '333242', label: 'Semiconductor Machinery Manufacturing' },
  {
    value: '333243',
    label: 'Sawmill, Woodworking, and Paper Machinery Manufacturing'
  },
  { value: '333248', label: 'All Other Industrial Machinery Manufacturing' },
  {
    value: '333310',
    label: 'Commercial and Service Industry Machinery Manufacturing'
  },
  {
    value: '333413',
    label:
      'Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing'
  },
  {
    value: '333414',
    label: 'Heating Equipment (except Warm Air Furnaces) Manufacturing'
  },
  {
    value: '333415',
    label:
      'Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing'
  },
  { value: '333511', label: 'Industrial Mold Manufacturing' },
  {
    value: '333514',
    label: 'Special Die and Tool, Die Set, Jig, and Fixture Manufacturing'
  },
  {
    value: '333515',
    label: 'Cutting Tool and Machine Tool Accessory Manufacturing'
  },
  { value: '333517', label: 'Machine Tool Manufacturing' },
  {
    value: '333519',
    label: 'Rolling Mill and Other Metalworking Machinery Manufacturing'
  },
  {
    value: '333611',
    label: 'Turbine and Turbine Generator Set Units Manufacturing'
  },
  {
    value: '333612',
    label: 'Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing'
  },
  {
    value: '333613',
    label: 'Mechanical Power Transmission Equipment Manufacturing'
  },
  { value: '333618', label: 'Other Engine Equipment Manufacturing' },
  { value: '333912', label: 'Air and Gas Compressor Manufacturing' },
  {
    value: '333914',
    label: 'Measuring, Dispensing, and Other Pumping Equipment Manufacturing'
  },
  { value: '333921', label: 'Elevator and Moving Stairway Manufacturing' },
  { value: '333922', label: 'Conveyor and Conveying Equipment Manufacturing' },
  {
    value: '333923',
    label: 'Overhead Traveling Crane, Hoist, and Monorail System Manufacturing'
  },
  {
    value: '333924',
    label:
      'Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing'
  },
  { value: '333991', label: 'Power-Driven Handtool Manufacturing' },
  { value: '333992', label: 'Welding and Soldering Equipment Manufacturing' },
  { value: '333993', label: 'Packaging Machinery Manufacturing' },
  {
    value: '333994',
    label: 'Industrial Process Furnace and Oven Manufacturing'
  },
  { value: '333995', label: 'Fluid Power Cylinder and Actuator Manufacturing' },
  { value: '333996', label: 'Fluid Power Pump and Motor Manufacturing' },
  {
    value: '333998',
    label: 'All Other Miscellaneous General Purpose Machinery Manufacturing'
  },
  { value: '334111', label: 'Electronic Computer Manufacturing' },
  { value: '334112', label: 'Computer Storage Device Manufacturing' },
  {
    value: '334118',
    label:
      'Computer Terminal and Other Computer Peripheral Equipment Manufacturing'
  },
  { value: '334210', label: 'Telephone Apparatus Manufacturing' },
  {
    value: '334220',
    label:
      'Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing'
  },
  { value: '334290', label: 'Other Communications Equipment Manufacturing' },
  { value: '334310', label: 'Audio and Video Equipment Manufacturing' },
  { value: '334412', label: 'Bare Printed Circuit Board Manufacturing' },
  { value: '334413', label: 'Semiconductor and Related Device Manufacturing' },
  {
    value: '334416',
    label:
      'Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing'
  },
  { value: '334417', label: 'Electronic Connector Manufacturing' },
  {
    value: '334418',
    label: 'Printed Circuit Assembly (Electronic Assembly) Manufacturing'
  },
  { value: '334419', label: 'Other Electronic Component Manufacturing' },
  {
    value: '334510',
    label: 'Electromedical and Electrotherapeutic Apparatus Manufacturing'
  },
  {
    value: '334511',
    label:
      'Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing'
  },
  {
    value: '334512',
    label:
      'Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use'
  },
  {
    value: '334513',
    label:
      'Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables'
  },
  {
    value: '334514',
    label: 'Totalizing Fluid Meter and Counting Device Manufacturing'
  },
  {
    value: '334515',
    label:
      'Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals'
  },
  { value: '334516', label: 'Analytical Laboratory Instrument Manufacturing' },
  { value: '334517', label: 'Irradiation Apparatus Manufacturing' },
  {
    value: '334519',
    label: 'Other Measuring and Controlling Device Manufacturing'
  },
  {
    value: '334610',
    label: 'Manufacturing and Reproducing Magnetic and Optical Media'
  },
  {
    value: '335131',
    label: 'Residential Electric Lighting Fixture Manufacturing'
  },
  {
    value: '335132',
    label:
      'Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing'
  },
  {
    value: '335139',
    label: 'Electric Lamp Bulb and Other Lighting Equipment Manufacturing'
  },
  { value: '335210', label: 'Small Electrical Appliance Manufacturing' },
  { value: '335220', label: 'Major Household Appliance Manufacturing' },
  {
    value: '335311',
    label: 'Power, Distribution, and Specialty Transformer Manufacturing'
  },
  { value: '335312', label: 'Motor and Generator Manufacturing' },
  {
    value: '335313',
    label: 'Switchgear and Switchboard Apparatus Manufacturing'
  },
  { value: '335314', label: 'Relay and Industrial Control Manufacturing' },
  { value: '335910', label: 'Battery Manufacturing' },
  { value: '335921', label: 'Fiber Optic Cable Manufacturing' },
  {
    value: '335929',
    label: 'Other Communication and Energy Wire Manufacturing'
  },
  { value: '335931', label: 'Current-Carrying Wiring Device Manufacturing' },
  { value: '335932', label: 'Noncurrent-Carrying Wiring Device Manufacturing' },
  { value: '335991', label: 'Carbon and Graphite Product Manufacturing' },
  {
    value: '335999',
    label:
      'All Other Miscellaneous Electrical Equipment and Component Manufacturing'
  },
  {
    value: '336110',
    label: 'Automobile and Light Duty Motor Vehicle Manufacturing'
  },
  { value: '336120', label: 'Heavy Duty Truck Manufacturing' },
  { value: '336211', label: 'Motor Vehicle Body Manufacturing' },
  { value: '336212', label: 'Truck Trailer Manufacturing' },
  { value: '336213', label: 'Motor Home Manufacturing' },
  { value: '336214', label: 'Travel Trailer and Camper Manufacturing' },
  {
    value: '336310',
    label: 'Motor Vehicle Gasoline Engine and Engine Parts Manufacturing'
  },
  {
    value: '336320',
    label: 'Motor Vehicle Electrical and Electronic Equipment Manufacturing'
  },
  {
    value: '336330',
    label:
      'Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing'
  },
  { value: '336340', label: 'Motor Vehicle Brake System Manufacturing' },
  {
    value: '336350',
    label: 'Motor Vehicle Transmission and Power Train Parts Manufacturing'
  },
  {
    value: '336360',
    label: 'Motor Vehicle Seating and Interior Trim Manufacturing'
  },
  { value: '336370', label: 'Motor Vehicle Metal Stamping' },
  { value: '336390', label: 'Other Motor Vehicle Parts Manufacturing' },
  { value: '336411', label: 'Aircraft Manufacturing' },
  { value: '336412', label: 'Aircraft Engine and Engine Parts Manufacturing' },
  {
    value: '336413',
    label: 'Other Aircraft Parts and Auxiliary Equipment Manufacturing'
  },
  { value: '336414', label: 'Guided Missile and Space Vehicle Manufacturing' },
  {
    value: '336415',
    label:
      'Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing'
  },
  {
    value: '336419',
    label:
      'Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing'
  },
  { value: '336510', label: 'Railroad Rolling Stock Manufacturing' },
  { value: '336611', label: 'Ship Building and Repairing' },
  { value: '336612', label: 'Boat Building' },
  { value: '336991', label: 'Motorcycle, Bicycle, and Parts Manufacturing' },
  {
    value: '336992',
    label: 'Military Armored Vehicle, Tank, and Tank Component Manufacturing'
  },
  {
    value: '336999',
    label: 'All Other Transportation Equipment Manufacturing'
  },
  {
    value: '337110',
    label: 'Wood Kitchen Cabinet and Countertop Manufacturing'
  },
  { value: '337121', label: 'Upholstered Household Furniture Manufacturing' },
  {
    value: '337122',
    label: 'Nonupholstered Wood Household Furniture Manufacturing'
  },
  {
    value: '337126',
    label: 'Household Furniture (except Wood and Upholstered) Manufacturing'
  },
  { value: '337127', label: 'Institutional Furniture Manufacturing' },
  { value: '337211', label: 'Wood Office Furniture Manufacturing' },
  {
    value: '337212',
    label: 'Custom Architectural Woodwork and Millwork Manufacturing'
  },
  { value: '337214', label: 'Office Furniture (except Wood) Manufacturing' },
  {
    value: '337215',
    label: 'Showcase, Partition, Shelving, and Locker Manufacturing'
  },
  { value: '337910', label: 'Mattress Manufacturing' },
  { value: '337920', label: 'Blind and Shade Manufacturing' },
  { value: '339112', label: 'Surgical and Medical Instrument Manufacturing' },
  { value: '339113', label: 'Surgical Appliance and Supplies Manufacturing' },
  { value: '339114', label: 'Dental Equipment and Supplies Manufacturing' },
  { value: '339115', label: 'Ophthalmic Goods Manufacturing' },
  { value: '339116', label: 'Dental Laboratories' },
  { value: '339910', label: 'Jewelry and Silverware Manufacturing' },
  { value: '339920', label: 'Sporting and Athletic Goods Manufacturing' },
  { value: '339930', label: 'Doll, Toy, and Game Manufacturing' },
  { value: '339940', label: 'Office Supplies (except Paper) Manufacturing' },
  { value: '339950', label: 'Sign Manufacturing' },
  {
    value: '339991',
    label: 'Gasket, Packing, and Sealing Device Manufacturing'
  },
  { value: '339992', label: 'Musical Instrument Manufacturing' },
  { value: '339993', label: 'Fastener, Button, Needle, and Pin Manufacturing' },
  { value: '339994', label: 'Broom, Brush, and Mop Manufacturing' },
  { value: '339995', label: 'Burial Casket Manufacturing' },
  { value: '339999', label: 'All Other Miscellaneous Manufacturing' },
  {
    value: '423110',
    label: 'Automobile and Other Motor Vehicle Merchant Wholesalers'
  },
  {
    value: '423120',
    label: 'Motor Vehicle Supplies and New Parts Merchant Wholesalers'
  },
  { value: '423130', label: 'Tire and Tube Merchant Wholesalers' },
  { value: '423140', label: 'Motor Vehicle Parts (Used) Merchant Wholesalers' },
  { value: '423210', label: 'Furniture Merchant Wholesalers' },
  { value: '423220', label: 'Home Furnishing Merchant Wholesalers' },
  {
    value: '423310',
    label: 'Lumber, Plywood, Millwork, and Wood Panel Merchant Wholesalers'
  },
  {
    value: '423320',
    label:
      'Brick, Stone, and Related Construction Material Merchant Wholesalers'
  },
  {
    value: '423330',
    label: 'Roofing, Siding, and Insulation Material Merchant Wholesalers'
  },
  {
    value: '423390',
    label: 'Other Construction Material Merchant Wholesalers'
  },
  {
    value: '423410',
    label: 'Photographic Equipment and Supplies Merchant Wholesalers'
  },
  { value: '423420', label: 'Office Equipment Merchant Wholesalers' },
  {
    value: '423430',
    label:
      'Computer and Computer Peripheral Equipment and Software Merchant Wholesalers'
  },
  { value: '423440', label: 'Other Commercial Equipment Merchant Wholesalers' },
  {
    value: '423450',
    label:
      'Medical, Dental, and Hospital Equipment and Supplies Merchant Wholesalers'
  },
  { value: '423460', label: 'Ophthalmic Goods Merchant Wholesalers' },
  {
    value: '423490',
    label: 'Other Professional Equipment and Supplies Merchant Wholesalers'
  },
  {
    value: '423510',
    label: 'Metal Service Centers and Other Metal Merchant Wholesalers'
  },
  {
    value: '423520',
    label: 'Coal and Other Mineral and Ore Merchant Wholesalers'
  },
  {
    value: '423610',
    label:
      'Electrical Apparatus and Equipment, Wiring Supplies, and Related Equipment Merchant Wholesalers'
  },
  {
    value: '423620',
    label:
      'Household Appliances, Electric Housewares, and Consumer Electronics Merchant Wholesalers'
  },
  {
    value: '423690',
    label: 'Other Electronic Parts and Equipment Merchant Wholesalers'
  },
  { value: '423710', label: 'Hardware Merchant Wholesalers' },
  {
    value: '423720',
    label:
      'Plumbing and Heating Equipment and Supplies (Hydronics) Merchant Wholesalers'
  },
  {
    value: '423730',
    label:
      'Warm Air Heating and Air-Conditioning Equipment and Supplies Merchant Wholesalers'
  },
  {
    value: '423740',
    label: 'Refrigeration Equipment and Supplies Merchant Wholesalers'
  },
  {
    value: '423810',
    label:
      'Construction and Mining (except Oil Well) Machinery and Equipment Merchant Wholesalers'
  },
  {
    value: '423820',
    label: 'Farm and Garden Machinery and Equipment Merchant Wholesalers'
  },
  {
    value: '423830',
    label: 'Industrial Machinery and Equipment Merchant Wholesalers'
  },
  { value: '423840', label: 'Industrial Supplies Merchant Wholesalers' },
  {
    value: '423850',
    label: 'Service Establishment Equipment and Supplies Merchant Wholesalers'
  },
  {
    value: '423860',
    label:
      'Transportation Equipment and Supplies (except Motor Vehicle) Merchant Wholesalers'
  },
  {
    value: '423910',
    label: 'Sporting and Recreational Goods and Supplies Merchant Wholesalers'
  },
  {
    value: '423920',
    label: 'Toy and Hobby Goods and Supplies Merchant Wholesalers'
  },
  { value: '423930', label: 'Recyclable Material Merchant Wholesalers' },
  {
    value: '423940',
    label:
      'Jewelry, Watch, Precious Stone, and Precious Metal Merchant Wholesalers'
  },
  {
    value: '423990',
    label: 'Other Miscellaneous Durable Goods Merchant Wholesalers'
  },
  { value: '424110', label: 'Printing and Writing Paper Merchant Wholesalers' },
  {
    value: '424120',
    label: 'Stationery and Office Supplies Merchant Wholesalers'
  },
  {
    value: '424130',
    label: 'Industrial and Personal Service Paper Merchant Wholesalers'
  },
  {
    value: '424210',
    label: "Drugs and Druggists' Sundries Merchant Wholesalers"
  },
  {
    value: '424310',
    label: 'Piece Goods, Notions, and Other Dry Goods Merchant Wholesalers'
  },
  { value: '424340', label: 'Footwear Merchant Wholesalers' },
  {
    value: '424350',
    label: 'Clothing and Clothing Accessories Merchant Wholesalers'
  },
  { value: '424410', label: 'General Line Grocery Merchant Wholesalers' },
  { value: '424420', label: 'Packaged Frozen Food Merchant Wholesalers' },
  {
    value: '424430',
    label: 'Dairy Product (except Dried or Canned) Merchant Wholesalers'
  },
  {
    value: '424440',
    label: 'Poultry and Poultry Product Merchant Wholesalers'
  },
  { value: '424450', label: 'Confectionery Merchant Wholesalers' },
  { value: '424460', label: 'Fish and Seafood Merchant Wholesalers' },
  { value: '424470', label: 'Meat and Meat Product Merchant Wholesalers' },
  { value: '424480', label: 'Fresh Fruit and Vegetable Merchant Wholesalers' },
  {
    value: '424490',
    label: 'Other Grocery and Related Products Merchant Wholesalers'
  },
  { value: '424510', label: 'Grain and Field Bean Merchant Wholesalers' },
  { value: '424520', label: 'Livestock Merchant Wholesalers' },
  {
    value: '424590',
    label: 'Other Farm Product Raw Material Merchant Wholesalers'
  },
  {
    value: '424610',
    label: 'Plastics Materials and Basic Forms and Shapes Merchant Wholesalers'
  },
  {
    value: '424690',
    label: 'Other Chemical and Allied Products Merchant Wholesalers'
  },
  { value: '424710', label: 'Petroleum Bulk Stations and Terminals' },
  {
    value: '424720',
    label:
      'Petroleum and Petroleum Products Merchant Wholesalers (except Bulk Stations and Terminals)'
  },
  { value: '424810', label: 'Beer and Ale Merchant Wholesalers' },
  {
    value: '424820',
    label: 'Wine and Distilled Alcoholic Beverage Merchant Wholesalers'
  },
  { value: '424910', label: 'Farm Supplies Merchant Wholesalers' },
  {
    value: '424920',
    label: 'Book, Periodical, and Newspaper Merchant Wholesalers'
  },
  {
    value: '424930',
    label: "Flower, Nursery Stock, and Florists' Supplies Merchant Wholesalers"
  },
  {
    value: '424940',
    label: 'Tobacco Product and Electronic Cigarette Merchant Wholesalers'
  },
  {
    value: '424950',
    label: 'Paint, Varnish, and Supplies Merchant Wholesalers'
  },
  {
    value: '424990',
    label: 'Other Miscellaneous Nondurable Goods Merchant Wholesalers'
  },
  { value: '425120', label: 'Wholesale Trade Agents and Brokers' },
  { value: '441110', label: 'New Car Dealers' },
  { value: '441120', label: 'Used Car Dealers' },
  { value: '441210', label: 'Recreational Vehicle Dealers' },
  { value: '441222', label: 'Boat Dealers' },
  {
    value: '441227',
    label: 'Motorcycle, ATV, and All Other Motor Vehicle Dealers'
  },
  { value: '441330', label: 'Automotive Parts and Accessories Retailers' },
  { value: '441340', label: 'Tire Dealers' },
  { value: '444110', label: 'Home Centers' },
  { value: '444120', label: 'Paint and Wallpaper Retailers' },
  { value: '444140', label: 'Hardware Retailers' },
  { value: '444180', label: 'Other Building Material Dealers' },
  { value: '444230', label: 'Outdoor Power Equipment Retailers' },
  {
    value: '444240',
    label: 'Nursery, Garden Center, and Farm Supply Retailers'
  },
  {
    value: '445110',
    label:
      'Supermarkets and Other Grocery Retailers (except Convenience Retailers)'
  },
  { value: '445131', label: 'Convenience Retailers' },
  { value: '445132', label: 'Vending Machine Operators' },
  { value: '445230', label: 'Fruit and Vegetable Retailers' },
  { value: '445240', label: 'Meat Retailers' },
  { value: '445250', label: 'Fish and Seafood Retailers' },
  { value: '445291', label: 'Baked Goods Retailers' },
  { value: '445292', label: 'Confectionery and Nut Retailers' },
  { value: '445298', label: 'All Other Specialty Food Retailers' },
  { value: '445320', label: 'Beer, Wine, and Liquor Retailers' },
  { value: '449110', label: 'Furniture Retailers' },
  { value: '449121', label: 'Floor Covering Retailers' },
  { value: '449122', label: 'Window Treatment Retailers' },
  { value: '449129', label: 'All Other Home Furnishings Retailers' },
  { value: '449210', label: 'Electronics and Appliance Retailers' },
  { value: '455110', label: 'Department Stores' },
  { value: '455211', label: 'Warehouse Clubs and Supercenters' },
  { value: '455219', label: 'All Other General Merchandise Retailers' },
  { value: '456110', label: 'Pharmacies and Drug Retailers' },
  {
    value: '456120',
    label: 'Cosmetics, Beauty Supplies, and Perfume Retailers'
  },
  { value: '456130', label: 'Optical Goods Retailers' },
  { value: '456191', label: 'Food (Health) Supplement Retailers' },
  { value: '456199', label: 'All Other Health and Personal Care Retailers' },
  { value: '457110', label: 'Gasoline Stations with Convenience Stores' },
  { value: '457120', label: 'Other Gasoline Stations' },
  { value: '457210', label: 'Fuel Dealers' },
  { value: '458110', label: 'Clothing and Clothing Accessories Retailers' },
  { value: '458210', label: 'Shoe Retailers' },
  { value: '458310', label: 'Jewelry Retailers' },
  { value: '458320', label: 'Luggage and Leather Goods Retailers' },
  { value: '459110', label: 'Sporting Goods Retailers' },
  { value: '459120', label: 'Hobby, Toy, and Game Retailers' },
  { value: '459130', label: 'Sewing, Needlework, and Piece Goods Retailers' },
  { value: '459140', label: 'Musical Instrument and Supplies Retailers' },
  { value: '459210', label: 'Book Retailers and News Dealers' },
  { value: '459310', label: 'Florists' },
  { value: '459410', label: 'Office Supplies and Stationery Retailers' },
  { value: '459420', label: 'Gift, Novelty, and Souvenir Retailers' },
  { value: '459510', label: 'Used Merchandise Retailers' },
  { value: '459910', label: 'Pet and Pet Supplies Retailers' },
  { value: '459920', label: 'Art Dealers' },
  { value: '459930', label: 'Manufactured (Mobile) Home Dealers' },
  {
    value: '459991',
    label: 'Tobacco, Electronic Cigarette, and Other Smoking Supplies Retailers'
  },
  { value: '459999', label: 'All Other Miscellaneous Retailers' },
  { value: '481111', label: 'Scheduled Passenger Air Transportation' },
  { value: '481112', label: 'Scheduled Freight Air Transportation' },
  {
    value: '481211',
    label: 'Nonscheduled Chartered Passenger Air Transportation'
  },
  {
    value: '481212',
    label: 'Nonscheduled Chartered Freight Air Transportation'
  },
  { value: '481219', label: 'Other Nonscheduled Air Transportation' },
  { value: '482111', label: 'Line-Haul Railroads' },
  { value: '482112', label: 'Short Line Railroads' },
  { value: '483111', label: 'Deep Sea Freight Transportation' },
  { value: '483112', label: 'Deep Sea Passenger Transportation' },
  { value: '483113', label: 'Coastal and Great Lakes Freight Transportation' },
  {
    value: '483114',
    label: 'Coastal and Great Lakes Passenger Transportation'
  },
  { value: '483211', label: 'Inland Water Freight Transportation' },
  { value: '483212', label: 'Inland Water Passenger Transportation' },
  { value: '484110', label: 'General Freight Trucking, Local' },
  {
    value: '484121',
    label: 'General Freight Trucking, Long-Distance, Truckload'
  },
  {
    value: '484122',
    label: 'General Freight Trucking, Long-Distance, Less Than Truckload'
  },
  { value: '484210', label: 'Used Household and Office Goods Moving' },
  {
    value: '484220',
    label: 'Specialized Freight (except Used Goods) Trucking, Local'
  },
  {
    value: '484230',
    label: 'Specialized Freight (except Used Goods) Trucking, Long-Distance'
  },
  { value: '485111', label: 'Mixed Mode Transit Systems' },
  { value: '485112', label: 'Commuter Rail Systems' },
  { value: '485113', label: 'Bus and Other Motor Vehicle Transit Systems' },
  { value: '485119', label: 'Other Urban Transit Systems' },
  { value: '485210', label: 'Interurban and Rural Bus Transportation' },
  { value: '485310', label: 'Taxi and Ridesharing Services' },
  { value: '485320', label: 'Limousine Service' },
  { value: '485410', label: 'School and Employee Bus Transportation' },
  { value: '485510', label: 'Charter Bus Industry' },
  { value: '485991', label: 'Special Needs Transportation' },
  {
    value: '485999',
    label: 'All Other Transit and Ground Passenger Transportation'
  },
  { value: '486110', label: 'Pipeline Transportation of Crude Oil' },
  { value: '486210', label: 'Pipeline Transportation of Natural Gas' },
  {
    value: '486910',
    label: 'Pipeline Transportation of Refined Petroleum Products'
  },
  { value: '486990', label: 'All Other Pipeline Transportation' },
  { value: '487110', label: 'Scenic and Sightseeing Transportation, Land' },
  { value: '487210', label: 'Scenic and Sightseeing Transportation, Water' },
  { value: '487990', label: 'Scenic and Sightseeing Transportation, Other' },
  { value: '488111', label: 'Air Traffic Control' },
  { value: '488119', label: 'Other Airport Operations' },
  { value: '488190', label: 'Other Support Activities for Air Transportation' },
  { value: '488210', label: 'Support Activities for Rail Transportation' },
  { value: '488310', label: 'Port and Harbor Operations' },
  { value: '488320', label: 'Marine Cargo Handling' },
  { value: '488330', label: 'Navigational Services to Shipping' },
  {
    value: '488390',
    label: 'Other Support Activities for Water Transportation'
  },
  { value: '488410', label: 'Motor Vehicle Towing' },
  {
    value: '488490',
    label: 'Other Support Activities for Road Transportation'
  },
  { value: '488510', label: 'Freight Transportation Arrangement' },
  { value: '488991', label: 'Packing and Crating' },
  { value: '488999', label: 'All Other Support Activities for Transportation' },
  { value: '491110', label: 'Postal Service' },
  { value: '492110', label: 'Couriers and Express Delivery Services' },
  { value: '492210', label: 'Local Messengers and Local Delivery' },
  { value: '493110', label: 'General Warehousing and Storage' },
  { value: '493120', label: 'Refrigerated Warehousing and Storage' },
  { value: '493130', label: 'Farm Product Warehousing and Storage' },
  { value: '493190', label: 'Other Warehousing and Storage' },
  { value: '512110', label: 'Motion Picture and Video Production' },
  { value: '512120', label: 'Motion Picture and Video Distribution' },
  { value: '512131', label: 'Motion Picture Theaters (except Drive-Ins)' },
  { value: '512132', label: 'Drive-In Motion Picture Theaters' },
  {
    value: '512191',
    label: 'Teleproduction and Other Postproduction Services'
  },
  { value: '512199', label: 'Other Motion Picture and Video Industries' },
  { value: '512230', label: 'Music Publishers' },
  { value: '512240', label: 'Sound Recording Studios' },
  { value: '512250', label: 'Record Production and Distribution' },
  { value: '512290', label: 'Other Sound Recording Industries' },
  { value: '513110', label: 'Newspaper Publishers' },
  { value: '513120', label: 'Periodical Publishers' },
  { value: '513130', label: 'Book Publishers' },
  { value: '513140', label: 'Directory and Mailing List Publishers' },
  { value: '513191', label: 'Greeting Card Publishers' },
  { value: '513199', label: 'All Other Publishers' },
  { value: '513210', label: 'Software Publishers' },
  { value: '516110', label: 'Radio Broadcasting Stations' },
  { value: '516120', label: 'Television Broadcasting Stations' },
  {
    value: '516210',
    label:
      'Media Streaming Distribution Services, Social Networks, and Other Media Networks and Content Providers'
  },
  { value: '517111', label: 'Wired Telecommunications Carriers' },
  {
    value: '517112',
    label: 'Wireless Telecommunications Carriers (except Satellite)'
  },
  { value: '517121', label: 'Telecommunications Resellers' },
  { value: '517122', label: 'Agents for Wireless Telecommunications Services' },
  { value: '517410', label: 'Satellite Telecommunications' },
  { value: '517810', label: 'All Other Telecommunications' },
  {
    value: '518210',
    label:
      'Computing Infrastructure Providers, Data Processing, Web Hosting, and Related Services'
  },
  { value: '519210', label: 'Libraries and Archives' },
  {
    value: '519290',
    label: 'Web Search Portals and All Other Information Services'
  },
  { value: '521110', label: 'Monetary Authorities-Central Bank' },
  { value: '522110', label: 'Commercial Banking' },
  { value: '522130', label: 'Credit Unions' },
  {
    value: '522180',
    label: 'Savings Institutions and Other Depository Credit Intermediation'
  },
  { value: '522210', label: 'Credit Card Issuing' },
  { value: '522220', label: 'Sales Financing' },
  { value: '522291', label: 'Consumer Lending' },
  { value: '522292', label: 'Real Estate Credit' },
  {
    value: '522299',
    label:
      'International, Secondary Market, and All Other Nondepository Credit Intermediation'
  },
  { value: '522310', label: 'Mortgage and Nonmortgage Loan Brokers' },
  {
    value: '522320',
    label:
      'Financial Transactions Processing, Reserve, and Clearinghouse Activities'
  },
  {
    value: '522390',
    label: 'Other Activities Related to Credit Intermediation'
  },
  {
    value: '523150',
    label: 'Investment Banking and Securities Intermediation'
  },
  { value: '523160', label: 'Commodity Contracts Intermediation' },
  { value: '523210', label: 'Securities and Commodity Exchanges' },
  { value: '523910', label: 'Miscellaneous Intermediation' },
  { value: '523940', label: 'Portfolio Management and Investment Advice' },
  { value: '523991', label: 'Trust, Fiduciary, and Custody Activities' },
  { value: '523999', label: 'Miscellaneous Financial Investment Activities' },
  { value: '524113', label: 'Direct Life Insurance Carriers' },
  { value: '524114', label: 'Direct Health and Medical Insurance Carriers' },
  { value: '524126', label: 'Direct Property and Casualty Insurance Carriers' },
  { value: '524127', label: 'Direct Title Insurance Carriers' },
  {
    value: '524128',
    label: 'Other Direct Insurance (except Life, Health, and Medical) Carriers'
  },
  { value: '524130', label: 'Reinsurance Carriers' },
  { value: '524210', label: 'Insurance Agencies and Brokerages' },
  { value: '524291', label: 'Claims Adjusting' },
  {
    value: '524292',
    label:
      'Pharmacy Benefit Management and Other Third Party Administration of Insurance and Pension Funds'
  },
  { value: '524298', label: 'All Other Insurance Related Activities' },
  { value: '525110', label: 'Pension Funds' },
  { value: '525120', label: 'Health and Welfare Funds' },
  { value: '525190', label: 'Other Insurance Funds' },
  { value: '525910', label: 'Open-End Investment Funds' },
  { value: '525920', label: 'Trusts, Estates, and Agency Accounts' },
  { value: '525990', label: 'Other Financial Vehicles' },
  { value: '531110', label: 'Lessors of Residential Buildings and Dwellings' },
  {
    value: '531120',
    label: 'Lessors of Nonresidential Buildings (except Miniwarehouses)'
  },
  {
    value: '531130',
    label: 'Lessors of Miniwarehouses and Self-Storage Units'
  },
  { value: '531190', label: 'Lessors of Other Real Estate Property' },
  { value: '531210', label: 'Offices of Real Estate Agents and Brokers' },
  { value: '531311', label: 'Residential Property Managers' },
  { value: '531312', label: 'Nonresidential Property Managers' },
  { value: '531320', label: 'Offices of Real Estate Appraisers' },
  { value: '531390', label: 'Other Activities Related to Real Estate' },
  { value: '532111', label: 'Passenger Car Rental' },
  { value: '532112', label: 'Passenger Car Leasing' },
  {
    value: '532120',
    label:
      'Truck, Utility Trailer, and RV (Recreational Vehicle) Rental and Leasing'
  },
  { value: '532210', label: 'Consumer Electronics and Appliances Rental' },
  { value: '532281', label: 'Formal Wear and Costume Rental' },
  { value: '532282', label: 'Video Tape and Disc Rental' },
  { value: '532283', label: 'Home Health Equipment Rental' },
  { value: '532284', label: 'Recreational Goods Rental' },
  { value: '532289', label: 'All Other Consumer Goods Rental' },
  { value: '532310', label: 'General Rental Centers' },
  {
    value: '532411',
    label:
      'Commercial Air, Rail, and Water Transportation Equipment Rental and Leasing'
  },
  {
    value: '532412',
    label:
      'Construction, Mining, and Forestry Machinery and Equipment Rental and Leasing'
  },
  {
    value: '532420',
    label: 'Office Machinery and Equipment Rental and Leasing'
  },
  {
    value: '532490',
    label:
      'Other Commercial and Industrial Machinery and Equipment Rental and Leasing'
  },
  {
    value: '533110',
    label:
      'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)'
  },
  { value: '541110', label: 'Offices of Lawyers' },
  { value: '541120', label: 'Offices of Notaries' },
  { value: '541191', label: 'Title Abstract and Settlement Offices' },
  { value: '541199', label: 'All Other Legal Services' },
  { value: '541211', label: 'Offices of Certified Public Accountants' },
  { value: '541213', label: 'Tax Preparation Services' },
  { value: '541214', label: 'Payroll Services' },
  { value: '541219', label: 'Other Accounting Services' },
  { value: '541310', label: 'Architectural Services' },
  { value: '541320', label: 'Landscape Architectural Services' },
  { value: '541330', label: 'Engineering Services' },
  { value: '541340', label: 'Drafting Services' },
  { value: '541350', label: 'Building Inspection Services' },
  { value: '541360', label: 'Geophysical Surveying and Mapping Services' },
  {
    value: '541370',
    label: 'Surveying and Mapping (except Geophysical) Services'
  },
  { value: '541380', label: 'Testing Laboratories and Services' },
  { value: '541410', label: 'Interior Design Services' },
  { value: '541420', label: 'Industrial Design Services' },
  { value: '541430', label: 'Graphic Design Services' },
  { value: '541490', label: 'Other Specialized Design Services' },
  { value: '541511', label: 'Custom Computer Programming Services' },
  { value: '541512', label: 'Computer Systems Design Services' },
  { value: '541513', label: 'Computer Facilities Management Services' },
  { value: '541519', label: 'Other Computer Related Services' },
  {
    value: '541611',
    label:
      'Administrative Management and General Management Consulting Services'
  },
  { value: '541612', label: 'Human Resources Consulting Services' },
  { value: '541613', label: 'Marketing Consulting Services' },
  {
    value: '541614',
    label: 'Process, Physical Distribution, and Logistics Consulting Services'
  },
  { value: '541618', label: 'Other Management Consulting Services' },
  { value: '541620', label: 'Environmental Consulting Services' },
  {
    value: '541690',
    label: 'Other Scientific and Technical Consulting Services'
  },
  { value: '541713', label: 'Research and Development in Nanotechnology' },
  {
    value: '541714',
    label:
      'Research and Development in Biotechnology (except Nanobiotechnology)'
  },
  {
    value: '541715',
    label:
      'Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)'
  },
  {
    value: '541720',
    label: 'Research and Development in the Social Sciences and Humanities'
  },
  { value: '541810', label: 'Advertising Agencies' },
  { value: '541820', label: 'Public Relations Agencies' },
  { value: '541830', label: 'Media Buying Agencies' },
  { value: '541840', label: 'Media Representatives' },
  { value: '541850', label: 'Indoor and Outdoor Display Advertising' },
  { value: '541860', label: 'Direct Mail Advertising' },
  { value: '541870', label: 'Advertising Material Distribution Services' },
  { value: '541890', label: 'Other Services Related to Advertising' },
  { value: '541910', label: 'Marketing Research and Public Opinion Polling' },
  { value: '541921', label: 'Photography Studios, Portrait' },
  { value: '541922', label: 'Commercial Photography' },
  { value: '541930', label: 'Translation and Interpretation Services' },
  { value: '541940', label: 'Veterinary Services' },
  {
    value: '541990',
    label: 'All Other Professional, Scientific, and Technical Services'
  },
  { value: '551111', label: 'Offices of Bank Holding Companies' },
  { value: '551112', label: 'Offices of Other Holding Companies' },
  {
    value: '551114',
    label: 'Corporate, Subsidiary, and Regional Managing Offices'
  },
  { value: '561110', label: 'Office Administrative Services' },
  { value: '561210', label: 'Facilities Support Services' },
  { value: '561311', label: 'Employment Placement Agencies' },
  { value: '561312', label: 'Executive Search Services' },
  { value: '561320', label: 'Temporary Help Services' },
  { value: '561330', label: 'Professional Employer Organizations' },
  { value: '561410', label: 'Document Preparation Services' },
  { value: '561421', label: 'Telephone Answering Services' },
  { value: '561422', label: 'Telemarketing Bureaus and Other Contact Centers' },
  { value: '561431', label: 'Private Mail Centers' },
  {
    value: '561439',
    label: 'Other Business Service Centers (including Copy Shops)'
  },
  { value: '561440', label: 'Collection Agencies' },
  { value: '561450', label: 'Credit Bureaus' },
  { value: '561491', label: 'Repossession Services' },
  { value: '561492', label: 'Court Reporting and Stenotype Services' },
  { value: '561499', label: 'All Other Business Support Services' },
  { value: '561510', label: 'Travel Agencies' },
  { value: '561520', label: 'Tour Operators' },
  { value: '561591', label: 'Convention and Visitors Bureaus' },
  {
    value: '561599',
    label: 'All Other Travel Arrangement and Reservation Services'
  },
  {
    value: '561611',
    label: 'Investigation and Personal Background Check Services'
  },
  { value: '561612', label: 'Security Guards and Patrol Services' },
  { value: '561613', label: 'Armored Car Services' },
  { value: '561621', label: 'Security Systems Services (except Locksmiths)' },
  { value: '561622', label: 'Locksmiths' },
  { value: '561710', label: 'Exterminating and Pest Control Services' },
  { value: '561720', label: 'Janitorial Services' },
  { value: '561730', label: 'Landscaping Services' },
  { value: '561740', label: 'Carpet and Upholstery Cleaning Services' },
  { value: '561790', label: 'Other Services to Buildings and Dwellings' },
  { value: '561910', label: 'Packaging and Labeling Services' },
  { value: '561920', label: 'Convention and Trade Show Organizers' },
  { value: '561990', label: 'All Other Support Services' },
  { value: '562111', label: 'Solid Waste Collection' },
  { value: '562112', label: 'Hazardous Waste Collection' },
  { value: '562119', label: 'Other Waste Collection' },
  { value: '562211', label: 'Hazardous Waste Treatment and Disposal' },
  { value: '562212', label: 'Solid Waste Landfill' },
  { value: '562213', label: 'Solid Waste Combustors and Incinerators' },
  { value: '562219', label: 'Other Nonhazardous Waste Treatment and Disposal' },
  { value: '562910', label: 'Remediation Services' },
  { value: '562920', label: 'Materials Recovery Facilities' },
  { value: '562991', label: 'Septic Tank and Related Services' },
  {
    value: '562998',
    label: 'All Other Miscellaneous Waste Management Services'
  },
  { value: '611110', label: 'Elementary and Secondary Schools' },
  { value: '611210', label: 'Junior Colleges' },
  {
    value: '611310',
    label: 'Colleges, Universities, and Professional Schools'
  },
  { value: '611410', label: 'Business and Secretarial Schools' },
  { value: '611420', label: 'Computer Training' },
  {
    value: '611430',
    label: 'Professional and Management Development Training'
  },
  { value: '611511', label: 'Cosmetology and Barber Schools' },
  { value: '611512', label: 'Flight Training' },
  { value: '611513', label: 'Apprenticeship Training' },
  { value: '611519', label: 'Other Technical and Trade Schools' },
  { value: '611610', label: 'Fine Arts Schools' },
  { value: '611620', label: 'Sports and Recreation Instruction' },
  { value: '611630', label: 'Language Schools' },
  { value: '611691', label: 'Exam Preparation and Tutoring' },
  { value: '611692', label: 'Automobile Driving Schools' },
  { value: '611699', label: 'All Other Miscellaneous Schools and Instruction' },
  { value: '611710', label: 'Educational Support Services' },
  {
    value: '621111',
    label: 'Offices of Physicians (except Mental Health Specialists)'
  },
  {
    value: '621112',
    label: 'Offices of Physicians, Mental Health Specialists'
  },
  { value: '621210', label: 'Offices of Dentists' },
  { value: '621310', label: 'Offices of Chiropractors' },
  { value: '621320', label: 'Offices of Optometrists' },
  {
    value: '621330',
    label: 'Offices of Mental Health Practitioners (except Physicians)'
  },
  {
    value: '621340',
    label:
      'Offices of Physical, Occupational and Speech Therapists, and Audiologists'
  },
  { value: '621391', label: 'Offices of Podiatrists' },
  {
    value: '621399',
    label: 'Offices of All Other Miscellaneous Health Practitioners'
  },
  { value: '621410', label: 'Family Planning Centers' },
  {
    value: '621420',
    label: 'Outpatient Mental Health and Substance Abuse Centers'
  },
  { value: '621491', label: 'HMO Medical Centers' },
  { value: '621492', label: 'Kidney Dialysis Centers' },
  {
    value: '621493',
    label: 'Freestanding Ambulatory Surgical and Emergency Centers'
  },
  { value: '621498', label: 'All Other Outpatient Care Centers' },
  { value: '621511', label: 'Medical Laboratories' },
  { value: '621512', label: 'Diagnostic Imaging Centers' },
  { value: '621610', label: 'Home Health Care Services' },
  { value: '621910', label: 'Ambulance Services' },
  { value: '621991', label: 'Blood and Organ Banks' },
  {
    value: '621999',
    label: 'All Other Miscellaneous Ambulatory Health Care Services'
  },
  { value: '622110', label: 'General Medical and Surgical Hospitals' },
  { value: '622210', label: 'Psychiatric and Substance Abuse Hospitals' },
  {
    value: '622310',
    label: 'Specialty (except Psychiatric and Substance Abuse) Hospitals'
  },
  {
    value: '623110',
    label: 'Nursing Care Facilities (Skilled Nursing Facilities)'
  },
  {
    value: '623210',
    label: 'Residential Intellectual and Developmental Disability Facilities'
  },
  {
    value: '623220',
    label: 'Residential Mental Health and Substance Abuse Facilities'
  },
  { value: '623311', label: 'Continuing Care Retirement Communities' },
  { value: '623312', label: 'Assisted Living Facilities for the Elderly' },
  { value: '623990', label: 'Other Residential Care Facilities' },
  { value: '624110', label: 'Child and Youth Services' },
  {
    value: '624120',
    label: 'Services for the Elderly and Persons with Disabilities'
  },
  { value: '624190', label: 'Other Individual and Family Services' },
  { value: '624210', label: 'Community Food Services' },
  { value: '624221', label: 'Temporary Shelters' },
  { value: '624229', label: 'Other Community Housing Services' },
  { value: '624230', label: 'Emergency and Other Relief Services' },
  { value: '624310', label: 'Vocational Rehabilitation Services' },
  { value: '624410', label: 'Child Care Services' },
  { value: '711110', label: 'Theater Companies and Dinner Theaters' },
  { value: '711120', label: 'Dance Companies' },
  { value: '711130', label: 'Musical Groups and Artists' },
  { value: '711190', label: 'Other Performing Arts Companies' },
  { value: '711211', label: 'Sports Teams and Clubs' },
  { value: '711212', label: 'Racetracks' },
  { value: '711219', label: 'Other Spectator Sports' },
  {
    value: '711310',
    label:
      'Promoters of Performing Arts, Sports, and Similar Events with Facilities'
  },
  {
    value: '711320',
    label:
      'Promoters of Performing Arts, Sports, and Similar Events without Facilities'
  },
  {
    value: '711410',
    label:
      'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures'
  },
  { value: '711510', label: 'Independent Artists, Writers, and Performers' },
  { value: '712110', label: 'Museums' },
  { value: '712120', label: 'Historical Sites' },
  { value: '712130', label: 'Zoos and Botanical Gardens' },
  { value: '712190', label: 'Nature Parks and Other Similar Institutions' },
  { value: '713110', label: 'Amusement and Theme Parks' },
  { value: '713120', label: 'Amusement Arcades' },
  { value: '713210', label: 'Casinos (except Casino Hotels)' },
  { value: '713290', label: 'Other Gambling Industries' },
  { value: '713910', label: 'Golf Courses and Country Clubs' },
  { value: '713920', label: 'Skiing Facilities' },
  { value: '713930', label: 'Marinas' },
  { value: '713940', label: 'Fitness and Recreational Sports Centers' },
  { value: '713950', label: 'Bowling Centers' },
  { value: '713990', label: 'All Other Amusement and Recreation Industries' },
  { value: '721110', label: 'Hotels (except Casino Hotels) and Motels' },
  { value: '721120', label: 'Casino Hotels' },
  { value: '721191', label: 'Bed-and-Breakfast Inns' },
  { value: '721199', label: 'All Other Traveler Accommodation' },
  { value: '721211', label: 'RV (Recreational Vehicle) Parks and Campgrounds' },
  {
    value: '721214',
    label: 'Recreational and Vacation Camps (except Campgrounds)'
  },
  {
    value: '721310',
    label: "Rooming and Boarding Houses, Dormitories, and Workers' Camps"
  },
  { value: '722310', label: 'Food Service Contractors' },
  { value: '722320', label: 'Caterers' },
  { value: '722330', label: 'Mobile Food Services' },
  { value: '722410', label: 'Drinking Places (Alcoholic Beverages)' },
  { value: '722511', label: 'Full-Service Restaurants' },
  { value: '722513', label: 'Limited-Service Restaurants' },
  { value: '722514', label: 'Cafeterias, Grill Buffets, and Buffets' },
  { value: '722515', label: 'Snack and Nonalcoholic Beverage Bars' },
  { value: '811111', label: 'General Automotive Repair' },
  { value: '811114', label: 'Specialized Automotive Repair' },
  {
    value: '811121',
    label: 'Automotive Body, Paint, and Interior Repair and Maintenance'
  },
  { value: '811122', label: 'Automotive Glass Replacement Shops' },
  { value: '811191', label: 'Automotive Oil Change and Lubrication Shops' },
  { value: '811192', label: 'Car Washes' },
  { value: '811198', label: 'All Other Automotive Repair and Maintenance' },
  {
    value: '811210',
    label: 'Electronic and Precision Equipment Repair and Maintenance'
  },
  {
    value: '811310',
    label:
      'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance'
  },
  {
    value: '811411',
    label: 'Home and Garden Equipment Repair and Maintenance'
  },
  { value: '811412', label: 'Appliance Repair and Maintenance' },
  { value: '811420', label: 'Reupholstery and Furniture Repair' },
  { value: '811430', label: 'Footwear and Leather Goods Repair' },
  {
    value: '811490',
    label: 'Other Personal and Household Goods Repair and Maintenance'
  },
  { value: '812111', label: 'Barber Shops' },
  { value: '812112', label: 'Beauty Salons' },
  { value: '812113', label: 'Nail Salons' },
  { value: '812191', label: 'Diet and Weight Reducing Centers' },
  { value: '812199', label: 'Other Personal Care Services' },
  { value: '812210', label: 'Funeral Homes and Funeral Services' },
  { value: '812220', label: 'Cemeteries and Crematories' },
  { value: '812310', label: 'Coin-Operated Laundries and Drycleaners' },
  {
    value: '812320',
    label: 'Drycleaning and Laundry Services (except Coin-Operated)'
  },
  { value: '812331', label: 'Linen Supply' },
  { value: '812332', label: 'Industrial Launderers' },
  { value: '812910', label: 'Pet Care (except Veterinary) Services' },
  { value: '812921', label: 'Photofinishing Laboratories (except One-Hour)' },
  { value: '812922', label: 'One-Hour Photofinishing' },
  { value: '812930', label: 'Parking Lots and Garages' },
  { value: '812990', label: 'All Other Personal Services' },
  { value: '813110', label: 'Religious Organizations' },
  { value: '813211', label: 'Grantmaking Foundations' },
  { value: '813212', label: 'Voluntary Health Organizations' },
  { value: '813219', label: 'Other Grantmaking and Giving Services' },
  { value: '813311', label: 'Human Rights Organizations' },
  {
    value: '813312',
    label: 'Environment, Conservation and Wildlife Organizations'
  },
  { value: '813319', label: 'Other Social Advocacy Organizations' },
  { value: '813410', label: 'Civic and Social Organizations' },
  { value: '813910', label: 'Business Associations' },
  { value: '813920', label: 'Professional Organizations' },
  { value: '813930', label: 'Labor Unions and Similar Labor Organizations' },
  { value: '813940', label: 'Political Organizations' },
  {
    value: '813990',
    label:
      'Other Similar Organizations (except Business, Professional, Labor, and Political Organizations)'
  },
  { value: '814110', label: 'Private Households' },
  { value: '921110', label: 'Executive Offices' },
  { value: '921120', label: 'Legislative Bodies' },
  { value: '921130', label: 'Public Finance Activities' },
  { value: '921140', label: 'Executive and Legislative Offices, Combined' },
  {
    value: '921150',
    label: 'American Indian and Alaska Native Tribal Governments'
  },
  { value: '921190', label: 'Other General Government Support' },
  { value: '922110', label: 'Courts' },
  { value: '922120', label: 'Police Protection' },
  { value: '922130', label: 'Legal Counsel and Prosecution' },
  { value: '922140', label: 'Correctional Institutions' },
  { value: '922150', label: 'Parole Offices and Probation Offices' },
  { value: '922160', label: 'Fire Protection' },
  {
    value: '922190',
    label: 'Other Justice, Public Order, and Safety Activities'
  },
  { value: '923110', label: 'Administration of Education Programs' },
  { value: '923120', label: 'Administration of Public Health Programs' },
  {
    value: '923130',
    label:
      "Administration of Human Resource Programs (except Education, Public Health, and Veterans' Affairs Programs)"
  },
  { value: '923140', label: "Administration of Veterans' Affairs" },
  {
    value: '924110',
    label:
      'Administration of Air and Water Resource and Solid Waste Management Programs'
  },
  { value: '924120', label: 'Administration of Conservation Programs' },
  { value: '925110', label: 'Administration of Housing Programs' },
  {
    value: '925120',
    label:
      'Administration of Urban Planning and Community and Rural Development'
  },
  { value: '926110', label: 'Administration of General Economic Programs' },
  {
    value: '926120',
    label: 'Regulation and Administration of Transportation Programs'
  },
  {
    value: '926130',
    label:
      'Regulation and Administration of Communications, Electric, Gas, and Other Utilities'
  },
  {
    value: '926140',
    label: 'Regulation of Agricultural Marketing and Commodities'
  },
  {
    value: '926150',
    label:
      'Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors'
  },
  { value: '927110', label: 'Space Research and Technology' },
  { value: '928110', label: 'National Security' },
  { value: '928120', label: 'International Affairs' }
];

export const UnspscCodes = [
  {
    value: '10000000',
    label: 'Live Plant and Animal Material and Accessories and Supplies'
  },
  { value: '10100000', label: 'Live animals' },
  { value: '10101500', label: 'Livestock' },
  { value: '10101501', label: 'Cats' },
  { value: '10101502', label: 'Dogs' },
  { value: '10101506', label: 'Horses' },
  { value: '10101507', label: 'Sheep' },
  { value: '10101508', label: 'Goats' },
  { value: '10101509', label: 'Asses' },
  { value: '10101511', label: 'Swine' },
  { value: '10101512', label: 'Rabbits' },
  { value: '10101513', label: 'Guinea pigs' },
  { value: '10101516', label: 'Cattle' },
  { value: '10101600', label: 'Birds and fowl' },
  { value: '10101601', label: 'Live chickens' },
  { value: '10101602', label: 'Live ducks' },
  { value: '10101603', label: 'Live turkeys' },
  { value: '10101604', label: 'Live geese' },
  { value: '10101605', label: 'Live pheasants' },
  { value: '10101700', label: 'Live fish' },
  { value: '10101701', label: 'Live salmon' },
  { value: '10101800', label: 'Shellfish and aquatic invertebrates' },
  { value: '10101801', label: 'Live shrimp' },
  { value: '10101900', label: 'Insects' },
  { value: '10101903', label: 'Bees' },
  { value: '10101904', label: 'Silkworms' },
  { value: '10120000', label: 'Animal feed' },
  { value: '10121500', label: 'Livestock feed' },
  { value: '10121501', label: 'Pure wheat bran' },
  { value: '10121600', label: 'Bird and fowl food' },
  { value: '10121601', label: 'Live food for birds' },
  { value: '10121700', label: 'Fish food' },
  { value: '10121701', label: 'Fresh or frozen brine' },
  { value: '10121800', label: 'Dog and cat food' },
  { value: '10121801', label: 'Dry food for dogs' },
  { value: '10131600', label: 'Animal containment' },
  { value: '10131500', label: 'Animal shelters' },
  { value: '10131601', label: 'Cages or its accessories' },
  { value: '10131701', label: 'Terrariums' },
  { value: '10131602', label: 'Kennels' },
  { value: '10140000', label: 'Saddlery and harness goods' },
  { value: '10141500', label: 'Saddlery' },
  { value: '10141501', label: 'Saddles' },
  { value: '10150000', label: 'Seeds and bulbs and seedlings and cuttings' },
  { value: '10151500', label: 'Vegetable seeds and seedlings' },
  { value: '10152301', label: 'Bean seeds or seedlings' },
  { value: '10152404', label: 'Carrot seeds or seedlings' },
  { value: '10151503', label: 'Celery seeds or seedlings' },
  { value: '10151504', label: 'Chili seeds or seedlings' },
  { value: '10151505', label: 'Courgette seeds or seedlings' },
  { value: '10151506', label: 'Pea seeds or seedlings' },
  { value: '10151507', label: 'Cucumber seeds or seedlings' },
  { value: '10151508', label: 'Eggplant seeds or seedlings' },
  { value: '10151509', label: 'Endive seeds or seedlings' },
  { value: '10151510', label: 'Garlic seeds or seedlings' },
  { value: '10151511', label: 'Leek seeds or seedlings' },
  { value: '10151512', label: 'Lettuce seeds or seedlings' },
  { value: '10151513', label: 'Corn seeds or seedlings' },
  { value: '10151514', label: 'Melon seeds or seedlings' },
  { value: '10151515', label: 'Onion seeds or seedlings' },
  { value: '10152302', label: 'Soya seeds or seedlings' },
  { value: '10151517', label: 'Spinach seeds or seedlings' },
  { value: '10151518', label: 'Tomato seeds or seedlings' },
  { value: '10152402', label: 'Turnip seeds or seedlings' },
  { value: '10151520', label: 'Chard seeds or seedlings' },
  { value: '10151600', label: 'Cereal seeds' },
  { value: '10151601', label: 'Wheat seeds' },
  { value: '10151602', label: 'Colza seeds' },
  { value: '10151603', label: 'Barley seeds' },
  { value: '10151604', label: 'Millet seeds' },
  { value: '10151605', label: 'Oat seeds' },
  { value: '10151606', label: 'Sesame seeds' },
  { value: '10151607', label: 'Linseed seeds' },
  { value: '10151608', label: 'Castor oil seeds' },
  { value: '10151609', label: 'Maize seeds' },
  { value: '10151610', label: 'Rye seeds' },
  { value: '10151611', label: 'Sorghum seeds' },
  { value: '10151700', label: 'Grass and forage seeds and seedlings' },
  { value: '10151614', label: 'Rice seeds or seedlings' },
  { value: '10151800', label: 'Spice crop seeds and seedlings' },
  {
    value: '10151900',
    label: 'Flower seeds and bulbs and seedlings and cuttings'
  },
  { value: '10152000', label: 'Tree and shrub seeds and cuttings' },
  { value: '10152001', label: 'Fruit tree seeds or cuttings' },
  { value: '10152003', label: 'Nut tree seeds or cuttings' },
  { value: '10160000', label: 'Floriculture and silviculture products' },
  { value: '10161500', label: 'Trees and shrubs' },
  {
    value: '10170000',
    label: 'Fertilizers and plant nutrients and herbicides'
  },
  { value: '10171500', label: 'Organic fertilizers and plant nutrients' },
  { value: '10171501', label: 'Manure or guano' },
  { value: '10171502', label: 'Plant hormones' },
  { value: '10171600', label: 'Chemical fertilizers and plant nutrients' },
  { value: '10171601', label: 'Ammonium nitrate fertilizer' },
  { value: '10171602', label: 'Potassic fertilizer' },
  { value: '10171603', label: 'Phosphatic fertilizer' },
  { value: '10171604', label: 'Sulphuric fertilizer' },
  { value: '10171605', label: 'Nitrogen Phosphorous Potassium Mixtures NPK' },
  { value: '10190000', label: 'Pest control products' },
  { value: '10191500', label: 'Pesticides or pest repellents' },
  { value: '10191509', label: 'Insecticides' },
  { value: '10191506', label: 'Rodenticides' },
  { value: '10191700', label: 'Pest control devices' },
  {
    value: '11000000',
    label: 'Mineral and Textile and Inedible Plant and Animal Materials'
  },
  { value: '11100000', label: 'Minerals and ores and metals' },
  { value: '11101500', label: 'Minerals' },
  { value: '11101501', label: 'Mica' },
  { value: '11101506', label: 'Chalk' },
  { value: '11101600', label: 'Ores' },
  { value: '11101700', label: 'Base Metals' },
  { value: '11101800', label: 'Precious metals' },
  { value: '11110000', label: 'Earth and stone' },
  { value: '11111500', label: 'Dirt and soil' },
  { value: '11111501', label: 'Soil' },
  { value: '11111600', label: 'Stone' },
  { value: '11111601', label: 'Gypsum' },
  { value: '11111602', label: 'Travertine' },
  { value: '11111603', label: 'Ecaussine or alabaster' },
  { value: '11111604', label: 'Granite' },
  { value: '11111605', label: 'Marble' },
  { value: '11111606', label: 'Slate' },
  { value: '11111607', label: 'Sandstone' },
  { value: '11111608', label: 'Limestone' },
  { value: '11111700', label: 'Sand' },
  { value: '11111701', label: 'Silica sand' },
  { value: '11111800', label: 'Clays' },
  { value: '11111801', label: 'Terra cotta' },
  { value: '11111802', label: 'Fireclay' },
  { value: '11111803', label: 'Kaolin or other kaolinic clays' },
  { value: '11111804', label: 'Bentonite' },
  { value: '11111805', label: 'Andalusite' },
  { value: '11111806', label: 'Mullite' },
  { value: '11111807', label: 'Chamotte' },
  { value: '11111808', label: 'Common clay' },
  { value: '11111809', label: 'Ball clay' },
  { value: '11111810', label: 'Fullers earth' },
  { value: '11120000', label: 'Non edible plant and forestry products' },
  { value: '11121500', label: 'Sap' },
  { value: '11121502', label: 'Rosin' },
  { value: '11121600', label: 'Wood' },
  { value: '11121603', label: 'Logs' },
  { value: '11121604', label: 'Soft timber' },
  { value: '11121605', label: 'Rattan' },
  { value: '11121606', label: 'Cork' },
  { value: '11121607', label: 'Wood pulp' },
  { value: '11121608', label: 'Bamboo' },
  { value: '11121700', label: 'Forestry byproducts' },
  { value: '11121800', label: 'Plant fibers' },
  { value: '11130000', label: 'Non edible animal products' },
  {
    value: '11131500',
    label: 'Animal hides and skins and animal textile materials'
  },
  { value: '11131501', label: 'Feathers' },
  { value: '11131600', label: 'Other animal products' },
  { value: '11131602', label: 'Semen' },
  { value: '11131607', label: 'Embryos' },
  { value: '11140000', label: 'Scrap and waste materials' },
  { value: '11141600', label: 'Non metallic waste and scrap' },
  { value: '11141700', label: 'Food and tobacco waste and scrap' },
  { value: '11150000', label: 'Fibers and threads and yarns' },
  { value: '11151500', label: 'Fibers' },
  { value: '11151600', label: 'Threads' },
  { value: '11151700', label: 'Yarns' },
  { value: '11160000', label: 'Fabrics and leather materials' },
  { value: '11161500', label: 'Silk fabrics' },
  { value: '11161600', label: 'Wool fabrics' },
  { value: '11161700', label: 'Cotton fabrics' },
  { value: '11161800', label: 'Synthetic fabrics' },
  {
    value: '11162000',
    label: 'Fabrics of vegetable material other than cotton'
  },
  { value: '11162100', label: 'Specialty fabrics or cloth' },
  { value: '11162101', label: 'Cheese cloth or fabric' },
  { value: '11162102', label: 'Bismalemide fabric or cloth' },
  { value: '11162104', label: 'Graphite fabric or cloth' },
  { value: '11162105', label: 'Glass fabric or cloth' },
  { value: '11162107', label: 'Resin impregnated fabric or cloth' },
  { value: '11162108', label: 'Wire mesh fabric or cloth' },
  { value: '11162109', label: 'Lace' },
  { value: '11162110', label: 'Netting' },
  { value: '11162111', label: 'Mesh' },
  { value: '11162112', label: 'Coated fabrics' },
  { value: '11162113', label: 'Upholstery fabrics' },
  {
    value: '12000000',
    label: 'Chemicals including Bio Chemicals and Gas Materials'
  },
  { value: '12141800', label: 'Alkali metals' },
  { value: '12142201', label: 'Deuterated solvents' },
  { value: '12142202', label: 'Heavy water' },
  { value: '12142203', label: 'Alpha sources' },
  { value: '12142204', label: 'Beta sources' },
  { value: '12142205', label: 'Cobalt sources' },
  { value: '12142206', label: 'Gamma sources' },
  { value: '12142207', label: 'Radioisotope sources' },
  { value: '12142208', label: 'Calibration sources' },
  { value: '12130000', label: 'Explosive materials' },
  { value: '12131500', label: 'Explosives' },
  { value: '12131501', label: 'Dynamite' },
  { value: '12131502', label: 'Explosive cartridges' },
  { value: '12131503', label: 'Propellant explosives' },
  { value: '12131504', label: 'Explosive charges' },
  { value: '12131505', label: 'Plastic explosives' },
  { value: '12131506', label: 'Aluminized explosives' },
  { value: '12131507', label: 'Ammonium nitrate explosives' },
  { value: '12131508', label: 'Nitroglycerin powder explosives' },
  { value: '12131600', label: 'Pyrotechnics' },
  { value: '12131700', label: 'Igniters' },
  { value: '12131702', label: 'Detonators' },
  { value: '12131703', label: 'Explosives fuses' },
  { value: '12131704', label: 'Explosive initiators' },
  { value: '12131705', label: 'Explosive primers' },
  { value: '12131800', label: 'Propellants' },
  {
    value: '13000000',
    label:
      'Resin and Rosin and Rubber and Foam and Film and Elastomeric Materials'
  },
  { value: '13100000', label: 'Rubber and elastomers' },
  { value: '13101500', label: 'Natural rubber' },
  { value: '13101600', label: 'Processed and synthetic rubber' },
  { value: '13101700', label: 'Elastomers' },
  { value: '13101900', label: 'Thermoset plastics' },
  { value: '13102000', label: 'Thermoplastic plastics' },
  { value: '13111000', label: 'Resins' },
  { value: '13111202', label: 'Polyurethane films' },
  { value: '14000000', label: 'Paper Materials and Products' },
  { value: '14100000', label: 'Paper materials' },
  { value: '14101500', label: 'Raw materials' },
  { value: '14101501', label: 'Paper pulp' },
  { value: '14120000', label: 'Industrial use papers' },
  { value: '14121500', label: 'Paperboard and packaging papers' },
  { value: '14121901', label: 'Standard newsprint' },
  { value: '14121807', label: 'Butcher papers' },
  { value: '14110000', label: 'Paper products' },
  { value: '14111500', label: 'Printing and writing paper' },
  { value: '14111501', label: 'Onion skin paper' },
  { value: '14111502', label: 'Vellum paper' },
  { value: '14111503', label: 'Parchment paper' },
  { value: '14111504', label: 'Tractor feed paper' },
  { value: '14111505', label: 'Mimeograph paper' },
  { value: '14111506', label: 'Computer printout paper' },
  { value: '14111507', label: 'Printer or copier paper' },
  { value: '14111508', label: 'Facsimile paper' },
  { value: '14111509', label: 'Stationery' },
  { value: '14111510', label: 'Plotter paper' },
  { value: '14111511', label: 'Writing paper' },
  { value: '14111512', label: 'Graph paper' },
  { value: '14111513', label: 'Ledger paper' },
  { value: '14111514', label: 'Paper pads or notebooks' },
  { value: '14111515', label: 'Calculator or cash register paper' },
  { value: '14111516', label: 'Notebook filler paper' },
  { value: '14111806', label: 'Business forms or questionnaires' },
  { value: '14111600', label: 'Novelty paper' },
  { value: '14111601', label: 'Gift wrapping paper or bags or boxes' },
  { value: '14111604', label: 'Business cards' },
  { value: '14111605', label: 'Greeting or note or post cards' },
  { value: '14111700', label: 'Personal paper products' },
  { value: '14111701', label: 'Facial tissues' },
  { value: '14111702', label: 'Toilet seat covers' },
  { value: '14111703', label: 'Paper towels' },
  { value: '14111704', label: 'Toilet tissue' },
  {
    value: '15000000',
    label:
      'Fuels and Fuel Additives and Lubricants and Anti corrosive Materials'
  },
  { value: '15100000', label: 'Fuels' },
  { value: '15101500', label: 'Petroleum and distillates' },
  { value: '15101700', label: 'Fuel Oils' },
  { value: '15101502', label: 'Kerosene' },
  { value: '15101503', label: 'Naphtha' },
  { value: '15101504', label: 'Aviation fuel' },
  { value: '15101505', label: 'Diesel fuel' },
  { value: '15101506', label: 'Gasoline or Petrol' },
  { value: '15101507', label: 'Benzene' },
  { value: '15101508', label: 'Crude oil' },
  { value: '15101509', label: 'Marine fuel' },
  { value: '15101600', label: 'Solid and gel fuels' },
  { value: '15110000', label: 'Gaseous fuels and additives' },
  { value: '15111500', label: 'Gaseous fuels' },
  { value: '15111501', label: 'Propane' },
  { value: '15111502', label: 'Methane' },
  { value: '15111503', label: 'Propylene' },
  { value: '15111504', label: 'Ethylene' },
  { value: '15111505', label: 'Butane' },
  {
    value: '15120000',
    label: 'Lubricants and oils and greases and anti corrosives'
  },
  { value: '15121500', label: 'Lubricating preparations' },
  { value: '15121501', label: 'Engine oil' },
  { value: '15121504', label: 'Hydraulic oil' },
  { value: '15121508', label: 'Transmission oil' },
  { value: '12181500', label: 'Waxes' },
  { value: '15121800', label: 'Anti corrosives' },
  { value: '15130000', label: 'Fuel for nuclear reactors' },
  { value: '15131500', label: 'Nuclear fuel' },
  { value: '15131601', label: 'Nuclear fuel rod' },
  { value: '15131502', label: 'Depleted uranium' },
  { value: '15131503', label: 'Enriched uranium' },
  { value: '15131504', label: 'Iridium' },
  { value: '15131505', label: 'Enriched plutonium' },
  { value: '15131506', label: 'Depleted plutonium' },
  {
    value: '20000000',
    label: 'Mining and Well Drilling Machinery and Accessories'
  },
  { value: '20100000', label: 'Mining and quarrying machinery and equipment' },
  { value: '20101500', label: 'Cutting equipment' },
  { value: '20101501', label: 'Continuous mining equipment' },
  { value: '20101502', label: 'Longwall shears' },
  { value: '20101600', label: 'Screens and feeding equipment' },
  { value: '20101700', label: 'Crushers and breakers and grinders' },
  { value: '20101709', label: 'Ball mills' },
  { value: '20110000', label: 'Well drilling and operation equipment' },
  { value: '20111500', label: 'Drilling and exploration equipment' },
  { value: '20111504', label: 'Water well drilling equipment' },
  { value: '20111505', label: 'Uranium exploration equipment' },
  { value: '20111600', label: 'Drilling and operation machinery' },
  { value: '20102100', label: 'Rock drills' },
  { value: '20111700', label: 'Drilling and operation accessories' },
  {
    value: '21000000',
    label:
      'Farming and Fishing and Forestry and Wildlife Machinery and Accessories'
  },
  {
    value: '21100000',
    label: 'Agricultural and forestry and landscape machinery and equipment'
  },
  { value: '21101500', label: 'Agricultural machinery for soil preparation' },
  { value: '21101501', label: 'Ploughs' },
  { value: '21101502', label: 'Harrows' },
  { value: '21101503', label: 'Cultivators' },
  { value: '21101504', label: 'Weeders' },
  { value: '21101505', label: 'Hoeing machines' },
  { value: '21101506', label: 'Graders or land levelers' },
  { value: '21101507', label: 'Agricultural rollers' },
  { value: '21101508', label: 'Rollers for lawn or sports grounds' },
  { value: '21101509', label: 'Trencher drainage machine' },
  { value: '21102501', label: 'Irrigation trickles' },
  { value: '21102502', label: 'Irrigation overheads' },
  {
    value: '21101600',
    label: 'Agricultural machinery for planting and seeding'
  },
  { value: '21101601', label: 'Planters' },
  { value: '21101700', label: 'Agricultural machinery for harvesting' },
  { value: '21101701', label: 'Mowers' },
  { value: '21101703', label: 'Harvesters' },
  { value: '21101705', label: 'Threshing machines' },
  {
    value: '21101800',
    label: 'Dispersing and spraying appliances for agriculture'
  },
  { value: '21101801', label: 'Sprayers' },
  { value: '21101802', label: 'Dusters' },
  { value: '21101803', label: 'Water sprinklers' },
  { value: '21101804', label: 'Fertilizer spreaders or distributors' },
  { value: '21101805', label: 'Fog or mist generators' },
  { value: '21101806', label: 'Composter' },
  { value: '21101807', label: 'Pollination equipment or supplies' },
  { value: '21101808', label: 'Frost protection equipment' },
  { value: '21101900', label: 'Poultry and livestock equipment' },
  { value: '21101901', label: 'Milking machines' },
  { value: '21101903', label: 'Incubators or brooders for poultry' },
  { value: '21101905', label: 'Livestock identification equipment' },
  { value: '21101906', label: 'Egg inspection or collecting equipment' },
  {
    value: '21102000',
    label: 'Agricultural machinery for cleaning and sorting and grading'
  },
  {
    value: '21102001',
    label: 'Cleaning machines for seed or grain or dried leguminous vegetables'
  },
  {
    value: '21102002',
    label: 'Sorting machines for seed or grain or dried leguminous vegetables'
  },
  {
    value: '21102100',
    label: 'Agricultural processing machinery and equipment'
  },
  { value: '21102200', label: 'Forestry machinery and equipment' },
  { value: '21102300', label: 'Greenhouse equipment' },
  { value: '21102400', label: 'Insect equipment' },
  { value: '21102401', label: 'Beekeeping equipment' },
  { value: '21110000', label: 'Fishing and aquaculture equipment' },
  { value: '21111500', label: 'Commercial fishing equipment' },
  { value: '21111501', label: 'Commercial fish hooks' },
  { value: '21111504', label: 'Commercial fishing nets' },
  { value: '21111600', label: 'Aquaculture equipment' },
  {
    value: '22000000',
    label: 'Building and Construction Machinery and Accessories'
  },
  { value: '22100000', label: 'Heavy construction machinery and equipment' },
  { value: '22101500', label: 'Earth moving machinery' },
  { value: '22101501', label: 'Front end loaders' },
  { value: '22101502', label: 'Graders' },
  { value: '22101504', label: 'Pile drivers' },
  { value: '22101505', label: 'Rollers' },
  { value: '22101507', label: 'Tampers' },
  { value: '22101508', label: 'Trenching machines' },
  { value: '22101509', label: 'Backhoes' },
  { value: '22101511', label: 'Compactors' },
  { value: '22101513', label: 'Draglines' },
  { value: '22101514', label: 'Dredgers' },
  { value: '22101516', label: 'Ditchers' },
  { value: '22101600', label: 'Paving equipment' },
  { value: '22101700', label: 'Heavy equipment components' },
  {
    value: '23000000',
    label: 'Industrial Manufacturing and Processing Machinery and Accessories'
  },
  { value: '23100000', label: 'Raw materials processing machinery' },
  {
    value: '23101500',
    label: 'Machinery for working wood and stone and ceramic and the like'
  },
  { value: '23101501', label: 'Coping machines' },
  { value: '23101513', label: 'Milling machines' },
  { value: '23110000', label: 'Petroleum processing machinery' },
  { value: '23120000', label: 'Textile and fabric machinery and accessories' },
  { value: '23121500', label: 'Textile processing machinery and accessories' },
  { value: '23121501', label: 'Embroidery making machines' },
  {
    value: '23121600',
    label: 'Textile working machinery and equipment and accessories'
  },
  { value: '23121614', label: 'Sewing machines' },
  { value: '23130000', label: 'Lapidary machinery and equipment' },
  {
    value: '23131500',
    label: 'Grinding and sanding and polishing equipment and supplies'
  },
  { value: '23131501', label: 'Abrasive compounds' },
  { value: '23131503', label: 'Grinding wheels' },
  { value: '23131600', label: 'Faceting equipment and accessories' },
  { value: '23131700', label: 'Cabbing equipment' },
  {
    value: '23140000',
    label: 'Leatherworking repairing machinery and equipment'
  },
  { value: '23141600', label: 'Leather preparing machinery and accessories' },
  { value: '23141601', label: 'Leather fleshing machines' },
  {
    value: '23141700',
    label: 'Leather working and repairing machinery and accessories'
  },
  {
    value: '23150000',
    label: 'Industrial process machinery and equipment and supplies'
  },
  {
    value: '23151500',
    label: 'Rubber and plastic processing machinery and equipment and supplies'
  },
  {
    value: '23151600',
    label:
      'Cement and ceramics and glass industry machinery and equipment and supplies'
  },
  {
    value: '23151700',
    label: 'Optical industry machinery and equipment and supplies'
  },
  {
    value: '23151800',
    label: 'Pharmaceutical industry machinery and equipment and supplies'
  },
  {
    value: '23151900',
    label:
      'Paper making and paper processing machinery and equipment and supplies'
  },
  { value: '23160000', label: 'Foundry machines and equipment and supplies' },
  { value: '23161500', label: 'Foundry machines and equipment' },
  { value: '23161501', label: 'Foundry blowers' },
  { value: '23161600', label: 'Foundry supplies' },
  { value: '23271812', label: 'Welding rod' },
  { value: '23180000', label: 'Industrial food and beverage equipment' },
  { value: '23181500', label: 'Food preparation machinery' },
  { value: '23181501', label: 'Filling machinery' },
  { value: '23181502', label: 'Milling machinery' },
  { value: '23181507', label: 'Crushing machinery' },
  { value: '23181600', label: 'Food cutting machinery' },
  { value: '23181601', label: 'Dicing machinery' },
  { value: '23181700', label: 'Food cooking and smoking machinery' },
  { value: '23181800', label: 'Industrial beverage processing machinery' },
  {
    value: '24000000',
    label:
      'Material Handling and Conditioning and Storage Machinery and their Accessories and Supplies'
  },
  { value: '24100000', label: 'Material handling machinery and equipment' },
  { value: '24101500', label: 'Industrial trucks' },
  { value: '24101501', label: 'Carts' },
  { value: '24101502', label: 'Bulk transporters' },
  { value: '24101503', label: 'Dollies' },
  { value: '24101504', label: 'Hand trucks or accessories' },
  { value: '24101505', label: 'Pallet trucks' },
  { value: '24101506', label: 'Pushcarts' },
  { value: '24101507', label: 'Wheelbarrows' },
  { value: '24101600', label: 'Lifting equipment and accessories' },
  { value: '24101601', label: 'Elevators' },
  { value: '24101602', label: 'Hoists' },
  { value: '24101603', label: 'Forklifts' },
  { value: '24101604', label: 'Lifts' },
  { value: '24101605', label: 'Loading equipment' },
  { value: '24101606', label: 'Stackers' },
  { value: '24101608', label: 'Winches' },
  { value: '24101700', label: 'Conveyors and accessories' },
  { value: '24101706', label: 'Trolleys or accessories' },
  { value: '24101800', label: 'Dock equipment' },
  { value: '24101900', label: 'Drum handling equipment' },
  { value: '24102000', label: 'Shelving and storage' },
  { value: '24112700', label: 'Pallets' },
  { value: '24102100', label: 'Warehousing equipment and supplies' },
  { value: '24102101', label: 'Cargo handling equipment' },
  { value: '24102102', label: 'Warehouse carousels' },
  { value: '24102103', label: 'Warehouse casers' },
  { value: '24102104', label: 'Depalletizers' },
  { value: '24102105', label: 'Palletizers' },
  { value: '24102106', label: 'Industrial shrink wrap equipment' },
  { value: '24110000', label: 'Containers and storage' },
  { value: '24111500', label: 'Bags' },
  { value: '24111501', label: 'Canvas bags' },
  { value: '24111502', label: 'Paper bags' },
  { value: '24111503', label: 'Plastic bags' },
  {
    value: '24112500',
    label: 'Corrugated and other supplies for distribution'
  },
  { value: '24111800', label: 'Tanks and cylinders and their accessories' },
  { value: '24111801', label: 'Reservoirs' },
  { value: '24111802', label: 'Air or gas tanks or cylinders' },
  { value: '24111803', label: 'Storage tanks' },
  { value: '24111804', label: 'Calibrating tanks' },
  { value: '24111805', label: 'Chemical tanks' },
  { value: '24111806', label: 'Dip tanks' },
  { value: '24111807', label: 'Expansion tanks' },
  { value: '24111808', label: 'Fuel storage tanks' },
  { value: '24111809', label: 'Processing tanks' },
  { value: '24111810', label: 'Water storage tanks' },
  { value: '24112000', label: 'Bins and baskets' },
  { value: '24112100', label: 'Casks and barrels and drums' },
  { value: '24112101', label: 'Casks' },
  { value: '24112200', label: 'Cans and pails' },
  { value: '24122000', label: 'Bottles' },
  { value: '24122002', label: 'Plastic bottles' },
  { value: '24122003', label: 'Glass bottles' },
  { value: '24112400', label: 'Storage chests and cabinets and trunks' },
  { value: '24112401', label: 'Tool chest or cabinet' },
  { value: '24112402', label: 'Hazardous materials cabinets' },
  { value: '24120000', label: 'Packaging materials' },
  { value: '24121500', label: 'Packaging boxes and bags and pouches' },
  { value: '24121502', label: 'Packaging pouches or bags' },
  { value: '24141501', label: 'Stretch wrap films' },
  { value: '24141600', label: 'Cushioning supplies' },
  { value: '24121800', label: 'Packaging cans' },
  { value: '24130000', label: 'Industrial refrigeration' },
  { value: '24131500', label: 'Industrial refrigerators' },
  { value: '24131501', label: 'Combined refrigerator freezers' },
  { value: '24131502', label: 'Liquid nitrogen refrigerators' },
  { value: '24131503', label: 'Walk in refrigerators' },
  { value: '24131504', label: 'Refrigerated containers' },
  { value: '24131505', label: 'Refrigerated vessels' },
  { value: '24131506', label: 'Refrigerated tanks' },
  { value: '24131600', label: 'Industrial freezers' },
  { value: '24131601', label: 'Chest freezers' },
  { value: '24131602', label: 'Upright cabinet freezer' },
  { value: '24131603', label: 'Low temperature freezers' },
  { value: '24131604', label: 'Freeze drying equipment' },
  { value: '24131605', label: 'Walk in freezers' },
  { value: '24131900', label: 'Ice makers' },
  {
    value: '25000000',
    label:
      'Commercial and Military and Private Vehicles and their Accessories and Components'
  },
  { value: '25100000', label: 'Motor vehicles' },
  { value: '25101500', label: 'Passenger motor vehicles' },
  { value: '25101501', label: 'Minibuses' },
  { value: '25101502', label: 'Busses' },
  { value: '25101503', label: 'Automobiles or cars' },
  { value: '25101504', label: 'Station wagons' },
  { value: '25101505', label: 'Minivans or vans' },
  { value: '25101506', label: 'Limousines' },
  { value: '25101507', label: 'Light trucks or sport utility vehicles' },
  { value: '25101600', label: 'Product and material transport vehicles' },
  { value: '25101601', label: 'Dump trucks' },
  { value: '25101602', label: 'Tow trucks' },
  { value: '25101604', label: 'Delivery trucks' },
  { value: '25101609', label: 'Sludge or sewage handling trucks' },
  { value: '25101700', label: 'Safety and rescue vehicles' },
  { value: '25101702', label: 'Police vehicles' },
  { value: '25101703', label: 'Ambulances' },
  { value: '25101800', label: 'Motorized cycles' },
  { value: '25101801', label: 'Motorcycles' },
  { value: '25101802', label: 'Scooters' },
  { value: '25101803', label: 'Mopeds' },
  { value: '25101900', label: 'Specialized and recreational vehicles' },
  { value: '25101901', label: 'Agricultural tractors' },
  { value: '25101902', label: 'Motor homes' },
  { value: '25101903', label: 'Snowmobiles or snow scooter' },
  { value: '25101904', label: 'Golf carts' },
  { value: '25102000', label: 'War vehicles' },
  { value: '25102001', label: 'Tanks' },
  { value: '25102002', label: 'Armored fighting vehicles' },
  { value: '25102003', label: 'Self propelled artillery' },
  { value: '25110000', label: 'Marine transport' },
  { value: '25111500', label: 'Commercial marine craft' },
  { value: '25111501', label: 'Trawlers' },
  { value: '25111502', label: 'Fishing boats' },
  { value: '25111503', label: 'Cargo or container ships' },
  { value: '25111504', label: 'Dredging craft' },
  { value: '25111505', label: 'Tankers' },
  { value: '25111506', label: 'Tug boats' },
  { value: '25111507', label: 'Barges' },
  { value: '25111508', label: 'Passenger or automobile ferries' },
  { value: '25111509', label: 'Cruise ships' },
  { value: '25111510', label: 'Salvage ships' },
  { value: '25111600', label: 'Safety and rescue water craft' },
  { value: '25111601', label: 'Lifeboats or liferafts' },
  { value: '25111602', label: 'Fire fighting watercraft' },
  { value: '25111603', label: 'Rescue ships or boats' },
  { value: '25111700', label: 'Military watercraft' },
  { value: '25111800', label: 'Recreational watercraft' },
  { value: '25111801', label: 'Recreational sailboats' },
  { value: '25111802', label: 'Recreational motorboats' },
  { value: '25111803', label: 'Recreational rowboats' },
  { value: '25111804', label: 'Canoes or kayaks' },
  { value: '25111805', label: 'Personal motorized watercraft' },
  { value: '25111806', label: 'Rafts' },
  { value: '25120000', label: 'Railway and tramway machinery and equipment' },
  { value: '25121500', label: 'Locomotives and electric trolleys' },
  { value: '25121501', label: 'Diesel freight locomotives' },
  { value: '25130000', label: 'Aircraft' },
  { value: '25131500', label: 'Powered fixed wing aircraft' },
  { value: '25131504', label: 'Commercial passenger propeller aircraft' },
  { value: '25131505', label: 'Cargo jet aircraft' },
  { value: '25131506', label: 'Commercial passenger jet aircraft' },
  { value: '25131507', label: 'Private or business propeller aircraft' },
  { value: '25131508', label: 'Private or business jet aircraft' },
  { value: '25131600', label: 'Civilian and commercial rotary wing aircraft' },
  { value: '25131601', label: 'Passenger transport helicopters' },
  { value: '25131602', label: 'Cargo transport helicopters' },
  { value: '25131604', label: 'Medical or rescue helicopters' },
  { value: '25131700', label: 'Military fixed wing aircraft' },
  { value: '25131705', label: 'Target or reconnaissance drones' },
  { value: '25131707', label: 'Reconnaissance or surveillance aircraft' },
  { value: '25131709', label: 'Military transport aircraft' },
  { value: '25131800', label: 'Specialty aircraft' },
  { value: '25131900', label: 'Military rotary wing aircraft' },
  { value: '25131902', label: 'Military transport helicopters' },
  { value: '25201702', label: 'Flight data recorders' },
  { value: '25201701', label: 'Aircraft communication systems' },
  {
    value: '25201901',
    label: 'Aircraft fire control or extinguishing systems'
  },
  { value: '25201801', label: 'Flight computer systems' },
  {
    value: '25191500',
    label: 'Air transportation support systems and equipment'
  },
  { value: '25191501', label: 'Ground support training systems' },
  { value: '25191502', label: 'Ground support test or maintenance systems' },
  { value: '25191503', label: 'Integrated maintenance information systems' },
  { value: '25191504', label: 'Aircraft flight simulators or trainers' },
  { value: '25191505', label: 'Aircraft cargo handling equipment' },
  { value: '25191506', label: 'Aircraft refueling equipment' },
  { value: '25191507', label: 'Aircraft deicing equipment' },
  { value: '25191508', label: 'Jetways' },
  { value: '25191509', label: 'Aircraft pushback or tow tractors' },
  { value: '25191510', label: 'Ground power units for aircraft' },
  { value: '25191511', label: 'Lavatory service equipment for aircraft' },
  { value: '25191512', label: 'Airstairs or stair trucks' },
  { value: '25150000', label: 'Spacecraft' },
  { value: '25151700', label: 'Satellites' },
  { value: '25151701', label: 'Communication satellites' },
  { value: '25160000', label: 'Non motorized cycles' },
  { value: '25161500', label: 'Pedal powered vehicles' },
  { value: '25161505', label: 'Mountain bicycles' },
  { value: '25161506', label: 'Racing bicycles' },
  { value: '25161507', label: 'Bicycles' },
  { value: '25170000', label: 'Transportation components and systems' },
  { value: '25171500', label: 'Windshield wipers' },
  { value: '25171600', label: 'Defrosting and defogging systems' },
  { value: '25171700', label: 'Braking systems and components' },
  { value: '25171702', label: 'Automotive braking systems' },
  { value: '25181600', label: 'Automotive chassis' },
  { value: '25181601', label: 'Automobile chassis' },
  { value: '25171900', label: 'Wheels and wheel trims' },
  { value: '25171901', label: 'Automobile rims or wheels' },
  { value: '25172000', label: 'Suspension system components' },
  {
    value: '25172100',
    label: 'Vehicle safety and security systems and components'
  },
  { value: '25202301', label: 'Aircraft lapbelts' },
  { value: '25172200', label: 'Vehicle doors' },
  { value: '25172300', label: 'Vehicle windows and windshields' },
  { value: '25172400', label: 'Fuel tanks and systems' },
  { value: '25172500', label: 'Tires and tire tubes' },
  { value: '25172502', label: 'Automobile tire tubes' },
  { value: '25172503', label: 'Heavy truck tires' },
  { value: '25172504', label: 'Automobile or light truck tires' },
  { value: '25172600', label: 'Vehicle trim and exterior covering' },
  { value: '25172700', label: 'Environmental control systems' },
  { value: '25172800', label: 'Hydraulic systems and components' },
  { value: '25172900', label: 'Exterior vehicle lighting' },
  { value: '25173000', label: 'Interior vehicle lighting' },
  {
    value: '26000000',
    label: 'Power Generation and Distribution Machinery and Accessories'
  },
  { value: '26100000', label: 'Power sources' },
  { value: '26101500', label: 'Engines' },
  { value: '26101501', label: 'Hydraulic engines' },
  { value: '26101502', label: 'Pneumatic engines' },
  { value: '26101503', label: 'Gas engines' },
  { value: '26101504', label: 'Diesel engines' },
  { value: '26101505', label: 'Steam engines' },
  { value: '26101506', label: 'Turbine engines' },
  { value: '26101507', label: 'Turbofan engines' },
  { value: '26101508', label: 'Thermal engines' },
  { value: '26101509', label: 'Hydroelectric engines' },
  { value: '26101511', label: 'Hydraulic turbine engines' },
  { value: '26101300', label: 'Non electric motors' },
  { value: '26101100', label: 'Electric alternating current AC motors' },
  { value: '26101200', label: 'Electric direct current DC motors' },
  {
    value: '26110000',
    label: 'Batteries and generators and kinetic power transmission'
  },
  { value: '26111500', label: 'Kinetic power transmission' },
  { value: '26111900', label: 'Clutches' },
  { value: '26111600', label: 'Power generators' },
  { value: '26111601', label: 'Diesel generators' },
  { value: '26111602', label: 'Hydro electric generators' },
  { value: '26111603', label: 'Wind generators' },
  { value: '26111604', label: 'Gas generators' },
  { value: '26111605', label: 'Thermal generators' },
  { value: '26111606', label: 'Hydraulic generators' },
  { value: '26111607', label: 'Solar generators' },
  { value: '26111700', label: 'Batteries and cells and accessories' },
  { value: '26111701', label: 'Rechargeable batteries' },
  { value: '26111702', label: 'Alkaline batteries' },
  { value: '26111703', label: 'Vehicle batteries' },
  { value: '26111704', label: 'Battery chargers' },
  { value: '26111705', label: 'Dry cell batteries' },
  { value: '26111706', label: 'Electronic batteries' },
  { value: '26111707', label: 'Lead acid batteries' },
  { value: '26111708', label: 'Nickel iron batteries' },
  { value: '26111709', label: 'Nickel cadmium batteries' },
  { value: '26111710', label: 'Product specific battery packs' },
  { value: '26111711', label: 'Lithium batteries' },
  { value: '26120000', label: 'Electrical wire and cable and harness' },
  { value: '26121500', label: 'Electrical wire' },
  { value: '26121609', label: 'Network cable' },
  { value: '26121600', label: 'Electrical cable and accessories' },
  { value: '26121700', label: 'Wiring harness' },
  { value: '26130000', label: 'Power generation' },
  { value: '26131500', label: 'Power plants' },
  { value: '26131501', label: 'Diesel power plants' },
  { value: '26131502', label: 'Geothermal power plants' },
  { value: '26131503', label: 'Hydro power plants' },
  { value: '26131504', label: 'Gas power plants' },
  { value: '26131505', label: 'Marine power plants' },
  { value: '26131506', label: 'Petrol power plants' },
  { value: '26131507', label: 'Solar power plants' },
  { value: '26131508', label: 'Steam power plants' },
  { value: '26131509', label: 'Wind power plants' },
  { value: '26131510', label: 'Thermal power plants' },
  {
    value: '26140000',
    label: 'Atomic and nuclear energy machinery and equipment'
  },
  { value: '26141600', label: 'Subcritical assembly equipment' },
  { value: '26141700', label: 'Dosimetry equipment' },
  { value: '26141800', label: 'Hot cell devices' },
  { value: '26141900', label: 'Industrial nucleonic instruments' },
  { value: '26142000', label: 'Irradiation equipment' },
  { value: '26142100', label: 'Nuclear reactor equipment' },
  { value: '26142200', label: 'Nuclear fuel equipment' },
  { value: '26142300', label: 'Radiation shielding equipment' },
  { value: '26142302', label: 'Lead screens' },
  { value: '26142400', label: 'Radioactive waste equipment' },
  { value: '26142407', label: 'Radioactive waste disposal systems' },
  { value: '27000000', label: 'Tools and General Machinery' },
  { value: '27110000', label: 'Hand tools' },
  { value: '27111701', label: 'Screwdrivers' },
  { value: '27111801', label: 'Tape measures' },
  { value: '27111704', label: 'Sockets' },
  { value: '27111509', label: 'Augers' },
  { value: '27111504', label: 'Pocket knives' },
  { value: '27112004', label: 'Shovels' },
  { value: '27112700', label: 'Power tools' },
  { value: '27112712', label: 'Power trimmers' },
  { value: '27112800', label: 'Tool attachments and accessories' },
  {
    value: '30000000',
    label:
      'Structures and Building and Construction and Manufacturing Components and Supplies'
  },
  { value: '30100000', label: 'Structural components and basic shapes' },
  { value: '30101500', label: 'Angles' },
  { value: '30101700', label: 'Beams' },
  { value: '30101800', label: 'Channels' },
  { value: '30102000', label: 'Foil' },
  { value: '40170000', label: 'Pipe piping and pipe fittings' },
  { value: '30102200', label: 'Plate' },
  { value: '30102300', label: 'Profiles' },
  { value: '30102306', label: 'Aluminum profiles' },
  { value: '30102400', label: 'Rod' },
  { value: '30102800', label: 'Piling' },
  { value: '30102900', label: 'Post' },
  { value: '30103100', label: 'Rails' },
  { value: '30103200', label: 'Grating' },
  { value: '30103500', label: 'Honeycomb core' },
  { value: '30103600', label: 'Structural products' },
  { value: '30110000', label: 'Concrete and cement and plaster' },
  { value: '30111500', label: 'Concrete and mortars' },
  { value: '30111501', label: 'Foamed concrete' },
  { value: '30111502', label: 'Conductive concrete' },
  { value: '30111503', label: 'Insulating concrete' },
  { value: '30111504', label: 'Mortars' },
  { value: '30111600', label: 'Cement and lime' },
  { value: '30111601', label: 'Cement' },
  { value: '30111602', label: 'Chlorinated lime' },
  { value: '30111603', label: 'Hydraulic lime' },
  { value: '30111604', label: 'Hydrated lime' },
  { value: '30111605', label: 'Lean lime' },
  { value: '30111700', label: 'Plasters' },
  { value: '30111701', label: 'Gypsum plaster' },
  { value: '30120000', label: 'Roads and landscape' },
  { value: '30121500', label: 'Bituminous derivatives' },
  { value: '30121600', label: 'Asphalts' },
  { value: '30121601', label: 'Asphalt' },
  { value: '30121602', label: 'Pitch' },
  { value: '30121603', label: 'Gilsonite' },
  { value: '30121604', label: 'Cutback products' },
  { value: '30130000', label: 'Structural building products' },
  { value: '30131500', label: 'Blocks' },
  { value: '30131502', label: 'Concrete blocks' },
  { value: '30131503', label: 'Stone blocks' },
  { value: '30131504', label: 'Ceramic blocks' },
  { value: '30131600', label: 'Bricks' },
  { value: '30131602', label: 'Ceramic bricks' },
  { value: '30131603', label: 'Concrete bricks' },
  { value: '30131604', label: 'Stone bricks' },
  { value: '30131700', label: 'Tiles and flagstones' },
  { value: '30131703', label: 'Concrete tiles or flagstones' },
  { value: '30140000', label: 'Insulation' },
  { value: '30150000', label: 'Exterior finishing materials' },
  { value: '30151500', label: 'Roofing materials' },
  { value: '30151501', label: 'Roll roofing' },
  { value: '30151502', label: 'Roof valleys' },
  { value: '30151503', label: 'Roofing fabrics' },
  { value: '30151505', label: 'Roofing membranes' },
  { value: '30151507', label: 'Shakes' },
  { value: '30151508', label: 'Shingles' },
  { value: '30151510', label: 'Slate roofing' },
  { value: '30151600', label: 'Roofing accessories' },
  { value: '30151700', label: 'Rain gutters and accessories' },
  { value: '30151800', label: 'Siding and exterior wall materials' },
  { value: '30151900', label: 'Finishing materials and products' },
  { value: '30160000', label: 'Interior finishing materials' },
  { value: '30161500', label: 'Wall finishing materials' },
  { value: '30161600', label: 'Ceiling materials' },
  { value: '30161700', label: 'Flooring' },
  { value: '30161702', label: 'Wood flooring' },
  { value: '30161800', label: 'Cabinetry' },
  { value: '30162200', label: 'Countertops ' },
  { value: '30161900', label: 'Molding and millwork' },
  { value: '30170000', label: 'Doors and windows and glass' },
  { value: '30171500', label: 'Doors' },
  { value: '30171501', label: 'Glass doors' },
  { value: '30171502', label: 'Screen doors' },
  { value: '30171503', label: 'Rolling doors' },
  { value: '30171504', label: 'Wooden doors' },
  { value: '30171505', label: 'Metal doors' },
  { value: '30171506', label: 'Storm doors' },
  { value: '30171507', label: 'Door frames' },
  { value: '30171508', label: 'Pocket doors' },
  { value: '30171600', label: 'Windows' },
  { value: '30171900', label: 'Window frames' },
  { value: '30171800', label: 'Skylights' },
  { value: '30171700', label: 'Glass products' },
  { value: '30171701', label: 'Paving blocks' },
  { value: '30171703', label: 'Beveled glass' },
  { value: '30171704', label: 'Leaded glass' },
  { value: '30171705', label: 'Laminated glass' },
  { value: '30171706', label: 'Tempered glass' },
  { value: '30171707', label: 'Safety glass' },
  { value: '30171708', label: 'Float glass' },
  { value: '30171709', label: 'Wired glass' },
  { value: '30180000', label: 'Plumbing fixtures' },
  { value: '30181500', label: 'Sanitary ware' },
  { value: '30181501', label: 'Bathtubs' },
  { value: '30181502', label: 'Bidets' },
  { value: '30181503', label: 'Showers' },
  { value: '30181504', label: 'Sinks' },
  { value: '30181505', label: 'Toilets' },
  { value: '30181506', label: 'Urinals' },
  { value: '30181507', label: 'Bathtub or shower enclosures' },
  {
    value: '30190000',
    label: 'Construction and maintenance support equipment'
  },
  { value: '30191500', label: 'Ladders and scaffolding' },
  { value: '24101609', label: 'Tilts' },
  { value: '95140000', label: 'Prefabricated buildings and structures' },
  { value: '95141500', label: 'Prefabricated farm buildings and structures' },
  { value: '95141501', label: 'Silo' },
  { value: '95141502', label: 'Greenhouse' },
  {
    value: '95141600',
    label: 'Prefabricated residential buildings and structures'
  },
  { value: '95141601', label: 'House' },
  { value: '95141602', label: 'Mobile home' },
  { value: '95141603', label: 'Cabin' },
  {
    value: '95141700',
    label: 'Prefabricated commercial and industrial buildings and structures'
  },
  { value: '95141701', label: 'In plant office' },
  { value: '95141702', label: 'Spray booth' },
  { value: '95141703', label: 'Storage shed' },
  { value: '95141704', label: 'Clean room' },
  { value: '95141705', label: 'Guardhouse' },
  { value: '95131602', label: 'Portable toilet' },
  { value: '95141706', label: 'Warehouse' },
  {
    value: '95141800',
    label: 'Prefabricated emergency relief buildings and structures'
  },
  { value: '95141801', label: 'Shelter' },
  { value: '95141802', label: 'Emergency tent or hall' },
  { value: '95141803', label: 'Container unit' },
  {
    value: '95141900',
    label: 'Prefabricated medical buildings and structures'
  },
  { value: '95141901', label: 'Medical unit' },
  { value: '95141902', label: 'Laboratory unit' },
  { value: '95141903', label: 'Dental unit' },
  { value: '95141904', label: 'Surgical units' },
  {
    value: '39120000',
    label: 'Electrical equipment and components and supplies'
  },
  { value: '39121004', label: 'Power supply units' },
  { value: '39121017', label: 'Power distribution units PDUs' },
  { value: '39121006', label: 'Power adapters or inverters' },
  { value: '41112100', label: 'Transducers' },
  {
    value: '39121300',
    label: 'Electrical boxes and enclosures and fittings and accessories'
  },
  { value: '39121400', label: 'Electrical lugs plugs and connectors' },
  { value: '39121402', label: 'Electrical plugs' },
  { value: '39121406', label: 'Electrical receptacles' },
  { value: '39121500', label: 'Electrical controls and accessories ' },
  { value: '39122216', label: 'Push button switch' },
  { value: '39121600', label: 'Circuit protection devices and accessories' },
  { value: '39121700', label: 'Electrical hardware and supplies' },
  { value: '39121701', label: 'Electrical hangers' },
  { value: '31000000', label: 'Manufacturing Components and Supplies' },
  { value: '31100000', label: 'Castings and casting assemblies' },
  { value: '31110000', label: 'Extrusions' },
  { value: '31130000', label: 'Forgings' },
  { value: '31140000', label: 'Moldings' },
  { value: '31150000', label: 'Rope and chain and cable and wire and strap' },
  { value: '31151500', label: 'Ropes' },
  { value: '31151600', label: 'Chains' },
  { value: '31151700', label: 'Mechanical cable' },
  { value: '31151800', label: 'Mechanical wire' },
  { value: '31151900', label: 'Straps' },
  { value: '31151902', label: 'Leather straps' },
  { value: '31160000', label: 'Hardware' },
  { value: '31161500', label: 'Screws' },
  { value: '31161600', label: 'Bolts' },
  { value: '31161700', label: 'Nuts' },
  { value: '31161800', label: 'Washers' },
  { value: '31161900', label: 'Springs' },
  { value: '31162000', label: 'Nails' },
  { value: '31162100', label: 'Anchors' },
  { value: '31162200', label: 'Rivets' },
  { value: '31162300', label: 'Mounting hardware' },
  { value: '31162400', label: 'Miscellaneous fasteners' },
  { value: '31162500', label: 'Brackets and braces' },
  { value: '31162600', label: 'Hooks' },
  { value: '31162700', label: 'Rolling hardware' },
  { value: '31162800', label: 'Miscellaneous hardware' },
  { value: '31162900', label: 'Clamps' },
  { value: '31163000', label: 'Couplings' },
  { value: '31170000', label: 'Bearings and bushings and wheels and gears' },
  { value: '31171500', label: 'Bearings' },
  { value: '31171504', label: 'Ball bearings' },
  { value: '31171600', label: 'Bushings' },
  { value: '31171700', label: 'Gears' },
  { value: '31171800', label: 'Industrial wheels' },
  { value: '31171900', label: 'Sprockets' },
  { value: '31180000', label: 'Packings glands boots and covers' },
  {
    value: '31190000',
    label: 'Grinding and polishing and smoothing materials'
  },
  { value: '31191500', label: 'Abrasives and abrasive media' },
  { value: '31191600', label: 'Abrasive wheels' },
  { value: '31191506', label: 'Abrasive discs' },
  { value: '31200000', label: 'Adhesives and sealants' },
  { value: '31201500', label: 'Tape' },
  { value: '31201505', label: 'Double sided tape' },
  { value: '31201600', label: 'Adhesives' },
  { value: '31210000', label: 'Paints and primers and finishes' },
  { value: '31211500', label: 'Paints and primers' },
  { value: '31211507', label: 'Spray paints' },
  { value: '31211600', label: 'Paint additives' },
  { value: '31211700', label: 'Miscellaneous finishes' },
  { value: '31211800', label: 'Paint solvents and thinners' },
  { value: '31211900', label: 'Paint applicators and painting accessories' },
  { value: '31220000', label: 'Dyeing and tanning extracts' },
  { value: '31240000', label: 'Industrial optics' },
  {
    value: '31250000',
    label: 'Pneumatic and hydraulic and electric control systems'
  },
  { value: '31260000', label: 'Housings and cabinets and casings' },
  { value: '32000000', label: 'Electronic Components and Supplies' },
  {
    value: '32100000',
    label: 'Printed circuits and integrated circuits and microassemblies'
  },
  {
    value: '32101500',
    label: 'Circuit assemblies and radio frequency RF components'
  },
  { value: '32101600', label: 'Integrated circuits' },
  { value: '32101601', label: 'Random access memory RAM' },
  { value: '32110000', label: 'Discrete semiconductor devices' },
  { value: '32120000', label: 'Passive discrete components' },
  { value: '32121700', label: 'Discrete components' },
  { value: '32121701', label: 'Rectifiers' },
  { value: '32121702', label: 'Inductors' },
  { value: '32121703', label: 'Ferrites' },
  { value: '32121704', label: 'Static converters' },
  { value: '32121705', label: 'Inverters' },
  {
    value: '39000000',
    label:
      'Electrical Systems and Lighting and Components and Accessories and Supplies'
  },
  { value: '39100000', label: 'Lamps and lightbulbs and lamp components' },
  { value: '39101616', label: 'Ultraviolet UV lamps' },
  { value: '39101600', label: 'Lamps and lightbulbs' },
  { value: '39101601', label: 'Halogen lamps' },
  { value: '39101602', label: 'Medical lamps' },
  { value: '39101603', label: 'Solar lamps' },
  { value: '39101605', label: 'Fluorescent lamps' },
  { value: '39110000', label: 'Lighting Fixtures and Accessories' },
  { value: '39111500', label: 'Interior lighting fixtures and accessories ' },
  { value: '39111600', label: 'Exterior lighting fixtures and accessories ' },
  { value: '39111608', label: 'Residential street lights' },
  { value: '39111700', label: 'Emergency lighting and accessories' },
  { value: '39111610', label: 'Flashlight' },
  { value: '39111800', label: 'Lighting accessories' },
  {
    value: '40000000',
    label: 'Distribution and Conditioning Systems and Equipment and Components'
  },
  { value: '40100000', label: 'Heating and ventilation and air circulation' },
  { value: '40101500', label: 'Ventilation' },
  { value: '40101503', label: 'Vents' },
  { value: '40101600', label: 'Air circulation and parts and accessories' },
  { value: '40101601', label: 'Blowers' },
  { value: '40101602', label: 'Air circulators' },
  { value: '40101604', label: 'Fans' },
  { value: '40101700', label: 'Cooling equipment and parts and accessories' },
  { value: '40101701', label: 'Air conditioners' },
  { value: '40101702', label: 'Cooling exchangers' },
  { value: '40101703', label: 'Evaporative coolers' },
  { value: '40101704', label: 'Condensing units' },
  { value: '40101800', label: 'Heating equipment and parts and accessories' },
  { value: '40101801', label: 'Radiators' },
  { value: '40101802', label: 'Heat exchangers' },
  { value: '40101805', label: 'Furnaces' },
  { value: '40101806', label: 'Heat pumps' },
  { value: '40101807', label: 'Solar heating units' },
  { value: '40101808', label: 'Heating stoves' },
  { value: '40101900', label: 'Humidity control' },
  { value: '40101901', label: 'Vaporizers' },
  { value: '40101902', label: 'Dehumidifiers' },
  { value: '40101903', label: 'Humidifiers' },
  { value: '40140000', label: 'Fluid and gas distribution' },
  { value: '40141900', label: 'Ducts' },
  { value: '40142000', label: 'Hoses' },
  { value: '40141600', label: 'Valves' },
  { value: '40141700', label: 'Hardware and fittings' },
  { value: '30181605', label: 'Drain' },
  { value: '30181700', label: 'Faucets or taps' },
  { value: '30181801', label: 'Shower head' },
  { value: '30181701', label: 'Spigot' },
  { value: '30181807', label: 'Spout' },
  { value: '40150000', label: 'Industrial pumps and compressors' },
  { value: '40151500', label: 'Pumps' },
  { value: '41105103', label: 'Laboratory centrifugal pumps' },
  { value: '40151506', label: 'Hand pumps' },
  { value: '40151507', label: 'Irrigation pumps' },
  { value: '40151510', label: 'Water pumps' },
  { value: '40151600', label: 'Compressors' },
  { value: '40151601', label: 'Air compressors' },
  { value: '40151607', label: 'Refrigerant compressors' },
  { value: '40160000', label: 'Industrial filtering and purification' },
  { value: '40161500', label: 'Filters' },
  { value: '40161504', label: 'Oil filters' },
  { value: '40161505', label: 'Air filters' },
  { value: '40161600', label: 'Purification' },
  { value: '40161602', label: 'Air cleaners' },
  { value: '40161700', label: 'Separators' },
  {
    value: '41000000',
    label: 'Laboratory and Measuring and Observing and Testing Equipment'
  },
  { value: '41100000', label: 'Laboratory and scientific equipment' },
  {
    value: '41101500',
    label:
      'Laboratory blending and dispersing and homogenizing equipment and supplies'
  },
  { value: '41101502', label: 'Stomachers' },
  { value: '41101503', label: 'Laboratory sprayers' },
  { value: '41101504', label: 'Homogenizers' },
  { value: '41103802', label: 'Roller mixers' },
  { value: '41103805', label: 'Laboratory vibrators' },
  { value: '41121802', label: 'Laboratory stirring rods' },
  {
    value: '41122402',
    label: 'Magnetic spin bar retrievers or stir bar retrievers'
  },
  { value: '41103803', label: 'Stirring tables' },
  { value: '41103804', label: 'Multi bank or flocculation equipment' },
  { value: '41103900', label: 'Laboratory centrifuges and accessories' },
  { value: '41120000', label: 'Laboratory supplies and fixtures' },
  { value: '41105205', label: 'Microslide making equipment' },
  { value: '41103512', label: 'Static eliminators' },
  { value: '41104017', label: 'Sample holders' },
  {
    value: '41101700',
    label:
      'Laboratory boring and grinding and cutting and crushing and pressing equipment'
  },
  { value: '41101701', label: 'Laboratory mills' },
  { value: '41101703', label: 'Tissue grinders' },
  { value: '41102916', label: 'Microtomes' },
  { value: '41101705', label: 'Laboratory crushers or pulverizers' },
  { value: '41101706', label: 'Laboratory disintegrators' },
  { value: '41101707', label: 'Laboratory presses' },
  {
    value: '41101800',
    label: 'Laboratory electron and solid state physics equipment'
  },
  { value: '41101801', label: 'Electron guns' },
  { value: '41101802', label: 'X ray generators' },
  { value: '41101803', label: 'Coulometers' },
  { value: '41101804', label: 'Electroscopes' },
  { value: '41101805', label: 'Fluxmeters' },
  { value: '41101806', label: 'Magnetometers' },
  { value: '41101807', label: 'Electron diffraction apparatus' },
  { value: '41101808', label: 'Neutron diffraction apparatus' },
  { value: '41101809', label: 'Optical diffraction apparatus' },
  { value: '41101810', label: 'Diffractometers' },
  { value: '41101900', label: 'Laboratory ionic equipment' },
  { value: '41101901', label: 'Ion sources' },
  { value: '41101902', label: 'Ion exchange apparatus' },
  { value: '41101903', label: 'Ion implantation equipment' },
  { value: '41104001', label: 'Sample changers' },
  { value: '41104002', label: 'Sample oxidizer' },
  { value: '41104003', label: 'Sample preparation line' },
  { value: '41104004', label: 'Sample preparation bombs' },
  { value: '41104007', label: 'Water samplers' },
  { value: '41104008', label: 'Air samplers or collectors' },
  { value: '41104010', label: 'Reagent kits for use with air samplers' },
  { value: '41104011', label: 'Filters or other spare parts for samplers' },
  { value: '41104012', label: 'Dust fall holders or jars' },
  { value: '41104013', label: 'Sulphur dioxide or smoke samplers' },
  { value: '41104014', label: 'Sample applicators' },
  { value: '41104015', label: 'Plant samples analysis equipment' },
  { value: '41104201', label: 'Water purification reagents' },
  { value: '41104202', label: 'Deionization or demineralization equipment' },
  { value: '41104203', label: 'Base exchange equipment' },
  { value: '41104204', label: 'Reverse osmosis equipment' },
  { value: '41104205', label: 'Ultra violet water purification units' },
  { value: '41104206', label: 'Ultra pure water systems' },
  { value: '41104207', label: 'Water analysis systems' },
  { value: '41104208', label: 'Dehydrators' },
  { value: '41104209', label: 'Deoxiders' },
  { value: '41104210', label: 'Dissolvers' },
  { value: '41112210', label: 'Remote reading thermometers' },
  { value: '41112211', label: 'Resistance thermometers' },
  { value: '41112212', label: 'Surface thermometers' },
  { value: '41104301', label: 'Standard fermentation units' },
  { value: '41104302', label: 'Continuous culture apparatus' },
  { value: '41104303', label: 'Anaerobic jars or accessories' },
  { value: '41104304', label: 'Digestion systems' },
  { value: '41104403', label: 'Tissue culture incubators' },
  { value: '41104405', label: 'Shaking incubators' },
  { value: '41104305', label: 'Inspissators' },
  { value: '41104406', label: 'Plate incubators' },
  { value: '41104306', label: 'In vitro culture equipment' },
  { value: '41102400', label: 'Laboratory heating and drying equipment' },
  { value: '41102401', label: 'Gas burners' },
  { value: '41102402', label: 'Spirit burners' },
  { value: '41102403', label: 'Laboratory incinerators' },
  { value: '41102404', label: 'Laboratory heaters' },
  { value: '41102405', label: 'Heating mantles or tapes' },
  { value: '41102406', label: 'Laboratory hotplates' },
  { value: '41102407', label: 'Warming cabinets' },
  { value: '41104508', label: 'Induction dryers' },
  { value: '41102410', label: 'Infrared dryers' },
  { value: '23201201', label: 'Spray dryers' },
  { value: '41102412', label: 'Hot air blowers' },
  { value: '41104600', label: 'Laboratory furnaces and accessories' },
  { value: '41104503', label: 'Ageing ovens' },
  { value: '41104505', label: 'Laboratory quartz oven pots' },
  {
    value: '41102500',
    label: 'Laboratory entomological equipment and accessories'
  },
  { value: '41102501', label: 'Laboratory insect containers' },
  { value: '41102502', label: 'Rearing facilities for entomology' },
  { value: '41102503', label: 'Fabric or netting for entomology' },
  { value: '41102504', label: 'Entomological pinning equipment' },
  { value: '41102505', label: 'Entomological mounting materials' },
  { value: '41102506', label: 'Entomological trays' },
  { value: '41102507', label: 'Entomological catching equipment' },
  { value: '41102508', label: 'Entomological aspirators' },
  { value: '41102509', label: 'Entomological dippers' },
  { value: '41102510', label: 'Entomological monocups' },
  { value: '41102511', label: 'Entomological sticky traps' },
  { value: '41102512', label: 'Insect test kits' },
  { value: '41102513', label: 'Entomological display units' },
  { value: '41102600', label: 'Animal laboratory equipment and accessories' },
  { value: '41102601', label: 'Laboratory cages for small animals' },
  { value: '41102602', label: 'Aquaria equipment' },
  { value: '41102603', label: 'Animal identification supplies' },
  { value: '41102604', label: 'Animal catching devices' },
  { value: '41102605', label: 'Fish aeration systems' },
  { value: '41102700', label: 'Crystallography equipment' },
  { value: '41102701', label: 'Crystal lattice models' },
  { value: '41102702', label: 'Scintillation crystal assemblies' },
  { value: '41102703', label: 'Light scattering equipment' },
  { value: '41102704', label: 'X ray diffraction equipment' },
  { value: '41102705', label: 'Crystallizers' },
  { value: '41102900', label: 'Histology equipment' },
  { value: '41102901', label: 'Tissue embedding stations' },
  { value: '41102905', label: 'Histological staining apparatus' },
  { value: '41104117', label: 'Specimen holders' },
  { value: '41104118', label: 'Specimen collection container' },
  { value: '41102424', label: 'Slide warmers' },
  { value: '41102909', label: 'Tissue processors' },
  { value: '41102910', label: 'Tissue culture apparatus' },
  {
    value: '41102911',
    label: 'Histological knives or knife holders or blades'
  },
  { value: '41102912', label: 'Histological glass knife makers' },
  { value: '41102913', label: 'Histological hones or straps or compounds' },
  { value: '41102914', label: 'Ultrasonic disintegrators' },
  { value: '41103000', label: 'Laboratory cooling equipment' },
  { value: '41103001', label: 'Refrigerated cooling plate probes' },
  { value: '41103003', label: 'Cryostats' },
  { value: '41103004', label: 'Fan circulated ovens' },
  {
    value: '41103005',
    label: 'Ultra cold or ultralow upright cabinets or freezers'
  },
  { value: '41103006', label: 'Cryogenic or liquid nitrogen freezers' },
  { value: '41103007', label: 'Chilling units or cold water circulators' },
  { value: '41103008', label: 'Refrigerated cooling modules' },
  { value: '41121700', label: 'Test Tubes' },
  { value: '41103200', label: 'Laboratory washing and cleaning equipment' },
  { value: '41103201', label: 'Chemical engineering washers' },
  { value: '41103202', label: 'Laboratory washing machines' },
  { value: '41103203', label: 'Pipette washers' },
  { value: '41103300', label: 'Fluid mechanics equipment' },
  { value: '41103301', label: 'Liquid scintillation counters' },
  { value: '41103302', label: 'Battery acid hydrometers' },
  { value: '41103303', label: 'Densitometers' },
  { value: '41104509', label: 'Vacuum ovens' },
  { value: '41103305', label: 'High vacuum equipment' },
  { value: '41103306', label: 'Pneumatic vacuum equipment' },
  { value: '41103307', label: 'Vacuum or mercury vapour equipment' },
  { value: '41103308', label: 'High vacuum combustion apparatus' },
  { value: '41103309', label: 'Flow injection analysis equipment' },
  {
    value: '41103310',
    label: 'Gas or vapour concentration measuring instruments'
  },
  { value: '41103311', label: 'Manometers' },
  { value: '41103312', label: 'Viscosimeters' },
  { value: '41103313', label: 'Depth indicators' },
  { value: '41103314', label: 'Microscopic structure estimation apparatus' },
  { value: '41103315', label: 'Solution strength estimation apparatus' },
  {
    value: '41103400',
    label: 'Laboratory environmental conditioning equipment'
  },
  { value: '41103401', label: 'Contamination control screens' },
  { value: '41103403', label: 'Microbiological aircontrol equipment' },
  { value: '41103406', label: 'Isolation glove boxes' },
  { value: '41103500', label: 'Laboratory enclosures and accessories' },
  { value: '41103501', label: 'Ebuliometer' },
  { value: '41103502', label: 'Fume hoods or cupboards' },
  { value: '41103504', label: 'Laminar flow cabinets or stations' },
  { value: '41104801', label: 'Flask or retort units' },
  { value: '41104802', label: 'Bi distillation units' },
  { value: '41104803', label: 'Laboratory evaporators' },
  { value: '41104806', label: 'Extracting equipment for laboratories' },
  { value: '41104901', label: 'Laboratory line filters' },
  { value: '41104902', label: 'Gel filtration equipment' },
  { value: '41104903', label: 'Ultra filtration equipment' },
  { value: '41104904', label: 'Sintered cell filters' },
  { value: '41104905', label: 'Thin channel filtration equipment' },
  { value: '41104907', label: 'Molecular filtration equipment' },
  { value: '41104908', label: 'Laboratory cartridge element filters' },
  { value: '41104909', label: 'Laboratory filter holders or cyclones' },
  { value: '41104911', label: 'Laboratory air filtration systems' },
  { value: '41104919', label: 'Laboratory HEPA filters' },
  { value: '41104912', label: 'Fluid presses filter' },
  { value: '41105001', label: 'Laboratory separators' },
  { value: '41105002', label: 'Laboratory sifting equipment' },
  { value: '41105003', label: 'Test sieves' },
  { value: '41103509', label: 'Laboratory scrubbers' },
  { value: '41115602', label: 'Titration equipment' },
  { value: '41121502', label: 'Laboratory diluters' },
  { value: '41104810', label: 'Fractionation apparatus' },
  { value: '41104812', label: 'Distillation pipings or columns or fittings' },
  { value: '41104813', label: 'Reflux components' },
  { value: '41104814', label: 'Laboratory heat exchange condensers' },
  { value: '41103700', label: 'Laboratory baths' },
  { value: '41103701', label: 'Circulating baths' },
  { value: '41103702', label: 'Thermostatic baths' },
  { value: '41103703', label: 'Multiple baths' },
  { value: '41103704', label: 'Biological baths' },
  { value: '41103705', label: 'Organ baths' },
  { value: '41103706', label: 'Water baths' },
  { value: '41103707', label: 'Oil baths' },
  { value: '41103708', label: 'Sand baths' },
  { value: '41103709', label: 'Refrigerated baths' },
  {
    value: '41110000',
    label: 'Measuring and observing and testing instruments'
  },
  { value: '41111500', label: 'Weight measuring instruments' },
  { value: '41111501', label: 'Electronic toploading balances' },
  { value: '41111502', label: 'Laboratory balances' },
  { value: '41111503', label: 'Mechanical balances' },
  { value: '41111504', label: 'Pull spring balances' },
  { value: '41111505', label: 'Calibration weights or weight sets' },
  { value: '41111506', label: 'Animal weighing scales' },
  { value: '41111507', label: 'Bench scales' },
  { value: '41111508', label: 'Bodyweight measuring scales' },
  { value: '41111509', label: 'Floor or platform scales' },
  { value: '41111510', label: 'Postal scales' },
  { value: '41111511', label: 'Truck or rail scales' },
  { value: '41111613', label: 'Distance meters' },
  { value: '41111614', label: 'Height gauges' },
  { value: '41111615', label: 'Laser measuring systems' },
  { value: '41111616', label: 'Measuring wheels for distance' },
  {
    value: '41111600',
    label: 'Length and thickness and distance measuring instruments'
  },
  { value: '41111601', label: 'Micrometers' },
  { value: '41111602', label: 'Pedometers' },
  { value: '41111603', label: 'Rangefinders' },
  { value: '41111604', label: 'Rulers' },
  { value: '41111605', label: 'Strain gauges' },
  { value: '41111606', label: 'Tellurometers' },
  { value: '41111607', label: 'Thread counters or gauges' },
  { value: '41111621', label: 'Calipers' },
  { value: '41111709', label: 'Binocular light compound microscopes' },
  { value: '41111710', label: 'Combination electron and light microscopes' },
  { value: '41111720', label: 'Scanning electron microscopes' },
  { value: '41111712', label: 'Inverted microscopes' },
  {
    value: '41111700',
    label: 'Viewing and observing instruments and accessories'
  },
  { value: '41111701', label: 'Ion microscopes' },
  { value: '41111702', label: 'Monocular microscopes' },
  { value: '41111703', label: 'Stereo or dissecting light microscopes' },
  { value: '41111704', label: 'Illuminators for microscopes' },
  { value: '41111705', label: 'Microscope objectives' },
  { value: '41111706', label: 'Photo attachments for microscopes' },
  { value: '41111707', label: 'Profile projectors' },
  { value: '41111708', label: 'Video attachments for microscopes' },
  { value: '41111800', label: 'Non destructive examination equipment' },
  { value: '41111808', label: 'X ray radiography examination equipment' },
  { value: '41111900', label: 'Indicating and recording instruments' },
  { value: '41111901', label: 'Counters' },
  { value: '41111902', label: 'Electronic counters' },
  { value: '41111903', label: 'Metal detectors' },
  { value: '41111904', label: 'Electronic columns' },
  { value: '41111905', label: 'Electronic measuring probes' },
  { value: '41111906', label: 'Chart recorders' },
  { value: '41111907', label: 'Digital readout recorders' },
  { value: '41111908', label: 'Graphic recorders' },
  { value: '41111909', label: 'Magnetic tape recorders' },
  { value: '41111910', label: 'Multipen recorders' },
  { value: '41111911', label: 'Oscillographic recorders' },
  { value: '41111912', label: 'Physiological recorders' },
  { value: '41111913', label: 'Point plotting recorders' },
  { value: '41111914', label: 'Servo recorders' },
  { value: '41111915', label: 'Bi metallic sensors' },
  { value: '41111916', label: 'Non contact sensors' },
  { value: '41111917', label: 'Digital testers' },
  { value: '41111918', label: 'Gyroscopic instruments' },
  { value: '41115301', label: 'Light absorption meters' },
  { value: '41115302', label: 'Anechoic chambers' },
  { value: '41115303', label: 'Frequency analyzers' },
  { value: '41115304', label: 'Frequency counters or timer or dividers' },
  { value: '41115307', label: 'Lasers' },
  { value: '41115308', label: 'Lightmeters' },
  { value: '41115310', label: 'Optical calibration sets' },
  { value: '41115311', label: 'Photometers' },
  { value: '41115314', label: 'Polarimeters' },
  { value: '41115315', label: 'Polariscopes' },
  { value: '41115316', label: 'Reflectometers' },
  { value: '41115402', label: 'Spectrographs' },
  { value: '41115403', label: 'Spectrometers' },
  { value: '41115404', label: 'Mass spectrometers' },
  { value: '41115405', label: 'Proton spectrometers' },
  { value: '41115406', label: 'Spectrophotometers' },
  { value: '41115317', label: 'Stroboscopes' },
  { value: '41112200', label: 'Temperature and heat measuring instruments' },
  { value: '41112201', label: 'Calorimeters' },
  { value: '41112202', label: 'Heat tracing equipment' },
  { value: '41112203', label: 'Melting point recorders' },
  { value: '41112204', label: 'Pyrometers' },
  { value: '41112205', label: 'Temperature regulators' },
  { value: '41112206', label: 'Thermocouples' },
  { value: '41112207', label: 'Thermographs' },
  { value: '41112209', label: 'Thermostats' },
  { value: '41112300', label: 'Humidity and moisture measuring instruments' },
  { value: '41112301', label: 'Hygrometers' },
  { value: '41112302', label: 'Psychrometers' },
  { value: '41112303', label: 'Temperature humidity testers' },
  { value: '41112400', label: 'Pressure measuring and control instruments' },
  { value: '41112401', label: 'Depth gauges' },
  { value: '41112402', label: 'Manostats' },
  { value: '41112403', label: 'Pressure indicators' },
  { value: '41112404', label: 'Pressure regulator' },
  { value: '41112405', label: 'Pressure or vacuum recorders' },
  { value: '41112406', label: 'Vacuum gauges' },
  {
    value: '41112500',
    label: 'Liquid and gas flow measuring and observing instruments'
  },
  { value: '41112501', label: 'Flowmeters' },
  { value: '41112502', label: 'Rheometers' },
  { value: '41112503', label: 'Rotameters' },
  { value: '41112700', label: 'Laboratory seed and feed testing equipment' },
  { value: '41112701', label: 'Grain analyzers' },
  { value: '41112702', label: 'Seed counters' },
  {
    value: '41112800',
    label: 'Transportation related equipment and instruments'
  },
  { value: '41112801', label: 'Speedometers' },
  { value: '41112802', label: 'Tachometers' },
  { value: '41112900', label: 'Navigational equipment and instruments' },
  { value: '41112901', label: 'Direction finding compasses' },
  { value: '41112902', label: 'Radio navigation instruments' },
  { value: '41112903', label: 'Sextants' },
  { value: '41113000', label: 'Chemical evaluation instruments and supplies' },
  { value: '41113001', label: 'Digital Analyzer controllers' },
  {
    value: '41113002',
    label: 'Chemiluminescence or bioluminescence analyzers'
  },
  { value: '41113003', label: 'Electrogravimetry analyzers' },
  { value: '41113004', label: 'Flame ionization analyzers' },
  { value: '41113005', label: 'Ion analyzers' },
  { value: '41113006', label: 'Radiometry analyzers' },
  { value: '41113007', label: 'Random access analyzers' },
  { value: '41113008', label: 'Cintigraphic analyzers' },
  { value: '41113009', label: 'Thermal differential analyzers' },
  { value: '41113010', label: 'Thermo gravimetry analyzers' },
  { value: '41115703', label: 'Gas chromatographs' },
  { value: '41115704', label: 'Ion chromatographs' },
  { value: '41115705', label: 'Liquid chromatographs' },
  {
    value: '41115707',
    label: 'High pressure liquid chromatograph chromatography'
  },
  { value: '41115708', label: 'High pressure thin layer chromatograph TLC' },
  { value: '41115318', label: 'Colorimeters' },
  { value: '41113023', label: 'Gel partition equipment' },
  { value: '41113024', label: 'Hydrometers' },
  { value: '41113025', label: 'Monochromators' },
  { value: '41113026', label: 'Nephelometers' },
  { value: '41113027', label: 'Osmometers' },
  { value: '41115603', label: 'pH meters' },
  { value: '41113029', label: 'Polarographs' },
  { value: '41113030', label: 'Radiochromatographic scanner' },
  { value: '41113031', label: 'Saccharometers' },
  { value: '41113033', label: 'Volumeters' },
  { value: '41113100', label: 'Gas analyzers and monitors' },
  { value: '41113107', label: 'Nitrogen gas analyzers' },
  { value: '41113108', label: 'Nitrogen oxide analyzers' },
  { value: '41113109', label: 'ORSAT equipment' },
  { value: '41113110', label: 'Oxygen gas analyzers' },
  { value: '41113300', label: 'Liquid and solid and elemental analyzers' },
  { value: '41113301', label: 'Acid or base analyzers' },
  { value: '41115801', label: 'Amino acid analyzers' },
  { value: '41113309', label: 'Enzyme analyzers' },
  { value: '41113310', label: 'Fatty acid analyzers' },
  { value: '41113311', label: 'Halide detector lamp' },
  { value: '41113312', label: 'Lactate analyzers' },
  { value: '41113313', label: 'Mineral oil testing instruments' },
  { value: '41115823', label: 'Protein analyzers' },
  { value: '41113318', label: 'Uranium analyzers' },
  { value: '41113319', label: 'Water analyzers' },
  { value: '41113400', label: 'Nuclear evaluation instruments' },
  {
    value: '41113600',
    label: 'Electrical measuring and testing equipment and accessories'
  },
  { value: '41113601', label: 'Ammeters' },
  { value: '41113602', label: 'Phasemeters' },
  { value: '41113603', label: 'Laboratory bridges' },
  { value: '41113604', label: 'Capacitance meters' },
  { value: '41113605', label: 'Thermoanalysis derivatographs' },
  { value: '41113606', label: 'Freeze watch indicators' },
  { value: '41113607', label: 'Heat stress monitors' },
  { value: '41113608', label: 'Coincidence or anticoincidence counters' },
  { value: '41115609', label: 'Conductivity meters' },
  { value: '41113611', label: 'Cross talk meters' },
  { value: '41113612', label: 'Earth resistance testers' },
  { value: '41113613', label: 'Electrical value recorders' },
  { value: '41113614', label: 'Electromagnetic field meters' },
  { value: '41113615', label: 'Electrometers' },
  { value: '41113616', label: 'Electronic loads' },
  { value: '41113617', label: 'Field strength measuring equipment' },
  { value: '41113618', label: 'Gain measuring instruments' },
  { value: '41113619', label: 'Galvanometers' },
  { value: '41113620', label: 'High voltage cable detection' },
  { value: '41113621', label: 'Impedance meters' },
  { value: '41113622', label: 'Calibrated inductance coils or boxes' },
  { value: '41113623', label: 'Insulation resistance meters' },
  { value: '41113624', label: 'Insulation testers' },
  { value: '41113625', label: 'Ionization chambers' },
  { value: '41113626', label: 'Ionmeters' },
  { value: '41113627', label: 'Line earth loop testers' },
  { value: '41113628', label: 'Megohmmeters' },
  { value: '41113629', label: 'Microwave leakage meters' },
  { value: '41113630', label: 'Multimeters' },
  { value: '41113631', label: 'Ohmmeters' },
  { value: '41113632', label: 'Oscillographs' },
  { value: '41113633', label: 'Potentiometers' },
  { value: '41113634', label: 'Q Meters' },
  { value: '41113635', label: 'Calibrated resistance measuring equipment' },
  { value: '41113636', label: 'Level generators' },
  { value: '41113637', label: 'Voltage or current meters' },
  { value: '41113638', label: 'Oscilloscopes' },
  { value: '41113639', label: 'Accelerometers' },
  { value: '41113640', label: 'Wattmeters' },
  {
    value: '41113700',
    label: 'Electronic and communication measuring and testing instruments'
  },
  { value: '41113701', label: 'Cathode ray tube tester' },
  { value: '41113702', label: 'Comparators' },
  { value: '41113703', label: 'Directional coupler' },
  { value: '41113704', label: 'Integrated circuit testers' },
  { value: '41113705', label: 'Logic state testers' },
  { value: '41113706', label: 'Semiconductor testers' },
  { value: '41113708', label: 'Power meters' },
  { value: '41113709', label: 'Modulation meters' },
  { value: '41113710', label: 'Level meter' },
  { value: '41113711', label: 'Network analyzers' },
  { value: '41113712', label: 'Tape testers' },
  { value: '41113713', label: 'Tapespeed testers' },
  { value: '41113800', label: 'Geophysical and geotechnical instruments' },
  { value: '41113801', label: 'Geological compasses' },
  { value: '41113802', label: 'Geological prospecting apparatus' },
  { value: '41113803', label: 'Electromagnetic geophysical instruments' },
  { value: '41113804', label: 'Gravity geophysical instruments' },
  {
    value: '41113805',
    label: 'Induced polarization IP geophysical instruments'
  },
  { value: '41113806', label: 'Magnetometer geophysical instruments' },
  { value: '41113807', label: 'Resistivity geophysical instruments' },
  { value: '41113808', label: 'Gravimeters' },
  { value: '41113900', label: 'Soil measuring equipment' },
  { value: '41113901', label: 'Bore measuring instruments' },
  { value: '41113902', label: 'Dissolution or disintegration testers' },
  { value: '41113903', label: 'Particle size measuring apparatus' },
  { value: '41113904', label: 'Penetrometers' },
  { value: '41113905', label: 'Permeability testing apparatus' },
  { value: '41113906', label: 'Permeability or porosity estimation apparatus' },
  { value: '41113907', label: 'Porosimeters' },
  { value: '41113908', label: 'Sand testing apparatus' },
  { value: '41113909', label: 'Soil core sampling apparatus' },
  { value: '41113910', label: 'Soil testing kits' },
  { value: '41114000', label: 'Rock and strata measuring equipment' },
  { value: '41114001', label: 'Clinometers' },
  { value: '41114100', label: 'Seismological instruments' },
  { value: '41114102', label: 'Earthquake simulators' },
  { value: '41114103', label: 'Seismic alarm modules' },
  { value: '41114104', label: 'Seismic amplifiers' },
  { value: '41114105', label: 'Portable seismic apparatus' },
  { value: '41114106', label: 'Seismic recorders or seismographs' },
  { value: '41114107', label: 'Seismometers' },
  { value: '41114108', label: 'Vibrometers' },
  { value: '41114200', label: 'Land surveying instruments' },
  { value: '41114201', label: 'Measuring tapes' },
  { value: '41114202', label: 'Measuring rods' },
  { value: '41114203', label: 'Measuring tables' },
  { value: '41114300', label: 'Hydrological instruments' },
  { value: '41114301', label: 'Open stream current meters' },
  { value: '41114302', label: 'Logging instruments for water wells' },
  { value: '41114303', label: 'Open stream water level recorders' },
  { value: '41114400', label: 'Meteorological instruments' },
  { value: '41114401', label: 'Anemometers' },
  { value: '41114402', label: 'Barometers' },
  { value: '41114403', label: 'Precipitation or evaporation recorders' },
  { value: '41114404', label: 'Radiosonde apparatus' },
  { value: '41114405', label: 'Rainfall recorders' },
  {
    value: '41114406',
    label: 'Precipitation or evaporation surface observing apparatus'
  },
  { value: '41114407', label: 'Solar radiation surface observing apparatus' },
  {
    value: '41114408',
    label: 'Temperature or humidity surface observing apparatus'
  },
  { value: '41114409', label: 'Wind surface observing apparatus' },
  { value: '41114500', label: 'Mechanical instruments' },
  { value: '41114501', label: 'Dynamometers' },
  { value: '41114502', label: 'Elastometers' },
  { value: '41114503', label: 'Extensometers' },
  { value: '41114504', label: 'Pitch measuring instruments' },
  { value: '41114505', label: 'Roundness testing instruments' },
  { value: '41114506', label: 'Spherometers' },
  { value: '41114507', label: 'Spring testing machines' },
  { value: '41114508', label: 'Surface testers' },
  { value: '41114509', label: 'Tensiometers' },
  {
    value: '41114600',
    label: 'Metals and metallurgy and structural materials testing instruments'
  },
  { value: '41114601', label: 'Abrasion testers' },
  { value: '41114602', label: 'Compression testers' },
  { value: '41114603', label: 'Concrete or cement testing instruments' },
  { value: '41114604', label: 'Corrosion testers' },
  { value: '41114605', label: 'Crack or corrosion detectors' },
  { value: '41114606', label: 'Creep testers' },
  { value: '41114607', label: 'Ductility testing machines' },
  { value: '41114608', label: 'Fatigue testers' },
  { value: '41114609', label: 'Forging testing apparatus' },
  { value: '41114610', label: 'Foundry testing apparatus' },
  { value: '41114611', label: 'Hardness testers' },
  { value: '41114612', label: 'Impact testers' },
  { value: '41114613', label: 'Load frame' },
  { value: '41114614', label: 'Metal testing instruments' },
  { value: '41114615', label: 'Photoelastic testing instruments' },
  { value: '41114616', label: 'Proofstress indicators' },
  { value: '41114617', label: 'Relaxation testers' },
  { value: '41114618', label: 'Roughness measuring instruments' },
  { value: '41114619', label: 'Shear strength testers' },
  { value: '41114620', label: 'Shock testing apparatus' },
  { value: '41114621', label: 'Tension testers' },
  { value: '41114622', label: 'Torsion testers' },
  { value: '41114623', label: 'Flexure or transverse testing machines' },
  { value: '41114624', label: 'Vibration testers' },
  { value: '41114625', label: 'Wear testers' },
  { value: '41114626', label: 'Welding testing apparatus' },
  {
    value: '41114700',
    label: 'Paper and wood and textile testing instruments'
  },
  { value: '41114800', label: 'Ceramics and glass testing instruments' },
  { value: '41114802', label: 'Glass testing instruments' },
  { value: '41115100', label: 'Coal and ore testing instruments' },
  { value: '41115200', label: 'Radar and sonar systems and components' },
  {
    value: '42000000',
    label: 'Medical Equipment and Accessories and Supplies'
  },
  { value: '42182200', label: 'Medical thermometers and accessories' },
  { value: '42182100', label: 'Stethoscopes and related products' },
  { value: '42141800', label: 'Electrotherapy equipment' },
  { value: '42201800', label: 'Medical diagnostic x ray products' },
  { value: '42203200', label: 'Radiotherapy simulators' },
  {
    value: '42201500',
    label: 'Medical computed tomography CT or CAT systems and related products'
  },
  {
    value: '42203000',
    label: 'Medical linear accelerators and related products'
  },
  { value: '42201807', label: 'Medical radioisotope scanners' },
  { value: '42152008', label: 'Dental x ray units' },
  { value: '42271700', label: 'Oxygen therapy delivery systems and devices' },
  { value: '42271802', label: 'Nebulizers' },
  { value: '42271708', label: 'Medical oxygen masks or parts' },
  { value: '42271713', label: 'Medical hyperbaric chambers' },
  {
    value: '42260000',
    label: 'Postmortem and mortuary equipment and supplies'
  },
  { value: '42291614', label: 'Surgical scissors' },
  { value: '42293100', label: 'Surgical retractors and related products' },
  { value: '42272001', label: 'Intubation laryngoscopes ' },
  { value: '42192301', label: 'Patient lifts' },
  { value: '42141806', label: 'Diathermy units' },
  {
    value: '42142108',
    label: 'Therapeutic heating or cooling pads or compresses or packs'
  },
  {
    value: '42294500',
    label: 'Ophthalmic specialty instruments and related products'
  },
  { value: '42312209', label: 'Umbilical tapes' },
  {
    value: '42181700',
    label: 'Electrocardiography EKG units and related products'
  },
  { value: '42296600', label: 'Medical stereotactic systems' },
  { value: '42182327', label: 'Thesiometers' },
  { value: '42181600', label: 'Blood pressure units and related products' },
  { value: '42160000', label: 'Dialysis equipment and supplies' },
  { value: '42161509', label: 'Peritoneal dialysis units or cyclers ' },
  { value: '42161601', label: 'Hemodialysis administration kits or sets' },
  { value: '42161701', label: 'Hemofilters' },
  {
    value: '42151600',
    label: 'Dental and subspecialty instruments and devices'
  },
  { value: '42152902', label: 'Bands for dental matrix' },
  {
    value: '42152508',
    label: 'Dental syringes or needles or syringes with needles'
  },
  { value: '42203500', label: 'Cardiac pacemakers and related products' },
  { value: '42312206', label: 'Suture needles' },
  { value: '42142504', label: 'Biopsy needles' },
  {
    value: '42242000',
    label: 'Prosthetic devices or accessories and supplies'
  },
  {
    value: '42211703',
    label: 'Braille writing paper or plastic for physically challenged'
  },
  {
    value: '42211711',
    label: 'Voice synthesizers for the physically challenged'
  },
  {
    value: '42211706',
    label: 'Letter or symbol boards for the physically challenged'
  },
  { value: '42211702', label: 'Braille devices for the physically challenged' },
  { value: '42312104', label: 'Ostomy collection supplies' },
  { value: '42211502', label: 'Crutches ' },
  { value: '42192210', label: 'Wheelchairs' },
  { value: '42192208', label: 'Wheelchair accessories' },
  { value: '42192209', label: 'Wheelchair ramps' },
  { value: '42211917', label: 'Tableware for the physically challenged' },
  { value: '42120000', label: 'Veterinary equipment and supplies' },
  { value: '42121500', label: 'Veterinary equipment' },
  { value: '42121501', label: 'Veterinary blood pressure testers' },
  { value: '42121600', label: 'Veterinary products' },
  {
    value: '43000000',
    label: 'Information Technology Broadcasting and Telecommunications'
  },
  { value: '43201534', label: 'Exchange component CODEC interfaces' },
  { value: '43201509', label: 'Exchange datacom modules' },
  { value: '43222806', label: 'Punch down block' },
  { value: '43191504', label: 'Fixed phones' },
  { value: '43221504', label: 'Premise branch exchange PBX systems' },
  { value: '43191501', label: 'Mobile phones' },
  { value: '43191505', label: 'Answering machines' },
  { value: '43191503', label: 'Pay phones' },
  { value: '43191609', label: 'Phone headsets' },
  { value: '43221519', label: 'Voice mail systems' },
  { value: '43222802', label: 'Circuit switchboard equipment' },
  { value: '43221713', label: 'Shortwave core equipment' },
  { value: '43222631', label: 'Wireless fidelity base stations Wifi' },
  { value: '43222700', label: 'Telegraph equipment' },
  { value: '43223000', label: 'Teletype equipment' },
  { value: '43221522', label: 'Teleconference equipment' },
  { value: '43221711', label: 'Satellite access equipment' },
  { value: '43191502', label: 'Pagers' },
  { value: '43221525', label: 'Intercom systems' },
  { value: '43201403', label: 'Modem cards' },
  { value: '43221709', label: 'Microwave antennas' },
  { value: '43221703', label: 'Television antennas' },
  { value: '43221712', label: 'Satellite antennas' },
  { value: '43221706', label: 'Radio antennas' },
  { value: '43221718', label: 'Radar antennas' },
  { value: '43211500', label: 'Computers' },
  { value: '43211503', label: 'Notebook computers' },
  { value: '43211602', label: 'Docking stations' },
  { value: '43211507', label: 'Desktop computers' },
  { value: '43211504', label: 'Personal digital assistant PDAs or organizers' },
  { value: '43211501', label: 'Computer servers' },
  { value: '43201503', label: 'Central processing unit CPU processors' },
  { value: '43201513', label: 'Motherboards' },
  {
    value: '43201500',
    label: 'System boards processors interfaces or modules'
  },
  { value: '43201535', label: 'Serial infrared ports' },
  { value: '43201401', label: 'Graphics or video accelerator cards' },
  { value: '43201404', label: 'Network interface cards' },
  { value: '43201502', label: 'Audio accelerator cards' },
  {
    value: '43201533',
    label: 'Musical instrument digital interface MIDI interfaces'
  },
  { value: '43211700', label: 'Computer data input devices' },
  { value: '43211712', label: 'Graphics tablets' },
  { value: '43211705', label: 'Game pads or joy sticks' },
  { value: '43211706', label: 'Keyboards' },
  { value: '43211711', label: 'Scanners' },
  { value: '43211713', label: 'Touch pads' },
  { value: '43201801', label: 'Floppy drives' },
  { value: '43201809', label: 'Read write compact disc CD' },
  { value: '43201806', label: 'Tape arrays' },
  { value: '43211900', label: 'Computer displays' },
  { value: '43211901', label: 'Cathode ray tube CRT monitors' },
  { value: '43212100', label: 'Computer printers' },
  { value: '43212107', label: 'Plotter printers' },
  { value: '43212101', label: 'Band printers' },
  { value: '43212102', label: 'Dot matrix printers' },
  { value: '43212106', label: 'Line matrix printers' },
  { value: '43212108', label: 'Thermal tape printers' },
  { value: '43222612', label: 'Network switches' },
  { value: '43230000', label: 'Software' },
  { value: '43232300', label: 'Data management and query software' },
  { value: '43233004', label: 'Operating system software' },
  { value: '43232603', label: 'Facilities management software' },
  { value: '43231513', label: 'Office suite software' },
  { value: '43231604', label: 'Financial analysis software' },
  { value: '43231601', label: 'Accounting software' },
  { value: '43232202', label: 'Document management software' },
  { value: '43233413', label: 'Voice recognition software' },
  { value: '43232106', label: 'Presentation software' },
  { value: '43232104', label: 'Word processing software' },
  { value: '43232604', label: 'Computer aided design CAD software' },
  { value: '43232500', label: 'Educational or reference software' },
  { value: '43232105', label: 'Charting software' },
  { value: '43232110', label: 'Spreadsheet software' },
  { value: '43232401', label: 'Configuration management software' },
  { value: '43232400', label: 'Development software' },
  { value: '43232404', label: 'Graphical user interface development software' },
  { value: '43232406', label: 'Program testing software' },
  { value: '43233410', label: 'Printer driver software' },
  { value: '43232900', label: 'Networking software' },
  { value: '43232408', label: 'Web platform development software' },
  { value: '43233000', label: 'Operating environment software' },
  { value: '43233002', label: 'Network operation system software' },
  { value: '43231512', label: 'License management software' },
  { value: '43233401', label: 'Compact disc CD server software' },
  { value: '43232313', label: 'Transaction server software' },
  { value: '43232908', label: 'Switch or router software' },
  { value: '43232906', label: 'Multiplexer software' },
  { value: '43232902', label: 'Communications server software' },
  { value: '43232702', label: 'Desktop communications software' },
  { value: '43232703', label: 'Interactive voice response software' },
  { value: '43233411', label: 'Screen saver software' },
  { value: '43232502', label: 'Computer based training software' },
  { value: '43232600', label: 'Industry specific software' },
  { value: '43232611', label: 'Point of sale POS software' },
  { value: '43232607', label: 'Flight control software' },
  { value: '43232601', label: 'Aviation ground support software' },
  { value: '43232602', label: 'Aviation test software' },
  { value: '44000000', label: 'Office Equipment and Accessories and Supplies' },
  {
    value: '44100000',
    label: 'Office machines and their supplies and accessories'
  },
  { value: '44101500', label: 'Duplicating machines' },
  { value: '44101501', label: 'Photocopiers' },
  { value: '44101600', label: 'Paper processing machines and accessories' },
  { value: '44101601', label: 'Paper cutting machines or accessories' },
  { value: '44101602', label: 'Paper punching or binding machines' },
  {
    value: '44101700',
    label: 'Printer and photocopier and facsimile accessories'
  },
  { value: '44101701', label: 'Color options or upgrades' },
  { value: '44101702', label: 'Duplexer trays' },
  { value: '44101703', label: 'Duplexer units' },
  { value: '44101704', label: 'Facsimile handsets' },
  { value: '44101800', label: 'Calculating machines and accessories' },
  { value: '44101802', label: 'Adding machines' },
  { value: '44101803', label: 'Accounting machines' },
  { value: '44101900', label: 'Check endorsing and writing machines' },
  { value: '44101901', label: 'Check endorsing machines' },
  { value: '44101902', label: 'Check writing machines' },
  { value: '44102000', label: 'Laminating supplies' },
  { value: '44102001', label: 'Lamination film' },
  { value: '44102100', label: 'Mail machines' },
  { value: '44102101', label: 'Franking or postage machines' },
  { value: '44102102', label: 'Mail opening machines' },
  { value: '44102103', label: 'Mail sealing machines' },
  { value: '44102104', label: 'Stamp canceling machines' },
  { value: '44102105', label: 'Addressing machines' },
  { value: '44102200', label: 'Scanner accessories' },
  { value: '44102201', label: 'Endorsers' },
  { value: '44102300', label: 'Packing machines' },
  { value: '44102301', label: 'Bundling machines' },
  { value: '44102302', label: 'Parcel wrapping machines' },
  { value: '44102303', label: 'Seal presses' },
  { value: '44102304', label: 'Sealing machines' },
  { value: '44102305', label: 'Strapping tensioners or sealers' },
  { value: '44102306', label: 'Tying machines' },
  { value: '44102400', label: 'Labeling machines' },
  { value: '44102402', label: 'Dating or numbering machines' },
  { value: '44102403', label: 'Identification ID press machines' },
  { value: '44102404', label: 'Label applying machines' },
  { value: '44102405', label: 'Label making machines' },
  { value: '44102406', label: 'Lettering equipment' },
  { value: '44102407', label: 'Tape embosser' },
  { value: '44102408', label: 'Automatic labeling systems' },
  { value: '44102409', label: 'Semi automatic labeling systems' },
  { value: '44102411', label: 'Label dispensers' },
  { value: '44102500', label: 'Sorting machines' },
  { value: '44102501', label: 'Money counting machines' },
  { value: '44102502', label: 'Sorters' },
  { value: '44102600', label: 'Typing machines and accessories' },
  { value: '44102602', label: 'Typewriters' },
  { value: '44102603', label: 'Printwheels' },
  { value: '44102604', label: 'Stenotype machines' },
  { value: '44102800', label: 'Binding and lamination machines' },
  { value: '44102801', label: 'Laminators' },
  { value: '44102900', label: 'Office machine accessories' },
  { value: '44102901', label: 'Travel kits for office machines' },
  { value: '44102902', label: 'Storage accessories for office machines' },
  { value: '44102903', label: 'Tape cleaners' },
  { value: '44103000', label: 'Fusers and accessories' },
  { value: '44103001', label: 'Fuser cleaning pads or filters' },
  { value: '44103002', label: 'Fuser oil' },
  { value: '44103003', label: 'Fuser wiper' },
  {
    value: '44103100',
    label: 'Printer and facsimile and photocopier supplies'
  },
  { value: '44103101', label: 'Printer or facsimile or photocopier belts' },
  { value: '44103103', label: 'Printer or facsimile toner' },
  { value: '44103104', label: 'Transfer rolls' },
  { value: '44103105', label: 'Ink cartridges' },
  { value: '44103106', label: 'Ink sticks' },
  {
    value: '44103107',
    label: 'Printer or facsimile or photocopier cleaning supplies'
  },
  { value: '44103108', label: 'Developer for printers or photocopiers' },
  { value: '44110000', label: 'Office and desk accessories' },
  { value: '44111500', label: 'Organizers and accessories' },
  { value: '44111501', label: 'Message holders or dispensers' },
  { value: '44111502', label: 'Desk drawer organizers' },
  { value: '44111503', label: 'Desktop trays or organizers' },
  { value: '44111600', label: 'Cash handling supplies' },
  { value: '44111601', label: 'Currency bags or wallets' },
  { value: '44111609', label: 'Counterfeit bill detectors or supplies' },
  { value: '44111603', label: 'Coin sorters' },
  { value: '44111604', label: 'Coin wrappers or bill straps' },
  { value: '44111605', label: 'Cash or ticket boxes' },
  { value: '44111607', label: 'Check files' },
  { value: '44122000', label: 'Folders and binders and indexes' },
  { value: '44122001', label: 'Index card files' },
  { value: '44122002', label: 'Sheet protectors' },
  { value: '44122003', label: 'Binders' },
  { value: '44122005', label: 'Magazine or book covers' },
  { value: '44122008', label: 'Tab indexes' },
  { value: '44122009', label: 'Rotary or business card files' },
  { value: '44122010', label: 'Dividers' },
  { value: '44122011', label: 'Folders' },
  { value: '44111800', label: 'Drafting supplies' },
  { value: '44111801', label: 'Stencils or lettering aids' },
  { value: '44111802', label: 'Drafting films' },
  { value: '44111803', label: 'Compasses' },
  { value: '44111804', label: 'Drafting papers' },
  { value: '44111805', label: 'Curves' },
  { value: '44111806', label: 'Protractors' },
  { value: '44111807', label: 'Scales' },
  { value: '44111808', label: 'T squares' },
  { value: '44111809', label: 'Templates' },
  { value: '44111810', label: 'Triangles' },
  { value: '44111900', label: 'Boards' },
  { value: '44111901', label: 'Planning boards or accessories' },
  { value: '44111902', label: 'Electronic copyboards or accessories' },
  { value: '44111903', label: 'Easels or accessories' },
  { value: '44111904', label: 'Letter boards or accessories' },
  { value: '44111905', label: 'Dry erase boards or accessories' },
  { value: '44111906', label: 'Chalk boards or accessories' },
  { value: '44111907', label: 'Bulletin boards or accessories' },
  { value: '44111908', label: 'Magnetic boards or accessories' },
  { value: '44112000', label: 'Planning systems' },
  { value: '44112001', label: 'Address books or refills' },
  { value: '44112002', label: 'Calendars' },
  { value: '44112004', label: 'Meeting planners' },
  { value: '44112005', label: 'Appointment books or refills' },
  { value: '44120000', label: 'Office supplies' },
  { value: '44121500', label: 'Mailing supplies' },
  { value: '44121501', label: 'Mailer tubes' },
  { value: '44121503', label: 'Mailers' },
  { value: '44121504', label: 'Window envelopes' },
  { value: '44121505', label: 'Specialty envelopes' },
  { value: '44121506', label: 'Standard envelopes' },
  { value: '44121600', label: 'Desk supplies' },
  { value: '44122012', label: 'Clipboards' },
  { value: '44122101', label: 'Rubber bands' },
  { value: '44122118', label: 'Prong fasteners' },
  { value: '44121604', label: 'Stamps' },
  { value: '44121605', label: 'Tape dispensers' },
  { value: '44122103', label: 'Clasp fasteners' },
  { value: '44122104', label: 'Paper clips' },
  { value: '44122105', label: 'Binder or bulldog clips' },
  { value: '44122106', label: 'Pins or tacks' },
  { value: '44121611', label: 'Paper or eyelet punches' },
  { value: '44121612', label: 'Paper cutters or refills' },
  { value: '44121613', label: 'Staple removers' },
  { value: '44121614', label: 'Call bells' },
  { value: '44121615', label: 'Staplers' },
  { value: '44122107', label: 'Staples' },
  { value: '44121617', label: 'Manual letter openers' },
  { value: '44121618', label: 'Scissors' },
  { value: '44121700', label: 'Writing instruments' },
  { value: '44121701', label: 'Rollerball pens' },
  { value: '44121702', label: 'Pen or pencil sets' },
  { value: '44121703', label: 'Fountain pens' },
  { value: '44121704', label: 'Ball point pens' },
  { value: '44121705', label: 'Mechanical pencils' },
  { value: '44121706', label: 'Wooden pencils' },
  { value: '44121707', label: 'Colored pencils' },
  { value: '44121708', label: 'Markers' },
  { value: '44121709', label: 'Crayons' },
  { value: '44121710', label: 'Writing chalk or accessories' },
  { value: '44121800', label: 'Correction media' },
  { value: '44121801', label: 'Correction film or tape' },
  { value: '44121802', label: 'Correction fluid' },
  { value: '44121619', label: 'Manual pencil sharpener' },
  { value: '44121900', label: 'Ink and lead refills' },
  { value: '44121902', label: 'Lead refills' },
  {
    value: '45000000',
    label:
      'Printing and Photographic and Audio and Visual Equipment and Supplies'
  },
  { value: '45100000', label: 'Printing and publishing equipment' },
  { value: '45101500', label: 'Printing machinery and equipment' },
  { value: '45101501', label: 'Heliographic printers' },
  { value: '45101502', label: 'Offset printing presses' },
  { value: '45101503', label: 'Letterpress equipment' },
  { value: '45101504', label: 'Lithographic equipment' },
  { value: '45101505', label: 'Photogravure printing machines' },
  { value: '45101506', label: 'Silk screen printing machines' },
  { value: '45101507', label: 'Printing presses' },
  { value: '45101508', label: 'Perforating machines' },
  { value: '45101600', label: 'Printing machinery accessories' },
  { value: '45121513', label: 'Offset cameras' },
  { value: '45101602', label: 'Offset darkroom equipment' },
  { value: '45101603', label: 'Offset printing consumables' },
  { value: '45101604', label: 'Offset printing plate processors' },
  { value: '45101606', label: 'Offset film processors' },
  { value: '45101607', label: 'Silk screen arc lamps' },
  { value: '45101608', label: 'Silk screen screens' },
  { value: '45101609', label: 'Silk screen printing racks' },
  { value: '45101610', label: 'Silk screen vacuum printing frames' },
  { value: '45101611', label: 'Silk screen squeegees' },
  { value: '45101700', label: 'Printing accessories' },
  { value: '45101701', label: 'Printing assemblers' },
  { value: '45101702', label: 'Printing guillotines' },
  { value: '45101703', label: 'Printing collators or decollators' },
  { value: '45101704', label: 'Printing cutters' },
  { value: '45101705', label: 'Printing trimmers' },
  { value: '45101706', label: 'Printing punches' },
  { value: '45101707', label: 'Printing plates' },
  { value: '45101708', label: 'Printing awls' },
  {
    value: '45101800',
    label: 'Book binding and sewing equipment and accessories'
  },
  { value: '45101801', label: 'Book creasing machines' },
  { value: '45101802', label: 'Book cutting machines' },
  { value: '45101803', label: 'Book punching machines' },
  { value: '45101804', label: 'Book stitching machines' },
  { value: '45101805', label: 'Book jogging machines' },
  { value: '45101806', label: 'Book gathering machines' },
  { value: '45101807', label: 'Book folding machines' },
  { value: '45101900', label: 'Printing laboratory equipment and accessories' },
  { value: '45101901', label: 'Banding machines' },
  { value: '45101902', label: 'Platemakers' },
  { value: '45101903', label: 'Paper drilling machines' },
  { value: '45101904', label: 'Enlargers' },
  { value: '45101905', label: 'Drawing or retouching boards' },
  { value: '45121514', label: 'Photocopier cameras' },
  { value: '45102000', label: 'Composing machines and accessories' },
  { value: '45102001', label: 'Intertype composing machines' },
  { value: '45102002', label: 'Linotype composing machines' },
  { value: '45102003', label: 'Photocomposition materials' },
  { value: '45102004', label: 'Monotype composing machines' },
  { value: '45102005', label: 'Phototypesetting machines' },
  {
    value: '45110000',
    label: 'Audio and visual presentation and composing equipment'
  },
  { value: '45111500', label: 'Lecterns and sound systems and accessories' },
  { value: '45111501', label: 'Free standing lecterns' },
  { value: '45111502', label: 'Table top lecterns' },
  { value: '45111600', label: 'Projectors and supplies' },
  { value: '45111601', label: 'Pointers' },
  { value: '45111602', label: 'Projection lamps' },
  { value: '45111603', label: 'Projection screens or displays' },
  { value: '45111604', label: 'Slide projectors' },
  { value: '45111605', label: 'Transparency equipment or supplies' },
  { value: '45111606', label: 'Liquid crystal display projection panels' },
  { value: '45111607', label: 'Overhead projectors' },
  { value: '45111608', label: 'Film projectors' },
  { value: '45111609', label: 'Multimedia projectors' },
  { value: '45111610', label: 'Epidiascopes' },
  { value: '45131604', label: 'Blank video tapes' },
  { value: '45120000', label: 'Photographic or filming or video equipment' },
  { value: '45121500', label: 'Cameras' },
  { value: '45121501', label: 'Still cameras' },
  { value: '45121502', label: 'Instant print cameras' },
  { value: '45121503', label: 'Disposable cameras' },
  { value: '45121504', label: 'Digital cameras' },
  { value: '45121505', label: 'Cinematographic cameras' },
  { value: '45121801', label: 'Microfilm cameras' },
  { value: '45121510', label: 'Aerial cameras' },
  { value: '45121511', label: 'High speed cameras' },
  { value: '45121512', label: 'Underwater cameras' },
  { value: '45121600', label: 'Camera accessories' },
  { value: '45121601', label: 'Camera flashes or lighting' },
  { value: '45121602', label: 'Camera tripods' },
  { value: '45121603', label: 'Camera lens' },
  { value: '45121700', label: 'Photographic processing equipment' },
  { value: '45121701', label: 'Film driers' },
  { value: '45121702', label: 'Film washers' },
  { value: '45121703', label: 'Film splicers' },
  { value: '45121704', label: 'Film editors' },
  { value: '45121705', label: 'Photographic enlargers' },
  { value: '45130000', label: 'Photographic and recording media' },
  { value: '45131500', label: 'Still picture film' },
  { value: '45131501', label: 'Color film' },
  { value: '45131502', label: 'Black and white film' },
  { value: '45131503', label: 'Instant picture film' },
  { value: '45131600', label: 'Moving picture media' },
  { value: '45131601', label: 'Motion picture camera film' },
  { value: '45131505', label: 'X ray film' },
  { value: '45140000', label: 'Photographic filmmaking supplies' },
  { value: '45141500', label: 'Photographic processing chemicals' },
  { value: '45141501', label: 'Developer solution' },
  { value: '45141502', label: 'Fixative' },
  { value: '45141600', label: 'Darkroom supplies' },
  { value: '45141601', label: 'Developing trays' },
  { value: '45141602', label: 'Developing tanks' },
  { value: '45141603', label: 'Developing tongs' },
  {
    value: '46000000',
    label:
      'Defense and Law Enforcement and Security and Safety Equipment and Supplies'
  },
  { value: '46100000', label: 'Light weapons and ammunition' },
  { value: '46101500', label: 'Firearms' },
  { value: '46101504', label: 'Handguns' },
  { value: '46101505', label: 'Air rifles or air handguns' },
  { value: '46101600', label: 'Ammunition' },
  { value: '46101700', label: 'Ammunition handling systems' },
  { value: '46110000', label: 'Conventional war weapons' },
  { value: '46111500', label: 'Bombs and grenades' },
  { value: '46120000', label: 'Missiles' },
  { value: '46130000', label: 'Rockets and subsystems' },
  { value: '46140000', label: 'Launchers' },
  { value: '46150000', label: 'Law enforcement' },
  { value: '46151500', label: 'Crowd control equipment' },
  { value: '46151501', label: 'Barricades' },
  { value: '46151502', label: 'Riot helmets' },
  { value: '46151503', label: 'Riot shields' },
  { value: '46151504', label: 'Body armour' },
  { value: '46151505', label: 'Barriers' },
  { value: '46151600', label: 'Security and control equipment' },
  { value: '46151601', label: 'Handcuffs' },
  { value: '46151602', label: 'Night sticks' },
  { value: '46151715', label: 'Fingerprint equipment' },
  { value: '46151604', label: 'Alcohol analysers' },
  { value: '46151605', label: 'Weapon or explosives detectors and supplies' },
  { value: '46151606', label: 'Narcotic test kits' },
  { value: '46160000', label: 'Public safety and control' },
  { value: '46161500', label: 'Traffic control' },
  { value: '46161501', label: 'Airport signaling systems' },
  { value: '46161502', label: 'Railway signaling systems' },
  { value: '46161503', label: 'Marine signaling systems' },
  { value: '46161504', label: 'Traffic signals' },
  { value: '46170000', label: 'Security surveillance and detection' },
  { value: '46171500', label: 'Locks and security hardware and accessories' },
  { value: '46171600', label: 'Surveillance and detection equipment' },
  { value: '46191501', label: 'Smoke detectors' },
  { value: '46171602', label: 'Safety horns' },
  { value: '46171603', label: 'Clock timers' },
  { value: '46171604', label: 'Alarm systems' },
  { value: '46171605', label: 'Door chimes' },
  { value: '46171606', label: 'Sirens' },
  { value: '46171607', label: 'Buzzers' },
  { value: '46171608', label: 'Motion detectors' },
  { value: '46171609', label: 'Convex security mirrors' },
  { value: '46171610', label: 'Security cameras' },
  { value: '46171611', label: 'Video identification systems' },
  { value: '46171612', label: 'Video monitors' },
  { value: '46171613', label: 'Gas detectors' },
  { value: '46171615', label: 'Light enhancing cameras or vision devices' },
  { value: '46180000', label: 'Personal safety and protection' },
  { value: '46181500', label: 'Safety apparel' },
  { value: '46181501', label: 'Protective aprons' },
  { value: '46181502', label: 'Bullet proof vests' },
  { value: '46181503', label: 'Protective coveralls' },
  { value: '46181504', label: 'Protective gloves' },
  { value: '46181506', label: 'Protective ponchos' },
  { value: '46181507', label: 'Safety vests' },
  { value: '46181508', label: 'Fire retardant apparel' },
  { value: '46181509', label: 'Hazardous material protective apparel' },
  { value: '46181512', label: 'Cleanroom apparel' },
  { value: '46182306', label: 'Safety harnesses or belts' },
  { value: '46181600', label: 'Safety footwear' },
  { value: '46181601', label: 'Fire retardant footwear' },
  { value: '46181602', label: 'Hazardous material protective footwear' },
  { value: '46181603', label: 'Cleanroom footwear' },
  { value: '46181604', label: 'Safety boots' },
  { value: '46181605', label: 'Safety shoes' },
  { value: '46181700', label: 'Face and head protection' },
  { value: '46181701', label: 'Hard hats' },
  { value: '46181702', label: 'Facial shields' },
  { value: '46181704', label: 'Safety helmets' },
  { value: '46181705', label: 'Motorcycle helmets' },
  { value: '46181800', label: 'Vision protection and accessories' },
  { value: '46181801', label: 'Eyewear holders or cases' },
  { value: '46181804', label: 'Goggles' },
  { value: '46181900', label: 'Hearing protectors' },
  { value: '42143523', label: 'Ear plugs' },
  { value: '46181902', label: 'Ear muffs' },
  { value: '46182000', label: 'Respiratory protection' },
  { value: '46182001', label: 'Masks or accessories' },
  { value: '46182002', label: 'Respirators' },
  { value: '46182003', label: 'Gas masks' },
  { value: '46182100', label: 'Anti static equipment and supplies' },
  { value: '46182101', label: 'Antistatic wrist straps' },
  { value: '46182200', label: 'Ergonomic support aids' },
  { value: '47000000', label: 'Cleaning Equipment and Supplies' },
  {
    value: '47100000',
    label: 'Water and wastewater treatment supply and disposal'
  },
  { value: '47101500', label: 'Water treatment and supply equipment' },
  { value: '47101501', label: 'Activated carbon equipment' },
  { value: '47101502', label: 'Ammonia removal equipment' },
  { value: '47101503', label: 'Carbon filtration equipment' },
  { value: '47101504', label: 'Bacterial removal equipment' },
  { value: '47101506', label: 'Corrosion control equipment' },
  { value: '47101507', label: 'Grit chambers' },
  { value: '47101508', label: 'Desalination equipment' },
  { value: '47101510', label: 'Iron removal equipment' },
  { value: '47101512', label: 'Mixers or agitators' },
  { value: '47101513', label: 'Oxygen generators' },
  { value: '47101514', label: 'Water purification equipment' },
  { value: '47101517', label: 'Ultraviolet disinfection equipment' },
  { value: '47101521', label: 'Ultrafiltration equipment' },
  { value: '47101522', label: 'Packaged water treatment systems' },
  { value: '47101523', label: 'Collection tanks' },
  { value: '47101524', label: 'Sludge or sewage composting equipment' },
  { value: '47101525', label: 'Dewatering equipment' },
  { value: '47101528', label: 'Water treatment dryers' },
  { value: '47101529', label: 'Incinerators' },
  { value: '47101530', label: 'Odor control equipment' },
  { value: '47101531', label: 'Septic tanks' },
  { value: '47101532', label: 'Settling tanks' },
  { value: '47101534', label: 'Sewage distributors' },
  { value: '47101535', label: 'Sludge disposal equipment' },
  { value: '47101536', label: 'Sludge collectors' },
  { value: '47101537', label: 'Sludge conditioning equipment' },
  { value: '47101538', label: 'Sludge or sewage digesters' },
  { value: '47101600', label: 'Water treatment consumables' },
  { value: '47101601', label: 'Algaecides' },
  { value: '47101605', label: 'Bacterial removal chemicals' },
  { value: '47101606', label: 'Corrosion control chemicals' },
  { value: '47101609', label: 'Microbiocides' },
  { value: '47101611', label: 'Demulsifiers' },
  { value: '47110000', label: 'Industrial laundry and dry cleaning equipment' },
  { value: '47111500', label: 'Washing and drying equipment' },
  {
    value: '47111501',
    label: 'Laundry type combined washing or drying machines'
  },
  { value: '47111502', label: 'Laundry type washing machines' },
  { value: '47111503', label: 'Clothes dryers' },
  { value: '47111600', label: 'Ironing equipment' },
  { value: '47111700', label: 'Dry cleaning equipment' },
  { value: '47120000', label: 'Janitorial equipment' },
  { value: '47121500', label: 'Cleaning and janitorial carts and accessories' },
  { value: '47121501', label: 'Cleaning or janitorial cart' },
  { value: '47121502', label: 'Cleaning or janitorial cart accessories' },
  { value: '47121600', label: 'Floor machines and accessories' },
  { value: '47121700', label: 'Waste containers and accessories' },
  { value: '47121701', label: 'Trash bags' },
  { value: '47121702', label: 'Waste containers or rigid liners' },
  { value: '47121703', label: 'Smoking urns or accessories' },
  { value: '47121800', label: 'Cleaning equipment' },
  { value: '47121801', label: 'Cleaning dusters' },
  { value: '47121802', label: 'Lint removers' },
  { value: '47121803', label: 'Squeegees or washers' },
  { value: '47121804', label: 'Cleaning pails or buckets' },
  { value: '47130000', label: 'Cleaning and janitorial supplies' },
  { value: '47131500', label: 'Cleaning rags and cloths and wipes' },
  { value: '47131501', label: 'Rags' },
  { value: '47131502', label: 'Cleaning cloths or wipes' },
  { value: '47131600', label: 'Brooms and mops and brushes and accessories' },
  { value: '47131601', label: 'Dust brushes or pans' },
  { value: '47131602', label: 'Scouring pads' },
  { value: '47131603', label: 'Sponges' },
  { value: '47131604', label: 'Brooms' },
  { value: '47131605', label: 'Cleaning brushes' },
  { value: '47131617', label: 'Dust mops' },
  { value: '47131618', label: 'Wet mops' },
  { value: '47131608', label: 'Toilet brushes' },
  { value: '47131700', label: 'Restroom supplies' },
  { value: '47131701', label: 'Paper towel dispensers' },
  { value: '47131702', label: 'Sanitary goods dispensers' },
  { value: '47131703', label: 'Sanitary waste receptacles' },
  { value: '47131704', label: 'Institutional soap or lotion dispensers' },
  { value: '47131800', label: 'Cleaning and disinfecting solutions' },
  { value: '47131801', label: 'Floor cleaners' },
  { value: '47131803', label: 'Household disinfectants' },
  { value: '47131804', label: 'Ammonia cleaners' },
  {
    value: '48000000',
    label: 'Service Industry Machinery and Equipment and Supplies'
  },
  { value: '48100000', label: 'Institutional food services equipment' },
  { value: '48101500', label: 'Cooking and warming equipment' },
  { value: '48101501', label: 'Commercial use bain maries' },
  { value: '48101502', label: 'Commercial use barbeque ovens' },
  { value: '48101503', label: 'Commercial use broilers' },
  { value: '48101504', label: 'Commercial use charcoal grills' },
  { value: '48101505', label: 'Commercial use coffee or iced tea makers' },
  { value: '48101506', label: 'Commercial use coffee warmers' },
  { value: '48101507', label: 'Commercial use convection ovens' },
  { value: '48101508', label: 'Commercial use conveyer toasters' },
  { value: '48101509', label: 'Commercial use deep fryers' },
  { value: '48101510', label: 'Commercial use food warmers' },
  { value: '48101511', label: 'Commercial use griddles' },
  { value: '48101512', label: 'Commercial use grills' },
  { value: '48101513', label: 'Commercial use heat lamps' },
  { value: '48101514', label: 'Commercial use high pressure steamers' },
  { value: '48101515', label: 'Commercial use hot dog grills' },
  { value: '48101516', label: 'Commercial use microwave ovens' },
  { value: '48101517', label: 'Commercial use ovens' },
  { value: '48101518', label: 'Commercial use pasta cookers' },
  { value: '48101519', label: 'Commercial use pizza ovens' },
  { value: '48101520', label: 'Commercial use popcorn machines' },
  { value: '48101521', label: 'Commercial use ranges' },
  { value: '48101522', label: 'Commercial use rotisseries' },
  { value: '48101523', label: 'Commercial use smokers or smoke ovens' },
  { value: '48101524', label: 'Commercial use steamers' },
  { value: '48101525', label: 'Commercial use toasters' },
  { value: '48101526', label: 'Commercial use waffle irons' },
  { value: '48101600', label: 'Food preparation equipment' },
  { value: '48101601', label: 'Commercial use blenders' },
  { value: '48101602', label: 'Commercial use electric can openers' },
  {
    value: '48101603',
    label: 'Commercial use food choppers or cubers or dicers'
  },
  { value: '48101604', label: 'Commercial use coffee grinders' },
  { value: '48101605', label: 'Commercial use food grinders' },
  { value: '48101606', label: 'Commercial use graters' },
  { value: '48101607', label: 'Commercial use juicers' },
  { value: '48101608', label: 'Commercial use mixers' },
  { value: '48101609', label: 'Commercial use pasta machines' },
  { value: '48101610', label: 'Commercial use peelers' },
  { value: '48101611', label: 'Commercial use scales' },
  { value: '48101612', label: 'Commercial use food processors' },
  { value: '48101700', label: 'Food and beverage dispensing equipment' },
  { value: '48101701', label: 'Carbonated beverage dispenser' },
  { value: '48101702', label: 'Non carbonated beverage dispenser' },
  { value: '48101703', label: 'Milk dispensers' },
  { value: '48101704', label: 'Syrup pumps' },
  { value: '48101705', label: 'Cappuccino or espresso machines' },
  { value: '48101706', label: 'Milkshake machines' },
  { value: '48101707', label: 'Soft serve machines' },
  { value: '48101708', label: 'Slush machines' },
  { value: '48101709', label: 'Ice dispensers' },
  { value: '48101800', label: 'Cookware and kitchen tools' },
  { value: '48101801', label: 'Commercial use cutlery' },
  { value: '48101802', label: 'Commercial use molds' },
  { value: '48101803', label: 'Commercial use scoops' },
  { value: '48101804', label: 'Commercial use measuring cups' },
  { value: '48101805', label: 'Commercial use mixing bowls' },
  { value: '48101806', label: 'Commercial use cake or pie pans' },
  { value: '48101807', label: 'Commercial use pizza pans' },
  { value: '48101808', label: 'Commercial use sauce or saute pans' },
  { value: '48101809', label: 'Commercial use stock or sauce pots' },
  { value: '48101810', label: 'Commercial use pot or pan covers' },
  { value: '48101811', label: 'Commercial use rolling pins' },
  { value: '48101812', label: 'Commercial use strainers' },
  { value: '48101813', label: 'Commercial use whisks' },
  { value: '48101814', label: 'Commercial use woks' },
  { value: '48101815', label: 'Commercial use ladles' },
  { value: '48101900', label: 'Tabletop and serving equipment' },
  { value: '48101901', label: 'Food service dinnerware' },
  { value: '48101902', label: 'Food service flatware' },
  { value: '48101903', label: 'Food service glasses' },
  { value: '48101904', label: 'Food service stemware' },
  { value: '48101905', label: 'Food service cups or mugs' },
  { value: '48101906', label: 'Food service serving baskets' },
  { value: '48101907', label: 'Food service pitchers' },
  { value: '48101908', label: 'Food service chafers' },
  { value: '48101909', label: 'Food service coffee or tea pots' },
  { value: '48101910', label: 'Food service soup crocks' },
  { value: '48101911', label: 'Food service ice buckets or wine coolers' },
  { value: '48101912', label: 'Food service condiment dispensers' },
  { value: '48101913', label: 'Food service punch bowls' },
  { value: '48101914', label: 'Food service wine carafes' },
  { value: '48101915', label: 'Food service trays' },
  { value: '48102000', label: 'Restaurant furniture' },
  { value: '48102001', label: 'Restaurant chairs' },
  { value: '48102002', label: 'Booths' },
  { value: '48102003', label: 'Salad bars' },
  { value: '48102004', label: 'Tabletops' },
  { value: '48102005', label: 'Bar stools' },
  { value: '48102006', label: 'Permanent bars' },
  { value: '48102007', label: 'Portable bars' },
  { value: '48102100', label: 'Storage and handling equipment and supplies' },
  { value: '48102101', label: 'Display cases' },
  { value: '48102102', label: 'Heated display cases' },
  { value: '48102103', label: 'Refrigerated display cases' },
  { value: '48102104', label: 'Ice cream display cases' },
  {
    value: '49000000',
    label: 'Sports and Recreational Equipment and Supplies and Accessories'
  },
  { value: '49100000', label: 'Collectibles and awards' },
  { value: '49101600', label: 'Collectibles' },
  { value: '49101602', label: 'Souvenirs' },
  { value: '49101700', label: 'Awards' },
  { value: '49101701', label: 'Medals' },
  { value: '49101702', label: 'Trophies' },
  { value: '60130000', label: 'Musical Instruments and parts and accessories' },
  { value: '60131000', label: 'Keyboard instruments' },
  { value: '60131003', label: 'Musical organs' },
  { value: '60131001', label: 'Pianos' },
  { value: '60131100', label: 'Brass instruments' },
  { value: '60131101', label: 'Trumpets' },
  { value: '60131102', label: 'Trombones' },
  { value: '60131104', label: 'Saxophones' },
  { value: '60131200', label: 'Woodwind instruments' },
  { value: '60131201', label: 'Clarinets' },
  { value: '60131202', label: 'Oboes' },
  { value: '60131203', label: 'Musical flutes' },
  { value: '60131204', label: 'Piccoloes' },
  { value: '60131300', label: 'String instruments' },
  { value: '60131301', label: 'Harpsichords' },
  { value: '60131302', label: 'Clavichords' },
  { value: '60131303', label: 'Guitars' },
  { value: '60131304', label: 'Violins' },
  { value: '60131305', label: 'Harps' },
  { value: '60131306', label: 'Banjoes' },
  { value: '60131400', label: 'Percussion instruments' },
  { value: '60131401', label: 'Cymbals' },
  { value: '60131402', label: 'Bells' },
  { value: '60131403', label: 'Tambourines' },
  { value: '60131404', label: 'Castanets' },
  { value: '60131405', label: 'Drums' },
  { value: '60131406', label: 'Xylophones' },
  { value: '60131500', label: 'Musical instrument parts and accessories' },
  { value: '60131501', label: 'Metronomes' },
  { value: '60131503', label: 'Instrument strings or picks' },
  { value: '49120000', label: 'Camping and outdoor equipment and accessories' },
  { value: '49121500', label: 'Camping and outdoor equipment' },
  { value: '24141506', label: 'Tarpaulins' },
  { value: '49121502', label: 'Sleeping pads' },
  { value: '49121503', label: 'Tents' },
  { value: '49121504', label: 'Sleeping bags' },
  { value: '49121505', label: 'Ice chests' },
  { value: '49121506', label: 'Tent repair kits' },
  { value: '49121507', label: 'Pneumatic mattresses' },
  { value: '49121508', label: 'Mosquito nets' },
  { value: '49121509', label: 'Camping or outdoor stoves' },
  { value: '49121600', label: 'Camping furniture' },
  { value: '49121601', label: 'Camping chairs or stools' },
  { value: '49121602', label: 'Camping tables' },
  { value: '49121603', label: 'Camping cots' },
  { value: '49130000', label: 'Fishing and hunting equipment' },
  { value: '49131500', label: 'Fishing tackle' },
  { value: '49131501', label: 'Fishing rods' },
  { value: '49140000', label: 'Watersports equipment' },
  { value: '49141500', label: 'Scuba and snorkeling gear' },
  { value: '49141501', label: 'Bouyancy compensators' },
  { value: '49141502', label: 'Scuba tanks' },
  { value: '49141503', label: 'Scuba regulators' },
  { value: '49141504', label: 'Diving instruments or accessories' },
  { value: '49141505', label: 'Masks or fins or snorkels' },
  { value: '49141506', label: 'Wetsuits' },
  { value: '49141507', label: 'Drysuits' },
  { value: '49150000', label: 'Winter sports equipment' },
  { value: '49151500', label: 'Skiing and snowboarding equipment' },
  { value: '49151501', label: 'Ski boots' },
  { value: '49151502', label: 'Skis' },
  { value: '49160000', label: 'Field and court sports equipment' },
  { value: '49161500', label: 'Field sports equipment' },
  { value: '49161501', label: 'Football blocking sleds' },
  { value: '49161503', label: 'Baseballs' },
  { value: '49161504', label: 'Footballs' },
  { value: '49161505', label: 'Soccer balls' },
  { value: '49161506', label: 'Baseball bats' },
  { value: '49161509', label: 'Softballs' },
  { value: '49161600', label: 'Racquet and court sports equipment' },
  { value: '49161601', label: 'Racquetball rackets' },
  { value: '49161602', label: 'Badminton rackets' },
  { value: '49161603', label: 'Basketballs' },
  { value: '49161604', label: 'Tennis balls' },
  { value: '49161607', label: 'Tennis racquets' },
  { value: '49161608', label: 'Volleyballs' },
  { value: '49161700', label: 'Track sports equipment' },
  { value: '49161702', label: 'Jumping bars' },
  { value: '49170000', label: 'Gymnastics and boxing equipment' },
  { value: '49171500', label: 'Gymnastics equipment' },
  { value: '49171501', label: 'Gymnastic bars or beams' },
  { value: '49171503', label: 'Gymnastic vaulting equipment' },
  { value: '49171600', label: 'Boxing equipment' },
  { value: '49171601', label: 'Boxing rings' },
  { value: '49180000', label: 'Target and table games and equipment' },
  { value: '49181500', label: 'Table games and equipment' },
  { value: '49181501', label: 'Billiard tables' },
  { value: '49181507', label: 'Tennis tables' },
  { value: '49200000', label: 'Fitness equipment' },
  { value: '49201500', label: 'Aerobic training equipment' },
  { value: '49201501', label: 'Treadmills' },
  { value: '49201503', label: 'Stationary bicycles' },
  { value: '49201504', label: 'Rowing machines' },
  { value: '49201601', label: 'Dumbbells' },
  { value: '49201512', label: 'Jump ropes' },
  { value: '49210000', label: 'Other sports' },
  { value: '49211600', label: 'Golf equipment' },
  { value: '49220000', label: 'Sports equipment and accessories' },
  { value: '49221500', label: 'Sport accessories' },
  { value: '49221501', label: 'Sport scoreboards' },
  { value: '49221502', label: 'Sport goals' },
  { value: '49221505', label: 'Sport nets or netting' },
  { value: '60140000', label: 'Toys and games' },
  { value: '60141000', label: 'Toys' },
  { value: '60141100', label: 'Games' },
  { value: '60141102', label: 'Board games' },
  {
    value: '49240000',
    label:
      'Recreation and playground and swimming and spa equipment and supplies'
  },
  { value: '49241500', label: 'Playground equipment' },
  { value: '49241501', label: 'Playground swings' },
  { value: '49241503', label: 'Playground merry go rounds' },
  { value: '50000000', label: 'Food Beverage and Tobacco Products' },
  { value: '50100000', label: 'Nuts and seeds' },
  { value: '50101700', label: 'Nuts and seeds' },
  { value: '50110000', label: 'Meat and poultry products' },
  { value: '50111500', label: 'Minimally processed meat and poultry products' },
  { value: '50120000', label: 'Seafood' },
  { value: '50121500', label: 'Fish' },
  { value: '50130000', label: 'Dairy products and eggs' },
  { value: '50131600', label: 'Eggs and egg substitutes' },
  { value: '50150000', label: 'Edible oils and fats' },
  { value: '50151500', label: 'Edible vegetable and plant oils and fats' },
  { value: '50151600', label: 'Edible animal oils and fats' },
  {
    value: '50160000',
    label: 'Chocolate and sugars and sweeteners and confectionary products'
  },
  { value: '50161500', label: 'Chocolate and sugars and sweetening products' },
  { value: '50161800', label: 'Confectionary products' },
  { value: '50170000', label: 'Seasonings and preservatives' },
  { value: '50171500', label: 'Herbs and spices and extracts' },
  { value: '50180000', label: 'Bread and bakery products' },
  { value: '50181700', label: 'Baking mixes and supplies' },
  { value: '50190000', label: 'Prepared and preserved foods' },
  { value: '50191500', label: 'Prepared soups and stews' },
  { value: '50192100', label: 'Snack foods' },
  { value: '50200000', label: 'Beverages' },
  { value: '50202301', label: 'Water' },
  { value: '50201700', label: 'Coffee and tea' },
  { value: '50201709', label: 'Instant coffee' },
  { value: '50201706', label: 'Coffee' },
  { value: '50202306', label: 'Soft drinks' },
  { value: '50210000', label: 'Tobacco and smoking products and substitutes' },
  { value: '51000000', label: 'Drugs and Pharmaceutical Products' },
  {
    value: '51100000',
    label: 'Amebicides and trichomonacides and antiprotozoals'
  },
  { value: '51282004', label: 'Chloramphenicol or chloramphenicolum' },
  { value: '51283002', label: 'Clindamycin' },
  { value: '51284017', label: 'Tetracycline' },
  {
    value: '51101600',
    label: 'Amebicides and Trichomonacides and Antiprotozoals'
  },
  { value: '51101923', label: 'Atovaquone' },
  { value: '51101602', label: 'Eflornithine' },
  { value: '51282808', label: 'Metronidazole' },
  { value: '51450000', label: 'Anthelmintics' },
  { value: '51451801', label: 'Albendazole' },
  { value: '51452401', label: 'Mebendazole' },
  { value: '51453201', label: 'Oxamniquine' },
  { value: '51452701', label: 'Praziquantel' },
  { value: '51452301', label: 'Pyrantel' },
  { value: '51453501', label: 'Thiabendazole or tiabendazole' },
  { value: '51300000', label: 'Antifungal drugs' },
  { value: '51302401', label: 'Amphotericin B' },
  { value: '51301802', label: 'Butenafine hydrochloride' },
  { value: '51302308', label: 'Clotrimazole' },
  { value: '51303402', label: 'Fluconazole' },
  { value: '51303001', label: 'Flucytosine' },
  { value: '51301904', label: 'Griseofulvin' },
  { value: '51303403', label: 'Itraconazole' },
  { value: '51302318', label: 'Ketoconazole' },
  { value: '51302320', label: 'Miconazole' },
  { value: '51302403', label: 'Natamycin' },
  { value: '51302404', label: 'Nystatin' },
  { value: '51302325', label: 'Tioconazole' },
  { value: '51302004', label: 'Tolnaftate' },
  { value: '51101900', label: 'Antiprotozoals' },
  { value: '51101902', label: 'Mefloquine' },
  { value: '51101903', label: 'Primaquine' },
  { value: '51101904', label: 'Quinine' },
  { value: '51281500', label: 'Antitubercular drugs' },
  { value: '51283301', label: 'Cycloserine' },
  { value: '51281501', label: 'Ethambutol' },
  { value: '51281502', label: 'Isoniazid' },
  { value: '51283110', label: 'Clofazimine' },
  { value: '51287004', label: 'Dapsone' },
  { value: '51282901', label: 'Cinoxacin' },
  { value: '51285907', label: 'Methenamine mandelate' },
  { value: '51282951', label: 'Nalidixic acid' },
  { value: '51172201', label: 'Pentosan polysulfate sodium' },
  { value: '51340000', label: 'Antiviral drugs' },
  { value: '51342901', label: 'Didanosine' },
  { value: '51342902', label: 'Famciclovir' },
  { value: '51343101', label: 'Idoxuridine' },
  { value: '51343110', label: 'Lamivudine' },
  { value: '51343002', label: 'Nevirapine' },
  { value: '51342503', label: 'Ribavirin' },
  { value: '51341604', label: 'Rimantadine hydrochloride' },
  { value: '51343203', label: 'Ritonavir' },
  { value: '51342310', label: 'Valacyclovir hydrochloride' },
  { value: '51342904', label: 'Vidarabine' },
  { value: '51343115', label: 'Zalcitabine' },
  { value: '51110000', label: 'Antineoplastic agents' },
  { value: '51112901', label: 'Amifostine' },
  { value: '51112301', label: 'Busulfan' },
  { value: '51112701', label: 'Carboplatin' },
  { value: '51112601', label: 'Carmustine' },
  { value: '51112505', label: 'Chlorambucil' },
  { value: '51112507', label: 'Cyclophosphamide' },
  { value: '51112402', label: 'Dacarbazine' },
  { value: '51112510', label: 'Ifosfamide' },
  { value: '51112513', label: 'Melphalan' },
  { value: '51172324', label: 'Mesna' },
  { value: '51113501', label: 'Pipobroman' },
  { value: '51111600', label: 'Antimetabolites' },
  { value: '51203402', label: 'Cladribine' },
  { value: '51111602', label: 'Cytarabine' },
  { value: '51442001', label: 'Fludarabine' },
  { value: '51442004', label: 'Fluorouracil' },
  { value: '51111606', label: 'Hydroxycarbamide or hydroxyurea' },
  { value: '51203403', label: 'Mercaptopurine' },
  { value: '51111610', label: 'Methotrexate' },
  { value: '51111700', label: 'Antineoplastic antibiotics' },
  { value: '51282001', label: 'Dactinomycin' },
  { value: '51111704', label: 'Mitomycin' },
  { value: '51111800', label: 'Hormones and antihormones' },
  { value: '51111875', label: 'Anastrozole' },
  { value: '51111802', label: 'Bicalutamide' },
  { value: '51111803', label: 'Estramustine' },
  { value: '51182027', label: 'Flutamide' },
  { value: '51111805', label: 'Goserelin' },
  { value: '51182035', label: 'Nilutamide' },
  { value: '51111873', label: 'Tamoxifen' },
  { value: '51111900', label: 'Natural antineoplastic products' },
  { value: '51111901', label: 'Asparaginase' },
  { value: '51101929', label: 'Docetaxel' },
  { value: '51111904', label: 'Paclitaxel' },
  {
    value: '51120000',
    label:
      'Antiarrythmics and antianginals and cardioplegics and drugs for heart failure'
  },
  { value: '51121500', label: 'Antiarrythmic agents' },
  { value: '51124101', label: 'Adenosine' },
  { value: '51124404', label: 'Digoxin' },
  { value: '51191554', label: 'Isosorbide dinitrate' },
  {
    value: '51411605',
    label: 'Glyceryl trinitrate or glyceryltrinitrate or nitroglycerin'
  },
  { value: '51430000', label: 'Antihypertensive drugs' },
  { value: '51432401', label: 'Captopril' },
  { value: '51432805', label: 'Lisinopril' },
  { value: '51433915', label: 'Felodipine' },
  { value: '51431609', label: 'Verapamil' },
  { value: '51431803', label: 'Methyldopa' },
  { value: '51262002', label: 'Carvedilol' },
  {
    value: '51320000',
    label: 'Antihyperlipidemic and hypocholesterolemic agents'
  },
  { value: '51322704', label: 'Simvastatin' },
  { value: '51130000', label: 'Hematolic drugs' },
  { value: '51131500', label: 'Antianemic drugs' },
  { value: '51191917', label: 'Ferrous sulfate' },
  { value: '51131600', label: 'Anticoagulants' },
  { value: '51131602', label: 'Heparin' },
  { value: '51131604', label: 'Warfarin' },
  {
    value: '51131700',
    label: 'Thrombolytic drugs and platelet aggregation inhibitors'
  },
  { value: '51131701', label: 'Abciximab' },
  { value: '51412702', label: 'Alteplase' },
  { value: '51131703', label: 'Streptokinase' },
  { value: '51140000', label: 'Central nervous system drugs' },
  { value: '51141500', label: 'Anticonvulsants' },
  { value: '51141501', label: 'Acetazolamide' },
  { value: '51401516', label: 'Clonazepam' },
  { value: '51141504', label: 'Lamotrigine' },
  { value: '51361918', label: 'Phenobarbital' },
  { value: '51141507', label: 'Phenytoin' },
  { value: '51290000', label: 'Antidepressants' },
  { value: '51292908', label: 'Mirtazapine' },
  { value: '51294513', label: 'Paroxetine hydrochloride' },
  { value: '51330000', label: 'Antipsychotics' },
  { value: '51332501', label: 'Haloperidol' },
  { value: '51401705', label: 'Olanzapine' },
  { value: '51333601', label: 'Risperidone' },
  { value: '51360000', label: 'Hypnotics' },
  { value: '51361501', label: 'Chloral hydrate' },
  { value: '51401550', label: 'Triazolam' },
  {
    value: '51400000',
    label: 'Tranquilizers and antimanic and antianxiety drugs'
  },
  { value: '51331901', label: 'Lithium carbonate' },
  { value: '51381701', label: 'Acetaminophen or paracetamol' },
  { value: '51381505', label: 'Mesalamine or mesalazine' },
  { value: '51380000', label: 'Nonsteroidal anti inflammatory drugs NSAIDs' },
  { value: '51384509', label: 'Ibuprofen' },
  { value: '51384511', label: 'Ketoprofen' },
  { value: '51384514', label: 'Naproxen' },
  { value: '51382702', label: 'Sulindac' },
  { value: '51372108', label: 'Oxycodone' },
  { value: '51142300', label: 'Narcotic antagonists' },
  { value: '51142302', label: 'Naloxone' },
  {
    value: '51142400',
    label: 'Drugs used for vascular and migraine headaches'
  },
  { value: '51142500', label: 'Antiparkinson drugs' },
  { value: '51142501', label: 'Bromocriptine' },
  { value: '51150000', label: 'Autonomic nervous system drugs' },
  {
    value: '51151500',
    label: 'Cholinergic drugs and cholinesterase inhibitors'
  },
  { value: '51151600', label: 'Cholinergic blocking agents' },
  { value: '51151604', label: 'Trihexyphenidyl' },
  { value: '51390000', label: 'Sympathomimetic or adrenergic drugs' },
  { value: '51391703', label: 'Albuterol or salbutamol' },
  { value: '51241131', label: 'Brimonidine tartrate' },
  { value: '51260000', label: 'Adrenergic blocking agents' },
  { value: '51263101', label: 'Atenolol' },
  { value: '51151900', label: 'Centrally acting skeletal muscle relaxants' },
  { value: '51151904', label: 'Methocarbamol' },
  { value: '51152000', label: 'Neuromuscular blocking agents' },
  { value: '51152004', label: 'Vecuronium' },
  { value: '51160000', label: 'Drugs affecting the respiratory tract' },
  { value: '51161500', label: 'Antiasthmatic drugs' },
  { value: '51391602', label: 'Aminophylline' },
  { value: '51393405', label: 'Theophylline' },
  { value: '51310000', label: 'Antihistamines or H1 blockers' },
  { value: '51171820', label: 'Dimenhydrinate' },
  { value: '51312204', label: 'Loratadine' },
  { value: '51161700', label: 'Drugs used for respiratory tract disorders' },
  { value: '51162701', label: 'Acetylcysteine' },
  { value: '51161702', label: 'Beractant' },
  { value: '51421802', label: 'Budesonide' },
  { value: '51161705', label: 'Ipratropium bromide' },
  { value: '51161800', label: 'Cough and cold and antiallergy products' },
  { value: '51162603', label: 'Benzonatate' },
  { value: '51170000', label: 'Drugs affecting the gastrointestinal system' },
  { value: '51171500', label: 'Antacids and antiflatulents' },
  { value: '51171600', label: 'Laxatives' },
  { value: '51171605', label: 'Lactulose' },
  { value: '51171607', label: 'Psyllium hydrophilic muciloid' },
  { value: '51171700', label: 'Antidiarrheals' },
  {
    value: '51171800',
    label: 'Antiemetics and antinauseants and antivertigo agents'
  },
  {
    value: '51171900',
    label: 'Antiulcer and related gastrointestinal GI drugs'
  },
  { value: '51171901', label: 'Cimetidine' },
  { value: '51171905', label: 'Cisapride' },
  { value: '51182214', label: 'Misoprostol' },
  { value: '51171962', label: 'Ursodeoxycholic acid or ursodiol' },
  { value: '51180000', label: 'Hormones and hormone antagonists' },
  { value: '51181500', label: 'Antidiabetic agents and hyperglycemic agents' },
  { value: '51183801', label: 'Acarbose' },
  { value: '51183603', label: 'Insulin' },
  { value: '51181600', label: 'Thyroid and antithyroid drugs' },
  {
    value: '51181604',
    label: 'Thyroid-stimulating hormone or thyrotropin or thyrotropin alfa'
  },
  { value: '51181605', label: 'Methimazole' },
  { value: '51181606', label: 'Propylthiouracil' },
  { value: '51420000', label: 'Corticosteroids' },
  { value: '51422303', label: 'Betamethasone' },
  { value: '51422306', label: 'Dexamethasone' },
  { value: '51422415', label: 'Hydrocortisone' },
  { value: '51422315', label: 'Prednisolone' },
  {
    value: '51350000',
    label: 'Estrogens and progestins and internal contraceptives'
  },
  { value: '51351804', label: 'Estrogens conjugated' },
  { value: '51352006', label: 'Levonorgestrel' },
  { value: '51351511', label: 'Megestrol acetate' },
  {
    value: '51181900',
    label: 'Gonadotropic hormones and ovarian stimulants and inhibitors'
  },
  {
    value: '51181901',
    label: 'Choriogonadotropin alfa or chorionic gonadotropin'
  },
  { value: '51182024', label: 'Danazol' },
  { value: '51182000', label: 'Androgens and androgen inhibitors' },
  { value: '51182002', label: 'Testosterone' },
  { value: '51182100', label: 'Posterior pituitary hormones' },
  { value: '51182101', label: 'Desmopressin' },
  { value: '51182102', label: 'Argipressin or vasopressin' },
  { value: '51182200', label: 'Drugs for inducing labor' },
  { value: '51182201', label: 'Dinoprostone' },
  { value: '51182202', label: 'Methylergometrine or methylergonovine' },
  { value: '51182203', label: 'Oxytocin' },
  { value: '51182300', label: 'Growth hormones and their inhibitors' },
  {
    value: '51182302',
    label: 'Human growth hormone or somatotropin or somatropin'
  },
  { value: '51182400', label: 'Calcium regulators and calcium salts' },
  { value: '51191915', label: 'Calcium gluconate' },
  { value: '51182406', label: 'Alendronic acid or alendronate sodium' },
  { value: '51181613', label: 'Calcitonin' },
  { value: '51190000', label: 'Agents affecting water and electrolytes' },
  { value: '51191500', label: 'Diuretics' },
  { value: '51191502', label: 'Chlortalidone or chlorthalidone' },
  { value: '51191504', label: 'Bumetanide' },
  { value: '51191507', label: 'Spironolactone' },
  { value: '51191509', label: 'Mannitol' },
  { value: '51191600', label: 'Electrolytes' },
  { value: '51191602', label: 'Sodium chloride electrolytes' },
  { value: '51191800', label: 'Potassium salts' },
  { value: '51191802', label: 'Potassium chloride' },
  { value: '51200000', label: 'Immunomodulating drugs' },
  { value: '51201500', label: 'Immunosuppressants' },
  { value: '51203401', label: 'Azathioprine' },
  { value: '51203101', label: 'Ciclosporin or cyclosporine' },
  { value: '51203203', label: 'Mycophenolate or mycophenolic acid' },
  { value: '51202804', label: 'Tacrolimus' },
  { value: '51201600', label: 'Vaccines and antigens and toxoids' },
  { value: '51201604', label: 'Diphteria vaccine' },
  { value: '51201606', label: 'Hemophilus influenzae vaccine' },
  { value: '51201607', label: 'Hepatitis B virus vaccine' },
  { value: '51201608', label: 'Influenza virus vaccine' },
  { value: '51201609', label: 'Measles virus vaccine' },
  { value: '51201610', label: 'Meningococcal vaccine' },
  { value: '51201615', label: 'Pneumococcal vaccine' },
  { value: '51201616', label: 'Poliovirus vaccine' },
  { value: '51201617', label: 'Rabies vaccine' },
  { value: '51201618', label: 'Rota virus vaccine' },
  { value: '51201623', label: 'Typhoid vaccine' },
  { value: '51201624', label: 'Varicella virus vaccine' },
  { value: '51201625', label: 'Yellow fever vaccine' },
  { value: '51192000', label: 'Antigout drugs' },
  { value: '51192001', label: 'Allopurinol' },
  { value: '51192002', label: 'Colchicine' },
  { value: '51172300', label: 'Antidotes and emetics' },
  { value: '51202206', label: 'Dexrazoxane' },
  { value: '51401525', label: 'Flumazenil' },
  { value: '51172305', label: 'Protamine sulfate' },
  {
    value: '52000000',
    label: 'Domestic Appliances and Supplies and Consumer Electronic Products'
  },
  { value: '52100000', label: 'Floor coverings' },
  { value: '52101500', label: 'Rugs and mats' },
  { value: '56100000', label: 'Accommodation furniture' },
  { value: '56101500', label: 'Furniture' },
  { value: '56101600', label: 'Outdoor furniture' },
  {
    value: '52120000',
    label: 'Bedclothes and table and kitchen linen and towels'
  },
  { value: '52121500', label: 'Bedclothes' },
  { value: '52121501', label: 'Quilts' },
  { value: '52121505', label: 'Pillows' },
  { value: '52121508', label: 'Blankets' },
  { value: '52121600', label: 'Table and kitchen linen and accessories' },
  { value: '52121700', label: 'Towels' },
  { value: '52130000', label: 'Window treatments' },
  { value: '52131500', label: 'Curtains and draperies' },
  { value: '52140000', label: 'Domestic appliances' },
  { value: '52141500', label: 'Domestic kitchen appliances' },
  { value: '52141501', label: 'Domestic refrigerators' },
  { value: '52141502', label: 'Domestic microwave ovens' },
  { value: '52141600', label: 'Domestic laundry appliances and supplies' },
  { value: '52141700', label: 'Domestic bath appliances' },
  { value: '52141800', label: 'Other domestic household appliances' },
  { value: '52150000', label: 'Domestic kitchenware and kitchen supplies' },
  { value: '52151500', label: 'Domestic disposable kitchenware' },
  { value: '52151501', label: 'Domestic disposable cookware' },
  { value: '52151504', label: 'Domestic disposable cups or glasses or lids' },
  { value: '52151600', label: 'Domestic kitchen tools and utensils' },
  { value: '52151700', label: 'Domestic flatware and cutlery' },
  { value: '52151800', label: 'Domestic cookware' },
  { value: '52151900', label: 'Domestic bakeware' },
  {
    value: '52152000',
    label: 'Domestic dishes and servingware and storage containers'
  },
  { value: '52152100', label: 'Domestic drink ware' },
  { value: '52152101', label: 'Domestic coffee or tea cups' },
  { value: '52160000', label: 'Consumer electronics' },
  { value: '52161500', label: 'Audio and visual equipment' },
  { value: '52161502', label: 'Cassette players or recorders' },
  { value: '52161505', label: 'Televisions' },
  { value: '45121515', label: 'Hand held camcorders or video cameras' },
  { value: '52161511', label: 'Radios' },
  { value: '46171616', label: 'Radar detectors' },
  {
    value: '53000000',
    label: 'Apparel and Luggage and Personal Care Products'
  },
  { value: '53100000', label: 'Clothing' },
  { value: '53101500', label: 'Slacks and trousers and shorts' },
  { value: '53101600', label: 'Shirts and blouses' },
  { value: '53101700', label: 'Sweaters' },
  { value: '53101800', label: 'Coats and jackets' },
  { value: '53101900', label: 'Suits' },
  { value: '53102000', label: 'Dresses and skirts and saris and kimonos' },
  { value: '53102100', label: 'Overalls and coveralls' },
  { value: '53102200', label: 'Folkloric clothing' },
  { value: '53102300', label: 'Undergarments' },
  { value: '53102305', label: 'Infant diapers' },
  { value: '53102400', label: 'Hosiery' },
  { value: '53102402', label: 'Socks' },
  { value: '53102500', label: 'Clothing accessories' },
  { value: '53102501', label: 'Belts or suspenders' },
  { value: '53102504', label: 'Gloves or mittens' },
  { value: '53102600', label: 'Nightwear' },
  { value: '53110000', label: 'Footwear' },
  { value: '53111500', label: 'Boots' },
  { value: '53111501', label: 'Mens boots' },
  { value: '53111502', label: 'Womens boots' },
  { value: '53111503', label: 'Boys boots' },
  { value: '53111504', label: 'Girls boots' },
  { value: '53111505', label: 'Infants boots' },
  { value: '53111600', label: 'Shoes' },
  { value: '53111700', label: 'Slippers' },
  { value: '53111800', label: 'Sandals' },
  { value: '53111900', label: 'Athletic footwear' },
  { value: '53120000', label: 'Luggage and handbags and packs and cases' },
  { value: '53121500', label: 'Luggage' },
  { value: '53121501', label: 'Garment bags' },
  { value: '53121502', label: 'Luggage sets' },
  { value: '53121503', label: 'Individual luggage pieces' },
  { value: '53121600', label: 'Purses and handbags and bags' },
  { value: '53121601', label: 'Handbags or purses' },
  { value: '53121602', label: 'Duffel bags' },
  { value: '53121603', label: 'Backpacks' },
  { value: '53121700', label: 'Business cases' },
  { value: '53121701', label: 'Briefcases' },
  { value: '53121800', label: 'Travel kits and accessories' },
  { value: '53121801', label: 'Travel kits' },
  { value: '53121802', label: 'Travel carts' },
  { value: '53130000', label: 'Personal care products' },
  { value: '53131500', label: 'Dental' },
  { value: '53131501', label: 'Mouthwash' },
  { value: '53131502', label: 'Toothpaste' },
  { value: '53131503', label: 'Toothbrushes' },
  { value: '53131504', label: 'Dental floss' },
  { value: '53131600', label: 'Bath and body' },
  { value: '53131601', label: 'Shower caps' },
  { value: '53131602', label: 'Hair care supplies' },
  { value: '53131603', label: 'Razors' },
  { value: '53131604', label: 'Hair combs or brushes' },
  { value: '53131605', label: 'Vanity kits' },
  { value: '53131606', label: 'Deodorants' },
  { value: '53131607', label: 'Hand or body lotion or oil' },
  { value: '53131608', label: 'Soaps' },
  { value: '53131609', label: 'Sun protection products' },
  { value: '53131610', label: 'Eye care supplies' },
  { value: '53131611', label: 'Shaving creams' },
  { value: '53131612', label: 'Bath gels' },
  { value: '53131613', label: 'Facial care products' },
  { value: '53131614', label: 'Foot care products' },
  { value: '53131615', label: 'Feminine hygiene products' },
  { value: '53131616', label: 'Para pharmaceutical creams or lotions' },
  { value: '53131617', label: 'Manicure implements' },
  { value: '53131618', label: 'Pedicure implements' },
  { value: '54000000', label: 'Timepieces and Jewelry and Gemstone Products' },
  { value: '54100000', label: 'Jewelry' },
  { value: '54110000', label: 'Timepieces' },
  { value: '54111500', label: 'Watches' },
  { value: '54111501', label: 'Wrist watches' },
  { value: '54111600', label: 'Clocks' },
  { value: '54111601', label: 'Wall clocks' },
  { value: '54111603', label: 'Free standing clocks' },
  { value: '54111700', label: 'Watch or clock parts or accessories' },
  { value: '54111701', label: 'Watch or clock dials' },
  { value: '54120000', label: 'Gemstones' },
  { value: '55000000', label: 'Published Products' },
  { value: '55100000', label: 'Printed media' },
  { value: '55101500', label: 'Printed publications' },
  { value: '55101501', label: 'Charts or maps or atlases' },
  { value: '55101502', label: 'Directories' },
  { value: '55101503', label: 'Catalogs' },
  { value: '55101504', label: 'Newspapers' },
  { value: '55101505', label: 'Comic books' },
  { value: '55101506', label: 'Magazines' },
  {
    value: '55101507',
    label: 'Picture or drawing or coloring books for children'
  },
  { value: '55101525', label: 'Encyclopedias' },
  { value: '55101509', label: 'Educational or vocational textbooks' },
  { value: '55101510', label: 'Leisure reading books' },
  { value: '55101524', label: 'Reference books' },
  { value: '55101526', label: 'Dictionaries' },
  { value: '55101513', label: 'Trading cards' },
  { value: '55101514', label: 'Sheet music' },
  { value: '55101515', label: 'Promotional material or annual reports' },
  { value: '55110000', label: 'Electronic reference material' },
  { value: '55111500', label: 'Electronic publications and music' },
  { value: '55111501', label: 'Electronic directories' },
  { value: '55120000', label: 'Signage and accessories' },
  { value: '55121500', label: 'Tags' },
  { value: '55121501', label: 'Luggage tags' },
  { value: '55121502', label: 'Security tags' },
  { value: '55121503', label: 'Identification tags' },
  { value: '55121600', label: 'Labels' },
  { value: '55121601', label: 'Label removing kits' },
  { value: '55121602', label: 'Clothing labels' },
  { value: '55121604', label: 'Canning or bottling labels' },
  { value: '55121605', label: 'Addressing or mailing labels' },
  { value: '55121606', label: 'Self adhesive labels' },
  { value: '55121607', label: 'Decals' },
  { value: '55121608', label: 'Bar code labels' },
  { value: '55121609', label: 'Packaging labels' },
  { value: '55121700', label: 'Signage' },
  { value: '55121701', label: 'Metallic nameplates' },
  { value: '55121702', label: 'Non metallic nameplates' },
  { value: '55121703', label: 'Illuminated signs' },
  { value: '55121704', label: 'Safety signs' },
  { value: '55121705', label: 'Self adhesive signs' },
  { value: '55121706', label: 'Banners' },
  { value: '55121707', label: 'Magnetic signs' },
  { value: '55121708', label: 'Neon signs' },
  { value: '55121903', label: 'Moving message signs' },
  { value: '55121710', label: 'Traffic signs' },
  { value: '55121904', label: 'Billboards' },
  { value: '55121715', label: 'Flags or accessories' },
  {
    value: '70000000',
    label: 'Farming and Fishing and Forestry and Wildlife Contracting Services'
  },
  { value: '70100000', label: 'Fisheries and aquaculture' },
  { value: '70110000', label: 'Horticulture' },
  { value: '70120000', label: 'Livestock services' },
  {
    value: '70130000',
    label: 'Land and soil preparation and management and protection'
  },
  { value: '70140000', label: 'Crop production and management and protection' },
  { value: '70150000', label: 'Forestry' },
  { value: '70160000', label: 'Wildlife and flora' },
  { value: '71000000', label: 'Mining and oil and gas services' },
  { value: '71100000', label: 'Mining services' },
  { value: '71110000', label: 'Oil and gas exploration services' },
  {
    value: '72000000',
    label: 'Building and Facility Construction and Maintenance Services '
  },
  {
    value: '72100000',
    label: 'Building and facility maintenance and repair services'
  },
  {
    value: '73000000',
    label: 'Industrial Production and Manufacturing Services'
  },
  { value: '73100000', label: 'Plastic and chemical industries' },
  { value: '73110000', label: 'Wood and paper industries' },
  { value: '73120000', label: 'Metal and mineral industries' },
  { value: '73130000', label: 'Food and beverage industries' },
  { value: '73140000', label: 'Fibers and textiles and fabric industries' },
  { value: '73150000', label: 'Manufacturing support services' },
  { value: '73160000', label: 'Machinery and transport equipment manufacture' },
  {
    value: '73170000',
    label: 'Manufacture of electrical goods and precision instruments'
  },
  { value: '76000000', label: 'Industrial Cleaning Services' },
  { value: '76100000', label: 'Decontamination services' },
  { value: '76110000', label: 'Cleaning and janitorial services' },
  { value: '76120000', label: 'Refuse disposal and treatment' },
  { value: '76130000', label: 'Toxic and hazardous waste cleanup' },
  { value: '77000000', label: 'Environmental Services' },
  { value: '77100000', label: 'Environmental management' },
  { value: '77110000', label: 'Environmental protection' },
  {
    value: '77130000',
    label: 'Pollutants tracking and monitoring and rehabilitation services'
  },
  { value: '78000000', label: 'Transportation and Storage and Mail Services' },
  { value: '78100000', label: 'Mail and cargo transport' },
  { value: '78110000', label: 'Passenger transport' },
  { value: '78120000', label: 'Material packing and handling' },
  { value: '78130000', label: 'Storage' },
  { value: '78140000', label: 'Transport services' },
  {
    value: '80000000',
    label: 'Management and Business Professionals and Administrative Services'
  },
  { value: '80100000', label: 'Management advisory services' },
  { value: '80110000', label: 'Human resources services' },
  { value: '80120000', label: 'Legal services' },
  { value: '80130000', label: 'Real estate services' },
  { value: '80140000', label: 'Marketing and distribution' },
  { value: '80150000', label: 'Trade policy and services' },
  { value: '80160000', label: 'Business administration services' },
  {
    value: '81000000',
    label: 'Engineering and Research and Technology Based Services'
  },
  { value: '81100000', label: 'Professional engineering services' },
  { value: '81110000', label: 'Computer services' },
  { value: '81120000', label: 'Economics' },
  { value: '81130000', label: 'Statistics' },
  { value: '81140000', label: 'Manufacturing technologies' },
  { value: '93171500', label: 'Trade policy' },
  { value: '81150000', label: 'Earth science services' },
  {
    value: '82000000',
    label: 'Editorial and Design and Graphic and Fine Art Services'
  },
  { value: '82100000', label: 'Advertising' },
  { value: '82110000', label: 'Writing and translations' },
  { value: '82120000', label: 'Reproduction services' },
  { value: '82130000', label: 'Photographic services' },
  { value: '82140000', label: 'Graphic design' },
  { value: '82150000', label: 'Professional artists and performers' },
  {
    value: '83000000',
    label: 'Public Utilities and Public Sector Related Services'
  },
  { value: '83100000', label: 'Utilities' },
  { value: '83110000', label: 'Telecommunications media services' },
  { value: '83120000', label: 'Information services' },
  { value: '84000000', label: 'Financial and Insurance Services' },
  { value: '84100000', label: 'Development finance' },
  { value: '84110000', label: 'Accounting and bookkeeping services' },
  { value: '84120000', label: 'Banking and investment' },
  { value: '93160000', label: 'Taxation' },
  { value: '84140000', label: 'Credit agencies' },
  { value: '85000000', label: 'Healthcare Services' },
  { value: '85100000', label: 'Comprehensive health services' },
  { value: '85110000', label: 'Disease prevention and control' },
  { value: '85120000', label: 'Medical practice' },
  { value: '85130000', label: 'Medical science research and experimentation' },
  { value: '85122000', label: 'Dental services' },
  { value: '85150000', label: 'Food and nutrition services' },
  { value: '86000000', label: 'Education and Training Services' },
  { value: '86100000', label: 'Vocational training' },
  { value: '86110000', label: 'Alternative educational systems' },
  { value: '86120000', label: 'Educational institutions' },
  { value: '86130000', label: 'Specialized educational services' },
  { value: '86140000', label: 'Educational facilities' },
  {
    value: '90000000',
    label: 'Travel and Food and Lodging and Entertainment Services'
  },
  { value: '90100000', label: 'Restaurants and catering' },
  { value: '90110000', label: 'Hotels and lodging and meeting facilities' },
  { value: '90120000', label: 'Travel facilitation' },
  { value: '90130000', label: 'Performing arts' },
  { value: '90140000', label: 'Commercial sports' },
  { value: '90150000', label: 'Entertainment services' },
  { value: '91000000', label: 'Personal and Domestic Services' },
  { value: '91100000', label: 'Personal appearance' },
  { value: '91110000', label: 'Domestic and personal assistance' },
  {
    value: '92000000',
    label: 'National Defense and Public Order and Security and Safety Services'
  },
  { value: '92100000', label: 'Public order and safety' },
  { value: '92110000', label: 'Military services and national defense' },
  { value: '92120000', label: 'Security and personal safety' },
  { value: '93000000', label: 'Politics and Civic Affairs Services' },
  { value: '93100000', label: 'Political systems and institutions' },
  { value: '93110000', label: 'Socio political conditions' },
  { value: '93120000', label: 'International relations' },
  { value: '93130000', label: 'Humanitarian aid and relief' },
  { value: '93140000', label: 'Community and social services' },
  { value: '93150000', label: 'Public administration and finance services' },
  { value: '94000000', label: 'Organizations and Clubs' },
  { value: '94100000', label: 'Work related organizations' },
  { value: '94110000', label: 'Religious organizations' },
  { value: '94120000', label: 'Clubs' },
  {
    value: '94130000',
    label: 'Civic organizations and associations and movements'
  },
  { value: '10191701', label: 'Animal control traps' },
  { value: '10131506', label: 'Livestock stables' },
  { value: '10131700', label: 'Animal habitats' },
  { value: '14111705', label: 'Paper napkins or serviettes' },
  { value: '14121501', label: 'Bleached paperboard' },
  { value: '14121502', label: 'Unbleached paperboard' },
  { value: '14121600', label: 'Tissue papers' },
  { value: '14121601', label: 'Unbleached crepe papers' },
  { value: '14121602', label: 'Semi bleached crepe papers' },
  { value: '14121603', label: 'Wet strength tissue papers' },
  { value: '14121604', label: 'Acid free tissue papers' },
  { value: '14121700', label: 'Laminated papers' },
  { value: '14121701', label: 'Papers bonded with film' },
  {
    value: '14121702',
    label: 'Cylinder papers or multi layer heavyweight paper'
  },
  { value: '14121800', label: 'Coated papers' },
  { value: '14121801', label: 'Clay coated papers' },
  { value: '14121802', label: 'Polyethylene coated papers' },
  { value: '14121803', label: 'Polyester coated papers' },
  { value: '14121804', label: 'Silicone coated papers' },
  { value: '14121900', label: 'Newsprint and offset papers' },
  { value: '14121902', label: 'Colored newsprint' },
  { value: '14121903', label: 'High brightness newsprint' },
  { value: '14121904', label: 'Offset paper' },
  { value: '15131600', label: 'Fission fuel assemblies' },
  { value: '24102200', label: 'Packing tools' },
  { value: '24111811', label: 'Carboys' },
  { value: '24112501', label: 'Slotted corrugated cartons' },
  { value: '24113109', label: 'Molded box' },
  { value: '24140000', label: 'Packing supplies' },
  { value: '24141500', label: 'Securing and protecting supplies' },
  { value: '25102100', label: 'Truck tractors' },
  { value: '25102101', label: 'Low cab forward tractors' },
  { value: '25102102', label: 'Long nose tractors with sleeper' },
  { value: '25102103', label: 'Long nose tractors without sleeper' },
  { value: '25102104', label: 'Cab over engine tractors with sleeper' },
  { value: '25102105', label: 'Cab over engine tractors without sleeper' },
  { value: '25111900', label: 'Marine craft systems and subassemblies' },
  { value: '25111901', label: 'Marine craft communications systems' },
  {
    value: '25173100',
    label: 'Location and navigation systems and components'
  },
  { value: '25173300', label: 'Master control systems' },
  { value: '25180000', label: 'Vehicle bodies and trailers' },
  { value: '25181700', label: 'Product and material trailers' },
  { value: '25181701', label: 'Container trailers' },
  { value: '25181703', label: 'Livestock trailers' },
  { value: '25190000', label: 'Transportation services equipment' },
  {
    value: '25191600',
    label: 'Space transportation support systems and equipment'
  },
  { value: '26101700', label: 'Engine components and accessories' },
  { value: '26101400', label: 'Motor or generator components' },
  { value: '26111712', label: 'Nickel hydrogen batteries' },
  { value: '26111713', label: 'Thermal batteries' },
  { value: '27111500', label: 'Cutting and crimping and punching tools' },
  { value: '27111600', label: 'Forming tools' },
  { value: '27111700', label: 'Wrenches and drivers' },
  { value: '27111800', label: 'Measuring and layout tools' },
  { value: '27111900', label: 'Rough and finishing tools' },
  { value: '27112000', label: 'Agriculture, forestry and garden handtools' },
  { value: '27112100', label: 'Holding and clamping tools' },
  { value: '27112200', label: 'Masonry and concrete tools' },
  { value: '27112300', label: 'Marking tools' },
  { value: '27112400', label: 'Fastener setting tools' },
  { value: '27112500', label: 'Prying and bending tools' },
  { value: '27112600', label: 'Sealing tools' },
  { value: '30171901', label: 'Double hung window frames' },
  { value: '30171902', label: 'Single hung window frames' },
  { value: '30171903', label: 'Casement window frames' },
  { value: '30171904', label: 'Horizontal slider window frames' },
  { value: '30171905', label: 'Tilt or transom window frames' },
  { value: '30171906', label: 'Fixed window frames' },
  {
    value: '41121500',
    label: 'Pipettes and liquid handling equipment and supplies'
  },
  { value: '41121804', label: 'Laboratory flasks' },
  { value: '41121803', label: 'Laboratory beakers' },
  { value: '41113115', label: 'Radon detectors' },
  { value: '43222800', label: 'Telephony equipment' },
  { value: '43222901', label: 'Line conditioners' },
  { value: '43211603', label: 'Port replicators' },
  { value: '43201559', label: 'Serial port cards' },
  { value: '43201522', label: 'Parallel port cards' },
  { value: '43201407', label: 'Peripheral controller cards' },
  { value: '43201531', label: 'Video capture boards' },
  { value: '43221702', label: 'Television access equipment' },
  { value: '43221704', label: 'Radio core equipment' },
  { value: '43211902', label: 'Liquid crystal display LCD panels or monitors' },
  { value: '43212104', label: 'Inkjet printers' },
  { value: '43201601', label: 'Computer chassis' },
  { value: '43201602', label: 'Network equipment chassis' },
  { value: '43222610', label: 'Network service concentrators or hubs' },
  { value: '43222609', label: 'Network routers' },
  { value: '43222630', label: 'Multistation access units' },
  { value: '43222628', label: 'Modems' },
  { value: '43222629', label: 'Modem banks' },
  { value: '43222608', label: 'Network repeaters' },
  {
    value: '43200000',
    label:
      'Components for information technology or broadcasting or telecommunications'
  },
  { value: '43202004', label: 'Floppy disks' },
  { value: '43202002', label: 'Blank tapes' },
  { value: '44122100', label: 'Fastening supplies' },
  { value: '46190000', label: 'Fire protection' },
  { value: '46191500', label: 'Fire prevention' },
  { value: '46191502', label: 'Heat detectors' },
  { value: '46191600', label: 'Fire fighting equipment' },
  { value: '46191601', label: 'Fire extinguishers' },
  { value: '46191602', label: 'Fire sprinkler systems' },
  { value: '46191603', label: 'Fire hoses or nozzles' },
  { value: '46191604', label: 'Fire blankets' },
  { value: '46191605', label: 'Fire suppression hand tools' },
  { value: '46191606', label: 'Fire suppression foam or similar compounds' },
  { value: '47121602', label: 'Vacuum cleaners' },
  { value: '48101710', label: 'Drinking fountains or bubblers' },
  { value: '48101711', label: 'Bottled water dispensers or accessories' },
  { value: '48110000', label: 'Vending machines' },
  { value: '49201600', label: 'Weight and resistance training equipment' },
  { value: '52131600', label: 'Blinds and shades' },
  { value: '52131700', label: 'Window treatment accessories and hardware' },
  { value: '70101500', label: 'Fisheries operations' },
  { value: '70101501', label: 'Commercial fishing operations' },
  { value: '70101502', label: 'Fishing port services' },
  { value: '70101503', label: 'Fishing onshore facilities' },
  { value: '70101504', label: 'Deep sea fishing operations' },
  { value: '70101507', label: 'Trawling' },
  { value: '70101510', label: 'Fishing nets' },
  { value: '70101600', label: 'Fisheries oversight' },
  { value: '70101601', label: 'Fishery information or documentation services' },
  { value: '70101602', label: 'Fishery research or experimentation services' },
  { value: '70101603', label: 'Fishery data collection or distribution' },
  { value: '70101607', label: 'Fishery resources protection or conservation' },
  { value: '70101700', label: 'Fishery industry and technology' },
  { value: '70101701', label: 'Fishing technology services' },
  { value: '70101702', label: 'Fishery by products production services' },
  { value: '70101703', label: 'Fish production' },
  { value: '70101704', label: 'Fish storage' },
  { value: '70101800', label: 'Fisheries resources' },
  { value: '70101801', label: 'Inland water fishery resources' },
  { value: '70101802', label: 'Fish pond resources' },
  { value: '70101803', label: 'Fish hatcheries' },
  { value: '70101804', label: 'Fish ranches' },
  { value: '70101805', label: 'Fishery by product resources' },
  { value: '70101806', label: 'Fisheries resource evaluation' },
  { value: '70101900', label: 'Aquaculture' },
  { value: '70101901', label: 'Mariculture' },
  { value: '70101902', label: 'Ostreiculture' },
  { value: '70101903', label: 'Shellfish culture' },
  { value: '70101904', label: 'Shrimp farming' },
  { value: '70101905', label: 'Fish farming' },
  { value: '70111500', label: 'Plants and ornamental trees' },
  {
    value: '70111501',
    label: 'Planting services or ornamental plant or bush or tree'
  },
  { value: '70111502', label: 'Pruning services or ornamental plant or bush' },
  { value: '70111503', label: 'Tree trimming services' },
  { value: '70111504', label: 'Bracing services' },
  { value: '70111505', label: 'Tree surgery services' },
  { value: '70111506', label: 'Arborist services' },
  {
    value: '70111507',
    label: 'Removal services or ornamental plant or bush or tree'
  },
  { value: '70111508', label: 'Plants or ornamental tree spraying services' },
  { value: '70111600', label: 'Flowering plants' },
  { value: '70111700', label: 'Parks and gardens and orchards' },
  { value: '70111701', label: 'Orchard management or maintenance services' },
  { value: '70111702', label: 'Vineyard management or maintenance services' },
  { value: '70111703', label: 'Garden planting or maintenance services' },
  { value: '70111704', label: 'Horticultural counseling services' },
  { value: '70111706', label: 'Lawn care services' },
  { value: '70111707', label: 'Highway lawn maintenance services' },
  { value: '70111708', label: 'Mulching services' },
  { value: '70111709', label: 'Seeding services' },
  { value: '70111710', label: 'Mowing services' },
  { value: '70111711', label: 'Sprigging services' },
  { value: '70111712', label: 'Parks or gardens spraying services' },
  { value: '70111713', label: 'Parks management or maintenance services' },
  { value: '70121500', label: 'Dairying' },
  { value: '70121501', label: 'Dairy herd management' },
  { value: '70121502', label: 'Dairy industry development' },
  { value: '70121503', label: 'Dairy technology' },
  { value: '70121504', label: 'Dairy laboratories services' },
  { value: '70121505', label: 'Dairy farm in house processing' },
  { value: '70121600', label: 'Livestock industry' },
  { value: '70121601', label: 'Livestock breeding' },
  { value: '70121602', label: 'Livestock genetics services' },
  { value: '70121603', label: 'Sericulture' },
  { value: '70121604', label: 'Animal husbandry' },
  { value: '70121605', label: 'Farm rearing systems' },
  { value: '70121606', label: 'Poultry production services' },
  { value: '70121607', label: 'Small animal breeding services' },
  { value: '70121608', label: 'Bovine production services' },
  { value: '70121610', label: 'Apiculture' },
  { value: '70121700', label: 'Livestock management' },
  { value: '70121701', label: 'Livestock selection' },
  { value: '70121703', label: 'Livestock slaughtering services' },
  { value: '70121704', label: 'Herd management' },
  { value: '70121705', label: 'Livestock breeding or grooming services' },
  { value: '70121800', label: 'Pets industry' },
  { value: '70121801', label: 'Pet breeding services' },
  { value: '70121802', label: 'Pet grooming services' },
  { value: '70121803', label: 'Kennels services' },
  { value: '70121900', label: 'Pasture and range services' },
  { value: '70121901', label: 'Pasture improvement' },
  { value: '70121902', label: 'Range management' },
  { value: '70121903', label: 'Range research' },
  { value: '70122000', label: 'Animal health' },
  { value: '70122001', label: 'Animal nutrition' },
  { value: '70122002', label: 'Animal disease control' },
  { value: '70122003', label: 'Animal trypanosomiasis' },
  { value: '70122004', label: 'Foot or mouth control services' },
  { value: '70122005', label: 'Animal health preventive medication services' },
  { value: '70122006', label: 'Animal vaccination services' },
  { value: '70122007', label: 'Veterinary administration' },
  { value: '70122008', label: 'Veterinary laboratory technology' },
  { value: '70122009', label: 'Animal hospital services' },
  { value: '70122010', label: 'Animal health information services' },
  { value: '70131500', label: 'Land and soil protection' },
  {
    value: '70131501',
    label: 'Desertification assessment or control services'
  },
  { value: '70131502', label: 'Soil conservation or protection services' },
  { value: '70131503', label: 'Erosion control services' },
  { value: '70131506', label: 'Soil improvement' },
  { value: '70131600', label: 'Land and soil preparation' },
  { value: '70131601', label: 'Fertilizer application services' },
  { value: '70131603', label: 'Plowing services' },
  { value: '70131604', label: 'Seed bed preparation services' },
  { value: '70131605', label: 'Soil chemical treatment services' },
  { value: '70131700', label: 'Land and soil management' },
  { value: '70131701', label: 'Land use planning' },
  { value: '70131702', label: 'Land evaluation' },
  { value: '70131703', label: 'Land reclamation' },
  { value: '70131704', label: 'Agro ecological zones assessment or planning' },
  { value: '70131705', label: 'Soil classification' },
  { value: '70131706', label: 'Watershed management' },
  { value: '70141500', label: 'Crop production' },
  { value: '70141501', label: 'Seed production services' },
  { value: '70141503', label: 'Grass or fodder production' },
  { value: '70141507', label: 'Sugarbeet or sugarcane production' },
  { value: '70141508', label: 'Nut production' },
  { value: '70141512', label: 'Grain or legume production' },
  { value: '70141515', label: 'Roots or tubers production' },
  { value: '70141516', label: 'Cereals production' },
  { value: '70141520', label: 'Vegetable production' },
  { value: '70141600', label: 'Crop protection' },
  { value: '70141601', label: 'Crop spraying services' },
  { value: '70141602', label: 'Biological control services' },
  { value: '70141604', label: 'Herbicide services' },
  { value: '70141605', label: 'Integrated pest management services' },
  { value: '70141700', label: 'Crop management' },
  { value: '70141701', label: 'Greenhouse services' },
  { value: '70141702', label: 'Fertilizer services' },
  { value: '70141800', label: 'Crop planting and cultivation' },
  { value: '70141801', label: 'Orchard tree or vine planting services' },
  { value: '70141802', label: 'Sprout or twig services' },
  { value: '70141803', label: 'Crop cultivating services' },
  { value: '70141804', label: 'Crop planting services' },
  { value: '70141900', label: 'Crop harvesting' },
  { value: '70141901', label: 'Cash grains harvesting services' },
  { value: '70141902', label: 'Fruit or tree nuts harvesting services' },
  { value: '70141903', label: 'Field crop harvesting services' },
  { value: '70141904', label: 'Seed harvesting services' },
  { value: '70142000', label: 'Post harvesting crop processing' },
  { value: '70142001', label: 'Silos related services' },
  { value: '70142002', label: 'Grain dryers services' },
  { value: '70142003', label: 'Crop processing services' },
  { value: '70142004', label: 'Field crops market preparation services' },
  { value: '70142005', label: 'Cash grain crops market preparation services' },
  { value: '70142006', label: 'Vegetable crops market preparation services' },
  { value: '70142007', label: 'Fruit crops market preparation services' },
  { value: '70142008', label: 'Tree nut crops market preparation services' },
  { value: '70142009', label: 'Ginning services' },
  { value: '70142010', label: 'Crop cleaning services' },
  { value: '70142011', label: 'Cooling or refrigeration services' },
  { value: '70151500', label: 'Forestry management' },
  { value: '70151501', label: 'Forest resources management services' },
  { value: '70151505', label: 'Forest inventory' },
  { value: '70151506', label: 'Forest monitoring or evaluation' },
  { value: '70151507', label: 'Afforestation services' },
  { value: '70151510', label: 'Forest sectoral planning' },
  { value: '70151600', label: 'Forestry industry' },
  { value: '70151601', label: 'Nonwood production services' },
  { value: '70151602', label: 'Essential oils production' },
  { value: '70151603', label: 'Timber production services' },
  { value: '70151604', label: 'Dyes production' },
  { value: '70151605', label: 'Edible forestry production' },
  { value: '70151606', label: 'Wood testing services' },
  { value: '70151700', label: 'Forestry harvesting' },
  { value: '70151701', label: 'Logging or felling' },
  { value: '70151703', label: 'Forest harvesting specialized operations' },
  { value: '70151706', label: 'Temperate forest harvesting' },
  { value: '70151800', label: 'Forestry conservation services' },
  { value: '70151801', label: 'Conservation of forest genetic resources' },
  { value: '70151802', label: 'Forest protection services' },
  { value: '70151803', label: 'Forest arid land rehabilitation' },
  { value: '70151804', label: 'Forest wind breaks or shelter belts' },
  {
    value: '70151805',
    label: 'Forest reserves or parks conservation services'
  },
  { value: '70151806', label: 'Forestry watershed management' },
  { value: '70151807', label: 'Forestry torrent control' },
  { value: '70151900', label: 'Forestry resources' },
  { value: '70151901', label: 'Forest resources development' },
  { value: '70151902', label: 'Agroforestry resources' },
  { value: '70151903', label: 'Fuel wood resources' },
  { value: '70161500', label: 'Fauna' },
  { value: '70161501', label: 'Fauna protection' },
  { value: '70161600', label: 'Flora' },
  { value: '70161601', label: 'Flora protection' },
  { value: '70161700', label: 'Ecosystems' },
  { value: '70161701', label: 'Ecodevelopment services' },
  { value: '70161702', label: 'Marine ecosystem management services' },
  { value: '70161703', label: 'Terrestrial ecosystem management services' },
  { value: '70161704', label: 'Ecosystems protection services' },
  { value: '70170000', label: 'Water resources development and oversight' },
  { value: '70171500', label: 'Water resource development service' },
  { value: '70171501', label: 'Water quality assessment services' },
  { value: '70171502', label: 'Water resources planning services' },
  { value: '70171503', label: 'Ground or surface water surveying' },
  { value: '70171504', label: 'Water resources mapping services' },
  { value: '70171505', label: 'River basin development' },
  { value: '70171506', label: 'Ground or surface water modeling services' },
  { value: '70171600', label: 'Water quality management services' },
  { value: '70171601', label: 'Water quality management' },
  { value: '70171602', label: 'Water testing services' },
  { value: '70171603', label: 'Floodplain management' },
  { value: '70171604', label: 'Water conservation advisory services' },
  { value: '70171605', label: 'Water rights advisory services' },
  { value: '70171606', label: 'Water resource recovery services' },
  { value: '70171607', label: 'Water pricing services' },
  {
    value: '70171700',
    label: 'Irrigation system maintenance and management services'
  },
  { value: '70171701', label: 'Canal maintenance or management services' },
  { value: '70171702', label: 'Dam maintenance or management services' },
  { value: '70171703', label: 'Reservoirs maintenance or management services' },
  {
    value: '70171704',
    label: 'Pumping station maintenance or management services'
  },
  { value: '70171705', label: 'Pipelines maintenance or management services' },
  {
    value: '70171706',
    label: 'Dike or embankment maintenance or management services'
  },
  { value: '70171707', label: 'Water well maintenance or management services' },
  { value: '70171708', label: 'Irrigation advisory services' },
  { value: '70171709', label: 'Irrigation systems management services' },
  { value: '70171800', label: 'Drainage services' },
  { value: '70171801', label: 'Land drainage services' },
  { value: '70171802', label: 'Storm water drainage' },
  { value: '70171803', label: 'Flood protection or control services' },
  { value: '71101700', label: 'Extraction' },
  { value: '71141100', label: 'Well plugging and abandonment services' },
  { value: '72101500', label: 'Building maintenance and repair services' },
  { value: '72101501', label: 'Handyman services' },
  { value: '72153402', label: 'Scaffolding services' },
  { value: '72153401', label: 'Rigging services' },
  { value: '72101504', label: 'Disaster proofing or contingency services' },
  { value: '72153504', label: 'High pressure water blasting' },
  { value: '72153612', label: 'Interior design or decorating' },
  { value: '72102100', label: 'Pest control' },
  { value: '72102101', label: 'Bird proofing services' },
  { value: '72102102', label: 'Termite control services' },
  { value: '72102103', label: 'Extermination or fumigation services' },
  { value: '72102104', label: 'Structural pest control' },
  { value: '72102105', label: 'Animal trapping' },
  { value: '72102106', label: 'Rodent control' },
  { value: '72101510', label: 'Plumbing system maintenance or repair' },
  {
    value: '72101511',
    label: 'Air conditioning installation or maintenance or repair service'
  },
  { value: '72101508', label: 'Floor cleaning services' },
  { value: '73101600', label: 'Chemicals and fertilizers production' },
  { value: '73111500', label: 'Wood processing' },
  { value: '73131500', label: 'Beverage processing' },
  { value: '73131600', label: 'Meat and poultry and seafood processing' },
  { value: '73131700', label: 'Fruits and vegetables processing' },
  { value: '73131800', label: 'Dairy and eggs processing' },
  { value: '73131900', label: 'Grains and sugar and oils and fat processing' },
  { value: '73141500', label: 'Fiber production' },
  { value: '73141600', label: 'Thread and yarn processing' },
  { value: '73141700', label: 'Fabrics and leather production' },
  { value: '73151500', label: 'Assembly services' },
  { value: '73151501', label: 'Assembly line work' },
  { value: '73151600', label: 'Packaging services' },
  { value: '73151700', label: 'Material treatment' },
  { value: '73161500', label: 'Manufacture of machinery' },
  { value: '73161600', label: 'Manufacture of transport equipment' },
  { value: '73171500', label: 'Manufacture of electrical goods' },
  {
    value: '73171501',
    label:
      'Power generation or transmission or distribution equipment manufacture services'
  },
  { value: '73171502', label: 'Dry or storage battery manufacture services' },
  { value: '73171503', label: 'Electrical tools manufacture services' },
  {
    value: '73171504',
    label: 'Measuring or testing instruments manufacture services'
  },
  { value: '73171506', label: 'Communication equipment manufacture services' },
  {
    value: '73171507',
    label: 'Electrical household appliances manufacture services'
  },
  {
    value: '73171510',
    label: 'Electrical accessories or supplies manufacture services'
  },
  { value: '73171511', label: 'Electronic equipment manufacture services' },
  {
    value: '73171512',
    label:
      'Electronic computers or data processing equipment manufacture services'
  },
  { value: '73171600', label: 'Manufacture of precision instruments' },
  {
    value: '73171602',
    label: 'Medical or dental equipment manufacture services'
  },
  { value: '73171605', label: 'Laboratory equipment manufacture services' },
  { value: '76101500', label: 'Disinfection' },
  { value: '76101600', label: 'Hazardous material decontamination' },
  { value: '76101601', label: 'Radioactive decontamination services' },
  { value: '76101602', label: 'Asbestos removal or encapsulation' },
  {
    value: '76111500',
    label: 'General building and office cleaning and maintenance services'
  },
  { value: '76111501', label: 'Building cleaning services' },
  { value: '76111503', label: 'Lighting maintenance services' },
  { value: '76111504', label: 'Window or window blind cleaning services' },
  { value: '76111600', label: 'Building component cleaning services' },
  { value: '76111601', label: 'Acoustical tile or ceiling cleaning services' },
  { value: '76111602', label: 'Air duct cleaning' },
  { value: '76111603', label: 'Chimney cleaning' },
  { value: '76111604', label: 'Floor waxing or carpet cleaning' },
  { value: '76111605', label: 'Exhaust hood or fan clearing' },
  { value: '72153505', label: 'Construction site clean up service' },
  { value: '72153507', label: 'Construction site haul away service' },
  { value: '76121500', label: 'Refuse collection and disposal' },
  {
    value: '76121501',
    label: 'Garbage collection or destruction or processing or disposal'
  },
  {
    value: '76121502',
    label: 'Liquid waste collection or processing or disposal'
  },
  { value: '76121503', label: 'Street cleaning services' },
  { value: '76121600', label: 'Nonhazardous waste disposal' },
  { value: '76121601', label: 'Garbage dump' },
  { value: '76121602', label: 'Sanitary landfill operations' },
  { value: '76121603', label: 'Sludge disposal' },
  { value: '76121604', label: 'Dead animal disposal services' },
  { value: '76121700', label: 'Liquid waste treatment' },
  { value: '76121701', label: 'Sewage treatment services' },
  { value: '76121702', label: 'Chemical treatment services' },
  { value: '76121800', label: 'Refuse treatment' },
  { value: '76121900', label: 'Hazardous waste disposal' },
  { value: '76121901', label: 'Medical waste disposal' },
  { value: '76121902', label: 'Acid waste collection or disposal' },
  { value: '76121903', label: 'Chemical detoxification' },
  { value: '76131500', label: 'Nuclear waste treatment' },
  { value: '76131600', label: 'Toxic spill cleanup' },
  { value: '76131700', label: 'Oil spill cleanup' },
  { value: '77101500', label: 'Environmental impact assessment' },
  { value: '77101501', label: 'Risk or hazard assessment' },
  { value: '77101502', label: 'Environmental standards' },
  { value: '77101503', label: 'Environmental indicators analysis' },
  { value: '77101504', label: 'Environmental Impact Assessment EIA services' },
  { value: '77101505', label: 'Environmental monitoring' },
  { value: '77101600', label: 'Environmental planning' },
  { value: '77101601', label: 'Urban environmental development planning' },
  { value: '77101602', label: 'Forest conservation strategy planning' },
  { value: '77101603', label: 'Marine conservation strategy planning' },
  {
    value: '77101604',
    label:
      'Natural resources management or conservation strategy planning services'
  },
  {
    value: '77101605',
    label: 'Environmental institution building or planning'
  },
  { value: '77101700', label: 'Environmental advisory services' },
  { value: '77101701', label: 'Environmental sciences advisory services' },
  { value: '77101702', label: 'Environmental chemistry advisory services' },
  { value: '77101703', label: 'Environmental ethics advisory services' },
  { value: '77101704', label: 'Environmental technology advisory services' },
  { value: '77101705', label: 'Environmental economics advisory services' },
  { value: '77101706', label: 'Environmental law advisory services' },
  { value: '77101707', label: 'Ecology advisory services' },
  { value: '77101800', label: 'Environmental auditing' },
  { value: '77101801', label: 'Environmental information systems' },
  { value: '77101802', label: 'Corporate environmental auditing services' },
  { value: '77101803', label: 'Sectoral environmental auditing services' },
  {
    value: '77101804',
    label: 'Activity specific environmental auditing services'
  },
  { value: '77101805', label: 'Environmental quality control services' },
  { value: '77101806', label: 'Environmental security control services' },
  { value: '77101900', label: 'Pollution investigation services' },
  { value: '77101901', label: 'Industrial site investigation' },
  { value: '77101902', label: 'Industrial waste site investigation' },
  { value: '77101903', label: 'Gasworks site investigation' },
  {
    value: '77101904',
    label: 'Chemical works or oil refinery waste site investigation'
  },
  { value: '77101905', label: 'Wood treatment plant site investigation' },
  { value: '77101906', label: 'Oil depot or terminal site investigation' },
  { value: '77101907', label: 'Dry cleaning plants site investigation' },
  { value: '77101908', label: 'Foundry site investigation' },
  { value: '77101909', label: 'Recycling plant site investigation' },
  { value: '77101910', label: 'Food processing plant site investigation' },
  { value: '77111500', label: 'Environmental safety services' },
  { value: '77111501', label: 'Landscape protection services' },
  { value: '77111502', label: 'Ozone protection services' },
  {
    value: '77111503',
    label: 'Food or feed contamination protection services'
  },
  { value: '77111504', label: 'Genetic resources protection services' },
  { value: '77111505', label: 'Toxic substances protection services' },
  { value: '77111506', label: 'Radiation protection services' },
  { value: '77111507', label: 'Endangered species protection services' },
  { value: '77111508', label: 'Natural risks or hazards protection services' },
  { value: '77111600', label: 'Environmental rehabilitation' },
  { value: '77111601', label: 'Industrial site rehabilitation' },
  { value: '77111602', label: 'Environmental decontamination services' },
  { value: '77111603', label: 'Land rehabilitation services' },
  {
    value: '77120000',
    label: 'Pollution tracking and monitoring and rehabilitation'
  },
  { value: '77121500', label: 'Air pollution' },
  { value: '77121501', label: 'Air quality management' },
  { value: '77121503', label: 'Air pollution protection services' },
  {
    value: '77121504',
    label: 'Air pollution monitoring or measurement services'
  },
  { value: '77121600', label: 'Soil pollution' },
  { value: '77121601', label: 'Soil pollution protection services' },
  { value: '77121602', label: 'Polluted soil removal services' },
  { value: '77121603', label: 'Polluted soil treatment or rehabilitation' },
  { value: '77121604', label: 'Soil pollution advisory services' },
  { value: '77121605', label: 'Soil pollution mapping' },
  { value: '77121606', label: 'Soil pollution measurement or monitoring' },
  { value: '77121607', label: 'Organic fertilizer pollution assessment' },
  { value: '77121608', label: 'Pesticides pollution assessment' },
  { value: '77121609', label: 'Nitrates pollution assessment' },
  { value: '77121610', label: 'Phosphates pollution assessment' },
  { value: '77121700', label: 'Water pollution' },
  {
    value: '77121701',
    label: 'Surface water pollution monitoring or control services'
  },
  {
    value: '77121702',
    label: 'Surface water pollution rehabilitation services'
  },
  { value: '77121703', label: 'Surface water pollution protection services' },
  { value: '77121704', label: 'Surface water treatment services' },
  { value: '77121705', label: 'Surface water pollution drainage services' },
  {
    value: '77121706',
    label: 'Transboundary water pollution management or control services'
  },
  {
    value: '77121707',
    label: 'Groundwater pollution monitoring or control services'
  },
  { value: '77121708', label: 'Groundwater pollution drainage services' },
  {
    value: '77121709',
    label: 'Groundwater pollution treatment or rehabilitation'
  },
  { value: '77131500', label: 'Oil pollution' },
  { value: '77131501', label: 'Oil spillage monitoring services' },
  { value: '77131502', label: 'Oil spillage control services' },
  { value: '77131503', label: 'Oil spillage rehabilitation services' },
  { value: '77131600', label: 'Noise pollution' },
  { value: '77131601', label: 'Noise control services' },
  { value: '77131602', label: 'Noise pollution protection services' },
  { value: '77131603', label: 'Noise pollution monitoring services' },
  { value: '77131604', label: 'Noise pollution advisory services' },
  { value: '77131700', label: 'Toxic substances pollution' },
  { value: '78101500', label: 'Air cargo transport' },
  { value: '78101501', label: 'Domestic air cargo transport' },
  { value: '78101502', label: 'International air cargo transport' },
  { value: '78101600', label: 'Rail cargo transport' },
  { value: '78101601', label: 'Boxcar transport services' },
  { value: '78101602', label: 'Bulk cargo rail transport services' },
  { value: '78101603', label: 'Livestock rail transport' },
  { value: '78101604', label: 'Vehicle transport services' },
  { value: '78101700', label: 'Marine cargo transport' },
  { value: '78101701', label: 'Domestic vessel transport services' },
  { value: '78101702', label: 'International vessel transport services' },
  { value: '78101703', label: 'Domestic barge transport services' },
  { value: '78101704', label: 'International barge transport services' },
  { value: '78101800', label: 'Road cargo transport' },
  { value: '78101801', label: 'Local area trucking services' },
  { value: '78101802', label: 'Regional or national trucking services' },
  { value: '78101803', label: 'Vehicle carrier services' },
  { value: '78101900', label: 'Intermodal cargo transport' },
  { value: '78101901', label: 'Air to ocean transportation' },
  { value: '78101902', label: 'Ocean to rail transportation' },
  { value: '78101903', label: 'Ocean to truck transportation' },
  { value: '78101904', label: 'Air to truck transport' },
  { value: '78102000', label: 'Spacecraft cargo transport' },
  { value: '78102100', label: 'Pipeline services' },
  { value: '78102101', label: 'Petroleum products transport' },
  { value: '78102102', label: 'Water transport' },
  { value: '78102200', label: 'Postal and small parcel and courier services' },
  { value: '78102201', label: 'National postal delivery services' },
  { value: '78102202', label: 'Post office box services' },
  { value: '78102203', label: 'Mailing or mail pick up or delivery services' },
  {
    value: '78102204',
    label: 'Letter or small parcel worldwide delivery services'
  },
  {
    value: '78102205',
    label: 'Letter or small parcel local delivery services'
  },
  { value: '78102206', label: 'Bicycle or scooter messenger services' },
  { value: '78111500', label: 'Passenger air transportation' },
  { value: '78111501', label: 'Helicopter services' },
  { value: '78111502', label: 'Commercial airplane travel' },
  { value: '78111503', label: 'Chartered airplane travel' },
  { value: '78111600', label: 'Passenger railway transportation' },
  { value: '78111601', label: 'Light rail vehicle transport LRV services' },
  { value: '78111602', label: 'Subway transport' },
  {
    value: '78111603',
    label: 'Continental or inter continental rail services'
  },
  { value: '78111700', label: 'Passenger marine transportation' },
  { value: '78111701', label: 'Water taxis' },
  { value: '78111702', label: 'Overnight ship cruises' },
  { value: '78111800', label: 'Passenger road transportation' },
  { value: '78111802', label: 'Scheduled bus services' },
  { value: '78111803', label: 'Chartered bus services' },
  { value: '78111804', label: 'Taxicab services' },
  { value: '78111900', label: 'Space transportation' },
  { value: '78121500', label: 'Packing' },
  { value: '78121501', label: 'Containerization of goods' },
  { value: '78121600', label: 'Material handling services' },
  { value: '78121601', label: 'Freight loading or unloading' },
  { value: '78131500', label: 'Farm products warehousing' },
  { value: '78131501', label: 'Silo services' },
  { value: '78131502', label: 'Grain elevator services' },
  { value: '78131600', label: 'General goods storage' },
  { value: '78131601', label: 'Palletized cargo storage' },
  { value: '78131700', label: 'Bulk storage' },
  { value: '78131701', label: 'In ground storage services' },
  { value: '78131800', label: 'Specialized warehousing and storage' },
  { value: '78131801', label: 'Refrigerated storage' },
  { value: '78131802', label: 'Customs bonded storage services' },
  { value: '78131803', label: 'Hazardous materials storage' },
  { value: '78141500', label: 'Transport arranging services ' },
  { value: '78141501', label: 'Freight forwarders services' },
  { value: '78141502', label: 'Customs brokerage services' },
  {
    value: '78141503',
    label: 'Transportation industry tariff comparison or freight audit services'
  },
  { value: '78141600', label: 'Inspection' },
  { value: '78141601', label: 'Packing inspection services' },
  { value: '78141602', label: 'Cargo survey services' },
  { value: '78141603', label: 'Pest control inspections' },
  { value: '78141700', label: 'Navigational services' },
  { value: '78141701', label: 'Tugboat services' },
  { value: '78141702', label: 'Drawbridge operations' },
  { value: '78141800', label: 'Terminal services' },
  { value: '78141801', label: 'Stevedoring services' },
  { value: '78141802', label: 'Vessel docking services' },
  { value: '78141803', label: 'Vessel stores services' },
  {
    value: '80101500',
    label: 'Business and corporate management consultation services'
  },
  { value: '80101501', label: 'New business start up consultation services' },
  { value: '80101502', label: 'Corporate mergers consultation services' },
  { value: '80101503', label: 'Corporate divestiture consultation services' },
  { value: '80101504', label: 'Strategic planning consultation services' },
  { value: '80101505', label: 'Corporate objectives or policy development' },
  { value: '80101506', label: 'Organizational structure consultation' },
  { value: '80101600', label: 'Project management' },
  {
    value: '80101601',
    label: 'Feasibility studies or screening of project ideas'
  },
  { value: '80101602', label: 'Regional or location studies for projects' },
  { value: '80101603', label: 'Economic or financial evaluation of projects' },
  { value: '80101604', label: 'Project administration or planning' },
  { value: '80101700', label: 'Industrial management' },
  {
    value: '80101702',
    label: 'Productivity or efficiency studies or implementation'
  },
  { value: '80101703', label: 'Specification standardization services' },
  {
    value: '80101704',
    label: 'Supply chain analysis or re engineering services'
  },
  { value: '80111500', label: 'Human resource development' },
  { value: '80111501', label: 'Management development' },
  { value: '80111502', label: 'Compensation or benefits planning' },
  { value: '80111503', label: 'Labor or union relations' },
  { value: '80111504', label: 'Labor training or development' },
  { value: '80111600', label: 'Temporary personnel services' },
  {
    value: '80111601',
    label: 'Temporary clerical or administrative assistance'
  },
  { value: '80111602', label: 'Temporary marketing staff needs' },
  { value: '80111603', label: 'Temporary production staffing needs' },
  { value: '80111604', label: 'Temporary technician staffing needs' },
  { value: '80111605', label: 'Temporary financial staffing needs' },
  { value: '80111606', label: 'Temporary medical staffing needs' },
  { value: '80111607', label: 'Temporary legal staffing needs' },
  {
    value: '80111608',
    label: 'Temporary information technology software developers'
  },
  {
    value: '80111609',
    label: 'Temporary information technology systems or database administrators'
  },
  {
    value: '80111610',
    label: 'Temporary information technology networking specialists'
  },
  { value: '80111700', label: 'Personnel recruitment' },
  { value: '80111701', label: 'Staff recruiting services' },
  { value: '80111702', label: 'Reference or background check services' },
  { value: '80111703', label: 'Resume or curriculum vitae screening services' },
  { value: '80121500', label: 'Criminal law services' },
  { value: '80121501', label: 'Juvenile justice law services' },
  { value: '80121502', label: 'Appellate procedure services' },
  { value: '80121503', label: 'Defense or criminal law services' },
  { value: '80121600', label: 'Business law services' },
  {
    value: '80121601',
    label: 'Government antitrust or regulations law services'
  },
  { value: '80121602', label: 'Bankruptcy law services' },
  { value: '80121603', label: 'Partnership law' },
  { value: '80121604', label: 'Patent or trademark or copyright law' },
  { value: '80121605', label: 'Liquidation law services' },
  { value: '80121606', label: 'Real estate law' },
  { value: '80121607', label: 'Taxation law' },
  { value: '80121608', label: 'Mergers or acquisitions law' },
  { value: '80121700', label: 'Civil liability services' },
  { value: '80121701', label: 'Malpractice or negligence law services' },
  { value: '80121703', label: 'Property law services' },
  { value: '80121704', label: 'Contract law services' },
  { value: '80121800', label: 'Family law services' },
  { value: '80121801', label: 'Divorce law services' },
  { value: '80121802', label: 'Adoption law services' },
  { value: '80121803', label: 'Immigration or naturalization law' },
  { value: '80121804', label: 'Guardianship or custody law services' },
  { value: '80131500', label: 'Lease and rental of property or building' },
  { value: '80131501', label: 'Residential rental' },
  { value: '80131502', label: 'Commercial or industrial facility rental' },
  { value: '80131503', label: 'Land leases' },
  { value: '80131600', label: 'Sale of property and building' },
  { value: '80131601', label: 'Real estate brokers or agents' },
  { value: '80131602', label: 'Real estate auction' },
  { value: '80131603', label: 'Sale of residential land' },
  { value: '80131604', label: 'Sale of commercial or industrial land' },
  { value: '80131700', label: 'Escrow and title services' },
  { value: '80131701', label: 'Title reconveyance services' },
  { value: '80131702', label: 'Title search services' },
  { value: '80131703', label: 'Escrow account services' },
  { value: '80131800', label: 'Real estate management services' },
  { value: '80131801', label: 'Property management' },
  { value: '80131802', label: 'Real estate appraisal and valuation service' },
  { value: '80131803', label: 'Real estate listing services' },
  { value: '80141500', label: 'Market research' },
  { value: '80141501', label: 'Marketing analysis' },
  { value: '80141502', label: 'Distributive or service trade statistics' },
  { value: '80141503', label: 'Commodity price forecasting' },
  { value: '80141504', label: 'Preparation of commodity market surveys' },
  { value: '80141600', label: 'Sales and business promotion activities' },
  { value: '80141601', label: 'Sales promotion services' },
  { value: '80141700', label: 'Distribution' },
  { value: '80141701', label: 'Direct sales services' },
  { value: '80141702', label: 'Wholesale distribution services' },
  { value: '80141703', label: 'Retail distribution services' },
  { value: '80141704', label: 'Franchise operations' },
  { value: '80141800', label: 'Mailing services' },
  { value: '80141801', label: 'Mailing list compilation services' },
  { value: '80141802', label: 'Mailing list management service' },
  { value: '80141803', label: 'Addressing service' },
  { value: '80151500', label: 'Trade facilitation' },
  { value: '80151501', label: 'Commodity policy or projections services' },
  { value: '80151502', label: 'Trade expansion' },
  { value: '80151503', label: 'Trade information services' },
  { value: '80151504', label: 'Trade promotion services' },
  { value: '80151505', label: 'Multinational marketing enterprises' },
  { value: '80151600', label: 'International trade services' },
  { value: '80151601', label: 'Export development' },
  { value: '80151602', label: 'Import planning' },
  { value: '80151603', label: 'Export projections' },
  { value: '80151604', label: 'Import procurement services' },
  { value: '80161500', label: 'Management support services' },
  { value: '80161501', label: 'Office administration or secretarial services' },
  { value: '80161502', label: 'Meeting planning services' },
  { value: '80161503', label: 'Keyboard entry services' },
  { value: '80161504', label: 'Clerical services' },
  { value: '80161600', label: 'Business facilities oversight' },
  { value: '80161601', label: 'Property management services' },
  { value: '81101500', label: 'Civil engineering' },
  { value: '81101501', label: 'Well engineering' },
  { value: '81101502', label: 'Technical drawing' },
  { value: '81101503', label: 'Harbor or water ports engineering' },
  { value: '81101505', label: 'Structural engineering' },
  { value: '81101506', label: 'Naval architecture' },
  { value: '81101507', label: 'Dam engineering' },
  { value: '81101508', label: 'Architectural engineering' },
  { value: '81101509', label: 'Airport engineering' },
  { value: '81101510', label: 'Highway engineering' },
  { value: '81101511', label: 'Railway engineering' },
  { value: '81101600', label: 'Mechanical engineering' },
  { value: '81101700', label: 'Electrical and electronic engineering' },
  { value: '81101701', label: 'Electrical engineering services' },
  { value: '81101702', label: 'Electronic circuit design' },
  { value: '81101800', label: 'Chemical engineering' },
  { value: '81101900', label: 'Oil and gas engineering' },
  { value: '81101902', label: 'Production engineering for oil or gas' },
  { value: '81102000', label: 'Mining engineering' },
  { value: '81102001', label: 'Hydraulic mining' },
  { value: '81102100', label: 'Ocean engineering' },
  { value: '81102101', label: 'Coastal engineering' },
  { value: '81102200', label: 'Transportation engineering' },
  { value: '81102201', label: 'Traffic engineering' },
  { value: '81102202', label: 'Urban transport network' },
  { value: '81102203', label: 'Inland waterways' },
  { value: '81111500', label: 'Software or hardware engineering' },
  { value: '81111501', label: 'Mainframe software applications design' },
  { value: '81111502', label: 'Personal computer PC application design' },
  { value: '81111503', label: 'Systems integration design' },
  { value: '81111504', label: 'Application programming services' },
  { value: '81111505', label: 'Operating system programming services' },
  { value: '81111506', label: 'Client or server programming services' },
  {
    value: '81111507',
    label: 'ERP or database applications programming services'
  },
  { value: '81111508', label: 'Application implementation services' },
  {
    value: '81111509',
    label: 'Internet or intranet client application development services'
  },
  {
    value: '81111510',
    label: 'Internet or intranet server application development services'
  },
  { value: '81111600', label: 'Computer programmers' },
  { value: '81111601', label: 'Programming for Visual Basic' },
  { value: '81111602', label: 'Programming for Java' },
  { value: '81111603', label: 'Programming for HTML' },
  { value: '81111604', label: 'Programming for ALGOL' },
  { value: '81111605', label: 'Programming for Assembler' },
  { value: '81111606', label: 'Programming for Basic' },
  { value: '81111607', label: 'Programming for C or C++' },
  { value: '81111608', label: 'Programming for COBOL' },
  { value: '81111609', label: 'Programming for FORTRAN' },
  { value: '81111610', label: 'Programming for Pascal' },
  { value: '81111611', label: 'Programming for PL/1' },
  { value: '81111612', label: 'Programming or Proprietary Languages' },
  { value: '81111700', label: 'Management information systems MIS' },
  { value: '81111701', label: 'Wide area network communications design' },
  { value: '81111702', label: 'Local area network communications design' },
  { value: '81111703', label: 'Electronic data interchange EDI design' },
  { value: '81111704', label: 'Database design' },
  { value: '81111705', label: 'Systems architecture' },
  {
    value: '81111800',
    label: 'System and system component administration services '
  },
  { value: '81111801', label: 'Computer or network or internet security' },
  { value: '81111802', label: 'Mainframe administration services' },
  { value: '81111803', label: 'Local area network LAN maintenance or support' },
  { value: '81111804', label: 'Wide area network WAN maintenance or support' },
  {
    value: '81111805',
    label: 'Proprietary or licensed systems maintenance or support'
  },
  { value: '81111806', label: 'Database analysis service' },
  { value: '81111808', label: 'System analysis service' },
  { value: '81111809', label: 'System installation service' },
  { value: '81111900', label: 'Information retrieval systems' },
  { value: '81111901', label: 'Database information retrieval' },
  { value: '81111902', label: 'Online database information retrieval service' },
  { value: '81112000', label: 'Data services' },
  { value: '81112001', label: 'Online data processing service' },
  { value: '81112002', label: 'Data processing or preparation services' },
  { value: '81112100', label: 'Internet services' },
  { value: '81112101', label: 'Internet service providers ISP' },
  { value: '81112102', label: 'Electronic mail service provider' },
  { value: '81112103', label: 'World wide web WWW site design services' },
  { value: '81112104', label: 'Web search engine providers' },
  {
    value: '81112105',
    label: 'World wide web WWW site operation host services'
  },
  { value: '81121500', label: 'Economic analysis' },
  { value: '81121501', label: 'Macro economic analysis' },
  { value: '81121502', label: 'Micro economic analysis' },
  { value: '81121503', label: 'Econometrics' },
  { value: '81121504', label: 'Economic forecasts' },
  { value: '81121600', label: 'Monetary systems and issues' },
  { value: '81121601', label: 'Monetary policy' },
  { value: '81121602', label: 'Monetary systems' },
  { value: '81121603', label: 'Monetary analysis' },
  { value: '81121604', label: 'Monetary liquidity' },
  { value: '81121605', label: 'Precious metals reserves' },
  { value: '81121606', label: 'Foreign exchange control' },
  { value: '81121607', label: 'Currency exchange markets' },
  { value: '81131500', label: 'Methodology and analysis' },
  { value: '81131501', label: 'Factor analysis' },
  { value: '81131502', label: 'Multivariate analysis' },
  { value: '81131503', label: 'Regression analysis' },
  { value: '81131504', label: 'Sampling surveys' },
  { value: '81131505', label: 'Time series analysis' },
  { value: '81141500', label: 'Quality control' },
  { value: '81141501', label: 'Materials testing' },
  { value: '81141502', label: 'Materials synthesis' },
  { value: '81141503', label: 'Materials or product inspection' },
  { value: '81141504', label: 'Equipment test calibration or repair' },
  { value: '81141505', label: 'Production standards development' },
  { value: '81141506', label: 'Product testing' },
  { value: '81141600', label: 'Supply chain management' },
  { value: '81141601', label: 'Logistics' },
  { value: '81141602', label: 'Transit analysis' },
  { value: '81141603', label: 'Transport finance or economics' },
  { value: '81141604', label: 'Transport facilitation' },
  { value: '81141605', label: 'Transport infrastructure' },
  { value: '81141606', label: 'Transport planning' },
  { value: '81141700', label: 'Production planning and control' },
  { value: '81141701', label: 'Production planning' },
  { value: '81141702', label: 'Production control' },
  { value: '81141703', label: 'Production scheduling' },
  {
    value: '81141704',
    label: 'Production statistics collection or analysis services'
  },
  { value: '81141800', label: 'Facilities management' },
  { value: '81141801', label: 'Safety or risk analysis' },
  { value: '81141802', label: 'Industrial hygiene or ventilation' },
  { value: '81141803', label: 'Acoustics or noise control' },
  { value: '81141804', label: 'Equipment inspection service' },
  { value: '81141805', label: 'Building inspection service' },
  { value: '81141806', label: 'Power line inspection service' },
  { value: '81141807', label: 'Plumbing or sewer inspection service' },
  { value: '81151500', label: 'Meteorology' },
  { value: '81151501', label: 'Climatology' },
  { value: '81151502', label: 'Meteorological services' },
  { value: '81151503', label: 'Hydrometeorology' },
  { value: '81151600', label: 'Cartography' },
  { value: '81151601', label: 'Mapping' },
  { value: '81151602', label: 'Map production' },
  { value: '81151603', label: 'Photogrammetry' },
  { value: '81151604', label: 'Land surveying' },
  { value: '81151700', label: 'Geology' },
  { value: '81151701', label: 'Photogeology' },
  { value: '81151702', label: 'Stratigraphic geology' },
  { value: '81151703', label: 'Geological surveys' },
  { value: '81151704', label: 'Geological exploration' },
  { value: '81151800', label: 'Oceanography and hydrology' },
  { value: '81151801', label: 'Oceanographic survey' },
  { value: '81151802', label: 'Estuarine oceanography' },
  { value: '81151803', label: 'Physical oceanography' },
  { value: '81151804', label: 'Hydrological surveys' },
  { value: '81151805', label: 'Bathymetric surveys' },
  { value: '81151806', label: 'Underwater exploration' },
  { value: '81151900', label: 'Geophysics' },
  { value: '81151901', label: 'Geophysical surveys' },
  { value: '81151902', label: 'Geophysical exploration' },
  { value: '81151903', label: 'Geophysical photo interpretation' },
  { value: '81151904', label: 'Aero magnetic geophysics' },
  { value: '82101500', label: 'Print advertising' },
  { value: '82101600', label: 'Broadcast advertising' },
  { value: '82101603', label: 'Internet advertising' },
  { value: '82101700', label: 'Aerial advertising' },
  { value: '82101701', label: 'Banner advertising services' },
  { value: '82101702', label: 'Skywriting advertising services' },
  { value: '82111500', label: 'Technical writing' },
  { value: '82111501', label: 'Instruction writing services' },
  { value: '82111502', label: 'Manual writing services' },
  { value: '82111503', label: 'Academic or scientific article writing' },
  { value: '82111600', label: 'Non technical writing' },
  { value: '82111601', label: 'Letter writing services' },
  { value: '82111602', label: 'Resume writing services' },
  { value: '82111603', label: 'Court reporting services' },
  { value: '82111604', label: 'Transcribing services' },
  { value: '82111700', label: 'Creative writing' },
  { value: '82111701', label: 'Article writers services' },
  { value: '82111702', label: 'Book authors services' },
  { value: '82111703', label: 'Poetry authors services' },
  { value: '82111800', label: 'Editorial and support services' },
  { value: '82111801', label: 'Editing services' },
  { value: '82111802', label: 'Fact checking services' },
  { value: '82111803', label: 'Proofreading services' },
  { value: '82111804', label: 'Written translation services' },
  { value: '82111900', label: 'News and publicity services' },
  { value: '82111901', label: 'Press release services' },
  { value: '82111902', label: 'Special interest newsletter services' },
  { value: '82111903', label: 'News agency wire services' },
  {
    value: '82111904',
    label: 'Newspaper or advertising material delivery services'
  },
  { value: '82121500', label: 'Printing' },
  { value: '82121501', label: 'Planning or layout of graphic production' },
  { value: '82121502', label: 'Typesetting' },
  { value: '82121503', label: 'Digital printing' },
  { value: '82121504', label: 'Letterpress or screen printing' },
  { value: '82121505', label: 'Promotional or advertising printing' },
  { value: '82121506', label: 'Publication printing' },
  { value: '82121507', label: 'Stationery or business form printing' },
  { value: '82121508', label: 'Wrap or tag or label or seal or bag printing' },
  { value: '82121509', label: 'Security or financial instruments printing' },
  { value: '82121510', label: 'Textile printing' },
  { value: '82121600', label: 'Engraving' },
  { value: '82121601', label: 'Currency engraving' },
  { value: '82121602', label: 'Engraved roll printing' },
  { value: '82121603', label: 'Metal plate engraving' },
  { value: '82121700', label: 'Photocopying' },
  { value: '82121701', label: 'Black and white copy or collating services' },
  { value: '82121702', label: 'Color copy or collating services' },
  { value: '82121800', label: 'Publishing' },
  { value: '82121801', label: 'Textbook or research publishing' },
  { value: '82121802', label: 'Author funded publishing services' },
  { value: '82121900', label: 'Bookbinding' },
  { value: '82121901', label: 'Thread stitch bookbinding' },
  { value: '82121902', label: 'Spiral binding' },
  { value: '82121903', label: 'Glued binding' },
  { value: '82121904', label: 'Comb or clamp type binding' },
  { value: '82121905', label: 'Binding restoration or repair' },
  { value: '82121906', label: 'Bronzing or gilding or edging or deckling' },
  { value: '82131500', label: 'Film processing services' },
  { value: '82131501', label: 'Still film processing or reproduction' },
  {
    value: '82131502',
    label: 'Motion picture film processing or reproduction'
  },
  { value: '82131503', label: 'Microfiche services' },
  { value: '82131504', label: 'Color separation' },
  { value: '82131600', label: 'Photographers and cinematographers' },
  { value: '82131601', label: 'Aerial photography services' },
  { value: '82131602', label: 'Motion picture cinematography' },
  { value: '82131603', label: 'Video production services' },
  {
    value: '82131604',
    label: 'Studio photography services or still photographs'
  },
  { value: '82141500', label: 'Art design services' },
  { value: '82141501', label: 'Layout or graphics editing services' },
  { value: '82141502', label: 'Art design or graphics' },
  { value: '82141503', label: 'Photocomposition' },
  { value: '82141504', label: 'Chart or graph design services' },
  { value: '82141505', label: 'Computer generated design services' },
  { value: '82141506', label: 'Package design services' },
  { value: '82141507', label: 'Silkscreen design services' },
  { value: '82141600', label: 'Graphic display services' },
  {
    value: '82141601',
    label: 'Photographic or picture mounting or framing services'
  },
  { value: '82141602', label: 'Article display arrangement' },
  { value: '82151500', label: 'Visual art services' },
  { value: '82151501', label: 'Painters services' },
  { value: '82151502', label: 'Lithographers services' },
  { value: '82151503', label: 'Cartoonists services' },
  { value: '82151504', label: 'Sculptors services' },
  { value: '82151505', label: 'Ceramics makers services' },
  { value: '82151506', label: 'Glass blowers services' },
  {
    value: '82151507',
    label: 'Textile spinners or loomers or weavers services'
  },
  { value: '82151700', label: 'Performing arts professionals' },
  { value: '82151701', label: 'Acting services' },
  { value: '82151702', label: 'Comedians services' },
  { value: '82151703', label: 'Dancers services' },
  { value: '82151704', label: 'Musicians services' },
  { value: '82151705', label: 'Vocalists services' },
  { value: '82151706', label: 'Choreographic services' },
  { value: '83101500', label: 'Water and sewer utilities' },
  { value: '83101501', label: 'Supply of water' },
  { value: '83101502', label: 'Water resource management' },
  { value: '83101503', label: 'Water quality control management' },
  { value: '83101504', label: 'Water distribution management' },
  { value: '83101505', label: 'Water policy advisory services' },
  { value: '83101506', label: 'Water treatment services' },
  { value: '83101507', label: 'Desalination services' },
  { value: '83101600', label: 'Oil and gas utilities' },
  { value: '83101601', label: 'Supply of natural gas' },
  { value: '83101602', label: 'Supply of fuel oil' },
  { value: '83101603', label: 'Oil pipeline services' },
  { value: '83101604', label: 'Gas pipeline services' },
  { value: '83101800', label: 'Electric utilities' },
  { value: '83101801', label: 'Supply of single phase electricity' },
  { value: '83101802', label: 'Supply of two phase electricity' },
  { value: '83101803', label: 'Supply of three phase electricity' },
  { value: '83101804', label: 'Electric power transmission services' },
  { value: '83101805', label: 'Industrial electric power distribution' },
  { value: '83101806', label: 'Rural electrical power distribution' },
  { value: '83101807', label: 'Municipal electric power distribution' },
  { value: '83101900', label: 'Energy conservation' },
  { value: '83101901', label: 'Energy conservation programs' },
  { value: '83101902', label: 'Energy use reduction measures' },
  {
    value: '83111500',
    label: 'Local and long distance telephone communications'
  },
  { value: '83111501', label: 'Local telephone service' },
  { value: '83111502', label: 'Long distance telephone services' },
  { value: '83111503', label: 'Pay phone provider services' },
  { value: '83111504', label: 'Pre paid phone card services' },
  { value: '83111600', label: 'Mobile communications services' },
  {
    value: '83111601',
    label: 'Telecommunication signal enhancement network services'
  },
  {
    value: '83111602',
    label: 'Satellite or earth communication systems services'
  },
  { value: '83111603', label: 'Cellular telephone services' },
  { value: '83111604', label: 'Paging services' },
  { value: '83111700', label: 'Facsimile and telegraph services' },
  { value: '83111701', label: 'Facsimile transmission services' },
  { value: '83111702', label: 'Telegraph transmission services' },
  { value: '83111703', label: 'Telex transmission services' },
  { value: '83111800', label: 'Television services' },
  { value: '83111801', label: 'Cable television services' },
  { value: '83111802', label: 'Closed circuit television services' },
  {
    value: '83111803',
    label: 'Television antenna construction or rental services'
  },
  { value: '83111804', label: 'Television broadcasting station management' },
  { value: '83111900', label: 'Radio services' },
  { value: '83111901', label: 'Radio broadcasting station management' },
  { value: '83111902', label: 'Amateur radio networks or services' },
  { value: '83111903', label: 'Small scale radio systems' },
  { value: '83111904', label: 'Radio studio or equipment services' },
  { value: '83121500', label: 'Libraries' },
  { value: '83121501', label: 'General municipal public use libraries' },
  { value: '83121502', label: 'College or university libraries' },
  { value: '83121503', label: 'Privately owned libraries' },
  {
    value: '83121504',
    label: 'National government or military post libraries'
  },
  { value: '83121600', label: 'Information centers' },
  { value: '83121601', label: 'Chambers of Commerce' },
  { value: '83121602', label: 'Tourism board services' },
  { value: '83121603', label: 'Computerized information retrieval systems' },
  { value: '83121604', label: 'Online database information retrieval systems' },
  {
    value: '83121605',
    label: 'Remote database information retrieval services'
  },
  { value: '83121700', label: 'Mass communication services' },
  { value: '83121701', label: 'Television related services' },
  { value: '83121702', label: 'Radio related services' },
  { value: '83121703', label: 'Internet related services' },
  { value: '83121704', label: 'Citizen warning systems' },
  { value: '84101500', label: 'Development assistance' },
  { value: '84101501', label: 'Financial assistance' },
  { value: '84101502', label: 'Savings mobilization programs' },
  { value: '84101503', label: 'Guarantee agreements' },
  { value: '84101600', label: 'Aid financing' },
  { value: '84101601', label: 'Co financing' },
  { value: '84101602', label: 'Bi lateral or multi lateral aid' },
  { value: '84101603', label: 'Non governmental aid' },
  { value: '84101604', label: 'Government aid' },
  { value: '84101700', label: 'Debt management' },
  { value: '84101701', label: 'Debt negotiation' },
  { value: '84101702', label: 'Debt reorganization' },
  { value: '84101703', label: 'Debt servicing' },
  { value: '84111500', label: 'Accounting services' },
  { value: '84111501', label: 'Cost accounting service' },
  { value: '84111502', label: 'Financial accounting service' },
  { value: '84111503', label: 'Tax accounting service' },
  { value: '84111504', label: 'Bookkeeping services' },
  { value: '84111505', label: 'Payroll accounting services' },
  { value: '84111506', label: 'Billing services' },
  { value: '84111600', label: 'Audit services' },
  { value: '84111601', label: 'Year end audits' },
  { value: '84111602', label: 'Quarterly reviews' },
  { value: '84111603', label: 'Internal audits' },
  { value: '84111700', label: 'Corporate finance' },
  { value: '84111701', label: 'Treasury services' },
  { value: '84111702', label: 'Investor relations services or programs' },
  { value: '84111703', label: 'Budget preparation or review services' },
  { value: '84111800', label: 'Taxation issues and preparation' },
  { value: '84111801', label: 'Tax preparers' },
  { value: '84111802', label: 'Tax advisory services' },
  { value: '84121500', label: 'Banking institutions' },
  { value: '84121501', label: 'Privately owned banks' },
  { value: '84121502', label: 'Publicly owned banks' },
  { value: '84121503', label: 'Credit unions' },
  { value: '84121504', label: 'Development finance institutions' },
  {
    value: '84121600',
    label: 'Funds transfer and clearance and exchange services'
  },
  { value: '84121601', label: 'Funds clearance services' },
  { value: '84121602', label: 'Letter of credit services' },
  { value: '84121603', label: 'Currency exchange services' },
  { value: '84121604', label: 'Spot exchange transaction services' },
  { value: '84121605', label: 'Currency conversion service' },
  { value: '84121700', label: 'Investment advice' },
  { value: '84121701', label: 'Investment advisers' },
  { value: '84121702', label: 'Investment policy' },
  { value: '84121703', label: 'Investment analysis' },
  { value: '84121704', label: 'Investment agreements' },
  { value: '84121800', label: 'Securities and commodities markets services' },
  { value: '84121801', label: 'Stock market trading services' },
  { value: '84121802', label: 'Commodities or futures market services' },
  { value: '84121803', label: 'Government bonds' },
  { value: '84121804', label: 'Privately issued bonds' },
  { value: '84121805', label: 'Precious metals market services' },
  { value: '84121900', label: 'Mortgage banking' },
  { value: '84121901', label: 'Housing finance' },
  { value: '84121902', label: 'Re financing services' },
  { value: '84121903', label: 'Commercial mortgage finance' },
  {
    value: '84131500',
    label: 'Insurance services for structures and property and possessions'
  },
  { value: '84131501', label: 'Building or building contents insurance' },
  { value: '84131502', label: 'Homeowners or renters insurance' },
  { value: '84131503', label: 'Car or truck insurance' },
  { value: '84131504', label: 'Cargo insurance' },
  { value: '84131505', label: 'Marine insurance' },
  { value: '84131506', label: 'Reinsurance services' },
  { value: '84131600', label: 'Life and health and accident insurance' },
  { value: '84131601', label: 'Life insurance' },
  { value: '84131602', label: 'Health or hospitalization insurance' },
  { value: '84131603', label: 'Accidental injury insurance' },
  { value: '84131604', label: 'Disability insurance' },
  { value: '84131605', label: 'Workmens insurance' },
  { value: '84131606', label: 'Unemployment insurance' },
  { value: '84131700', label: 'Pension funds' },
  { value: '84131701', label: 'Employer administered pension funds' },
  { value: '84131702', label: 'Union or guild administered pension funds' },
  { value: '84131800', label: 'Retirement funds' },
  {
    value: '84131801',
    label: 'Self directed or employer sponsored retirement funds'
  },
  {
    value: '84131802',
    label: 'Self directed or self initiated retirement plans'
  },
  { value: '84141500', label: 'Governmental credit agencies' },
  { value: '84141501', label: 'Farm credit services' },
  { value: '84141502', label: 'Small business loan agencies' },
  { value: '84141503', label: 'Minority owned business programs' },
  { value: '84141600', label: 'Personal credit agencies' },
  {
    value: '84141601',
    label: 'Consumer credit gathering or reporting services'
  },
  { value: '84141602', label: 'Credit card service providers' },
  { value: '84141700', label: 'Business credit agencies' },
  {
    value: '84141701',
    label: 'Business credit gathering or reporting services'
  },
  { value: '84141702', label: 'Value added network VAN services' },
  { value: '85101500', label: 'Healthcare centers' },
  { value: '85101501', label: 'Emergency or surgical hospital services' },
  { value: '85101502', label: 'Private specialized clinic services' },
  { value: '85101503', label: 'Medical office services' },
  { value: '85101504', label: 'Psychiatric hospital services' },
  { value: '85101505', label: 'Respiratory hospital services' },
  { value: '85101506', label: 'Substance abuse hospital services' },
  { value: '85101508', label: 'Mobile healthcare centers or services' },
  {
    value: '85101509',
    label: 'Gynecological or obstetrical hospital services'
  },
  { value: '85101600', label: 'Healthcare provider support persons' },
  { value: '85101700', label: 'Health administration services' },
  { value: '85101701', label: 'Health policy' },
  { value: '85101702', label: 'Health legislation or regulations' },
  { value: '85101703', label: 'Health service planning' },
  { value: '85101705', label: 'Public health administration' },
  { value: '85101707', label: 'Health system evaluation services' },
  { value: '85111500', label: 'Contagious disease prevention and control' },
  { value: '85111501', label: 'Aids prevention or control services' },
  {
    value: '85111502',
    label: 'Parasitic disease prevention or control services'
  },
  { value: '85111503', label: 'Fungal disease prevention or control services' },
  { value: '85111504', label: 'Tuberculosis prevention or control services' },
  { value: '85111505', label: 'Leprosy prevention or control services' },
  {
    value: '85111506',
    label: 'Bacterial disease prevention or control services'
  },
  {
    value: '85111507',
    label: 'Sexually transmitted disease prevention or control services'
  },
  { value: '85111508', label: 'Viral disease prevention or control services' },
  {
    value: '85111509',
    label: 'Zoonotic disease prevention or control services'
  },
  { value: '85111510', label: 'Vaccination services' },
  { value: '85111511', label: 'Quarantine services' },
  { value: '85111512', label: 'Immunization services' },
  { value: '85111513', label: 'Disinsectization services' },
  { value: '85111514', label: 'Epidemic prevention or control services' },
  { value: '85111600', label: 'Non contagious disease prevention and control' },
  { value: '85111601', label: 'Bone disease prevention or control services' },
  {
    value: '85111602',
    label: 'Cancer or leukemia prevention or control services'
  },
  {
    value: '85111603',
    label: 'Endocrine disease prevention or control services'
  },
  { value: '85111604', label: 'Heart disease prevention or control services' },
  { value: '85111605', label: 'Immunologic prevention or control services' },
  { value: '85111606', label: 'Allergies prevention or control services' },
  {
    value: '85111607',
    label: 'Neurological disorder prevention or control services'
  },
  {
    value: '85111608',
    label: 'Nutritional disease prevention or control services'
  },
  {
    value: '85111609',
    label: 'Radiation sickness prevention or control services'
  },
  {
    value: '85111610',
    label: 'Digestive system disease prevention or control services'
  },
  { value: '85111611', label: 'Eye disease prevention or control services' },
  {
    value: '85111612',
    label: 'Respiratory disease prevention or control services'
  },
  {
    value: '85111613',
    label: 'Tropical disease prevention or control services'
  },
  {
    value: '85111614',
    label: 'Childhood disease prevention or control services'
  },
  {
    value: '85111615',
    label: 'Diarrheal disease prevention or control services'
  },
  { value: '85111616', label: 'Alcoholism prevention or control services' },
  { value: '85111617', label: 'Drug addiction prevention or control services' },
  { value: '85111700', label: 'Disease vectors management and control' },
  { value: '85111701', label: 'Ticks management or control services' },
  { value: '85111702', label: 'Tsetse flies management or control services' },
  { value: '85111703', label: 'Bacteria management or control services' },
  { value: '85111704', label: 'Mosquito management or control services' },
  { value: '85121500', label: 'Primary care practitioner services' },
  { value: '85121501', label: 'Primary care physician home visits services' },
  { value: '85121502', label: 'Primary care physician consultation services' },
  { value: '85121503', label: 'Primary care physician control services' },
  {
    value: '85121504',
    label: 'Primary care physician emergency medical services'
  },
  { value: '85121600', label: 'Medical doctor specialist services' },
  { value: '85121608', label: 'Psychologist services' },
  { value: '85121613', label: 'Pediatric services' },
  { value: '85121700', label: 'Healthcare provider specialist services' },
  { value: '85121800', label: 'Medical laboratories' },
  { value: '85121801', label: 'Blood analysis laboratory services' },
  { value: '85121802', label: 'Bacteriological laboratory services' },
  { value: '85121803', label: 'Biological laboratory services' },
  { value: '85121804', label: 'Pathological laboratory services' },
  { value: '85121805', label: 'Urinalysis laboratory services' },
  { value: '85121806', label: 'Neurological laboratory services' },
  { value: '85121807', label: 'Ultrasound laboratory services' },
  { value: '85121808', label: 'X ray laboratory services' },
  {
    value: '85121809',
    label: 'Blood or sperm or transplant organ banks services'
  },
  { value: '85121900', label: 'Pharmacists' },
  { value: '85121901', label: 'Pharmaceutical preparation services' },
  { value: '85121902', label: 'Commercial pharmaceutical services' },
  { value: '85122001', label: 'Dentist services' },
  { value: '85122002', label: 'Dental hygienist services' },
  { value: '85122003', label: 'Dentist support staff services' },
  { value: '85122004', label: 'Oral surgeon services' },
  { value: '85122005', label: 'Orthodontic services' },
  { value: '85122100', label: 'Rehabilitation services' },
  { value: '85122101', label: 'Physical therapy services' },
  { value: '85122102', label: 'Occupational therapy services' },
  { value: '85122103', label: 'Rehabilitation services for substance abuse' },
  {
    value: '85122109',
    label: 'Rehabilitation services for people with chronic disabilities'
  },
  { value: '85131500', label: 'Experimental medicine services' },
  { value: '85131600', label: 'Medical ethics' },
  { value: '85131700', label: 'Medical science and research' },
  { value: '85141500', label: 'Faith healers' },
  { value: '85141600', label: 'Herbal treatments' },
  { value: '85141601', label: 'Herbal medicine or herbalists services' },
  { value: '85141602', label: 'Algae or sea weed medical cures' },
  { value: '85141700', label: 'Homeopathic practice' },
  { value: '85151500', label: 'Food technology' },
  { value: '85151501', label: 'Food hygiene control services' },
  { value: '85151502', label: 'Food contamination control services' },
  {
    value: '85151503',
    label: 'Food preservation management or control services'
  },
  { value: '85151600', label: 'Nutrition issues' },
  { value: '85151601', label: 'Nutrition programming services' },
  { value: '85151604', label: 'Nutrition project evaluation' },
  { value: '85151606', label: 'Nutrient deficiency control programs' },
  { value: '85151700', label: 'Food policy planning and aid' },
  { value: '85151701', label: 'Food standards' },
  {
    value: '85151702',
    label: 'Food aid global information or early warning systems services'
  },
  { value: '85151703', label: 'Assessment of emergency food requirements' },
  { value: '85151704', label: 'National food intervention policy or programs' },
  { value: '85151705', label: 'Evaluation of food aid nutritional impact' },
  {
    value: '86101500',
    label:
      'Agriculture and forestry and other natural resources training services'
  },
  { value: '86101501', label: 'Agro industry vocational training' },
  { value: '86101502', label: 'Dairy industry vocational training' },
  { value: '86101503', label: 'Meat industry vocational training' },
  { value: '86101504', label: 'Agriculture vocational training services' },
  {
    value: '86101505',
    label: 'Rural youth or farmers vocational training services'
  },
  { value: '86101506', label: 'Forestry vocational training services' },
  { value: '86101507', label: 'Fishery vocational training services' },
  { value: '86101508', label: 'Environmental vocational training services' },
  {
    value: '86101509',
    label: 'Natural resources vocational training services'
  },
  { value: '86101600', label: 'Scientific vocational training services' },
  { value: '86101601', label: 'Computer vocational training services' },
  { value: '86101602', label: 'Energy related vocational training services' },
  { value: '86101603', label: 'Chemistry vocational training services' },
  { value: '86101604', label: 'Biology vocational training services' },
  { value: '86101605', label: 'Medical vocational training services' },
  { value: '86101606', label: 'Electronics vocational training services' },
  {
    value: '86101607',
    label: 'Telecommunications vocational training services'
  },
  { value: '86101608', label: 'Hydraulics vocational training services' },
  { value: '86101609', label: 'Industrial vocational training services' },
  { value: '86101610', label: 'Engineering vocational training services' },
  { value: '86101700', label: 'Non scientific vocational training services' },
  { value: '86101701', label: 'Communications vocational training services' },
  { value: '86101702', label: 'Tourism related training' },
  { value: '86101703', label: 'Library or documentation training' },
  { value: '86101704', label: 'Procurement or supply chain training' },
  { value: '86101705', label: 'Clerical training' },
  {
    value: '86101706',
    label: 'Health assistance vocational training services'
  },
  { value: '86101708', label: 'Literacy services' },
  { value: '86101709', label: 'Safety training services' },
  { value: '86101710', label: 'Teacher training services' },
  { value: '86101711', label: 'Fire fighting training services' },
  { value: '86101712', label: 'Handcrafts vocational training services' },
  { value: '86101713', label: 'Law vocational training services' },
  { value: '86101714', label: 'Law enforcement vocational training services' },
  {
    value: '86101715',
    label: 'Road or rail transportation vocational training services'
  },
  { value: '86101716', label: 'Shipping vocational training services' },
  { value: '86101800', label: 'In service training and manpower development' },
  { value: '86101802', label: 'Re training or refreshing training services' },
  { value: '86101803', label: 'Vocational rehabilitation services' },
  { value: '86101806', label: 'Health sector manpower development' },
  { value: '86111500', label: 'Distance learning services' },
  { value: '86111501', label: 'Distance learning guidance services' },
  { value: '86111502', label: 'Distance teaching services' },
  { value: '86111503', label: 'Certificated distance learning services' },
  { value: '86111504', label: 'Non certificated distance learning services' },
  { value: '86111505', label: 'Distance learning assessment services' },
  { value: '86111600', label: 'Adult education' },
  { value: '86111601', label: 'Evening courses' },
  { value: '86111602', label: 'Part time adult education services' },
  { value: '86111603', label: 'Parent education' },
  { value: '86111700', label: 'Language schooling' },
  { value: '86111701', label: 'Conversational foreign language instruction' },
  { value: '86111702', label: 'Foreign language immersion instruction' },
  { value: '86111800', label: 'Educational exchanges' },
  { value: '86111801', label: 'Educational exchanges between universities' },
  { value: '86111802', label: 'Educational exchanges between schools' },
  { value: '86121500', label: 'Elementary and secondary schools' },
  { value: '86121600', label: 'Junior colleges' },
  { value: '86121601', label: 'Community colleges' },
  { value: '86121602', label: 'Technical institutes' },
  { value: '86121700', label: 'University and colleges' },
  { value: '86121701', label: 'Undergraduate programs' },
  { value: '86121702', label: 'Postgraduate programs' },
  { value: '86121800', label: 'Professional schools' },
  { value: '86121802', label: 'Theological seminaries' },
  { value: '86121803', label: 'Technical professional schools' },
  { value: '86121804', label: 'Non technical professional schools' },
  { value: '86131500', label: 'Fine arts' },
  { value: '86131501', label: 'Theater studies' },
  { value: '86131502', label: 'Painting' },
  { value: '86131503', label: 'Sculpture' },
  { value: '86131504', label: 'Media studies' },
  { value: '86131600', label: 'Music and drama' },
  { value: '86131601', label: 'Music schools' },
  { value: '86131602', label: 'Dance education' },
  { value: '86131603', label: 'Drama studies' },
  { value: '86131700', label: 'Driving and flying and sailing' },
  { value: '86131701', label: 'Vehicle driving schools services' },
  { value: '86131800', label: 'Military education' },
  { value: '86131803', label: 'Military police training' },
  { value: '86131900', label: 'Schools for people with disabilities' },
  { value: '86141500', label: 'Educational guidance services' },
  { value: '86141501', label: 'Educational advisory services' },
  { value: '86141600', label: 'Students organizations' },
  { value: '86141700', label: 'Educational technology' },
  { value: '86141702', label: 'Audio visual technology' },
  { value: '86141703', label: 'Computer programmed instruction' },
  { value: '86141704', label: 'Library or documentation services' },
  { value: '90101500', label: 'Eating and drinking establishments' },
  { value: '90101501', label: 'Restaurants' },
  { value: '90101502', label: 'Bars' },
  { value: '90101503', label: 'Fast food establishments' },
  { value: '90101504', label: 'On street food vendors' },
  { value: '90101600', label: 'Banquet and catering services' },
  { value: '90101601', label: 'Banquet facilities' },
  { value: '90101602', label: 'Party tent services' },
  { value: '90101603', label: 'Catering services' },
  { value: '90101604', label: 'Construction or work site catering services' },
  { value: '90101700', label: 'Cafeteria services' },
  { value: '90101701', label: 'On site cafeteria management' },
  { value: '90101800', label: 'Carryout and takeaway services' },
  { value: '90101801', label: 'Professionally prepared carryout meals' },
  { value: '90101802', label: 'Delivered meals services' },
  { value: '90111500', label: 'Hotels and motels and inns' },
  { value: '90111501', label: 'Hotels' },
  { value: '90111502', label: 'Lodges or resorts' },
  { value: '90111503', label: 'Bed and breakfast inns' },
  { value: '90111504', label: 'Cottage rental services' },
  { value: '90111600', label: 'Meeting facilities' },
  { value: '90111601', label: 'Conference centers' },
  { value: '90111602', label: 'Videoconferencing facilities' },
  { value: '90111603', label: 'Meeting or banquet rooms' },
  { value: '90111700', label: 'Camping and wilderness facilities' },
  { value: '90111701', label: 'Campsites' },
  { value: '90111702', label: 'Government owned parks' },
  { value: '90111703', label: 'Recreational vehicle campsite facilities' },
  { value: '90121500', label: 'Travel agents' },
  { value: '90121502', label: 'Travel agencies' },
  { value: '90121600', label: 'Travel document assistance' },
  { value: '90121601', label: 'Passport services' },
  { value: '90121602', label: 'Visa or auxiliary document services' },
  { value: '90121700', label: 'Guides and interpreters' },
  { value: '91101500', label: 'Spa and workout and rejuvenation facilities' },
  { value: '91101501', label: 'Health or fitness clubs' },
  { value: '91111500', label: 'Laundering services' },
  { value: '91111502', label: 'Laundry services' },
  { value: '91111600', label: 'Household assistance and care' },
  { value: '91111700', label: 'Consumer buying and bartering services' },
  { value: '91111800', label: 'Personal item care and storage' },
  { value: '91111900', label: 'Personal care services' },
  { value: '92101500', label: 'Police services' },
  { value: '92101501', label: 'Policing services' },
  {
    value: '92101502',
    label: 'Special weapons and tactics SWAT or riot teams'
  },
  { value: '92101503', label: 'Community outreach programs' },
  { value: '92101504', label: 'Crime deterrence programs' },
  { value: '92101600', label: 'Fire services' },
  { value: '92101601', label: 'Municipal or national firefighting services' },
  { value: '92101602', label: 'Volunteer fire department services' },
  { value: '92101603', label: 'Fire prevention services' },
  { value: '92101604', label: 'Forest or wilderness firefighting services' },
  { value: '92101700', label: 'Jail and prison system' },
  { value: '92101701', label: 'Jail or prison or penitentiary services' },
  { value: '92101702', label: 'Youth camps or facilities services' },
  { value: '92101703', label: 'Half way house services' },
  { value: '92101704', label: 'Mentally impaired criminal facilities' },
  { value: '92101800', label: 'Court system' },
  { value: '92101900', label: 'Rescue services' },
  { value: '92101901', label: 'Search and rescue teams' },
  { value: '92101902', label: 'Ambulance services' },
  { value: '92101903', label: 'Life saving helicopter services' },
  { value: '92101904', label: 'Lifeguard services for pool or beach' },
  {
    value: '92111500',
    label: 'Maintenance of international peace and security'
  },
  {
    value: '92111501',
    label: 'Dispute mediation or conciliation or negotiation or settlement'
  },
  { value: '92111502', label: 'Peace keeping operations' },
  { value: '92111503', label: 'Cease fire agreements or truce supervision' },
  { value: '92111504', label: 'Fact finding missions' },
  { value: '92111505', label: 'War prevention strategies' },
  { value: '92111506', label: 'Counterterrorism' },
  { value: '92111507', label: 'Embargoes' },
  { value: '92111600', label: 'Disarmament' },
  { value: '92111601', label: 'Arms limitations' },
  { value: '92111602', label: 'Conventional arms disarmament' },
  { value: '92111603', label: 'Nuclear freezes or disarmament' },
  { value: '92111604', label: 'Weapons destruction' },
  { value: '92111605', label: 'Disarmament negotiations or agreements' },
  { value: '92111606', label: 'Mutual or balanced force reductions' },
  { value: '92111700', label: 'Military science and research' },
  { value: '92111800', label: 'Military personnel and discipline' },
  { value: '92111900', label: 'Military policy' },
  { value: '92112000', label: 'Military zones' },
  { value: '92112100', label: 'Nuclear warfare' },
  { value: '92112200', label: 'Military tactics' },
  { value: '92112300', label: 'Military bases' },
  { value: '92112400', label: 'Armed conflicts and incidents' },
  { value: '92121500', label: 'Guard services' },
  { value: '92121502', label: 'Burglary protection services' },
  { value: '92121503', label: 'Guard dog rental' },
  { value: '92121504', label: 'Security guard services' },
  { value: '92121600', label: 'Detective services' },
  { value: '92121601', label: 'Detective agencies' },
  { value: '92121602', label: 'Fingerprint services' },
  { value: '92121603', label: 'Lie detection services' },
  { value: '92121604', label: 'Private investigation services' },
  { value: '92121700', label: 'Security systems services' },
  {
    value: '92121701',
    label: 'Surveillance or alarm maintenance or monitoring'
  },
  { value: '92121702', label: 'Fire alarm maintenance or monitoring' },
  { value: '93101500', label: 'Political bodies' },
  { value: '93101504', label: 'Political legislature services' },
  { value: '93101600', label: 'Political officials' },
  { value: '93101601', label: 'Cabinet officers services' },
  { value: '93101602', label: 'Governors services' },
  { value: '93101603', label: 'Heads of states services' },
  { value: '93101604', label: 'Presidential services' },
  { value: '93101605', label: 'Prime ministers services' },
  { value: '93101607', label: 'Statesmen services' },
  { value: '93101608', label: 'Parliament members services' },
  { value: '93101700', label: 'Legislative bodies and practice' },
  { value: '93101701', label: 'National council services' },
  { value: '93101702', label: 'Corporate states' },
  { value: '93101703', label: 'Bill drafting services' },
  { value: '93101704', label: 'Legislative hearings services' },
  { value: '93101705', label: 'Intelligence services' },
  { value: '93101706', label: 'Legislators services' },
  { value: '93101707', label: 'Parliamentary practice services' },
  { value: '93111500', label: 'Political movements' },
  { value: '93111501', label: 'Extremist movements' },
  { value: '93111502', label: 'Peace movements' },
  { value: '93111503', label: 'Protest movements' },
  { value: '93111504', label: 'Underground movements' },
  { value: '93111505', label: 'Student movements' },
  { value: '93111506', label: 'Peasant movements' },
  { value: '93111507', label: 'Opposition movements' },
  { value: '93111600', label: 'Political representation and participation' },
  { value: '93111601', label: 'Political representation' },
  { value: '93111602', label: 'Political participation' },
  { value: '93111603', label: 'Proportional representation' },
  { value: '93111604', label: 'Vote catcher services' },
  {
    value: '93111605',
    label: 'Voter registration or counting or analysis or scrutiny services'
  },
  {
    value: '93111606',
    label: 'Pressure groups representation or participation services'
  },
  { value: '93111607', label: 'Election analysis services' },
  { value: '93111608', label: 'Election organization services' },
  { value: '93121500', label: 'Diplomacy' },
  { value: '93121501', label: 'Diplomatic services' },
  { value: '93121502', label: 'Consular services' },
  { value: '93121503', label: 'Diplomats security services' },
  { value: '93121504', label: 'Diplomatic privileges or immunities services' },
  { value: '93121505', label: 'State immunities services' },
  { value: '93121506', label: 'Embassies or Ambassadors services' },
  { value: '93121507', label: 'State visits organization services' },
  { value: '93121508', label: 'International law prescription services' },
  {
    value: '93121509',
    label: 'International law promotion or recognition services'
  },
  { value: '93121600', label: 'International relations and cooperation' },
  { value: '93121601', label: 'Multilateral cooperation services' },
  { value: '93121604', label: 'International economic cooperation services' },
  { value: '93121605', label: 'North south cooperation services' },
  { value: '93121606', label: 'East west cooperation services' },
  { value: '93121607', label: 'International cooperation services' },
  { value: '93121608', label: 'Non governmental liaison services' },
  { value: '93121609', label: 'Non aligned countries cooperation' },
  { value: '93121610', label: 'Aligned countries cooperation' },
  { value: '93121612', label: 'Peace treaties cooperation' },
  {
    value: '93121613',
    label: 'Treaty signature or accessions or rectification services'
  },
  {
    value: '93121614',
    label: 'International watercourse cooperation services'
  },
  {
    value: '93121615',
    label: 'Territorial claims or negotiations third party services'
  },
  { value: '93121700', label: 'International organizations' },
  { value: '93121701', label: 'System of organizations services' },
  { value: '93121703', label: 'Economic or social council services' },
  { value: '93121704', label: 'Secretariat services' },
  { value: '93121706', label: 'General assembly services' },
  { value: '93121707', label: 'International court of justice services' },
  {
    value: '93121708',
    label: 'International political organizations services'
  },
  { value: '93121709', label: 'International charity organizations services' },
  {
    value: '93121710',
    label: 'International human relief organizations services'
  },
  { value: '93131500', label: 'Refugee programs' },
  { value: '93131501', label: 'Protection of human rights services' },
  { value: '93131502', label: 'Promotion of human rights services' },
  {
    value: '93131503',
    label: 'Human rights education or information dissemination services'
  },
  { value: '93131504', label: 'Refugee emergency assistance services' },
  { value: '93131505', label: 'Refugee camps services' },
  {
    value: '93131506',
    label: 'Refugee resettlements or repatriation services'
  },
  { value: '93131507', label: 'Displaced persons assistance services' },
  {
    value: '93131600',
    label: 'Food and nutrition policy planning and programs'
  },
  { value: '93131601', label: 'Hunger eradication programs' },
  { value: '93131602', label: 'Emergency food supply services' },
  { value: '93131603', label: 'World food program services' },
  { value: '93131609', label: 'Food aid policies or programs' },
  { value: '93131610', label: 'Food planning services' },
  { value: '93131611', label: 'Food security services' },
  { value: '93131700', label: 'Health programs' },
  { value: '93131702', label: 'Sanitation programs' },
  { value: '93131703', label: 'Research programs' },
  { value: '93131704', label: 'Disease prevention or control services' },
  { value: '93131705', label: 'Drug abuse prevention or control programs' },
  { value: '93131800', label: 'Disaster preparedness and relief' },
  { value: '93131801', label: 'Disaster warning services' },
  { value: '93131802', label: 'Disaster preparedness response services' },
  { value: '93131803', label: 'Emergency housing services' },
  { value: '93141500', label: 'Social development and services' },
  { value: '93141501', label: 'Social policy services' },
  { value: '93141502', label: 'Social security legislation services' },
  { value: '93141503', label: 'Social planning services' },
  { value: '93141506', label: 'Social welfare services' },
  { value: '93141507', label: 'Social work administration services' },
  { value: '93141508', label: 'Voluntary service management' },
  {
    value: '93141509',
    label: 'Social problems analysis or management services'
  },
  { value: '93141510', label: 'Social structure studies or related services' },
  { value: '93141511', label: 'Social groups studies or related services' },
  { value: '93141513', label: 'Social justice or legislation services' },
  { value: '93141600', label: 'Population' },
  { value: '93141601', label: 'Population census services' },
  { value: '93141602', label: 'Population sample surveys services' },
  { value: '93141603', label: 'Birth reporting or control services' },
  { value: '93141604', label: 'Population control services' },
  { value: '93141605', label: 'Population trends or projections services' },
  { value: '93141608', label: 'Population distribution or analysis services' },
  { value: '93141609', label: 'Population composition analysis services' },
  { value: '93141610', label: 'Demographic studies' },
  { value: '93141612', label: 'Family planning programs or services' },
  { value: '93141700', label: 'Culture' },
  { value: '93141701', label: 'Cultural events organizations' },
  { value: '93141702', label: 'Culture promotional services' },
  { value: '93141706', label: 'Minorities protection services' },
  {
    value: '93141707',
    label: 'Cultural heritage preservation or promotion services'
  },
  { value: '93141709', label: 'Cultural policy services' },
  { value: '93141710', label: 'Archaic or indigenous language services' },
  { value: '93141711', label: 'Traditional handcrafts promotion services' },
  {
    value: '93141712',
    label: 'Protection of intellectual or cultural property services'
  },
  {
    value: '93141713',
    label: 'Historic sites or monuments protection services'
  },
  { value: '93141800', label: 'Employment' },
  { value: '93141801', label: 'Employment promotion or planning services' },
  { value: '93141802', label: 'Recruitment services' },
  { value: '93141803', label: 'International labor standards services' },
  { value: '93141804', label: 'International labor registration services' },
  { value: '93141805', label: 'Unemployment services' },
  { value: '93141806', label: 'Employment statistics or forecasting services' },
  { value: '93141807', label: 'Work time arrangements' },
  { value: '93141808', label: 'Occupational health or safety services' },
  { value: '93141810', label: 'Career development services' },
  { value: '93141811', label: 'Promotional services' },
  { value: '93141812', label: 'Labor inspection services' },
  { value: '93141813', label: 'Work council services' },
  { value: '93141814', label: 'International labor services' },
  { value: '93141900', label: 'Rural development' },
  { value: '93141902', label: 'Rural investment services' },
  { value: '93141905', label: 'Agricultural research services' },
  { value: '93141906', label: 'Farmers or peasants organizations services' },
  {
    value: '93141907',
    label: 'Womens services in agricultural production or rural development'
  },
  { value: '93141908', label: 'Agrarian reform or land settlement services' },
  { value: '93141909', label: 'Land administration services' },
  { value: '93142000', label: 'Urban development' },
  { value: '93142001', label: 'Urban development planning services' },
  { value: '93142002', label: 'Urban land administration services' },
  { value: '93142003', label: 'Urban investment programming services' },
  { value: '93142004', label: 'Slum redevelopment services' },
  { value: '93142005', label: 'Urban lighting services' },
  {
    value: '93142006',
    label: 'Urban development control or regulations services'
  },
  {
    value: '93142007',
    label: 'Urban building standards or regulations services'
  },
  { value: '93142008', label: 'Urban community services' },
  {
    value: '93142009',
    label: 'Urban project or program administration or management services'
  },
  { value: '93142100', label: 'Regional development' },
  { value: '93142101', label: 'Regional development planning services' },
  { value: '93142102', label: 'Economic cooperation services' },
  { value: '93142103', label: 'Technical cooperation services' },
  { value: '93142104', label: 'Sectoral planning services' },
  { value: '93151500', label: 'Public administration' },
  {
    value: '93151501',
    label: 'Public enterprises management or financial services'
  },
  {
    value: '93151502',
    label: 'Public enterprises information or control systems services'
  },
  { value: '93151503', label: 'Privatization programs' },
  { value: '93151504', label: 'Administrative reform services' },
  { value: '93151505', label: 'Administrative agencies services' },
  { value: '93151506', label: 'Administrative economic council services' },
  { value: '93151507', label: 'Administrative procedures or services' },
  { value: '93151508', label: 'Government departments services' },
  { value: '93151509', label: 'Government information services' },
  {
    value: '93151510',
    label: 'Administrative fees or tax collection services'
  },
  {
    value: '93151511',
    label: 'Administrative acts ratification or implementation services'
  },
  { value: '93151512', label: 'Public institutions services' },
  { value: '93151513', label: 'Multinational public corporations services' },
  { value: '93151514', label: 'Ombudsman services' },
  { value: '93151515', label: 'National planning services' },
  { value: '93151600', label: 'Public finance' },
  { value: '93151601', label: 'Program budgeting services' },
  { value: '93151602', label: 'Government budgeting services' },
  { value: '93151603', label: 'Budget or public investment management' },
  { value: '93151605', label: 'Government finance services' },
  { value: '93151606', label: 'Government accounting services' },
  { value: '93151607', label: 'Government auditing services' },
  { value: '93151608', label: 'Government or central bank services' },
  { value: '93151610', label: 'Tax collection services' },
  { value: '93151611', label: 'Subsidies' },
  { value: '93151700', label: 'Currency' },
  { value: '93161500', label: 'Income tax' },
  { value: '93161501', label: 'National income tax' },
  { value: '93161502', label: 'Municipal income tax' },
  { value: '93161503', label: 'Capital gains tax' },
  { value: '93161504', label: 'Excess profits tax' },
  { value: '93161600', label: 'Taxes other than income tax' },
  { value: '93161601', label: 'Property tax' },
  { value: '93161602', label: 'Land tax' },
  { value: '93161603', label: 'Value added tax VAT' },
  { value: '93161604', label: 'Payroll tax' },
  { value: '93161605', label: 'Sales tax' },
  { value: '93161606', label: 'Social security tax' },
  { value: '93161607', label: 'Inheritance or transfer tax' },
  { value: '93161700', label: 'Tax administration' },
  { value: '93161701', label: 'Tax collation' },
  { value: '93161702', label: 'Tax incentives' },
  { value: '93161703', label: 'Tax systems' },
  { value: '93161704', label: 'Tax revenue administration' },
  { value: '93161800', label: 'Taxation issues' },
  { value: '93161801', label: 'Tax reform' },
  { value: '93161802', label: 'Taxation policy' },
  { value: '93161804', label: 'Investment tax credit' },
  { value: '93161805', label: 'Tax deductions' },
  { value: '93161808', label: 'Tax returns' },
  { value: '93171501', label: 'Trade agreements' },
  { value: '93171502', label: 'Trade negotiations' },
  { value: '93171503', label: 'Formulation of national commodity policies' },
  { value: '93171504', label: 'Development of small scale industries' },
  { value: '93171600', label: 'International trade' },
  { value: '93171601', label: 'International commodity agreements' },
  { value: '93171602', label: 'Export policy' },
  { value: '93171603', label: 'Global trade policies or procedures' },
  { value: '93171604', label: 'Bilateral trade agreements' },
  { value: '93171700', label: 'Customs administration and compliance' },
  { value: '93171800', label: 'Trade analysis' },
  { value: '93171801', label: 'Trade projections' },
  { value: '93171802', label: 'Balance of trade projections' },
  { value: '93171803', label: 'Trade statistics' },
  { value: '94131500', label: 'Non governmental organizations' },
  { value: '94131501', label: 'Environmental non governmental services' },
  { value: '94131600', label: 'Charity organizations' },
  { value: '94131601', label: 'Charity organizations shelter services' },
  { value: '94131602', label: 'Food relief services' },
  { value: '94131603', label: 'Legal assistance services' },
  { value: '94131604', label: 'Resource mobilization services' },
  { value: '94131605', label: 'International aid assistance services' },
  { value: '94131700', label: 'Green associations' },
  { value: '94131701', label: 'Radical green associations' },
  { value: '94131702', label: 'Ecofeminists associations' },
  { value: '94131703', label: 'Ecological political organizations' },
  { value: '94131704', label: 'Green activists movements or services' },
  { value: '94131800', label: 'Movements' },
  {
    value: '94131801',
    label: 'Gay or lesbian or bisexual or transgender movements'
  },
  { value: '94131802', label: 'Anti racism movements' },
  { value: '94131803', label: 'Women liberation movements' },
  { value: '94131804', label: 'Fraternal associations' },
  {
    value: '94131805',
    label: 'Ethnic minorities cultural preservation services'
  },
  { value: '94131900', label: 'Animal protection associations' },
  { value: '94131901', label: 'Animal liberation movements' },
  { value: '94131902', label: 'Endangered species protection associations' },
  { value: '94131903', label: 'Threatened animals protection associations' },
  {
    value: '94132000',
    label: 'Human rights advocacy and defense associations'
  },
  { value: '94132001', label: 'Children rights defense services' },
  { value: '94132004', label: 'Freedom of speech defense associations' },
  { value: '25101905', label: 'All terrain vehicles tracked or wheeled' },
  { value: '44121804', label: 'Erasers' },
  { value: '43201803', label: 'Hard disk drives' },
  { value: '43211903', label: 'Touch screen monitors' },
  { value: '44101705', label: 'Office machine trays or feeders' },
  { value: '44103004', label: 'Fusers' },
  { value: '44122013', label: 'Report covers' },
  {
    value: '60120000',
    label: 'Arts and crafts equipment and accessories and supplies'
  },
  { value: '56101700', label: 'Office furniture' },
  { value: '56101701', label: 'Credenzas' },
  { value: '56101702', label: 'Filing cabinets or accesories' },
  { value: '13111201', label: 'Polyethylene films' },
  { value: '24141504', label: 'Tamper proof or security seals' },
  { value: '43211701', label: 'Bar code reader equipment' },
  { value: '43211710', label: 'Radio frequency identification devices' },
  { value: '11162114', label: 'Hook and loop fabrics or tapes' },
  { value: '24101610', label: 'Manipulators' },
  { value: '24102107', label: 'Cartoning machinery' },
  { value: '45101509', label: 'Ultraviolet UV rotary printer' },
  { value: '45101510', label: 'Flexographic printer' },
  {
    value: '45101511',
    label: 'Inkjet printer for commercial printing applications'
  },
  {
    value: '45101512',
    label: 'Thermal transfer printer for commercial printing applications'
  },
  { value: '45101513', label: 'Hot stamp printer' },
  { value: '45101514', label: 'Pad printer' },
  { value: '45101515', label: 'Core printer' },
  {
    value: '23152000',
    label: 'Web handling and control machinery and equipment and supplies'
  },
  { value: '43211601', label: 'Computer switch boxes' },
  { value: '53102505', label: 'Umbrellas' },
  { value: '11162115', label: 'Elastic braid' },
  { value: '12140000', label: 'Elements and gases' },
  { value: '12141500', label: 'Earth metals' },
  { value: '12141600', label: 'Rare earth metals' },
  { value: '12141700', label: 'Transition metals' },
  { value: '12141900', label: 'Non metals and pure and elemental gases' },
  { value: '12142000', label: 'Noble gases' },
  { value: '12142100', label: 'Industrial use gases' },
  { value: '12160000', label: 'Additives' },
  { value: '12161500', label: 'Indicators and Reagents' },
  { value: '12161700', label: 'Buffers' },
  { value: '12161800', label: 'Colloids' },
  { value: '12161804', label: 'Emulsions' },
  { value: '12161900', label: 'Surfactants' },
  { value: '12162000', label: 'Plasticizers' },
  { value: '12162100', label: 'Flame retardants' },
  { value: '12162200', label: 'Anti oxidants' },
  { value: '12170000', label: 'Colorants' },
  { value: '12171500', label: 'Dyes' },
  { value: '12171600', label: 'Pigments' },
  { value: '12171700', label: 'Color compounds and dispersions' },
  { value: '12180000', label: 'Waxes and oils' },
  { value: '12181600', label: 'Oils' },
  { value: '12190000', label: 'Solvents' },
  { value: '12191500', label: 'Hydrocarbonated solvents' },
  { value: '12352500', label: 'Fixatives' },
  { value: '12350000', label: 'Compounds and mixtures' },
  { value: '12142200', label: 'Isotopes' },
  { value: '12352200', label: 'Biochemicals' },
  { value: '24101611', label: 'Slings' },
  { value: '24101612', label: 'Jacks' },
  { value: '24112003', label: 'Non metallic bins' },
  { value: '24112004', label: 'Metallic bins' },
  { value: '24112005', label: 'Metallic baskets' },
  { value: '24112006', label: 'Non metallic baskets' },
  { value: '24112204', label: 'Metallic pails' },
  { value: '25173107', label: 'Vehicular global positioning system GPS' },
  {
    value: '25200000',
    label: 'Aerospace systems and components and equipment'
  },
  { value: '25201700', label: 'Flight communications related systems' },
  { value: '25201800', label: 'Aircraft master control systems' },
  { value: '25201900', label: 'Aircraft emergency systems' },
  { value: '25202100', label: 'Flight instrumentation' },
  { value: '25202300', label: 'Aircraft passenger restraints' },
  { value: '31163100', label: 'Connectors' },
  { value: '31270000', label: 'Machine made parts' },
  { value: '41122000', label: 'Laboratory or sampling syringes' },
  { value: '41112904', label: 'Complex controlling devices' },
  { value: '44102605', label: 'Dictation machines' },
  { value: '47131805', label: 'General purpose cleaners' },
  { value: '53121705', label: 'Equipment cases' },
  { value: '53140000', label: 'Sewing supplies and accessories' },
  { value: '53141600', label: 'Miscellaneous sewing supplies' },
  { value: '53141602', label: 'Sewing kits' },
  { value: '12131706', label: 'Matches' },
  { value: '12131707', label: 'Lighters' },
  { value: '24101508', label: 'Creepers' },
  { value: '24101509', label: 'Wagons' },
  { value: '41111617', label: 'Feeler gauges' },
  { value: '41111713', label: 'Magnifiers' },
  { value: '44121904', label: 'Ink refills' },
  { value: '47121805', label: 'Pressure or steam cleaners' },
  { value: '14111518', label: 'Index cards' },
  { value: '14121605', label: 'Kraft tissue paper' },
  { value: '14122100', label: 'Uncoated base papers' },
  { value: '14122101', label: 'Super calendared kraft paper' },
  { value: '14122102', label: 'Machine finished or glazed kraft paper' },
  { value: '24101613', label: 'Blocks or pulleys' },
  { value: '27112900', label: 'Dispensing tools' },
  { value: '31280000', label: 'Stampings and sheet components' },
  { value: '44121905', label: 'Ink or stamp pads' },
  { value: '52161512', label: 'Loudspeakers' },
  { value: '11162200', label: 'Nonwoven fabrics' },
  { value: '12162300', label: 'Curing agents' },
  { value: '14111519', label: 'Cardstock papers' },
  {
    value: '24141700',
    label: 'Packaging tubes and cores and labels and accessories'
  },
  { value: '26111800', label: 'Drive components' },
  { value: '26111801', label: 'V belts' },
  { value: '27113000', label: 'Brushes' },
  { value: '27120000', label: 'Hydraulic machinery and equipment' },
  { value: '27121500', label: 'Hydraulic presses' },
  { value: '27130000', label: 'Pneumatic machinery and equipment' },
  { value: '27131500', label: 'Pneumatic tools' },
  { value: '27131501', label: 'Pneumatic impact wrenches' },
  { value: '41111714', label: 'Loupes' },
  { value: '41111715', label: 'Telescopes' },
  { value: '43233403', label: 'Data compression software' },
  {
    value: '46182004',
    label:
      'Respiration air supplying self contained breathing apparatus or accessories'
  },
  { value: '11162116', label: 'Burlap cloth' },
  { value: '24101615', label: 'Loading ramps' },
  { value: '25172406', label: 'Fuel tanks' },
  { value: '25172607', label: 'Vehicle side panels' },
  { value: '25191700', label: 'Vehicle servicing equipment' },
  { value: '31152000', label: 'Security wire' },
  { value: '43221500', label: 'Call management systems or accessories' },
  { value: '48101816', label: 'Commercial use cutlery pouch' },
  { value: '60131105', label: 'Whistle' },
  { value: '80111611', label: 'Temporary warehouse staff' },
  { value: '80111612', label: 'Temporary drivers' },
  { value: '83101508', label: 'Town water' },
  { value: '83101509', label: 'Service water' },
  { value: '83101605', label: 'Gas facility charge' },
  { value: '14121503', label: 'Cardboard' },
  { value: '14121805', label: 'Latex treated coated paper' },
  { value: '14122103', label: 'Non treated uncoated paper' },
  { value: '14122104', label: 'Non treated crepe paper' },
  { value: '14122105', label: 'Latex treated crepe paper' },
  { value: '14122106', label: 'Latex treated uncoated paper' },
  { value: '44101503', label: 'Multifunction machines' },
  { value: '56000000', label: 'Furniture and Furnishings' },
  { value: '56110000', label: 'Commercial and industrial furniture' },
  { value: '56111500', label: 'Workstations and office packages' },
  { value: '56111501', label: 'Modular reception office packages' },
  { value: '56111600', label: 'Panel systems' },
  { value: '56111601', label: 'Screens for panel systems' },
  { value: '56111700', label: 'Casegood and non modular systems' },
  { value: '56111800', label: 'Freestanding furniture' },
  { value: '56111900', label: 'Industrial furniture' },
  { value: '56112000', label: 'Computer support furniture' },
  { value: '56112100', label: 'Seating' },
  { value: '80101507', label: 'Information technology consultation services' },
  { value: '80121609', label: 'Legal Research Services' },
  { value: '81111810', label: 'Software coding service' },
  { value: '81112003', label: 'Data center services' },
  { value: '47131807', label: 'Bleaches' },
  { value: '78131602', label: 'File archive storage' },
  { value: '40180000', label: 'Tubes tubing and tube fittings' },
  { value: '13111220', label: 'Silicone coated films' },
  { value: '14111520', label: 'Blotter paper' },
  { value: '14121808', label: 'Freezer paper' },
  { value: '15111506', label: 'Acetylene' },
  { value: '23152100', label: 'Separation machinery and equipment' },
  { value: '23152200', label: 'Manufacturing tables and stands' },
  { value: '24131606', label: 'Plate freezers' },
  { value: '24131607', label: 'Blast freezers' },
  { value: '30171509', label: 'Revolving doors' },
  { value: '39121011', label: 'Uninterruptible power supply UPS' },
  { value: '40151700', label: 'Pump parts and accessories' },
  { value: '41111716', label: 'Borescope inspection equipment' },
  { value: '41111919', label: 'Detection apparatus for non metallic objects' },
  { value: '41111920', label: 'Coordinate measuring machines CMM' },
  { value: '41112504', label: 'Water meters' },
  { value: '41113714', label: 'Differentiator' },
  { value: '43232605', label: 'Analytical or scientific software' },
  { value: '43222611', label: 'Network channel or data service units' },
  { value: '43201402', label: 'Memory module cards' },
  { value: '43211608', label: 'Encoder decoder equipment' },
  { value: '43211707', label: 'Light stylus' },
  { value: '43211709', label: 'Pressure stylus' },
  { value: '43211802', label: 'Mouse pads' },
  { value: '43212000', label: 'Computer display accessories' },
  { value: '43211704', label: 'Currency recognition equipment' },
  { value: '43211801', label: 'Computer data input device covers' },
  { value: '44101603', label: 'Paper shredding machines or accessories' },
  {
    value: '44103200',
    label: 'Office time recording machines and accessories'
  },
  { value: '44103201', label: 'Time card machines' },
  { value: '44103202', label: 'Time stamping machines' },
  { value: '44121507', label: 'Catalog or clasp envelopes' },
  { value: '44121711', label: 'Felt pen' },
  { value: '45121506', label: 'Video conference cameras' },
  { value: '46182103', label: 'Grounding hardware' },
  { value: '46182104', label: 'Anti static floor mats' },
  { value: '47131808', label: 'Dry germicidal' },
  { value: '47131810', label: 'Dishwashing products' },
  { value: '47131811', label: 'Laundry products' },
  { value: '48101527', label: 'Barbecues' },
  { value: '48101528', label: 'Commercial use crepe machines' },
  { value: '48101529', label: 'Pressure cookers or pressure fryers' },
  { value: '48101530', label: 'Commercial use rice cookers' },
  { value: '48101531', label: 'Commercial salmon poachers or kettles' },
  { value: '48101613', label: 'Commercial use dough machines' },
  { value: '48101614', label: 'Commercial use icing sets or bags' },
  { value: '48101712', label: 'Cup dispenser' },
  { value: '48101713', label: 'Cocktail shakers or accessories' },
  { value: '48101817', label: 'Cake decorating equipment or moulds' },
  { value: '48102105', label: 'Glass chilling equipment' },
  { value: '50192900', label: 'Plain pasta and noodles' },
  { value: '50171552', label: 'Seasoning mix' },
  { value: '50181708', label: 'Baking mixes' },
  { value: '50201713', label: 'Tea bags' },
  { value: '56101520', label: 'Lockers' },
  { value: '52161514', label: 'Headphones' },
  { value: '53131621', label: 'Nail clippers' },
  { value: '53131622', label: 'Condoms' },
  { value: '55101516', label: 'Operation or instruction manuals' },
  { value: '55101517', label: 'Clearance or dimensional drawings' },
  { value: '55101518', label: 'Technical diagrams or drawings' },
  { value: '56101502', label: 'Sofas' },
  { value: '56101503', label: 'Coat racks' },
  { value: '56101504', label: 'Chairs' },
  { value: '56101507', label: 'Bookcases' },
  { value: '56101508', label: 'Mattresses or sleep sets' },
  { value: '56101509', label: 'Dressers or armoires' },
  { value: '56101510', label: 'Partitions' },
  { value: '56101703', label: 'Desks' },
  { value: '56101513', label: 'Cots or accessories' },
  { value: '56101515', label: 'Beds' },
  { value: '56101516', label: 'Chests' },
  { value: '56101518', label: 'Wall racks' },
  { value: '56101519', label: 'Tables' },
  { value: '78101905', label: 'Rail truck transportation' },
  { value: '78131804', label: 'Document storage services' },
  { value: '80101706', label: 'Professional procurement services' },
  { value: '80141603', label: 'Telemarketing' },
  { value: '81111811', label: 'Technical support or help desk services' },
  { value: '81111812', label: 'Computer hardware maintenance support service' },
  { value: '81112004', label: 'Disaster recovery services' },
  { value: '81112106', label: 'Application service providers' },
  {
    value: '82121511',
    label: 'Technical manual or instruction sheet printing'
  },
  { value: '83111505', label: 'Directory assistance services' },
  { value: '10101517', label: 'Camels' },
  { value: '10102000', label: 'Wild animals' },
  { value: '10110000', label: 'Domestic pet products' },
  { value: '10152100', label: 'Residues other than animal feed' },
  { value: '13111300', label: 'Foams' },
  { value: '30152000', label: 'Fencing' },
  { value: '31230000', label: 'Machined raw stock' },
  { value: '31290000', label: 'Machined extrusions' },
  { value: '31300000', label: 'Machined forgings' },
  { value: '31310000', label: 'Fabricated pipe assemblies' },
  { value: '31320000', label: 'Fabricated bar stock assemblies' },
  { value: '31330000', label: 'Fabricated structural assemblies' },
  { value: '31340000', label: 'Fabricated sheet assemblies' },
  { value: '31350000', label: 'Fabricated tube assemblies' },
  { value: '31360000', label: 'Fabricated plate assemblies' },
  { value: '41112101', label: 'Piezo electric crystals' },
  { value: '44121620', label: 'Finger stalls' },
  { value: '47131900', label: 'Absorbents' },
  { value: '47131901', label: 'Absorbent mats' },
  { value: '48120000', label: 'Gambling or wagering equipment' },
  { value: '51191900', label: 'Nutritional therapy products' },
  { value: '78181600', label: 'Panel and paint services' },
  { value: '78180000', label: 'Transportation repair or maintenance services' },
  { value: '78181500', label: 'Vehicle maintenance and repair services' },
  { value: '78181501', label: 'Vehicle body repair or painting service' },
  { value: '81102300', label: 'Aeronautical engineering' },
  { value: '81102301', label: 'Avionics design' },
  { value: '13111203', label: 'Acetate films' },
  { value: '13111204', label: 'Acrylic films' },
  { value: '13111205', label: 'Coextruded films' },
  { value: '13111206', label: 'Flouropolymer films' },
  { value: '13111207', label: 'Metalized films' },
  { value: '13111208', label: 'Nylon films' },
  { value: '13111209', label: 'Polycarbonate films' },
  { value: '13111210', label: 'Polyester films' },
  { value: '13111211', label: 'Polypropylene films' },
  { value: '13111212', label: 'Biaxially orientated polypropylene' },
  { value: '13111213', label: 'Polymide films' },
  { value: '13111214', label: 'Polystyrene films' },
  { value: '13111215', label: 'Flexible polyvinyl chloride film' },
  { value: '13111216', label: 'Rigid polyvinyl chloride film' },
  { value: '13111217', label: 'Ethylene vinyl alcohol film' },
  { value: '13111218', label: 'Polyvinylidene chloride' },
  { value: '56111507', label: 'Modular staff office packages' },
  { value: '56111509', label: 'Modular technical office packages' },
  { value: '56111511', label: 'Modular clerical office packages' },
  { value: '56111513', label: 'Conference or non modular room packages' },
  { value: '56111602', label: 'Storage for panel systems' },
  { value: '56111603', label: 'Organization for panel systems' },
  { value: '56111604', label: 'Work surfaces for panel systems' },
  {
    value: '56111605',
    label: 'Lighting or power or data components for panel systems'
  },
  { value: '56111606', label: 'Parts or accessories panel systems' },
  { value: '73141715', label: 'Industrial sewing services' },
  { value: '73181103', label: 'Wrapping services' },
  { value: '73151800', label: 'Converting services' },
  { value: '73151900', label: 'Industrial printing services' },
  { value: '73152000', label: 'Filling Services' },
  { value: '73180000', label: 'Machining and processing services' },
  { value: '73181100', label: 'Coating services' },
  { value: '23152900', label: 'Packaging machinery' },
  { value: '23152901', label: 'Wrapping machinery' },
  { value: '25101906', label: 'Go cart' },
  { value: '30121605', label: 'Manhole frames with covers' },
  { value: '95141707', label: 'Auditorium' },
  { value: '95120000', label: 'Permanent buildings and structures' },
  {
    value: '95121500',
    label: 'Commercial and entertainment buildings and structures'
  },
  { value: '95121600', label: 'Transport buildings and structures' },
  { value: '95121601', label: 'Steel bridge' },
  { value: '95121602', label: 'Dry dock' },
  { value: '39101800', label: 'Lamp components and accessories' },
  { value: '43211505', label: 'Point of sale POS terminal' },
  { value: '44103109', label: 'Printer or facsimile or photocopier drums' },
  { value: '44103500', label: 'Binding machine supplies' },
  { value: '45112001', label: 'Microfiche or microfilm viewers' },
  { value: '46191607', label: 'Fire breathing apparatus' },
  { value: '47121806', label: 'Mop wringer' },
  { value: '53131505', label: 'Infant soother or pacifier or dummy' },
  { value: '53131624', label: 'Disposable personal wipes' },
  { value: '42142901', label: 'Eyeglasses' },
  { value: '55101519', label: 'Periodicals' },
  { value: '80141604', label: 'Branding of product naming services' },
  { value: '80141605', label: 'Promotional merchandise' },
  { value: '80141606', label: 'Direct marketing fulfillment' },
  { value: '80161505', label: 'Fleet management services' },
  { value: '80161506', label: 'Data archiving services' },
  { value: '81112200', label: 'Software maintenance and support' },
  { value: '81112201', label: 'Maintenance or support fees' },
  { value: '81112202', label: 'Software patches or upgrades' },
  { value: '85171500', label: 'Funeral and associated services' },
  { value: '85171501', label: 'Grave digging' },
  { value: '14111706', label: 'Paper table cloth' },
  { value: '25111511', label: 'Oil or gas crew boat' },
  { value: '25111512', label: 'Oil or gas workboat' },
  { value: '25111513', label: 'Seismic vessel' },
  { value: '26111714', label: 'Zinc air' },
  { value: '41123000', label: 'Laboratory desiccators and desiccants' },
  { value: '41123003', label: 'Desiccants' },
  { value: '41121801', label: 'Laboratory watch glasses' },
  { value: '41121808', label: 'Laboratory burets' },
  { value: '41121805', label: 'Laboratory graduated cylinders' },
  { value: '41111618', label: 'Gage block set' },
  { value: '43232608', label: 'Industrial control software' },
  { value: '43201544', label: 'Interface bus converter or controller' },
  { value: '53102700', label: 'Uniforms' },
  { value: '53102701', label: 'Military uniforms' },
  { value: '53102800', label: 'Swimwear' },
  { value: '72102900', label: 'Facility maintenance and repair services' },
  { value: '72102903', label: 'Snow removal services' },
  { value: '72102902', label: 'Landscaping services' },
  { value: '81111814', label: 'Co location service' },
  { value: '81112005', label: 'Document scanning service' },
  { value: '81112107', label: 'Internet domain names' },
  { value: '83111506', label: 'Conference calling services' },
  { value: '11162117', label: 'Rubber fabrics' },
  { value: '12352300', label: 'Inorganic compounds' },
  { value: '15101605', label: 'Charcoal' },
  { value: '15121900', label: 'Greases' },
  { value: '25111902', label: 'Marine propellers' },
  { value: '26101900', label: 'Internal combustion engine components' },
  { value: '27113100', label: 'Pulling tools' },
  { value: '56101800', label: 'Baby and toddler furniture and accessories' },
  { value: '82111704', label: 'Copywriting' },
  { value: '44122019', label: 'File pockets or accessories' },
  { value: '80141505', label: 'Marketing plans' },
  { value: '80141607', label: 'Events management' },
  { value: '80111505', label: 'Human resources productivity audits' },
  { value: '82111705', label: 'Speech writing' },
  { value: '14121806', label: 'Waxed paper' },
  { value: '47121605', label: 'Floor scrubbers' },
  { value: '47121606', label: 'Carpet sweepers' },
  { value: '53131625', label: 'Hair or beard nets' },
  { value: '46161600', label: 'Water safety' },
  { value: '46161601', label: 'Rope float lines' },
  { value: '47121608', label: 'Floor machine pads' },
  { value: '47121704', label: 'Waste container lids' },
  { value: '12162700', label: 'Fluid loss additives' },
  { value: '12162800', label: 'Friction reducers' },
  { value: '12163100', label: 'Anti sludgers' },
  { value: '12163200', label: 'Anti gas migration agents' },
  { value: '12163600', label: 'Corrosion inhibitors' },
  { value: '12164100', label: 'In situ' },
  { value: '12164300', label: 'Iron controllers' },
  { value: '12164400', label: 'Non emulsifiers' },
  {
    value: '20120000',
    label: 'Oil and gas drilling and exploration equipment'
  },
  { value: '46182314', label: 'Safety sling' },
  { value: '20121400', label: 'Completion tools and equipment' },
  { value: '20121600', label: 'Drilling bits' },
  { value: '20130000', label: 'Oil and gas drilling and operation materials' },
  {
    value: '20140000',
    label: 'Oil and gas operating and production equipment'
  },
  { value: '40142200', label: 'Fluid and gas regulators' },
  { value: '40174700', label: 'Pipe threaded flanges' },
  { value: '40142500', label: 'Traps and strainers' },
  { value: '71112300', label: 'Seismic services' },
  { value: '71120000', label: 'Well drilling and construction services' },
  {
    value: '71130000',
    label: 'Oil and gas extraction and production enhancement services'
  },
  {
    value: '71140000',
    label: 'Oil and gas restoration and reclamation services'
  },
  {
    value: '71150000',
    label: 'Oil and gas data management and processing services'
  },
  { value: '71151100', label: 'Oilfield data management services' },
  { value: '71160000', label: 'Oil and gas well project management services' },
  { value: '71161000', label: 'Oilfield modeling services' },
  {
    value: '73152108',
    label: 'Electrical equipment maintenance and repair service'
  },
  {
    value: '72154501',
    label: 'Heavy equipment maintenance and repair service'
  },
  {
    value: '72154201',
    label: 'Instrument or meter maintenance and repair service'
  },
  {
    value: '73152112',
    label: 'Valve or valve part testing maintenance or repair service'
  },
  { value: '10151521', label: 'Sweet pepper seeds or seedlings' },
  {
    value: '41102421',
    label: 'Temperature cycling chambers or thermal cyclers'
  },
  { value: '41121812', label: 'Laboratory dishes' },
  { value: '41122601', label: 'Microscope slides' },
  { value: '41122502', label: 'Laboratory stoppers' },
  { value: '43191612', label: 'Phone rests' },
  { value: '44102904', label: 'Air compressed spray' },
  { value: '53131626', label: 'Hand sanitizer' },
  { value: '80141705', label: 'Auction services' },
  { value: '83111507', label: 'Call centre bureau services' },
  { value: '84121606', label: 'Remittance processing services' },
  { value: '46161506', label: 'Snow or ice melter' },
  { value: '47131611', label: 'Trash picker' },
  { value: '47131902', label: 'Granular absorbent' },
  { value: '53131627', label: 'Hand cleaner' },
  { value: '47121807', label: 'Drain or toilet plunger' },
  { value: '48101916', label: 'Food service napkin dispensers' },
  { value: '10171700', label: 'Herbicides' },
  { value: '41111921', label: 'Speed sensors' },
  { value: '44112006', label: 'Diaries or refills' },
  { value: '10191703', label: 'Flying insect control traps' },
  { value: '11162300', label: 'Leathers' },
  { value: '25172505', label: 'Bicycle tubes' },
  { value: '25172506', label: 'Bicycle tires' },
  { value: '30181508', label: 'Restroom partitions' },
  { value: '44102905', label: 'Small paper bags of wet absorbing salts' },
  { value: '46161602', label: 'Life rings' },
  { value: '46161603', label: 'Pool alarms' },
  { value: '46161604', label: 'Life vests or preservers' },
  { value: '47121808', label: 'Drain or pipe cleaning equipment' },
  { value: '48101615', label: 'Commercial use dishwashers' },
  { value: '48101616', label: 'Commercial use food slicers' },
  { value: '49241700', label: 'Swimming pool and spa equipment and supplies' },
  { value: '49241701', label: 'Diving boards' },
  { value: '52170000', label: 'Domestic wall treatments' },
  { value: '53102900', label: 'Athletic wear' },
  {
    value: '73152100',
    label: 'Manufacturing equipment maintenance and repair services'
  },
  { value: '73152101', label: 'Manufacturing equipment maintenance services' },
  { value: '73152102', label: 'Manufacturing equipment repair services' },
  { value: '80111613', label: 'Temporary manual labor' },
  { value: '82101800', label: 'Advertising agency services' },
  { value: '84122000', label: 'Cash vault services' },
  { value: '84122001', label: 'Deposit verification services' },
  { value: '10151904', label: 'Sunflower seeds' },
  { value: '11111609', label: 'Basalt' },
  { value: '11111610', label: 'Pumice stone' },
  { value: '12352000', label: 'Aliphatic and aromatic compounds' },
  { value: '13111219', label: 'Polyvinyl alcohol films' },
  { value: '24101510', label: 'Tilt trucks' },
  { value: '24111812', label: 'Containment basin' },
  { value: '25171713', label: 'Disc brake pads' },
  { value: '25173800', label: 'Drivetrain systems' },
  { value: '25173900', label: 'Electrical components' },
  { value: '25174000', label: 'Engine coolant system' },
  { value: '25174100', label: 'Roof systems' },
  { value: '25174200', label: 'Steering system' },
  { value: '25174400', label: 'Vehicle interior systems' },
  { value: '25173108', label: 'Vehicle navigation systems' },
  { value: '25174600', label: 'Vehicle seating systems' },
  { value: '25181708', label: 'Trailer hitches' },
  { value: '26101513', label: 'Engine Repair Kit' },
  { value: '26101736', label: 'Pistons' },
  { value: '26101740', label: 'Fuel injectors' },
  { value: '26101720', label: 'Turbocharger' },
  { value: '26111715', label: 'Zinc coal battery' },
  { value: '26112000', label: 'Clutch parts and accessories' },
  { value: '26112001', label: 'Pressure plate' },
  { value: '26112003', label: 'Clutch plates' },
  { value: '30103700', label: 'Braid' },
  { value: '30152100', label: 'Surface' },
  { value: '31151904', label: 'Plastic straps' },
  { value: '30171514', label: 'Door closers' },
  { value: '31370000', label: 'Refractories' },
  {
    value: '32130000',
    label: 'Electronic hardware and component parts and accessories'
  },
  {
    value: '32131000',
    label: 'Electronic component parts and raw materials and accessories'
  },
  { value: '40102000', label: 'Boilers' },
  { value: '40183001', label: 'Rubber tubing' },
  { value: '41101515', label: 'Liquid measuring cans' },
  { value: '41111619', label: 'Go or no go gauge' },
  { value: '41111922', label: 'Lamp failure sensor' },
  { value: '41111923', label: 'Pre ignition knock sensor' },
  { value: '41111924', label: 'Oxygen sensors' },
  { value: '41114510', label: 'Torque limiter' },
  { value: '43201409', label: 'Wireless network interface cards' },
  { value: '44102106', label: 'Letter folders' },
  { value: '44102906', label: 'Computer or office equipment cleaning kit' },
  { value: '44103203', label: 'Time card machine replacement ribbon' },
  { value: '44103502', label: 'Binding covers' },
  { value: '44121621', label: 'Desk pads or its accessories' },
  { value: '44121622', label: 'Moisteners' },
  { value: '44121623', label: 'Mechanical letter opener' },
  { value: '44121805', label: 'Correction pens' },
  { value: '44122014', label: 'Sheet lifters' },
  { value: '47131903', label: 'Plugging compound' },
  { value: '49241704', label: 'Water test kit or solutions' },
  { value: '50400000', label: 'Fresh vegetables' },
  { value: '50300000', label: 'Fresh fruits' },
  { value: '50121539', label: 'Fresh fish' },
  { value: '50121537', label: 'Frozen fish' },
  { value: '50121538', label: 'Shelf stable fish' },
  { value: '50131700', label: 'Milk and butter products' },
  { value: '50131701', label: 'Fresh milk or butter products' },
  { value: '50131800', label: 'Cheese' },
  { value: '50131801', label: 'Natural cheese' },
  { value: '50131802', label: 'Processed cheese' },
  { value: '50131803', label: 'Imitation cheese' },
  { value: '50151513', label: 'Edible vegetable or plant oils' },
  { value: '50151514', label: 'Edible vegetable or plant fats' },
  { value: '50151604', label: 'Edible animal oils' },
  { value: '50161509', label: 'Natural sugars or sweetening products' },
  { value: '50171548', label: 'Fresh herbs' },
  { value: '50171550', label: 'Spices or extracts' },
  { value: '50181709', label: 'Baking supplies' },
  { value: '50181900', label: 'Bread and biscuits and cookies' },
  { value: '50181901', label: 'Fresh bread' },
  { value: '50191505', label: 'Fresh prepared soups or stews' },
  { value: '50191506', label: 'Frozen prepared soups or stews' },
  { value: '50191507', label: 'Shelf stable prepared soups or stews' },
  { value: '50192300', label: 'Desserts and dessert toppings' },
  {
    value: '50192400',
    label: 'Jams and jellies and nut and sweet spreads and fruit conserves'
  },
  { value: '50192500', label: 'Sandwiches and filled rolls' },
  { value: '50192600', label: 'Prepared side dishes' },
  { value: '50192700', label: 'Packaged combination meals' },
  { value: '50192800', label: 'Savory pies and quiches and pasties' },
  { value: '50193000', label: 'Infant foods and beverages' },
  { value: '50201710', label: 'Leaf tea' },
  { value: '50201711', label: 'Instant tea' },
  { value: '50202200', label: 'Alcoholic beverages' },
  { value: '50202300', label: 'Non alcoholic beverages' },
  { value: '50220000', label: 'Cereal and pulse products' },
  { value: '50221000', label: 'Pulses' },
  { value: '50221002', label: 'Pulse flour' },
  { value: '50221100', label: 'Cereals' },
  { value: '50221102', label: 'Cereal flour' },
  { value: '52161515', label: 'Compact disk players or recorders' },
  { value: '52161516', label: 'Digital video disk players or recorders' },
  { value: '52161518', label: 'Global positioning system GPS receiver' },
  { value: '52161520', label: 'Microphones' },
  { value: '52161523', label: 'Radio frequency transmitters or receivers' },
  { value: '52161524', label: 'Radio receivers' },
  { value: '52161526', label: 'Satellite receivers' },
  { value: '55101520', label: 'Instruction sheets or booklets' },
  { value: '55101521', label: 'Owner or user manuals' },
  { value: '56101704', label: 'Table base' },
  { value: '73151502', label: 'Joint sealing services' },
  { value: '78101804', label: 'Relocation services' },
  { value: '78111808', label: 'Vehicle rental' },
  { value: '80161507', label: 'Audio visual services' },
  { value: '84121705', label: 'Market data' },
  { value: '86111604', label: 'Employee education' },
  { value: '44111507', label: 'Book ends' },
  { value: '43231503', label: 'Procurement software' },
  { value: '78111807', label: 'Parking fees' },
  { value: '81101512', label: 'Geographic information system GIS services' },
  { value: '25171507', label: 'Wiper blades' },
  { value: '42130000', label: 'Medical apparel and textiles' },
  { value: '42131500', label: 'Patient clothing' },
  { value: '42131501', label: 'Patient bibs' },
  { value: '42131502', label: 'Patient caps' },
  { value: '42131503', label: 'Patient exam capes' },
  { value: '42131504', label: 'Patient gowns' },
  { value: '42131505', label: 'Patient infant shirts or vests' },
  { value: '42131506', label: 'Patient jackets' },
  { value: '42131507', label: 'Patient slippers or socks' },
  { value: '42131600', label: 'Medical staff clothing and related articles' },
  { value: '42131601', label: 'Medical staff aprons or bibs' },
  { value: '42131602', label: 'Medical staff beard covers' },
  { value: '42131603', label: 'Medical staff blouses or smocks' },
  { value: '42131604', label: 'Medical staff bouffant caps' },
  { value: '42131713', label: 'Surgical isolation or surgical masks' },
  { value: '42131607', label: 'Medical staff jackets or coats' },
  { value: '42131608', label: 'Medical staff scrubs' },
  { value: '42131609', label: 'Medical staff shoe covers' },
  { value: '42131610', label: 'Medical staff sleeve protection' },
  { value: '42131611', label: 'Surgeon caps or hoods' },
  { value: '42131700', label: 'Surgical textiles' },
  { value: '42131701', label: 'Surgical drapes' },
  { value: '42131702', label: 'Surgical gowns' },
  { value: '42131508', label: 'Patient pajamas' },
  { value: '42132100', label: 'Hospital housekeeping textiles' },
  { value: '42132101', label: 'Hospital underpads' },
  { value: '42132102', label: 'Medical stretcher sheets' },
  { value: '42132103', label: 'Patient barrier drapes' },
  { value: '42132104', label: 'Hospital pillows' },
  { value: '42132105', label: 'Hospital bed sheets or mattress covers' },
  { value: '42132106', label: 'Hospital bedspreads or duvet covers' },
  { value: '42132107', label: 'Hospital blankets or duvets or quilts' },
  { value: '42132108', label: 'Hospital pillow covers or pillowcases' },
  {
    value: '42140000',
    label: 'Patient care and treatment products and supplies'
  },
  { value: '42141500', label: 'Applicator swabs and cotton balls' },
  { value: '42141501', label: 'Cotton or fiber balls ' },
  { value: '42141502', label: 'Fiber tipped sticks' },
  {
    value: '42141600',
    label: 'Basins and bedpans and urinals and admission kits'
  },
  { value: '42141601', label: 'Patient care admission kits' },
  { value: '42141602', label: 'Bedpans' },
  { value: '42141603', label: 'Emesis basins' },
  { value: '42141605', label: 'Medical mixing or solution basins or bowls' },
  { value: '42141606', label: 'Medical multipurpose basins' },
  { value: '42141607', label: 'Patient urinals' },
  { value: '42141700', label: 'Decubitus prevention products' },
  { value: '42141801', label: 'Electrotherapy combination units' },
  { value: '42141802', label: 'Electrotherapy electrodes' },
  { value: '42141803', label: 'Electrotherapy lead wires or cables' },
  { value: '42141804', label: 'Galvanic or faradic stimulators' },
  { value: '42141805', label: 'Neuromuscular stimulators or kits' },
  {
    value: '42141807',
    label: 'Transcutaneous electric nerve stimulation units'
  },
  { value: '42141900', label: 'Enema administration supplies' },
  { value: '42141903', label: 'Enema kits' },
  { value: '42141904', label: 'Enema accessories' },
  { value: '42142100', label: 'Heat and cold therapy products' },
  { value: '42142101', label: 'Covers for heat or cold therapy products' },
  { value: '42142102', label: 'Medical cold storage chilling units' },
  { value: '42142103', label: 'Medical heat lamps' },
  { value: '42142104', label: 'Medical hydrocollators' },
  {
    value: '42142105',
    label: 'Therapeutic heating or cooling units or systems'
  },
  {
    value: '42142106',
    label: 'Therapeutic heating or cooling blankets or drapes'
  },
  { value: '42142107', label: 'Therapeutic cryo compression therapy systems' },
  { value: '42142109', label: 'Therapeutic hot or cold therapy mitts' },
  { value: '42142110', label: 'Therapeutic hot or cold water bottles' },
  { value: '42142111', label: 'Therapeutic ice packs or pillows' },
  { value: '42142112', label: 'Therapeutic paraffin baths' },
  { value: '42142113', label: 'Therapeutic sinus masks' },
  { value: '42142200', label: 'Hydrotherapy products' },
  { value: '42142300', label: 'Medical documentation products' },
  { value: '42142301', label: 'General use medical labels' },
  { value: '42142302', label: 'Medical paper charting systems' },
  {
    value: '42142303',
    label: 'Patient identification and information products'
  },
  { value: '42293519', label: 'Medical fluid solidifiers' },
  { value: '42293521', label: 'Medical or surgical suction containers' },
  {
    value: '42293522',
    label: 'Medical or surgical suction or vacuum appliances'
  },
  {
    value: '42142500',
    label: 'Injection and aspiration needles and accessories'
  },
  { value: '42142501', label: 'Amniocentesis needles' },
  { value: '42142502', label: 'Anesthesia needles' },
  { value: '42142503', label: 'Arterial needles' },
  { value: '41104138', label: 'Blood collection needle holders' },
  { value: '42142506', label: 'Blunt needles' },
  { value: '42142509', label: 'Epidural anesthesia kit or tray' },
  { value: '42142510', label: 'Filter needles' },
  { value: '42142511', label: 'Needle caps or protection devices' },
  { value: '42142512', label: 'Fistula needles' },
  { value: '42142513', label: 'Radiology procedural needles' },
  { value: '42142514', label: 'Spinal anesthesia kits or trays' },
  { value: '42142516', label: 'Vented needles' },
  { value: '42142600', label: 'Syringes and accessories' },
  { value: '42142601', label: 'Medical aspiration or irrigation syringes' },
  { value: '42142602', label: 'Medical bulb syringes' },
  { value: '42142603', label: 'Medical cartridge syringes' },
  { value: '42142604', label: 'Medical catheter tip syringes' },
  { value: '42142605', label: 'Medical ear syringes' },
  { value: '42142606', label: 'Medical metered delivery syringes' },
  { value: '42142607', label: 'Medical micro syringes' },
  { value: '42142608', label: 'Medical syringes without needle' },
  { value: '42142609', label: 'Medical syringes with needle' },
  { value: '42142610', label: 'Oral liquid medication syringes' },
  { value: '42142611', label: 'Tuberculin syringes' },
  { value: '42142700', label: 'Urological supplies' },
  { value: '42142701', label: 'Suprapubic urinary catheters or kits' },
  { value: '42142702', label: 'Urethral urinary catheters' },
  { value: '42142703', label: 'Urinary catheter plugs or clamps' },
  { value: '42142704', label: 'Urinary drainage bags or meters' },
  { value: '42142705', label: 'Urinary drainage bag straps or fasteners' },
  { value: '42142706', label: 'Urological procedure trays or packs or kits' },
  {
    value: '42142800',
    label: 'Vascular and compression therapy equipment and supplies'
  },
  {
    value: '42142801',
    label: 'Vascular sequential compression devices or tubing'
  },
  { value: '42142802', label: 'Vascular or compression apparel or support' },
  {
    value: '42142900',
    label: 'Vision correction or cosmetic eyewear and related products'
  },
  { value: '42142902', label: 'Eyeglass lenses' },
  { value: '42150000', label: 'Dental equipment and supplies' },
  { value: '42151500', label: 'Cosmetic dentistry equipment and supplies' },
  { value: '42151501', label: 'Cosmetic dentistry curing lights ' },
  { value: '42151502', label: 'Cosmetic dentistry mixing wells' },
  { value: '42151503', label: 'Crowns or crown forms' },
  { value: '42151504', label: 'Dental veneers' },
  { value: '42151614', label: 'Dental burs' },
  { value: '42151700', label: 'Dental clinical furniture' },
  {
    value: '42151800',
    label: 'Dental fillers and finishing and polishing supplies'
  },
  {
    value: '42151900',
    label: 'Dental hygiene and preventive care equipment and supplies'
  },
  { value: '42151901', label: 'Dental plate or denture brushes' },
  { value: '42152000', label: 'Dental imaging equipment and supplies' },
  {
    value: '42152100',
    label: 'Dental impression and forming equipment and supplies'
  },
  {
    value: '42152200',
    label: 'Dental laboratory and sterilization equipment and supplies'
  },
  {
    value: '42152300',
    label:
      'Dental lasers and illumination and fiber optic equipment and supplies'
  },
  { value: '42152400', label: 'Dental materials' },
  { value: '42152423', label: 'Dental pit or fissure sealants' },
  { value: '42152424', label: 'Dental cements' },
  { value: '42152425', label: 'Denture resins' },
  { value: '42152434', label: 'Dental amalgamators' },
  { value: '42152500', label: 'General dental supplies' },
  { value: '42152501', label: 'Dental preassembled disposable kits or trays' },
  { value: '42152502', label: 'Dental bibs' },
  { value: '42152503', label: 'Dental dam supplies' },
  { value: '42152504', label: 'Dental dressings' },
  { value: '42152505', label: 'Dental examination chair headrest covers' },
  { value: '42152506', label: 'Dental absorbent pellets' },
  { value: '42152507', label: 'Dental rolls' },
  { value: '42152509', label: 'Disposable dental tray covers' },
  { value: '42152600', label: 'Dental operatory specific supplies' },
  {
    value: '42152700',
    label: 'Orthodontic and prosthodontic equipment and supplies'
  },
  { value: '42152800', label: 'Periodontal equipment and supplies' },
  {
    value: '42161500',
    label: 'Peritoneal and equilibrium dialysis equipment and supplies'
  },
  {
    value: '42161501',
    label: 'Continuous ambulatory peritoneal dialysis CAPD transfer sets'
  },
  { value: '42161502', label: 'Peritoneal dialysate warmers' },
  { value: '42161503', label: 'Peritoneal dialysis administration sets' },
  {
    value: '42161504',
    label: 'Peritoneal dialysis catheter adapters or clamps or connectors'
  },
  {
    value: '42161505',
    label: 'Peritoneal dialysis drainage bags or containers'
  },
  {
    value: '42161506',
    label: 'Peritoneal dialysis catheters or indwelling access devices'
  },
  { value: '42161507', label: 'Peritoneal dialysis solutions' },
  { value: '42161508', label: 'Peritoneal dialysis unit straps or harnesses' },
  {
    value: '42161600',
    label: 'Extracorporeal hemodialysis equipment and supplies'
  },
  { value: '42161602', label: 'Hemodialysis blood bag samplers' },
  { value: '42161603', label: 'Hemodialysis blood oxygen demand apparatus' },
  { value: '42161604', label: 'Hemodialysis chairs' },
  { value: '42161605', label: 'Hemodialysis dialysate conductivity meters' },
  { value: '42161606', label: 'Hemodialysis dialysate level detectors' },
  { value: '42161607', label: 'Hemodialysis dialysate pressure pumps' },
  { value: '42161608', label: 'Hemodialysis dialysate solutions' },
  { value: '42161609', label: 'Hemodialysis dialysate tanks' },
  { value: '42161610', label: 'Hemodialysis dialysate tubing' },
  { value: '42161611', label: 'Hemodialysis dialysate warming baths' },
  { value: '42161612', label: 'Hemodialysis dialyzer celluloid filters' },
  { value: '42161613', label: 'Hemodialysis dialyzer collodion filters' },
  { value: '42161614', label: 'Hemodialysis dialyzer hollow filament filters' },
  { value: '42161615', label: 'Hemodialysis dialyzer reprocessing systems' },
  {
    value: '42161616',
    label:
      'Hemodialysis unit air bubble or foam or clot detectors or traps or alarms'
  },
  { value: '42161617', label: 'Hemodialysis unit arterial pressure monitors' },
  { value: '42161618', label: 'Hemodialysis unit blood line clamps' },
  { value: '42161619', label: 'Hemodialysis unit blood port caps' },
  { value: '42161620', label: 'Hemodialysis unit blood pumps' },
  { value: '42161621', label: 'Hemodialysis unit dialysate port caps' },
  { value: '42161622', label: 'Hemodialysis unit disinfectants or cleansers' },
  { value: '42161623', label: 'Hemodialysis unit heparin infusion pumps' },
  { value: '42161624', label: 'Hemodialysis unit single needle controllers' },
  { value: '42161625', label: 'Hemodialysis unit single pass converters' },
  { value: '42192410', label: 'Hemodialysis unit stands or supports or carts' },
  { value: '42161627', label: 'Hemodialysis unit temperature monitors' },
  { value: '42161628', label: 'Hemodialysis unit test equipment' },
  { value: '42161629', label: 'Hemodialysis unit transducer filters' },
  { value: '42161630', label: 'Hemodialysis unit transducer protectors' },
  { value: '42161631', label: 'Hemodialysis unit water purification systems' },
  { value: '42161632', label: 'Hemodialysis units' },
  { value: '42161700', label: 'Hemofiltration equipment and supplies' },
  { value: '42161702', label: 'Hemofiltrate collection bags' },
  { value: '42161703', label: 'Hemofiltration infusion ports' },
  { value: '42161704', label: 'Hemofiltration sampling ports' },
  {
    value: '42161800',
    label: 'Continuous renal replacement therapy CRRT equipment and supplies'
  },
  { value: '42170000', label: 'Mobile medical services products' },
  {
    value: '42171500',
    label:
      'Mobile medical services disaster management products and mobile medical services supplies'
  },
  { value: '42171501', label: 'Mobile medical services disaster body pouches' },
  { value: '42171502', label: 'Mobile medical services triage tags' },
  {
    value: '42171600',
    label:
      'Mobile medical services extricating and immobilizing and transporting products'
  },
  {
    value: '42171601',
    label: 'Mobile medical services air evacuation stretchers'
  },
  { value: '42171602', label: 'Mobile medical services ambulance cots' },
  { value: '42171603', label: 'Mobile medical service anti shock garments' },
  { value: '42171604', label: 'Mobile medical services basket stretchers' },
  { value: '42171605', label: 'Mobile medical services cinch rescue loops' },
  { value: '42171606', label: 'Mobile medical services air splints' },
  {
    value: '42171607',
    label: 'Mobile medical services cervical or extrication collars'
  },
  { value: '42171608', label: 'Mobile medical services head immobilizers' },
  {
    value: '42171609',
    label: 'Mobile medical services restraint or spine board straps'
  },
  { value: '42171610', label: 'Mobile medical services torso immobilizers' },
  { value: '42171611', label: 'Mobile response litters' },
  { value: '42171612', label: 'Mobile medical services scoop stretchers' },
  { value: '42171613', label: 'Mobile medical services spine boards' },
  {
    value: '42171614',
    label: 'Mobile medical services water rescue tubes or cans'
  },
  { value: '42171700', label: 'Mobile medical services blankets' },
  { value: '42171701', label: 'Mobile or rescue blankets' },
  { value: '42171702', label: 'Mobile medical services first aid blankets' },
  {
    value: '42171703',
    label: 'Mobile medical services heat shielding wraps or blankets'
  },
  {
    value: '42171704',
    label: 'Mobile medical services infant swaddlers or buntings'
  },
  {
    value: '42171800',
    label: 'Mobile medical services airway management equipment'
  },
  {
    value: '42171801',
    label: 'Mobile medical services anesthesiology oropharyngeal airways'
  },
  { value: '42171805', label: 'Jaw spreaders' },
  {
    value: '42171900',
    label: 'Medical equipment bags and cases and carriers and related products'
  },
  { value: '42192414', label: 'Airway management bags' },
  { value: '42192416', label: 'Defibrillator cases' },
  { value: '42192417', label: 'Drug cases' },
  { value: '42192419', label: 'Extrication product cases or bags' },
  { value: '42192423', label: 'Intravenous IV cases' },
  { value: '42192424', label: 'Life support cases' },
  {
    value: '42192425',
    label: 'Long distance response LDR trauma cases or bags'
  },
  {
    value: '42192427',
    label: 'Paramedic or emergency medical technician EMT cases or bags'
  },
  { value: '42192428', label: 'Portable oxygen or resuscitation cases' },
  { value: '42172000', label: 'Mobile medical services kits' },
  { value: '42172001', label: 'Mobile medical services first aid kits' },
  { value: '42172002', label: 'Mobile medical services first response kits' },
  { value: '42172003', label: 'Mobile medical service intravenous IV kits' },
  { value: '42172004', label: 'Mobile medical services life support kits' },
  {
    value: '42172005',
    label: 'Mobile medical services long distance response LDR trauma kits'
  },
  { value: '42172006', label: 'Mobile medical services obstetrics kits' },
  { value: '42172008', label: 'Mobile medical services rappel kits' },
  {
    value: '42172009',
    label: 'Mobile medical services search and rescue kits'
  },
  { value: '42172010', label: 'Mobile medical services trauma kits' },
  { value: '42172011', label: 'Mobile medical technician EMT kits' },
  {
    value: '42172100',
    label: 'Mobile medical services resuscitation products'
  },
  {
    value: '42172101',
    label:
      'Mobile medical services automated external defibrillators AED or hard paddles'
  },
  { value: '42295313', label: 'Mobile medical services tourniquets or clamps' },
  { value: '42180000', label: 'Patient exam and monitoring products' },
  {
    value: '42181500',
    label: 'Diagnostic assessment and exam products for general use'
  },
  { value: '42181501', label: 'Tongue depressors or blades or sticks' },
  { value: '42181502', label: 'Medical exam transilluminators' },
  { value: '42181503', label: 'Exam or personal lubricants or jellies' },
  { value: '42181601', label: 'Aneroid blood pressure units' },
  { value: '42181602', label: 'Electronic blood pressure units' },
  { value: '42181603', label: 'Mercury blood pressure units' },
  {
    value: '42181604',
    label: 'Blood pressure air release valves or inflation bulbs'
  },
  { value: '42181605', label: 'Blood pressure cuffs or bladders' },
  {
    value: '42181606',
    label: 'Blood pressure inflation hoses or pneumatic hoses or adapters'
  },
  { value: '42181607', label: 'Blood pressure recording units' },
  { value: '42181701', label: 'Electrocardiography EKG units' },
  { value: '42181800', label: 'Pulse oximeters' },
  { value: '42181801', label: 'Pulse oximeter units' },
  { value: '42181802', label: 'Pulse oximeter cables' },
  { value: '42181803', label: 'Pulse oximeter probes or sensors' },
  {
    value: '42181900',
    label: 'Acute care monitoring units and related products'
  },
  { value: '42143120', label: 'Acute care fetal or maternal monitoring units' },
  { value: '42181902', label: 'Intracranial pressure ICP monitoring units' },
  { value: '42181903', label: 'Cardiac output CO monitoring units' },
  { value: '42181904', label: 'Multiparameter vital sign units' },
  { value: '42181905', label: 'Medical transducer monitor cables' },
  {
    value: '42182000',
    label: 'Scopes and specula and accessories for medical exam diagnostic use'
  },
  { value: '42182001', label: 'Anal or rectal exam speculas' },
  { value: '42182003', label: 'Colposcopes or vaginoscopes' },
  { value: '42182004', label: 'Dermatoscopes' },
  { value: '42182005', label: 'Ophthalmoscopes or otoscopes or scope sets' },
  { value: '42182006', label: 'Laryngeal or oropharyngeal exam speculas' },
  { value: '42182013', label: 'Vaginal exam speculas' },
  { value: '42182101', label: 'Electronic stethoscopes' },
  { value: '42182102', label: 'Hand held dopplers' },
  { value: '42182103', label: 'Medical acoustic stethoscopes' },
  { value: '42182201', label: 'Electronic medical thermometers' },
  { value: '42182202', label: 'Fiberoptic medical thermometers' },
  { value: '42182203', label: 'Medical thermometer carrying cases or covers' },
  { value: '42182204', label: 'Medical thermometer racks' },
  { value: '42182205', label: 'Medical thermometer tip or probe covers' },
  { value: '42182206', label: 'Mercury medical thermometers' },
  {
    value: '42182207',
    label: 'Patient temperature continuous or trend monitors'
  },
  { value: '42182208', label: 'Patient temperature strips' },
  { value: '42182300', label: 'Neurological exam products' },
  { value: '42182301', label: 'Medical diagnostic pinwheels' },
  { value: '42182302', label: 'Reflex hammers or mallets' },
  { value: '42182303', label: 'Neuropsychiatry exam cards' },
  { value: '42182400', label: 'Hearing testing products' },
  { value: '42182500', label: 'Nasal function meters' },
  { value: '42182600', label: 'Medical exam lights or lamps' },
  { value: '42182601', label: 'Freestanding medical exam lights or lamps' },
  { value: '42182602', label: 'Installed medical exam lights or lamps' },
  {
    value: '42182603',
    label: 'Medical exam or surgical headlights or headlamps'
  },
  { value: '42182604', label: 'Medical exam penlights or flashlights' },
  { value: '42182700', label: 'Medical exam size measuring devices' },
  { value: '42182701', label: 'Goniometers or arthrometers' },
  { value: '42182702', label: 'Medical tape measures' },
  { value: '42182703', label: 'Patient height rulers' },
  { value: '42182704', label: 'Skinfold calipers' },
  { value: '42182800', label: 'Medical weight scales' },
  { value: '42182801', label: 'Diaper weight scales' },
  { value: '42182802', label: 'Infant scales' },
  { value: '42182803', label: 'Patient bed or table scales for general use' },
  { value: '42182804', label: 'Patient chair scales' },
  { value: '42182805', label: 'Patient floor scales' },
  { value: '42182806', label: 'Patient sling scales' },
  { value: '42182807', label: 'Wheelchair platform scales' },
  { value: '42192700', label: 'Specialty exam tables and related products' },
  { value: '42192702', label: 'Obstetrical or gynecological exam tables' },
  {
    value: '42192701',
    label: 'Obstetrical or gynecological exam table accessories'
  },
  { value: '42192707', label: 'Pediatric examination tables' },
  { value: '42192706', label: 'Pediatric examination table accessories' },
  { value: '42183000', label: 'Ophthalmic diagnostic exam products' },
  { value: '42190000', label: 'Medical facility products' },
  {
    value: '42191500',
    label: 'Medical facility materials handling and distribution equipment'
  },
  { value: '42191501', label: 'Clinical pneumatic tube systems' },
  { value: '42191600', label: 'Medical facility building systems' },
  { value: '42191601', label: 'Patient room lighting' },
  { value: '42191602', label: 'Operating room lighting' },
  { value: '42191603', label: 'Hospital equipment instrument panels' },
  { value: '42191605', label: 'Hospital equipment power columns' },
  {
    value: '42191607',
    label: 'Patient cubicle curtains or screens or curtain track hardware'
  },
  { value: '42191611', label: 'Nurse communication modules or systems' },
  { value: '42191612', label: 'Hospital intercom systems' },
  { value: '42191700', label: 'Medical gas products' },
  { value: '42191701', label: 'Medical gas or electric service tracks' },
  { value: '42191705', label: 'Medical gas alarms' },
  { value: '42191707', label: 'Medical vacuum systems' },
  { value: '42191710', label: 'Medical gas cylinder carts or stands' },
  { value: '42191800', label: 'Patient beds and accessories' },
  { value: '42191801', label: 'Overbed tables' },
  { value: '42191802', label: 'Clinical incubators or infant warmers' },
  { value: '42191803', label: 'Clinical bassinets or cribs or pediatric beds' },
  { value: '42191804', label: 'Medical or surgical bedside rails' },
  { value: '42191805', label: 'Medical suspended columns' },
  { value: '42191806', label: 'Clinical trapeze bars' },
  { value: '42191807', label: 'Patient care beds for general use' },
  { value: '42191808', label: 'Patient care beds for specialty care' },
  { value: '42191810', label: 'Patient care mattresses' },
  { value: '42191900', label: 'Clinical cabinetry' },
  { value: '42191901', label: 'Bedside clinical cabinets' },
  { value: '42191902', label: 'Hospital armoires' },
  { value: '42191903', label: 'Medical monitor cabinets' },
  { value: '42191905', label: 'Medical treatment cabinets' },
  { value: '42192000', label: 'Clinical procedure and examination tables' },
  {
    value: '42192001',
    label: 'Medical exam or procedure tables for general use'
  },
  {
    value: '42192002',
    label:
      'Medical exam or procedure table accessories for general use excluding cover sheets'
  },
  {
    value: '42192100',
    label: 'Clinical seating and stools and related products'
  },
  { value: '42192101', label: 'Blood drawing or phlebotomy chairs' },
  { value: '42192102', label: 'Hospital recliners' },
  { value: '42192104', label: 'Medical stools or chairs' },
  { value: '42192107', label: 'Clinical examination chairs' },
  { value: '42192200', label: 'Patient transport products' },
  { value: '42192201', label: 'Patient transport trolleys' },
  { value: '42192202', label: 'Gurneys or scissor lifts' },
  { value: '42192203', label: 'Geriatric chairs' },
  { value: '42192204', label: 'Patient transport incubators' },
  { value: '42192205', label: 'Patient scooter accessories' },
  { value: '42192206', label: 'Patient scooters' },
  { value: '42192207', label: 'Patient stretchers' },
  { value: '42192300', label: 'Patient lifts' },
  { value: '42192302', label: 'Clinical hydraulic lifts' },
  { value: '42192303', label: 'Patient suspended seats or slings' },
  { value: '42192304', label: 'Patient ceiling hoists' },
  {
    value: '42192400',
    label: 'Medical equipment transport and transfer and storage products'
  },
  { value: '42192401', label: 'Emergency or resuscitation carts' },
  {
    value: '42192402',
    label: 'Diagnostic or monitoring equipment specific carts or stands'
  },
  { value: '42192403', label: 'Medical isolation carts' },
  { value: '42192404', label: 'Medical carts' },
  { value: '42192405', label: 'Irrigator mobile stands' },
  {
    value: '42200000',
    label: 'Medical diagnostic imaging and nuclear medicine products'
  },
  {
    value: '42201501',
    label: 'Medical computed tomography CT or CAT stationary units'
  },
  {
    value: '42201502',
    label:
      'Medical computed tomography CT or CAT mobile or transportable or van units'
  },
  {
    value: '42201508',
    label: 'Medical computed tomography CT or CAT monitors'
  },
  {
    value: '42201509',
    label: 'Medical computed tomography CT or CAT power conditioners'
  },
  {
    value: '42201600',
    label: 'Medical magnetic resonance imaging MRI products'
  },
  {
    value: '42201601',
    label: 'Medical magnetic resonance imaging MRI stationary units'
  },
  {
    value: '42201602',
    label:
      'Medical magnetic resonance imaging MRI mobile or transportable or van systems'
  },
  {
    value: '42201607',
    label: 'Medical magnetic resonance imaging MRI monitors'
  },
  {
    value: '42201609',
    label: 'Medical magnetic resonance imaging MRI scanners'
  },
  { value: '42201611', label: 'Medical magnetic resonance imaging MRI tables' },
  {
    value: '42201700',
    label: 'Medical ultrasound and doppler and echo imaging products'
  },
  { value: '42201704', label: 'Medical ultrasound bone densitometers' },
  {
    value: '42201705',
    label: 'Medical ultrasound or doppler or echocardiograph probe covers'
  },
  {
    value: '42201706',
    label: 'Medical ultrasound or doppler or echocardiograph probes'
  },
  {
    value: '42201707',
    label: 'Medical ultrasound or doppler or echocardiograph gel warmers'
  },
  {
    value: '42201708',
    label:
      'Medical ultrasound or doppler or echocardiograph gels or transmission pads or lotions'
  },
  {
    value: '42201709',
    label: 'Medical ultrasound or doppler or echocardiograph monitors'
  },
  {
    value: '42201710',
    label: 'Medical ultrasound or doppler or echocardiograph printers'
  },
  {
    value: '42201711',
    label:
      'Medical ultrasound or doppler or echocardiograph transducers or accessories'
  },
  {
    value: '42201712',
    label:
      'Medical ultrasound or doppler or pulse echocardiograph or echocardiograph units for general diagnostic use'
  },
  {
    value: '42201713',
    label:
      'Medical ultrasound or doppler or echocardiograph three dimensional components'
  },
  { value: '42201803', label: 'Mammography x ray units' },
  { value: '42201804', label: 'Medical c arm x ray units' },
  { value: '42201805', label: 'Medical cinefluoroscopy units' },
  { value: '42201806', label: 'Medical radiology and fluoroscopy RF units' },
  { value: '42201808', label: 'Medical x ray buckys' },
  {
    value: '42201809',
    label: 'Medical imaging contrast agent injectors or accessories'
  },
  { value: '42201810', label: 'Medical x ray films or cassettes' },
  {
    value: '42201811',
    label: 'Medical x ray quality assurance or calibration devices'
  },
  {
    value: '42201812',
    label: 'Medical x ray tables or stands or chairs or cabinets or accessories'
  },
  { value: '42201813', label: 'Medical x ray tomography units' },
  { value: '42201814', label: 'Medical x ray tubes' },
  {
    value: '42201815',
    label: 'Medical x ray units for general diagnostic use'
  },
  { value: '42201816', label: 'Medical xeroradiography units' },
  { value: '42201817', label: 'X ray bone densitometers' },
  {
    value: '42201900',
    label: 'Medical x ray film illuminators and viewing equipment'
  },
  { value: '42201901', label: 'Medical x ray film hot spot lights' },
  { value: '42201902', label: 'Medical x ray film large rack viewing systems' },
  { value: '42201903', label: 'Medical x ray film view boxes' },
  {
    value: '42202000',
    label: 'Medical diagnostic gamma cameras and related products'
  },
  { value: '42202100', label: 'Brachytherapy products' },
  { value: '42202101', label: 'Brachytherapy intracavity containers or seeds' },
  {
    value: '42202102',
    label: 'Brachytherapy catheters or syringes or inserters or applicators'
  },
  { value: '42202103', label: 'Brachytherapy seed storage containers' },
  { value: '42202104', label: 'Brachytherapy seed capture kits' },
  { value: '42202105', label: 'Brachytherapy units' },
  { value: '42202200', label: 'Gamma radiation therapy products' },
  {
    value: '42202300',
    label:
      'Medical linear accelerator intensity modulated radiation therapy IMRT products'
  },
  {
    value: '42202301',
    label:
      'Medical linear accelerator intensity modulated radiation therapy IMRT two dimensional units'
  },
  {
    value: '42202302',
    label:
      'Medical linear accelerator intensity modulated radiation therapy IMRT three dimensional units'
  },
  {
    value: '42202303',
    label:
      'Medical linear accelerator intensity modulated radiation therapy IMRT collimators'
  },
  {
    value: '42202400',
    label:
      'Medical positron emission tomography PET equipment and related products'
  },
  {
    value: '42202500',
    label:
      'Medical single photon emission computed tomography SPECT equipment and related products'
  },
  {
    value: '42202600',
    label: 'Radioimmunotherapy and radioisotope administration products'
  },
  { value: '42202700', label: 'Radiotherapy teletherapy products' },
  { value: '42202900', label: 'Low energy medical x ray equipment' },
  { value: '42202901', label: 'Low energy medical x ray units' },
  {
    value: '42203201',
    label: 'X ray and fluoroscopy RF radiotherapy planning simulators'
  },
  {
    value: '42203400',
    label:
      'Vascular imaging and interventional cardiology and cardiac catheterization lab products'
  },
  { value: '42295912', label: 'Coronary stents' },
  {
    value: '42203402',
    label: 'Diagnostic or interventional vascular catheters for general use'
  },
  {
    value: '42203403',
    label: 'Diagnostic or interventional vascular catheter introducers'
  },
  { value: '42203404', label: 'Vascular imaging guidewires' },
  { value: '42203405', label: 'Angioplasty balloon catheters' },
  {
    value: '42203501',
    label:
      'Cardiac pacemaker generators or cardiac resynchronization therapy pacemakers CRT-P'
  },
  { value: '42203502', label: 'Cardiac pacing leads or electrodes' },
  { value: '42203503', label: 'Cardiac pacing lead introducers or sets' },
  { value: '42203415', label: 'Cardiac catheterization lab equipment' },
  {
    value: '42203600',
    label: 'Medical radiological imaging information and archiving products'
  },
  { value: '42203601', label: 'Digital imaging network DIN system equipment' },
  {
    value: '42203602',
    label:
      'Digital Imaging Communications in Medicine DICOM standard system equipment'
  },
  {
    value: '42203603',
    label: 'Medical picture archiving computer systems PACS'
  },
  { value: '42203605', label: 'Medical x ray film archiving system software' },
  {
    value: '42203700',
    label: 'Medical imaging processing equipment and supplies'
  },
  {
    value: '42203701',
    label: 'Medical x ray film daylight stampers or identification printers'
  },
  {
    value: '42203702',
    label: 'Medical imaging wet darkroom or daylight processors'
  },
  { value: '42203703', label: 'Medical x ray passthrough boxes' },
  { value: '42203704', label: 'Medical toners or developers' },
  { value: '42203705', label: 'Medical imaging dry laser printers or imagers' },
  { value: '42203800', label: 'Medical radiological positioning aids' },
  {
    value: '42203801',
    label: 'Medical computed tomography CT or CAT positioning aids'
  },
  {
    value: '42203802',
    label: 'Medical magnetic resonance imaging MRI positioning aids'
  },
  {
    value: '42203803',
    label: 'Medical radiological positioning aids for general radiological use'
  },
  {
    value: '42203900',
    label: 'Medical radiation detection or monitoring products'
  },
  { value: '42203901', label: 'Medical radiation dosimeters' },
  { value: '42203902', label: 'Medical radiation films or badges' },
  {
    value: '42204000',
    label: 'Medical radiological shielding and protection products'
  },
  { value: '42204001', label: 'Medical radiological shielding apron racks' },
  {
    value: '42204002',
    label: 'Medical radiological shielding aprons or masks or drapes'
  },
  {
    value: '42204003',
    label:
      'Medical radiological shielding portable containers for radioactive materials'
  },
  { value: '42204004', label: 'Medical radiological shielding earplugs' },
  { value: '42204005', label: 'Medical radiological shielding gloves' },
  {
    value: '42204006',
    label:
      'Medical radiological shielding freestanding or portable screens or curtains'
  },
  {
    value: '42204007',
    label:
      'Medical radiological shielding wall or ceiling or floor installed panels'
  },
  {
    value: '42204008',
    label:
      'Medical radiological shielding chambers or rooms or safes or cabinets'
  },
  {
    value: '42210000',
    label: 'Independent living aids for the physically challenged'
  },
  {
    value: '42211500',
    label:
      'Ambulation and transfer and positioning aids for the physically challenged'
  },
  { value: '42211501', label: 'Canes' },
  { value: '42211503', label: 'Positioning devices' },
  { value: '42211504', label: 'Standers or standing cages or standing aids ' },
  { value: '42211505', label: 'Walker or rollator accessories' },
  { value: '42211506', label: 'Walkers or rollators' },
  {
    value: '42211600',
    label: 'Bathroom and bathing aids for the physically challenged'
  },
  { value: '30181803', label: 'Hand held shower unit' },
  {
    value: '42211700',
    label: 'Communication aids for the physically challenged'
  },
  {
    value: '42211701',
    label: 'Adaptive communication switches for the physically challenged'
  },
  {
    value: '42211704',
    label: 'Headpointers or mouthsticks for the physically challenged'
  },
  { value: '42211705', label: 'Hearing aids for the physically challenged' },
  {
    value: '42211707',
    label:
      'Telecommunication devices TDD or teletypewriters TTY for the physically challenged'
  },
  { value: '42211708', label: 'Telephone aids for the physically challenged' },
  { value: '42211709', label: 'Typing aids for the physically challenged' },
  { value: '42211710', label: 'Writing aids for the physically challenged' },
  {
    value: '42211800',
    label: 'Dressing and grooming aids for the physically challenged'
  },
  {
    value: '42211900',
    label:
      'Eating and drinking and food preparation aids for the physically challenged'
  },
  {
    value: '42211901',
    label: 'Anti slip materials for the physically challenged'
  },
  {
    value: '42211902',
    label: 'Assistive cooking devices for the physically challenged'
  },
  { value: '42211903', label: 'Can openers for the physically challenged' },
  { value: '42211904', label: 'Choppers for the physically challenged' },
  { value: '42211905', label: 'Cups or mugs for the physically challenged' },
  {
    value: '42211906',
    label: 'Cutlery or utensil holders for the physically challenged'
  },
  {
    value: '42211907',
    label: 'Cutlery or utensils for the physically challenged'
  },
  {
    value: '42211908',
    label: 'Cutting or paring boards for the physically challenged'
  },
  { value: '42211909', label: 'Drink holders for the physically challenged' },
  {
    value: '42211910',
    label: 'Food catchers or bibs for the physically challenged'
  },
  { value: '42211911', label: 'Food guards for the physically challenged' },
  {
    value: '42211912',
    label: 'Holders for kitchen devices for the physically challenged'
  },
  {
    value: '42211913',
    label: 'Jumbo digit timers for the physically challenged'
  },
  { value: '42211914', label: 'Measuring aids for the physically challenged' },
  { value: '42211915', label: 'Self feeders for the physically challenged' },
  {
    value: '42211916',
    label: 'Straws or straw holders for the physically challenged'
  },
  {
    value: '42211918',
    label: 'Talking food scales for the physically challenged'
  },
  {
    value: '42212000',
    label: 'Housekeeping and homemaking aids for the physically challenged'
  },
  { value: '42212001', label: 'Door openers for the physically challenged' },
  {
    value: '42212002',
    label: 'Key turners or holders for the physically challenged'
  },
  { value: '42212003', label: 'Knob turners for the physically challenged' },
  {
    value: '42212004',
    label: 'Light switch extensions for the physically challenged'
  },
  {
    value: '42212005',
    label: 'Long handled dustpans or brushes for the physically challenged'
  },
  {
    value: '42212006',
    label: 'Self opening scissors for the physically challenged'
  },
  { value: '42212007', label: 'Suction brushes for the physically challenged' },
  {
    value: '42212100',
    label: 'Leisure and recreational aids for the physically challenged'
  },
  {
    value: '42212200',
    label: 'Medication handling aids for the physically challenged'
  },
  {
    value: '42212300',
    label: 'Reaching and gripping aids for the physically challenged'
  },
  {
    value: '42220000',
    label: 'Intravenous and arterial administration products'
  },
  {
    value: '42221500',
    label: 'Intravenous and arterial cannulas and catheters and accessories'
  },
  { value: '42221501', label: 'Arterial line catheters' },
  {
    value: '42221502',
    label: 'Arterial line continuous catheter flush valves'
  },
  { value: '42221503', label: 'Central venous catheters' },
  { value: '42221504', label: 'Peripherally inserted central catheters PICC' },
  { value: '42221506', label: 'Umbilical catheters' },
  {
    value: '42221507',
    label: 'Intravenous or arterial start kit without catheters'
  },
  {
    value: '42221600',
    label:
      'Intravenous and arterial tubing and administration sets and related products'
  },
  {
    value: '42221601',
    label: 'Intravenous or arterial administration air bubble detectors'
  },
  {
    value: '42221602',
    label:
      'Intravenous or arterial administration ports or injection sites or caps or protectors'
  },
  { value: '42221603', label: 'Intravenous or arterial extension tubing' },
  {
    value: '42221604',
    label:
      'Intravenous or arterial tubing adapters or connectors or locks or caps or protectors'
  },
  { value: '42221605', label: 'Intravenous or arterial tubing check valves' },
  { value: '42221606', label: 'Intravenous or arterial tubing clamps' },
  {
    value: '42221607',
    label: 'Intravenous or arterial tubing filters or screens for general use'
  },
  {
    value: '42221608',
    label: 'Intravenous or arterial tubing identification labels or tapes'
  },
  {
    value: '42221609',
    label: 'Intravenous or arterial tubing administration sets'
  },
  {
    value: '42221610',
    label: 'Intravenous tubing spike entry ports or caps or protectors'
  },
  {
    value: '42221611',
    label: 'Intravenous tubing spikes or caps or protectors'
  },
  {
    value: '42221612',
    label:
      'Intravenous or arterial tubing needleless injection ports or stopcocks or manifolds'
  },
  {
    value: '42221613',
    label: 'Intravenous tubing transfer leg closures or snaps'
  },
  {
    value: '42221614',
    label: 'Intravenous tubing with catheter administration kits'
  },
  {
    value: '42221615',
    label: 'Secondary or piggyback medication intravenous tubing'
  },
  {
    value: '42221700',
    label:
      'Intravenous and arterial infusion bags and containers and related products'
  },
  {
    value: '42221701',
    label: 'Intravenous or arterial infusion bags or containers'
  },
  { value: '42221703', label: 'Intravenous or arterial fluid warmers' },
  {
    value: '42221704',
    label: 'Intravenous or arterial pressure infusion bags'
  },
  {
    value: '42221800',
    label: 'Intravenous and arterial catheter and needle positioning aids'
  },
  { value: '42221801', label: 'Intravenous or arterial arm board covers' },
  { value: '42221802', label: 'Intravenous or arterial arm boards' },
  {
    value: '42221803',
    label:
      'Intravenous or arterial catheter positioning tapes or dressings or straps or cuffs'
  },
  {
    value: '42221900',
    label: 'Intravenous or arterial flow measurement and regulation products'
  },
  {
    value: '42221901',
    label: 'Dial calibrated intravenous flowmeters or regulators'
  },
  { value: '42221902', label: 'Intravenous drop counters or regulators' },
  {
    value: '42222000',
    label:
      'Intravenous infusion pumps and its analyzers and sensors and its accessories'
  },
  { value: '42222001', label: 'Intravenous infusion pumps for general use' },
  { value: '42222002', label: 'Intravenous syringe infusion pumps' },
  { value: '42222003', label: 'Multichannel intravenous infusion pumps' },
  { value: '42222004', label: 'Patient controlled analgesia infusion pumps' },
  {
    value: '42222100',
    label: 'Intravenous and arterial equipment suspension and transport systems'
  },
  {
    value: '42222101',
    label: 'Intravenous or arterial line equipment hangers'
  },
  {
    value: '42222102',
    label: 'Mounted tracks or racks for intravenous infusion gravity systems'
  },
  { value: '42222103', label: 'Intravenous infusion poles for wheelchairs' },
  { value: '42222104', label: 'Intravenous or arterial line poles or stands' },
  {
    value: '42222200',
    label: 'Needleless intravenous injection and withdrawal systems'
  },
  {
    value: '42222201',
    label: 'Needleless intravenous injection syringe sets or injection cannulae'
  },
  {
    value: '42222202',
    label: 'Needleless vial or bag withdrawal cannulas or adapters or decanters'
  },
  { value: '42222300', label: 'Blood administration and transfusion products' },
  { value: '42222301', label: 'Blood transfusion administration kits' },
  { value: '42222302', label: 'Blood transfusion filters or screens' },
  {
    value: '42222303',
    label: 'Blood administration or transfusion identification systems'
  },
  { value: '42222304', label: 'Blood administration or transfusion tubing' },
  {
    value: '42222305',
    label: 'Blood administration or transfusion tubing clamps'
  },
  { value: '42230000', label: 'Clinical nutrition' },
  { value: '42231500', label: 'Enteral feeding equipment and supplies' },
  { value: '42231501', label: 'Enteral feeding infusion pumps' },
  { value: '42231504', label: 'Enteral nutrition bags or containers' },
  {
    value: '42231505',
    label: 'Enteral feeding set adapters or connectors or extensions'
  },
  {
    value: '42231600',
    label: 'Gastrostomy and jejunostomy access devices or accessories'
  },
  { value: '42231601', label: 'Gastrostomy tubes or kits for general use' },
  { value: '42231700', label: 'Nasoenteric tubes' },
  { value: '42231703', label: 'Gastric decompression tubes' },
  { value: '42231800', label: 'Formulas and products for nutritional support' },
  {
    value: '42231801',
    label: 'Adult supplemental formulas or bars or puddings for general use'
  },
  {
    value: '42231802',
    label: 'Pediatric supplemental formulas or bars or puddings for general use'
  },
  { value: '42231806', label: 'Medical nutrition food or liquid thickeners' },
  {
    value: '42240000',
    label: 'Orthopedic and prosthetic and sports medicine products'
  },
  { value: '42241500', label: 'Casting and splinting supplies' },
  { value: '42241502', label: 'Cast or splint padding materials' },
  { value: '42241503', label: 'Cast or splint protectors' },
  { value: '42241504', label: 'Cast or splint stockinet or liners' },
  { value: '42241505', label: 'Orthopedic casting rolls or tapes' },
  { value: '42241506', label: 'Orthopedic casting material for splints' },
  { value: '42241507', label: 'Orthopedic splint systems' },
  { value: '42241509', label: 'Thermoplastic orthoses components' },
  { value: '42241510', label: 'Thermoplastic splint kits or materials' },
  { value: '42241600', label: 'Casting equipment and parts and accessories' },
  { value: '42192409', label: 'Cast or splint carts or stands' },
  { value: '42241602', label: 'Cast cutters or saws' },
  { value: '42241603', label: 'Cast removal systems' },
  { value: '42241604', label: 'Cast vacuums' },
  { value: '42241700', label: 'Orthopedic softgoods for lower extremity' },
  { value: '42241701', label: 'Ankle or foot orthopedic softgoods' },
  { value: '42241702', label: 'Hip orthopedic softgoods' },
  { value: '42241703', label: 'Knee braces or supports' },
  { value: '42241705', label: 'Leg orthopedic softgoods' },
  { value: '42241706', label: 'Orthotics or foot care products' },
  { value: '42241707', label: 'Walking braces' },
  {
    value: '42241800',
    label: 'Orthopedic softgoods for upper extremity and torso'
  },
  { value: '42241801', label: 'Arm orthopedic softgoods' },
  { value: '42241802', label: 'Back or lumbar or sacral orthopedic softgoods' },
  { value: '42241803', label: 'Cervical collars or neck braces' },
  { value: '42241804', label: 'Clavicle orthopedic softgoods' },
  { value: '42241805', label: 'Elbow orthopedic softgoods' },
  {
    value: '42241806',
    label: 'Forearm or wrist or thumb orthopedic softgoods'
  },
  { value: '42241807', label: 'Hand or finger orthopedic softgoods' },
  { value: '42241808', label: 'Ribs or abdomen orthopedic softgoods' },
  { value: '42241809', label: 'Shoulder orthopedic softgoods' },
  { value: '42241900', label: 'Outrigger and dynamic splinting supplies' },
  { value: '42242100', label: 'Orthopedic traction supplies and accessories' },
  {
    value: '42250000',
    label: 'Physical and occupational therapy and rehabilitation products'
  },
  {
    value: '42251500',
    label:
      'Cognitive and dexterity and perceptual and sensory evaluation and therapy products'
  },
  { value: '42251501', label: 'Dressing education products' },
  {
    value: '42251502',
    label:
      'Cognitive or dexterity or perceptual or sensory evaluation or testing products'
  },
  { value: '42251503', label: 'Therapeutic games' },
  { value: '42251504', label: 'Therapeutic pegboards or activity boards' },
  { value: '42251505', label: 'Therapeutic puzzles' },
  { value: '42251700', label: 'Gait training products' },
  { value: '42251701', label: 'Gait belts for rehabilitation or therapy' },
  { value: '42251702', label: 'Training ramps for rehabilitation or therapy' },
  { value: '42251703', label: 'Training stairs for rehabilitation or therapy' },
  { value: '42261500', label: 'Pathology dissection instruments and supplies' },
  { value: '42261501', label: 'Autopsy scissors' },
  { value: '42261502', label: 'Autopsy dissection forceps for general use' },
  { value: '42261503', label: 'Autopsy bullet probes' },
  { value: '42261504', label: 'Autopsy thread or needle pullers' },
  { value: '42261505', label: 'Autopsy knives or blades' },
  { value: '42261506', label: 'Autopsy chisels or osteotomes' },
  { value: '42261507', label: 'Postmortem threads' },
  { value: '42261508', label: 'Postmortem needles' },
  { value: '42261509', label: 'Autopsy dissection kits' },
  { value: '42261510', label: 'Postmortem incision clips' },
  { value: '42261511', label: 'Autopsy vein directors' },
  { value: '42261512', label: 'Autopsy saws' },
  { value: '42261513', label: 'Autopsy saw blades' },
  { value: '42261514', label: 'Dissection boards or pads' },
  { value: '42261600', label: 'Autopsy equipment and supplies' },
  { value: '42261601', label: 'Bone dust collectors' },
  { value: '42261602', label: 'Medical body bags' },
  { value: '42261604', label: 'Autopsy head rests' },
  { value: '42261605', label: 'Autopsy body boards' },
  { value: '42261606', label: 'Autopsy hanging scales' },
  { value: '42261607', label: 'Autopsy specimen bags or containers' },
  { value: '42261608', label: 'Autopsy infectious disease kits' },
  { value: '42261609', label: 'Postmortem identification tags or bracelets' },
  {
    value: '42261610',
    label: 'Autopsy fluid collection vacuum aspirators or tubing'
  },
  { value: '42261611', label: 'Postmortem rectal thermometers' },
  { value: '42261612', label: 'Postmortem finger straighteners' },
  { value: '42261613', label: 'Cadaver tissue builder kits' },
  { value: '42261700', label: 'Autopsy furniture' },
  {
    value: '42261800',
    label: 'Cadaver transport and storage equipment and supplies'
  },
  { value: '42261801', label: 'Cadaver storage racks' },
  { value: '42261802', label: 'Cadaver carriers' },
  { value: '42261803', label: 'Cadaver scissor lift trolleys' },
  { value: '42261804', label: 'Morgue cabinet refrigerators' },
  { value: '42261805', label: 'Morgue walk in refrigerators' },
  { value: '42261806', label: 'Morgue freezers' },
  { value: '42261807', label: 'Autopsy carts' },
  { value: '42261808', label: 'Cadaver trays' },
  { value: '42261809', label: 'Cadaver lifter or transfer devices' },
  { value: '42261810', label: 'Body transport containers' },
  { value: '42261900', label: 'Clinical forensics equipment and supplies' },
  { value: '42262000', label: 'Embalming equipment and supplies' },
  {
    value: '42270000',
    label: 'Respiratory and anesthesia and resuscitation products'
  },
  { value: '42271500', label: 'Respiratory monitoring products' },
  { value: '42271502', label: 'Arterial blood gas monitors' },
  { value: '42271503', label: 'End tidal carbon dioxide monitors' },
  { value: '42271504', label: 'Esophageal stethoscopes' },
  {
    value: '42271600',
    label: 'Pulmonary function testing and treatment products'
  },
  { value: '42271701', label: 'Medical gas cylinders or related devices' },
  { value: '42271702', label: 'Oxygen concentrators' },
  { value: '42271703', label: 'Oxygen air blenders' },
  { value: '42271704', label: 'Oxygen timers' },
  { value: '42271705', label: 'Oxygen delivery connectors or adapters' },
  { value: '42271706', label: 'Respiratory therapy compressors' },
  { value: '42271707', label: 'Flow sensors or regulators or components' },
  { value: '42271709', label: 'Medical nasal cannulae' },
  {
    value: '42271710',
    label: 'Medical nasal catheters or catheterization kits'
  },
  { value: '42271711', label: 'Medical head hoods' },
  { value: '42271712', label: 'Medical aerosol tents' },
  { value: '42271714', label: 'Medical incubators' },
  { value: '42271715', label: 'Medical oxygen tubing or connectors' },
  {
    value: '42271800',
    label: 'Respiratory humidity and aerosol therapy products'
  },
  { value: '42271801', label: 'Respiratory humidifiers or vaporizers' },
  { value: '42271900', label: 'Airway management products' },
  { value: '42271902', label: 'Esophageal tubes' },
  { value: '42271903', label: 'Endotracheal tubes' },
  { value: '42271904', label: 'Tracheostomy tubes' },
  { value: '42271905', label: 'Endobronchial tubes' },
  { value: '42272000', label: 'Intubation supplies' },
  { value: '42192617', label: 'Laryngeal sprays' },
  { value: '42272003', label: 'Bite blocks' },
  { value: '42272004', label: 'Intubation stylets' },
  { value: '42272005', label: 'Intubation forceps' },
  { value: '42272006', label: 'Introducers' },
  { value: '42272007', label: 'Bender tools' },
  { value: '42272008', label: 'Intubation gauges or guides' },
  { value: '42272009', label: 'Patient carbon dioxide detectors' },
  { value: '42144406', label: 'Suction catheters' },
  { value: '42272100', label: 'Negative mechanical pressure ventilators' },
  {
    value: '42272200',
    label: 'Positive mechanical pressure ventilators and accessories'
  },
  { value: '42272204', label: 'Transport ventilators' },
  { value: '42272205', label: 'Intensive care ventilators' },
  { value: '42272207', label: 'High frequency ventilators' },
  { value: '42272215', label: 'Ventilator testing supplies' },
  { value: '42272216', label: 'Ventilator thermometers' },
  { value: '42272217', label: 'Ventilator water traps' },
  { value: '42272218', label: 'Ventilator gas sampling ports or lines' },
  {
    value: '42272219',
    label: 'Ventilator heat or moisture exchangers or filters'
  },
  { value: '42272220', label: 'Ventilator accessories' },
  { value: '42272222', label: 'Ventilator weaning products' },
  { value: '42272300', label: 'Resuscitation supplies' },
  { value: '42142537', label: 'Thoracentesis sets or trays' },
  { value: '42280000', label: 'Medical cleaning and sterilization products' },
  {
    value: '42281500',
    label: 'Autoclave and sterilizer equipment and accessories'
  },
  { value: '42282000', label: 'Instrument cleaning devices and accessories' },
  { value: '42281508', label: 'Steam autoclaves or sterilizers' },
  { value: '42281509', label: 'Sterilization containers or trays' },
  { value: '42281600', label: 'Cold sterilization and disinfectant solutions' },
  { value: '42281604', label: 'Medical surface disinfectants' },
  {
    value: '42281700',
    label: 'Disinfecting or presterilization cleaning equipment and solutions'
  },
  {
    value: '42281701',
    label: 'Chamber cleaners for autoclaves or sterilizers'
  },
  { value: '42281703', label: 'Instrument care kits' },
  { value: '42281704', label: 'Instrument cleaners or detergents' },
  {
    value: '42282010',
    label: 'Medical equipment and instrument disinfectant washing equipment'
  },
  { value: '42282017', label: 'Ultrasonic cleaning equipment' },
  { value: '42281800', label: 'Sterilization indicators and controls' },
  { value: '42281801', label: 'Disinfectant test strips' },
  { value: '42281802', label: 'Sterilization labels' },
  { value: '42281803', label: 'Sterilization biological kits' },
  { value: '42281804', label: 'Sterilization controls' },
  { value: '42281805', label: 'Sterilization indicator records' },
  { value: '42281806', label: 'Sterilization indicator strips' },
  { value: '42281807', label: 'Sterilization indicator tapes' },
  { value: '42281900', label: 'Sterilization wraps and packaging supplies' },
  {
    value: '42281901',
    label: 'Holders or carts for sterilization wrappers or pouches'
  },
  { value: '42281902', label: 'Sterilization wraps or overwraps' },
  { value: '42281903', label: 'Sterilization dust covers' },
  { value: '42281904', label: 'Sterilization pouches' },
  { value: '42281905', label: 'Sterilization heat sealers' },
  { value: '42281906', label: 'Sterilization instrument bands' },
  { value: '42281907', label: 'Sterilization instrument protectors' },
  { value: '42281908', label: 'Sterilization instrument tray liners' },
  { value: '42281909', label: 'Sterilization labeling guns' },
  { value: '42281912', label: 'Sterilization towels' },
  { value: '42281913', label: 'Sterilization tubing' },
  { value: '42290000', label: 'Surgical products' },
  {
    value: '42291500',
    label: 'Surgical bone biopsy instruments and related products'
  },
  {
    value: '42291600',
    label: 'Surgical cutting instruments and snares and related products'
  },
  {
    value: '42291700',
    label:
      'Surgical hand drills and reamers and puncturing instruments and accessories and related products'
  },
  {
    value: '42291800',
    label:
      'Surgical clamps and forceps and surgical ligators and related instruments'
  },
  {
    value: '42291801',
    label: 'Surgical band ligator appliers or bands or related products'
  },
  {
    value: '42291900',
    label: 'Surgical instrument and tube holders and positioners'
  },
  { value: '42292000', label: 'Surgical mirrors' },
  { value: '42292001', label: 'Surgical mirrors' },
  {
    value: '42292100',
    label: 'Surgical inserters and extractors and related products'
  },
  {
    value: '42292300',
    label:
      'Surgical bending irons and crimpers and pliers and tensioners and wrenches and related products'
  },
  {
    value: '42292400',
    label: 'Surgical taps and drivers and related products'
  },
  { value: '42292403', label: 'Surgical ratchet handles' },
  {
    value: '42292500',
    label:
      'Surgical hammers and mallets and impactors and presses and related products'
  },
  {
    value: '42292600',
    label: 'Surgical dilators and probes and grooves and related products'
  },
  {
    value: '42292700',
    label: 'Surgical dissectors and elevators and picks and related products'
  },
  { value: '42292800', label: 'Surgical marking instruments' },
  { value: '42292801', label: 'Ophthalmic marking instruments' },
  { value: '42292802', label: 'Surgical marking instruments for general use' },
  {
    value: '42292900',
    label: 'Suture and surgical tissue closure instruments and related products'
  },
  { value: '42292901', label: 'Surgical cerclage instruments' },
  { value: '42292903', label: 'Surgical needle holders' },
  {
    value: '42292904',
    label: 'Surgical suture or wire passers or related products'
  },
  {
    value: '42293000',
    label: 'Surgical measuring devices and related products'
  },
  { value: '42293001', label: 'Surgical calipers or rulers or protractors' },
  { value: '42293002', label: 'Surgical measuring gauges or rods' },
  { value: '42293003', label: 'Surgical graft measuring instruments' },
  { value: '42293004', label: 'Surgical sizing instruments or templates' },
  { value: '42293005', label: 'Surgical valve sizing instruments' },
  {
    value: '42293300',
    label:
      'Surgical distraction and compression and combination instruments and related product'
  },
  {
    value: '42293400',
    label: 'Surgical manipulators and implant positioners and related products'
  },
  { value: '42293500', label: 'Surgical suction and irrigation products' },
  {
    value: '42293600',
    label: 'Surgical bougies and sounds and related products'
  },
  {
    value: '42293700',
    label:
      'Surgical crushers and excavators and morselizers and related products'
  },
  {
    value: '42293800',
    label:
      'Surgical passers and searchers and tunnelers and strippers and related products'
  },
  {
    value: '42293900',
    label: 'Surgical wound packing instruments and related products'
  },
  { value: '42293901', label: 'Surgical laparotomy rings' },
  { value: '42293902', label: 'Surgical wound packing instruments' },
  {
    value: '42294000',
    label: 'Surgical spatulas and spoons and scoops and related products'
  },
  {
    value: '42294100',
    label: 'Surgical skeletal traction devices and related products'
  },
  {
    value: '42294200',
    label: 'Surgical instrument sets and systems and trays'
  },
  { value: '42294202', label: 'External fixation instrument sets or systems' },
  { value: '42294203', label: 'General surgical instrument sets' },
  { value: '42294212', label: 'Urological surgical instrument sets' },
  {
    value: '42294300',
    label:
      'Minimally invasive breast biopsy instruments and supplies and equipment'
  },
  { value: '42294400', label: 'Vascular and cardiac systems' },
  { value: '42294401', label: 'Vein harvest kits or systems' },
  { value: '42294402', label: 'Coronary visualization systems' },
  { value: '42294600', label: 'Autotransfusion products' },
  { value: '42294601', label: 'Autotransfusion blood or transfer bags' },
  { value: '42294602', label: 'Autotransfusion bowl kits or centrifugal kits' },
  { value: '42294603', label: 'Autotransfusion units' },
  { value: '42294604', label: 'Autotransfusion filters' },
  { value: '42294605', label: 'Autotransfusion reservoirs ' },
  { value: '42294606', label: 'Autotransfusion tubing sets or kits' },
  { value: '42294607', label: 'Autotransfusion valves' },
  {
    value: '42294700',
    label:
      'Open heart perfusion equipment and monitors and accessories and related products'
  },
  { value: '42294900', label: 'Endoscopic instruments' },
  {
    value: '42296401',
    label: 'Endoscope or instrument positioners or holders'
  },
  { value: '42294902', label: 'Endoscopic applicators or elevators' },
  { value: '42294903', label: 'Endoscopic aspiration or biopsy needles' },
  { value: '42294904', label: 'Endoscopic bite blocks or straps' },
  {
    value: '42294905',
    label: 'Endoscopic cleaning brushes or related products'
  },
  { value: '42294906', label: 'Endoscopic cutting instruments' },
  { value: '42294907', label: 'Endoscopic cytology or microbiology brushes' },
  {
    value: '42294908',
    label: 'Endoscopic clamp or dissector or grasper or forceps'
  },
  { value: '42294909', label: 'Endoscopic dilators or inflation devices' },
  { value: '42294910', label: 'Endoscopic monopolar or bipolar cable' },
  {
    value: '42296402',
    label: 'Endoscopic fluid management systems or accessories'
  },
  {
    value: '42294913',
    label: 'Endoscopic hemostatic balloons or needles or tubes or accessories'
  },
  { value: '42294914', label: 'Endoscopic instrument sets' },
  { value: '42294915', label: 'Endoscopic instrument spreaders' },
  { value: '42296413', label: 'Endoscopic insufflation filters' },
  { value: '42294917', label: 'Endoscopic insufflation needles' },
  { value: '42296414', label: 'Endoscopic insufflation tubing' },
  { value: '42294919', label: 'Endoscopic guidewire or glidewire' },
  { value: '42294920', label: 'Endoscopic laser instruments' },
  { value: '42294921', label: 'Endoscopic knot pushers or delivery systems' },
  { value: '42294922', label: 'Endoscopic ligators' },
  { value: '42294923', label: 'Endoscopic manipulators' },
  {
    value: '42294924',
    label: 'Endoscopic monopolar or bipolar hand instruments'
  },
  { value: '42294925', label: 'Endoscopic needles or punches' },
  { value: '42294926', label: 'Endoscopic overtubes' },
  { value: '42294927', label: 'Endoscopic instrument packs or trays or kits' },
  { value: '42294928', label: 'Endoscopic probes' },
  { value: '42294929', label: 'Endoscopic retractors' },
  { value: '42294930', label: 'Endoscopic snares or snare wires' },
  { value: '42294931', label: 'Endoscopic specimen retrieval forceps' },
  {
    value: '42294933',
    label: 'Endoscopic suction or irrigation tips or coagulation probes'
  },
  { value: '42294934', label: 'Endoscopic suturing devices' },
  {
    value: '42294978',
    label: 'Endoscopic trocars or sheathes or obturators or cannulae'
  },
  {
    value: '42294936',
    label: 'Endoscopic working elements or working channels'
  },
  {
    value: '42294937',
    label: 'Fog reduction devices for endoscopes or mirrors'
  },
  {
    value: '42295000',
    label: 'Endoscopic equipment accessories and related products'
  },
  { value: '42295001', label: 'Endoscope maintenance units' },
  { value: '42295002', label: 'Endoscope storage cabinets' },
  { value: '42295003', label: 'Endoscope wall hangers' },
  { value: '42295004', label: 'Endoscopic equipment or procedure carts' },
  { value: '42295005', label: 'Endoscopic equipment sets' },
  {
    value: '42295006',
    label: 'Endoscopic heater probe units or heater probes'
  },
  { value: '42296404', label: 'Endoscopic imaging systems or accessories' },
  {
    value: '42296406',
    label: 'Endoscopic insufflation or distention units or accessories'
  },
  { value: '42294972', label: 'Endoscopic or surgical light sources' },
  { value: '42296407', label: 'Endoscopic printers or film or accessories' },
  {
    value: '42296408',
    label: 'Endoscopic video cameras or recorders or adapters or accessories'
  },
  { value: '42296409', label: 'Endoscopic water bottles or accessories' },
  {
    value: '42295100',
    label: 'Surgical equipment and accessories and related products'
  },
  { value: '42295101', label: 'Basin or pail stands for surgical use' },
  { value: '42295104', label: 'Electrosurgical or electrocautery equipment' },
  {
    value: '42293518',
    label:
      'Surgical irrigation pump equipment or pulsed lavage with or without suction'
  },
  {
    value: '42295123',
    label:
      'Surgical suction machines or vacuum extractors or ultrasonic surgical aspirators or regulators'
  },
  {
    value: '42295200',
    label: 'Surgical power equipment and accessories and related products'
  },
  {
    value: '42295201',
    label: 'Surgical dermatomes or dermabraders or dermameshers'
  },
  {
    value: '42295202',
    label:
      'Surgical pneumatic or battery or electric saws or drills or pin drivers'
  },
  { value: '42295203', label: 'Surgical power equipment sets' },
  { value: '42295204', label: 'Surgical power reamers ' },
  {
    value: '42295300',
    label: 'Open heart surgical supplies and accessories and related products'
  },
  { value: '42295400', label: 'Surgical support supplies' },
  { value: '42295401', label: 'Battery operated surgical cautery pencils' },
  { value: '42295402', label: 'Surgical marking pens' },
  {
    value: '42294222',
    label: 'Introducers or guide pins or guidewires or glidewires or guide rods'
  },
  {
    value: '42295406',
    label: 'Laparotomy or surgical x-ray detectable sponges or towels'
  },
  { value: '42295408', label: 'Surgical scrub brushes' },
  { value: '42295409', label: 'Surgical applicators' },
  { value: '42295410', label: 'Surgical basin sets or packs' },
  { value: '42295411', label: 'Surgical blade holders or breakers' },
  { value: '42295413', label: 'Surgical canal brushes' },
  { value: '42295414', label: 'Surgical specialty procedure packs' },
  { value: '42295415', label: 'Surgical equipment covers' },
  { value: '42142734', label: 'Urological evacuators' },
  { value: '42295417', label: 'Surgical light handle covers' },
  { value: '42295418', label: 'Surgical magnetic mats' },
  { value: '42295419', label: 'Surgical nerve stimulators' },
  { value: '42295421', label: 'Surgical prep scrub or paint solutions' },
  { value: '42295422', label: 'Surgical scrub or prep kits for patient' },
  { value: '42295423', label: 'Surgical sharps or sponge counters' },
  {
    value: '42295424',
    label: 'Surgical shave kits or prep razors or clippers'
  },
  {
    value: '42295426',
    label: 'Surgical specimen collection traps or containers'
  },
  {
    value: '42295427',
    label:
      'Surgical instrument brushes or instrument stylets or instrument wipes'
  },
  { value: '42131721', label: 'Surgical instrument pouches' },
  { value: '42142736', label: 'Urological surgical catheters' },
  {
    value: '42295500',
    label:
      'Surgical implants and expanders and extenders and surgical wires and related products'
  },
  { value: '42296000', label: 'Cardiovascular implants' },
  { value: '42295502', label: 'Human tissue implants' },
  { value: '42296100', label: 'Neurosurgical implants' },
  { value: '42295513', label: 'Surgical mesh or tissue barriers' },
  { value: '42300000', label: 'Medical training and education supplies' },
  { value: '42301500', label: 'Aids for medical training' },
  {
    value: '42301501',
    label: 'Anatomical human models for medical education or training'
  },
  {
    value: '42301502',
    label: 'Anatomical human mannequins for medical education or training'
  },
  {
    value: '42301503',
    label: 'Cardio pulmonary resuscitation CPR training aids'
  },
  { value: '42301504', label: 'Kits for medical education or training' },
  { value: '42301505', label: 'Nursing or medical clipboards' },
  { value: '42301506', label: 'Dual earpiece stethoscopes' },
  { value: '42310000', label: 'Wound care products' },
  { value: '42311500', label: 'Bandages and dressings and related products' },
  { value: '42311501', label: 'Clips for bandages or dressings' },
  { value: '42311502', label: 'Bandage or dressing trays' },
  { value: '42311503', label: 'Bandage rollers' },
  { value: '42311506', label: 'Compression bandages' },
  { value: '42311510', label: 'Foam dressings' },
  { value: '42311512', label: 'Gauze sponges' },
  { value: '42311513', label: 'Gel dressings' },
  { value: '42311515', label: 'Hydrocolloid dressings' },
  { value: '42311517', label: 'Liquid adhesives for bandages or dressings' },
  { value: '42311518', label: 'Medical eye pads or patches' },
  { value: '42311519', label: 'Medical non adherent straps' },
  { value: '42311520', label: 'Medical non adherent tapes' },
  { value: '42312603', label: 'Negative pressure dressings' },
  { value: '42311523', label: 'Paste bandages' },
  { value: '42311524', label: 'Petrolatum dressings' },
  { value: '42311527', label: 'Transparent film dressings' },
  { value: '42311528', label: 'Wet dressing systems' },
  { value: '42311600', label: 'Hemostatic agents' },
  { value: '42311548', label: 'Medical or surgical tape dispensers' },
  { value: '42311551', label: 'Medical or surgical tape removers' },
  {
    value: '42311552',
    label: 'Medical and surgical adherent tapes for general use'
  },
  { value: '42312000', label: 'Medical tissue closure and related products' },
  { value: '42312010', label: 'Medical staplers for external use' },
  {
    value: '42312100',
    label: 'Ostomy supplies and non surgical wound drainage products'
  },
  { value: '42312101', label: 'Ostomy appliance adhesives' },
  { value: '42312102', label: 'Ostomy appliances' },
  { value: '42312103', label: 'Ostomy cleaners or deodorants' },
  { value: '42312105', label: 'Ostomy skin barriers or protective care kits' },
  { value: '42312106', label: 'Ostomy inserts' },
  { value: '42312107', label: 'Ostomy wafers' },
  { value: '42312108', label: 'Wound drainage pouches' },
  { value: '42312200', label: 'Sutures and related products' },
  { value: '42312201', label: 'Sutures' },
  { value: '42312202', label: 'Suturing kits or trays or packs or sets' },
  { value: '42312203', label: 'Suture buttons or bridges' },
  { value: '42312204', label: 'Suture boots and capturing devices' },
  { value: '42192413', label: 'Suture carts or racks' },
  { value: '42312207', label: 'Suture removal kits or trays or packs or sets' },
  { value: '42312208', label: 'Suture removers' },
  { value: '42312300', label: 'Wound cleaning and debridement products' },
  { value: '42312301', label: 'Absorbers for wound cleansing' },
  { value: '42312302', label: 'Debridement sponges' },
  { value: '42312303', label: 'Pulsed lavage systems for wound treatment' },
  { value: '42312304', label: 'Autolytic debridement products' },
  { value: '42312305', label: 'Enzymatic debridement products' },
  { value: '42312306', label: 'Mechanical debridement products' },
  { value: '42312307', label: 'Surgical debridement products' },
  { value: '42312400', label: 'Wound packing products' },
  { value: '42312401', label: 'Calcium alginate wound packing' },
  { value: '42312402', label: 'Nasal splints or stents' },
  { value: '42312403', label: 'Packing strips for wound care' },
  { value: '72141510', label: 'Demolition services' },
  { value: '80101508', label: 'Business intelligence consulting services' },
  { value: '41101516', label: 'Dounce homogenizers' },
  { value: '41101518', label: 'Laboratory blenders or emulsifiers' },
  { value: '41102422', label: 'Dry baths or heating blocks' },
  { value: '41102423', label: 'Stirring hotplates' },
  { value: '41102425', label: 'Slide dryers' },
  { value: '41102426', label: 'Heating or drying equipment or accessories' },
  { value: '41102606', label: 'Laboratory animal restraints or harnesses' },
  { value: '41102915', label: 'Histology sampling and dissecting stations' },
  { value: '41102917', label: 'Microtome blades' },
  { value: '41102918', label: 'Laboratory cover slippers' },
  { value: '41102919', label: 'Solvent recyclers' },
  { value: '41102920', label: 'Histology tissue cassettes' },
  { value: '41102921', label: 'Histology paraffin' },
  { value: '41102922', label: 'Automated cover slipping equipment' },
  { value: '41103010', label: 'Blood bank refrigerators' },
  {
    value: '41103011',
    label: 'General purpose refrigerators or refrigerator freezers'
  },
  {
    value: '41103012',
    label: 'Flammable material storage refrigerators or refrigerator freezers'
  },
  {
    value: '41103013',
    label: 'Explosion proof refrigerators or refrigerator freezers'
  },
  { value: '41103014', label: 'Chromatography refrigerators' },
  { value: '41103015', label: 'Blood bank freezers' },
  { value: '41103017', label: 'Flammable material storage freezers' },
  { value: '41103019', label: 'Plasma storage freezers' },
  { value: '41103020', label: 'Ultra cold or ultralow chest freezers' },
  { value: '41103022', label: 'Cool transport or storage' },
  { value: '41103023', label: 'Laboratory chillers' },
  { value: '41103024', label: 'Cold traps' },
  { value: '41103025', label: 'Laboratory cooling equipment accessories' },
  { value: '41103205', label: 'Washing machine racks or accessories' },
  { value: '41103206', label: 'Laboratory washing detergents' },
  { value: '41103207', label: 'Microplate washers' },
  { value: '41103208', label: 'Blood bank cell washers' },
  { value: '41103407', label: 'Anaerobic chamber' },
  {
    value: '41103408',
    label: 'Refrigerated reach in environmental or growth chambers'
  },
  {
    value: '41103409',
    label: 'Heated reach in environmental or growth chambers'
  },
  {
    value: '41103410',
    label: 'Refrigerated and heated reach in environmental or growth chambers'
  },
  {
    value: '41103411',
    label: 'Refrigerated walk in environmental or growth chambers'
  },
  {
    value: '41103412',
    label: 'Heated walk in environmental or growth chambers'
  },
  {
    value: '41103413',
    label: 'Refrigerated and heated walk in environmental or growth chambers'
  },
  {
    value: '41103414',
    label: 'Laboratory environmental conditioning equipment accessories'
  },
  { value: '41103506', label: 'PCR enclosures' },
  { value: '41103507', label: 'HEPA filtered enclosures' },
  { value: '41103508', label: 'Carbon filtered enclosures' },
  { value: '41103510', label: 'Laboratory blowers' },
  { value: '41103511', label: 'Laboratory enclosure accessories' },
  { value: '41103513', label: 'Tissue culture enclosures' },
  { value: '41103710', label: 'Orbital shaking water baths' },
  { value: '41103711', label: 'Reciprocating shaking water baths' },
  { value: '41103712', label: 'Immersion circulators' },
  { value: '41103713', label: 'Viscosity baths' },
  { value: '41103714', label: 'Tissue flotation baths' },
  { value: '41103715', label: 'Laboratory bath accessories or supplies' },
  {
    value: '41103800',
    label: 'Laboratory mixing and stirring and shaking equipment and supplies'
  },
  { value: '41103801', label: 'Laboratory mixers' },
  { value: '41103806', label: 'Magnetic stirrers' },
  { value: '41103807', label: 'Laboratory touch mixers' },
  { value: '41103808', label: 'Platelet mixers' },
  { value: '41103809', label: 'Hematology or chemistry mixers' },
  { value: '41103810', label: 'Overhead stirrers' },
  { value: '41103811', label: 'Orbital shakers' },
  { value: '41103812', label: 'Reciprocal shakers' },
  { value: '41103813', label: 'Rotating shakers' },
  { value: '41103814', label: 'Vortex mixers' },
  { value: '41103815', label: 'Tube rotators' },
  { value: '41103816', label: 'Mixer or shaker accessories or attachments' },
  { value: '41103901', label: 'Microcentrifuges' },
  { value: '41103902', label: 'Refrigerated microcentrifuges' },
  { value: '41103903', label: 'Benchtop centrifuges' },
  { value: '41103904', label: 'Refrigerated benchtop centrifuges' },
  { value: '41103905', label: 'Floor centrifuges' },
  { value: '41103906', label: 'Refrigerated floor centrifuges' },
  { value: '41103907', label: 'Ultracentrifuges' },
  { value: '41103908', label: 'Vacuum centrifuges' },
  { value: '41103909', label: 'Centrifuge rotors' },
  { value: '41103910', label: 'Centrifuge buckets' },
  { value: '41103911', label: 'Centrifuge adapters' },
  { value: '41103912', label: 'Centrifuge brushes' },
  { value: '41103913', label: 'Laboratory centrifuge accessories' },
  { value: '41104000', label: 'Sampling equipment' },
  { value: '41104005', label: 'Laboratory bailers' },
  { value: '41104006', label: 'Coliwasas' },
  { value: '41104009', label: 'Air sampling pumps' },
  {
    value: '41104100',
    label: 'Specimen collection and transport containers and supplies'
  },
  { value: '41104101', label: 'Slide or specimen mailers or shippers' },
  { value: '41104102', label: 'Lancets' },
  { value: '41104103', label: 'Heel warmers' },
  { value: '41104104', label: 'Tourniquets' },
  { value: '41104105', label: 'Specimen collection or transport bags' },
  { value: '41104106', label: 'Phlebotomy trays or accessories' },
  { value: '41104107', label: 'Vacuum blood collection tubes or containers' },
  {
    value: '41104108',
    label: 'Non vacuum blood collection tubes or containers'
  },
  { value: '41104109', label: 'Blood unit collection bags' },
  { value: '41104110', label: 'Blood culture bottles' },
  { value: '41104111', label: 'Cytology collection kits or containers' },
  { value: '41104112', label: 'Urine collection containers' },
  { value: '41104114', label: 'Frepp Sepp collection containers' },
  { value: '41104115', label: 'Serum Filter collection containers' },
  { value: '41104116', label: 'Swab collection or transport containers' },
  { value: '41104119', label: 'Bone tissue collection containers' },
  { value: '41104120', label: 'Sedimentation rate tubes' },
  { value: '41104121', label: 'Stool collection containers with media' },
  { value: '41104122', label: 'Stool collection containers without media' },
  { value: '41104123', label: 'Sputum collection apparatus or containers' },
  {
    value: '41104200',
    label: 'Laboratory water purification equipment and supplies'
  },
  { value: '41104211', label: 'Softeners' },
  { value: '41104212', label: 'Water filtration cartridges' },
  { value: '41104300', label: 'Fermentation equipment' },
  { value: '41104307', label: 'Microbiology fermentation equipment' },
  {
    value: '41104308',
    label: 'Anaerobic environmental culture systems or supplies'
  },
  { value: '41104400', label: 'Laboratory incubating equipment' },
  { value: '41104401', label: 'Gravity convection general purpose incubators' },
  {
    value: '41104402',
    label: 'Forced air or mechanical convection general purpose incubators'
  },
  {
    value: '41104404',
    label: 'Cooled biological oxygen demand BOD incubators'
  },
  {
    value: '41104413',
    label:
      'Dry wall single chamber carbon dioxide incubators with humidity control'
  },
  {
    value: '41104414',
    label:
      'Dry wall dual chamber carbon dioxide incubators with humidity control'
  },
  {
    value: '41104415',
    label: 'Water jacketed single chamber three gas incubators'
  },
  {
    value: '41104416',
    label: 'Water jacketed dual chamber three gas incubators'
  },
  {
    value: '41104417',
    label:
      'Water jacketed single chamber three gas incubators with humidity control'
  },
  {
    value: '41104418',
    label:
      'Water jacketed dual chamber three gas incubators with humidity control'
  },
  { value: '41104419', label: 'Dry wall single chamber three gas incubators' },
  { value: '41104420', label: 'Dry wall dual chamber three gas incubators' },
  { value: '41104423', label: 'Refrigerated incubators' },
  { value: '41104424', label: 'Incubator accessories' },
  { value: '41104500', label: 'Laboratory ovens and accessories' },
  { value: '41104501', label: 'Laboratory mechanical convection ovens' },
  { value: '41104502', label: 'Gravity convection ovens' },
  { value: '41104504', label: 'Cleanroom ovens' },
  { value: '41104506', label: 'Laboratory safety ovens' },
  { value: '41104507', label: 'Laboratory microwave ovens' },
  { value: '41104510', label: 'Drying cabinets or ovens' },
  { value: '41104511', label: 'Hybridization ovens or incubators' },
  { value: '41104512', label: 'Laboratory oven accessories' },
  { value: '41104601', label: 'Laboratory box furnaces' },
  { value: '41104602', label: 'Programmable box furnaces' },
  { value: '41104603', label: 'Tube furnaces' },
  { value: '41104604', label: 'Programmable tube furnaces' },
  { value: '41104605', label: 'Crucible furnaces' },
  { value: '41104609', label: 'Laboratory safety furnaces' },
  { value: '41104610', label: 'Laboratory furnace hearthplates' },
  { value: '41104611', label: 'Laboratory furnace replacement insulation' },
  { value: '41104612', label: 'Laboratory furnace accessories' },
  {
    value: '41104700',
    label: 'Laboratory freeze dryers and lyopholizers and accessories'
  },
  { value: '41104701', label: 'Freeze dryers or lyopholizers' },
  { value: '41104702', label: 'Freeze dryer glassware' },
  { value: '41104703', label: 'Tray dryers' },
  { value: '41104704', label: 'Freeze dryer or lyopholizer accessories' },
  {
    value: '41104800',
    label:
      'Laboratory decanting and distilling and evaporating and extracting equipment and supplies'
  },
  { value: '41104804', label: 'Vacuum or rotary evaporators' },
  { value: '41104805', label: 'Nitrogen blowdown evaporators' },
  { value: '41104811', label: 'Density gradient fractionators' },
  { value: '41104815', label: 'Kjeldahl nitrogen determination apparatus' },
  { value: '41104816', label: 'Vacuum or centrifugal concentrators' },
  { value: '41104817', label: 'Extraction thimbles' },
  { value: '41104900', label: 'Laboratory filtering equipment and supplies' },
  { value: '41104910', label: 'Laboratory multi sheet or press filters' },
  { value: '41104913', label: 'Bioseparation filters' },
  { value: '41104914', label: 'Bottletops or filtration cups' },
  { value: '41104915', label: 'Capsules filters' },
  { value: '41104916', label: 'Centrifugal filters' },
  { value: '41104917', label: 'Laboratory environmental filters' },
  { value: '41104918', label: 'Laboratory glass filters' },
  { value: '41104920', label: 'Hybridization filters' },
  { value: '41104921', label: 'Laboratory membrane filters' },
  { value: '41104922', label: 'Syringe filters' },
  { value: '41104923', label: 'Multiwell plate filters' },
  { value: '41104924', label: 'Microbiology filters' },
  { value: '41104925', label: 'Laboratory filtration hardware or accessories' },
  {
    value: '41105000',
    label: 'Laboratory sieves and sifting equipment and supplies'
  },
  { value: '41105100', label: 'Laboratory pumps and tubing' },
  { value: '41105102', label: 'Peristaltic pumps' },
  { value: '41105104', label: 'Syringe pumps' },
  { value: '41105105', label: 'Metering pumps' },
  { value: '41105106', label: 'Chromatography pumps' },
  { value: '41105108', label: 'Laboratory general purpose tubing' },
  {
    value: '41105200',
    label: 'Laboratory slide stainer equipment and accessories'
  },
  { value: '41105202', label: 'Hematology slide stainers' },
  { value: '41105203', label: 'Microbiology slide stainers' },
  { value: '41105204', label: 'Laboratory slide stainer accessories' },
  {
    value: '41105300',
    label: 'Laboratory electrophoresis and blotting system and supplies'
  },
  { value: '41105301', label: 'Gel boxes' },
  { value: '41105302', label: 'Gel dryers' },
  { value: '41105303', label: 'Electrophoresis system power supplies' },
  { value: '41105304', label: 'Transilluminators' },
  { value: '41105305', label: 'Electrophoresis system accessories' },
  { value: '41111512', label: 'Triple beam balances' },
  { value: '41111513', label: 'Moisture balances' },
  {
    value: '41111515',
    label: 'Balance weighing containers or bowls or boats or papers'
  },
  { value: '41111516', label: 'Weight measuring instrument accessories' },
  { value: '41111517', label: 'Analytical balances' },
  { value: '41111620', label: 'Etalon wedge' },
  { value: '41111622', label: 'Micrometer calipers' },
  { value: '41111623', label: 'Thickness measuring devices' },
  { value: '41111717', label: 'Binoculars' },
  { value: '41111718', label: 'Metallurgical microscopes' },
  { value: '41111719', label: 'Darkfield microscopes' },
  { value: '41111721', label: 'Transmission electron microscopes' },
  { value: '41111722', label: 'Fluorescent microscopes' },
  {
    value: '41111723',
    label: 'Scanning light or spinning disk or laser scanning microscopes'
  },
  { value: '41111724', label: 'Scanning probe microscopes' },
  { value: '41111725', label: 'Polarizing microscopes' },
  { value: '41111726', label: 'Acoustic microscopes' },
  { value: '41111727', label: 'Projection microscopes' },
  { value: '41111728', label: 'Wide field microscopes' },
  { value: '41111729', label: 'Microscope eyepieces' },
  { value: '41111730', label: 'Microscope condensers' },
  { value: '41111731', label: 'Microscope collectors' },
  { value: '41111733', label: 'Microscope tubes' },
  { value: '41111734', label: 'Microscope stages' },
  { value: '41111735', label: 'Automated microscope stages' },
  { value: '41111736', label: 'Microscope covers' },
  { value: '41111926', label: 'Proximity sensors' },
  { value: '41111927', label: 'Pressure sensors' },
  { value: '41111928', label: 'Current sensors' },
  { value: '41112103', label: 'Fiber sensors' },
  { value: '41112213', label: 'Handheld thermometer' },
  { value: '41112214', label: 'Cryogenic temperature controllers' },
  { value: '41112215', label: 'Humidifier temperature controllers' },
  { value: '41112407', label: 'Liquid level controls or instruments' },
  { value: '41112505', label: 'Water meter spares' },
  { value: '41112506', label: 'Venturis' },
  { value: '41112508', label: 'Gas gauges' },
  { value: '41112509', label: 'Air velocity and temperature monitors' },
  { value: '41112600', label: 'Hygiene monitoring and testing equipment' },
  { value: '41112601', label: 'Manual swab test kits' },
  { value: '41112602', label: 'Automated swab test kits' },
  { value: '41113116', label: 'Gas detector tubes' },
  { value: '41113117', label: 'Single gas monitors' },
  { value: '41113118', label: 'Multi gas monitors' },
  { value: '41113641', label: 'GFI circuit testers' },
  { value: '41113642', label: 'Circuit tester' },
  { value: '41113643', label: 'Demand meters or registers' },
  { value: '41113644', label: 'Circuit tracers' },
  { value: '41114410', label: 'Weather stations' },
  { value: '41114411', label: 'Meteorology instrument accessories' },
  {
    value: '41115300',
    label: 'Light and wave generating and measuring equipment'
  },
  { value: '41115312', label: 'Bench refractometers or polarimeters' },
  { value: '41115319', label: 'Tube or plate readers' },
  { value: '41115400', label: 'Spectroscopic equipment' },
  { value: '41115401', label: 'Spectrofluorimeters or fluorimeters' },
  { value: '41115407', label: 'Atomic absorption AA spectrometers' },
  { value: '41115408', label: 'Infrared spectrometers' },
  { value: '41115409', label: 'Nuclear magnetic resonance NMR spectrometers' },
  { value: '41115411', label: 'Inductively coupled plasma ICP spectrometers' },
  { value: '41115500', label: 'Sound generating and measuring equipment' },
  {
    value: '41115600',
    label: 'Electrochemical measuring instruments and accessories'
  },
  { value: '41115601', label: 'Karl Fischer titration equipment' },
  {
    value: '41115700',
    label: 'Chromatographic measuring instruments and accessories'
  },
  {
    value: '41115709',
    label: 'High pressure liquid chromatography HPLC columns'
  },
  {
    value: '41115800',
    label: 'Clinical and diagnostic analyzers and accessories and supplies'
  },
  { value: '41115802', label: 'Amino acid analyzer accessories or supplies' },
  { value: '41115803', label: 'Blood bank analyzers' },
  { value: '41115804', label: 'Blood bank analyzer accessories or supplies' },
  { value: '41115805', label: 'Blood gas analyzers' },
  { value: '41115806', label: 'Blood gas analyzer accessories or supplies' },
  { value: '41115807', label: 'Chemistry analyzers' },
  { value: '41115808', label: 'Chemistry analyzer accessories or supplies' },
  { value: '41115809', label: 'Coagulation analyzers' },
  { value: '41115810', label: 'Coagulation analyzer accessories or supplies' },
  { value: '41115811', label: 'Deoxyribonucleic sequence analyzers' },
  {
    value: '41115812',
    label: 'Deoxyribonucleic sequence analyzer accessories or supplies'
  },
  { value: '41115813', label: 'Toxicology analyzers' },
  { value: '41115814', label: 'Toxicology analyzers accessories or supplies' },
  { value: '41115815', label: 'Hematology analyzers' },
  { value: '41115816', label: 'Hematology analyzer accessories or supplies' },
  { value: '41115817', label: 'Histology analyzers' },
  { value: '41115818', label: 'Histology analyzer accessories or supplies' },
  { value: '41115819', label: 'Immunology analyzers' },
  { value: '41115820', label: 'Immunology analyzer accessories or supplies' },
  { value: '41115821', label: 'Microbiology analyzers' },
  { value: '41115822', label: 'Microbiology analyzer accessories or supplies' },
  { value: '41115824', label: 'Protein analyzer accessories or supplies' },
  { value: '41115825', label: 'Radioisotopic analyzers' },
  {
    value: '41115826',
    label: 'Radioisotopic analyzer accessories or supplies'
  },
  { value: '41115827', label: 'Urinalysis analyzers' },
  { value: '41115828', label: 'Urinalysis analyzer accessories or supplies' },
  { value: '41116000', label: 'Clinical and diagnostic analyzer reagents' },
  { value: '41116001', label: 'Amino acid analyzer reagents' },
  { value: '41116002', label: 'Blood bank analyzer reagents' },
  { value: '41116003', label: 'Blood gas analyzer reagents' },
  { value: '41116004', label: 'Chemistry analyzer reagents' },
  { value: '41116005', label: 'Coagulation analyzer reagents' },
  {
    value: '41116006',
    label: 'Deoxyribonucleic acid DNA sequence analyzer reagents'
  },
  { value: '41116007', label: 'Toxicology analyzer reagents' },
  { value: '41116008', label: 'Hematology analyzer reagents' },
  { value: '41116009', label: 'Histology analyzer reagents' },
  { value: '41116010', label: 'Immunology analyzer reagents' },
  { value: '41116011', label: 'Microbiology analyzer reagents' },
  { value: '41116012', label: 'Protein analyzer reagents' },
  { value: '41116013', label: 'Radioisotopic analyzer reagents' },
  {
    value: '41116100',
    label: 'Manual test kits and quality controls and calibrators and standards'
  },
  { value: '41116101', label: 'Blood bank test kits or supplies' },
  { value: '41116102', label: 'Blood bank reagents or solutions' },
  {
    value: '41116103',
    label: 'Blood bank quality controls or calibrators or standards'
  },
  { value: '41116104', label: 'Chemistry test kits or supplies' },
  { value: '41116105', label: 'Chemistry reagents or solutions' },
  { value: '41116106', label: 'Chemistry test strips or test paper' },
  {
    value: '41116107',
    label: 'Chemistry quality controls or calibrators or standards'
  },
  { value: '41116108', label: 'Coagulation test kits or supplies' },
  { value: '41116109', label: 'Coagulation reagents or solutions' },
  {
    value: '41116110',
    label: 'Coagulation quality controls or calibrators or standards'
  },
  { value: '41116111', label: 'Cytology test kits or supplies' },
  {
    value: '41116112',
    label: 'Cytology quality controls or calibrators or standards'
  },
  { value: '41116113', label: 'Cytology reagents or solutions or stains' },
  { value: '41116116', label: 'Environmental test kits or supplies' },
  { value: '41116117', label: 'Environmental reagents or solutions or stains' },
  { value: '41116118', label: 'Food test kits or supplies' },
  {
    value: '41116119',
    label: 'Food test kits reagents or solutions or stains'
  },
  { value: '41116120', label: 'Hematology test kits or supplies' },
  { value: '41116121', label: 'Hematology reagents or solutions or stains' },
  {
    value: '41116122',
    label: 'Hematology quality controls or calibrators or standards'
  },
  { value: '41116123', label: 'Histology test kits or supplies' },
  { value: '41116124', label: 'Histology reagents or solutions or stains' },
  {
    value: '41116125',
    label: 'Histology quality controls or calibrators or standards'
  },
  { value: '41116126', label: 'Immunology or serology test kits or supplies' },
  {
    value: '41116127',
    label: 'Immunology or serology reagents or solutions or stains'
  },
  {
    value: '41116128',
    label: 'Immunology or serology quality controls or calibrators or standards'
  },
  {
    value: '41116129',
    label: 'Microbiology or bacteriology test kits or supplies'
  },
  {
    value: '41116130',
    label: 'Microbiology or bacteriology reagents or solutions or stains'
  },
  {
    value: '41116131',
    label:
      'Microbiology or bacteriology identification or sensitivity disks or panels'
  },
  {
    value: '41116132',
    label:
      'Microbiology or bacteriology quality controls or calibrators or standards'
  },
  { value: '41116133', label: 'Molecular biology test kits or supplies' },
  {
    value: '41116134',
    label: 'Molecular biology reagents or solutions or stains'
  },
  {
    value: '41116135',
    label: 'Molecular biology quality controls or calibrators or standards'
  },
  { value: '41116136', label: 'Urinalysis test kits or supplies' },
  { value: '41116137', label: 'Urinalysis reagents or solutions or stains' },
  { value: '41116138', label: 'Urinalysis test strips' },
  {
    value: '41116139',
    label: 'Urinalysis quality controls or calibrators or standards'
  },
  {
    value: '41116140',
    label: 'Parasitology or mycology test kits or supplies'
  },
  {
    value: '41116141',
    label: 'Parasitology or mycology reagents or solutions or stains'
  },
  { value: '41116142', label: 'Parasitology or mycology media' },
  {
    value: '41116143',
    label:
      'Parasitology or mycology quality controls or calibrators or standards'
  },
  { value: '41116144', label: 'Virology test kits or supplies' },
  {
    value: '41116145',
    label: 'Virology quality controls or calibrators or standards'
  },
  { value: '41116146', label: 'Toxicology test kits or supplies' },
  {
    value: '41116147',
    label: 'Toxicology quality controls or calibrators or standards'
  },
  {
    value: '41116200',
    label: 'Patient point of care testing supplies and equipment'
  },
  { value: '41116201', label: 'Glucose monitors or meters' },
  { value: '41116202', label: 'Cholesterol monitors or meters' },
  { value: '41116203', label: 'Monitor or meter accessories' },
  { value: '41116205', label: 'Rapid test kits' },
  { value: '41116300', label: 'Laboratory flash point testers' },
  { value: '41116301', label: 'Flash point testers' },
  { value: '41121501', label: 'Robotic or automated liquid handling systems' },
  {
    value: '41121503',
    label: 'Manual multichannel air displacement pipetters'
  },
  {
    value: '41121504',
    label: 'Manual single channel air displacement pipetters'
  },
  {
    value: '41121505',
    label: 'Manual single channel positive displacement pipetters'
  },
  { value: '41121506', label: 'Manual single channel repeating pipetters' },
  { value: '41121507', label: 'Electronic single channel pipetters' },
  { value: '41121508', label: 'Electronic multichannel pipetters' },
  { value: '41121509', label: 'Pasteur or transfer pipettes' },
  { value: '41121510', label: 'Volumetric pipettes' },
  { value: '41121511', label: 'Serological pipettes' },
  { value: '41121513', label: 'Dropping pipettes' },
  { value: '41121514', label: 'Pipette pumps' },
  { value: '41121515', label: 'Pipette bulbs' },
  { value: '41121516', label: 'Bottle top dispensers' },
  { value: '41121517', label: 'Pipetter inserts or accessories' },
  { value: '41121600', label: 'Pipette tips' },
  { value: '41121601', label: 'Filter tip pipette tips' },
  { value: '41121602', label: 'Aerosol barrier pipette tips' },
  { value: '41121603', label: 'Low retention pipette tips' },
  { value: '41121604', label: 'Reference pipette tips' },
  { value: '41121605', label: 'Ultramicro pipette tips' },
  { value: '41121606', label: 'Gel loading pipette tips' },
  { value: '41121607', label: 'Universal pipette tips' },
  { value: '41121608', label: 'Robotic pipette tips' },
  { value: '41121701', label: 'Multipurpose or general test tubes' },
  { value: '41121702', label: 'Microcentrifuge tubes' },
  { value: '41121703', label: 'Centrifuge tubes' },
  { value: '41121704', label: 'Cryogenic tubes' },
  { value: '41121705', label: 'Nuclear magnetic resonance NMR tubes' },
  { value: '41121706', label: 'Culture tubes' },
  { value: '41121707', label: 'Separator test tubes' },
  { value: '41121708', label: 'Anti coagulant test tubes' },
  { value: '41121709', label: 'Capillary or hematocrit tubes' },
  { value: '41121710', label: 'Test tube closures or caps' },
  {
    value: '41121800',
    label: 'General laboratory glassware and plasticware and supplies'
  },
  { value: '41121806', label: 'Laboratory vials' },
  { value: '41121807', label: 'Laboratory ampoules' },
  { value: '41121809', label: 'Laboratory funnels' },
  { value: '41121810', label: 'Laboratory staining dishes or jars' },
  { value: '41121811', label: 'Laboratory microchemistry kits' },
  { value: '41103209', label: 'Laboratory wash bottles' },
  { value: '41121815', label: 'Laboratory adapters or connectors or fittings' },
  { value: '41122001', label: 'Chromatography syringes' },
  { value: '41122002', label: 'Chromatography syringe needles' },
  { value: '41122003', label: 'Syringe adapters or accessories' },
  { value: '41122004', label: 'Sampling syringes' },
  {
    value: '41122100',
    label: 'Tissue culture and high throughput screening supplies'
  },
  { value: '41122101', label: 'Petri plates or dishes' },
  { value: '41122102', label: 'Multiwell plates' },
  { value: '41122103', label: 'Cell scrapers' },
  { value: '41122104', label: 'Tissue culture flasks' },
  { value: '41122105', label: 'Roller bottles' },
  { value: '41122106', label: 'Inoculating devices' },
  {
    value: '41122107',
    label: 'Tissue culture coated plates or dishes or inserts'
  },
  { value: '41122108', label: 'Microbiology inoculation loops or needles' },
  { value: '41122200', label: 'Crucibles' },
  { value: '41122201', label: 'Glass crucibles' },
  { value: '41122202', label: 'Ceramic crucibles' },
  { value: '41122203', label: 'Metal crucibles' },
  { value: '41122300', label: 'Laboratory bench protectors and liners' },
  { value: '41122301', label: 'Bench protectors or liners' },
  { value: '41122400', label: 'Laboratory implements' },
  {
    value: '41122401',
    label: 'Magnetic spin bars or stir bars or stirring beads'
  },
  { value: '41122403', label: 'Laboratory spatulas' },
  { value: '41122404', label: 'Laboratory tongs' },
  { value: '41122405', label: 'Laboratory forceps' },
  { value: '41122406', label: 'Laboratory knives' },
  { value: '41122407', label: 'Laboratory scalpels' },
  { value: '41122408', label: 'Laboratory scissors' },
  { value: '41122409', label: 'Laboratory tools' },
  { value: '41122410', label: 'Laboratory sealing film' },
  { value: '41122500', label: 'Laboratory corks and stoppers and accessories' },
  { value: '41122501', label: 'Laboratory corks' },
  { value: '41122503', label: 'Laboratory cork borers' },
  { value: '41122600', label: 'Laboratory microscope slides and supplies' },
  { value: '41122602', label: 'Microscopes slide coverslips' },
  { value: '41122603', label: 'Microscope lens paper' },
  { value: '41122604', label: 'Hemocytometers' },
  { value: '41122605', label: 'Microscope immersion oil' },
  { value: '41122606', label: 'Microscope slide dispensers' },
  { value: '41122700', label: 'Laboratory tapes and labels' },
  { value: '41122701', label: 'Slide or specimen labels' },
  { value: '41122702', label: 'Labeling tapes' },
  { value: '41122703', label: 'Safety tapes' },
  { value: '41122704', label: 'Tamper proof tapes' },
  { value: '41122800', label: 'Laboratory stands and racks and trays' },
  { value: '41122801', label: 'Pipette racks or stands' },
  { value: '41122802', label: 'Microscope slide racks' },
  { value: '41122803', label: 'Sedimentation tube racks or stands' },
  { value: '41122804', label: 'Test tube racks' },
  { value: '41122805', label: 'Drying racks' },
  { value: '41122806', label: 'Cryoracks' },
  { value: '41122807', label: 'Dissecting trays' },
  { value: '41122413', label: 'Laboratory clamps' },
  { value: '41123001', label: 'Jar desiccators' },
  { value: '41123002', label: 'Cabinet desiccators' },
  { value: '41123004', label: 'Vacuum desiccators' },
  { value: '41123100', label: 'Laboratory dialysis supplies' },
  { value: '41123101', label: 'Dialysis tubing' },
  { value: '41123102', label: 'Dialysis clamps' },
  { value: '41123200', label: 'Preserved specimens and supplies' },
  { value: '41123201', label: 'Preserved prepared slides' },
  { value: '41123202', label: 'Preserved animals and organisms' },
  {
    value: '41123300',
    label: 'General laboratory storage containers and cabinets'
  },
  { value: '41123302', label: 'Microscope slide boxes or folders' },
  { value: '41123303', label: 'Microscope slide cabinets' },
  { value: '41123304', label: 'Cryogenic storage boxes' },
  { value: '42141503', label: 'Skin preparation wipes' },
  { value: '51171909', label: 'Esomeprazole or omeprazole' },
  { value: '80111614', label: 'Temporary engineering services' },
  { value: '95141710', label: 'Phone box or phone booth' },
  { value: '72103302', label: 'Telecom equipment maintenance or support' },
  {
    value: '72151606',
    label: 'Underground engineering for communication equipment'
  },
  {
    value: '72151607',
    label: 'Overground engineering for communication equipment'
  },
  { value: '72141511', label: 'Digging services' },
  { value: '80141506', label: 'Internet based market research' },
  { value: '80141609', label: 'Sponsorship of event or celebrity' },
  { value: '93170000', label: 'Trade policy and regulation' },
  { value: '26101732', label: 'Spark plugs' },
  { value: '10130000', label: 'Animal containment and habitats' },
  { value: '85140000', label: 'Alternative and holistic medicine' },
  { value: '25173700', label: 'Exhaust and emission controls' },
  { value: '31163200', label: 'Retaining hardware' },
  { value: '41103021', label: 'Laboratory plate freezers' },
  { value: '41105101', label: 'Laboratory vacuum pumps' },
  { value: '41105107', label: 'Laboratory drum pumps' },
  { value: '13111100', label: 'Rosins' },
  { value: '84130000', label: 'Insurance and retirement services' },
  { value: '13111200', label: 'Plastic films' },
  {
    value: '13110000',
    label: 'Resins and rosins and other resin derived materials'
  },
  { value: '42132200', label: 'Medical gloves and accessories' },
  {
    value: '42132201',
    label: 'Bathophenanthroline, Colorimetry, Iron (Non-Heme)'
  },
  { value: '42132202', label: 'Finger cots' },
  { value: '42132203', label: 'Medical exam or non surgical procedure gloves' },
  { value: '42132204', label: 'Medical glove liners' },
  { value: '42132205', label: 'Surgical gloves' },
  { value: '43212001', label: 'Computer display glare screens' },
  { value: '83101903', label: 'District heating' },
  { value: '83112500', label: 'Backbone capacities' },
  {
    value: '83112501',
    label:
      'Submarine cable capacities and submarine cable PoP to PoP capacities'
  },
  { value: '83112502', label: 'Terrestrial backbone capacities' },
  {
    value: '83112503',
    label:
      'Rights of way for transit for half circuit systems, DDPs and admin lease'
  },
  {
    value: '83112504',
    label:
      'Indefeasible rights of use IRU for submarine cable or terrestrial cable systems'
  },
  { value: '83112505', label: 'Crossconnection functionality' },
  { value: '83112600', label: 'Customer access' },
  { value: '83112601', label: 'Local loop capacities' },
  { value: '83112602', label: 'Domestic leased lines' },
  { value: '83112603', label: 'International access lines' },
  { value: '83112604', label: 'Dial access services' },
  { value: '83112605', label: 'External international lines' },
  { value: '26111608', label: 'Steam generators' },
  { value: '26131600', label: 'Exhaust structures or screening equipment' },
  { value: '26131800', label: 'Power generation control equipment' },
  {
    value: '26131700',
    label: 'Power generation monitoring or detecting equipment'
  },
  { value: '43190000', label: 'Communications Devices and Accessories' },
  { value: '43191500', label: 'Personal communication devices' },
  { value: '43211511', label: 'Wearable computing devices' },
  {
    value: '43191600',
    label: 'Personal communications device accessories or parts'
  },
  { value: '43191601', label: 'Mobile phone face plates' },
  { value: '43191602', label: 'Phone dialers' },
  { value: '43191603', label: 'Phone extension cords' },
  { value: '43191604', label: 'Phone face plates' },
  { value: '43191605', label: 'Phone handset cords' },
  { value: '43191606', label: 'Phone handsets' },
  { value: '43191607', label: 'Phone headset ear or speaker cushions' },
  { value: '43191608', label: 'Phone headset voice tubes' },
  {
    value: '43191610',
    label: 'Personal communication holders or mounts, stands'
  },
  { value: '43191611', label: 'Phone line protectors' },
  { value: '43202204', label: 'External ringer or its parts' },
  { value: '43191614', label: 'Phone voice converters' },
  {
    value: '43201501',
    label: 'Asynchronous transfer mode ATM telecommunications interface cards'
  },
  { value: '43201507', label: 'Daughterboards' },
  { value: '43201508', label: 'Dispersion compensation fiber module DCFMs' },
  { value: '43221803', label: 'Optical adapters' },
  { value: '43221801', label: 'Optical amplifiers' },
  { value: '43221802', label: 'Optical network or communication filters' },
  { value: '43201405', label: 'Optical network receive cards' },
  { value: '43201406', label: 'Optical network transmit cards' },
  { value: '43221804', label: 'Optical networking lasers' },
  { value: '43201537', label: 'Print servers' },
  { value: '43201600', label: 'Chassis components' },
  { value: '43201603', label: 'Chassis stacking components' },
  { value: '43201604', label: 'Electronic equipment bays or baskets' },
  { value: '43201605', label: 'Expanders' },
  { value: '43201552', label: 'Hardware or telephony adapters' },
  { value: '43201800', label: 'Media storage devices' },
  { value: '43201802', label: 'Hard disk arrays' },
  { value: '43201808', label: 'Read only compact disc CD' },
  { value: '43201807', label: 'Tape drives' },
  { value: '43201900', label: 'Media storage device accessories' },
  { value: '43201616', label: 'Hard disk drive array towers' },
  { value: '43201902', label: 'Optical disk changers' },
  { value: '43201903', label: 'Tape drive libraries' },
  { value: '43202000', label: 'Removable storage media' },
  { value: '43202001', label: 'Compact disks CDs' },
  { value: '43202003', label: 'Digital versatile disks DVDs' },
  { value: '43202100', label: 'Removable storage media accessories' },
  { value: '43202101', label: 'Compact disk cases' },
  { value: '43202102', label: 'Floppy disk cases' },
  { value: '43202103', label: 'Multimedia storage holders' },
  { value: '43210000', label: 'Computer Equipment and Accessories' },
  { value: '43211502', label: 'High end computer servers' },
  { value: '43211506', label: 'Thin client computers' },
  { value: '43211600', label: 'Computer accessories' },
  { value: '43211604', label: 'Peripheral switch boxes' },
  { value: '43211702', label: 'Magnetic stripe readers and encoders' },
  { value: '43211708', label: 'Computer mouse or trackballs' },
  { value: '43211714', label: 'Biometric identification equipment' },
  { value: '43211800', label: 'Computer data input device accessories' },
  { value: '43212103', label: 'Dye sublimination printers' },
  {
    value: '43220000',
    label:
      'Data Voice or Multimedia Network Equipment or Platforms and Accessories'
  },
  { value: '43221501', label: 'Automated attendant systems' },
  { value: '43221502', label: 'Automatic call distributor ACD' },
  { value: '43221503', label: 'Telecom announcers' },
  { value: '43221505', label: 'Standalone telephone caller identification' },
  { value: '43221506', label: 'Teleconferencing console' },
  { value: '43221507', label: 'Autodialers' },
  { value: '43221508', label: 'Telephone busy lamp fields' },
  { value: '43221509', label: 'Telephone call accounting systems' },
  { value: '43221510', label: 'Telephone call diverter or forwarder' },
  { value: '43221513', label: 'Telephone call sequencers' },
  { value: '43221514', label: 'Telephone dial backup units' },
  { value: '43221515', label: 'Telephone line sharing devices' },
  { value: '43221516', label: 'Telephone line status monitors' },
  { value: '43221517', label: 'Telephony equipment service observing units' },
  { value: '43221518', label: 'Telephony equipment toll restrictors' },
  { value: '43221523', label: 'Music or message on hold player' },
  { value: '43221521', label: 'Telecommunications remote access unit' },
  { value: '43222625', label: 'Access servers' },
  { value: '43222626', label: 'Cable modems' },
  {
    value: '43222627',
    label: 'Integrated services digital network ISDN access devices'
  },
  { value: '43221700', label: 'Fixed network equipment and components' },
  { value: '43221707', label: 'Microwave core equipment' },
  { value: '43221705', label: 'Radio access equipment' },
  { value: '43222500', label: 'Network security equipment' },
  { value: '43222501', label: 'Firewall network security equipment' },
  { value: '43222502', label: 'VPN network security equipment' },
  { value: '43222600', label: 'Network service equipment' },
  { value: '43222602', label: 'Cable head end equipment' },
  { value: '43222604', label: 'Content delivery networking equipment' },
  { value: '43222605', label: 'Network gateway' },
  { value: '43222606', label: 'Internet service node startup kits' },
  { value: '43222607', label: 'Cache engine equipment' },
  { value: '43221800', label: 'Optical network devices' },
  { value: '43222615', label: 'Storage area network SAN switch' },
  {
    value: '43221806',
    label: 'Synchronous optical network SONET network equipment'
  },
  { value: '43222822', label: 'Time division multiplexer TDM' },
  { value: '43222619', label: 'Video networking equipment' },
  { value: '43222701', label: 'Telegraph keys' },
  { value: '43222702', label: 'Telegraph electromagnets' },
  { value: '43222703', label: 'Telegraph sounders' },
  {
    value: '43222801',
    label: 'Broadband or narrowband digital cross connect DCX equipment'
  },
  { value: '43222803', label: 'Digital loop carrier DLCs' },
  { value: '43222805', label: 'Private branch exchange PBX equipment' },
  {
    value: '43221808',
    label: 'Synchronous digital hierarchy SDH telecom equipment'
  },
  { value: '43222811', label: 'Telephony equipment alarm units' },
  { value: '43222900', label: 'Telephony equipment accessories' },
  { value: '43222902', label: 'Telephony cable air dryers' },
  { value: '43223001', label: 'Teletype input devices' },
  { value: '43231500', label: 'Business function specific software' },
  { value: '43231501', label: 'Helpdesk or call center software' },
  { value: '43231505', label: 'Human resources software' },
  {
    value: '43231506',
    label: 'Materials requirements planning logistics and supply chain software'
  },
  { value: '43232000', label: 'Computer game or entertainment software' },
  { value: '43232001', label: 'Action games' },
  { value: '43232002', label: 'Adventure games' },
  { value: '43232003', label: 'Sports games' },
  { value: '43232004', label: 'Family software' },
  { value: '43232100', label: 'Content authoring and editing software' },
  { value: '43232101', label: 'Pattern design software' },
  { value: '43232102', label: 'Graphics or photo imaging software' },
  { value: '43232103', label: 'Video creation and editing software' },
  { value: '43232107', label: 'Web page creation and editing software' },
  { value: '43232108', label: 'Calendar and scheduling software' },
  { value: '43232200', label: 'Content management software' },
  { value: '43232201', label: 'Content workflow software' },
  { value: '43232203', label: 'File versioning software' },
  { value: '43232301', label: 'Categorization or classification software' },
  { value: '43232302', label: 'Clustering software' },
  { value: '43232303', label: 'Customer relationship management CRM software' },
  { value: '43232304', label: 'Data base management system software' },
  { value: '43232305', label: 'Data base reporting software' },
  { value: '43232306', label: 'Data base user interface and query software' },
  { value: '43232307', label: 'Data mining software' },
  { value: '43231602', label: 'Enterprise resource planning ERP software' },
  { value: '43232309', label: 'Information retrieval or search software' },
  { value: '43232310', label: 'Metadata management software' },
  { value: '43232311', label: 'Object oriented data base management software' },
  { value: '43232312', label: 'Portal server software' },
  { value: '43232402', label: 'Development environment software' },
  { value: '43232403', label: 'Enterprise application integration software' },
  {
    value: '43232405',
    label: 'Object or component oriented development software'
  },
  {
    value: '43232407',
    label: 'Requirements analysis and system architecture software'
  },
  { value: '43232501', label: 'Foreign language software' },
  { value: '43232606', label: 'Compliance software' },
  { value: '43232609', label: 'Library software' },
  { value: '43232610', label: 'Medical software' },
  { value: '43232700', label: 'Network applications software' },
  { value: '43232701', label: 'Application server software' },
  { value: '43232704', label: 'Internet directory services software' },
  { value: '43232800', label: 'Network management software' },
  { value: '43232801', label: 'Network monitoring software' },
  { value: '43232802', label: 'Network operating system enhancement software' },
  { value: '43232803', label: 'Optical network management software' },
  { value: '43232901', label: 'Access software' },
  { value: '43232903', label: 'Contact center software' },
  { value: '43232904', label: 'Fax software' },
  { value: '43232905', label: 'LAN software' },
  { value: '43232907', label: 'Storage networking software' },
  { value: '43232909', label: 'WAN switching software and firmware' },
  { value: '43232910', label: 'Wireless software' },
  { value: '43233001', label: 'Filesystem software' },
  { value: '43232409', label: 'Compiler and decompiler software' },
  { value: '43233200', label: 'Security and protection software' },
  { value: '43233201', label: 'Authentication server software' },
  {
    value: '43233203',
    label: 'Network security or virtual private network VPN management software'
  },
  {
    value: '43233204',
    label: 'Network security and virtual private network VPN equipment software'
  },
  {
    value: '43233205',
    label: 'Transaction security and virus protection software'
  },
  { value: '43231605', label: 'Time accounting software' },
  { value: '43231511', label: 'Expert system software' },
  { value: '43233400', label: 'Utility and device driver software' },
  { value: '43233402', label: 'Data conversion software' },
  { value: '43233404', label: 'Compact disc CD or DVD or sound card software' },
  { value: '43233405', label: 'Device drivers or system software' },
  { value: '43233406', label: 'Ethernet driver software' },
  { value: '43233407', label: 'Graphics card driver software' },
  {
    value: '83111905',
    label:
      'International bilateral services and international private leased lines'
  },
  { value: '81111613', label: 'Programming for Perl' },
  { value: '42131612', label: 'Medical staff isolation or cover gowns' },
  { value: '42131704', label: 'Surgical towels' },
  { value: '42192500', label: 'Medical equipment protectors' },
  { value: '42192501', label: 'Medical equipment covers' },
  { value: '42192502', label: 'Medical equipment bags' },
  { value: '43221520', label: 'Interactive voice recognition equipment' },
  { value: '43222621', label: 'Content switch' },
  { value: '43222620', label: 'Multiservice switch' },
  { value: '43222632', label: 'Broadband aggregators' },
  { value: '40151503', label: 'Centrifugal pumps' },
  { value: '41111711', label: 'Electron microscopes' },
  { value: '42142523', label: 'Hypodermic needle' },
  { value: '43231603', label: 'Tax preparation software' },
  { value: '43231508', label: 'Inventory management software' },
  {
    value: '43232111',
    label: 'Optical character reader OCR or scanning software'
  },
  { value: '43232112', label: 'Desktop publishing software' },
  { value: '43232612', label: 'Computer aided manufacturing CAM software' },
  { value: '43233506', label: 'Map creation software' },
  { value: '43231507', label: 'Project management software' },
  { value: '43232005', label: 'Music or sound editing software' },
  { value: '43231510', label: 'Label making software' },
  { value: '43233415', label: 'Backup or archival software' },
  { value: '43231509', label: 'Bar coding software' },
  { value: '43233414', label: 'Storage media loading software' },
  { value: '43232915', label: 'Platform interconnectivity software' },
  {
    value: '43232911',
    label: 'Network connectivity terminal emulation software'
  },
  { value: '43232912', label: 'Gateway software' },
  { value: '43232804', label: 'Administration software' },
  { value: '43232913', label: 'Bridge software' },
  { value: '43232914', label: 'Modem software' },
  { value: '43233502', label: 'Video conferencing software' },
  { value: '43232705', label: 'Internet browser software' },
  { value: '43221719', label: 'Aircraft antennas' },
  { value: '43221720', label: 'Automotive antennas' },
  { value: '43211605', label: 'Signal processor upgrades' },
  { value: '43221701', label: 'Television core equipment' },
  { value: '43211606', label: 'Multimedia kits' },
  { value: '43211719', label: 'Voice microphones for computers' },
  { value: '43201813', label: 'High capacity removable media drives' },
  { value: '43212105', label: 'Laser printers' },
  { value: '43211607', label: 'Computer speakers' },
  { value: '43221721', label: 'Radio frequency data communication equipment' },
  {
    value: '43211718',
    label: 'Camera based vision systems for automated data collection'
  },
  { value: '15111700', label: 'Fuel additives' },
  { value: '43201400', label: 'System Cards' },
  {
    value: '43231600',
    label: 'Finance accounting and enterprise resource planning ERP software'
  },
  { value: '43211803', label: 'Key board skins' },
  { value: '43212002', label: 'Monitor arms or stands' },
  { value: '43211721', label: 'Punch card readers' },
  { value: '56101705', label: 'Showcases' },
  { value: '46182300', label: 'Fall protection and rescue equipment' },
  { value: '46182301', label: 'Lifelines or lifeline equipment' },
  { value: '46182105', label: 'Anti static worktable mats' },
  { value: '14121809', label: 'Masking paper' },
  {
    value: '46182400',
    label: 'Decontamination aids and safety cleaning equipment'
  },
  { value: '46182401', label: 'Decontamination shower' },
  {
    value: '46191503',
    label: 'Fire resistant coatings or putties or sealants'
  },
  { value: '23190000', label: 'Mixers and their parts and accessories' },
  { value: '23191000', label: 'Batch mixers' },
  { value: '23191001', label: 'Change can mixers' },
  { value: '23191100', label: 'Continuous mixers' },
  { value: '23191101', label: 'Single screw mixers' },
  { value: '44102606', label: 'Typewriter ribbon' },
  { value: '14111606', label: 'Art or craft paper' },
  { value: '14121810', label: 'Carbon papers' },
  { value: '40161800', label: 'Filter media' },
  { value: '44111521', label: 'Copy holders' },
  { value: '44121624', label: 'Embossing tools' },
  { value: '23200000', label: 'Mass transfer equipment' },
  { value: '23201000', label: 'Gas liquid contacting systems' },
  { value: '43201810', label: 'Read only digital versatile disc DVD' },
  { value: '24111813', label: 'Rinse tanks' },
  { value: '44111506', label: 'Paper or pad holder or dispensers' },
  { value: '39111810', label: 'Lamp starter' },
  { value: '43222622', label: 'Server load balancer' },
  { value: '43212109', label: 'Bag tag printer' },
  { value: '43201539', label: 'Console controller mainframe' },
  { value: '43201540', label: 'Channel converter' },
  { value: '43201541', label: 'Channel to channel interface mainframe' },
  { value: '43201542', label: 'Control unit' },
  { value: '43201543', label: 'Coupler facility mainframe' },
  { value: '43212110', label: 'Multi function printers' },
  { value: '44102607', label: 'Word processors' },
  { value: '44102907', label: 'Equipment dust covers' },
  { value: '85121810', label: 'Drug or alcohol screening' },
  { value: '14111523', label: 'Tracing paper' },
  { value: '55121800', label: 'Identification documents' },
  { value: '55121801', label: 'Car tax discs' },
  {
    value: '55121802',
    label: 'Identification cards or bands or similar products'
  },
  { value: '55121803', label: 'Passports' },
  { value: '84101704', label: 'Debt collection services' },
  { value: '84101705', label: 'Repossession services' },
  { value: '83121606', label: 'Skip tracing' },
  { value: '22101800', label: 'Aerial lifts' },
  {
    value: '39112000',
    label: 'Portable and Temporary Lighting and accessories'
  },
  { value: '46182302', label: 'Fall protection lanyard' },
  { value: '14111607', label: 'Poster boards' },
  { value: '56101706', label: 'Conferencing tables' },
  { value: '83112300', label: 'Fiber telecommunications services' },
  { value: '83112301', label: 'Dark fiber' },
  { value: '83112302', label: 'Dense wavelength division multiplexing DWDM' },
  { value: '83112303', label: 'Wave division multiplexing WDM' },
  { value: '83112304', label: 'Ocx optical carrier service' },
  { value: '80111506', label: 'Personnel relocation' },
  { value: '82121512', label: 'Embossing' },
  {
    value: '78181700',
    label: 'Transport fueling and vehicle storage and support services'
  },
  { value: '78181701', label: 'Vehicle fueling service' },
  { value: '78181702', label: 'Transportation storage service' },
  { value: '78101503', label: 'Armored air transport' },
  {
    value: '83112400',
    label:
      'Switched dial up and leased dedicated line circuit telecommunications services'
  },
  { value: '83112401', label: 'High speed circuit switched dial up services' },
  {
    value: '83112402',
    label: 'ISDN integrated services digital network services'
  },
  {
    value: '83112403',
    label: 'Point to point digital telecommunications circuit'
  },
  { value: '83112404', label: 'Multi point analog telecommunications circuit' },
  {
    value: '83112405',
    label: 'Point to point analog telecommunications circuit'
  },
  { value: '83112406', label: 'DSL digital subscriber line' },
  { value: '83112200', label: 'Enhanced telecommunications services' },
  {
    value: '83112201',
    label: 'ATM asynchronous transfer mode managed network services'
  },
  { value: '83112202', label: 'Frame relay public managed network services' },
  {
    value: '83112203',
    label: 'VPN virtual private network managed network services'
  },
  { value: '83112204', label: 'X75 managed network services' },
  { value: '83112205', label: 'X25 managed network services' },
  { value: '44121508', label: 'Message droppers' },
  { value: '24111507', label: 'Tool bags' },
  { value: '27113200', label: 'Tool kits' },
  { value: '27113201', label: 'General tool kits' },
  { value: '53131628', label: 'Shampoos' },
  { value: '53131506', label: 'Dental kits' },
  { value: '44102908', label: 'Compact disc cleaners or scratch removers' },
  { value: '44102909', label: 'Compact disc drive cleaner' },
  { value: '44122015', label: 'File backers' },
  { value: '44121625', label: 'Paper weights' },
  { value: '44121806', label: 'Correction pen refills' },
  { value: '44121712', label: 'Marker refills' },
  { value: '44122016', label: 'Document holder' },
  { value: '44121509', label: 'Mailing bags' },
  { value: '24111508', label: 'Tent bag' },
  { value: '22101518', label: 'Elevating scrapers' },
  { value: '47121705', label: 'Urn sand bags' },
  { value: '48101714', label: 'Hot water dispenser' },
  { value: '43212111', label: 'Airline ticket or boarding pass ATB printers' },
  { value: '22101519', label: 'Twin engine open bowl scrapers' },
  { value: '22101520', label: 'Twin engine elevating scrapers' },
  { value: '22101521', label: 'Pulled scrapers' },
  { value: '22101522', label: 'Track bulldozers' },
  { value: '22101523', label: 'Wheel bulldozers' },
  { value: '22101524', label: 'Mobile excavators' },
  { value: '22101525', label: 'Wheel excavators' },
  { value: '22101526', label: 'Track excavators' },
  { value: '22101527', label: 'Integrated tool carriers' },
  { value: '24101625', label: 'Conventional truck cranes' },
  { value: '44102002', label: 'Laminator pouches' },
  { value: '44121807', label: 'Eraser refills' },
  { value: '44122109', label: 'Hook and loop fastener' },
  { value: '43211510', label: 'Mainframe console or dumb terminals' },
  { value: '22101528', label: 'Wheel loaders' },
  { value: '22101529', label: 'Skid steer loaders' },
  { value: '22101530', label: 'Open bowl scrapers' },
  { value: '44121510', label: 'Mailing seals' },
  { value: '44121626', label: 'Adhesive remover' },
  { value: '44103110', label: 'Print heads' },
  { value: '44102910', label: 'Laminator trays' },
  { value: '44112007', label: 'Suggestion box' },
  { value: '44103111', label: 'Ink rolls' },
  { value: '53121706', label: 'Computer bags' },
  { value: '95121700', label: 'Public buildings and structures' },
  { value: '95121701', label: 'Post office' },
  { value: '95121702', label: 'Police station' },
  { value: '95121703', label: 'Court building' },
  { value: '95121704', label: 'Prison building' },
  { value: '95121705', label: 'Fire station' },
  { value: '95121706', label: 'Ambulance station' },
  { value: '95121707', label: 'Mountain rescue building' },
  { value: '95121708', label: 'Lifeboat station' },
  { value: '95121709', label: 'Coastguard building' },
  { value: '95121710', label: 'Rescue service station' },
  { value: '95121711', label: 'Civic center' },
  { value: '95121712', label: 'Art gallery' },
  { value: '95121713', label: 'Prehistoric monument' },
  { value: '95121800', label: 'Utility buildings and structures' },
  { value: '95121801', label: 'Radar station' },
  { value: '95121802', label: 'Substation' },
  { value: '95121803', label: 'Nuclear power station' },
  { value: '95121804', label: 'Water tower' },
  { value: '95121805', label: 'Well' },
  { value: '95121806', label: 'Mobile telephone base station' },
  { value: '95121807', label: 'Dam' },
  { value: '95121808', label: 'Oil or gas platform' },
  { value: '95121603', label: 'Bus station' },
  { value: '95121604', label: 'Bus garage' },
  { value: '95121605', label: 'Automotive repair or servicing building' },
  { value: '95121606', label: 'Railway station' },
  { value: '95121607', label: 'Railway depot' },
  { value: '95121608', label: 'Underground railway station' },
  { value: '95121611', label: 'Airport building' },
  { value: '95121612', label: 'Airport control tower' },
  { value: '95121613', label: 'Airfield' },
  { value: '95121614', label: 'Runway' },
  { value: '95121620', label: 'Breakwater' },
  { value: '95121621', label: 'Seawall' },
  { value: '95121624', label: 'Lighthouse' },
  { value: '95121625', label: 'Road bridge' },
  { value: '95121626', label: 'Railway bridge' },
  { value: '95121627', label: 'Footbridge' },
  { value: '95121628', label: 'Road viaduct' },
  { value: '95121629', label: 'Railway viaduct' },
  { value: '95121630', label: 'Underpass' },
  { value: '95121631', label: 'Overpass' },
  { value: '95121632', label: 'Subway' },
  { value: '95121634', label: 'Road tunnel' },
  { value: '95121635', label: 'Railway tunnel' },
  { value: '95121636', label: 'Pedestrian tunnel' },
  { value: '95111609', label: 'Crossroad' },
  { value: '95111610', label: 'Ring road' },
  { value: '95111611', label: 'Trunk road' },
  { value: '95111612', label: 'Secondary road' },
  { value: '95111504', label: 'Highway ramp or slip road' },
  { value: '95111615', label: 'Road junction' },
  { value: '95121639', label: 'Pipeline carrying bridge' },
  { value: '95121640', label: 'Pipeline' },
  { value: '95121641', label: 'Pumping station' },
  { value: '95121642', label: 'Sewage pumping station' },
  { value: '95121643', label: 'Sewage outfall' },
  { value: '95121644', label: 'Parking lot' },
  { value: '95121645', label: 'Motorway service area' },
  {
    value: '95121900',
    label: 'Educational and research buildings and structures'
  },
  { value: '95121901', label: 'Polytechnic school' },
  { value: '95121902', label: 'Vocational college' },
  { value: '95121903', label: 'Lecture theater' },
  { value: '95121904', label: 'Library' },
  { value: '95121905', label: 'Language laboratory' },
  { value: '95121906', label: 'Laboratory building' },
  { value: '95121907', label: 'Meteorological station' },
  { value: '95121908', label: 'Research or testing facility' },
  { value: '95122000', label: 'Hospital buildings and structures' },
  { value: '95122001', label: 'Clinic' },
  { value: '95122002', label: 'Nursing home' },
  { value: '95122003', label: 'Operating room or theater' },
  { value: '95122004', label: 'Intensive care unit' },
  { value: '95122005', label: 'Diagnostic screening room' },
  { value: '95122006', label: 'Fluoroscopy room' },
  { value: '95122007', label: 'Pathology room or laboratory' },
  { value: '95122008', label: 'Catheter room' },
  { value: '95122100', label: 'Accommodation buildings and structures' },
  { value: '95122101', label: 'Residential home' },
  { value: '95122102', label: 'Flat or apartment' },
  { value: '95122103', label: 'Childrens home' },
  { value: '95122104', label: 'Daycare center' },
  { value: '95122105', label: 'Retirement home' },
  { value: '95122106', label: 'Hostel' },
  { value: '95122300', label: 'Sports and health buildings and structures' },
  { value: '95122400', label: 'Industrial buildings and structures' },
  {
    value: '95122500',
    label: 'Agricultural and farming and fishing buildings and structures'
  },
  { value: '95122700', label: 'Defense buildings and structures' },
  { value: '95122600', label: 'Religious buildings and structures' },
  { value: '22101531', label: 'Snow blowers' },
  { value: '24101626', label: 'Escalator or walkways' },
  { value: '25111903', label: 'Sails' },
  { value: '25111904', label: 'Paddles' },
  { value: '55101522', label: 'Terrestrial or celestial globes' },
  { value: '44102911', label: 'Cleaning wipes for office machines' },
  { value: '44111812', label: 'Drafting kits or sets' },
  { value: '44102608', label: 'Typewriter printing elements' },
  { value: '15101510', label: 'Condensate' },
  { value: '14111524', label: 'Foolscap sheets' },
  { value: '49121510', label: 'Drink coolers' },
  { value: '43202005', label: 'Flash memory storage card' },
  { value: '60131504', label: 'Tuning pins' },
  { value: '60131505', label: 'Musical instrument stands or sheet holders' },
  { value: '60131506', label: 'Accessories for stringed instruments' },
  { value: '60131507', label: 'Percussion instrument accessory' },
  { value: '60131508', label: 'Music boxes or mechanisms' },
  { value: '10152200', label: 'Fibrous crop seeds and seedlings' },
  { value: '10152201', label: 'Cotton seeds or seedlings' },
  { value: '10171702', label: 'Fungicides' },
  { value: '10151522', label: 'Beet seeds or seedlings' },
  { value: '10151523', label: 'Cauliflower seeds or seedlings' },
  { value: '10151524', label: 'Parsley seeds or seedlings' },
  { value: '11121900', label: 'Perfumery products' },
  { value: '11122001', label: 'Plywood' },
  { value: '11121610', label: 'Hardwoods' },
  { value: '22101532', label: 'Track loaders' },
  { value: '11162118', label: 'Paper yarn fabric' },
  { value: '11162119', label: 'Tracing cloth' },
  { value: '11162120', label: 'Bolting cloth' },
  { value: '11162121', label: 'Ornamental trimmings' },
  { value: '46182005', label: 'Mask or respirators filters or accessories' },
  { value: '47121809', label: 'Degreasing pans' },
  { value: '46171617', label: 'Door eyes' },
  { value: '44121713', label: 'Pen nibs' },
  { value: '14121812', label: 'Photography paper' },
  { value: '26111716', label: 'Mercury oxide battery' },
  { value: '24112209', label: 'Jerrycans' },
  { value: '15111507', label: 'Water gas or producer gas' },
  { value: '15111508', label: 'Coal gas' },
  { value: '44103112', label: 'Printer ribbon' },
  { value: '43223310', label: 'Fiber optic connector' },
  { value: '24101629', label: 'Forklift or elevator accessories or supplies' },
  { value: '23271712', label: 'Welding or soldering kit' },
  { value: '24101630', label: 'Workshop cranes' },
  { value: '24101511', label: 'Shelf trolleys' },
  { value: '72101505', label: 'Locksmith services' },
  { value: '25111905', label: 'Marine ballast systems' },
  { value: '24112600', label: 'Liquid containers' },
  { value: '24113101', label: 'Mail box' },
  { value: '30181601', label: 'Soap dish' },
  { value: '30181602', label: 'Towel bar or ring or stand or hook' },
  { value: '31380000', label: 'Magnets and magnetic materials' },
  { value: '43232503', label: 'Spell checkers' },
  { value: '44111509', label: 'Pen or pencil holders' },
  { value: '44121627', label: 'Bookmarks' },
  { value: '44121628', label: 'Clip holders or dispensers' },
  { value: '47121706', label: 'Ash trays' },
  { value: '80131605', label: 'Sale of commercial building' },
  { value: '81112006', label: 'Data storage service' },
  { value: '83101510', label: 'Chilled water' },
  { value: '95141604', label: 'Garage' },
  { value: '53112000', label: 'Shoe accessories' },
  { value: '53131507', label: 'Toothpicks' },
  { value: '53131629', label: 'Makeup kits' },
  { value: '53131630', label: 'Lip balm' },
  { value: '83111508', label: 'Toll free inbound telephone service' },
  { value: '53102511', label: 'Bandannas' },
  { value: '55121905', label: 'Flagpoles or parts or accessories' },
  { value: '46182107', label: 'Antistatic maintenance kits' },
  { value: '24113102', label: 'Antistatic box' },
  { value: '56101707', label: 'Drafting tables' },
  { value: '27113203', label: 'Computer tool kits' },
  { value: '14111525', label: 'Multipurpose paper' },
  { value: '47131824', label: 'Glass or window cleaners' },
  { value: '44122110', label: 'Adhesive mounts' },
  { value: '26111717', label: 'Manganese batteries' },
  { value: '26111718', label: 'Silver oxide batteries' },
  { value: '47121810', label: 'Cleaning rag dispenser' },
  { value: '14111608', label: 'Gift certificate' },
  { value: '48101617', label: 'Commercial use plastic shovels' },
  { value: '24102108', label: 'Packaging compactors' },
  { value: '44111510', label: 'Hanging organizers or accessories' },
  { value: '52161600', label: 'Audio visual equipment accessories' },
  { value: '44122017', label: 'Hanging folders or accessories' },
  { value: '44103113', label: 'Phasers or inkjet kits' },
  { value: '44111511', label: 'Display systems or its accessories' },
  { value: '14111609', label: 'Cover paper' },
  { value: '44122018', label: 'File inserts or tabs' },
  { value: '45131700', label: 'Media storage' },
  { value: '45131701', label: 'Slide trays or organizers' },
  { value: '43191629', label: 'Notebook or palmtop skins or face plates' },
  { value: '44122111', label: 'Hole reinforcements' },
  { value: '44111512', label: 'Literature rack' },
  { value: '44103204', label: 'Time card racks' },
  { value: '44121630', label: 'Stapler kit' },
  { value: '53131634', label: 'Chemical protection products' },
  { value: '44103205', label: 'Time cards or sheets' },
  { value: '44101706', label: 'Photoconductor or imaging units' },
  { value: '44121631', label: 'Glue dispensers or refills' },
  { value: '44122112', label: 'Round head fasteners' },
  { value: '44122113', label: 'Tag fasteners' },
  { value: '44111513', label: 'Supports for diaries or calendars' },
  { value: '44122020', label: 'Card pockets' },
  { value: '44122114', label: 'Screw posts' },
  { value: '44112008', label: 'Wall planners or refills' },
  { value: '44111813', label: 'Drafting dots or tapes' },
  { value: '44121714', label: 'Pencil or pen grips' },
  { value: '44121715', label: 'Combination pen or pencil' },
  { value: '44103114', label: 'Laserjet coating kits' },
  { value: '44111514', label: 'Stamp racks or organizers' },
  { value: '44122115', label: 'Adhesive corners' },
  { value: '44111515', label: 'File storage boxes or organizers' },
  { value: '30191600', label: 'Ladders and scaffolding accessories' },
  {
    value: '23153000',
    label: 'Holding and positioning and guiding systems and devices'
  },
  {
    value: '23210000',
    label: 'Electronic manufacturing machinery and equipment and accessories'
  },
  { value: '95141709', label: 'Conservatory' },
  {
    value: '23153100',
    label: 'Industrial machinery components and accessories'
  },
  { value: '14111800', label: 'Business use papers' },
  { value: '14111801', label: 'Tickets or ticket rolls' },
  { value: '14111802', label: 'Receipts or receipt books' },
  { value: '14111803', label: 'Vouchers' },
  { value: '14111804', label: 'Bills or bill books' },
  { value: '14111805', label: 'Checks or check books' },
  { value: '45121604', label: 'Camera shutters' },
  { value: '45121605', label: 'Screen frames' },
  { value: '45121606', label: 'Camera harnesses' },
  { value: '45121607', label: 'Camera blocks or holders' },
  { value: '45121608', label: 'Camera assemblies' },
  { value: '45121609', label: 'Camera brackets' },
  { value: '45121610', label: 'Camera cables' },
  { value: '45121611', label: 'Lens cover' },
  { value: '45121612', label: 'Camera tables' },
  { value: '45121613', label: 'Camera enclosures or covers' },
  { value: '45121614', label: 'Retrofit kits' },
  { value: '45121615', label: 'Camera rings' },
  { value: '44102912', label: 'Cleaning solutions for office equipment' },
  { value: '14121504', label: 'Packaging paper' },
  { value: '30171510', label: 'Automatic doors' },
  { value: '44102913', label: 'Compact disc destroyer' },
  { value: '46171618', label: 'Door bells' },
  { value: '44122021', label: 'Postage stamp albums' },
  { value: '44121511', label: 'Mailing boxes' },
  { value: '44101804', label: 'Cash registers' },
  { value: '44111608', label: 'Coin banks' },
  { value: '44122116', label: 'Bag clips' },
  { value: '60131207', label: 'Harmonicas' },
  { value: '55121804', label: 'Badges or badge holders' },
  { value: '56101708', label: 'Mobile files' },
  { value: '55121908', label: 'Sign holders or stands' },
  { value: '24141510', label: 'Cable protectors' },
  { value: '47121811', label: 'Duct cleaning machines' },
  { value: '51201626', label: 'Hepatitis A virus vaccine' },
  { value: '48102106', label: 'Cool containers' },
  { value: '43201538', label: 'Central processing unit coolers' },
  { value: '44122022', label: 'Folder binding accessories' },
  { value: '39111900', label: 'Special environment fixtures and accessories' },
  { value: '30172000', label: 'Gates' },
  { value: '43201608', label: 'Removable drive frames' },
  {
    value: '60000000',
    label:
      'Musical Instruments and Games and Toys and Arts and Crafts and Educational Equipment and Materials and Accessories and Supplies'
  },
  {
    value: '60100000',
    label:
      'Developmental and professional teaching aids and materials and accessories and supplies'
  },
  { value: '60110000', label: 'Classroom decoratives and supplies' },
  { value: '43202206', label: 'Storage drive or input device components' },
  { value: '43202207', label: 'Head mount arms and assembly' },
  { value: '43202208', label: 'Lead assemblies' },
  { value: '43202209', label: 'Head stack assemblies' },
  { value: '43202214', label: 'Comb assemblies' },
  { value: '43202210', label: 'Crash stops' },
  { value: '43202211', label: 'Platters or disks' },
  { value: '43202212', label: 'Read write head assemblies' },
  { value: '43202213', label: 'Disc motor drives' },
  { value: '51101905', label: 'Chloroquine' },
  { value: '51101906', label: 'Proguanil' },
  { value: '51101907', label: 'Pyrimethamine' },
  { value: '82151508', label: 'Taxidermy services' },
  { value: '56101529', label: 'Magazine racks' },
  { value: '43201550', label: 'Network packet data synchronization device' },
  { value: '43222623', label: 'Digital cross connects DCX equipment' },
  { value: '43222624', label: 'Optical cross connects equipment' },
  { value: '43222819', label: 'Port connection panels' },
  { value: '43222823', label: 'Wave division mulitplexer WDM' },
  { value: '43222820', label: 'Voice echo cancellers' },
  { value: '30171511', label: 'Swing door' },
  { value: '26112100', label: 'Industrial braking systems' },
  { value: '53131508', label: 'Denture cleaning tablets' },
  { value: '48101917', label: 'Food service fondue pots' },
  { value: '30111506', label: 'Grout' },
  { value: '27113204', label: 'Electrician kits' },
  { value: '30171512', label: 'Door openers' },
  { value: '55101523', label: 'Exercise books' },
  { value: '80111615', label: 'Temporary machinist personnel' },
  { value: '56112108', label: 'Combination chair with desk' },
  { value: '46181532', label: 'Lab coats' },
  { value: '14111526', label: 'Telephone message pads or books' },
  { value: '44111909', label: 'Board cleaning kits or accessories' },
  { value: '26111719', label: 'Battery testers' },
  { value: '52152200', label: 'Dishwashing and dish storage accessories' },
  {
    value: '56120000',
    label:
      'Classroom and instructional and institutional furniture and fixtures'
  },
  { value: '56121000', label: 'Library furnishings' },
  { value: '56121400', label: 'Cafeteria and lunchroom furnishings' },
  { value: '56121500', label: 'General classroom furnishings' },
  { value: '56121501', label: 'Activity tables' },
  { value: '56121502', label: 'Classroom chairs' },
  { value: '56121503', label: 'Classroom benches' },
  { value: '56121504', label: 'Classroom stools' },
  { value: '56121505', label: 'Classroom tables' },
  { value: '56121506', label: 'Student desks' },
  { value: '56121507', label: 'Study carrels' },
  { value: '56121508', label: 'Student computer desks' },
  { value: '56121509', label: 'Student computer tables' },
  { value: '56121700', label: 'Book and general storage units for classrooms' },
  { value: '56121900', label: 'Demonstration furnishings' },
  {
    value: '45111700',
    label:
      'Audio presentation and composing equipment and hardware and controllers'
  },
  { value: '45111701', label: 'Assistive listening devices' },
  { value: '45111702', label: 'Audio jack boxes' },
  { value: '45111703', label: 'Listening centers' },
  {
    value: '45111800',
    label:
      'Video and combination video and audio presentation equipment and hardware and controllers'
  },
  { value: '45111801', label: 'Media control systems' },
  { value: '45111802', label: 'Television mounts' },
  { value: '45111803', label: 'Scan converters' },
  { value: '45111804', label: 'Line doublers' },
  { value: '45111805', label: 'Video editors' },
  { value: '45111806', label: 'Distance learning systems' },
  { value: '45111807', label: 'Interfaces' },
  { value: '45111808', label: 'Lighting controls' },
  {
    value: '45111900',
    label: 'Phone and video conference equipment and hardware and controllers'
  },
  { value: '45111901', label: 'Audioconferencing systems' },
  { value: '45111902', label: 'Videoconferencing systems' },
  { value: '45111612', label: 'Dissolve controls' },
  { value: '45111613', label: 'Cathode ray tube projector' },
  { value: '45111614', label: 'Liquid crystal display projector' },
  { value: '45111615', label: 'Projection lenses' },
  { value: '45121516', label: 'Digital camcorders or video cameras' },
  { value: '45121517', label: 'Document camera' },
  { value: '45111616', label: 'Video projectors' },
  { value: '49211800', label: 'Physical education classroom equipment' },
  { value: '49211802', label: 'Hula hoops or hoop equipment' },
  { value: '49211803', label: 'Orienteering equipment' },
  { value: '49211804', label: 'Team identification materials or markers' },
  { value: '49211805', label: 'Lanyard' },
  { value: '49211806', label: 'Physical education equipment storage' },
  { value: '49211807', label: 'Physical education assessment tools' },
  { value: '14122203', label: 'Transfer paper' },
  { value: '60101700', label: 'Teacher resource materials' },
  { value: '60103700', label: 'Foreign languages resources' },
  { value: '60103701', label: 'Resources for learning to speak Spanish' },
  { value: '60103702', label: 'Resources for learning to speak French' },
  { value: '60103704', label: 'Resources for learning to speak English' },
  {
    value: '60103900',
    label: 'Living organisms and preserved specimens and related materials'
  },
  { value: '60103905', label: 'Culture kits or supplies' },
  { value: '60104100', label: 'Body systems and related materials' },
  { value: '60104200', label: 'Water testing and conservation and ecology' },
  { value: '60104201', label: 'Water testing chemicals' },
  { value: '60104202', label: 'Water testing and sampling kits' },
  { value: '60104203', label: 'Water models' },
  { value: '60104204', label: 'Water ecology supplies' },
  { value: '60104400', label: 'Geology and earth science' },
  { value: '60104700', label: 'Energy and power physics materials' },
  { value: '60104701', label: 'Solar collecting devices' },
  { value: '60104702', label: 'Solar kits' },
  { value: '60105300', label: 'Career education instructional materials' },
  {
    value: '60105301',
    label:
      'Career education or planning or decision making skills instructional materials'
  },
  { value: '60105302', label: 'Basic job skills instructional materials' },
  { value: '60105303', label: 'Job search skills instructional materials' },
  {
    value: '60105304',
    label: 'Time management skills instructional materials'
  },
  { value: '60105305', label: 'Interview skills instructional materials' },
  { value: '60105306', label: 'Resume skills instructional materials' },
  {
    value: '60105307',
    label: 'Work ethics or attitude training instructional materials'
  },
  { value: '60105308', label: 'Team building skills instructional materials' },
  { value: '60105309', label: 'Business etiquette instructional materials' },
  {
    value: '60105600',
    label:
      'Health education and nutrition and food preparation instructional materials'
  },
  { value: '60105613', label: 'Kitchen utensils instructional aids' },
  {
    value: '60105900',
    label:
      'Dating and sex and teen pregnancy and parenting skills and child development instructional materials'
  },
  {
    value: '60105901',
    label:
      'Sex education or sexually transmitted disease instructional materials'
  },
  {
    value: '60105902',
    label: 'Prenatal nutrition resources or fetal abuse instructional materials'
  },
  { value: '60105903', label: 'Parenting skills instructional materials' },
  { value: '60105904', label: 'Child development instructional materials' },
  {
    value: '60105905',
    label:
      'Understanding date rape or dating skills or harassment instructional materials'
  },
  { value: '60105906', label: 'Childbirth education instructional materials' },
  {
    value: '60105907',
    label: 'Pregnancy from conception through birth instructional materials'
  },
  {
    value: '60105908',
    label: 'Understanding the risks of birth defects instructional materials'
  },
  {
    value: '60105915',
    label:
      'Cardio pulmonary resusitation or basic life support instructional materials'
  },
  { value: '60106100', label: 'Vocational teaching aids and materials' },
  { value: '60106101', label: 'Automotive teaching aids or materials' },
  { value: '60106102', label: 'Construction teaching aids or materials' },
  {
    value: '60106104',
    label: 'Electronics or electricity teaching aids or materials'
  },
  { value: '60106200', label: 'Technology teaching aids and materials' },
  { value: '60106201', label: 'Agriculture teaching aids or materials' },
  { value: '60106202', label: 'Biotechnology teaching aids or materials' },
  { value: '60106203', label: 'Communications teaching aids or materials' },
  { value: '60106204', label: 'Computer science teaching aids or materials' },
  { value: '60106205', label: 'Energy or power teaching aids or materials' },
  { value: '60106206', label: 'Environmental teaching aids or materials' },
  { value: '60106208', label: 'Medical teaching aids or materials' },
  { value: '60106209', label: 'Transportation teaching aids or materials' },
  { value: '60121300', label: 'Art and craft cutting products' },
  { value: '60121301', label: 'Guillotine paper trimmers' },
  { value: '60121600', label: 'Studio aids' },
  { value: '60131600', label: 'Musical instrument sets' },
  { value: '60131601', label: 'Rhythm band sets' },
  { value: '60131700', label: 'Alternative sounds musical instruments' },
  { value: '60131701', label: 'Boomwhackers' },
  { value: '60131702', label: 'Disco taps' },
  { value: '60131800', label: 'Music and dance accessories' },
  { value: '60131801', label: 'Dancing scarves' },
  { value: '60131802', label: 'Rhythm sticks or lummi sticks' },
  { value: '60131803', label: 'Rhythm wands or hoops' },
  { value: '44103116', label: 'Kit for printer' },
  { value: '95141605', label: 'Gazebo' },
  {
    value: '43201815',
    label:
      'Peripheral component microchannel interconnect architecture reader and writer drives'
  },
  { value: '52161535', label: 'Digital voice recorders' },
  { value: '95141708', label: 'Office kitchen' },
  { value: '43201812', label: 'Magneto optical MO drives' },
  { value: '44103117', label: 'Facsimile ribbons' },
  { value: '84131607', label: 'Liability insurance' },
  { value: '43201614', label: 'Console extenders' },
  { value: '53102702', label: 'Customs uniforms' },
  { value: '53102703', label: 'Police uniforms' },
  { value: '44101707', label: 'Stapler units' },
  { value: '56112109', label: 'Benches' },
  { value: '45121616', label: 'Pan heads' },
  { value: '53131635', label: 'Shaving brushes' },
  { value: '81151705', label: 'Archaeological services' },
  { value: '24113103', label: 'Box lid' },
  { value: '44103118', label: 'Transparency film' },
  { value: '14111527', label: 'Carbonless paper' },
  { value: '44111516', label: 'Personal organizers' },
  { value: '45111617', label: 'Overhead projector or video trolleys' },
  {
    value: '53102704',
    label: 'Institutional food preparation or service attire'
  },
  { value: '48101918', label: 'Food service table covering rolls' },
  { value: '44121632', label: 'Scissor sharpener' },
  { value: '44103119', label: 'Heat transfer paper for copiers' },
  { value: '23201200', label: 'Industrial drying equipment' },
  { value: '44111517', label: 'Study stands' },
  { value: '56101530', label: 'Storage cabinets' },
  { value: '56101531', label: 'Shoe racks' },
  { value: '56101532', label: 'Furniture set' },
  { value: '45111503', label: 'Gavels or sounding blocks' },
  { value: '55121906', label: 'Flag stands' },
  { value: '14111528', label: 'Magnet paper' },
  { value: '56122000', label: 'Laboratory furniture' },
  { value: '45121518', label: 'Camera kits' },
  { value: '45111618', label: 'Presentation light boxes' },
  { value: '84111507', label: 'Inventory accounting service' },
  { value: '41113320', label: 'Lubricating oil testing kit' },
  { value: '14111529', label: 'Telex rolls' },
  { value: '46101800', label: 'Arms and ammunition accessories' },
  { value: '46181606', label: 'Footwear covers' },
  { value: '47132100', label: 'Cleaning kits' },
  { value: '47132101', label: 'Industrial cleaning kits' },
  { value: '47132102', label: 'General purpose cleaning kits' },
  { value: '46182500', label: 'Personal safety devices or weapons' },
  { value: '14111530', label: 'Self adhesive note paper' },
  {
    value: '60106300',
    label: 'Forensic science teaching equipment and supplies'
  },
  { value: '60106301', label: 'Forensic science kits' },
  {
    value: '51270000',
    label: 'Anaesthetic drugs and related adjuncts and analeptics'
  },
  { value: '30111607', label: 'Unslaked lime' },
  { value: '81112007', label: 'Content or data standardization services' },
  { value: '44122117', label: 'Book rings' },
  { value: '47121707', label: 'Motion sickness bags' },
  { value: '50171551', label: 'Cooking or table salt' },
  { value: '41113034', label: 'pH test strips or papers' },
  { value: '41113035', label: 'Chemical test strips or papers' },
  { value: '41116014', label: 'Urinalysis analyzer reagents' },
  { value: '41113322', label: 'Nitrogen or nitrate or nitrite analyzer' },
  { value: '10171504', label: 'Compost' },
  { value: '25101907', label: 'Trailer, travel or caravan' },
  { value: '76111800', label: 'Transport vehicle cleaning' },
  { value: '76111801', label: 'Car or boat detailing' },
  { value: '44101708', label: 'Ozone filters' },
  { value: '43212112', label: 'Point of sale POS receipt printers' },
  {
    value: '48101532',
    label: 'Commercial use cotton candy machines or accessories'
  },
  { value: '48101715', label: 'Ice shaver machines or accessories' },
  { value: '25101508', label: 'Sports car' },
  { value: '83111510', label: 'Interactive voice response service' },
  { value: '84121607', label: 'Operating lease finance service' },
  { value: '53102705', label: 'School uniforms' },
  { value: '46151607', label: 'Security lanyards' },
  { value: '44103120', label: 'Toner collectors' },
  { value: '45121617', label: 'Camera bags' },
  { value: '45121618', label: 'Camera lens adapters' },
  { value: '43212113', label: 'Compact disc CD or labeling printers' },
  { value: '45121619', label: 'Picture card wallets' },
  { value: '23220000', label: 'Chicken processing machinery and equipment' },
  { value: '23221000', label: 'Arrival department machinery and equipment' },
  { value: '45121620', label: 'Camera power adapters' },
  {
    value: '43201814',
    label: 'Electronic media or data duplicating equipment'
  },
  { value: '23221001', label: 'Crate unloading system' },
  { value: '45121621', label: 'Picture card adapters' },
  { value: '53102516', label: 'Caps' },
  { value: '53102706', label: 'Security uniforms' },
  { value: '53102707', label: 'Doctors coat' },
  { value: '44121633', label: 'Postage stamp dispensers' },
  { value: '44103503', label: 'Binding spines or snaps' },
  { value: '44103504', label: 'Binding coils or wire loops' },
  { value: '44103505', label: 'Binding combs or strips' },
  { value: '44121716', label: 'Highlighters' },
  { value: '44111910', label: 'Hanging rails or holders' },
  { value: '25191513', label: 'Ground support vehicle maintenance kit' },
  { value: '56101710', label: 'Projector stands or carts' },
  { value: '53131509', label: 'Mouth fresheners' },
  { value: '41122808', label: 'General purpose trays' },
  { value: '47121812', label: 'Cleaning scrapers' },
  { value: '47121813', label: 'Scraper replacement blades' },
  { value: '39121000', label: 'Power conditioning equipment' },
  { value: '39121001', label: 'Distribution power transformers' },
  {
    value: '39121100',
    label: 'Distribution and control centers and accessories'
  },
  { value: '39121101', label: 'Load centers' },
  { value: '23151816', label: 'Chromatography columns' },
  { value: '23151817', label: 'Chromatography media' },
  { value: '41112412', label: 'Pressure gauge' },
  { value: '56101711', label: 'Modular furniture connectors' },
  { value: '56101712', label: 'Pedestals' },
  { value: '90111800', label: 'Hotel rooms' },
  { value: '90111801', label: 'Single room' },
  { value: '90111802', label: 'Double room' },
  { value: '90111803', label: 'Suite' },
  { value: '41104927', label: 'Filter support screens' },
  { value: '43201615', label: 'Drive bay cover kits' },
  { value: '47131829', label: 'Toilet cleaners' },
  {
    value: '48101919',
    label: 'Food service glasses or cups or mugs or container lids'
  },
  { value: '44122023', label: 'Archboards' },
  { value: '44122024', label: 'Binder handles' },
  { value: '44122025', label: 'Binder pockets or accessories' },
  { value: '44111518', label: 'Business card holders' },
  { value: '47121900', label: 'Cleaning equipment accessories' },
  { value: '47121901', label: 'Squeege or washer holsters' },
  { value: '47121902', label: 'Squeege or washer accessories' },
  { value: '45112000', label: 'Microfilm equipment and supplies' },
  { value: '45112002', label: 'Microfiche reader printers' },
  { value: '45121622', label: 'Camera lens cleaners' },
  { value: '46171619', label: 'Security or access control systems' },
  { value: '44121808', label: 'Electrical erasers' },
  { value: '25101610', label: 'Water trucks' },
  { value: '25101908', label: 'Quads' },
  { value: '45121800', label: 'Microfilm production equipment and supplies' },
  { value: '45121802', label: 'Microfilm duplicators' },
  { value: '45121803', label: 'Microfilm jacket fillers' },
  { value: '45121804', label: 'Microfilm processors' },
  {
    value: '44103121',
    label: 'Printer or facsimile or photocopier roller guides'
  },
  { value: '44111610', label: 'Coin trays' },
  { value: '44111519', label: 'Collating racks' },
  { value: '43211720', label: 'Point of sale payment terminal' },
  { value: '25102106', label: 'Tractor head' },
  { value: '25101611', label: 'Cargo trucks' },
  { value: '51271613', label: 'Lidocaine' },
  { value: '51391734', label: 'Phenylephrine' },
  { value: '51151606', label: 'Tropicamide' },
  { value: '51171806', label: 'Metoclopramide' },
  { value: '51284014', label: 'Oxytetracycline' },
  { value: '51283501', label: 'Cloxacillin' },
  { value: '51452305', label: 'Copper napthanate or copper naphthenate' },
  { value: '51101693', label: 'Toltrazuril' },
  { value: '51453404', label: 'Flumethrin' },
  { value: '51452801', label: 'Ivermectin' },
  { value: '51451604', label: 'Oxfendazole' },
  { value: '51201700', label: 'Poultry vaccines' },
  { value: '51201702', label: 'E Coli vaccines' },
  { value: '51201703', label: 'Gumboro vaccines' },
  { value: '51201704', label: 'Avian infectious bronchitis vaccines' },
  { value: '51201705', label: 'Newcastle virus vaccine' },
  { value: '51281618', label: 'Neomycin' },
  { value: '51240000', label: 'Drugs affecting the ears, eye, nose and skin' },
  { value: '51282916', label: 'Enrofloxacin' },
  { value: '50201714', label: 'Non dairy creamers' },
  { value: '45121706', label: 'Photo cutters or trimmers' },
  { value: '45101808', label: 'Thermal book binding machines' },
  { value: '44101604', label: 'Base protection boards' },
  { value: '14111531', label: 'Log books or pads' },
  { value: '44122026', label: 'Paper claws' },
  { value: '44111814', label: 'Work surface protection covers' },
  { value: '48102107', label: 'Catering gloves or glove dispensers' },
  { value: '56101715', label: 'Mail sorters or organizers' },
  {
    value: '45111504',
    label: 'Lighting or power or data components for lecterns'
  },
  { value: '56101716', label: 'Desktop organizer hutches' },
  { value: '44101605', label: 'Paper jogging machines' },
  { value: '44101606', label: 'Paper sorting machines' },
  { value: '44111815', label: 'Drafting table covers' },
  { value: '44122027', label: 'Expandable file folders' },
  { value: '43201811', label: 'Read write digital versatile disc DVD' },
  { value: '44111611', label: 'Money clips' },
  { value: '39121534', label: 'Indicator or pilot lights' },
  { value: '81111818', label: 'Third party warranty service' },
  { value: '14111807', label: 'Multipurpose business book' },
  { value: '24101512', label: 'Power buggies' },
  {
    value: '45112003',
    label: 'Microfiche or microfilm viewer components or accessories'
  },
  {
    value: '45112004',
    label: 'Microfiche reader printer components or accessories'
  },
  { value: '45121805', label: 'Microfilm camera components or accessories' },
  {
    value: '45121806',
    label: 'Microfilm duplicator components or accessories'
  },
  {
    value: '45121807',
    label: 'Microfilm jacket filler components or accessories'
  },
  { value: '45121808', label: 'Microfilm processor components or accessories' },
  { value: '45121809', label: 'Microfilm film supplies' },
  { value: '45121810', label: 'Microfilm sundry components or accessories' },
  { value: '44111612', label: 'Coin bag seal' },
  { value: '44122119', label: 'Self adhesive fasteners' },
  { value: '14111808', label: 'Accounting forms or accounting books' },
  { value: '14111809', label: 'Bill of lading forms or bill of lading books' },
  { value: '14111810', label: 'Personnel forms or personnel books' },
  { value: '14111811', label: 'Sales forms or sales books' },
  { value: '14111812', label: 'Inventory forms or inventory books' },
  { value: '14111813', label: 'Correspondence forms or correspondence books' },
  { value: '14111814', label: 'Tax forms or tax books' },
  { value: '46182006', label: 'Protective films' },
  {
    value: '80141507',
    label: 'Consumer based research or clinics or focus groups'
  },
  { value: '80141508', label: 'Syndicated or proprietary forecast studies' },
  { value: '80141509', label: 'Market intelligence or competitive analysis' },
  { value: '80141612', label: 'Sales or marketing programs' },
  { value: '80141613', label: 'After sales programs' },
  { value: '80141618', label: 'Sales marketing agencies including print' },
  {
    value: '80141619',
    label: 'Customer relationship center CRC management services'
  },
  { value: '80141900', label: 'Trade shows and exhibits' },
  { value: '80141901', label: 'Auto shows or other exhibits' },
  { value: '80141902', label: 'Meetings events' },
  { value: '23153200', label: 'Robotics' },
  { value: '23241615', label: 'Taps' },
  {
    value: '46182007',
    label: 'Powered air purifying respirator system PAPRs or accessories'
  },
  { value: '41123400', label: 'Dosing devices' },
  { value: '41123401', label: 'Dosing cups' },
  { value: '41123402', label: 'Dosing spoons' },
  { value: '41123403', label: 'Dosing droppers' },
  { value: '43191615', label: 'Vehicle handsfree phone set' },
  { value: '41115320', label: 'Signal generators' },
  { value: '44121512', label: 'Mailer tube caps' },
  { value: '14111815', label: 'Tent cards' },
  { value: '45111704', label: 'Audio mixing consoles' },
  { value: '46191504', label: 'Flame detectors' },
  { value: '41112104', label: 'Audio transducers' },
  { value: '41111929', label: 'Radiation detectors' },
  { value: '41111930', label: 'Electrical power sensors' },
  { value: '41111931', label: 'Flow sensors' },
  { value: '46171620', label: 'Safety light curtains' },
  { value: '41111932', label: 'Liquid leak detectors' },
  { value: '80121706', label: 'Employment law services' },
  { value: '23153400', label: 'Assembly machines' },
  { value: '23153500', label: 'Paint systems' },
  { value: '43191507', label: 'Special purpose telephones' },
  { value: '43191616', label: 'Centrex phone console' },
  { value: '43202201', label: 'Telephone piece parts' },
  { value: '43191618', label: 'Conversation recording units' },
  { value: '43223211', label: 'Paging controllers' },
  { value: '43223212', label: 'Paging terminals' },
  { value: '43191623', label: 'Pay phone coin mechanisms' },
  { value: '43191624', label: 'Pay phone coin chutes' },
  { value: '43191625', label: 'Pay phone coin boxes' },
  { value: '43191626', label: 'Pay phone hoppers' },
  { value: '43191627', label: 'Pay phone coin box vault doors' },
  {
    value: '43221807',
    label:
      'Telecommunications dense wavelength division multiplexing DWDM filters'
  },
  {
    value: '43221805',
    label: 'Asynchronous transfer mode ATM network equipment'
  },
  { value: '41111933', label: 'Electrical charge sensors' },
  { value: '41111934', label: 'Force or torque sensors' },
  { value: '41111935', label: 'Tilt sensors' },
  {
    value: '41111936',
    label: 'Complementary metal oxide semiconductor CMOS image sensors'
  },
  { value: '41111937', label: 'Rotary position sensors' },
  { value: '41111938', label: 'Level sensors or transmitters' },
  { value: '80121707', label: 'Labor disputes law services' },
  { value: '45121623', label: 'Camera controllers' },
  { value: '41111939', label: 'Acoustic sensors' },
  { value: '41111940', label: 'Color sensors' },
  { value: '41111941', label: 'Olfactory sensors' },
  { value: '41111942', label: 'Opacity or dust or visibility sensors' },
  { value: '41111943', label: 'Electrical resistance or conductance sensors' },
  { value: '41112105', label: 'Temperature transmitters' },
  { value: '41112216', label: 'Thermowells' },
  { value: '41112217', label: 'Thermoheads' },
  { value: '41112106', label: 'Humidity transmitters' },
  { value: '41111737', label: 'Videoscopes' },
  { value: '41111738', label: 'Fiberscopes' },
  { value: '45121519', label: 'Low light camera' },
  { value: '41103415', label: 'Clean benches' },
  { value: '41112516', label: 'Flow transmitters' },
  { value: '43211717', label: 'Optical character recognition systems' },
  { value: '41112408', label: 'Pressure intensifiers' },
  { value: '41112409', label: 'Pressure scanners' },
  { value: '23201202', label: 'Air dryers' },
  { value: '41111809', label: 'Leak testing equipment' },
  { value: '41113036', label: 'Microplates' },
  { value: '41113037', label: 'Microplate readers' },
  { value: '41103316', label: 'Pycnometers' },
  {
    value: '41116400',
    label: 'Acceleration and vibration measuring instruments'
  },
  { value: '26111720', label: 'Battery holders' },
  { value: '41115321', label: 'Infrared imagers' },
  { value: '41115322', label: 'Laser beam analyzers' },
  { value: '41113716', label: 'Fiber optic fault locators' },
  { value: '41113717', label: 'Fiber optic test sources' },
  { value: '41104018', label: 'Solid phase extraction preparations' },
  { value: '41122109', label: 'Petri pads' },
  { value: '41122110', label: 'Petri pad dispenser' },
  { value: '44121634', label: 'Adhesive rollers' },
  { value: '14111532', label: 'Assorted paper kits' },
  { value: '41105109', label: 'Rotary vane pumps' },
  {
    value: '43202104',
    label: 'Vertical helix scan VHS videotape storage or accessories'
  },
  { value: '53102708', label: 'Nurses uniforms' },
  { value: '53102709', label: 'Ambulance officers uniforms' },
  { value: '14111610', label: 'Construction paper' },
  { value: '30181511', label: 'Toilet bowls' },
  { value: '30181603', label: 'Toilet seat' },
  { value: '30181604', label: 'Toilet seat lid' },
  { value: '32140000', label: 'Electron tube devices and accessories' },
  { value: '41112410', label: 'Pressure transmitters' },
  { value: '30181514', label: 'Toilet tank covers' },
  { value: '30181515', label: 'Toilet tanks' },
  { value: '25111906', label: 'Anchor chocks' },
  { value: '25111907', label: 'Anchor lines' },
  { value: '47121708', label: 'Hygienic bags' },
  { value: '26101412', label: 'Motor repair kit' },
  { value: '41116500', label: 'Instrument parts and accessories' },
  { value: '41116501', label: 'Meter dials or dial kits' },
  { value: '39121721', label: 'Electrical insulators' },
  { value: '21101708', label: 'Mower parts or accessories' },
  { value: '20101800', label: 'Mechanized ground support systems' },
  { value: '20101900', label: 'Secondary rock breaking systems' },
  { value: '20102000', label: 'Exploration and development systems' },
  { value: '20102200', label: 'Explosive loading machinery' },
  { value: '20102300', label: 'Underground mining service vehicles' },
  { value: '20102301', label: 'Personnel carriers' },
  { value: '20102302', label: 'Crane vehicles' },
  { value: '20102303', label: 'Flat deck material carriers' },
  { value: '20102304', label: 'Bulk material carriers' },
  { value: '20102305', label: 'Utility service vehicles' },
  { value: '20102306', label: 'Elevating platform vehicles or scissor lifts' },
  {
    value: '20102307',
    label: 'Underground mining service vehicle spare parts or accessories'
  },
  { value: '41111944', label: 'Electrical admittance sensors' },
  { value: '41111945', label: 'Linear position sensors' },
  { value: '41111946', label: 'Electrical inductance sensors' },
  { value: '41112107', label: 'Electro pneumatic transducers' },
  { value: '41112510', label: 'Sight flow indicators' },
  { value: '41112511', label: 'Sight flow windows' },
  { value: '41103317', label: 'Surface tension measuring instruments' },
  { value: '41112512', label: 'Flow computers or totalizers' },
  { value: '41113119', label: 'Dissolved carbon dioxide analyzers' },
  { value: '25111807', label: 'Dinghies' },
  { value: '44101805', label: 'Calculator ribbons' },
  { value: '81112008', label: 'Cd rom mastering services' },
  { value: '41112513', label: 'Orifice plate' },
  { value: '41111947', label: 'Chart recorder pens' },
  { value: '27140000', label: 'Automotive specialty tools' },
  { value: '27141000', label: 'Body tools' },
  { value: '41112219', label: 'Thermocouple probes' },
  { value: '41112411', label: 'Pressure controllers' },
  { value: '41112108', label: 'Loadcells' },
  { value: '41112514', label: 'Oil gauges' },
  { value: '41121813', label: 'Cuvettes' },
  { value: '46182402', label: 'Safety wash units' },
  { value: '44111613', label: 'Credit card holders' },
  { value: '25174213', label: 'Steering cylinder assemblies' },
  { value: '44101806', label: 'Cash register ribbons' },
  { value: '43201609', label: 'Storage device trays or assemblies' },
  { value: '26112004', label: 'Clutch repair kits' },
  { value: '43201610', label: 'Backplane or panels or assemblies' },
  { value: '43211805', label: 'Service kits for storage devices' },
  { value: '25111808', label: 'Yachts' },
  { value: '24101633', label: 'Hoist drums' },
  {
    value: '25174700',
    label: 'Non motorized cycle components and accessories'
  },
  { value: '44102412', label: 'Adhesive label cartridges' },
  { value: '44121635', label: 'Adhesive tape spindles' },
  { value: '44122028', label: 'Binder mounting channels' },
  { value: '44122120', label: 'Binder posts' },
  { value: '80111704', label: 'Permanent marketing staff needs' },
  { value: '80111705', label: 'Permanent machinist personnel' },
  { value: '82121907', label: 'Velo binding services' },
  { value: '43233501', label: 'Electronic mail software' },
  { value: '26111721', label: 'Nickel metal hydride batteries' },
  { value: '44121717', label: 'Combination pen and highlighter' },
  { value: '49221511', label: 'Sports equipment bags' },
  { value: '45111809', label: 'Television mount accessories' },
  { value: '44103506', label: 'Binding tape' },
  { value: '44103507', label: 'Binding Kits' },
  { value: '44103122', label: 'Print bands' },
  { value: '43201611', label: 'Computer cradles' },
  { value: '43212114', label: 'Digital image printers' },
  { value: '30171513', label: 'Kick plates' },
  { value: '44102609', label: 'Accessory or supply kits for typewriters' },
  { value: '44102003', label: 'Transfer foils' },
  { value: '45111705', label: 'Public address systems' },
  { value: '14111533', label: 'Examination booklets or forms' },
  { value: '14111816', label: 'Applicant fingerprint cards' },
  { value: '44102004', label: 'Creative foils' },
  { value: '44111614', label: 'Bill strap racks' },
  { value: '53103000', label: 'Tshirts' },
  { value: '53103100', label: 'Waistcoats' },
  { value: '53103101', label: 'Mens waistcoats' },
  { value: '46171621', label: 'Surveillance video or audio recorders' },
  { value: '44121718', label: 'Secured pen sets' },
  { value: '44122121', label: 'Wall or board clips' },
  { value: '44102107', label: 'Stamp affixers' },
  { value: '44102610', label: 'Typewriter starter kits' },
  { value: '95141606', label: 'Home kitchen' },
  { value: '43201612', label: 'Computer faceplates' },
  { value: '44111520', label: 'Surface protectors or pads' },
  { value: '44101709', label: 'Mirror assemblies' },
  { value: '44101710', label: 'Magnetic pickup assemblies' },
  { value: '26101764', label: 'Cylinder liners' },
  { value: '41112304', label: 'Moisture meters' },
  {
    value: '46151700',
    label: 'Forensic equipment and supplies and accessories'
  },
  { value: '46151702', label: 'Fingerprint applicators or brushes' },
  { value: '46151703', label: 'Fingerprint ink' },
  { value: '46151704', label: 'Fingerprint ink removers' },
  { value: '46151705', label: 'Fingerprint or palmprint ink rollers' },
  { value: '46151706', label: 'Fingerprint latent print kits' },
  { value: '46151707', label: 'Fingerprint lifters' },
  { value: '46151708', label: 'Forensic magnifiers' },
  { value: '46151709', label: 'Fingerprint marking pens' },
  { value: '46151710', label: 'Fingerprint powders' },
  { value: '46151711', label: 'Footprint lifters' },
  { value: '46151712', label: 'Forensic chemical workstations' },
  { value: '46151713', label: 'Forensic latent print chemicals' },
  { value: '46151714', label: 'Evidence drying cabinets' },
  {
    value: '41105500',
    label:
      'Nucleic acid DNA and RNA extraction and purification and quantitation kits and components'
  },
  {
    value: '41105501',
    label: 'Deoxyribonucleic acid DNA cleanup or gel extraction kits'
  },
  {
    value: '41105502',
    label: 'Kits for deoxyribonucleic acid DNA extraction from food'
  },
  {
    value: '41105504',
    label: 'Genomic deoxyribonucleic acid DNA purification kits'
  },
  {
    value: '41105506',
    label: 'Kits for purification of messenger ribonucleic acid mRNA'
  },
  { value: '41105508', label: 'Nucleic acids coprecipitants' },
  { value: '41105509', label: 'Nucleic acids quantitation kits' },
  {
    value: '41105510',
    label: 'Phage deoxyribonucleic acid DNA purification kits'
  },
  {
    value: '41105511',
    label: 'Kits for plasmids deoxyribonucleic acid DNA extraction from yeast'
  },
  {
    value: '41105512',
    label:
      'Plasmids or cosmids or bacterial artificial chromosomes BAC purification kit'
  },
  { value: '41105513', label: 'Labeled nucleic acid purification kits' },
  {
    value: '41105514',
    label:
      'Reagents for nucleic acid extraction or precipitation or resuspension'
  },
  {
    value: '41105515',
    label: 'Ribonucleic acid RNA cleanup or stabilization materials'
  },
  { value: '41105516', label: 'Ribonucleic acid RNA gel extraction kits' },
  {
    value: '41105517',
    label: 'Kits for nucleic acid extraction from plant cells or tissue'
  },
  { value: '41105518', label: 'Total ribonucleic acid RNA purification kits' },
  {
    value: '41105519',
    label: 'Viral deoxyribonucleic acid DNA purification kits'
  },
  { value: '41105520', label: 'Viral ribonucleic acid RNA purification kits' },
  { value: '41105600', label: 'Deoxyribonucleic acid DNA sequencing products' },
  { value: '41105601', label: 'Kits or enzymes for sequencing' },
  { value: '41105700', label: 'Gene arrays' },
  {
    value: '41105800',
    label: 'In vitro transcription and translation products'
  },
  { value: '41105803', label: 'Transcription or translation systems or kits' },
  { value: '41105804', label: 'Translation labeling accessories' },
  { value: '41105900', label: 'Libraries and related materials' },
  {
    value: '41105902',
    label: 'Complementary deoxyribonucleic acid cDNA libraries'
  },
  {
    value: '41105903',
    label: 'Complementary deoxyribonucleic acid cDNA synthesis kits'
  },
  { value: '41105906', label: 'Protein or peptide display libraries' },
  { value: '41105907', label: 'Two hybrid libraries or systems' },
  { value: '41105908', label: 'Viral packaging kits' },
  { value: '41106000', label: 'Nucleic acid labeling and detection systems' },
  { value: '41106004', label: 'Nucleic acid non radioactive labeling kits' },
  { value: '41106005', label: 'Nucleic acid radioactive labeling kits' },
  { value: '41106100', label: 'Deoxyribonucleic acid DNA analysis kits' },
  { value: '41106101', label: 'Cytogenetics kits' },
  { value: '41106102', label: 'Differential display or subtraction kits' },
  { value: '41106103', label: 'Deoxyribonucleic acid DNA typing kits' },
  { value: '41106104', label: 'Nuclease protection assays' },
  {
    value: '41106200',
    label:
      'Microorganism propagation and transformation media and kits and equipment'
  },
  { value: '41106203', label: 'Bacteria transformation kits' },
  { value: '41106204', label: 'Bottled agar media or stabs for bacteria' },
  {
    value: '41106210',
    label: 'Media or supplements for schizosaccharomyces pombe'
  },
  {
    value: '41106211',
    label: 'Media ingredients or additives schizosaccharomyces pombe'
  },
  { value: '41106212', label: 'Media ingredients or additives for bacteria' },
  { value: '41106213', label: 'Premixed media dry' },
  { value: '41106214', label: 'Reagents for preparing competent bacteria' },
  { value: '41106215', label: 'Reagents for preparing competent yeast' },
  { value: '41106216', label: 'Rich media for yeast' },
  { value: '41106217', label: 'Specialty plates for bacteria' },
  { value: '41106218', label: 'Specialty premixed media dry' },
  {
    value: '41106219',
    label: 'Synthetic complete supplement mixtures for yeast'
  },
  { value: '41106220', label: 'Synthetic media for yeast' },
  { value: '41106221', label: 'Yeast competent cells' },
  { value: '41106222', label: 'Yeast transformation kits' },
  {
    value: '41106223',
    label: 'Yeast nitrogen bases YNB or yeast nitrogen base YNB variants'
  },
  {
    value: '41106300',
    label:
      'Polymerase chain reaction PCR and reverse transcriptase polymerase chain reaction RT PCR products'
  },
  {
    value: '41106302',
    label: 'Gene specific polymerase chain reaction PCR kits'
  },
  {
    value: '41106303',
    label: 'Kits for polymerase chain reaction PCR purification'
  },
  { value: '41106306', label: 'Polymerase chain reaction PCR buffers' },
  {
    value: '41106307',
    label: 'Polymerase chain reaction PCR optimizing products'
  },
  {
    value: '41106308',
    label:
      'Polymerase chain reaction PCR or reverse transcriptase polymerase chain reaction RT PCR primers'
  },
  {
    value: '41106309',
    label: 'Premade complementary deoxyribonucleic acid cDNA'
  },
  { value: '41106311', label: 'Purified ribonucleic acids RNA' },
  {
    value: '41106313',
    label: 'Reverse transcriptase polymerase chain reaction RT PCR kits'
  },
  {
    value: '41106314',
    label: 'Thermostable deoxyribonucleic acid DNA polymerases or kits'
  },
  { value: '41106400', label: 'Primers and linkers and adaptors' },
  { value: '41106401', label: 'Adaptors or linkers' },
  { value: '41106402', label: 'Miscellaneous primers' },
  { value: '41106403', label: 'Sequencing primers' },
  { value: '41106500', label: 'Protein expression products' },
  { value: '41106501', label: 'Bacterial expression kits' },
  { value: '41106502', label: 'Eucariotic transfection reagents' },
  { value: '41106505', label: 'Insect expression kits' },
  { value: '41106506', label: 'Insect media' },
  { value: '41106507', label: 'Insect medium supplements or reagents' },
  { value: '41106508', label: 'Mammalian cell expression kits' },
  { value: '41106509', label: 'Mammalian cells' },
  {
    value: '41106510',
    label: 'Kits for protein extraction from mammalian cells or tissues'
  },
  { value: '41106511', label: 'Kits for protein extraction from bacteria' },
  { value: '41106512', label: 'Kits for protein extraction from yeast' },
  { value: '41106513', label: 'Reporter gene assay' },
  { value: '41106514', label: 'Stable mammalian cell lines' },
  { value: '41106515', label: 'Yeast expression kits' },
  { value: '41106600', label: 'Vectors' },
  { value: '41106601', label: 'Chromosome targeting vectors' },
  { value: '41106604', label: 'Display vector maps or sequences' },
  { value: '41106605', label: 'Enzyme reporter vector maps or sequences' },
  {
    value: '41106606',
    label: 'Expression complementary deoxyribonucleic acid cDNA vectors'
  },
  { value: '41106607', label: 'Fluorescent protein vector maps or sequences' },
  { value: '41106608', label: 'Fusion vectors' },
  { value: '41106609', label: 'Gene targeting vectors' },
  { value: '41106610', label: 'General cloning vectors' },
  { value: '41106611', label: 'Hybrid system vectors or kits' },
  { value: '41106612', label: 'Insect expression vectors' },
  {
    value: '41106615',
    label: 'Polymerase chain reaction PCR cloning vectors or kits'
  },
  { value: '41106616', label: 'Phage or viral deoxyribonucleic acids DNA' },
  { value: '41106617', label: 'Plasmid mutagenesis vectors or kits' },
  { value: '41106619', label: 'Sequencing vectors' },
  {
    value: '41106620',
    label: 'Signal transduction reporter vector maps or sequences'
  },
  { value: '41106621', label: 'Virus mediated expression vectors or kits' },
  { value: '41106622', label: 'Yeast expression vectors' },
  { value: '41105307', label: 'Instrumentation for capillary electrophoresis' },
  { value: '41105308', label: 'Capillaries or cartridges' },
  {
    value: '41105309',
    label: 'Kits or reagents for capillary electrophoresis'
  },
  { value: '41105310', label: 'Blotting or transfer accessories' },
  { value: '41105311', label: 'Blotting or transfer apparatus' },
  { value: '41105312', label: 'Combs or plates or spacers or trays' },
  { value: '41105313', label: 'Cassettes or related detection accessories' },
  { value: '41105314', label: 'Gel documentation systems' },
  { value: '41105315', label: 'Gel documentation accessories' },
  { value: '41105316', label: 'Ultraviolet crosslinkers' },
  { value: '41105317', label: 'Agarose gel making reagents' },
  { value: '41105319', label: 'Polyacrylamide gel making reagents' },
  { value: '41105320', label: 'Polyacrylamide premade gels' },
  { value: '41105321', label: 'Nucleic acid gels stain' },
  { value: '41105322', label: 'Polyacrylamide gels stain' },
  { value: '41105323', label: 'Electrophoresis premade buffers or solutions' },
  {
    value: '41105325',
    label:
      'Microwells plates for deoxyribonucleic acid DNA or deoxyribonucleic acid DNA hybridization'
  },
  { value: '41105326', label: 'Hybridization reagents or buffers' },
  { value: '41105327', label: 'Conjugated nucleotides or oligomers' },
  { value: '41105328', label: 'Premade northern or southern or western blots' },
  { value: '41105329', label: 'Blocking agents' },
  {
    value: '41105330',
    label: 'Control proteins or cell lysates or tissue lysates'
  },
  {
    value: '41105331',
    label: 'Protein chemifluorescent detection reagents or kits or substrates'
  },
  {
    value: '41105332',
    label: 'Protein chemiluminescent detection reagents or kits or substrates'
  },
  {
    value: '41105333',
    label: 'Protein chromogenic detection reagents or kits or substrates'
  },
  {
    value: '41105334',
    label: 'Deoxyribonucleic acid DNA quantitation markers'
  },
  {
    value: '41105335',
    label: 'Deoxyribonucleic acid DNA size markers or standards'
  },
  { value: '41105336', label: 'Isoelectric focusing IEF markers' },
  { value: '41105337', label: 'Protein electrophoresis markers' },
  { value: '41105338', label: 'Ribonucleic acid RNA markers or standards' },
  { value: '51283112', label: 'Gramicidin' },
  { value: '51282956', label: 'Ofloxacin' },
  { value: '51284303', label: 'Thiamphenicol' },
  { value: '51343702', label: 'Moroxydine' },
  { value: '51282302', label: 'Clarithromycin' },
  { value: '51286801', label: 'Chlorquinaldol' },
  { value: '51241237', label: 'Benzoyl peroxide' },
  { value: '51282005', label: 'Tyrothricin' },
  { value: '51101604', label: 'Meglumine antimonate' },
  { value: '51283603', label: 'Rifapentine' },
  { value: '51101607', label: 'Calcium oxide' },
  { value: '51302701', label: 'Chlorocresol' },
  { value: '51284605', label: 'Meropenem' },
  { value: '51101612', label: 'Polynoxylin' },
  { value: '51101613', label: 'Pentamidine' },
  { value: '51101614', label: 'Diloxanide furoate' },
  { value: '51101616', label: 'Melarsoprol' },
  { value: '51282801', label: 'Tinidazole' },
  { value: '51101618', label: 'Taurolidine' },
  { value: '51451907', label: 'Niclosamide' },
  { value: '51453001', label: 'Piperazine' },
  { value: '51452402', label: 'Diethylcarbamazine' },
  { value: '51302901', label: 'Ciclopirox' },
  { value: '51162713', label: 'Guaiacol' },
  { value: '51281503', label: 'Amithiozone or thiacetazone' },
  { value: '51281504', label: 'Pyrazinamide' },
  { value: '51172332', label: 'Acetohydroxamic acid' },
  { value: '51241128', label: 'Hypromellose' },
  { value: '51162728', label: 'Naphazoline' },
  { value: '51473001', label: 'Benzalkonium Chloride' },
  { value: '51101630', label: 'Propamidine' },
  { value: '51470000', label: 'Antiseptics' },
  { value: '51285502', label: 'Clofoctol' },
  { value: '51473504', label: 'Sterile water for irrigation' },
  { value: '51452202', label: 'Benzyl benzoate' },
  { value: '51452302', label: 'Piperonyl' },
  { value: '51452502', label: 'Lindane' },
  { value: '51111739', label: 'Etoposide' },
  { value: '51281803', label: 'Epirubicin' },
  { value: '51111815', label: 'Triptorelin' },
  { value: '51123906', label: 'Propafenone' },
  { value: '51432803', label: 'Enalapril' },
  { value: '51263002', label: 'Prazosin' },
  { value: '51321604', label: 'Polidocanol' },
  { value: '51321808', label: 'Fenofibrate' },
  { value: '51433903', label: 'Nifedipine' },
  { value: '51410000', label: 'Vasodilators' },
  { value: '51132001', label: 'Pentoxifylline' },
  { value: '51131506', label: 'Erythropoietin' },
  { value: '51131705', label: 'Dipyridamole' },
  { value: '51131800', label: 'Coagulants and systemic hemostatic agents' },
  { value: '51131801', label: 'Fibrinogen' },
  { value: '51141509', label: 'Vigabatrin' },
  { value: '51141513', label: 'Carbamazepine' },
  { value: '51401501', label: 'Bromazepam' },
  { value: '51383304', label: 'Diclofenac' },
  { value: '51381502', label: 'Etofenamate' },
  { value: '51385805', label: 'Piroxicam' },
  { value: '51144601', label: 'Caffeine or trimethylxanthine' },
  { value: '51391717', label: 'Ephedrine' },
  { value: '51313301', label: 'Promethazine' },
  { value: '51313101', label: 'Cetirizine' },
  { value: '51162626', label: 'Dextromethorphan' },
  { value: '51162704', label: 'Bromhexine' },
  { value: '51171510', label: 'Magnesium trisilicate' },
  { value: '51171610', label: 'Senna glycosides or sennosides' },
  { value: '51171807', label: 'Cinnarizine' },
  { value: '51171808', label: 'Difenidol or diphenidol' },
  { value: '51171910', label: 'Pancreatin' },
  { value: '51184007', label: 'Gliclazide' },
  { value: '51422101', label: 'Prednisone' },
  { value: '51422408', label: 'Deflazacort' },
  { value: '51422206', label: 'Fluocinolone acetonide' },
  { value: '51352422', label: 'Desogestrel and ethinyl estradiol' },
  { value: '51351514', label: 'Progesterone' },
  { value: '51201627', label: 'Haemophilus B vaccine' },
  { value: '51201800', label: 'Immunostimulating agents' },
  { value: '50501500', label: 'Vitamin supplements' },
  { value: '51272406', label: 'Isoflurane' },
  { value: '51143200', label: 'Anti abuse drugs' },
  { value: '41113645', label: 'Earth leakage devices' },
  {
    value: '80111706',
    label: 'Permanent clerical or administrative assistance'
  },
  { value: '80111707', label: 'Permanent technical staffing needs' },
  { value: '80111708', label: 'Permanent financial staffing needs' },
  { value: '80111709', label: 'Permanent medical staff needs' },
  { value: '80111710', label: 'Permanent legal staffing needs' },
  {
    value: '80111711',
    label: 'Permanent information technology software developers'
  },
  {
    value: '80111712',
    label: 'Permanent information technology networking specialists'
  },
  {
    value: '80111713',
    label: 'Permanent information technology systems or database administrators'
  },
  { value: '80111714', label: 'Permanent drivers' },
  { value: '80111715', label: 'Permanent professional staff' },
  {
    value: '23221100',
    label: 'Killing and defeathering department machinery and equipment'
  },
  { value: '41103318', label: 'Nuclear densitometer' },
  { value: '51312304', label: 'Diphenhydramine' },
  { value: '46151506', label: 'Riot batons' },
  { value: '44101711', label: 'Compressor assembly' },
  { value: '50193100', label: 'Instant mixes and supplies' },
  { value: '78141703', label: 'Marine navigational or communication services' },
  { value: '44101712', label: 'Mailbox stackers' },
  { value: '43221526', label: 'Telephone entry systems' },
  { value: '51143202', label: 'Nicotine' },
  { value: '51131607', label: 'Enoxaparin' },
  { value: '56101535', label: 'Furniture carts' },
  { value: '51184004', label: 'Glibenclamide or glyburide' },
  { value: '51313802', label: 'Fexofenadine hydrochloride' },
  { value: '51432705', label: 'Ramipril' },
  { value: '52161542', label: 'Plasma screens' },
  { value: '51171511', label: 'Algeldrate or aluminium hydroxide' },
  { value: '43202105', label: 'Multiple media cabinets' },
  { value: '51101908', label: 'Artemether' },
  { value: '51151617', label: 'Biperiden' },
  { value: '51434502', label: 'Valsartan' },
  {
    value: '51131900',
    label: 'Blood plasma substitutes and extenders and expanders'
  },
  { value: '51131901', label: 'Poligeline or polygeline' },
  { value: '51202501', label: 'Leflunomide' },
  { value: '51171911', label: 'Sucralfate' },
  { value: '51302309', label: 'Isoconazole' },
  { value: '44103005', label: 'Fuser lamps or assemblies' },
  { value: '44111615', label: 'Deposit bags' },
  { value: '44101713', label: 'Copy counters' },
  { value: '44101714', label: 'Facsimile units for office machines' },
  { value: '44101715', label: 'Platen covers' },
  { value: '44101716', label: 'Hole punching units' },
  { value: '51183505', label: 'Metformin' },
  { value: '51283800', label: 'Streptogramins' },
  { value: '51282804', label: 'Secnidazole' },
  { value: '51282701', label: 'Teicoplanin' },
  { value: '51283602', label: 'Rifamycin' },
  { value: '14111611', label: 'Invitation or announcement cards' },
  { value: '82121908', label: 'Case making services' },
  { value: '51201900', label: 'Multiple sclerosis MS agents' },
  { value: '51201901', label: 'Glatiramer acetate' },
  { value: '41115829', label: 'Meat or dairy product analyzers' },
  { value: '41113323', label: 'Sugar analyzers' },
  { value: '44102503', label: 'Coin wrapper machines' },
  { value: '44102307', label: 'Collators' },
  { value: '47121903', label: 'Pressure or steam cleaner accessories' },
  { value: '44111616', label: 'Check separators' },
  {
    value: '23230000',
    label: 'Sawmilling and lumber processing machinery and equipment'
  },
  { value: '23231000', label: 'Log debarkers and accessories' },
  { value: '23231001', label: 'Tool holder' },
  { value: '12352100', label: 'Organic derivatives and substituted compounds' },
  { value: '12352400', label: 'Mixtures' },
  { value: '12164500', label: 'Food or drug additives' },
  { value: '41112704', label: 'Feed analyzers' },
  { value: '81112009', label: 'Content or data classification services' },
  { value: '25191514', label: 'Aircraft towbar' },
  {
    value: '43221600',
    label:
      'Digital subscriber loop DSL access equipment and components and accessories'
  },
  {
    value: '43221601',
    label:
      'Digital subscriber loop DSL captive office plain old telephone system POTS splitter'
  },
  {
    value: '43221602',
    label: 'Digital subscriber loop DSL captive office splitter shelf'
  },
  {
    value: '43221603',
    label:
      'Digital subscriber loop DSL customer premise equipment CPE plain old telephone system POTS splitter'
  },
  {
    value: '80111716',
    label: 'Permanent information technology staffing needs'
  },
  { value: '12131708', label: 'Detonator box' },
  { value: '41104020', label: 'Calcine element flow tray' },
  { value: '45111810', label: 'Visual presenters' },
  { value: '41115830', label: 'Glucose analyzers' },
  { value: '53102710', label: 'Corporate uniforms' },
  { value: '43233500', label: 'Information exchange software' },
  { value: '44111911', label: 'Interactive whiteboards or accessories' },
  { value: '84131507', label: 'Business interruption insurance' },
  { value: '84131508', label: 'Cash in transit insurance' },
  { value: '84131509', label: 'Comprehensive projects insurance' },
  { value: '84131510', label: 'Contractors all risks insurance' },
  { value: '84131511', label: 'Deterioration of stocks insurance' },
  { value: '84131512', label: 'Electronic equipment insurance' },
  { value: '84131513', label: 'Erection all risks insurance' },
  { value: '84131514', label: 'Fidelity guarantee insurance' },
  { value: '84131515', label: 'Jewelers block insurance' },
  { value: '84131516', label: 'Professional indemnity insurance' },
  { value: '84131517', label: 'Travel insurance' },
  { value: '14111613', label: 'Banner paper' },
  { value: '44101718', label: 'Infrared adapters' },
  { value: '44101719', label: 'Copy or scan accessories' },
  { value: '44101504', label: 'Digital senders' },
  { value: '44101720', label: 'Language fonts' },
  { value: '44101721', label: 'Media spindles' },
  { value: '44101722', label: 'Multi bin mailbox power upgrades' },
  { value: '44101723', label: 'Multi bin mailboxes' },
  { value: '44101724', label: 'Multi function upgrades' },
  { value: '44101725', label: 'Printer cabinets' },
  { value: '44101726', label: 'Printer emulation upgrades' },
  { value: '44101727', label: 'Printer stands' },
  { value: '44101728', label: 'Roll feeds' },
  { value: '44102202', label: 'Scanner document feeders' },
  { value: '44102203', label: 'Scanner transparency adapters' },
  { value: '46191608', label: 'Fire suppression system' },
  { value: '44101729', label: 'Output stackers' },
  { value: '44101505', label: 'Digital duplicators' },
  { value: '78181703', label: 'Vehicle parking service' },
  { value: '80121611', label: 'Healthcare claim law services' },
  { value: '80141510', label: 'Market research telephone surveys' },
  { value: '80141511', label: 'Market research paper surveys' },
  { value: '80141512', label: 'Market research on location surveys' },
  { value: '80141513', label: 'Market research one on one interviews' },
  { value: '46151507', label: 'Queuing control system' },
  { value: '56111514', label: 'Modular counter office packages' },
  { value: '80111507', label: 'Outplacement services' },
  { value: '80141514', label: 'Market research mail surveys' },
  { value: '46191505', label: 'Fire alarm systems' },
  { value: '83101808', label: 'Power quality monitoring' },
  { value: '81101703', label: 'Engineering testing services' },
  { value: '81101513', label: 'Building construction management' },
  { value: '78131603', label: 'Furniture storage' },
  { value: '80161602', label: 'Receiving or inventorying services' },
  { value: '53131637', label: 'Nursing pads' },
  { value: '42131706', label: 'Surgical jumpsuits and coveralls' },
  { value: '42141504', label: 'Medicated applicators or absorbents' },
  { value: '42192406', label: 'Urinal carrying carts' },
  { value: '81112010', label: 'Data conversion service' },
  { value: '83111511', label: 'Frame relay telecommunications service' },
  { value: '42312109', label: 'Ostomy bag rings' },
  { value: '42312110', label: 'Ostomy starter kits' },
  { value: '42312111', label: 'Ostomy bag covers' },
  { value: '42312112', label: 'Ostomy belts' },
  { value: '42312113', label: 'Ostomy bag plugs' },
  { value: '44101506', label: 'Faxswitch machines' },
  {
    value: '43223102',
    label: '2G GSM wireless access network equipment and components'
  },
  { value: '42311531', label: 'Dressing covers for general use' },
  {
    value: '42203406',
    label: 'Removal devices of diagnostic or interventional vascular catheters'
  },
  { value: '53112100', label: 'Overshoes' },
  { value: '42191811', label: 'Infant positioning cradles' },
  { value: '11122002', label: 'Particleboard' },
  { value: '43211804', label: 'Keyboard drawers or shelves' },
  { value: '41112803', label: 'Tachometer disks' },
  { value: '43211715', label: 'Portable data input terminals' },
  { value: '26111722', label: 'Battery adapter or accessories' },
  { value: '26111723', label: 'Battery cabinets or covers or doors' },
  { value: '26111724', label: 'Tool kits for batteries' },
  { value: '43201545', label: 'Fax boards' },
  { value: '43201546', label: 'Audio conferencing boards' },
  { value: '43201547', label: 'Voice boards' },
  { value: '43191619', label: 'Telephone signaling devices' },
  { value: '10122100', label: 'Miscellaneous animal food' },
  { value: '10151525', label: 'Broccoli seeds or seedlings' },
  { value: '10151526', label: 'Cabbage seeds or seedlings' },
  { value: '10152401', label: 'Potato seeds or seedlings' },
  { value: '10152403', label: 'Sweet potato seeds or seedlings' },
  { value: '10151529', label: 'Pumpkin seeds or seedlings' },
  { value: '10152405', label: 'Radish seeds or seedlings' },
  { value: '10151531', label: 'Brussel sprout seeds or seedlings' },
  { value: '10151532', label: 'Squash seeds or seedlings' },
  { value: '10151533', label: 'Okra seeds or seedlings' },
  { value: '10151534', label: 'Cantaloupe seeds or seedlings' },
  { value: '10151535', label: 'Peanut seeds or seedlings' },
  { value: '10191507', label: 'Bird repellents' },
  { value: '10191704', label: 'Fly swatters' },
  { value: '10191508', label: 'Termite shields' },
  { value: '10191705', label: 'Lariats' },
  { value: '10191706', label: 'Leghold traps' },
  { value: '11111611', label: 'Gravel' },
  { value: '11162122', label: 'Binding fabrics' },
  { value: '11162123', label: 'Tape fabrics' },
  { value: '11162124', label: 'Felt fabrics' },
  { value: '11162125', label: 'Webbing fabrics' },
  { value: '11162126', label: 'Quilted cloth' },
  { value: '11162127', label: 'Camouflage cloth' },
  { value: '11162128', label: 'Parachute cloth' },
  { value: '11162129', label: 'Marquisette cloth' },
  { value: '11162130', label: 'Dossal' },
  { value: '11162131', label: 'Welting fabrics' },
  { value: '14111534', label: 'Music score or manuscript papers' },
  { value: '14111535', label: 'Telegraph papers' },
  { value: '14111536', label: 'Library book or borrowers cards' },
  { value: '14111537', label: 'Label papers' },
  { value: '14111614', label: 'Album papers or tissues' },
  { value: '14111615', label: 'Poster papers' },
  { value: '14121505', label: 'Fiberboards' },
  { value: '14121811', label: 'Sensitized copying papers' },
  { value: '14121905', label: 'Tympan papers' },
  { value: '14122200', label: 'Specialty industrial use papers' },
  { value: '14122201', label: 'Seed germinating papers' },
  { value: '56101900', label: 'General furniture parts and accessories' },
  { value: '56101539', label: 'Bedframes or parts or accessories' },
  { value: '43191621', label: 'Handset adapters' },
  { value: '43202202', label: 'Telephone ring generators' },
  { value: '43222825', label: 'Telephone modification kits' },
  { value: '43202205', label: 'Keycaps or keytops or keys' },
  { value: '43222818', label: 'Telephone distributing terminal frame' },
  {
    value: '43222814',
    label: 'Telecommunication equipment installation or modification kits'
  },
  { value: '43222815', label: 'Telecommunication terminal units' },
  { value: '43222816', label: 'Telephony keyers' },
  { value: '43222817', label: 'Telecommunication repeaters' },
  { value: '43222813', label: 'Telephone switchboard part kits' },
  { value: '43222824', label: 'Aerial cable rollers' },
  { value: '43191628', label: 'Pay phone microphone windscreens' },
  { value: '43191622', label: 'Pager modules or accessories' },
  { value: '24111509', label: 'Water bags' },
  { value: '50221301', label: 'Vegetable flour' },
  { value: '55101527', label: 'Song Books' },
  { value: '55101528', label: 'Religious books' },
  { value: '55121907', label: 'Title boards' },
  {
    value: '55121806',
    label: 'Personnel identification band kits or accessories'
  },
  { value: '55121807', label: 'Identification product holders or accessories' },
  { value: '42121700', label: 'Veterinary clinical furniture' },
  {
    value: '42121506',
    label: 'Veterinary surgical instrument and supply sets'
  },
  {
    value: '42121507',
    label: 'Veterinary injection or suction units or accessories'
  },
  { value: '42241709', label: 'Post operative shoes' },
  { value: '42131510', label: 'Patient trousers' },
  {
    value: '42143100',
    label: 'Obstetrical and gynecological equipment and supplies'
  },
  {
    value: '42143101',
    label: 'Intrauterine catheters or catheterization kits'
  },
  { value: '42143102', label: 'Uterine devices' },
  { value: '42143103', label: 'Contraceptive devices for female users' },
  { value: '42143104', label: 'Amniocentesis kits' },
  { value: '42143105', label: 'Obstetrical extraction units' },
  { value: '42143106', label: 'Gynecology drainage kits' },
  {
    value: '42143200',
    label: 'Fertility and Sterility treatment equipment and supplies'
  },
  { value: '42143201', label: 'Semen preparation kits' },
  {
    value: '42144500',
    label: 'Impotence and sexual disorder devices and supplies'
  },
  { value: '42143300', label: 'Chemotherapy equipment and supplies' },
  { value: '42143301', label: 'Chemotherapy administration sets or kits' },
  { value: '42143400', label: 'Hyperhidrosis control equipment and supplies' },
  {
    value: '42143500',
    label: 'Ear nose and throat ENT treatment products and accessories'
  },
  { value: '42141808', label: 'Diathermy electrodes' },
  { value: '42141809', label: 'Muscle stimulator lead sets' },
  { value: '42141905', label: 'Enema soaps' },
  { value: '42142114', label: 'Therapeutic hot or cold therapy pants' },
  { value: '42293523', label: 'Medical or surgical suction sets or kits' },
  { value: '42293524', label: 'Cases for medical suction cannulae' },
  { value: '42142518', label: 'Biopsy aspirator products ' },
  { value: '42142519', label: 'Needle guides' },
  { value: '42142520', label: 'Dispensing pins or needles' },
  { value: '42142521', label: 'Blood collection needles' },
  { value: '42142525', label: 'Irrigation needles' },
  { value: '42142527', label: 'Needle cleaning wires' },
  { value: '42142528', label: 'Sternum puncture needles or sets' },
  { value: '42142529', label: 'Needle trays or holders' },
  { value: '42142530', label: 'Diagnostic procedure needles' },
  { value: '42142612', label: 'Irrigation syringe sets' },
  { value: '42142613', label: 'Injection guns' },
  { value: '42142614', label: 'Hypodermic injection apparatus' },
  { value: '42142615', label: 'Syringe accessories' },
  { value: '42142616', label: 'Blood collection syringes' },
  { value: '42142617', label: 'Fountain syringes' },
  { value: '42142618', label: 'Blood gas analysis syringe kits' },
  { value: '42142707', label: 'Urinary irrigation tubings' },
  { value: '42142708', label: 'Urological instrument adapters' },
  { value: '42142709', label: 'Nephrostomy sets' },
  { value: '42142710', label: 'Urinary drainage tubes' },
  { value: '42293605', label: 'Surgical sound sets' },
  { value: '42142712', label: 'Stone remover sets' },
  { value: '42142713', label: 'Urological sheaths or sets' },
  { value: '42142714', label: 'Urological percolators' },
  { value: '42142715', label: 'Urinary catheterization kits' },
  {
    value: '42152446',
    label: 'Dental cavities lining or thinner materials or sets'
  },
  { value: '42152465', label: 'Dental lubricants' },
  { value: '42152511', label: 'Dental dishes' },
  { value: '42152512', label: 'Dental tongs' },
  { value: '42152513', label: 'Dental measuring cups' },
  { value: '42161510', label: 'Peritoneal lavage kits' },
  { value: '42161633', label: 'Hemodialysis apparatus dialyzer membranes' },
  { value: '42161634', label: 'Hemodialysis procedure trays' },
  { value: '42161635', label: 'Hemodialysis apparatus cartridges' },
  { value: '42192422', label: 'Immobilization set carrying or storage cases' },
  { value: '42192420', label: 'First aid kit cases or bags' },
  { value: '42192421', label: 'Gas casualty treatment set cases' },
  { value: '42192418', label: 'Evacuation bags or liners' },
  { value: '42192415', label: 'Clothing cases' },
  {
    value: '42172014',
    label: 'Mobile medical services patient transport kits or supplies'
  },
  { value: '42172015', label: 'Mobile medical services dental treatment kits' },
  { value: '42172016', label: 'Mobile medical services fracture kits' },
  {
    value: '42172017',
    label:
      'Mobile medical services field medical or laboratory medical equipment kits or related products'
  },
  { value: '42172103', label: 'Mobile resuscitator or aspirator kits' },
  { value: '42183200', label: 'Allergy examining equipment and supplies' },
  { value: '42183201', label: 'Allergy detecting or testing instruments' },
  {
    value: '42143516',
    label: 'Ear nose throat ENT examining unit accessories and related products'
  },
  { value: '42181506', label: 'Sexual assault determination kits' },
  { value: '42181514', label: 'Hemoglobin photometers' },
  {
    value: '42181608',
    label: 'Blood pressure measuring instruments accessories'
  },
  { value: '42181609', label: 'Blood pressure monitor dome kits' },
  { value: '42181610', label: 'Blood pressure cuff kits' },
  { value: '42181804', label: 'Pulse oximeter probe or sensor accessories' },
  { value: '42143116', label: 'Intrauterine pressure monitoring catheters' },
  { value: '42181907', label: 'Basal metabolism apparatus' },
  { value: '42181908', label: 'Intracompartmental pressure monitoring sets' },
  { value: '42143118', label: 'Transcervical intrauterine kits' },
  { value: '42182015', label: 'Otoscope speculums' },
  { value: '42182017', label: 'Ear specula sets' },
  { value: '42296305', label: 'Bronchoscopes' },
  { value: '42182107', label: 'Stethoscope headsets' },
  { value: '42182209', label: 'Thermometer probes' },
  {
    value: '42182304',
    label: 'Psychological examination medical equipment sets'
  },
  { value: '42201853', label: 'Myelographic procedure sets' },
  { value: '42182306', label: 'Neurological discriminators' },
  { value: '42182307', label: 'Neurological pins' },
  { value: '42182308', label: 'Electroencephalograph EEGs' },
  { value: '42182311', label: 'Neurologic sensors' },
  { value: '42181541', label: 'Electromyograph electrodes or sets' },
  { value: '42182313', label: 'Neurological diagnostic sets' },
  { value: '42182808', label: 'Covers or liners for weighing scales' },
  {
    value: '42192600',
    label: 'Medication dispensing and measuring devices and supplies'
  },
  { value: '42192601', label: 'Suppository molds' },
  { value: '42192602', label: 'Medication or pill dispensers' },
  { value: '42192603', label: 'Medicine feeding cups or bottles' },
  { value: '42192604', label: 'Drug delivery systems' },
  { value: '42191502', label: 'Medicine trays or covers' },
  {
    value: '42171503',
    label: 'Patient holding or evacuation system heater units'
  },
  { value: '42191812', label: 'Infant incubator supply kits' },
  { value: '42191813', label: 'Patient care cot covers' },
  {
    value: '42191907',
    label: 'Medical or surgical instrument storage cabinets or chests'
  },
  { value: '42192211', label: 'Patient shifting boards' },
  { value: '42192212', label: 'Patient transfer mats or sheets' },
  { value: '42192305', label: 'Infant slings' },
  { value: '42201718', label: 'Medical ultrasound ophthalmic scanners' },
  {
    value: '42201818',
    label: 'Combination step wedges and spin tops for radiographic units'
  },
  { value: '42201819', label: 'Medical x ray film hangers' },
  { value: '42201820', label: 'Medical radiographic equipment grids' },
  { value: '42201821', label: 'Radiographic film cassette holders' },
  {
    value: '42201822',
    label: 'Medical x ray equipment cases or covers  in a medical setting'
  },
  { value: '42201823', label: 'Medical x ray tube and transfomer units' },
  { value: '42201824', label: 'Medical arthrography sets' },
  { value: '42201825', label: 'Medical x ray apparatus tube inserts' },
  { value: '42201826', label: 'Medical x ray apparatus repair kits' },
  { value: '42201827', label: 'Medical x ray darkroom tent repair kits' },
  { value: '42201828', label: 'Medical x ray apparatus filters' },
  { value: '42201829', label: 'Radiographic locators' },
  { value: '42201830', label: 'Medical x ray intensifying screens' },
  { value: '42201831', label: 'Medical x ray films or cassettes masks' },
  { value: '42201832', label: 'Radiographic film or cassette covers' },
  { value: '42201833', label: 'Radiographic film or cassette changers' },
  {
    value: '42201834',
    label: 'Medical radiographic x ray apparatus rectifier assemblies'
  },
  { value: '42201835', label: 'Medical x ray apparatus tube unit assemblies' },
  {
    value: '42201836',
    label: 'Medical x ray apparatus compression band assemblies'
  },
  { value: '42201837', label: 'Medical x ray water coolers' },
  {
    value: '42201838',
    label: 'Medical enteroclysis catheters or catheters kits'
  },
  {
    value: '42201904',
    label: 'Medical x ray film illuminator windows or screens'
  },
  { value: '42201905', label: 'Medical x ray film transfer cases' },
  { value: '42201906', label: 'Medical x ray film illuminator clips' },
  { value: '42201907', label: 'Medical x ray film stereoscopes' },
  { value: '42201908', label: 'Medical fluoroscopic screen hoods' },
  { value: '42203407', label: 'Angiography contrast medium delivery sets' },
  { value: '42203408', label: 'Cardiovascular sheath kits' },
  { value: '42203409', label: 'Angioscopic valvulotomes' },
  { value: '42203410', label: 'Cardiovascular catheter sheathes' },
  { value: '42192429', label: 'Catheter carts' },
  { value: '42203706', label: 'Medical x ray film processing chemical kits' },
  { value: '42203707', label: 'Medical x ray darkroom accessories' },
  { value: '42203708', label: 'Medical x ray film processing fixers' },
  {
    value: '42203709',
    label: 'Combination displays and printers for x ray system calibrator sets'
  },
  { value: '42203710', label: 'Medical x ray film markers' },
  { value: '42211712', label: 'Cases for hearing aids' },
  {
    value: '42221508',
    label: 'Intravenous or arterial catheter skin care kits or sets'
  },
  { value: '42221509', label: 'Intravenous or arterial catheter trays' },
  { value: '42221512', label: 'Intravenous catheters' },
  { value: '42203447', label: 'Cardiovascular catheterization kits or sets' },
  { value: '42221616', label: 'Intravenous or arterial tubing extension sets' },
  { value: '42221617', label: 'Needle resheathers' },
  { value: '42221705', label: 'Analgesia infusion vial assemblies' },
  {
    value: '42221706',
    label: 'Intravenous or arterial infusion transfer bags or container spouts'
  },
  { value: '42221707', label: 'Analgesic infusion sets or kits' },
  { value: '42221903', label: 'Ultrasonic blood flow detectors' },
  { value: '42222005', label: 'Intravenous pump parts or accessories' },
  {
    value: '42222006',
    label: 'Intravenous infusion pump analyzers or sensors'
  },
  { value: '42222007', label: 'Intravenous infusion pump transducers' },
  { value: '42222008', label: 'Infusion pump kits' },
  { value: '42294611', label: 'Autotransfusion waste collection systems' },
  { value: '42222307', label: 'Blood warming or transfusion systems' },
  {
    value: '42231900',
    label: 'Breast feeding equipment and accessories and supplies'
  },
  { value: '42231901', label: 'Breast pumps' },
  { value: '42231902', label: 'Breast shells or shields' },
  { value: '42231903', label: 'Breast pump kits' },
  {
    value: '42232000',
    label: 'Tablet crushers and cutters and related products'
  },
  { value: '42231510', label: 'Enteral feeding infusion pump tubing sets' },
  { value: '42142119', label: 'Hypothermia apparatus' },
  { value: '42241511', label: 'Traction splint sets' },
  { value: '42241512', label: 'Cast or splint bonding materials' },
  {
    value: '42241513',
    label:
      'Carrier and storage cases for splints or precut splints or splint systems'
  },
  { value: '42241514', label: 'Instrument rolls for splint sets' },
  { value: '42171922', label: 'Cases for splints' },
  { value: '42241606', label: 'Cast impression trays' },
  { value: '42241708', label: 'Femoral fracture pillows' },
  { value: '42251506', label: 'Therapeutic decorating boxes' },
  { value: '42251704', label: 'Gait bars for rehabilitation or therapy' },
  { value: '42262100', label: 'Mortuary equipment and supplies' },
  { value: '42192431', label: 'Cases for postmortem surgical instruments' },
  {
    value: '42261516',
    label: 'Instrument rolls for postmortem surgical instruments'
  },
  {
    value: '42272500',
    label: 'Anesthesia apparatus and accessories and supplies'
  },
  { value: '42272501', label: 'Gas anesthesia apparatus or machines' },
  {
    value: '42272502',
    label: 'Absorber units for gas anesthesia apparatus or machines'
  },
  { value: '42272503', label: 'Anesthesia inhalers or inhaler units' },
  {
    value: '42272505',
    label:
      'Gas anesthesia apparatus tubes or tubing assemblies or tube fittings'
  },
  { value: '42272506', label: 'Anesthesia apparatus screen filters' },
  { value: '42271505', label: 'Respiratory monitoring kits' },
  { value: '42271716', label: 'Cases for nasal insufflators' },
  { value: '42271717', label: 'Inhalators or sets' },
  { value: '42271718', label: 'Oxygen therapy delivery system products' },
  { value: '42271719', label: 'Oxygen insufflators' },
  { value: '42271720', label: 'Liquid oxygen converters' },
  { value: '42271721', label: 'Oxygen concentrator filters' },
  { value: '42271803', label: 'Transfer sets for respiratory therapy' },
  { value: '42272017', label: 'Intubator components' },
  { value: '42272223', label: 'Breathing apparatus accessories or supplies' },
  { value: '42272224', label: 'Ventilator circuit kits' },
  { value: '42144101', label: 'Pleural cavity drainage units or containers' },
  { value: '42281519', label: 'Needle sterilizers' },
  { value: '42281521', label: 'Sterilization sets' },
  { value: '42281808', label: 'Sterilization papers or sheets' },
  { value: '42281809', label: 'Sterilization record storage envelopes' },
  { value: '42281914', label: 'Sterilization disposable containers' },
  { value: '42295435', label: 'Ear protectors or shields' },
  { value: '42292907', label: 'Skin stretching systems' },
  { value: '42293006', label: 'Surgical measuring tapes' },
  { value: '42296335', label: 'Resectoscopes' },
  { value: '42296323', label: 'Laparoscopes or laparoscopic telescopes' },
  { value: '42296311', label: 'Cystoscopes or cystourethroscopes' },
  { value: '42294959', label: 'Endoscopic sphincterotomes' },
  { value: '42296318', label: 'Esophagoscopes or oesophagoscopes' },
  { value: '42294938', label: 'Sealing caps for endoscopes' },
  { value: '42294939', label: 'Endoscopic valves' },
  { value: '42296411', label: 'Endoscopic converters' },
  { value: '42294941', label: 'Endoscopic biliary drainage sets' },
  { value: '42294942', label: 'Endoscopic instrument seals' },
  { value: '42294943', label: 'Endoscopic valve units' },
  { value: '42294944', label: 'Endoscopic accessory kits' },
  { value: '42294946', label: 'Endoscopic gages' },
  { value: '42296412', label: 'Endoscopic diaphragms' },
  { value: '42294948', label: 'Endoscopic mouthpieces' },
  { value: '42294949', label: 'Endoscopic guidewire handles' },
  { value: '42294950', label: 'Endoscopic drills or drill bits' },
  { value: '42294951', label: 'Endoscopic small joint instrument sets' },
  { value: '42294952', label: 'Endoscopic retrievers or sets' },
  { value: '42294953', label: 'Endoscopic extractors' },
  {
    value: '42294954',
    label: 'Endoscopic tissue or specimen removing devices'
  },
  { value: '42294955', label: 'Endoscopic hooks' },
  { value: '42294956', label: 'Endoscopic guidewire tracers' },
  { value: '42295013', label: 'Endoscope tip protector or covers' },
  { value: '42295014', label: 'Endoscopic instrument cases' },
  { value: '42295015', label: 'Endoscopic lenses' },
  { value: '42312014', label: 'Anastomosis rings' },
  { value: '42295440', label: 'Endoscopic catheter adapters' },
  { value: '42296818', label: 'Surgical retriever sets' },
  { value: '42295446', label: 'Internal organ retainers' },
  { value: '42295448', label: 'Surgical splash guards' },
  { value: '42191711', label: 'Surgical compressed air tanks' },
  { value: '42295451', label: 'Surgical preparation mitts' },
  { value: '42295452', label: 'Skin preparation cups' },
  { value: '42295453', label: 'Surgical drains or drain sets' },
  { value: '42295454', label: 'Surgical hand protectors' },
  { value: '42296701', label: 'Cranial repair resin kits' },
  { value: '42295456', label: 'Surgical felt or fabric or patch or pledget' },
  {
    value: '42295458',
    label: 'Drying or powdering equipment for surgical gloves'
  },
  { value: '42312311', label: 'Wound disinfectant kits' },
  { value: '42311537', label: 'Bandage scissors or its supplies' },
  { value: '42312115', label: 'Ostomy irrigation sleeves' },
  { value: '42312309', label: 'Wound irrigation systems' },
  { value: '42312310', label: 'Wound cleansing bottles' },
  {
    value: '60106108',
    label: 'Safety or hazardous teaching aids or materials'
  },
  { value: '60106302', label: 'Forensic science teaching aids or materials' },
  { value: '60121606', label: 'Background screens' },
  { value: '60131307', label: 'Mandolins' },
  { value: '60131308', label: 'Violoncellos' },
  { value: '60131509', label: 'Mouthpieces' },
  { value: '60131512', label: 'Tuning bars' },
  {
    value: '22101900',
    label: 'Building construction machinery and accessories'
  },
  { value: '22101901', label: 'Concrete mixers or plants' },
  { value: '22102000', label: 'Building demolition machinery and equipment' },
  { value: '22101533', label: 'Treedozers' },
  { value: '22101534', label: 'Combat earthmovers' },
  { value: '14111817', label: 'Deposit verification form' },
  { value: '41113646', label: 'Temperature calibrator or simulator' },
  {
    value: '85122200',
    label: 'Individual health screening and assessment services'
  },
  { value: '85122201', label: 'Individual health assessment' },
  { value: '14111616', label: 'Lining papers' },
  { value: '51201628', label: 'Measles and mumps and rubella virus vaccine' },
  { value: '51201629', label: 'Diphtheria and tetanus toxoids absorbed' },
  {
    value: '51201631',
    label: 'Diptheria and tetanus toxoids and acellular pertussis vaccine'
  },
  {
    value: '51201632',
    label: 'Diptheria and tetanus and wholecell pertussis vaccine'
  },
  {
    value: '51201633',
    label:
      'Haemophilus influenzae b with diptheria and tetanus and acellular pertussis'
  },
  {
    value: '51201634',
    label:
      'Haemophilus influenzae B with diptheria and tetanus and wholecell pertussis conjugated vaccine'
  },
  { value: '41113647', label: 'Frequency calibrator or simulator' },
  { value: '43201410', label: 'Switch ports or cards' },
  { value: '43201549', label: 'Interface bus switches' },
  { value: '43202200', label: 'Sub assemblies for electronic devices' },
  { value: '43211508', label: 'Personal computers' },
  { value: '43211509', label: 'Tablet computers' },
  { value: '43211904', label: 'Plasma display panels PDP' },
  { value: '43211905', label: 'Organic light emitting displays' },
  { value: '43221524', label: 'Music on hold adapter' },
  { value: '43221708', label: 'Microwave access equipment' },
  { value: '43221710', label: 'Satellite core equipment' },
  { value: '43221714', label: 'Shortwave access equipment' },
  { value: '43221715', label: 'Shortwave antennas' },
  { value: '43221716', label: 'Pager core equipment' },
  { value: '43221717', label: 'Pager access equipment' },
  {
    value: '43223100',
    label: 'Digital mobile network infrastructure equipment and components'
  },
  {
    value: '43223101',
    label: '2G GSM mobile core network equipment and components'
  },
  {
    value: '43223103',
    label: '2,5G GPRS mobile core network equipment and components'
  },
  {
    value: '43223104',
    label: '2,5G GPRS wireless access network equipment and components'
  },
  {
    value: '43223105',
    label: '3G UMTS mobile core network equipment and components'
  },
  {
    value: '43223106',
    label: '3G UMTS wireless access network equipment and components'
  },
  {
    value: '43223107',
    label: 'WLAN mobile core network equipment and components'
  },
  {
    value: '43223108',
    label: 'WLAN wireless access network equipment and components'
  },
  { value: '43223109', label: 'IN SSP switching equipment' },
  { value: '43223110', label: 'IN mobile core equipment' },
  {
    value: '43223111',
    label: 'OSS mobile core network equipment and components'
  },
  {
    value: '43223112',
    label: 'OSS wireless access network equipment and components'
  },
  { value: '43223113', label: 'GSM UMT LAN antenna' },
  { value: '43223200', label: 'Mobile messaging platforms' },
  { value: '43223201', label: 'Voice messaging portal' },
  { value: '43223202', label: 'Short message service center' },
  { value: '43223203', label: 'Multimedia service center' },
  { value: '43223204', label: 'Unified messaging platform' },
  { value: '43223205', label: 'Instant messaging platform' },
  { value: '43223206', label: 'Wireless internet gateway' },
  { value: '43223207', label: 'Video streaming system' },
  { value: '43223208', label: 'Mobile or messaging game platform' },
  { value: '43223209', label: 'Location based messaging service platforms' },
  { value: '43223210', label: 'Micropayment messaging systems' },
  { value: '43232504', label: 'Route navigation software' },
  { value: '43233503', label: 'Network conferencing software' },
  { value: '43233504', label: 'Instant messaging software' },
  {
    value: '43233505',
    label: 'Ambient music or advertising messaging software'
  },
  { value: '43233507', label: 'Mobile operator specific standard software' },
  { value: '43233508', label: 'Mobile operator specific application software' },
  { value: '43233509', label: 'Mobile messaging service software' },
  { value: '43233510', label: 'Mobile internet services software' },
  { value: '43233511', label: 'Mobile location based services software' },
  { value: '42201839', label: 'Medical imaging procedure trays' },
  { value: '51101620', label: 'Iodoquinol' },
  { value: '51453601', label: 'Isobornyl thiocyanoacetate' },
  { value: '51452901', label: 'Malathion topical antiparasitic' },
  { value: '51452201', label: 'Methylparaben' },
  { value: '51453401', label: 'Permethrin topical antiparasitic' },
  { value: '51452501', label: 'Tetrachloroethylene' },
  { value: '51101629', label: 'Trimetrexate' },
  { value: '51303407', label: 'Terconazole' },
  { value: '51101911', label: 'Halofantrine' },
  { value: '51101912', label: 'Hydroxychloroquine' },
  { value: '51343502', label: 'Cidofovir' },
  { value: '51341903', label: 'Efavirenz' },
  { value: '51341501', label: 'Amprenavir' },
  { value: '51342307', label: 'Penciclovir' },
  { value: '51342402', label: 'Saquinavir' },
  { value: '51341901', label: 'Zanamivir' },
  { value: '51472802', label: 'Chlorhexidine gluconate' },
  { value: '51473013', label: 'Oxyquinoline' },
  { value: '51471502', label: 'Povidone' },
  { value: '51112902', label: 'Temozolomide' },
  { value: '51112001', label: 'Capecitabine' },
  { value: '51111617', label: 'Gemcitabine' },
  { value: '51111716', label: 'Rituximab' },
  { value: '51111717', label: 'Trastuzumab' },
  { value: '51123601', label: 'Amiodarone' },
  { value: '51191555', label: 'Isosorbide mononitrate' },
  { value: '51431801', label: 'Irbesartan' },
  { value: '51431902', label: 'Telmisartan' },
  { value: '51131507', label: 'Darbepoetin alfa' },
  { value: '51131615', label: 'Fondaparinux' },
  { value: '51412004', label: 'Cilostazol' },
  { value: '51131711', label: 'Tenecteplase' },
  { value: '51362601', label: 'Gabapentin' },
  { value: '51141518', label: 'Levetiracetam' },
  { value: '51141522', label: 'Oxcarbazepine' },
  { value: '51141526', label: 'Primidone' },
  { value: '51141528', label: 'Topiramate' },
  { value: '51141531', label: 'Valproic acid' },
  { value: '51292012', label: 'Fluoxetine hydrochloride' },
  { value: '51332703', label: 'Clozapine' },
  { value: '51401533', label: 'Lorazepam' },
  { value: '51381504', label: 'Mefenamic acid' },
  { value: '51384607', label: 'Celecoxib' },
  { value: '51385804', label: 'Meloxicam' },
  { value: '51372305', label: 'Fentanyl' },
  { value: '51142411', label: 'Rizatriptan' },
  { value: '51151512', label: 'Neostigmine' },
  { value: '51151514', label: 'Pyridostigmine' },
  { value: '51152005', label: 'Botulinum toxin' },
  { value: '51152006', label: 'Cisatracurium' },
  { value: '51313702', label: 'Bosentan' },
  { value: '51452101', label: 'Hexylresorcinol' },
  { value: '51171614', label: 'Bisacodyl' },
  { value: '51143104', label: 'Bismuth subsalicylate' },
  { value: '51171816', label: 'Ondansetron' },
  { value: '51171915', label: 'Pantoprazole' },
  { value: '51171916', label: 'Rabeprazole sodium' },
  { value: '51171917', label: 'Ranitidine' },
  { value: '51421501', label: 'Fluticasone' },
  { value: '51422417', label: 'Hydrocortisone butyrate' },
  { value: '51351612', label: 'Medroxyprogesterone acetate' },
  {
    value: '51181912',
    label: 'Follitropin or follitropin alfa and follitropin beta'
  },
  { value: '51191515', label: 'Hydrochlorothiazide' },
  { value: '51191517', label: 'Isosorbide' },
  { value: '51172327', label: 'Sodium lactate' },
  { value: '51202402', label: 'Basiliximab' },
  { value: '51333202', label: 'Imiquimod' },
  { value: '51202801', label: 'Pimecrolimus' },
  { value: '51202803', label: 'Sirolimus' },
  {
    value: '51172311',
    label: 'Activated carbon or activated charcoal for medical use'
  },
  { value: '51272401', label: 'Desflurane' },
  { value: '51272302', label: 'Etomidate' },
  { value: '51272901', label: 'Propofol' },
  { value: '51272408', label: 'Sevoflurane' },
  { value: '15111509', label: 'Methylacetylene propadiene MAPP gas' },
  { value: '41104124', label: 'Laboratory bone marrow biopsy trays' },
  { value: '41104928', label: 'Laboratory bottle receiver' },
  { value: '41104929', label: 'Laboratory filter papers' },
  { value: '41111739', label: 'Laboratory microscope replacement bulbs' },
  {
    value: '41111948',
    label: 'Manual or electronic hematology differential cell counters'
  },
  {
    value: '41112220',
    label: 'Laboratory freezer or refrigerator thermometers'
  },
  { value: '41112221', label: 'Laboratory incubator thermometers' },
  { value: '41121609', label: 'Variable volume pipette tips' },
  { value: '41121711', label: 'Urinalysis testing tubes or accessories' },
  { value: '41121814', label: 'Laboratory lids or covers or coverslips' },
  { value: '41122411', label: 'Laboratory timers or watches' },
  { value: '41122412', label: 'Laboratory tube sealants' },
  { value: '41122809', label: 'Petri dish racks' },
  { value: '41123305', label: 'Histology or tissue cassette cabinets' },
  { value: '42131509', label: 'Hospital robes' },
  { value: '42142716', label: 'Pessary devices' },
  { value: '42181805', label: 'Pulse oximeter unit accessories' },
  {
    value: '42191814',
    label: 'Clinical incubator or infant warmer accessories'
  },
  { value: '42201840', label: 'Vascular sealing devices' },
  { value: '42295911', label: 'Peripheral stents' },
  { value: '42221618', label: 'Intravenous or arterial procedure trays' },
  { value: '42241811', label: 'Hernia truss' },
  { value: '42251705', label: 'Parallel bars for rehabilitation or therapy' },
  {
    value: '42272225',
    label: 'Bi level positive airway pressure Bi PAP accessories'
  },
  { value: '42281810', label: 'Sterilization test packs' },
  { value: '42292908', label: 'Surgical purstring devices' },
  { value: '42295459', label: 'Fluid decanting devices for surgical use' },
  { value: '42295460', label: 'Protective caps for orthopedic implants' },
  { value: '42295461', label: 'Tissue glues or systems or applicators' },
  { value: '42301507', label: 'Training videos for medical staff education' },
  {
    value: '42301508',
    label: 'Operational or instructional videos for medical equipment'
  },
  { value: '42311539', label: 'Bandage applicators' },
  { value: '42312312', label: 'Wound care or cleansing trays' },
  { value: '42312313', label: 'Wound cleaning solutions' },
  { value: '43211512', label: 'Mainframe computers' },
  { value: '48101920', label: 'Straw dispensers' },
  { value: '43191510', label: 'Two way radios' },
  {
    value: '78111809',
    label: 'Vehicle leasing of sedans or coupes or station wagons'
  },
  { value: '43191508', label: 'Digital telephones' },
  { value: '43191509', label: 'Analog telephones' },
  { value: '83111605', label: 'Spacesegment leasing' },
  { value: '84131608', label: 'Medical Claims Review and Management' },
  { value: '43222503', label: 'Vulnerability Assessment Security Equipment' },
  { value: '44102108', label: 'Mail machine accessories' },
  { value: '51284115', label: 'Ceftibuten' },
  { value: '51282517', label: 'Cefradine or cephradine' },
  { value: '51282926', label: 'Levofloxacin' },
  { value: '51282921', label: 'Gatifloxacin' },
  { value: '51282953', label: 'Enoxacin' },
  { value: '51282909', label: 'Ciprofloxacin' },
  { value: '51283711', label: 'loracarbef' },
  { value: '51282932', label: 'Norfloxacin' },
  { value: '51283709', label: 'Cefprozil' },
  { value: '51283303', label: 'Linezolid' },
  { value: '51282514', label: 'Cephalexin' },
  { value: '51284119', label: 'Ceftriaxone' },
  { value: '51284108', label: 'Ceftazidime' },
  { value: '51282902', label: 'Alatrofloxacin' },
  { value: '51284502', label: 'Aztreonam' },
  { value: '51284012', label: 'Minocycline' },
  { value: '51284006', label: 'Doxycycline' },
  { value: '51284004', label: 'Demeclocycline' },
  { value: '51284002', label: 'Chlortetracycline' },
  { value: '51282411', label: 'Ticarcillin' },
  { value: '51282409', label: 'Piperacillin' },
  { value: '51282407', label: 'Mezlocillin' },
  { value: '51282404', label: 'Carbenicillin' },
  { value: '51281703', label: 'Ampicillin' },
  { value: '51283210', label: 'Troleandomycin' },
  { value: '51282308', label: 'Roxithromycin' },
  { value: '51282304', label: 'Erythromycin' },
  { value: '51282303', label: 'Dirithromycin' },
  { value: '51282301', label: 'Azithromycin' },
  { value: '51283712', label: 'Cefuroxime' },
  { value: '51285302', label: 'Furazolidone' },
  { value: '51284103', label: 'Cefdinir' },
  { value: '51282511', label: 'Cefazolin or cefazoline or cephazolin' },
  { value: '51283702', label: 'Cefamandole' },
  { value: '51282502', label: 'Cefadroxil' },
  { value: '51283701', label: 'Cefaclor' },
  { value: '51281626', label: 'Tobramycin' },
  { value: '51284104', label: 'Cefditoren' },
  { value: '51281607', label: 'Gentamicin' },
  { value: '51281614', label: 'Netilmicin' },
  { value: '51281627', label: 'Amikacin' },
  { value: '51281616', label: 'Kanamycin' },
  { value: '51282944', label: 'Sparfloxacin' },
  { value: '51282702', label: 'Vancomycin' },
  { value: '51282202', label: 'Cefoxitin' },
  { value: '51284109', label: 'Cefotaxime' },
  { value: '51282602', label: 'Cefepime' },
  { value: '51282201', label: 'Cefotetan' },
  { value: '51284121', label: 'Cefoperazone' },
  { value: '51286501', label: 'Mupirocin' },
  { value: '51283706', label: 'Cefonicid' },
  { value: '51284106', label: 'Cefixime' },
  { value: '51303002', label: 'Voriconazole' },
  { value: '51473602', label: 'Clioquinol' },
  { value: '51473104', label: 'Nitrofural or nitrofurazone' },
  { value: '51473502', label: 'Silver nitrate' },
  { value: '51472902', label: 'Iodoform' },
  { value: '51473302', label: 'Chloroxylenol' },
  { value: '51473203', label: 'Merbromin or mercurochrome' },
  { value: '51351609', label: 'Fulvestrant' },
  { value: '51111820', label: 'Letrozole' },
  { value: '51263139', label: 'Metoprolol' },
  { value: '51322301', label: 'Cholestyramine or colestyramine' },
  { value: '51321701', label: 'Atorvastatin' },
  { value: '51322201', label: 'Ezetimibe' },
  { value: '51413504', label: 'Nimodipine' },
  { value: '51413301', label: 'Alprostadil' },
  { value: '51131911', label: 'Dextran' },
  { value: '51131908', label: 'Hetastarch' },
  { value: '51131909', label: 'Albumin human' },
  { value: '51132000', label: 'Hemorrheologic agents' },
  { value: '51202401', label: 'Adalimumab' },
  { value: '51142413', label: 'Zolmitriptan' },
  { value: '51144014', label: 'Methylphenidate' },
  { value: '51161563', label: 'Zafirlukast' },
  { value: '51161708', label: 'Desoxyribonuclease or dornase alfa' },
  { value: '51161709', label: 'Poractant alfa' },
  { value: '51171628', label: 'Potassium phosphate' },
  {
    value: '51181608',
    label: 'Levothyroxine or thyroxine or triiodothyronine'
  },
  { value: '51422202', label: 'Fluorometholone' },
  { value: '51182014', label: 'Dutasteride' },
  { value: '51182415', label: 'Zoledronic acid' },
  { value: '51182417', label: 'Risedronate sodium or risedronic acid' },
  { value: '51201646', label: 'Measles and rubella virus vaccine' },
  { value: '51201648', label: 'Rubella and mumps virus vaccine' },
  { value: '51201802', label: 'Filgrastim' },
  { value: '51201803', label: 'Pegfilgrastim' },
  { value: '51201805', label: 'Rho D immunoglobulins' },
  { value: '51201806', label: 'Gamma IGG or immunoglobulin' },
  { value: '51172308', label: 'Methylene blue or methylthioninium chloride' },
  { value: '51172310', label: 'Folinic acid or leucovorin' },
  { value: '52161543', label: 'MP3 players or recorders' },
  { value: '54111709', label: 'Watch or clock repair kits' },
  { value: '72101506', label: 'Elevator maintenance services' },
  { value: '80161508', label: 'Document destruction services' },
  { value: '84121806', label: 'Securities Custodial Services' },
  { value: '15111510', label: 'Liquified petroleum gas' },
  { value: '24102109', label: 'Sack holders' },
  { value: '26111725', label: 'Nickel sodium chloride batteries' },
  { value: '41102608', label: 'Animal testing equipment' },
  {
    value: '41116015',
    label: 'Flow cytometry analyzers reagents or antibodies'
  },
  { value: '41116148', label: 'Flow cytometry test kits or supplies' },
  {
    value: '42131707',
    label: 'Surgical isolation suits or helmets or shields or accessories'
  },
  {
    value: '42142531',
    label: 'Needle or blade or sharps disposal containers or carts'
  },
  { value: '42142532', label: 'Pericardiocentesis needles or kits' },
  { value: '42143600', label: 'Restraints and accessories' },
  { value: '42143601', label: 'Restraint vests and jackets' },
  { value: '42143602', label: 'Torso and belt restraints' },
  { value: '42143603', label: 'Extremity restraints' },
  { value: '42143604', label: 'Non EMS head restraints' },
  { value: '42143605', label: 'Restraint straps or buckles or supplies' },
  { value: '42143606', label: 'Full body restraints' },
  { value: '42143607', label: 'Patient motion sensors or alarms' },
  {
    value: '42143608',
    label: 'Patient stabilization or fall prevention devices'
  },
  { value: '42201841', label: 'Medical diagnostic x ray papers' },
  { value: '42211508', label: 'Multifunctional mobility devices' },
  { value: '42251706', label: 'Gait training walkers or bikers or exercisers' },
  { value: '42272507', label: 'Temperature control for anesthesia apparatus' },
  { value: '42281915', label: 'Sterilization reels' },
  { value: '42281916', label: 'Sterilization bags' },
  { value: '42295462', label: 'Urodynamic catheters or accessories' },
  { value: '42142733', label: 'Ureteral catheter or catheter set' },
  { value: '42312500', label: 'Wound supports and supplies and accessories' },
  { value: '42312501', label: 'Mammary support binders' },
  { value: '42312502', label: 'Abdominal binders' },
  { value: '42312503', label: 'Scrotal supports' },
  { value: '43191630', label: 'Mobile phone starter kits' },
  { value: '43211609', label: 'Universal serial bus hubs or connectors' },
  { value: '44103600', label: 'Cassette disposal equipment and accessories' },
  { value: '44103601', label: 'Cassette or tape shredders' },
  { value: '46191609', label: 'Fire escape equipment' },
  { value: '50193200', label: 'Prepared salads' },
  { value: '51473503', label: 'Carbamide peroxide or hydrogen peroxide' },
  { value: '51303104', label: 'Thymol iodide' },
  { value: '51472302', label: 'Sodium perborate' },
  { value: '51131808', label: 'Aminocaproic acid' },
  { value: '51401505', label: 'Alprazolam' },
  { value: '51401518', label: 'Diazepam' },
  { value: '51151616', label: 'Atropine' },
  { value: '51294001', label: 'Aprepitant' },
  { value: '51441600', label: 'Diagnostic agents and radiopharmaceuticals' },
  { value: '51241100', label: 'Ophthalmic agents' },
  { value: '51241103', label: 'Bimatoprost' },
  { value: '51241110', label: 'Latanoprost' },
  { value: '51241200', label: 'Dermatologic agents' },
  { value: '51241205', label: 'Calamine' },
  { value: '51241209', label: 'Hydroquinone' },
  { value: '51385608', label: 'Methyl salicylate' },
  { value: '51241215', label: 'Podophyllum resin' },
  { value: '51191923', label: 'Tretinoin' },
  { value: '51241227', label: 'Topical turpentine oil preparations' },
  { value: '53102712', label: 'Paramedic uniforms' },
  {
    value: '72103301',
    label: 'Parking lot or road maintenance or repairs or services'
  },
  { value: '72103304', label: 'Parking lot or road sweeping services' },
  { value: '72102905', label: 'Exterior grounds maintenance' },
  { value: '80111508', label: 'Service recognition programs' },
  { value: '80111616', label: 'Temporary customer service personnel' },
  { value: '80111617', label: 'Temporary architectural services' },
  { value: '80111618', label: 'Temporary construction services' },
  { value: '80111619', label: 'Temporary creative services' },
  {
    value: '80161603',
    label: 'Furniture project administration or management'
  },
  { value: '82101900', label: 'Media placement and fulfillment' },
  { value: '82101901', label: 'Radio placement' },
  { value: '82101902', label: 'Television placement' },
  { value: '82101903', label: 'Internet placement' },
  { value: '82101904', label: 'Cinema placement' },
  { value: '82101905', label: 'Print placement' },
  { value: '84131609', label: 'Employee assistance programs' },
  { value: '84131610', label: 'Flexible spending accounts FSA' },
  { value: '90111604', label: 'Marquees' },
  { value: '11170000', label: 'Alloys' },
  { value: '11171500', label: 'Basic steels' },
  { value: '11171501', label: 'E24-2 or A37-2 steel' },
  { value: '11171600', label: 'Stainless steel alloys' },
  { value: '11171601', label: 'Stainless steel alloy 304' },
  { value: '11171602', label: 'Stainless steel alloy 304l' },
  { value: '11171603', label: 'Stainless steel alloy 316' },
  { value: '11171700', label: 'High speed steels' },
  { value: '11171701', label: 'Z90WDCV6542 or M2 high speed steel' },
  { value: '11171702', label: 'Z90WDKCV65542 or M35 high speed steel' },
  { value: '11171800', label: 'Nickel based super alloys' },
  { value: '11171801', label: 'Inconel 600 super alloy' },
  { value: '11171900', label: 'Titanium based super alloys' },
  { value: '11171901', label: 'TA6V super alloy' },
  { value: '11172000', label: 'Aluminum based alloys' },
  { value: '11172001', label: 'Aluminum alloy 7178' },
  { value: '11172100', label: 'Cobalt based super alloys' },
  { value: '11172101', label: 'Pygmalion or 846 alloy' },
  { value: '11180000', label: 'Metal oxide' },
  { value: '11190000', label: 'Metal waste scrap and by products' },
  { value: '40101707', label: 'Cooling tower accessories' },
  { value: '41103210', label: 'Laboratory ultraviolet UV sterilizers' },
  { value: '41105339', label: 'Blotting membranes' },
  { value: '43201553', label: 'Transceivers and media converters' },
  { value: '45121520', label: 'Web cameras' },
  { value: '46191610', label: 'Fire sprinkler heads' },
  { value: '51171822', label: 'Palonosetron' },
  { value: '76111505', label: 'Fabric and furniture cleaning services' },
  { value: '14111818', label: 'Thermal paper' },
  { value: '23201203', label: 'Fluidbed dryers' },
  { value: '39112100', label: 'Optical lighting' },
  { value: '39121800', label: 'Intelligent Building Installations IBI' },
  { value: '39121801', label: 'Controlling units or devices' },
  { value: '41106516', label: 'Enzyme expression consumables' },
  { value: '43191511', label: 'IP phones' },
  {
    value: '43191512',
    label: 'Digital enhanced cordless telecommunications DECT cordless phones'
  },
  {
    value: '43191631',
    label: 'Phone or modem jack adapters or country kits or travel kits'
  },
  { value: '43201554', label: 'Personal computer television PC TV tuners' },
  { value: '43201555', label: 'PC radio tuners' },
  {
    value: '43201556',
    label: 'Small computer system interconnect SCSI adapters'
  },
  {
    value: '43201557',
    label: 'Redundant array of independent disks RAID controllers'
  },
  { value: '43201558', label: 'Fibre channel controller' },
  { value: '43202006', label: 'Magneto Optical MO disks' },
  { value: '43202007', label: 'High capacity removable media blank disks' },
  { value: '43211610', label: 'Computer country or localization kits' },
  { value: '43211611', label: 'Handheld PDA Starter Kits' },
  { value: '43211906', label: 'Character displays' },
  { value: '43211907', label: 'Head mounted displays' },
  { value: '43222633', label: 'Remote management adapters' },
  { value: '43222634', label: 'Network management or monitoring device' },
  { value: '43232505', label: 'Multi-media educational software' },
  { value: '43232506', label: 'Encyclopedia software' },
  { value: '44103123', label: 'Plotter pens' },
  { value: '44103124', label: 'Thermal ribbon' },
  { value: '56112200', label: 'Desking systems' },
  { value: '76111506', label: 'Interior plant landscaping services' },
  {
    value: '43201816',
    label: 'Ultra density optical UDO drives or autoloaders or libraries'
  },
  { value: '43202008', label: 'Ultra density optical UDO blank disks' },
  { value: '24111510', label: 'Rope bags and rope packs' },
  { value: '24112007', label: 'Material handling racks' },
  { value: '39121803', label: 'Building environmental control system' },
  { value: '95131603', label: 'Site office' },
  { value: '39112200', label: 'Special effects devices' },
  { value: '39112300', label: 'Stage and studio lighting and accessories' },
  {
    value: '39112400',
    label: 'Stage and studio lighting dimming and control devices'
  },
  { value: '39112500', label: 'Stage and studio luminaires' },
  {
    value: '39121621',
    label: 'Lightning protection apparatus and accessories'
  },
  { value: '41111518', label: 'Axle load scales' },
  { value: '41116206', label: 'Diagnostic beverages for laboratory testing' },
  {
    value: '42142533',
    label: 'Needle for intravenous or arterial administration ports'
  },
  { value: '42143609', label: 'Patient restraint boards' },
  { value: '42143700', label: 'Phototherapy systems and accessories' },
  { value: '42143701', label: 'Phototherapy air circulators' },
  { value: '42143702', label: 'Phototherapy blankets' },
  { value: '42143703', label: 'Phototherapy bulbs' },
  { value: '42143704', label: 'Phototherapy light mats' },
  { value: '42143705', label: 'Phototherapy patient protection devices' },
  { value: '42143706', label: 'Phototherapy power units' },
  { value: '42143707', label: 'Phototherapy warmer beds' },
  {
    value: '42143800',
    label:
      'Urological pressure measurement instruments and supplies and equipment'
  },
  { value: '42143801', label: 'Cystometry systems' },
  { value: '42143802', label: 'Cystometry transducers' },
  { value: '42143803', label: 'Urodynamic catheters' },
  { value: '42191908', label: 'Medical chart caddies' },
  { value: '42204009', label: 'Medical radiological shielding eyewears' },
  { value: '42272509', label: 'Anesthesia machine calibrators' },
  { value: '42272510', label: 'Anesthesia machine upgrade kits' },
  { value: '43201617', label: 'Card cages' },
  { value: '43211612', label: 'Computer accessory kits' },
  { value: '43211613', label: 'Computer or notebook stands' },
  { value: '43231514', label: 'Sales and marketing software' },
  { value: '43232204', label: 'Embedded text entry software' },
  { value: '43232205', label: 'Fonts software' },
  { value: '43232507', label: 'Dictionary software' },
  { value: '43232508', label: 'Phonebook software' },
  { value: '43232916', label: 'Infrared data transfer irda software' },
  { value: '43233416', label: 'Codec stacks' },
  { value: '43233417', label: 'Handwriting recognition software components' },
  { value: '43233418', label: 'Memory drivers' },
  { value: '43233419', label: 'Multimedia stacks' },
  { value: '43233420', label: 'Text to speech conversion software' },
  { value: '43233421', label: 'Video drivers' },
  { value: '43233512', label: 'Ring tone software' },
  { value: '45101516', label: 'Pad printing cliché' },
  { value: '45121521', label: 'Inspection camera' },
  { value: '46182307', label: 'Evacuation harnesses' },
  { value: '46182309', label: 'Safety rope ladders and wire rope ladders' },
  { value: '46191506', label: 'Flame arrestor' },
  { value: '48101618', label: 'Commercial use dishwashing machine parts' },
  { value: '48102008', label: 'Condiment counters' },
  { value: '50301500', label: 'Apples' },
  { value: '55121900', label: 'Signage equipment' },
  { value: '55121901', label: 'Advertising pillars' },
  { value: '55121902', label: 'Barker stands or stalls' },
  { value: '56101542', label: 'Folding chairs' },
  { value: '56130000', label: 'Merchandising furniture and accessories' },
  { value: '73152105', label: 'Test equipment refurbishment' },
  { value: '73152103', label: 'Engineering equipment maintenance services' },
  { value: '78181505', label: 'Vehicle inspection service' },
  { value: '80111620', label: 'Temporary human resources services' },
  { value: '80111621', label: 'Temporary research and development services' },
  {
    value: '80111622',
    label: 'Temporary safety health environmental services'
  },
  { value: '80111623', label: 'Temporary sourcing and logistics services' },
  { value: '81102400', label: 'Electrical power transmission engineering' },
  { value: '81102401', label: 'High voltage overhead line construction' },
  {
    value: '81102402',
    label: 'High voltage overhead line maintenance and repair'
  },
  { value: '81111706', label: 'Network planning services' },
  { value: '81111707', label: 'Systems planning services' },
  { value: '81111708', label: 'Telecommunications planning services' },
  { value: '81111819', label: 'Quality assurance services' },
  { value: '81111820', label: 'System usability services' },
  { value: '42294957', label: 'Endoscopic shaver blades' },
  { value: '71141103', label: 'Well abandonment platform removal services' },
  { value: '71161600', label: 'Other oilfield support services' },
  { value: '71161605', label: 'Oilfield consultancy services' },
  { value: '23153600', label: 'Part marking machines' },
  { value: '23153700', label: 'Precision fastening or torque equipment' },
  { value: '23161700', label: 'Foundry dies and tooling' },
  { value: '23240000', label: 'Metal cutting machinery and accessories' },
  { value: '23250000', label: 'Metal forming machinery and accessories' },
  { value: '23260000', label: 'Rapid prototyping machinery and accessories' },
  {
    value: '23270000',
    label:
      'Welding and soldering and brazing machinery and accessories and supplies'
  },
  { value: '23271500', label: 'Brazing machinery' },
  { value: '23281500', label: 'Coating or plating machines' },
  { value: '23251700', label: 'Forging machines' },
  { value: '23242200', label: 'Gear manufacturing machines' },
  { value: '23242300', label: 'Lathes and turning centers' },
  { value: '23242400', label: 'Machining centers' },
  { value: '23251500', label: 'Metal bending machines' },
  { value: '23241900', label: 'Metal boring machines' },
  { value: '23242100', label: 'Metal cutting machine attachments' },
  { value: '23241500', label: 'Metal cutting machines' },
  { value: '23241600', label: 'Metal cutting tools' },
  { value: '23241700', label: 'Metal deburring machines' },
  { value: '23241800', label: 'Metal drilling machines' },
  { value: '23251800', label: 'Metal forming dies and tooling' },
  { value: '23242500', label: 'Metal milling machines' },
  { value: '23251600', label: 'Metal rolling machines' },
  { value: '23261500', label: 'Rapid prototyping machines' },
  { value: '23271600', label: 'Soldering machines' },
  {
    value: '23242600',
    label: 'Specialized or miscellaneous metal cutting machines'
  },
  { value: '23271700', label: 'Welding and soldering and brazing accessories' },
  { value: '23271800', label: 'Welding and soldering and brazing supplies' },
  { value: '23261501', label: 'Fused deposition modeling machine' },
  { value: '23251501', label: 'Hydraulic press brake' },
  { value: '23271603', label: 'Soldering iron' },
  { value: '24102300', label: 'Automatic guided vehicles AGV' },
  { value: '26111726', label: 'Fluorescent ballast battery unit' },
  { value: '95131604', label: 'Portable kitchen unit' },
  { value: '30240000', label: 'Portable Structure Building Components' },
  { value: '95130000', label: 'Portable buildings and structures' },
  { value: '95131500', label: 'Grandstands and bleachers' },
  {
    value: '95131600',
    label: 'Portable commercial and industrial buildings and structures'
  },
  { value: '30241500', label: 'Portable Structure Consolidating Components' },
  {
    value: '30241600',
    label: 'Grandstand bleacher and stair structural components'
  },
  {
    value: '30241700',
    label: 'Tent and membrane structure framing and covering components'
  },
  { value: '95131700', label: 'Tents and membrane structures' },
  { value: '30241502', label: 'Anchor plate' },
  { value: '30241503', label: 'Bail ring' },
  { value: '30241504', label: 'Base plate' },
  { value: '30241505', label: 'Cupola' },
  { value: '30241701', label: 'Framing beam or rail or tubing' },
  { value: '30241506', label: 'Funicular bell' },
  { value: '30241507', label: 'Gable' },
  { value: '30241508', label: 'Gin pole and accessories' },
  { value: '30241601', label: 'Grandstand footboard and accessories' },
  { value: '30241509', label: 'Mast section and king pole' },
  { value: '95131601', label: 'Portable box office' },
  { value: '30241702', label: 'Portable structure canvas section' },
  { value: '30241510', label: 'Side pole' },
  { value: '30241604', label: 'Stair riser' },
  { value: '30241511', label: 'Stake or peg' },
  { value: '30241602', label: 'Stair step' },
  { value: '30241603', label: 'Stringer' },
  { value: '30241512', label: 'Transom' },
  { value: '30241501', label: 'Tube frame connector' },
  {
    value: '32150000',
    label: 'Automation control devices and components and accessories'
  },
  { value: '39101900', label: 'Lamp Ballasts and Lamp Transformers' },
  { value: '39112600', label: 'Non electrical lighting devices' },
  { value: '39121900', label: 'Electrical safety devices and accessories' },
  { value: '39122000', label: 'Electrical Variable Speed Drives' },
  { value: '39122001', label: 'Inverter drive AC' },
  { value: '39121901', label: 'Circuit breaker lockout' },
  {
    value: '39130000',
    label: 'Electrical wire management devices and accessories and supplies'
  },
  { value: '39131500', label: 'Wire Markers and Wire Marking Devices' },
  { value: '39131600', label: 'Wire protection devices' },
  { value: '39131700', label: 'Wire Raceways Conduit and Busways' },
  { value: '42131511', label: 'Hydrotherapy garments' },
  { value: '42181611', label: 'Central venous pressure CVP manometers' },
  { value: '42182705', label: 'Body composition analyzers' },
  { value: '42192003', label: 'Physical therapy massage tables' },
  { value: '42203413', label: 'Vascular coils' },
  { value: '42203414', label: 'Vascular filters' },
  { value: '42203606', label: 'Teleradiography systems' },
  {
    value: '42295600',
    label: 'Cerebral spinal fluid CSF drainage products and accessories'
  },
  { value: '42320000', label: 'Orthopedic surgical implants' },
  { value: '43201618', label: 'Computer rack component' },
  { value: '43201817', label: 'Compact disc CD drive' },
  { value: '43201818', label: 'Digital video disc DVD drive' },
  { value: '43211722', label: 'Business card scanner' },
  {
    value: '43223300',
    label: 'Datacom and network connectivity installation devices and equipment'
  },
  { value: '43223301', label: 'Datacom cross connect system and accessories' },
  { value: '43223302', label: 'Datacom label' },
  { value: '43223303', label: 'Datacom patch cord' },
  { value: '43223305', label: 'Network cable management panel assembly' },
  { value: '43223306', label: 'Network system cabinet or enclosure' },
  { value: '43223307', label: 'Network system cabling box' },
  { value: '43223308', label: 'Network system equipment rack' },
  { value: '43223309', label: 'Patch panel' },
  { value: '43233005', label: 'Computer firmware' },
  { value: '43233600', label: 'Electrical Equipment software' },
  { value: '43233601', label: 'Motor Drive Software' },
  { value: '43233602', label: 'Power Monitor Software' },
  { value: '43233603', label: 'Programmable Logic Control Software' },
  { value: '47121709', label: 'Hazardous waste container' },
  { value: '50111513', label: 'Beef, minimally processed without additions' },
  {
    value: '56112300',
    label: 'Auditorium or stadium or special use seating parts and accessories'
  },
  { value: '72151005', label: 'Boiler pressure controller installation' },
  {
    value: '72151006',
    label: 'Boiler pressure controller maintenance or repair or operation'
  },
  { value: '80111624', label: 'Temporary Travel Staffing' },
  { value: '81161700', label: 'Telecommunication Services' },
  { value: '81161500', label: 'Access management services' },
  { value: '81161600', label: 'Electronic mail and messaging services' },
  { value: '81161701', label: 'Fax Administration Service' },
  { value: '81161702', label: 'Fax Support Service' },
  { value: '81161501', label: 'Software application administration service' },
  { value: '81161601', label: 'Instant Messaging Administration Service' },
  { value: '81161703', label: 'Mobile Telephone Administration Service' },
  { value: '81161704', label: 'Mobile Telephone Support Service' },
  { value: '81161502', label: 'Network Account Administration Service' },
  { value: '81161503', label: 'Network folder administration service' },
  { value: '81161705', label: 'Pager Administration Service' },
  { value: '81161706', label: 'Pager Support Service' },
  { value: '81161707', label: 'Telephone Administration Service' },
  { value: '81161708', label: 'Telephone Support Service' },
  { value: '81161709', label: 'Voice Mail Administration Service' },
  { value: '81161710', label: 'Voice Mail Support Service' },
  {
    value: '85160000',
    label:
      'Medical Surgical Equipment Maintenance Refurbishment and Repair Services'
  },
  { value: '85161500', label: 'Medical or surgical equipment repair' },
  {
    value: '85161501',
    label: 'Medical capital equipment maintenance or repair'
  },
  { value: '85161502', label: 'Medical minor equipment maintenance or repair' },
  {
    value: '85161503',
    label: 'Medical or surgical instrument maintenance or repair'
  },
  { value: '23280000', label: 'Metal treatment machinery' },
  { value: '81160000', label: 'Information Technology Service Delivery' },
  { value: '23271400', label: 'Welding machinery' },
  { value: '23241400', label: 'Metal grinding machines' },
  { value: '23271401', label: 'Submerged arc welding machine' },
  { value: '23241401', label: 'Bench grinder' },
  { value: '24112701', label: 'Wood pallet' },
  { value: '24112702', label: 'Plastic pallet' },
  { value: '24112800', label: 'Freight containers' },
  { value: '24112801', label: 'Dry freight container' },
  { value: '24112802', label: 'Open top freight container' },
  { value: '24112803', label: 'Platform freight container' },
  { value: '27111913', label: 'Edge trimmer' },
  { value: '30162000', label: 'Interior laminates' },
  { value: '30241513', label: 'Steel A-frame' },
  { value: '30241514', label: 'Structural brace' },
  { value: '30241515', label: 'Structural alignment tool' },
  { value: '30181802', label: 'Faucet aerator' },
  { value: '42142534', label: 'Intraosseous needles' },
  { value: '42143900', label: 'Fecal incontinence products' },
  { value: '42143901', label: 'Fecal incontinence collection bags' },
  { value: '42143902', label: 'Fecal management systems or kits' },
  { value: '42295464', label: 'Organ preservation solutions' },
  { value: '43191513', label: 'Video phone' },
  { value: '43191632', label: 'Phone antenna' },
  { value: '43211513', label: 'Ultra mobile personal computer' },
  { value: '43222635', label: 'Network equipment upgrade kit' },
  { value: '43222636', label: 'Network application engine' },
  { value: '44121513', label: 'Postage stamp' },
  { value: '44121636', label: 'Electric pencil sharpener' },
  { value: '45121707', label: 'Photo print dryer' },
  { value: '45121708', label: 'Photo print washer' },
  { value: '45121709', label: 'Automated film processor' },
  { value: '45121710', label: 'Sheet film drum' },
  { value: '45141503', label: 'Photo development processing kit' },
  { value: '45141504', label: 'Photo enhancing and correcting solution' },
  { value: '46151608', label: 'Bomb protection devices and supplies' },
  { value: '48102108', label: 'Aluminum food wrapping foil' },
  { value: '81161711', label: 'Videoconferencing service' },
  { value: '11111811', label: 'Haydite' },
  { value: '23291500', label: 'Industrial drilling tools' },
  { value: '23290000', label: 'Industrial machine tools' },
  { value: '24112412', label: 'Equipment transportation case' },
  { value: '27121504', label: 'Industrial hydraulic press' },
  { value: '30172100', label: 'Garage doors and operators' },
  { value: '30191700', label: 'Construction sheds and trailers' },
  { value: '30121700', label: 'Road and railroad construction materials' },
  { value: '30162100', label: 'Stairs and stairways' },
  { value: '30121504', label: 'Bitumen' },
  { value: '30131505', label: 'Haydite block' },
  { value: '30131506', label: 'Natural rock slab' },
  { value: '30131507', label: 'Light concrete block' },
  { value: '30161803', label: 'Domestic cupboard' },
  { value: '30171515', label: 'Inspection door' },
  { value: '30111505', label: 'Ready mix concrete' },
  { value: '30131508', label: 'Glass block' },
  { value: '30131605', label: 'Sandlime brick' },
  { value: '30151511', label: 'Concrete roofing tile' },
  { value: '30151512', label: 'Brick roofing tile' },
  { value: '30171907', label: 'Window sill' },
  { value: '30121701', label: 'Geomesh' },
  { value: '30121702', label: 'Geotextile' },
  { value: '30121703', label: 'Bridge rail' },
  { value: '30121704', label: 'Concrete slab' },
  { value: '30121705', label: 'Paving slab' },
  { value: '30121706', label: 'Concrete curb' },
  { value: '30121707', label: 'Noise protection board' },
  { value: '30121708', label: 'Brick tile' },
  { value: '30121709', label: 'Ungraded crushed rock' },
  { value: '30121710', label: 'Paving stone' },
  { value: '30121711', label: 'Natural curbstone' },
  { value: '40102100', label: 'Fireplaces and accessories' },
  { value: '41111949', label: 'Hour meter' },
  { value: '41111810', label: 'Hot testing equipment station' },
  { value: '41111811', label: 'Cold testing equipment station' },
  { value: '41114207', label: 'Total station' },
  { value: '42203416', label: 'Cardiac ablation catheters' },
  {
    value: '46151800',
    label: 'Explosives control equipment and accessories and supplies'
  },
  {
    value: '46151900',
    label: 'Chemical biological control equipment and accessories and supplies'
  },
  { value: '46151801', label: 'Blast guard container' },
  { value: '46151901', label: 'Chemical biological decontamination trailer' },
  { value: '47121814', label: 'Dust separator' },
  { value: '52152300', label: 'Domestic kitchen supplies' },
  { value: '76122000', label: 'Landfill services' },
  { value: '76122100', label: 'Waste to fuel blending services' },
  { value: '76122200', label: 'Waste incineration services' },
  { value: '76122300', label: 'Recycling services' },
  { value: '76122001', label: 'Landfill for non hazardous generic waste' },
  { value: '76122002', label: 'Landfill for non hazardous special waste' },
  { value: '76122003', label: 'Landfill for hazardous waste' },
  {
    value: '76122004',
    label: 'Landfill for low risk hazardous or universal waste'
  },
  { value: '81101514', label: 'Geotechnical or geoseismic engineering' },
  { value: '81101515', label: 'Plant or facility infrastructure engineering' },
  { value: '11122003', label: 'Medium density fiberboard' },
  { value: '27113300', label: 'Precision hand tools' },
  { value: '30103800', label: 'Metallic fibers and filaments' },
  { value: '30181804', label: 'Faucet handle' },
  { value: '31152100', label: 'Cords' },
  { value: '41111624', label: 'Alexometer' },
  { value: '41111625', label: 'Curvimeter' },
  { value: '41111626', label: 'Gauge block' },
  { value: '41113038', label: 'Alcoholometer' },
  { value: '41111627', label: 'Vee block' },
  { value: '41111628', label: 'Wire meter' },
  {
    value: '41111629',
    label: 'Cable or wire extension linear position sensor'
  },
  { value: '42201843', label: 'Medical x ray skin markers' },
  { value: '42143135', label: 'Amniocentesis hooks' },
  { value: '42203417', label: 'Endografts' },
  { value: '42201844', label: 'Medical diagnostic x ray rulers' },
  { value: '42231613', label: 'Gastric lavage tubes or kits' },
  { value: '43202106', label: 'Portable media case or wallet' },
  { value: '43191514', label: 'Satellite phone' },
  { value: '46181540', label: 'Welder gloves' },
  { value: '46181541', label: 'Chemical resistant gloves' },
  { value: '46181543', label: 'Waterproof jacket or raincoat' },
  { value: '48101533', label: 'Commercial use combination oven' },
  { value: '48101534', label: 'Commercial use boiling table' },
  { value: '48101535', label: 'Commercial use salamander grill' },
  { value: '48101536', label: 'Commercial use pastry oven' },
  { value: '48101537', label: 'Commercial use conveyor oven' },
  { value: '48101538', label: 'Commercial use double contact grill' },
  { value: '48101539', label: 'Commercial use oil fat filtration unit' },
  { value: '48101540', label: 'Commercial use wok heating unit' },
  {
    value: '48101541',
    label: 'Commercial use chip and french fry storage and scuttle'
  },
  { value: '48101542', label: 'Commercial use jacketed tilting kettle' },
  { value: '48101543', label: 'Commercial use jacketed boiling pan' },
  { value: '48101544', label: 'Commercial use direct heat boiling pan' },
  { value: '48101545', label: 'Commercial use oven stand' },
  { value: '81102500', label: 'Permitting services' },
  { value: '81102501', label: 'Mine permitting service' },
  { value: '81102600', label: 'Sampling services' },
  { value: '81102601', label: 'Coal sampling service' },
  { value: '81112203', label: 'Firmware patching or upgrade service' },
  { value: '76122400', label: 'Refuse disposal and treatment fees' },
  { value: '76122407', label: 'Refuse transportation fee' },
  { value: '11121614', label: 'Teak wood' },
  { value: '11121616', label: 'Pine wood' },
  { value: '11122004', label: 'Wood veneers' },
  { value: '12131709', label: 'Explosive tamper' },
  { value: '14111823', label: 'Control forms or control books' },
  { value: '14122202', label: 'Tea bag paper' },
  { value: '14111538', label: 'Digital paper' },
  { value: '14111819', label: 'Booking forms or reservation books' },
  { value: '14111820', label: 'Game of chance forms or coupons' },
  { value: '14111821', label: 'Order forms or order books' },
  { value: '14111822', label: 'Delivery forms or delivery books' },
  { value: '25101612', label: 'Concrete transport truck' },
  { value: '25101613', label: 'Concrete pump truck' },
  { value: '24131513', label: 'Refrigerant' },
  { value: '24111511', label: 'Fiber mesh bag' },
  { value: '25172509', label: 'Heavy truck tire tube' },
  { value: '25101909', label: 'Amphibious vehicle' },
  { value: '25111514', label: 'Icebreaker' },
  { value: '41111950', label: 'Level indicator' },
  { value: '30250000', label: 'Underground mining structures and materials' },
  { value: '30181606', label: 'Robe hook' },
  { value: '30171516', label: 'Door screen' },
  { value: '30151509', label: 'Rubber support block' },
  { value: '30181805', label: 'Combination fixed and hand held shower head' },
  {
    value: '39122100',
    label: 'Electrical transmission and distribution equipment '
  },
  { value: '39121635', label: 'Voltage regulator' },
  { value: '41104213', label: 'Distilled or deionized water' },
  { value: '41115323', label: 'Function generator' },
  { value: '41112109', label: 'Current transducer' },
  { value: '41112110', label: 'Pressure transducer' },
  { value: '41112111', label: 'Displacement transducer' },
  { value: '41113649', label: 'Psophometer' },
  { value: '41116016', label: 'Electrolyte analyzer reagent' },
  { value: '41113039', label: 'Osmometer accessories' },
  { value: '41123306', label: 'Histology tissue cassette accessories' },
  {
    value: '41104125',
    label: 'Histology or pathology preservative specimen container'
  },
  { value: '41104126', label: 'Histology or pathology specimen container' },
  {
    value: '42121800',
    label: 'Veterinary artificial insemination equipment and supplies'
  },
  { value: '14111824', label: 'Pharmacy prescription pad' },
  {
    value: '14111539',
    label: 'Medical monitoring or tracing or recording paper'
  },
  { value: '42201845', label: 'Radiology film jacket or inserts or mailers' },
  { value: '42241516', label: 'Marker for splinting materials' },
  { value: '42142717', label: 'Nephrostomy catheters or drains' },
  { value: '42143708', label: 'Phototherapy vests' },
  { value: '42241517', label: 'Cast spreaders' },
  {
    value: '42295800',
    label: 'Surgical thermo ablation systems and accessories'
  },
  { value: '42203418', label: 'Artherectomy catheters' },
  { value: '42295910', label: 'Biliary stents' },
  { value: '42203420', label: 'Vascular imaging snares' },
  {
    value: '42203421',
    label: 'Diagnostic or interventional vascular trays or packs'
  },
  { value: '42203422', label: 'Embolization glues' },
  { value: '42203423', label: 'Embolization spheres' },
  { value: '42203504', label: 'Cardiac recorders' },
  { value: '42221514', label: 'Pulmonary artery catheters' },
  { value: '42221619', label: 'Needleless injection manifold kits' },
  { value: '42203424', label: 'Arteriotomy site closure devices' },
  { value: '42191911', label: 'Blood pressure mobile stands' },
  { value: '42203425', label: 'Thrombectomy or embolectomy catheters' },
  { value: '42295900', label: 'Stents' },
  {
    value: '42312600',
    label: 'Negative pressure and oxygen therapy wound care products'
  },
  { value: '43211723', label: 'Electronic voting or vote-counting equipment' },
  {
    value: '43201819',
    label: 'Read only high definition digital versatile disc HD DVD'
  },
  {
    value: '43201820',
    label: 'Read write high definition digital versatile disc HD DVD'
  },
  { value: '43201821', label: 'Read only blu-ray disc BD' },
  { value: '43201822', label: 'Read write blu-ray disc BD' },
  { value: '43211614', label: 'Bluetooth universal serial bus USB adapter' },
  { value: '43201412', label: 'PCMCIA or PC card' },
  {
    value: '43221604',
    label: 'Digital subscriber loop or line XDSL equipment'
  },
  {
    value: '43222639',
    label: 'Internet protocol IP multimedia subsystem hardware'
  },
  { value: '43211615', label: 'Notebook computer expansion dock' },
  { value: '43211616', label: 'Personal digital assistant PDA holder' },
  { value: '43211724', label: 'Computer peripheral kit' },
  { value: '43211806', label: 'Keyboard wrist rest' },
  { value: '43211807', label: 'Mouse wrist rest' },
  {
    value: '43211725',
    label: 'Compact disc or digital versatile disc CD/DVD cleaner'
  },
  { value: '43211726', label: 'Digital pen' },
  { value: '43201411', label: 'Peripheral component interconnect PCI card' },
  { value: '43211617', label: 'Universal serial bus USB extension cable' },
  {
    value: '43202107',
    label: 'Compact disc or digital versatile disc CD/DVD briefcase'
  },
  { value: '43201823', label: 'Multimedia hard disc drive HDD player' },
  { value: '43202010', label: 'Pen or flash drive' },
  { value: '43202108', label: 'Memory card case' },
  { value: '43201825', label: 'Disc publisher' },
  { value: '43202215', label: 'Keyboard or mouse cable' },
  { value: '43202216', label: 'Printer connection cable' },
  { value: '43191515', label: 'Speaker phone' },
  { value: '43202217', label: 'Computer cable holder' },
  {
    value: '43211727',
    label: 'Magnetic ink character recognition MICR device'
  },
  { value: '43202218', label: 'Computer cable cover' },
  { value: '43211514', label: 'Computer kiosk' },
  { value: '43201413', label: 'Magnetic stripe card' },
  { value: '43222637', label: 'Gigabyte passive optical network GPON' },
  { value: '43222638', label: 'Managed lease line network MLLN equipment' },
  {
    value: '43232805',
    label: 'Internet protocol IP multimedia subsystem software'
  },
  { value: '44122029', label: 'Classification folder' },
  { value: '44122122', label: 'Magnetic thumbtack' },
  { value: '44101810', label: 'Printer calculator' },
  { value: '44121719', label: 'Disposable fountain pen' },
  { value: '44101507', label: 'Inkjet fax machine' },
  { value: '44101508', label: 'Laser fax machine' },
  { value: '44101509', label: 'Thermal fax machine' },
  { value: '44121906', label: 'Felt pen cartridge' },
  { value: '44122030', label: 'Folder kit' },
  { value: '44101807', label: 'Pocket calculator' },
  { value: '44101808', label: 'Scientific calculator' },
  { value: '44101809', label: 'Desktop calculator' },
  {
    value: '44102413',
    label: 'Compact disc or digital versatile disc CD/DVD labeling system kit'
  },
  { value: '44121907', label: 'Fountain pen ink refill' },
  { value: '44121908', label: 'Ballpoint pen ink refill' },
  { value: '44122031', label: 'Side rails for hanging folder frame' },
  { value: '44102414', label: 'Self-inking stamp' },
  { value: '44102802', label: 'Thermal binding machine' },
  { value: '44102803', label: 'Spiral binding machine' },
  { value: '44102804', label: 'Comb binding machine' },
  { value: '44102805', label: 'Binding punch machine' },
  { value: '44102806', label: 'Wire binding machine' },
  { value: '44103508', label: 'Binding die punch' },
  { value: '44122032', label: 'Conference folder' },
  { value: '44122033', label: 'Accordion file folder' },
  { value: '44111912', label: 'Whiteboard eraser' },
  { value: '44111913', label: 'Battery driven whiteboard eraser' },
  { value: '44121720', label: 'Multifunction pen' },
  { value: '44121721', label: 'Erasable ink pen' },
  { value: '44121809', label: 'Eraser holder' },
  { value: '44111522', label: 'Book holder' },
  { value: '46181552', label: 'Mining headlamp' },
  { value: '48101818', label: 'Tableware disinfector' },
  { value: '51191605', label: 'Sterile water for injection' },
  { value: '56101543', label: 'Dining table' },
  { value: '72101513', label: 'Offsite construction service' },
  { value: '72101512', label: 'Hoist construction service' },
  { value: '72101514', label: 'Refuse area construction service' },
  { value: '72103100', label: 'Conveyance systems installation and repair' },
  { value: '72103101', label: 'Material conveyance system installation' },
  { value: '72103102', label: 'Material conveyance system repair' },
  { value: '72101507', label: 'Building maintenance service' },
  { value: '78181506', label: 'Vehicle glass replacement service' },
  { value: '80111509', label: 'Job evaluation service' },
  { value: '81101706', label: 'Laboratory equipment maintenance' },
  { value: '81101707', label: 'Printing equipment maintenance ' },
  { value: '72154065', label: 'Electrical copying equipment maintenance' },
  { value: '72154066', label: 'General office equipment maintenance ' },
  { value: '82112000', label: 'In person language interpretation services' },
  {
    value: '82112001',
    label: 'In person afghan or pashto or pushto interpretation service'
  },
  { value: '82112002', label: 'In person albanian interpretation service' },
  { value: '82112003', label: 'In person amharic interpretation service' },
  { value: '82112004', label: 'In person arabic interpretation service' },
  { value: '82112005', label: 'In person armenian interpretation service' },
  { value: '82112006', label: 'In person bangledesh interpretation service' },
  { value: '82112007', label: 'In person belarussian interpretation service' },
  { value: '82112008', label: 'In person bengali interpretation service' },
  { value: '82112009', label: 'In person bosnian interpretation service' },
  { value: '82112010', label: 'In person bulgarian interpretation service' },
  { value: '82112011', label: 'In person cambodian interpretation service' },
  { value: '82112012', label: 'In person chinese interpretation service' },
  { value: '82112013', label: 'In person creole interpretation service' },
  { value: '82112014', label: 'In person croatian interpretation service' },
  { value: '82112015', label: 'In person czech interpretation service' },
  { value: '82112016', label: 'In person danish interpretation service' },
  { value: '82112017', label: 'In person dinka interpretation service' },
  { value: '82112018', label: 'In person dutch interpretation service' },
  { value: '82112019', label: 'In person egyptian interpretation service' },
  { value: '82112020', label: 'In person fanti interpretation service' },
  {
    value: '82112021',
    label: 'In person fanti or persian interpretation service'
  },
  { value: '82112022', label: 'In person french interpretation service' },
  { value: '82112023', label: 'In person german interpretation service' },
  { value: '82112024', label: 'In person greek interpretation service' },
  { value: '82112025', label: 'In person gugarati interpretation service' },
  { value: '82112026', label: 'In person hebrew interpretation service' },
  { value: '82112027', label: 'In person hindi interpretation service' },
  { value: '82112028', label: 'In person hmong interpretation service' },
  { value: '82112066', label: 'In person hungarian interpretation service' },
  {
    value: '82112029',
    label: 'In person american indian interpretation service'
  },
  { value: '82112030', label: 'In person indonesian interpretation service' },
  { value: '82112031', label: 'In person italian interpretation service' },
  { value: '82112032', label: 'In person jamaican interpretation service' },
  { value: '82112033', label: 'In person japanese interpretation service' },
  { value: '82112034', label: 'In person kirghiz interpretation service' },
  { value: '82112035', label: 'In person korean interpretation service' },
  { value: '82112036', label: 'In person kurdish interpretation service' },
  { value: '82112037', label: 'In person lithuanian interpretation service' },
  { value: '82112038', label: 'In person malayalam interpretation service' },
  { value: '82112039', label: 'In person mandingo interpretation service' },
  {
    value: '82112040',
    label: 'In person native american interpretation service'
  },
  { value: '82112041', label: 'In person pakistani interpretation service' },
  {
    value: '82112042',
    label: 'In person eastern panjabi interpretation service'
  },
  {
    value: '82112043',
    label: 'In person western panjabi interpretation service'
  },
  { value: '82112044', label: 'In person polish interpretation service' },
  { value: '82112045', label: 'In person portuguese interpretation service' },
  { value: '82112046', label: 'In person romanian interpretation service' },
  { value: '82112047', label: 'In person romany interpretation service' },
  { value: '82112048', label: 'In person russian interpretation service' },
  { value: '82112049', label: 'In person rwandan interpretation service' },
  { value: '82112050', label: 'In person samoan interpretation service' },
  { value: '82112051', label: 'In person serbian interpretation service' },
  {
    value: '82112052',
    label: 'In person serbo croatian interpretation service'
  },
  { value: '82112053', label: 'In person slovenian interpretation service' },
  { value: '82112054', label: 'In person somali interpretation service' },
  { value: '82112055', label: 'In person spanish interpretation service' },
  { value: '82112056', label: 'In person swahili interpretation service' },
  { value: '82112057', label: 'In person swedish interpretation service' },
  { value: '82112058', label: 'In person taiwanese interpretation service' },
  { value: '82112059', label: 'In person thai interpretation service' },
  { value: '82112060', label: 'In person tibetan interpretation service' },
  { value: '82112061', label: 'In person turkish interpretation service' },
  { value: '82112062', label: 'In person ukranian interpretation service' },
  { value: '82112063', label: 'In person vietnamese interpretation service' },
  { value: '82112064', label: 'In person yiddish interpretation service' },
  { value: '82112065', label: 'In person yugoslavian interpretation service' },
  {
    value: '83112506',
    label:
      'Directional radio backbone capacity technical infrastructure service'
  },
  {
    value: '83112606',
    label: 'Directional radio capacity customer access service'
  },
  {
    value: '83112206',
    label: 'Directional radio capacity disaster recovery management service'
  },
  { value: '86101810', label: 'Personnel skills training' },
  { value: '93151516', label: 'Building permit' },
  { value: '93161608', label: 'Custom tax or duty' },
  { value: '50301501', label: 'Akane apples' },
  { value: '50301502', label: 'Ambrosia apples' },
  { value: '50301503', label: 'Api apples' },
  { value: '50301504', label: 'Baldwin apples' },
  { value: '50301505', label: 'Braeburn apples' },
  { value: '50301506', label: 'Bramley apples' },
  { value: '50301507', label: 'Bramley seedling apples' },
  { value: '50301508', label: "Calville blanche d'hiver apples" },
  { value: '50301509', label: 'Cameo apples' },
  { value: '50301510', label: 'Charles ross apples' },
  { value: '50301511', label: 'Codlin apples' },
  { value: '50301512', label: 'Cortland apples' },
  { value: '50301513', label: 'Costard apples' },
  { value: '50301514', label: 'Court pendu plat apples' },
  { value: '50301515', label: "Cox's orange pippin apples" },
  { value: '50301516', label: 'Crab apples' },
  { value: '50301517', label: 'Crispin apples' },
  { value: '50310000', label: 'Organic fresh fruits' },
  { value: '50311500', label: 'Organic apples' },
  { value: '50311501', label: 'Organic akane apples' },
  { value: '50311502', label: 'Organic ambrosia apples' },
  { value: '50311503', label: 'Organic api apples' },
  { value: '50311504', label: 'Organic baldwin apples' },
  { value: '50311505', label: 'Organic braeburn apples' },
  { value: '50311506', label: 'Organic bramley apples' },
  { value: '50311507', label: 'Organic bramley seedling apples' },
  { value: '50311508', label: "Organic calville blanche d'hiver apples" },
  { value: '50311509', label: 'Organic cameo apples' },
  { value: '50311510', label: 'Organic charles ross apples' },
  { value: '50311511', label: 'Organic codlin apples' },
  { value: '50311512', label: 'Organic cortland apples' },
  { value: '50311513', label: 'Organic costard apples' },
  { value: '50311514', label: 'Organic court pendu plat apples' },
  { value: '50311515', label: "Organic cox's orange pippin apples" },
  { value: '50311516', label: 'Organic crab apples' },
  { value: '50311517', label: 'Organic crispin apples' },
  { value: '50401500', label: 'Artichokes' },
  { value: '50401501', label: 'Brittany artichokes' },
  { value: '50403400', label: 'Cucumbers' },
  { value: '50404500', label: 'Lentils' },
  { value: '50410000', label: 'Organic fresh vegetables' },
  { value: '50411500', label: 'Organic artichokes' },
  { value: '50411501', label: 'Organic brittany artichokes' },
  { value: '50407000', label: 'Nominant vegetables' },
  { value: '14111617', label: 'Leathack paper' },
  { value: '15111511', label: 'Liquefied natural gas LNG' },
  { value: '25172511', label: 'Tire repair kit' },
  { value: '30181607', label: 'Shower curtain or assembly' },
  { value: '30181608', label: 'Shower rod' },
  { value: '30181609', label: 'Shower caddy' },
  { value: '30181610', label: 'Toilet tissue holder' },
  { value: '30181611', label: 'Toothbrush or tumbler holder' },
  { value: '30181614', label: 'Soap dispenser' },
  { value: '30181806', label: 'Whirlpool jet' },
  { value: '30103619', label: 'Precast concrete element' },
  { value: '30162300', label: 'Cabinet accessories' },
  { value: '42142718', label: 'Penile support and lengthening devices' },
  {
    value: '42172104',
    label: 'Mobile medical services cardio pulmonary resuscitation CPR boards'
  },
  { value: '42295016', label: 'Surgical or endoscopic video cable' },
  { value: '46161700', label: 'Rescue equipment and accessories' },
  { value: '46161701', label: 'Descending life line' },
  { value: '46191611', label: 'Fire hose cart' },
  { value: '46191612', label: 'Backpack water pump' },
  { value: '48101819', label: 'Hand sterilizer' },
  { value: '50131704', label: 'Powdered milk' },
  { value: '53131639', label: 'Urinary incontinence pad' },
  { value: '76121904', label: 'Hazardous waste water disposal' },
  {
    value: '80111510',
    label: 'Job description development and writing service'
  },
  { value: '80141706', label: 'Product brokerage service' },
  { value: '80141624', label: 'Recognition program management service' },
  { value: '80141625', label: 'Incentive program management service' },
  { value: '80141626', label: 'Promotional program management service' },
  { value: '90121603', label: 'Passenger ticket verification service' },
  { value: '93161609', label: 'Tariff or duty' },
  { value: '93151517', label: 'License or registration fee' },
  { value: '93151518', label: 'Property title fee' },
  { value: '81112300', label: 'Computer hardware maintenance and support ' },
  { value: '81112301', label: 'Disk storage system maintenance' },
  { value: '81112302', label: 'Nearline or backup system maintenance' },
  { value: '81112303', label: 'Mainframe computer maintenance' },
  { value: '81112304', label: 'UNIX server maintenance' },
  {
    value: '81112306',
    label: 'Printer, scanner and multifunctional equipment maintenance'
  },
  { value: '81112307', label: 'PC or workstation or notebook maintenance' },
  { value: '81112204', label: 'Operating system software maintenance' },
  {
    value: '81112205',
    label: 'Database management system software maintenance'
  },
  {
    value: '81112206',
    label: 'Information retrieval or search software maintenance'
  },
  { value: '81112207', label: 'Video conferencing software maintenance' },
  { value: '81112208', label: 'Security and protection software maintenance' },
  { value: '81112209', label: 'Development software maintenance' },
  { value: '43233700', label: 'System management software' },
  { value: '43233701', label: 'Enterprise system management software' },
  { value: '81112210', label: 'System management software maintenance' },
  {
    value: '81112211',
    label: 'Enterprise resource planning software maintenance'
  },
  {
    value: '81112212',
    label: 'Customer relationship management software maintenance'
  },
  { value: '81112213', label: 'Accounting software maintenance' },
  {
    value: '81112214',
    label: 'Content authoring and editing software maintenance'
  },
  { value: '81112215', label: 'Content management software maintenance' },
  { value: '81112216', label: 'Educational or reference software maintenance' },
  { value: '81112217', label: 'Industry specific software maintenance' },
  { value: '81112218', label: 'Network application software maintenance' },
  {
    value: '81112219',
    label: 'Computer game or entertainment software maintenance'
  },
  { value: '81112220', label: 'Server software maintenance' },
  { value: '81112305', label: 'X86 server maintenance' },
  { value: '50320000', label: 'Dried fruit' },
  { value: '50321500', label: 'Dried apples' },
  { value: '50321501', label: 'Dried akane apples' },
  { value: '50330000', label: 'Dried organic fruit' },
  { value: '50331500', label: 'Dried organic apples' },
  { value: '50331501', label: 'Dried organic akane apples' },
  { value: '50331502', label: 'Dried organic ambrosia apples' },
  { value: '50340000', label: 'Frozen fruit' },
  { value: '50341500', label: 'Frozen apples' },
  { value: '50341501', label: 'Frozen akane apples' },
  { value: '50360000', label: 'Canned or jarred fruit' },
  { value: '50361500', label: 'Canned or jarred apples' },
  { value: '50361501', label: 'Canned or jarred akane apples' },
  { value: '50370000', label: 'Canned or jarred organic fruit' },
  { value: '50371500', label: 'Canned or jarred organic apples' },
  { value: '50371501', label: 'Canned or jarred organic akane apples' },
  { value: '50380000', label: 'Fresh fruit purees' },
  { value: '50221300', label: 'Flour and milled products' },
  { value: '50420000', label: 'Dried vegetables' },
  { value: '50421500', label: 'Dried artichokes' },
  { value: '50421501', label: 'Dried brittany artichokes' },
  { value: '50421800', label: 'Dried beans' },
  { value: '50421834', label: 'Dried lima beans' },
  { value: '50424000', label: 'Dried green peas' },
  { value: '50425400', label: 'Dried peas' },
  { value: '50425600', label: 'Dried potatoes' },
  { value: '50430000', label: 'Dried organic vegetables' },
  { value: '50431500', label: 'Dried organic artichokes' },
  { value: '50431501', label: 'Dried organic brittany artichokes' },
  { value: '50440000', label: 'Frozen vegetables' },
  { value: '50441500', label: 'Frozen artichokes' },
  { value: '50441501', label: 'Frozen brittany artichokes' },
  { value: '50450000', label: 'Frozen organic vegetables' },
  { value: '50451500', label: 'Frozen organic artichokes' },
  { value: '50451501', label: 'Frozen organic brittany artichokes' },
  { value: '50460000', label: 'Canned or jarred vegetables' },
  { value: '50461500', label: 'Canned or jarred artichokes' },
  { value: '50461501', label: 'Canned or jarred brittany artichokes' },
  { value: '50465300', label: 'Canned or jarred peanuts' },
  { value: '50466800', label: 'Canned or jarred nominant vegetables' },
  { value: '50466818', label: 'Canned or jarred chickpeas' },
  { value: '50470000', label: 'Canned or jarred organic vegetables' },
  { value: '50471500', label: 'Canned or jarred organic artichokes' },
  { value: '50471501', label: 'Canned or jarred organic brittany artichokes' },
  { value: '10191510', label: 'Abamectin' },
  { value: '10191511', label: 'Fipronil' },
  { value: '11122000', label: 'Engineered wood products' },
  { value: '14111825', label: 'Menu' },
  { value: '20121449', label: 'Plunger lift equipment' },
  { value: '41112517', label: 'Optical flowmeter and accessories' },
  { value: '22101535', label: 'Pile extractor' },
  { value: '22101536', label: 'Tire washer' },
  { value: '23153800', label: 'Coating systems' },
  { value: '23300000', label: 'Wire machinery and equipment' },
  { value: '23201204', label: 'Food drying equipment' },
  {
    value: '23301500',
    label: 'Wire working machinery and equipment and accessories'
  },
  { value: '45101612', label: 'Doctor blade' },
  { value: '24112900', label: 'Crates' },
  { value: '24113000', label: 'Slip sheets' },
  { value: '24113003', label: 'Plastic slip sheet' },
  { value: '24121511', label: 'Packaging carton' },
  { value: '25174800', label: 'Specialized vehicle systems and components' },
  { value: '26121800', label: 'Automotive cable' },
  { value: '26101515', label: 'Gasoline outboard motor' },
  { value: '26111610', label: 'Selsyn generator' },
  { value: '26111609', label: 'Gas turbine generator' },
  { value: '26111611', label: 'Auxiliary generator' },
  { value: '27112035', label: 'Hedge trimmer' },
  { value: '30131509', label: 'Sound proof block' },
  { value: '30131510', label: 'Concrete block for revetment' },
  { value: '30131511', label: 'Concrete armor unit' },
  { value: '30131512', label: 'Autoclaved lightweight aerated concrete block' },
  { value: '30131513', label: 'Rubber block' },
  { value: '30131514', label: 'Reinforced concrete built up culvert block' },
  { value: '30131606', label: 'Loess brick' },
  { value: '30131607', label: 'Clay brick' },
  { value: '30131608', label: 'Salt glazed brick' },
  { value: '30131609', label: 'Fabricated brick' },
  { value: '30131610', label: 'Insulating fire brick' },
  { value: '30171517', label: 'Sound proof door' },
  { value: '30171519', label: 'Blast proof door' },
  { value: '30171521', label: 'Water tight door' },
  { value: '30171522', label: 'Air tight door' },
  { value: '30171523', label: 'Fire door' },
  { value: '30171908', label: 'Window arch' },
  { value: '30162400', label: 'Partition walls' },
  { value: '30171518', label: 'Blem door' },
  { value: '30171520', label: 'Door and window loop' },
  { value: '31163300', label: 'Studs' },
  { value: '40101709', label: 'Air handling unit' },
  { value: '40101710', label: 'Reciprocating chiller' },
  { value: '40101711', label: 'Centrifugal liquid chiller' },
  { value: '40101712', label: 'Screw chiller' },
  { value: '40101713', label: 'Absorption chiller' },
  { value: '40101714', label: 'Absorption chiller heater' },
  { value: '40101716', label: 'Cooling tower' },
  { value: '40101717', label: 'Air conditioner power saver' },
  { value: '41101708', label: 'Laboratory grinder or polisher' },
  { value: '41101811', label: 'Electron probe x ray micro analyzer' },
  { value: '41101812', label: 'Particle accelerator' },
  { value: '41103416', label: 'Temperature cycle chamber' },
  { value: '41103514', label: 'Laboratory steam generator' },
  { value: '41104021', label: 'Fraction collector' },
  { value: '41104513', label: 'Magnesium melt oven' },
  { value: '41104818', label: 'Structured packing' },
  { value: '41104930', label: 'Molecular sieve' },
  { value: '41111630', label: 'Dial indicator or dial gauge' },
  { value: '41111740', label: 'Automated optical inspection system' },
  { value: '41111951', label: 'Humidistat' },
  { value: '41111952', label: 'Hydrogen sulfide sensor' },
  { value: '41111965', label: 'Water sensor' },
  { value: '41111967', label: 'Lateral long yaw sensor' },
  { value: '41111970', label: 'Temperature sensor' },
  { value: '41111971', label: 'Binary counter' },
  { value: '41112222', label: 'Temperature gauge' },
  { value: '41112223', label: 'Compound gauge' },
  { value: '41112224', label: 'Infrared thermometer' },
  { value: '41112225', label: 'Resistance temperature detector RTD' },
  { value: '41113040', label: 'Colony counter' },
  { value: '41113650', label: 'Functional tester' },
  { value: '41113651', label: 'Ring out board' },
  { value: '41113652', label: 'Wire assembly board' },
  { value: '41113653', label: 'In circuit tester ICT' },
  { value: '41115831', label: 'Stacker or magazine for microplate handling' },
  { value: '41111953', label: 'Supplemental inflator restraint arming sensor' },
  { value: '41111954', label: 'Liquid sensor' },
  { value: '41111955', label: 'Solar sensor' },
  { value: '41111956', label: 'Infrared temperature sensor' },
  { value: '41111957', label: 'Oil pressure sensor' },
  { value: '41111958', label: 'Manifold ambient pressure sensor' },
  { value: '41111959', label: 'Fuel pressure sensor' },
  { value: '41111960', label: 'Ultrasonic sensor' },
  { value: '41111961', label: 'Occupant sensor' },
  { value: '41111962', label: 'Brake wear sensor' },
  { value: '41111963', label: 'Fuel level sensor' },
  { value: '41111964', label: 'Seat belt tension sensor' },
  { value: '41111966', label: 'Humidity sensor' },
  { value: '41111968', label: 'Angular rate sensor' },
  { value: '41111969', label: 'Voltage control sensor' },
  { value: '41112226', label: 'Low temperature thermocouple sensor' },
  { value: '41112227', label: 'High temperature thermocouple sensor' },
  { value: '41112413', label: 'Differentialpressure gauge' },
  { value: '41115324', label: 'Ultraviolet sensor' },
  { value: '41116149', label: 'Limulus amebocyte lysate LAL tester' },
  {
    value: '42131708',
    label: 'Surgical cleanup or room turnover packs or kits'
  },
  { value: '42141608', label: 'Bedpan liners or bags' },
  { value: '42141810', label: 'Iontophoresis electrodes' },
  { value: '42141811', label: 'Extracorporeal shock wave therapy systems' },
  { value: '42143203', label: 'Artificial insemination catheters' },
  { value: '42143204', label: 'Embryo transfer catheters' },
  { value: '42144000', label: 'External hearing device parts and accessories' },
  { value: '42161900', label: 'Therapeutic apheresis equipment and supplies' },
  {
    value: '42172105',
    label:
      'Mobile medical service automated external defibrillator AED accessories'
  },
  { value: '42182706', label: 'Wound measuring grids or devices' },
  { value: '42203426', label: 'Angiography guidewire torque devices' },
  { value: '42203427', label: 'Angiography introducer needles' },
  {
    value: '42203505',
    label:
      'Implantable cardioverter defibrillators ICD or cardiac resynchronization therapy defibrillators CRT-D'
  },
  { value: '42221515', label: 'Central venous catheter repair kit or sets' },
  { value: '42281530', label: 'Steam autoclave or sterilizer accessories' },
  { value: '42295465', label: 'Neurophysiological monitoring systems' },
  { value: '42311540', label: 'Alginate dressings' },
  { value: '42311541', label: 'Biological dressings' },
  { value: '42312504', label: 'Facial support garments' },
  { value: '42142535', label: 'Anesthesia nerve block trays or needles' },
  {
    value: '42203428',
    label: 'Angiography control valves and hemostatic valves'
  },
  { value: '42281811', label: 'Sterilization container locks' },
  { value: '43201827', label: 'Portable hard disk storage device' },
  { value: '43211728', label: 'Smart card dummy terminal' },
  { value: '43211729', label: 'Optical mark reader' },
  { value: '43211908', label: 'Vacuum fluorescent display' },
  { value: '43222640', label: 'Wireless access point' },
  { value: '43201414', label: 'Hard disk protector' },
  { value: '43201826', label: 'Compact disk read only memory CD ROM array' },
  {
    value: '43211618',
    label: 'Universal serial bus general packet radio service USB GPRS modem'
  },
  { value: '43212115', label: 'Bar code printer' },
  {
    value: '43212116',
    label: 'Radio frequency identification RFID tag printer'
  },
  { value: '43232613', label: 'Manufacturing execution system MES software' },
  { value: '43201828', label: 'Digital video disc audio DVD A' },
  { value: '43201829', label: 'Digital video disc video DVD V' },
  { value: '43201904', label: 'Digital video disc audio video DVD AV' },
  {
    value: '43202219',
    label:
      'Automotive heating ventilation air conditioning HVAC control head assembly'
  },
  {
    value: '43202220',
    label: 'Automotive heating ventilation air conditioning HVAC power module'
  },
  {
    value: '43202221',
    label:
      'Automotive heating ventilation air conditioning HVAC resistor assembly'
  },
  { value: '43211909', label: 'Poly light emitting diode LED display' },
  { value: '43211910', label: 'Display tilt mechanism' },
  { value: '43211911', label: 'Touchscreen glass' },
  { value: '43211912', label: 'Touchscreen film' },
  { value: '43221722', label: 'Global positioning system GPS antenna' },
  { value: '43221723', label: 'Cellular antenna' },
  {
    value: '44103206',
    label: 'Fingerprint time attendance and access control machine'
  },
  { value: '45101709', label: 'Printing film' },
  { value: '45111706', label: 'Combination audio mixer and amplifier' },
  { value: '45111707', label: 'Audio equipment connector and stage box' },
  { value: '46171622', label: 'Closed circuit television CCTV system' },
  { value: '46191507', label: 'Passive firestop system' },
  { value: '51453502', label: 'Nitazoxanide' },
  { value: '51282109', label: 'Faropenem sodium' },
  { value: '51452601', label: 'Febantel' },
  { value: '51101913', label: 'Amodiaquine' },
  { value: '51101914', label: 'Artesunate' },
  { value: '51101915', label: 'Benflumetol or lumefantrine' },
  { value: '51341802', label: 'Oseltamivir' },
  { value: '51342305', label: 'Ganciclovir' },
  { value: '51343004', label: 'Stavudine or stavidine' },
  { value: '51342301', label: 'Valaciclovir or valacyclovir' },
  { value: '51342308', label: 'Valganciclovir' },
  { value: '51202409', label: 'Infliximab' },
  { value: '51112705', label: 'Oxaliplatin' },
  { value: '51111824', label: 'Exemestane' },
  { value: '51182047', label: 'Tibolone' },
  { value: '51431602', label: 'Atomoxetine' },
  { value: '51261703', label: 'Nebivolol' },
  { value: '51432503', label: 'Perindopril' },
  { value: '51433804', label: 'Levosimendan' },
  { value: '51432606', label: 'Eplerenone' },
  { value: '51141535', label: 'Cabergoline' },
  { value: '51141538', label: 'Donepezil' },
  { value: '51333410', label: 'Ziprasidone' },
  { value: '51294403', label: 'Aripiprazole' },
  { value: '51384802', label: 'Etoricoxib' },
  { value: '51151666', label: 'Tolterodine' },
  { value: '51161524', label: 'Tiotropium' },
  { value: '51171714', label: 'Racecadotril' },
  { value: '51171926', label: 'Sevelamer' },
  { value: '51201810', label: 'Galsulfase' },
  { value: '51201811', label: 'Thalidomide' },
  { value: '51241232', label: 'Isotretinoin' },
  { value: '52141804', label: 'Domestic electric fan' },
  { value: '53102715', label: 'Prison officer uniform' },
  { value: '53102716', label: 'Prisoner uniform' },
  { value: '53103200', label: 'Disposable clothes' },
  { value: '53103201', label: 'Disposable work coat' },
  { value: '55101529', label: 'Bankbook or passbook' },
  { value: '60131520', label: 'Drum stick' },
  {
    value: '72101509',
    label: 'Fire protection system and equipment maintenance or repair service'
  },
  { value: '72110000', label: 'Residential building construction services' },
  { value: '72111000', label: 'Single family dwelling construction services' },
  {
    value: '72111001',
    label: 'Single family home remodeling addition and repair service'
  },
  { value: '72111002', label: 'Single family home general remodeling service' },
  { value: '72111003', label: 'On site mobile home repair service' },
  {
    value: '72111004',
    label: 'Patio and deck construction and repair service'
  },
  { value: '72111005', label: 'Single family home fire damage repair service' },
  { value: '72111006', label: 'Single family home new construction service' },
  {
    value: '72111007',
    label: 'Single family prefabricated home erection service'
  },
  {
    value: '72111008',
    label: 'Single family new town home or garden home construction service'
  },
  { value: '72111100', label: 'Multiple unit dwelling construction services' },
  { value: '72111101', label: 'New apartment building construction service' },
  { value: '72111102', label: 'New cooperative construction service' },
  { value: '72111103', label: 'New condominium construction service' },
  { value: '72111104', label: 'New dormitory construction service' },
  { value: '72111105', label: 'New hotel or motel construction service' },
  { value: '72111106', label: 'Apartment remodeling service' },
  { value: '72111107', label: 'Cooperative apartment remodeling service' },
  { value: '72111108', label: 'Condominium remodeling service' },
  { value: '72111109', label: 'Dormitory remodeling service' },
  { value: '72111110', label: 'Hotel or motel remodeling service' },
  {
    value: '72111111',
    label: 'General residential construction contractor service'
  },
  { value: '72120000', label: 'Nonresidential building construction services' },
  {
    value: '72121102',
    label: 'Commercial and office building prefabricated erection service'
  },
  {
    value: '72121103',
    label: 'Commercial and office building renovation and repair service'
  },
  { value: '72121104', label: 'Restaurant construction service' },
  { value: '72121105', label: 'Shopping center and mall construction service' },
  { value: '72121200', label: 'Agricultural building construction services' },
  { value: '72121202', label: 'Greenhouse construction service' },
  {
    value: '72121300',
    label: 'Automotive garage and service station construction services'
  },
  { value: '72121301', label: 'Automotive garage construction service' },
  {
    value: '72121302',
    label: 'Automotive service station construction service'
  },
  { value: '72121402', label: 'Fire station construction service' },
  { value: '72121403', label: 'Hospital construction service' },
  { value: '72121404', label: 'Post office construction service' },
  { value: '72121406', label: 'School building construction service' },
  { value: '72121407', label: 'Mausoleum construction service' },
  { value: '72121408', label: 'Stadium construction service' },
  { value: '72121409', label: 'Public library construction' },
  { value: '72121410', label: 'Airport terminal and hanger construction' },
  { value: '72121501', label: 'Chemical plant construction service' },
  {
    value: '72121502',
    label: 'Mine loading and discharging station construction service'
  },
  { value: '72121503', label: 'Oil refinery construction service' },
  { value: '72121504', label: 'Waste disposal plant construction service' },
  {
    value: '72121505',
    label: 'Waste water and sewage treatment plant construction service'
  },
  { value: '72121506', label: 'Power plant construction service' },
  { value: '72140000', label: 'Heavy construction services' },
  { value: '72141000', label: 'Highway and road construction services' },
  { value: '72141001', label: 'Highway and road new construction service' },
  {
    value: '72141002',
    label: 'Highway and road sign or guardrail construction and repair service'
  },
  { value: '72141003', label: 'Highway and road maintenance service' },
  { value: '72141004', label: 'Highway reflector installation service' },
  {
    value: '72141100',
    label: 'Infrastructure building and surfacing and paving services'
  },
  { value: '72141101', label: 'Airport runway construction service' },
  { value: '72141103', label: 'Highway and road paving service' },
  { value: '72141104', label: 'Highway and road resurfacing service' },
  { value: '72141110', label: 'Oil and gas branch line construction service' },
  { value: '72141111', label: 'Gas main construction service' },
  {
    value: '72141112',
    label: 'Natural gas compressor station construction service'
  },
  { value: '72141113', label: 'Oil and gas pipeline construction service' },
  { value: '72141114', label: 'Pipeline wrapping service' },
  { value: '72141115', label: 'Electrical cable laying service' },
  { value: '72141116', label: 'Television cable laying service' },
  {
    value: '72141117',
    label: 'Telephone and communication cable laying service'
  },
  {
    value: '72141118',
    label: 'Telecommunication transmission tower construction service'
  },
  { value: '72141119', label: 'Aqueduct construction service' },
  { value: '72141120', label: 'Sewer line construction service' },
  { value: '72141121', label: 'Water main construction service' },
  { value: '72141122', label: 'Electric power line construction service' },
  { value: '72141123', label: 'Manhole construction service' },
  { value: '72141124', label: 'Pipe laying service' },
  { value: '72141125', label: 'Pumping station construction service' },
  { value: '72141126', label: 'Underground utilities construction service' },
  { value: '72141200', label: 'Marine construction services' },
  { value: '72141201', label: 'Caisson drilling service' },
  { value: '72141202', label: 'Canal construction service' },
  { value: '72141203', label: 'Dam construction service' },
  { value: '72141204', label: 'Dock construction service' },
  { value: '72141205', label: 'Drainage system construction service' },
  { value: '72141206', label: 'Dredging service' },
  { value: '72141207', label: 'Harbor construction service' },
  { value: '70171710', label: 'Irrigation system construction service' },
  { value: '72141209', label: 'Levee construction service' },
  { value: '72141210', label: 'Marina construction service' },
  { value: '72141211', label: 'Pier construction service' },
  { value: '72141212', label: 'Pond construction service' },
  { value: '72141214', label: 'Sheet pile driving service' },
  { value: '72141215', label: 'Underwater construction service' },
  { value: '72153107', label: 'Golf course construction service' },
  { value: '72153108', label: 'Tennis court construction service' },
  {
    value: '72141400',
    label: 'Detention facility construction and repair services'
  },
  { value: '72141401', label: 'Detention facility construction service' },
  { value: '72141402', label: 'Detention facility remodeling and repair' },
  { value: '72141500', label: 'Land preparation services' },
  { value: '72141502', label: 'Land pre-construction assessment service' },
  { value: '72141503', label: 'Rock removal service' },
  { value: '72141504', label: 'Timber removal service' },
  { value: '72141507', label: 'Pile driving service' },
  { value: '72141508', label: 'Blasting service except building demolition' },
  { value: '72141509', label: 'Trenching service' },
  { value: '72141605', label: 'Railway track laying service' },
  {
    value: '72150000',
    label: 'Specialized trade construction and maintenance services'
  },
  {
    value: '72151000',
    label: 'Boiler and furnace construction and maintenance services'
  },
  { value: '72151001', label: 'Boiler maintenance service' },
  { value: '72151002', label: 'Boiler installation and setup service' },
  { value: '72151003', label: 'Heating system maintenance and repair service' },
  {
    value: '72151004',
    label: 'Hydronics heating system maintenance and repair service'
  },
  { value: '72151100', label: 'Plumbing construction services' },
  { value: '72151101', label: 'Septic system construction service' },
  { value: '72151102', label: 'Fire sprinkler system installation service' },
  {
    value: '72151103',
    label: 'Irrigation sprinkler system installation service'
  },
  {
    value: '72151200',
    label:
      'Heating and cooling and air conditioning HVAC construction and maintenance services'
  },
  { value: '72151201', label: 'HVAC mechanical construction service' },
  { value: '72151202', label: 'HVAC process piping construction service' },
  { value: '72151203', label: 'HVAC solar energy construction service' },
  {
    value: '72151204',
    label: 'HVAC ventilation and duct work construction service'
  },
  { value: '72151205', label: 'HVAC refrigeration construction service' },
  { value: '72151206', label: 'HVAC heating system construction service' },
  { value: '72151300', label: 'Painting and paper hanging services' },
  { value: '72151301', label: 'Residential painting service' },
  { value: '72151302', label: 'Commercial painting service' },
  { value: '72151303', label: 'Industrial painting service' },
  { value: '72151304', label: 'Aircraft painting service' },
  { value: '72151305', label: 'Bridge painting service' },
  { value: '72151306', label: 'Pavement marking service' },
  { value: '72151307', label: 'Ship painting service' },
  { value: '72151308', label: 'Paper hanging service' },
  { value: '72151400', label: 'Wall covering construction services' },
  { value: '72151401', label: 'Commercial wall covering construction service' },
  {
    value: '72151402',
    label: 'Residential wall covering construction service'
  },
  { value: '72151500', label: 'Electrical system services' },
  { value: '72151501', label: 'Lighting installation services' },
  { value: '72151502', label: 'Electric power system construction service' },
  { value: '72151503', label: 'Cogeneration plant construction service' },
  {
    value: '72151505',
    label: 'Standby or emergency power installation service'
  },
  {
    value: '72151506',
    label: 'Switchgear and related devices installation service'
  },
  { value: '72151507', label: 'Electronic controls installation service' },
  { value: '72151508', label: 'Computerized controls installation service' },
  {
    value: '72151509',
    label: 'Energy management controls installation service'
  },
  {
    value: '72151510',
    label: 'Environmental system control installation service'
  },
  { value: '72151511', label: 'Lighting system maintenance or repair service' },
  { value: '72151600', label: 'Specialized communication system services' },
  { value: '72151601', label: 'Cable television installation service' },
  { value: '72151602', label: 'Fiber optic cable installation service' },
  {
    value: '72151603',
    label: 'Specialized sound equipment installation service'
  },
  {
    value: '72151604',
    label: 'Telephone and telephone equipment installation service'
  },
  { value: '72151605', label: 'Voice and data and video wiring service' },
  {
    value: '72151700',
    label: 'Safety and security system installation services'
  },
  { value: '72151701', label: 'Access control system installation service' },
  {
    value: '72151702',
    label: 'Closed circuit television system installation service'
  },
  {
    value: '72151703',
    label: 'Fire detection and burglar alarm systems installation service'
  },
  {
    value: '72151800',
    label: 'Machine installation and maintenance and repair services'
  },
  { value: '72151801', label: 'Banking machine installation and maintenance' },
  { value: '72151900', label: 'Masonry and stonework services' },
  { value: '72151901', label: 'Foundation building service' },
  { value: '72151903', label: 'Bricklaying service' },
  { value: '72151904', label: 'Chimney construction and maintenance service' },
  { value: '72151905', label: 'Concrete block masonry service' },
  { value: '72151906', label: 'Drain tile installation service' },
  { value: '72151907', label: 'Exterior marble masonry service' },
  { value: '72151908', label: 'Refractory or acid brick masonry service' },
  { value: '72151909', label: 'Stone masonry service' },
  { value: '72151910', label: 'Mortar tuckpointing or restoration service' },
  { value: '72151911', label: 'Unit paver installation service' },
  { value: '72152000', label: 'Plastering and drywall services' },
  { value: '72152001', label: 'Drywall installation and repair service' },
  { value: '72152002', label: 'Fresco installation and repair service' },
  { value: '72152003', label: 'Mantel installation and repair service' },
  { value: '72152004', label: 'Plain or ornamental plastering service' },
  { value: '72152005', label: 'Stucco installation and repair service' },
  { value: '72152100', label: 'Acoustical and insulation services' },
  { value: '72152101', label: 'Acoustical and ceiling work service' },
  { value: '72152102', label: 'Exterior insulation and finishing service' },
  { value: '72152103', label: 'Building insulation service' },
  { value: '72152104', label: 'Solar reflecting insulation film service' },
  { value: '72152200', label: 'Terrazzo tile and marble and mosaic services' },
  { value: '72152201', label: 'Interior marble installation service' },
  { value: '72152202', label: 'Mosaic creation and repair service' },
  { value: '72152203', label: 'Terrazzo installation and repair service' },
  { value: '72152204', label: 'Ceramic tile installation service' },
  { value: '72152300', label: 'Carpentry services' },
  { value: '72152301', label: 'Rough carpentry service' },
  { value: '72152302', label: 'Finish carpentry service' },
  { value: '72152303', label: 'Cabinet building and installation service' },
  {
    value: '72152400',
    label: 'Window and door installation and erection services'
  },
  { value: '72152401', label: 'Garage door installation or erection service' },
  {
    value: '72152402',
    label: 'Prefabricated window and door installation service'
  },
  { value: '72152403', label: 'Entryway and exit framing service' },
  { value: '72152404', label: 'Metal stud installation service' },
  { value: '72152405', label: 'Store fixture installation service' },
  { value: '72152500', label: 'Floor laying services' },
  { value: '72152501', label: 'Access flooring system installation service' },
  { value: '72152502', label: 'Asphalt tile installation service' },
  { value: '72152503', label: 'Carpet laying service' },
  { value: '72152504', label: 'Ceramic floor tile installation service' },
  { value: '72152505', label: 'Linoleum installation service' },
  { value: '72152506', label: 'Resilient floor laying service' },
  {
    value: '72152507',
    label: 'Vinyl floor tile and sheet installation service'
  },
  {
    value: '72152508',
    label: 'Wood floor installation and refinishing service'
  },
  { value: '72152509', label: 'Floor leveling service' },
  { value: '72152600', label: 'Roofing and siding and sheet metal services' },
  { value: '72152601', label: 'Roofing service' },
  { value: '72152602', label: 'Gutter and downspout service' },
  { value: '72152603', label: 'Skylight installation service' },
  { value: '72152604', label: 'Architectural sheet metal service' },
  { value: '72152605', label: 'Ceiling erection and repair service' },
  { value: '72152606', label: 'Siding installation and repair service' },
  { value: '72152607', label: 'Chute installation service' },
  { value: '72152700', label: 'Concrete installation and repair services' },
  { value: '72152702', label: 'Grouting service' },
  { value: '72152703', label: 'Gunite installation service' },
  { value: '72152704', label: 'Curb construction service' },
  { value: '72152705', label: 'Sidewalk or ramp construction service' },
  { value: '72152706', label: 'Driveway construction service' },
  { value: '72152707', label: 'Retaining wall construction service' },
  { value: '72152708', label: 'Parking lot construction service' },
  { value: '72152709', label: 'Concrete pumping service' },
  { value: '72152710', label: 'Foundation and footing construction service' },
  { value: '72152711', label: 'Concrete patio construction service' },
  { value: '72152800', label: 'Water well drilling services' },
  { value: '72152801', label: 'Domestic water well drilling service' },
  { value: '72152802', label: 'Geothermal water well drilling service' },
  { value: '72152900', label: 'Structural steel erection services' },
  { value: '72152901', label: 'Metal building front installation service' },
  { value: '72152902', label: 'Concrete reinforcement placing service' },
  { value: '72152903', label: 'Elevator front installation service' },
  {
    value: '72152904',
    label: 'Exterior metal wall system installation service'
  },
  { value: '72152905', label: 'Structural iron work service' },
  { value: '72152906', label: 'Metal lath and furring service' },
  {
    value: '72152907',
    label: 'Precast concrete structural framing panel placing service'
  },
  { value: '72152908', label: 'Smoke stack installation service' },
  { value: '72152909', label: 'Metal storage tank erection service' },
  { value: '72153000', label: 'Glass and glazing services' },
  {
    value: '72153001',
    label: 'Ballistic resistant glazing installation service'
  },
  { value: '72153002', label: 'Glazing installation and repair service' },
  {
    value: '72153100',
    label: 'Athletic and recreational facility construction services'
  },
  { value: '72153101', label: 'Bowling alley installation service' },
  { value: '72153102', label: 'Indoor sport court construction service' },
  {
    value: '72153103',
    label: 'Playground construction and equipment installation service'
  },
  {
    value: '72153104',
    label: 'Spa or hot tub construction and installation service'
  },
  { value: '72153105', label: 'Swimming pool construction service' },
  {
    value: '72153200',
    label: 'Coating and caulking and weather water and fireproofing services'
  },
  {
    value: '72153201',
    label: 'Plastic coating of concrete structures service'
  },
  { value: '72153202', label: 'Metal structure coating service' },
  { value: '72153203', label: 'Corrosion control service' },
  { value: '72153204', label: 'Damp proofing service' },
  { value: '72153205', label: 'Caulking service' },
  { value: '72153206', label: 'Building fireproofing service' },
  { value: '72153207', label: 'Glazing of concrete surfaces service' },
  { value: '72153208', label: 'Insulation of pipes and boilers service' },
  { value: '72153209', label: 'Waterproofing service' },
  {
    value: '72153300',
    label: 'Service station equipment installation and maintenance services'
  },
  { value: '72153301', label: 'Gasoline pump installation service' },
  { value: '72153302', label: 'Diesel pump installation service' },
  { value: '72153400', label: 'Rigging and scaffolding services' },
  { value: '72153500', label: 'Structural exterior cleaning services' },
  { value: '72153501', label: 'Building exterior cleaning service' },
  { value: '72153502', label: 'Building exterior sandblasting service' },
  { value: '72153503', label: 'Building exterior steam cleaning service' },
  {
    value: '72153600',
    label: 'Interior finishing and furnishing and remodeling services'
  },
  { value: '72153601', label: 'Bathtub refinishing and repair service' },
  {
    value: '72153602',
    label: 'Closet organizer installation and repair service'
  },
  { value: '72153603', label: 'Counter top installation service' },
  { value: '72153604', label: 'Drapery track installation service' },
  { value: '72153605', label: 'Kitchen and bathroom remodeling service' },
  { value: '72153606', label: 'Office furniture installation service' },
  { value: '72153607', label: 'Plastic wall tile installation service' },
  { value: '72153608', label: 'Window treatment installation service' },
  {
    value: '72153609',
    label: 'Domestic or commercial appliance installation service'
  },
  { value: '72153610', label: 'Prefabricated fireplace installation service' },
  { value: '72153611', label: 'Kitchen cabinet installation service' },
  { value: '72153701', label: 'Parking facility equipment installation' },
  { value: '72153702', label: 'Parking lot maintenance' },
  { value: '72153900', label: 'Building site preparation services' },
  { value: '72153901', label: 'Shoring and underpinning work' },
  { value: '72153902', label: 'Mobile home site set up and tie down' },
  { value: '72154000', label: 'Specialty building and trades services' },
  { value: '72154001', label: 'Antenna installation service' },
  { value: '72154002', label: 'Artificial turf installation service' },
  { value: '72154003', label: 'Awning installation service' },
  { value: '72154004', label: 'Building mover service' },
  { value: '72154005', label: 'Cable splicingservice' },
  { value: '72154006', label: 'Core drilling and cutting service' },
  { value: '72154007', label: 'Dewatering service' },
  { value: '72154008', label: 'Diamond drilling and sawing service' },
  {
    value: '72154009',
    label: 'Industrial dock and dock equipment installation service'
  },
  {
    value: '72154010',
    label: 'Elevator installation maintenance and repair service'
  },
  { value: '72154011', label: 'Epoxy application service' },
  {
    value: '72154012',
    label: 'Concrete forms erection and dismantling service'
  },
  { value: '72154013', label: 'Fence construction service' },
  {
    value: '72154014',
    label: 'Fiberglass installation and maintenance service'
  },
  { value: '72154015', label: 'Fire escape installation service' },
  { value: '72154016', label: 'Parade float construction service' },
  { value: '72154017', label: 'Food service equipment installation service' },
  { value: '72154018', label: 'Fountain installation service' },
  { value: '72154019', label: 'Gas leak detection service' },
  { value: '72154020', label: 'Medical gas system installation service' },
  { value: '72154021', label: 'Glass tinting service' },
  {
    value: '72154022',
    label: 'Hydraulic equipment installation and maintenance service'
  },
  { value: '72154023', label: 'Lightning conductor erection service' },
  { value: '72154024', label: 'Ornamental metal and iron work service' },
  { value: '72154026', label: 'Hydrogen cell refueling service' },
  { value: '72154027', label: 'Posthole digging service' },
  { value: '72154028', label: 'Sign installation and maintenance service' },
  { value: '72154029', label: 'Steeple jack installation and repair service' },
  { value: '72154030', label: 'Tower bell installation and repair service' },
  { value: '72154031', label: 'On site welding service' },
  {
    value: '72154032',
    label: 'Window and door and screening installation and repair service'
  },
  {
    value: '72154033',
    label:
      'Theatrical prop or set or scenery construction erection and repair service'
  },
  { value: '72154035', label: 'Nuclear power refueling service' },
  {
    value: '72154036',
    label: 'Central vacuum cleaning system installation service'
  },
  { value: '72154037', label: 'Safe or vault installation service' },
  {
    value: '72154038',
    label: 'Airwave shielding installation for computer room service'
  },
  { value: '72154039', label: 'Building board up service' },
  {
    value: '72154040',
    label: 'Underground protective lining installation service'
  },
  { value: '72154042', label: 'Flag pole erection service' },
  { value: '72154044', label: 'Water hewning or use service' },
  { value: '72154045', label: 'Ground water level reduction service' },
  { value: '72154047', label: 'Lift table service' },
  { value: '72154048', label: 'Balcony and external walkway service' },
  { value: '72154049', label: 'Sun screen service' },
  {
    value: '72103300',
    label: 'Infrastructure maintenance and repair services'
  },
  {
    value: '72121000',
    label: 'New industrial building and warehouse construction services'
  },
  {
    value: '72121001',
    label: 'Dry cleaning plant construction and remodeling service'
  },
  {
    value: '72121002',
    label:
      'Food product manufacturing or packing plant construction and remodeling service'
  },
  {
    value: '72121003',
    label: 'Grain elevator construction and remodeling service'
  },
  {
    value: '72121004',
    label: 'Paper or pulp mill construction and remodeling service'
  },
  {
    value: '72121005',
    label:
      'Pharmaceutical manufacturing plant construction and remodeling service'
  },
  {
    value: '72121006',
    label: 'Prefabricated industrial building erection and remodeling service'
  },
  {
    value: '72121007',
    label:
      'Truck and automobile assembly plant constructionand remodeling service'
  },
  { value: '72121008', label: 'Warehouse construction and remodeling service' },
  {
    value: '72121100',
    label: 'Commercial and office building construction services'
  },
  {
    value: '72121101',
    label: 'Commercial and office building new construction service'
  },
  { value: '72121201', label: 'Farm building construction service' },
  {
    value: '72121203',
    label: 'Silo and agricultural service building construction service'
  },
  {
    value: '72121400',
    label: 'Specialized public building construction services'
  },
  { value: '72121401', label: 'Bank building construction service' },
  { value: '72121405', label: 'Religious building construction service' },
  { value: '72121500', label: 'Industrial plant construction services' },
  { value: '72141102', label: 'Land grading service' },
  { value: '72141105', label: 'Sidewalk construction and repair service' },
  { value: '72141106', label: 'Gravel or dirt road construction service' },
  { value: '72141107', label: 'Bridge construction and repair service' },
  { value: '72141108', label: 'Tunnel construction and repair service' },
  { value: '72141109', label: 'Viaduct construction and repair service' },
  { value: '72141213', label: 'Waterway construction service' },
  { value: '72153106', label: 'Athletic field construction service' },
  { value: '72141505', label: 'Earthmoving service' },
  { value: '72141600', label: 'Mass transit system construction services' },
  { value: '72141601', label: 'Light rail construction service' },
  { value: '72141602', label: 'Right of way cutting service' },
  {
    value: '72141603',
    label: 'Railroad and railway roadbed construction service'
  },
  { value: '72141604', label: 'Subway construction service' },
  { value: '72151504', label: 'Computer power conditioning service' },
  { value: '72152701', label: 'Exterior concrete stucco service' },
  {
    value: '72153700',
    label:
      'Parking facility construction and equipment installation and maintenance and repair services'
  },
  {
    value: '72154025',
    label: 'Petroleum storage tank pumping and draining service'
  },
  {
    value: '72154034',
    label: 'Underground petroleum storage tank installation service'
  },
  { value: '72154041', label: 'Target systems installation service' },
  { value: '72154043', label: 'Industrial and commercial sprayingservice' },
  { value: '72154046', label: 'Escalator installation service' },
  {
    value: '73152104',
    label: 'Packaging equipment maintenance and repair service'
  },
  {
    value: '73152107',
    label: 'Machining equipment rebuild and refurbishment service'
  },
  {
    value: '73152106',
    label: 'Baking and heating equipment maintenance and repair service'
  },
  { value: '78131806', label: 'Self storage or mini storage service' },
  { value: '78121603', label: 'Freight fee' },
  {
    value: '80101509',
    label: 'Government affairs and community relations consultation service'
  },
  { value: '80101510', label: 'Risk management consultation service' },
  { value: '80101605', label: 'Temporary drafting service' },
  { value: '80121900', label: 'Compensated legal participation services' },
  { value: '80121901', label: 'Jury member service' },
  { value: '80121902', label: 'Witness service' },
  { value: '80121903', label: 'Expert witness service' },
  { value: '80151605', label: 'Customs consulting service' },
  { value: '80161700', label: 'Asset recovery service' },
  { value: '80161701', label: 'Byproduct disposal or sale service' },
  { value: '80161702', label: 'Capital asset disposal or sale service' },
  {
    value: '80161703',
    label: 'Excess or obsolete non capital material disposal or sale service'
  },
  { value: '72154050', label: 'Shopping cart maintenance or repair service' },
  { value: '81101516', label: 'Energy or utility consulting service' },
  { value: '81101710', label: 'Wafer reclaiming service' },
  { value: '81101711', label: 'Electronic component manufacturing service' },
  { value: '72154302', label: 'Motor installation and maintenance service' },
  { value: '82131505', label: 'Film post production service' },
  { value: '84111508', label: 'Export administration and accounting service' },
  {
    value: '85161504',
    label: 'Medical or surgical equipment service agreement'
  },
  {
    value: '85161505',
    label: 'Medical or surgical equipment or implant rental and shipping fee'
  },
  { value: '85170000', label: 'Death and dying support services' },
  { value: '85171600', label: 'Hospice care' },
  { value: '85171601', label: 'Hospice administration service' },
  { value: '90121800', label: 'Emergency travel assistance services' },
  { value: '90121801', label: 'Emergency travel agent assistance' },
  { value: '93151519', label: 'Censorship service' },
  { value: '42131613', label: 'Medical staff eye shields or visors' },
  {
    value: '72153506',
    label: 'New building post construction cleanup service'
  },
  { value: '72154051', label: 'Grave relocation service' },
  { value: '72154052', label: 'Post disaster renovation and repair service' },
  { value: '72154053', label: 'Broadcasting station repair service' },
  { value: '72121507', label: 'Tank construction and servicing' },
  { value: '11111612', label: 'Limestone dust or mine rock dust' },
  { value: '12131509', label: 'Ammonium nitrate and fuel oil ANFO' },
  { value: '14111826', label: 'Birth certificate' },
  { value: '14111827', label: 'Death certificate' },
  { value: '24121512', label: 'Cold pack or ice brick' },
  { value: '24121513', label: 'Packaging case' },
  { value: '25101910', label: 'Water sprinkling truck' },
  { value: '25101911', label: 'Aerial working platform truck' },
  { value: '25101912', label: 'Ladder lift truck' },
  { value: '25101913', label: 'Refrigerator truck' },
  { value: '25101914', label: 'Waste collection vehicle or garbage truck' },
  { value: '25101915', label: 'Oil tank truck' },
  { value: '25101916', label: 'Hopper truck' },
  { value: '25101917', label: 'Flatbed truck' },
  { value: '25101918', label: 'Neighborhood electric vehicle NEV' },
  { value: '25101919', label: 'Road sweeper' },
  { value: '25111515', label: 'Liquified gas carrier' },
  { value: '25111516', label: 'Inflatable rubber boat' },
  { value: '25111517', label: 'Hovercraft' },
  { value: '25111518', label: 'Hospital ship' },
  { value: '25111520', label: 'Buoy' },
  { value: '25131509', label: 'Fixed wing training aircraft or trainer' },
  { value: '25172512', label: 'Motorcycle tire' },
  { value: '25181719', label: 'Refrigerated trailer' },
  { value: '30121800', label: 'Landscape architecture materials' },
  { value: '30181600', label: 'Non sanitary residential fixtures' },
  { value: '30181612', label: 'Shaving razor hook' },
  { value: '30181613', label: 'Bathtub or whirlpool apron or skirt' },
  {
    value: '30181800',
    label: 'Faucet and shower heads, jets and parts and accessories'
  },
  { value: '30181808', label: 'Rough in valve' },
  { value: '30181809', label: 'Hand shower repair kit' },
  { value: '30181810', label: 'Faucet trim' },
  { value: '30181811', label: 'Faucet repair kit' },
  { value: '30181812', label: 'Body spray head' },
  { value: '31390000', label: 'Machinings' },
  { value: '39111530', label: 'Commercial downlighting fixture' },
  { value: '39111531', label: 'Commercial downlighting trim' },
  { value: '39111612', label: 'Area lighting' },
  { value: '39111613', label: 'Security lighting' },
  { value: '40181800', label: 'Welded steel tubes' },
  { value: '40183000', label: 'Rubber and plastic tubing' },
  { value: '41113654', label: 'Current loop meter' },
  { value: '41115325', label: 'Thermal imager' },
  { value: '42142120', label: 'Therapeutic cryo compression therapy cuffs' },
  {
    value: '42142536',
    label: 'Thoracentesis or paracentesis needles and catheters'
  },
  { value: '42142538', label: 'Paracentesis sets or trays' },
  { value: '42143903', label: 'Colon catheters or rectal tubes' },
  { value: '42144100', label: 'Pleural cavity drain products' },
  { value: '42144102', label: 'Chest tubes' },
  { value: '42144103', label: 'Chest tube kits' },
  { value: '42182210', label: 'Non mercury glass medical thermometers' },
  { value: '42296705', label: 'Surgical bone cements' },
  { value: '42296702', label: 'Surgical bone cement mixers' },
  { value: '42296703', label: 'Surgical bone cement injectors ' },
  { value: '43201830', label: 'Solid state drive SSD' },
  { value: '43202222', label: 'Computer cable' },
  { value: '43211515', label: 'Computer workstation' },
  { value: '43211619', label: 'Notebook computer carrying case' },
  { value: '43223311', label: 'Fiber optic attenuator' },
  { value: '43223312', label: 'Fiber optic cable stripper' },
  { value: '43223313', label: 'Fiber optic crimper' },
  { value: '43223314', label: 'Fiber optic polishing fixture' },
  { value: '43223315', label: 'Fiber optic tool blade set' },
  { value: '43223316', label: 'Fiber optic scribe' },
  { value: '43223317', label: 'Fiber optic buffer remover' },
  { value: '43223318', label: 'Fiber optic emitter' },
  { value: '43223319', label: 'Fiber optic detector' },
  { value: '43223320', label: 'Fiber optic continuity tester' },
  { value: '43223321', label: 'Fiber optic connector clip' },
  { value: '43223322', label: 'Fiber optic support grip' },
  { value: '43223323', label: 'Fiber optic adapter' },
  { value: '43223324', label: 'Fiber optic polishing film' },
  { value: '43223325', label: 'Fiber optic swab' },
  { value: '43223326', label: 'Fiber optic patch cord' },
  { value: '43223327', label: 'Fiber optic fault locator' },
  { value: '43223328', label: 'Fiber optic patch panel' },
  { value: '43223329', label: 'Fiber optic light source' },
  { value: '43223330', label: 'Fiber optic connector assembly tool' },
  { value: '43223331', label: 'Fiber optic insert' },
  { value: '43223332', label: 'Fiber optic tool kit' },
  { value: '43223333', label: 'Fiber optic scope eyepiece' },
  { value: '43223334', label: 'Fiber optic tool and die set' },
  { value: '43223335', label: 'Fiber optic music wire' },
  { value: '43223336', label: 'Fiber optic scope adapter' },
  { value: '44103125', label: 'Printer maintenance kit' },
  { value: '50221303', label: 'Corn starch or corn flour' },
  { value: '51183601', label: 'Exenatide' },
  { value: '52161553', label: 'Blu ray video disc player or recorder' },
  { value: '60131006', label: 'Synthesizer' },
  { value: '72101515', label: 'Subsidence service work' },
  { value: '72103103', label: 'Aboveground conveyor service' },
  { value: '72103104', label: 'Underground conveyor service' },
  { value: '72121508', label: 'Preparation plant construction service' },
  { value: '72121509', label: 'Underground Electrical Services' },
  {
    value: '72151802',
    label: 'Machinery component refurbishing and repair service'
  },
  { value: '80111625', label: 'Temporary manual labor underground' },
  { value: '81112400', label: 'Computer hardware rental or leasing services' },
  { value: '81112401', label: 'Computer hardware rental' },
  { value: '81161712', label: 'Network voice service' },
  { value: '25101920', label: 'Tank trucks' },
  { value: '25101921', label: 'Van trucks' },
  { value: '39111616', label: 'Outdoor lighting accessories' },
  { value: '40101512', label: 'Ventilation tube fittings' },
  {
    value: '42294958',
    label: 'Endoscopic vessel sealing and cutting attachments'
  },
  { value: '42296704', label: 'Surgical bone cement kits' },
  {
    value: '10210000',
    label: 'Live plants of high species or variety count flowers'
  },
  {
    value: '10220000',
    label: 'Live plants of low species or variety count flowers'
  },
  { value: '30260000', label: 'Structural materials' },
  { value: '30263900', label: 'Specialty steel bars' },
  { value: '30265000', label: 'Aluminum sheets' },
  { value: '30265001', label: 'Aluminum SAE 1000 series cold rolled sheet' },
  { value: '30265002', label: 'Aluminum SAE 1000 series hot rolled sheet' },
  { value: '30265003', label: 'Aluminum SAE 2000 series cold rolled sheet' },
  { value: '30265004', label: 'Aluminum SAE 2000 series hot rolled sheet' },
  { value: '30265005', label: 'Aluminum SAE 3000 series cold rolled sheet' },
  { value: '30265006', label: 'Aluminum SAE 3000 series hot rolled sheet' },
  { value: '30265007', label: 'Aluminum SAE 4000 series cold rolled sheet' },
  { value: '30265008', label: 'Aluminum SAE 4000 series hot rolled sheet' },
  { value: '30265009', label: 'Aluminum SAE 5000 series cold rolled sheet' },
  { value: '30265010', label: 'Aluminum SAE 5000 series hot rolled sheet' },
  { value: '30265011', label: 'Aluminum SAE 6000 series cold rolled sheet' },
  { value: '30265012', label: 'Corrugated aluminum sheet' },
  { value: '30265400', label: 'Iron sheets' },
  { value: '30265401', label: 'Ductile iron hot rolled sheet' },
  { value: '30265402', label: 'Gray iron hot rolled sheet' },
  { value: '30265403', label: 'White iron hot rolled sheet' },
  { value: '30265404', label: 'Malleable iron hot rolled sheet' },
  { value: '30265800', label: 'Plastic bars and sheets' },
  { value: '30265802', label: 'Plastic sheet' },
  { value: '30103900', label: 'Shafts' },
  { value: '31400000', label: 'Gaskets' },
  { value: '31401500', label: 'Molded gaskets' },
  { value: '31401503', label: 'O ring molded gasket' },
  { value: '31410000', label: 'Seals' },
  { value: '31420000', label: 'Sintered parts' },
  {
    value: '95000000',
    label: 'Land and Buildings and Structures and Thoroughfares'
  },
  { value: '95100000', label: 'Land parcels' },
  { value: '95101500', label: 'Residential land parcel' },
  { value: '95101600', label: 'Commercial land parcels' },
  { value: '95101700', label: 'Industrial land parcels' },
  { value: '95101707', label: 'Telecommunication site' },
  { value: '95101800', label: 'Governmental land parcels' },
  { value: '95101900', label: 'Agricultural land' },
  { value: '95110000', label: 'Thoroughfares' },
  { value: '95111500', label: 'Limited traffic thoroughfares' },
  { value: '95111503', label: 'Access roads' },
  { value: '95111600', label: 'Open traffic thoroughfares' },
  { value: '11162132', label: 'Damask fabric' },
  { value: '11162133', label: 'Satin fabric' },
  { value: '15101802', label: 'Ethanol' },
  { value: '15121807', label: 'Antifreeze' },
  { value: '24101513', label: 'Tugger' },
  { value: '25174900', label: 'Vehicle vibration dampeners and isolators' },
  { value: '27112841', label: 'Hand drill bit for metal' },
  { value: '30121712', label: 'Post cover' },
  { value: '39121036', label: 'Radio frequency RF transformer' },
  { value: '40101719', label: 'Liquid refrigerant receiver' },
  { value: '40101720', label: 'Unit cooler' },
  { value: '40101721', label: 'Water cooled condensor' },
  { value: '40101722', label: 'Steam condenser' },
  {
    value: '41111972',
    label: 'Supplemental inflator restraint SIR or airbag coil assembly sensor'
  },
  {
    value: '42141812',
    label: 'Static electricity generators for electric stimulation'
  },
  { value: '42141813', label: 'Electroconvulsive devices' },
  { value: '42141814', label: 'Static electricity stimulators' },
  {
    value: '42141815',
    label: 'High frequency electromagnetic wave stimulators'
  },
  { value: '42141816', label: 'Electromagnetic therapy stimulators' },
  { value: '42141817', label: 'Magnetotherapy pulse stimulators' },
  { value: '42142121', label: 'Moist steam cabinets' },
  { value: '42142619', label: 'Prefilled flush syringes' },
  { value: '42142719', label: 'Urethrotomes' },
  { value: '42142720', label: 'Male external urinary catheters' },
  { value: '42143107', label: 'Perinometers' },
  { value: '42143108', label: 'Abdominal decompression chambers' },
  { value: '42143302', label: 'Chemotherapy prep mats' },
  { value: '42143303', label: 'Chemotherapy transport bags' },
  { value: '42143709', label: 'Visible light radiators' },
  { value: '42144200', label: 'Acupuncture equipment' },
  { value: '42181721', label: 'Sphygmographs' },
  { value: '42143121', label: 'Fetal scalp electrodes' },
  { value: '42143123', label: 'Fetal electroencephalographic monitors' },
  { value: '42182211', label: 'Infrared thermography systems' },
  { value: '42182314', label: 'Biofeedback devices' },
  { value: '42182315', label: 'Electroencephalographic spectrum analysers' },
  { value: '42182316', label: 'Evoked response detectors' },
  { value: '42192606', label: 'Powder blowers' },
  {
    value: '42201846',
    label: 'Radiographic and fluoroscopic x ray collimators'
  },
  { value: '42201847', label: 'Mobile x ray systems' },
  { value: '42201848', label: 'Digital x ray imaging systems' },
  { value: '42201849', label: 'Fluorescent scanners' },
  { value: '42201850', label: 'Nuclear tomography systems' },
  { value: '42201851', label: 'Beta or gamma counters for clinical use' },
  { value: '42201852', label: 'Fluorescent screens' },
  {
    value: '42202106',
    label: 'Manual radionuclide applicator systems or guides '
  },
  { value: '42203429', label: 'Pericardiocentesis catheters' },
  {
    value: '42203506',
    label:
      'Implantable cardiac defibrillator device ICD or cardiac resynchronization therapy defibrillator CRT D leads or electrodes'
  },
  { value: '42203903', label: 'Medical neutron radiation therapy systems' },
  { value: '42221516', label: 'Catheter tip occluders' },
  { value: '42221904', label: 'Electromagnetic blood flowmeters' },
  { value: '42221905', label: 'Laser blood flowmeters' },
  { value: '42221906', label: 'Magnetic resonance blood flowmeters' },
  { value: '42222009', label: 'Syringe actuators for an injector' },
  { value: '42231904', label: 'Breast feeding pillows' },
  { value: '42271722', label: 'Oxygen uptake computers' },
  {
    value: '42296200',
    label: 'Surgical robotic equipment and accessories and related products'
  },
  { value: '42296201', label: 'Surgical robotic clip application instruments' },
  { value: '42296202', label: 'Surgical robotic dissection instruments' },
  { value: '42296203', label: 'Surgical robotic electrocautery instruments' },
  { value: '42296204', label: 'Surgical robotic graspers or forceps' },
  { value: '42296205', label: 'Surgical robotic hook instruments' },
  {
    value: '42296206',
    label: 'Surgical robotic knot pusher or delivery instruments'
  },
  { value: '42296207', label: 'Surgical robotic needle drivers' },
  { value: '42296208', label: 'Surgical robotic retractor instruments' },
  { value: '42296209', label: 'Surgical robotic scalpels and blades' },
  { value: '42296210', label: 'Surgical robotic scissors' },
  {
    value: '42296211',
    label: 'Surgical robotic ultrasonic energy instruments'
  },
  { value: '43201415', label: 'Subscriber identity module SIM card' },
  { value: '43211913', label: 'LCD active display' },
  { value: '43211914', label: 'LCD passive display' },
  { value: '43222641', label: 'Internet protocol sharing device' },
  { value: '43223337', label: 'Fiber optic enclosure' },
  { value: '46191508', label: 'Belt monitoring system' },
  { value: '48101820', label: 'Commercial kitchen hood' },
  { value: '51343005', label: 'Stavudine and lamivudine and nevirapine' },
  { value: '51283901', label: 'Sulfacetamide' },
  { value: '51112005', label: 'Imatinib' },
  { value: '51111828', label: 'Cyproterone' },
  { value: '51432305', label: 'Losartan' },
  { value: '51191918', label: 'Folic acid' },
  { value: '51332807', label: 'Chlorpromazine' },
  { value: '51383905', label: 'Lysine clonixinate' },
  { value: '51241235', label: 'Calcitriol' },
  { value: '51143203', label: 'Varenicline' },
  { value: '53131510', label: 'Denture adhesive' },
  { value: '53131641', label: 'Petroleum jelly' },
  { value: '53131643', label: 'Electric hair clipper' },
  { value: '53131700', label: 'Therapeutic massage equipment' },
  { value: '55101530', label: 'Code book' },
  { value: '55101531', label: 'Law book' },
  { value: '55121808', label: 'Identification badge clip' },
  { value: '56111806', label: 'Swiveling barber chair' },
  {
    value: '72141700',
    label: 'Construction machinery and equipment rental or leasing services'
  },
  {
    value: '72141701',
    label: 'Construction machinery rental or leasing service'
  },
  {
    value: '72141702',
    label: 'Construction equipment rental or leasing service'
  },
  { value: '78111704', label: 'Marine craft rental or leasing service' },
  {
    value: '78181507',
    label: 'Automotive and light truck maintenance and repair'
  },
  { value: '78181508', label: 'Heavy truck maintenance and repair' },
  { value: '80161800', label: 'Office equipment rental or leasing services' },
  { value: '80161801', label: 'Photocopier rental or leasing service' },
  {
    value: '81112500',
    label: 'Computer software licensing rental or leasing service'
  },
  { value: '81112501', label: 'Computer software licensing service' },
  { value: '81112502', label: 'Computer software rental or leasing service' },
  {
    value: '81161800',
    label:
      'Data voice or multimedia network equipment or platform rental or leasing services'
  },
  {
    value: '81161801',
    label: 'Data communication equipment or platform rental or leasing service'
  },
  { value: '10200000', label: 'Live rose bushes' },
  { value: '10171611', label: 'Calcium fertilizer' },
  { value: '10171800', label: 'Soil conditioners' },
  { value: '10191707', label: 'Ultrasonic pest repeller' },
  { value: '11111613', label: 'Wollastonite' },
  { value: '11111812', label: 'Vermiculite' },
  { value: '11122005', label: 'Glued laminated timber' },
  { value: '11122006', label: 'Treated timber' },
  { value: '11162400', label: 'Batting' },
  { value: '12164900', label: 'Waterproofing agents' },
  { value: '14111540', label: 'Stamp paper' },
  { value: '14111541', label: 'Optical mark reader paper' },
  { value: '14111542', label: 'Korean paper for stationery' },
  { value: '14111543', label: 'Inkstone' },
  { value: '14111618', label: 'Kent paper' },
  { value: '14121506', label: 'Corrugated fiberboard or container board CCM' },
  { value: '14121507', label: 'Volatile corrosion inhibitor or VCI paper' },
  { value: '14121703', label: 'Laminated aluminum foil paper' },
  { value: '14122107', label: 'Corrugated base paper' },
  { value: '15101800', label: 'Plant based liquid fuels or biofuels' },
  { value: '15101801', label: 'Biodiesel' },
  { value: '21101517', label: 'Rotary tiller or power tiller' },
  { value: '21101709', label: 'Baler' },
  { value: '21102305', label: 'Agricultural sun shade material' },
  { value: '22101537', label: 'Concrete pile cutter' },
  { value: '22101538', label: 'Snow plow' },
  { value: '23153145', label: 'Spray gun' },
  { value: '23153605', label: 'Dot marking machine' },
  { value: '23181514', label: 'Cheese making machine' },
  { value: '23242612', label: 'Metal engraving machine' },
  {
    value: '23251900',
    label: 'Metal container manufacturing machinery and equipment'
  },
  { value: '23271423', label: 'Alternating current AC arc welder' },
  { value: '24101514', label: 'Platform truck' },
  { value: '24101515', label: 'Straddle carrier truck' },
  { value: '24101516', label: 'Powered platform truck' },
  { value: '24101517', label: 'Electric tow tractor' },
  { value: '24101644', label: 'Dumb waiter' },
  { value: '24101655', label: 'Vehicle parking lift system' },
  { value: '24101656', label: 'Tractor towed crane' },
  { value: '24101658', label: 'Lifting magnet' },
  { value: '24101659', label: 'Vehicle movable jack or dolly' },
  { value: '24101661', label: 'Vehicle lift' },
  { value: '24111512', label: 'Straw bag' },
  { value: '24111513', label: 'Cotton bag' },
  { value: '24111514', label: 'Zipper bag' },
  { value: '24111814', label: 'Hot water tank' },
  { value: '24111815', label: 'Steam condensate tank' },
  { value: '24111816', label: 'Liquified gas tank or cylinder' },
  { value: '24111817', label: 'Cement silo' },
  { value: '24113104', label: 'Cold storage box' },
  { value: '24113105', label: 'Ballot box' },
  { value: '24112805', label: 'Temperature controlled freight container' },
  { value: '25101509', label: 'Electrically powered vehicle' },
  { value: '25101804', label: 'Motorcycle sidecar' },
  { value: '25101922', label: 'Road repair truck' },
  { value: '25101923', label: 'Crawler type tractor' },
  { value: '25101924', label: 'Guard rail cleaning vehicle' },
  { value: '25101925', label: 'Sign truck' },
  { value: '25101926', label: 'Snow plow truck' },
  { value: '25101927', label: 'Salt spreader truck' },
  { value: '25101928', label: 'Mobile media truck' },
  { value: '25101929', label: 'Mobile office van' },
  { value: '25101930', label: 'Lighting truck' },
  { value: '25101931', label: 'Outside broadcasting van' },
  { value: '25101932', label: 'Crowd control truck' },
  { value: '25101933', label: 'Crane truck' },
  { value: '25101934', label: 'Funeral vehicle or hearse' },
  { value: '25101935', label: 'Spray truck' },
  { value: '25101936', label: 'Vacuum truck' },
  { value: '25101937', label: 'Medical examination and treatment bus' },
  { value: '25101938', label: 'Bloodmobile' },
  { value: '25111524', label: 'Bulk carrier' },
  { value: '25111527', label: 'Row boat' },
  { value: '25111528', label: 'Quarantine boat' },
  { value: '25111529', label: 'Weather ship' },
  { value: '25111532', label: 'Debris collection vessel' },
  { value: '25111533', label: 'Training ship' },
  { value: '25111722', label: 'Coast guard boat or cutter' },
  { value: '25111724', label: 'Fishery training ship' },
  { value: '25111725', label: 'Fishery research ship' },
  { value: '25111726', label: 'Marine pollution control ship' },
  { value: '25172709', label: 'Vehicle air conditioner' },
  { value: '25172710', label: 'Vehicle heater' },
  { value: '25172711', label: 'Vehicle air purifier' },
  { value: '25174414', label: 'Tachograph' },
  { value: '25175000', label: 'Electric vehicle charging systems' },
  { value: '25181604', label: 'Cargo truck body' },
  { value: '25181605', label: 'Dump truck body' },
  { value: '25181606', label: 'Trailer body' },
  { value: '25181610', label: 'Air cargo trailer' },
  { value: '25181612', label: 'Agricultural trailer' },
  { value: '25191515', label: 'Ground controlled approach system' },
  { value: '25191516', label: 'Aircraft air start unit' },
  { value: '25191517', label: 'Aircraft air conditioning and heating truck' },
  { value: '25191518', label: 'Aeronautical sensor test equipment' },
  { value: '25191519', label: 'Aircraft towing adapter' },
  { value: '25191520', label: 'Environmental test chamber' },
  { value: '25191521', label: 'Retractable hook cable support system' },
  { value: '25191522', label: 'Aircraft engine maintenance set' },
  { value: '25191523', label: 'Airplane baggage tug or tractor' },
  { value: '25191524', label: 'Airfreight conveyor truck' },
  { value: '25191525', label: 'Aircraft refueling truck' },
  { value: '25191800', label: 'Vehicle testing and measuring equipment' },
  { value: '26111612', label: 'Impulse generator' },
  { value: '26111613', label: 'Tidal wave generator' },
  { value: '26111727', label: 'Battery discharger' },
  { value: '26111728', label: 'Standard cell' },
  { value: '26111729', label: 'Auto battery charger unit' },
  { value: '30111507', label: 'Soil cured concrete' },
  { value: '30111508', label: 'Water permeable concrete' },
  { value: '30111509', label: 'Asphalt based concrete' },
  { value: '30111800', label: 'Aggregates' },
  { value: '30111900', label: 'Concrete reinforcement hardware' },
  { value: '30121713', label: 'Manhole cover' },
  { value: '30121714', label: 'Manhole box' },
  { value: '30121715', label: 'Bridge expansion joint' },
  { value: '30121716', label: 'Bridge seat mounting' },
  { value: '30121717', label: 'Guardrail' },
  { value: '30121718', label: 'Safety fence and net for rock drop' },
  { value: '30121719', label: 'Safety separator for road' },
  { value: '30131515', label: 'Wood block' },
  { value: '30131516', label: 'Braille block' },
  { value: '30151513', label: 'Ceramic roof tile' },
  { value: '30151514', label: 'Metal roof tile' },
  { value: '30151515', label: 'Wooden roof tile' },
  { value: '30171524', label: 'Shielding door' },
  { value: '30171525', label: 'Pressure door' },
  { value: '30171526', label: 'Folding door' },
  { value: '30171710', label: 'Insulating glass' },
  { value: '30171711', label: 'Corrugated glass' },
  {
    value: '30191800',
    label:
      'Temporary construction and maintenance support equipment and materials'
  },
  { value: '95141711', label: 'Tollbooth' },
  { value: '95121809', label: 'Movable weir' },
  { value: '95131605', label: 'Shipping container house' },
  { value: '95131606', label: 'Portable sales booth' },
  { value: '31152200', label: 'Non electric iron and steel wire' },
  { value: '31152300', label: 'Non electric nonferrous metal wire' },
  { value: '31163400', label: 'Metal nets and screening structures' },
  { value: '39121040', label: 'Electric power saver' },
  { value: '39122200', label: 'Electrical switches and accessories' },
  { value: '39122300', label: 'Electrical relays and accessories' },
  { value: '40101609', label: 'Ceiling fan' },
  { value: '40161608', label: 'Air sterilizer' },
  { value: '41101709', label: 'Laboratory asphalt and concrete mixer' },
  { value: '41101813', label: 'Laboratory X ray equipment controller' },
  { value: '41102427', label: 'Drying tower' },
  { value: '41102428', label: 'Bibulous paper' },
  { value: '41102429', label: 'Blood unit tubing heat sealer' },
  { value: '41102923', label: 'Somatic cell counter' },
  { value: '41103026', label: 'Benchtop ice bucket or chilling container' },
  { value: '41103027', label: 'Blood unit storage boot' },
  { value: '41103211', label: 'Laboratory wastewater treatment equipment' },
  { value: '41103212', label: 'Lab glassware cleaning brush' },
  { value: '41103319', label: 'Concentration measurement instrument' },
  { value: '41103320', label: 'Density measurement instrument' },
  { value: '41103321', label: 'Floating body stability measurement apparatus' },
  { value: '41103322', label: 'Draft gauge' },
  { value: '41103323', label: 'Open channel acoustic flowmeter' },
  { value: '41103324', label: 'Laboratory wave generator' },
  { value: '41103325', label: 'Visual fluid flow apparatus' },
  { value: '41103326', label: 'Wind tunnel' },
  { value: '41103327', label: 'Current meter' },
  { value: '41103417', label: 'Indoor air quality monitor' },
  {
    value: '41103418',
    label: 'Temperature and humidity walk in environmental chamber'
  },
  { value: '41103419', label: 'Pollution environmental chamber' },
  { value: '41103420', label: 'Ozone environmental chamber' },
  { value: '41103421', label: 'Explosion environmental chamber' },
  { value: '41103515', label: 'Laboratory gas generator' },
  { value: '41103516', label: 'Arm hood' },
  { value: '41103716', label: 'Blood bank plasma thawing bath' },
  { value: '41103717', label: 'Tissue culture bath' },
  { value: '41103817', label: 'Laboratory reactor' },
  { value: '41103914', label: 'Cytocentrifuge' },
  { value: '41104022', label: 'Sample shaper' },
  { value: '41104127', label: 'Laboratory feeder' },
  { value: '41104128', label: 'Culture specimen collector without swabs' },
  {
    value: '41104129',
    label: 'Neonatal metabolic disorder screen collection card'
  },
  { value: '41104425', label: 'Carbon dioxide incubator' },
  { value: '41104426', label: 'Media preparation apparatus' },
  { value: '41104427', label: 'Platelet incubator' },
  { value: '41104613', label: 'Laboratory hot press' },
  { value: '41104819', label: 'Cell harvester' },
  { value: '41104820', label: 'Laboratory fractional distillation apparatus' },
  { value: '41104821', label: 'Static sample concentrator' },
  { value: '41105206', label: 'Immunohistochemistry autostainer' },
  { value: '41105207', label: 'Immunohistochemistry autostainer accessory' },
  { value: '41105208', label: 'Laboratory staining rack and tray' },
  { value: '41105209', label: 'Multidepartment manual slide stainer set' },
  { value: '41105340', label: 'Electrophoresis system' },
  { value: '41105341', label: 'Autoradiography film' },
  { value: '41105521', label: 'Deoxyribonucleic acid DNA detection system' },
  { value: '41111519', label: 'Crane scale' },
  { value: '41111520', label: 'Conveyor weighting scale' },
  { value: '41111521', label: 'Thermogravimeter' },
  { value: '41111522', label: 'Hopper scale' },
  { value: '41111523', label: 'Weight prototype' },
  { value: '41111524', label: 'Price indicating scale' },
  { value: '41111525', label: 'Automatic packer scale' },
  { value: '41111526', label: 'Automatic selective scale' },
  { value: '41111631', label: 'Radius gauge' },
  { value: '41111632', label: 'Cylinder gauge' },
  { value: '41111633', label: 'Air micrometer' },
  { value: '41111634', label: 'Electrical micrometer' },
  { value: '41111635', label: 'Gage block comparator' },
  { value: '41111636', label: 'Cylinder diameter measuring equipment' },
  { value: '41111637', label: 'Conical cup tester' },
  { value: '41111638', label: 'Cigarette circumference tester' },
  { value: '41111639', label: 'Screw plug gauge and cross recess' },
  { value: '41111640', label: 'Welding gauge' },
  { value: '41111641', label: 'Thread pitch gauge' },
  { value: '41111642', label: 'Rail joint gap gauge' },
  { value: '41111643', label: 'Wire gauge' },
  { value: '41111644', label: 'Pin gauge' },
  { value: '41111645', label: 'Sine bar' },
  { value: '41111646', label: 'Telescoping gauge' },
  { value: '41111647', label: 'Hole gauge' },
  { value: '41111648', label: 'Taper gauge' },
  { value: '41111649', label: 'Tire depth gauge' },
  { value: '41111650', label: 'Drill gauge' },
  { value: '41111651', label: 'Cathetometer' },
  {
    value: '41111741',
    label: 'Microscope differential interference contrast equipment'
  },
  { value: '41111742', label: 'Periscope or protectorscope' },
  { value: '41111743', label: 'Autocollimator' },
  { value: '41111744', label: 'Microscopic micrometer' },
  { value: '41111745', label: 'Micromanipulator' },
  { value: '41111746', label: 'Optical lever' },
  { value: '41111747', label: 'Industrial fiberscope' },
  { value: '41111748', label: 'Multimedia image microscope' },
  { value: '41111749', label: 'Phase contrast microscope' },
  { value: '41111812', label: 'Gamma ray radiography examination equipment' },
  { value: '41111815', label: 'Reinforcement metal detector' },
  { value: '41111816', label: 'Displacement measuring instrument' },
  { value: '41111817', label: 'Rice taste measuring system' },
  { value: '41111818', label: 'Non destructive examination reagent' },
  { value: '41111819', label: 'Wire rope tester' },
  { value: '41111820', label: 'Fluorescence detector' },
  { value: '41111973', label: 'Particle counter' },
  { value: '41111974', label: 'Heating sensor tester' },
  { value: '41111975', label: 'Magnetic field monitor' },
  { value: '41111976', label: 'Water meter check system' },
  { value: '41111977', label: 'Ion counter' },
  { value: '41111978', label: 'Manual microhematocrit tube reader' },
  { value: '41112112', label: 'Electric power transducer' },
  { value: '41112113', label: 'Mechanical energy transducer' },
  { value: '41112114', label: 'Thermo hygro transmitter' },
  { value: '41112228', label: 'Combustion efficiency analyzer' },
  { value: '41112229', label: 'Heat meter' },
  { value: '41112230', label: 'Pyrometer calibrator' },
  { value: '41112231', label: 'Oil cloud and pour point tester' },
  { value: '41112232', label: 'Freezing point measuring instrument' },
  { value: '41112233', label: 'Melting point measuring instrument' },
  { value: '41112234', label: 'Heat flowmeter' },
  { value: '41112235', label: 'Specific heat measuring instrument' },
  { value: '41112236', label: 'Asphalt softening point tester' },
  { value: '41112237', label: 'Dewpoint thermometer' },
  { value: '41112238', label: 'Dropping point tester' },
  { value: '41112239', label: 'Glass thermometer' },
  { value: '41112240', label: 'Blood unit temperature verification strip' },
  { value: '41112305', label: 'Humidity controller' },
  { value: '41112306', label: 'Humidity calibrator' },
  { value: '41112307', label: 'Cement water retentivity tester' },
  { value: '41112414', label: 'Pressure calibrator' },
  { value: '41112415', label: 'Warburg apparatus' },
  { value: '41112416', label: 'Bursting pressure tester' },
  { value: '41112417', label: 'Vapor pressure measuring instrument' },
  { value: '41112418', label: 'Capillary pressure tester' },
  { value: '41112419', label: 'Cigarette filter draw resistance meter' },
  { value: '41112420', label: 'Pressure drop gauge' },
  { value: '41112421', label: 'Fruit hardness tester' },
  { value: '41112422', label: 'Hydraulic pressure tester' },
  { value: '41112423', label: 'Pressure altimeter' },
  { value: '41112518', label: 'Pump efficiency testing equipment' },
  { value: '41112519', label: 'Pitot gauge' },
  { value: '41112520', label: 'Fire pump flow meter' },
  { value: '41113041', label: 'Elemental analyzer' },
  { value: '41113042', label: 'Milk analyzer' },
  { value: '41113043', label: 'Dietary fiber determination system' },
  { value: '41113044', label: 'Alcohol hydrometer' },
  { value: '41113045', label: 'Oil film tester' },
  { value: '41113046', label: 'Adhesion tester' },
  { value: '41113047', label: 'Oil foaming characteristics tester' },
  { value: '41113048', label: 'Color fastness tester' },
  { value: '41113049', label: 'Combustion analyzer' },
  { value: '41113050', label: 'Arsenic detector' },
  { value: '41113051', label: 'Oxidation reduction tester' },
  { value: '41113052', label: 'Chalking tester' },
  { value: '41113053', label: 'Rust prevention tester' },
  { value: '41113054', label: 'Demulsibility tester' },
  { value: '41113055', label: 'Fuel gum tester' },
  { value: '41113056', label: 'Flame experiment wire' },
  { value: '41113057', label: 'Sludge densitometer' },
  { value: '41113124', label: 'Respirometer' },
  { value: '41113325', label: 'Washability tester' },
  { value: '41113327', label: 'Film applicator' },
  { value: '41113328', label: 'Mercury analyzer' },
  { value: '41113331', label: 'Freezing and thawing tester' },
  { value: '41113332', label: 'Liquid ration analyzer' },
  { value: '41113333', label: 'Zeta potential analyzer' },
  { value: '41113655', label: 'Generator test set' },
  { value: '41113656', label: 'Servo system tester' },
  { value: '41113657', label: 'Circuit breaker tester' },
  { value: '41113658', label: 'Switch durability tester' },
  { value: '41113659', label: 'Var meter' },
  { value: '41113660', label: 'Oscillator tester' },
  { value: '41113661', label: 'Voltage regulator tester' },
  { value: '41113662', label: 'Cavity resonator' },
  { value: '41113663', label: 'Transformer tester' },
  { value: '41113664', label: 'Phase sequence indicator' },
  { value: '41113665', label: 'Contact resistance tester' },
  { value: '41113666', label: 'Arrester tester' },
  { value: '41113667', label: 'Watt hour meter test equipment' },
  { value: '41113668', label: 'Capacitor tester' },
  { value: '41113669', label: 'Line voltage detector' },
  { value: '41113670', label: 'Resister test equipment' },
  { value: '41113671', label: 'Current divider' },
  { value: '41113672', label: 'Relay tester' },
  { value: '41113673', label: 'Panel and switchboard meter' },
  { value: '41113674', label: 'Blasting machine tester' },
  { value: '41113675', label: 'Frequency control test equipment' },
  { value: '41113676', label: 'Low voltage tester' },
  { value: '41113677', label: 'Withstand voltage tester' },
  { value: '41113678', label: 'Clamp tester' },
  {
    value: '41113679',
    label: 'Current transformer and potential transformer test equipment'
  },
  { value: '41113680', label: 'Power factor meter' },
  { value: '41113681', label: 'Combi tester' },
  { value: '41113682', label: 'Electric power tester' },
  { value: '41113683', label: 'Lamp tester' },
  { value: '41113684', label: 'Voltage and current meter calibrator' },
  { value: '41113685', label: 'Static electricity measuring equipment' },
  { value: '41113686', label: 'Pulse meter' },
  { value: '41113687', label: 'Brake testing machine' },
  { value: '41113688', label: 'Converter testing equipment' },
  { value: '41113689', label: 'Controller testing equipment' },
  { value: '41113726', label: 'Heat resistance tester' },
  { value: '41113727', label: 'Distortion meter' },
  { value: '41113728', label: 'Electromagnetic shield environmental chamber' },
  { value: '41113809', label: 'Ground penetrating radar' },
  { value: '41113810', label: 'Hydrothermal testing equipment' },
  { value: '41113811', label: 'Solid bearing tester' },
  { value: '41113812', label: 'Ground friction tester' },
  { value: '41113813', label: 'Standard aggregate' },
  { value: '41113814', label: 'Soil plastic limit tester' },
  { value: '41113815', label: 'Soil shrinkage limit tester' },
  { value: '41113816', label: 'Consolidation tester' },
  { value: '41113817', label: 'Soil liquid limit tester' },
  { value: '41113818', label: 'Plate bearing tester' },
  { value: '41113819', label: 'Soil analyzer' },
  { value: '41113820', label: 'Aggregate specific gravity tester' },
  { value: '41113821', label: 'Soil penetration tester' },
  { value: '41113822', label: 'California bearing ratio tester' },
  { value: '41113823', label: 'Specimen expansion tester' },
  { value: '41113824', label: 'Soil head permeability tester' },
  { value: '41113825', label: 'Soil aggregate analyzer' },
  { value: '41113826', label: 'Soil texture analyzer' },
  { value: '41113827', label: 'Soil unconfined compression apparatus' },
  { value: '41113828', label: 'Sand density cone apparatus' },
  { value: '41113829', label: 'Underwater soil picking equipment' },
  { value: '41113830', label: 'Aggregate unit weight measuring instrument' },
  { value: '41113831', label: 'Facing sand water measuring instrument' },
  { value: '41114412', label: 'Radio acoustic sounding system' },
  { value: '41114413', label: 'Ceilometer' },
  { value: '41114414', label: 'Meteorological satellite receiving equipment' },
  { value: '41114415', label: 'Lightning analysis system' },
  { value: '41114416', label: 'Weather chart recorder or scanner' },
  { value: '41114417', label: 'Meteorological buoy robot' },
  {
    value: '41114418',
    label: 'Meteorological satellite data receiving and analyzing system'
  },
  { value: '41114419', label: 'Low level wind shear alert system' },
  { value: '41114420', label: 'Barometer calibrator' },
  { value: '41114421', label: 'Hygrometer calibrator' },
  { value: '41114422', label: 'Rain gauge calibrator' },
  { value: '41114423', label: 'Automatic weather system calibrator' },
  { value: '41114424', label: 'Anenometer calibrator' },
  { value: '41114425', label: 'Actinometer calibrator' },
  { value: '41114426', label: 'Baroswitch' },
  { value: '41114427', label: 'Wind vane' },
  { value: '41114511', label: 'Softness tester' },
  { value: '41114512', label: 'High speed rotational balance tester' },
  { value: '41114513', label: 'Eccentricity measuring instrument' },
  { value: '41114514', label: 'Twist vibration measuring instrument' },
  { value: '41114515', label: 'Centrifugal force tester' },
  { value: '41114516', label: 'Cutting force measuring instrument' },
  { value: '41114517', label: 'Hydraulic bulge tester' },
  { value: '41114518', label: 'Zipper endurance tester' },
  { value: '41114519', label: 'Tug and trolley system' },
  { value: '41114520', label: 'Drop tester' },
  { value: '41114521', label: 'Air compressor tester' },
  { value: '41114522', label: 'Gear tester' },
  { value: '41114523', label: 'Test bar' },
  { value: '41114524', label: 'Hydraulic tester' },
  { value: '41114525', label: 'Oil filter tester' },
  { value: '41114526', label: 'Air cleaner tester' },
  { value: '41114527', label: 'Belt tester' },
  { value: '41114528', label: 'Planer motion mechanism measuring instrument' },
  { value: '41114529', label: 'Tire running tester' },
  { value: '41114530', label: 'Bonding stress tester' },
  { value: '41114627', label: 'Proving ring' },
  { value: '41114628', label: 'Plasticity tester' },
  { value: '41114629', label: 'Heat distortion tester' },
  { value: '41114630', label: 'Thermal shock tester' },
  { value: '41114631', label: 'Stress tester' },
  { value: '41114632', label: 'Tensile strength tester' },
  { value: '41114633', label: 'Thermal expansion tester' },
  { value: '41114634', label: 'Erichsen tester' },
  { value: '41114635', label: 'Jewel appraising tester' },
  {
    value: '41114636',
    label: 'Polymer molding condition measuring instrument'
  },
  { value: '41114637', label: 'Beam test apparatus' },
  { value: '41114638', label: 'Load tester' },
  { value: '41114639', label: 'Cement flow tester' },
  { value: '41114640', label: 'Road plane measuring equipment' },
  { value: '41114641', label: 'Laboratory cement curing equipment' },
  { value: '41114642', label: 'Pinhole detector' },
  { value: '41114643', label: 'Cement soundness tester' },
  { value: '41114644', label: 'Concrete air measuring instrument' },
  { value: '41114645', label: 'Mortar length tester' },
  { value: '41114646', label: 'Vicat needle tester' },
  { value: '41114647', label: 'Slump tester' },
  { value: '41114648', label: 'Concrete or cement vibration tester' },
  { value: '41114649', label: 'Mortar permeability tester' },
  { value: '41114650', label: 'Concrete cylinder mold' },
  { value: '41115102', label: 'Goniometer' },
  { value: '41115326', label: 'Optic collimator' },
  { value: '41115327', label: 'Photoelectric measuring equipment' },
  { value: '41115330', label: 'Haze meter' },
  { value: '41115335', label: 'Vectorscope' },
  { value: '41115412', label: 'Spectrobolometer' },
  { value: '41115413', label: 'Flow cytometer' },
  { value: '41115414', label: 'Radio ray spectroscopy system' },
  {
    value: '41115415',
    label: 'Electron spectroscopy system for chemical analysis'
  },
  { value: '41115416', label: 'Electron spin resonance spectrometer' },
  { value: '41115832', label: 'Antibiotic detection equipment' },
  { value: '41115833', label: 'Skin analyzer' },
  { value: '41115834', label: 'Flow cytometer accessories or supplies' },
  { value: '41115835', label: 'Androgeny and fertility automated counter' },
  {
    value: '41115836',
    label: 'Androgeny and fertility automated counter accessories and supplies'
  },
  { value: '41115837', label: 'Particle counter and sizer' },
  {
    value: '41115838',
    label: 'Particle counter and sizer accessories and supplies'
  },
  {
    value: '41115839',
    label: 'Blood bank apheresis and donor unit processing analyzer'
  },
  {
    value: '41115840',
    label:
      'Blood bank apheresis and donor unit processing analyzer accessories and supplies'
  },
  {
    value: '41116017',
    label: 'Androgeny and fertility automated counter reagent'
  },
  { value: '41116018', label: 'Particle sizer and counter analyzer reagent' },
  {
    value: '41116150',
    label: 'Androgeny and fertility test kits and supplies'
  },
  {
    value: '41116151',
    label:
      'Androgeny and fertility quality controls and calibrators and standards'
  },
  {
    value: '41116152',
    label: 'Flow cytometry quality controls and calibrators and standards'
  },
  {
    value: '41116153',
    label: 'Immunohistochemistry quality controls and calibrators and standards'
  },
  {
    value: '41116154',
    label:
      'Particle counter and sizer quality controls and calibrators and standards'
  },
  {
    value: '41116155',
    label: 'Molecular biology and cell culture growth media'
  },
  { value: '41116156', label: 'Virology reagents or solutions or stains' },
  { value: '41116157', label: 'Virology cell and tissue culture media' },
  { value: '41116207', label: 'Hemoglobin or hematocrit monitor or meter' },
  {
    value: '41116208',
    label: 'Blood chemistry multiple parameter monitor or meter'
  },
  {
    value: '41116209',
    label: 'Blood coagulation multiple parameter monitor or meter'
  },
  {
    value: '41116210',
    label: 'Metabolic disorder breathalyzer monitor or meter'
  },
  { value: '41116211', label: 'Ethanol breathalyzer' },
  {
    value: '41116212',
    label: 'Monitor or meter quality controls calibrators or standards'
  },
  { value: '41116302', label: 'Incombustibility tester' },
  { value: '41116303', label: 'Flammability tester' },
  { value: '41116304', label: 'Fire point tester' },
  { value: '41116502', label: 'Electrical clip' },
  { value: '41121712', label: 'Viscometer tube' },
  { value: '41121713', label: 'Sand size analyzer' },
  { value: '41121816', label: 'Laboratory glass tube' },
  { value: '41121817', label: 'Glass bell jar' },
  { value: '41121818', label: 'Laboratory storage bottle' },
  { value: '41121819', label: 'Laboratory dropper bottle and dropper cap' },
  { value: '41122414', label: 'Laboratory spoon' },
  { value: '41122415', label: 'Centrifugal bioseparation crystals' },
  { value: '41122607', label: 'Microscope lens cleaner solution' },
  { value: '41122810', label: 'Benchtop reagent storage rack' },
  { value: '41122811', label: 'Test and culture tube dispenser' },
  {
    value: '41123005',
    label: 'Microbial susceptibility disc desiccant storage container'
  },
  { value: '41123103', label: 'Dialysis cassette' },
  { value: '41123104', label: 'Dialysis membrane' },
  { value: '41123105', label: 'Dialysis cassette holder' },
  { value: '42192214', label: 'Wheelchair lifts' },
  { value: '42161659', label: 'Hemodialysis catheters' },
  { value: '42295148', label: 'Surgical navigation systems' },
  { value: '42295470', label: 'Platelet concentration systems' },
  {
    value: '42296212',
    label: 'Surgical robotic trocars or sheathes or obturators or cannulas'
  },
  { value: '43191516', label: 'Keyphone system' },
  { value: '43191633', label: 'Key phone subset' },
  { value: '43191634', label: 'Extension board for keyphone system' },
  { value: '43201416', label: 'Data acquisition board' },
  { value: '43201417', label: 'Multimedia integrated board' },
  { value: '43201560', label: 'Coprocessor' },
  { value: '43201619', label: 'Computer cooling fan' },
  { value: '43201831', label: 'Telecommunications memory unit' },
  { value: '43201832', label: 'Optical or compact disc juke box' },
  { value: '43201833', label: 'Digital to analog conversion system' },
  { value: '43201834', label: 'Storage device controller' },
  { value: '43201835', label: 'Network attached storage NAS device' },
  { value: '43202009', label: 'Blank audio tape' },
  { value: '43211516', label: 'Minicomputer' },
  { value: '43211517', label: 'Analog computer' },
  { value: '43211518', label: 'Multi screen computer' },
  { value: '43211730', label: 'Data acquisition system' },
  { value: '43211731', label: 'Image analyzer' },
  { value: '43211732', label: 'Digital film reader' },
  { value: '43212117', label: 'Printer controller' },
  { value: '43221527', label: 'Wire tapping protector' },
  { value: '43221528', label: 'Supervisory signal unit' },
  { value: '43221529', label: 'Call meter' },
  { value: '43221530', label: 'Switch board signal device' },
  { value: '43221724', label: 'Time synchronized generator' },
  { value: '43221725', label: 'Signal divider' },
  { value: '43221726', label: 'Reflector for antenna' },
  { value: '43221727', label: 'Antenna accessory' },
  { value: '43221728', label: 'Radome or radar dome' },
  { value: '43221729', label: 'Portable antenna' },
  { value: '43221730', label: 'Wired telecommunications transmitter' },
  { value: '43221731', label: 'Underwater communication system' },
  { value: '43221732', label: 'Speaker control unit' },
  { value: '43221733', label: 'Remote automatic meter reading system' },
  { value: '43221809', label: 'Optical transmitter' },
  { value: '43221810', label: 'Optical multiplexer' },
  { value: '43221811', label: 'Optical switch' },
  { value: '43222504', label: 'Radio jamming transmitter' },
  { value: '43222642', label: 'Routing switcher' },
  { value: '43222643', label: 'Cable network tester' },
  { value: '43222644', label: 'Network traffic controller' },
  { value: '43222704', label: 'Telegraph switch board' },
  { value: '43222826', label: 'Carrier terminal' },
  { value: '43222827', label: 'Multiplexer or MUX' },
  { value: '43222903', label: 'Telecommunication tower or support' },
  { value: '43223338', label: 'Fiber optic connection closure' },
  { value: '43223339', label: 'Fiber optic distribution box' },
  { value: '43223340', label: 'Fiber optic fusion splicer' },
  { value: '43223341', label: 'Combiner' },
  { value: '43223342', label: 'Waveguide and connection kit' },
  { value: '43223343', label: 'Network punchdown tool' },
  { value: '43231515', label: 'Mailing and shipping software' },
  {
    value: '43232614',
    label:
      'Computer aided design CAD and computer aided manufacturing CAM system'
  },
  { value: '44101510', label: 'Weather facsimile or radiofax' },
  { value: '44101607', label: 'Paper press machine' },
  { value: '44101730', label: 'Automatic document sorter' },
  { value: '44101903', label: 'Bankbook or passbook update machine' },
  { value: '44102109', label: 'Automatic postal or mailing machine' },
  { value: '44103126', label: 'Digital duplicating machine ink' },
  { value: '44111617', label: 'Coin inspection machine' },
  { value: '44111618', label: 'Public telephone card or phone card' },
  { value: '44111816', label: 'Multiplex drafting machine' },
  { value: '44111817', label: 'Drafting divider' },
  { value: '44111818', label: 'Eidograph' },
  { value: '44111914', label: 'Chart hanger' },
  { value: '45101517', label: 'Offset proof press' },
  { value: '45101518', label: 'Printing plate press' },
  { value: '45111619', label: 'Film reel' },
  { value: '45111620', label: 'Slide film copier' },
  { value: '45111708', label: 'Broadcast domain controller' },
  { value: '45111709', label: 'Remote amplifier' },
  { value: '45111710', label: 'Chime and siren unit' },
  { value: '45111711', label: 'Speaker baffle' },
  { value: '45111712', label: 'Tape duplicator' },
  { value: '45111713', label: 'Audio distribution amplifier' },
  { value: '45111714', label: 'Audio analog to digital AD converter' },
  { value: '45111715', label: 'Audio digital to analog DA converter' },
  { value: '45111716', label: 'Digital audio workstation DAW' },
  { value: '45111717', label: 'Reverberator' },
  { value: '45111718', label: 'Delay unit' },
  { value: '45111719', label: 'Audio monitor' },
  { value: '45111720', label: 'Audio spectrum analyzer' },
  { value: '45111721', label: 'Reel to reel tape recorder' },
  { value: '45111722', label: 'Cassette deck' },
  { value: '45111723', label: 'Hard disk recorder' },
  { value: '45111724', label: 'Magnetic head' },
  { value: '45111811', label: 'Video precision monitor' },
  { value: '45111812', label: 'Visual information display device' },
  { value: '45111813', label: 'Down stream keyer' },
  { value: '45111814', label: 'Digital video effects DVE equipment' },
  { value: '45111815', label: 'Audio vidio console' },
  { value: '45111816', label: 'Logo generator' },
  { value: '45111817', label: 'Character generator' },
  { value: '45111818', label: 'Video noise reducer' },
  { value: '45111819', label: 'Video mixer' },
  { value: '45111820', label: 'Video processing amplifier' },
  { value: '45111821', label: 'Synchronizing or sync generator' },
  { value: '45111822', label: 'Television aspect ratio converter' },
  { value: '45111823', label: 'Video standards converter' },
  { value: '45111824', label: 'Synchronizing or sync converter' },
  { value: '45111825', label: 'Frame synchronizer' },
  { value: '45111826', label: 'Motion picture experts group MPEG encoder' },
  { value: '45111827', label: 'Video tape checker' },
  { value: '45111828', label: 'Prompter' },
  { value: '45111829', label: 'Process camera' },
  { value: '45121522', label: 'Infrared camera' },
  { value: '45121523', label: 'Astronomical camera' },
  { value: '45121624', label: 'Photography light reflector' },
  { value: '45121625', label: 'Shutter release' },
  { value: '45121626', label: 'Digital camera back' },
  { value: '45121711', label: 'Color controller' },
  { value: '45121712', label: 'Film editing desk' },
  { value: '45121713', label: 'Film inspection machine' },
  { value: '45121714', label: 'Film reader' },
  { value: '45121715', label: 'Film hanger' },
  { value: '45121716', label: 'Contact printer' },
  { value: '45121717', label: 'Film platemaking system' },
  { value: '45121718', label: 'Film densitometer' },
  { value: '45121719', label: 'Digital photo printing kiosk' },
  { value: '45121720', label: 'Film loader' },
  { value: '45131506', label: 'Slide film' },
  { value: '45131507', label: 'Processed microfilm' },
  { value: '45131508', label: 'Negative film' },
  { value: '45131509', label: 'Film spotting color' },
  { value: '45131510', label: 'Photographic dye' },
  { value: '45141604', label: 'Photographic print processor' },
  { value: '45141605', label: 'Photograph purifier' },
  { value: '45141606', label: 'Developing tank reel' },
  { value: '45141607', label: 'Camera obscura' },
  { value: '45141608', label: 'Film squeegee' },
  { value: '45141609', label: 'Film developing machine worktable' },
  { value: '45141610', label: 'Photographic timer' },
  { value: '45141611', label: 'Photographic processing temperature regulator' },
  { value: '45141612', label: 'Portable darkroom' },
  { value: '46101802', label: 'Cartridge belt' },
  { value: '46151609', label: 'Identification card discriminator' },
  { value: '46151716', label: 'Lie detector' },
  { value: '46161516', label: 'License plate recognition system' },
  { value: '46161528', label: 'Airport warning light' },
  { value: '46161605', label: 'Life buoy' },
  { value: '46161702', label: 'Rescue air bag' },
  { value: '46161703', label: 'Safety air mat' },
  { value: '46161704', label: 'Rescue line launcher' },
  { value: '46161705', label: 'Hydraulic rescue equipment set' },
  { value: '46161706', label: 'Air tent' },
  { value: '46161707', label: 'Rescue door opener' },
  { value: '46161708', label: 'Rescue net' },
  { value: '46161710', label: 'Rescue light' },
  { value: '46161711', label: 'Life detector' },
  { value: '46161712', label: 'Personal safety alert' },
  { value: '46161713', label: 'Smoke signal device' },
  { value: '46161714', label: 'Life line' },
  { value: '46161715', label: 'Confined space rescue equipment' },
  { value: '46171623', label: 'Home security alarm' },
  { value: '46171624', label: 'X ray baggage inspection system' },
  { value: '46171625', label: 'Radio communication monitoring system' },
  { value: '46171626', label: 'Night vision equipment' },
  { value: '46171627', label: 'Wireless signaler system for hearing impaired' },
  { value: '46171628', label: 'Automatic time of day indicator' },
  { value: '46171629', label: 'Signal mirror' },
  { value: '46171630', label: 'Vehicle rain and water level sensor' },
  { value: '46171631', label: 'Seismic alarm' },
  { value: '46171632', label: 'Passive infrared sensor' },
  { value: '46171633', label: 'Security metal detector' },
  { value: '46171634', label: 'Chemical agent detector paper' },
  { value: '46171635', label: 'Chemical agent detector' },
  { value: '46171636', label: 'Security facsimile transmission equipment' },
  { value: '46171637', label: 'Wiretap device' },
  { value: '46171638', label: 'Radiation detector and alarm' },
  { value: '46171639', label: 'Traffic video compensator' },
  { value: '46171640', label: 'Automatic traffic monitoring system' },
  { value: '46181612', label: 'Military boot' },
  { value: '46182504', label: 'Fire alarm control panel' },
  { value: '46182505', label: 'Earth leakage detector and fire alarm' },
  { value: '46191509', label: 'Toxic vapor detector' },
  { value: '46191510', label: 'Thermal observation device TOD' },
  { value: '46191511', label: 'Fire hot spot detector' },
  { value: '46191613', label: 'Fire extinguishing agent' },
  { value: '46191614', label: 'Fire foam liquid proportioner' },
  { value: '46191615', label: 'Firefighting standpipe' },
  { value: '46191616', label: 'Helicopter fire extinguishing water tank' },
  { value: '46191617', label: 'Fire hose washer' },
  { value: '46191618', label: 'Fire extinguisher base' },
  { value: '46191619', label: 'Fire water monitor' },
  { value: '46191620', label: 'Smoke exhaust fan' },
  { value: '46191621', label: 'Fire extinguisher storage box' },
  {
    value: '46200000',
    label:
      'Defense and law enforcement and security and safety training equipment'
  },
  { value: '46201000', label: 'Public safety training equipment' },
  { value: '46201001', label: 'Rescue mannequin' },
  {
    value: '46201002',
    label: 'Disaster management training simulation system'
  },
  { value: '46201100', label: 'Small arms weapons training equipment' },
  { value: '46201101', label: 'Imitation rifle' },
  { value: '46201102', label: 'Firing or shooting range' },
  { value: '47101614', label: 'Sludge bulking agent' },
  { value: '47111507', label: 'Laundry drying stand' },
  { value: '47111509', label: 'Laundry work table' },
  { value: '47121815', label: 'Pool cleaner' },
  { value: '48101546', label: 'Commercial decoction machine' },
  { value: '48101619', label: 'Water sterilization system' },
  { value: '48101716', label: 'Water purifier' },
  { value: '48102009', label: 'Food serving or dispensing table' },
  { value: '48102010', label: 'Cup collection cart' },
  { value: '48130000', label: 'Funeral equipment and materials' },
  { value: '48131500', label: 'Burial or grave products' },
  { value: '48131502', label: 'Coffin' },
  { value: '49141508', label: 'Diving boot' },
  { value: '49141509', label: 'Diver hood' },
  { value: '49211808', label: 'Obesity measurement tool' },
  { value: '49211809', label: 'Body measurement tool' },
  { value: '50201715', label: 'Fruit tea' },
  { value: '55101532', label: 'Graduation album or yearbook' },
  { value: '56101718', label: 'Newspaper rack' },
  { value: '56101719', label: 'Side table' },
  { value: '56121510', label: 'Teacher desk' },
  { value: '56140000', label: 'Decorative adornments' },
  { value: '60131703', label: 'Horses hoofs' },
  { value: '92121800', label: 'Armored car services and money transport' },
  { value: '92121802', label: 'Money transport service' },
  { value: '95121909', label: 'Elementary school' },
  { value: '95121910', label: 'Junior high or middle school' },
  { value: '95121911', label: 'High school' },
  { value: '95121913', label: 'University' },
  { value: '10151536', label: 'Caigua seeds or seedlings' },
  { value: '10151537', label: 'Asparagus seeds or seedlings' },
  { value: '10151538', label: 'Chickpea seeds or seedlings' },
  { value: '10151539', label: 'Fava or broad bean seeds or seedlings' },
  { value: '10151612', label: 'Kiwicha seeds or seedlings' },
  { value: '10151613', label: 'Quinoa seeds or seedlings' },
  { value: '10171506', label: 'Humus' },
  { value: '11172200', label: 'Magnesium based alloys' },
  { value: '11172300', label: 'Manganese based alloys' },
  { value: '12165100', label: 'Chemicals for enhanced oil recovery' },
  { value: '14111828', label: 'Business letterhead paper' },
  { value: '15101513', label: 'Diesel fuel off road' },
  { value: '25172908', label: 'Vehicle rear light' },
  { value: '30131517', label: 'Adobe block' },
  { value: '30171712', label: 'Cathedral glass' },
  { value: '41112521', label: 'Calibration column' },
  {
    value: '42131512',
    label: 'Patient infant caps or hats or material for infant caps'
  },
  { value: '42142620', label: 'Intraosseous needle drivers or injection guns' },
  { value: '42142721', label: 'Urological guidewires or glidewires' },
  { value: '42182707', label: 'Inclinometers' },
  { value: '42203430', label: 'Electrophysiology catheters' },
  { value: '42203804', label: 'Radiation therapy positioning aids' },
  {
    value: '42204100',
    label: 'Nuclear medicine hot lab equipment and related products'
  },
  {
    value: '42204101',
    label: 'Medical radiological syringe or vial shield or holders or carriers'
  },
  { value: '42292803', label: 'Biopsy sealing and marking devices' },
  { value: '42295471', label: 'Bone marrow aspiration systems' },
  { value: '42311543', label: 'Dressing retainers' },
  { value: '43212200', label: 'Computer data storage management systems' },
  { value: '43212201', label: 'Storage virtualization system' },
  { value: '44122034', label: 'Book page separator' },
  { value: '45121627', label: 'Camera flash diffuser' },
  { value: '45121628', label: 'Camera lens filter' },
  { value: '45121629', label: 'Camera track motor' },
  { value: '45121630', label: 'Camera parasol reflector' },
  { value: '50221304', label: 'Potato flour' },
  { value: '53131647', label: 'Infant hygiene and personal care set' },
  {
    value: '72101516',
    label: 'Fire extinguisher inspection maintenance and repair service'
  },
  {
    value: '72101517',
    label: 'Portable generator maintenance and or repair service'
  },
  { value: '72101518', label: 'Portable generator rental service' },
  {
    value: '72121510',
    label:
      'Floating oil and gas production storage and offloading system construction service'
  },
  {
    value: '72121511',
    label:
      'Offshore oil and gas production facility hookup and commissioning service'
  },
  {
    value: '72121512',
    label:
      'Offshore oil and gas production facility equipment installation and integration service'
  },
  { value: '72121513', label: 'Oil and gas plant modular fabrication service' },
  {
    value: '72121514',
    label: 'Oil and gas production platform and topside fabrication service'
  },
  {
    value: '72121515',
    label: 'Floating oil and gas production system hull fabrication service'
  },
  {
    value: '72121516',
    label:
      'Fixed oil and gas offshore production facilities fabrication service'
  },
  {
    value: '72121517',
    label: 'Liquid natural gas LNG plant construction service'
  },
  { value: '72141216', label: 'Offshore construction vessel service' },
  {
    value: '72151207',
    label:
      'Heating and cooling and air conditioning HVAC installation and maintenance service'
  },
  {
    value: '72151514',
    label: 'Standby or emergency power maintenance service'
  },
  { value: '72151515', label: 'Electrical inspection service' },
  {
    value: '72151608',
    label: 'Satellite system maintenance or repair service'
  },
  { value: '72151609', label: 'Satellite system hub support service' },
  {
    value: '72151704',
    label: 'Safety instrumented system installation and maintenance service'
  },
  {
    value: '72151803',
    label: 'Vending machine installation and maintenance service'
  },
  {
    value: '72153303',
    label: 'Service station equipment maintenance and or repair service'
  },
  {
    value: '72153613',
    label: 'Office furniture lease and maintenance service'
  },
  { value: '72154054', label: 'Catalyst loading or removal service' },
  { value: '72154055', label: 'Tank cleaning service' },
  { value: '72154056', label: 'Tank maintenance or repair service' },
  { value: '72154057', label: 'Storage tank rental service' },
  { value: '72154058', label: 'Tank and line testing service' },
  { value: '72154059', label: 'Leak detection sealing and repair service' },
  { value: '72154060', label: 'Degassing service' },
  { value: '72154061', label: 'Air filtration service' },
  { value: '72154062', label: 'Hot tapping service' },
  { value: '72154063', label: 'Stopple or line stopping service' },
  { value: '72154064', label: 'Portable lighting equipment rental service' },
  {
    value: '72154100',
    label:
      'Distribution and conditioning system equipment maintenance and repair services'
  },
  { value: '72154101', label: 'Air compressor rental and maintenance service' },
  { value: '72154102', label: 'Heat exchanger maintenance or repair service' },
  { value: '72154103', label: 'Rotary pump maintenance or repair service' },
  { value: '72154104', label: 'Gearbox maintenance and repair service' },
  { value: '72154105', label: 'Control valve maintenance and repair service' },
  {
    value: '72154106',
    label: 'Reciprocating compressor rental and maintenance service'
  },
  {
    value: '72154107',
    label: 'Centrifugal compressor rental and maintenance service'
  },
  {
    value: '72154108',
    label: 'Reciprocating pump maintenance or repair service'
  },
  {
    value: '72154109',
    label: 'Centrifugal pump maintenance or repair service'
  },
  {
    value: '72154110',
    label: 'Fin fan cooling tower maintenance and repair service'
  },
  {
    value: '72154200',
    label: 'Instrumentation installation maintenance and repair services'
  },
  {
    value: '72154300',
    label:
      'Motive and electrical power generation equipment maintenance and repair services'
  },
  {
    value: '72154301',
    label: 'Turbine equipment maintenance or repair service'
  },
  {
    value: '72154400',
    label: 'Pipefitting fabrication and maintenance services'
  },
  { value: '72154401', label: 'Pipefitting fabrication service' },
  { value: '72154402', label: 'Pipefitting maintenance or repair service' },
  {
    value: '72154500',
    label: 'Heavy equipment installation and maintenance services'
  },
  { value: '72154502', label: 'Crane maintenance and repair service' },
  { value: '72154503', label: 'Crane rental service' },
  {
    value: '73152109',
    label: 'Industrial weight scale maintenance and rental service'
  },
  { value: '77102000', label: 'Environmental reporting services' },
  { value: '77102002', label: 'Emission reporting compliance service' },
  {
    value: '77102004',
    label: 'Waste generation or disposal reporting service'
  },
  { value: '78102103', label: 'Pipeline inline inspection service' },
  {
    value: '78111705',
    label: 'Oil and gas offshore platform personnel transportation service'
  },
  { value: '78111810', label: 'Limousine or town car service' },
  {
    value: '78111811',
    label: 'Vehicle leasing of light trucks and sport utility vehicles'
  },
  { value: '78111812', label: 'Vehicle leasing of passenger vans or minivans' },
  { value: '78131702', label: 'Above ground storage or tankage service' },
  {
    value: '78141504',
    label: 'Supplier or vendor managed freight and rebilling'
  },
  { value: '78141704', label: 'Dead man anchor service' },
  { value: '78141804', label: 'Loading terminal facility management' },
  {
    value: '78141805',
    label: 'Aerodrome or airport or aviation facility operations service'
  },
  {
    value: '78141806',
    label: 'Oil and gas offshore support shore base service'
  },
  { value: '78141900', label: 'Transport container rental services' },
  { value: '78181509', label: 'Rail car inspection and maintenance service' },
  {
    value: '78181800',
    label: 'Fixed wing aircraft maintenance and repair services'
  },
  { value: '80101511', label: 'Human resources consulting service' },
  { value: '80131504', label: 'Offshore temporary housing service' },
  { value: '80131505', label: 'Portable or modular office rental service' },
  { value: '80131506', label: 'Portable toilet rental service' },
  { value: '80141630', label: 'Direct marketing print service' },
  {
    value: '81101713',
    label:
      'Electronic measurement and recording instrument engineering and design service'
  },
  { value: '81112221', label: 'Point of sale software maintenance service' },
  {
    value: '81112222',
    label: 'Facility operation and maintenance management software maintenance'
  },
  {
    value: '81112308',
    label: 'Point of sale hardware maintenance and support service'
  },
  {
    value: '81112309',
    label: 'Point of sale hardware installation or implementation service'
  },
  {
    value: '81141900',
    label: 'Manufacturing technology research and development services'
  },
  { value: '81141901', label: 'Product research and development service' },
  {
    value: '81141902',
    label: 'Application or technology research and development service'
  },
  { value: '86132000', label: 'Management education and training services' },
  { value: '86132001', label: 'Executive coaching service' },
  { value: '10101518', label: 'Alpaca' },
  { value: '10101607', label: 'Live guinea fowl' },
  { value: '10131605', label: 'Animal transport cage' },
  { value: '10151540', label: 'Centrocema or butterfly pea seed or seedlings' },
  { value: '10151541', label: 'Nettle seed or seedlings' },
  { value: '10152008', label: 'Coffee seed or seedlings' },
  { value: '10152048', label: 'Castor oil bush seed or cutting' },
  { value: '11121625', label: 'Eucalyptus wood' },
  { value: '11121624', label: 'Copaiba wood' },
  { value: '11121623', label: 'Capirona wood' },
  { value: '11121622', label: 'Catahua wood' },
  { value: '11121621', label: 'Pashaco wood' },
  { value: '11131609', label: 'Bovine semen' },
  { value: '14111544', label: 'Security paper' },
  { value: '14111829', label: 'Pre-printed notepad' },
  { value: '14121510', label: 'Test liner paperboard' },
  {
    value: '14121509',
    label: 'Composite paper or paperboard without surface coating'
  },
  { value: '14121508', label: 'Kaolin treated paperboard' },
  { value: '14121813', label: 'Satin paper' },
  { value: '14122204', label: 'Impressed stamp paper' },
  { value: '21101916', label: 'Milking machine parts and accessories' },
  {
    value: '21102009',
    label: 'Cleaning, sorting, and grading machine parts and accessories'
  },
  { value: '22101539', label: 'Earthmoving machinery parts and accessories' },
  { value: '24111515', label: 'Unwoven fabric bag' },
  { value: '25101614', label: 'Load motovan' },
  { value: '25181720', label: 'Trailer and semi-trailer parts' },
  { value: '30181702', label: 'Faucet unit' },
  { value: '31211523', label: 'High temperature paint' },
  { value: '40101723', label: 'Thermoelectric cooler' },
  { value: '41104023', label: 'Water sampler accessories' },
  {
    value: '41104214',
    label: 'Reverse osmosis equipment parts and accessories'
  },
  { value: '41111652', label: 'Compressometer' },
  { value: '41115340', label: 'Photometer accessories' },
  { value: '41115417', label: 'Atomic absorption AA spectrometer accessories' },
  { value: '41115418', label: 'Spectrophotometer accessories' },
  { value: '41116019', label: 'Hormone analyzer reagent' },
  { value: '41123006', label: 'Desiccator accessories' },
  { value: '43201905', label: 'Tape unit cleaning kit' },
  { value: '43211519', label: 'Electronic reader or E-reader' },
  { value: '43212003', label: 'Monitor components' },
  { value: '43231516', label: 'Audit software' },
  { value: '43231517', label: 'Procedure management software' },
  {
    value: '43232314',
    label: 'Business intelligence and data analysis software'
  },
  { value: '43232509', label: 'Voice synthesizer and recognition software' },
  { value: '43232615', label: 'Facial recognition software' },
  { value: '43232616', label: 'Legal management software' },
  { value: '43232617', label: 'Meteorological control software' },
  { value: '43232618', label: 'Radar image treatment software' },
  { value: '43232619', label: 'Satellite image treatment software' },
  { value: '43233006', label: 'Virtual machine software' },
  { value: '44101511', label: 'Mimeograph and accessories' },
  { value: '44101904', label: 'Protectograph' },
  { value: '44102504', label: 'Paper and plastic counter' },
  { value: '44103127', label: 'Photocopier toner' },
  { value: '44103207', label: 'Time card or time clock machine accessories' },
  { value: '44111523', label: 'Desk organizer accessories kit' },
  { value: '44122035', label: 'Lever arch file' },
  { value: '44122036', label: 'Box file' },
  { value: '44122037', label: 'Ring binder' },
  { value: '45101520', label: 'Industrial sign and label portable printer' },
  {
    value: '45101613',
    label: 'Industrial sign and label benchtop printer accessories'
  },
  {
    value: '45101614',
    label: 'Industrial sign and label portable printer accessories'
  },
  { value: '45101710', label: 'Industrial sign and label printer ink' },
  { value: '45101711', label: 'Industrial sign and label printer ribbon' },
  { value: '45121631', label: 'Electronic viewfinder' },
  { value: '45121632', label: 'Photographic flashlight apparatus' },
  { value: '45121721', label: 'Monocomparator and accessories' },
  { value: '46171700', label: 'Vehicle access control' },
  { value: '46171701', label: 'Vehicle parking permit' },
  { value: '46182407', label: 'Chemical spill safety kit' },
  {
    value: '46210000',
    label: 'Workplace safety equipment and supplies and training materials'
  },
  { value: '46211600', label: 'Work area marking and visual indicators' },
  { value: '46211500', label: 'Workplace safety training aids and materials' },
  { value: '46211602', label: 'Work area warning posts and chains' },
  { value: '46211601', label: 'Work area barricade tape and flags' },
  { value: '46211504', label: 'Workplace safety internet based training' },
  { value: '46211503', label: 'Workplace safety training manual or handbook' },
  { value: '46211502', label: 'Workplace safety training video' },
  { value: '46211501', label: 'Workplace safety training kit' },
  { value: '47111510', label: 'Laundry wash tub or basin' },
  { value: '47121816', label: 'Cleaning solution dispenser and accessories' },
  { value: '42143304', label: 'Chemotherapy preparation pads' },
  { value: '48101821', label: 'Commercial nut cracker or opener' },
  {
    value: '90101902',
    label: 'Coffee or hot drink vending machine rental and maintenance service'
  },
  {
    value: '90101901',
    label: 'Coffee machine and grinder rental and maintenance service'
  },
  { value: '50181710', label: 'Bakers yeast' },
  { value: '50201717', label: 'Coffee, green' },
  { value: '50221108', label: 'Rice grain' },
  { value: '53101806', label: 'Poncho' },
  { value: '53102717', label: 'Sport uniform' },
  { value: '53131649', label: 'Bath and body powder' },
  { value: '53131648', label: 'Insect repellant' },
  { value: '54111710', label: 'Watch or clock movement' },
  { value: '60101734', label: 'Flip chart' },
  {
    value: '70161705',
    label: 'Bush and forest ecology and conservation service'
  },
  {
    value: '70161711',
    label: 'Mangrove swamp ecology and conservation service'
  },
  { value: '70161710', label: 'Wetland ecology and conservation service' },
  { value: '70161706', label: 'Coastal ecology and conservation service' },
  { value: '72101521', label: 'Building framing service' },
  { value: '72101520', label: 'Roof framing service' },
  { value: '72101519', label: 'Gas fitting installation service' },
  { value: '72102906', label: 'Landscape architecture service' },
  { value: '72103305', label: 'Drain laying service' },
  {
    value: '72141130',
    label: 'Ornamental fountain construction and or remodelling service'
  },
  {
    value: '72141129',
    label: 'Park and garden construction and or remodelling service'
  },
  {
    value: '72141128',
    label: 'Public square construction and or remodelling service'
  },
  {
    value: '72141127',
    label: 'Seal coating of roads, highways and parking lots'
  },
  {
    value: '72154067',
    label: 'Temporary theatrical stage and platform rental and installation'
  },
  { value: '72154068', label: 'Graffiti removal and treatment service' },
  { value: '72154303', label: 'Steam turbine maintenance service' },
  {
    value: '72154600',
    label: 'Animal habitat and enclosure construction and maintenance services'
  },
  {
    value: '72154605',
    label: 'Construction of zoo habitat and enclosure for reptiles'
  },
  {
    value: '72154604',
    label:
      'Construction of zoo habitat and enclosure for aquatic and amphibious species'
  },
  {
    value: '72154603',
    label:
      'Construction of zoo habitat and enclosure for land mammals and primates'
  },
  {
    value: '72154602',
    label:
      'Construction of zoo habitat and enclosure for insects and invertebrates'
  },
  {
    value: '72154601',
    label:
      'Construction of zoo habitat and enclosure for birds and flying species'
  },
  { value: '72154069', label: 'Fencing and railing service' },
  {
    value: '76111507',
    label: 'Cleaning services for parks and outdoor public venues'
  },
  {
    value: '76121506',
    label: 'Abandoned vehicle recovery and disposal service'
  },
  { value: '76121505', label: 'Inorganic waste collection and disposal' },
  {
    value: '76121507',
    label: 'Post event refuse collection and clean up service'
  },
  { value: '76121504', label: 'Tire collection and disposal' },
  {
    value: '76121905',
    label: 'Inorganic hazardous waste collection and disposal'
  },
  {
    value: '77101506',
    label: 'Development consent or approval processing service'
  },
  { value: '78101505', label: 'Air transport of livestock or live animals' },
  { value: '78101504', label: 'Air transport of letters and parcels' },
  { value: '78101610', label: 'Railway transport by refrigerator car' },
  { value: '78101609', label: 'Railway transport by tanker car' },
  {
    value: '78101608',
    label: 'Railway transport services of letters and parcels'
  },
  {
    value: '78101710',
    label: 'Coastal and transoceanic water transport by refrigerator vessels'
  },
  { value: '78101709', label: 'Coastal and transoceanic transport by tankers' },
  {
    value: '78101708',
    label: 'Inland water transport by refrigerator vessels'
  },
  { value: '78101810', label: 'Road transport of livestock or live animals' },
  { value: '78101809', label: 'Road transport of letters and parcels' },
  { value: '78101808', label: 'Road transport of dry bulk' },
  { value: '78111504', label: 'Sightseeing service by air' },
  { value: '78111707', label: 'Inland water transport of passengers by ferry' },
  {
    value: '78111706',
    label: 'Coastal and transoceanic water transport of passengers by ferry'
  },
  {
    value: '78111814',
    label: 'Road transport of passengers by man-or animal-drawn vehicle'
  },
  { value: '78111813', label: 'Sightseeing service by land, except rail' },
  { value: '78131807', label: 'Critical spare part storage service' },
  {
    value: '78141505',
    label: 'Towing service for commercial and private vehicles'
  },
  { value: '78141807', label: 'Air traffic control service' },
  {
    value: '78142003',
    label:
      'Rental of freight vessel for coastal and transoceanic water transport with operator'
  },
  {
    value: '78142002',
    label: 'Rental of freight vessel for inland water transport with operator'
  },
  { value: '78142001', label: 'Rental of freight aircraft with operator' },
  { value: '78142000', label: 'Transport conveyance rental or lease services' },
  { value: '80101512', label: 'Actuarial consulting services' },
  { value: '80111719', label: 'Employee psychometric testing service' },
  { value: '80111718', label: 'Employee skill testing and assessment service' },
  { value: '80111717', label: 'Employee physical screening service' },
  { value: '80121904', label: 'Process server service' },
  { value: '80122000', label: 'Legal review and inquiry services' },
  {
    value: '80170000',
    label: 'Public relations and professional communications services'
  },
  { value: '80172100', label: 'Issues and crisis management services' },
  { value: '80172000', label: 'Professional communication services' },
  { value: '80171900', label: 'Stakeholder management and relations services' },
  { value: '80171800', label: 'Media relations services' },
  {
    value: '80171500',
    label:
      'Situation and stakeholder analysis and communications planning services'
  },
  { value: '80171600', label: 'Publicity and marketing support services' },
  { value: '80171602', label: 'Online and social media publicity service' },
  {
    value: '80172104',
    label: 'Issues management and mitigation advisory service'
  },
  {
    value: '80172103',
    label: 'Change management communication and advisory service'
  },
  { value: '80172102', label: 'Crisis planning and avoidance service' },
  { value: '80172101', label: 'Crisis management and recovery service' },
  { value: '80172001', label: 'Internal communication service' },
  { value: '80172002', label: 'Project based communications service' },
  { value: '80172003', label: 'Public affairs service' },
  {
    value: '80171909',
    label: 'Business and utility provider relations consultation and engagement'
  },
  {
    value: '80171908',
    label: 'Not for profit organization relations consultation and engagement'
  },
  {
    value: '80171907',
    label: 'Community relations consultation and engagement'
  },
  {
    value: '80171906',
    label: 'Government relations consultation and engagement'
  },
  {
    value: '80171905',
    label: 'Indigenous peoples relations consultation and engagement'
  },
  {
    value: '80171904',
    label: 'Cultural and ethnic group relations consultation and engagement'
  },
  {
    value: '80171903',
    label: 'Internal stakeholder relations consultation and engagement'
  },
  {
    value: '80171902',
    label: 'Investor and shareholder relations consultation and engagement'
  },
  { value: '80171803', label: 'Media training and coaching service' },
  { value: '80171802', label: 'Media relations and advisory service' },
  { value: '80171801', label: 'Media monitoring service' },
  { value: '80171505', label: 'Communication planning' },
  { value: '80171504', label: 'Stakeholder analysis' },
  {
    value: '80171503',
    label: 'Public relations situation and issues and risk analysis'
  },
  {
    value: '80171502',
    label: 'Focus group and public feedback meeting facilitation and analysis'
  },
  {
    value: '80171501',
    label: 'Polling and survey and public opinion monitoring and analysis'
  },
  { value: '81101529', label: 'Acoustic engineering' },
  { value: '81101528', label: 'Stormwater engineering' },
  { value: '81101527', label: 'Wastewater engineering' },
  { value: '81101526', label: 'Quantity surveying service' },
  { value: '81101525', label: 'Sediment control engineering' },
  { value: '81101524', label: 'City development planning service' },
  { value: '81101523', label: 'Urban design and engineering service' },
  { value: '81101522', label: 'Earthworks engineering' },
  { value: '81101521', label: 'Hydrogeology assessment service' },
  { value: '81101520', label: 'Hydrology assessment service' },
  { value: '81101519', label: 'Subdivison planning service' },
  { value: '81101518', label: 'Lighting engineering service' },
  { value: '81101517', label: 'Landscape architecture and design service' },
  {
    value: '81102503',
    label: 'Building consent processing and support service'
  },
  {
    value: '81102502',
    label: 'Building consent and permit engineering peer review service'
  },
  { value: '81170000', label: 'Biological science services' },
  { value: '81171900', label: 'Aerobiological science services' },
  { value: '81171800', label: 'Agricultural science services' },
  { value: '81171700', label: 'Botanical science services' },
  { value: '81171600', label: 'Ecological science services' },
  { value: '81171500', label: 'Marine biology services' },
  { value: '81171901', label: 'Air quality science service' },
  { value: '81171801', label: 'Agricultural science consultation service' },
  { value: '81171702', label: 'Arboricultural science service' },
  { value: '81171701', label: 'Horticultural science service' },
  { value: '81171601', label: 'Aquatic ecology service' },
  { value: '81171501', label: 'Marine biology consultation service' },
  {
    value: '82112067',
    label: 'In person sign language interpretation service'
  },
  {
    value: '82151512',
    label:
      'Curatorial service for art gallery and museum exhibitions and collections'
  },
  {
    value: '82151511',
    label:
      'Technical service for art gallery and museum exhibitions and collections'
  },
  { value: '82151510', label: 'Art installation and picture hanging service' },
  {
    value: '82151509',
    label:
      'Public or outdoor artwork or decorative fixture installation and maintenance'
  },
  { value: '84121706', label: 'Financial asset management service' },
  {
    value: '86132100',
    label: 'Training planning, facilitation and delivery services'
  },
  {
    value: '86132102',
    label: 'Training planning and development consultancy service'
  },
  { value: '86132101', label: 'Training facilitation service' },
  { value: '90101605', label: 'Food service for transportation operators' },
  { value: '90111900', label: 'Specialized accommodation services' },
  {
    value: '90111902',
    label: 'Student residential room or unit accommodation service'
  },
  {
    value: '90111901',
    label: 'Worker worksite room or unit accommodation service'
  },
  {
    value: '90101900',
    label:
      'Food and beverage equipment rental or leasing and maintenance services'
  },
  {
    value: '92101505',
    label: 'Graffiti and vandalism deterrence support service'
  },
  { value: '95121647', label: 'Elevated roadway or highway' },
  { value: '95121715', label: 'Public toilet facility' },
  { value: '95121714', label: 'Public changing room and shower facility' },
  {
    value: '95121811',
    label: 'Aquaduct or other water supply conduit, except pipeline'
  },
  { value: '95121810', label: 'Irrigation and flood control waterworks' },
  { value: '10151616', label: 'Teff seed' },
  { value: '10152057', label: 'Cassava seed or cutting' },
  { value: '10152055', label: 'Date palm cutting' },
  { value: '10152056', label: 'Oil palm seed' },
  { value: '10171507', label: 'Urea fertilizer' },
  {
    value: '10171613',
    label: 'Ammonium nitrate mixed with calcium carbonate fertilizer'
  },
  { value: '10171616', label: 'Diammonium hydrogenorthophosphate fertilizer' },
  { value: '10171612', label: 'Superphosphate fertilizer' },
  { value: '10191515', label: 'Chlorphyriphos' },
  { value: '10191513', label: 'Deltamethrin' },
  { value: '10191514', label: 'Fenitrothion' },
  { value: '10191516', label: 'Malathion' },
  { value: '14111545', label: 'Wide format printer paper' },
  { value: '14111830', label: 'Engrossing paper' },
  { value: '14111831', label: 'Visitor or guest book' },
  { value: '23181522', label: 'Seed or nut sheller' },
  { value: '23181523', label: 'Slaughterhouse or abattoir equipment' },
  { value: '24113108', label: 'Insulated box for vaccines' },
  { value: '24102400', label: 'Waste material handling and recycling systems' },
  { value: '24102402', label: 'Stationary waste compactor' },
  { value: '24102401', label: 'Vertical waste compactor' },
  { value: '24113107', label: 'Box partition' },
  { value: '24113100', label: 'Boxes' },
  { value: '24113106', label: 'Tool box' },
  { value: '24121514', label: 'Insulation pack' },
  { value: '24131515', label: 'Pharmaceutical refrigerator and freezer' },
  { value: '25101510', label: 'Armored motor vehicle' },
  { value: '25101706', label: 'Fire fighting chemical truck' },
  { value: '25101707', label: 'Fire fighting ladder truck' },
  { value: '25101704', label: 'Fire fighting pump truck' },
  {
    value: '25101711',
    label: 'Fire fighting truck with remote control nozzle'
  },
  { value: '25101705', label: 'Fire fighting water tank truck' },
  { value: '25101708', label: 'Rescue truck' },
  { value: '25101709', label: 'Smoke exhaust truck' },
  { value: '25101710', label: 'Smoke exhaust truck with lighting' },
  { value: '25101939', label: 'Trailer, mobile classroom' },
  {
    value: '25101940',
    label: 'Utility task vehicle UTV or recreational off highway vehicle ROV'
  },
  { value: '25111809', label: 'Skiff' },
  { value: '25172514', label: 'Motorcycle tire inner tube' },
  { value: '25101712', label: 'Fire investigation car' },
  { value: '30121720', label: 'Drain pipe odor suppression device' },
  { value: '30121721', label: 'Drainage earth and sand barrel' },
  { value: '30131518', label: 'Concrete block for bridges' },
  { value: '30131611', label: 'Wood brick' },
  { value: '30171527', label: 'Melamine door' },
  { value: '46161534', label: 'Vehicle parking concave plate' },
  { value: '39111617', label: 'Renewable energy street lighting' },
  { value: '41101519', label: 'Laboratory cell disruptor' },
  { value: '41102430', label: 'Laboratory general purpose heat sealer' },
  { value: '41102610', label: 'Animal for research testing' },
  { value: '41102614', label: 'Research animal bedding material' },
  { value: '41102611', label: 'Research animal food and diet' },
  { value: '41102612', label: 'Research animal induction chamber' },
  { value: '41102613', label: 'Research animal physiological test kit' },
  { value: '41102928', label: 'Automated microscope slide labeler' },
  {
    value: '41103034',
    label: 'Cryobead system for microbial organism cryogenic storage'
  },
  { value: '41103030', label: 'Cryogenic storage bag or overwrap' },
  { value: '41103029', label: 'Cryogenic storage cane' },
  {
    value: '41103032',
    label: 'Cryogenic tube or vial permanent marker or label'
  },
  { value: '41103035', label: 'Fresh frozen plasma storage carton or frame' },
  { value: '41103031', label: 'Insulated transport cooler or tote' },
  { value: '41103033', label: 'Laboratory dewar flask' },
  { value: '41103028', label: 'Liquid nitrogen measuring stick' },
  {
    value: '41103037',
    label: 'Polymerase chain reaction PCR tube strip and plate cooler'
  },
  { value: '41103036', label: 'Refrigerated specimen storage rack or tray' },
  {
    value: '41103213',
    label: 'Laboratory surface and glassware decontaminant'
  },
  { value: '41103328', label: 'Vacuum based pipette aspirator system' },
  { value: '41103517', label: 'Biological safety cabinet' },
  { value: '41103718', label: 'Histology tissue freezing bath' },
  { value: '41103818', label: 'Microplate shaker' },
  { value: '41103819', label: 'Tissue culture roller drum' },
  {
    value: '41103916',
    label: 'Centrifuge control board or printed circuit board'
  },
  { value: '41103917', label: 'Centrifuge microplate carrier or sealing lid' },
  {
    value: '41103915',
    label: 'Cytocentrifuge cytofunnel or filter card or clamp'
  },
  {
    value: '41104137',
    label: 'Arterial and capillary blood gas collection kit'
  },
  { value: '41104130', label: 'Blood culture collection kit' },
  { value: '41104131', label: 'Pinworm collection paddle or device' },
  {
    value: '41104134',
    label: 'Refrigerant pack for diagnostic specimen shippers'
  },
  { value: '41104136', label: 'Specimen drop box' },
  {
    value: '41104135',
    label: 'Template bleeding time incision device and blotting paper'
  },
  { value: '41104133', label: 'Umbilical cord blood collector' },
  { value: '41104132', label: 'Urine strainer for renal calculi' },
  { value: '41104309', label: 'Microaerophilic environmental cutlure system' },
  { value: '41104932', label: 'Cell strainer' },
  { value: '41104931', label: 'Serum separator tube filter' },
  { value: '41105344', label: 'Electrophoresis sample applicator or blade' },
  {
    value: '41105342',
    label: 'Kit and reagent for agarose gel electrophoresis'
  },
  {
    value: '41105343',
    label: 'Kit and reagent for tape based electrophoresis'
  },
  { value: '41105345', label: 'Protein gel stain' },
  { value: '41106232', label: 'Automated microbial culture plate streaker' },
  { value: '41106224', label: 'Electroporation system' },
  { value: '41111766', label: 'Handheld digital microscope' },
  { value: '41111768', label: 'Lighted box agglutination viewer' },
  { value: '41111769', label: 'Lighted mirror agglutination viewer' },
  { value: '41111753', label: 'Manual microscope nosepiece' },
  { value: '41111750', label: 'Microscope anti vibration mat or table' },
  { value: '41111756', label: 'Microscope base unit' },
  { value: '41111758', label: 'Microscope body' },
  { value: '41111763', label: 'Microscope cleaning kit' },
  { value: '41111752', label: 'Microscope filter cube or box' },
  { value: '41111764', label: 'Microscope fitting' },
  { value: '41111751', label: 'Microscope fluorescence filter' },
  { value: '41111757', label: 'Microscope head' },
  { value: '41111755', label: 'Microscope knob' },
  { value: '41111770', label: 'Microscope pointer' },
  {
    value: '41111760',
    label: 'Microscope sample manipulation system and accessories'
  },
  { value: '41111765', label: 'Microscope stage slide clip' },
  {
    value: '41111767',
    label: 'Microscope stage warming system or incubator and accessories'
  },
  { value: '41111754', label: 'Motorized microscope nosepiece' },
  {
    value: '41111761',
    label: 'Transmission electron microscopy TEM grid or support film'
  },
  {
    value: '41111762',
    label: 'Transmission electron microscopy TEM grid storage box'
  },
  { value: '41111759', label: 'Trinocular light compound microscope' },
  { value: '41111981', label: 'Blood utilization management system' },
  {
    value: '41111979',
    label: 'Data logger for clinical temperature controlled equipment'
  },
  {
    value: '41111980',
    label:
      'Remote monitoring system for clinical temperature controlled equipment'
  },
  { value: '41112244', label: 'Calibration reference thermometer' },
  { value: '41112243', label: 'Laboratory heat block thermometer' },
  { value: '41112241', label: 'Laboratory oven thermometer' },
  { value: '41112242', label: 'Laboratory waterbath thermometer' },
  { value: '41112522', label: 'Water meter tester' },
  { value: '41115841', label: 'Automated blood culture system' },
  {
    value: '41115842',
    label: 'Automated blood culture system analyzer accessories or supplies '
  },
  {
    value: '41115853',
    label: 'Automated high performance chromatography HPLC analyzer system'
  },
  {
    value: '41115854',
    label:
      'Automated high performance chromatography HPLC analyzer system accessories'
  },
  { value: '41115868', label: 'Automated quality control manager system' },
  { value: '41115866', label: 'Biomagnetic separation analyzer' },
  { value: '41115867', label: 'Biomagnetic separation analyzer accessories' },
  { value: '41115845', label: 'Cell metabolism analyzer' },
  { value: '41115846', label: 'Cell metabolism analyzer accessories' },
  { value: '41115849', label: 'Electrolyte analyzer' },
  { value: '41115857', label: 'Flame photometer analyzer' },
  { value: '41115858', label: 'Flame photometer analyzer accessories' },
  { value: '41115852', label: 'Flow cytometry analyzer' },
  {
    value: '41115859',
    label: 'Gas-liquid chromatography and mass spectrometry analyzer'
  },
  { value: '41115860', label: 'Genomic analysis analyzer' },
  { value: '41115861', label: 'Genomic analysis analyzer accessories' },
  { value: '41115843', label: 'Molecular diagnostics analyzer' },
  { value: '41115844', label: 'Molecular diagnostics analyzer accessories' },
  { value: '41115864', label: 'Multiplex analyzer' },
  { value: '41115865', label: 'Multiplex analyzer accessories' },
  {
    value: '41115863',
    label:
      'Nucleic acid extraction isolation and purification analyzer accessories'
  },
  {
    value: '41115862',
    label: 'Nucleic acid extraction, isolation and purification analyzer'
  },
  {
    value: '41115850',
    label: 'Pipettor delivery calibration and verification analyzer'
  },
  {
    value: '41115851',
    label: 'Pipettor delivery calibration and verification analyzer accessories'
  },
  { value: '41115847', label: 'Sweat chloride iontophoresis analyzer' },
  {
    value: '41115848',
    label: 'Sweat chloride iontophoresis analyzer accessories'
  },
  { value: '41115855', label: 'Transplant diagnostics analyzer' },
  { value: '41115856', label: 'Transplant diagnostics analyzer accessories' },
  {
    value: '41116021',
    label: 'Automated blood culture system analyzer reagent'
  },
  {
    value: '41116023',
    label:
      'Automated high performance chromatography HPLC analyzer system reagent or kit'
  },
  { value: '41116025', label: 'Flame photometer analyzer reagent or kit' },
  {
    value: '41116026',
    label: 'Gas-liquid chromatography and mass spectrometry analyzer reagent'
  },
  {
    value: '41116027',
    label:
      'Nucleic acid extraction isolation and purification analyzer reagent and kit'
  },
  {
    value: '41116022',
    label: 'Pipettor delivery calibration and verification analyzer reagent'
  },
  { value: '41116024', label: 'Transplant analyzer reagent or kit' },
  { value: '41116020', label: 'Virology analyzer reagent' },
  {
    value: '41116162',
    label: 'Androgeny and fertility reagent or solution or stain'
  },
  { value: '41116158', label: 'Enzyme linked immunosorbent assay ELISA kit' },
  { value: '41116165', label: 'Manual blood culture system' },
  {
    value: '41116166',
    label: 'Ph meter and conductivity meter calibrant and standard'
  },
  {
    value: '41116163',
    label: 'Pipettor delivery manual calibration and verification test kit'
  },
  {
    value: '41116161',
    label:
      'Primary and secondary antibodies for multiple methodology immunostaining detection application'
  },
  {
    value: '41116167',
    label: 'Radio labeled chemical for radiometric detection'
  },
  {
    value: '41116164',
    label:
      'Test kit or probe for laboratory proficiency assessment or laboratory performance improvement tracking'
  },
  { value: '41116160', label: 'Transplant diagnostics reagent or solution' },
  { value: '41116159', label: 'Transplant diagnostics test kit' },
  { value: '41116222', label: 'Blood bilirubin test monitor or meter' },
  {
    value: '41116223',
    label: 'Blood bilirubin test monitor or meter accessories'
  },
  { value: '41116216', label: 'Blood lead test monitor or meter' },
  { value: '41116217', label: 'Blood lead test monitor or meter accessories' },
  { value: '41116218', label: 'Cardiac marker panel test monitor or meter' },
  {
    value: '41116219',
    label: 'Cardiac marker panel test monitor or meter accessories'
  },
  {
    value: '41116224',
    label: 'Glucose and beta hemoglobin test monitor or meter accessories'
  },
  { value: '41116215', label: 'Glucose test monitor or meter accessories' },
  { value: '41116213', label: 'Glycosolated hemoglobin HBA1C test meter' },
  {
    value: '41116214',
    label: 'Glycosolated hemoglobin HBA1C test meter accessories'
  },
  {
    value: '41116220',
    label: 'Lipid profile or at risk liver enzyme test monitor or meter'
  },
  {
    value: '41116221',
    label:
      'Lipid profile or at risk liver enzyme test monitor or meter accessories'
  },
  { value: '41121518', label: 'Aspirating pipette' },
  { value: '41121521', label: 'Automated liquid handling system accessories' },
  { value: '41121520', label: 'Automated vial or tube decapper recapper' },
  { value: '41121522', label: 'Pipettor reagent reservoir' },
  { value: '41121519', label: 'Positive displacment repeating pipettor' },
  { value: '41121610', label: 'Repeating pipettor reservoir pipette tip' },
  { value: '41121714', label: 'Cluster tube or tube strip or cap' },
  { value: '41121715', label: 'Specimen transport tube or aliquot tube' },
  { value: '41121820', label: 'Laboratory carboy or spout' },
  { value: '41121821', label: 'Vial closure cap or seal or stopper' },
  { value: '41122113', label: 'Capillary pipette or tube puller' },
  { value: '41122112', label: 'Cell culture glass capillary tube' },
  { value: '41122114', label: 'In situ culture harvester' },
  { value: '41122111', label: 'Tissue culture chambered slide' },
  { value: '41122417', label: 'Blood drop dispenser device' },
  { value: '41122418', label: 'Donor blood unit segment sampler' },
  { value: '41122421', label: 'Flask ring stabilizer or weight' },
  { value: '41122420', label: 'Laboratory bottle carrier' },
  { value: '41122419', label: 'Laboratory scoop' },
  { value: '41122416', label: 'Scalpel blade and knife blade remover' },
  { value: '41122608', label: 'Hemocytometer cover glass' },
  { value: '41123106', label: 'Dialysis flask' },
  {
    value: '41123204',
    label: 'Microbial quality control QC reference organism'
  },
  { value: '41123203', label: 'Virology stock control culture' },
  {
    value: '41123312',
    label: 'Bioprocess container or bag for molecular biology platform'
  },
  { value: '41123310', label: 'Blood collection tube dispenser' },
  { value: '41123313', label: 'Histology specimen container storage tray' },
  { value: '41123307', label: 'Histology tissue cassette storage tray or box' },
  {
    value: '41123314',
    label: 'Microscope slide and tissue cabinet marker and accessories'
  },
  { value: '41123309', label: 'Pasteur or transfer pipette dispenser' },
  {
    value: '41123311',
    label: 'Phlebotomy area organization rack and dispenser'
  },
  { value: '41123308', label: 'Pipette storage canister or box' },
  { value: '43211520', label: 'Barebone computer' },
  { value: '43232510', label: 'Geographic information system' },
  { value: '44101608', label: 'Paper shredder bag' },
  { value: '45101616', label: 'Offset printing cleaning accessories' },
  { value: '45101615', label: 'Offset printing machine parts' },
  { value: '45111725', label: 'Acoustic box' },
  { value: '46151508', label: 'Helmet, bullet proof' },
  { value: '46171525', label: 'Key card for lock' },
  { value: '47121710', label: 'Food waste meter' },
  { value: '50500000', label: 'Nutritional supplements' },
  { value: '50501700', label: 'Macro nutrient supplements' },
  { value: '50501600', label: 'Nutritional mineral supplements' },
  { value: '50501501', label: 'Vitamin A supplement' },
  { value: '50501509', label: 'Vitamin B12 supplement' },
  { value: '50501506', label: 'Vitamin B6 supplement' },
  { value: '50501513', label: 'Vitamin K supplement' },
  { value: '51131641', label: 'Nadroparin' },
  { value: '51111855', label: 'Lanreotide' },
  { value: '51131642', label: 'Iloprost' },
  { value: '51111856', label: 'Lapatinib' },
  { value: '51183301', label: 'Linagliptin' },
  { value: '51111746', label: 'Ipilimumab' },
  { value: '51142538', label: 'Rotigotine' },
  { value: '51111650', label: 'Pemetrexed' },
  { value: '51183611', label: 'Liraglutide' },
  { value: '51202411', label: 'Natalizumab' },
  { value: '51202412', label: 'Omalizumab' },
  { value: '51101636', label: 'Azanidazole' },
  { value: '51101680', label: 'Quinfamide' },
  { value: '51101928', label: 'Cycloguanil' },
  { value: '51281507', label: 'Ethionamide' },
  { value: '51281509', label: 'Terizidone' },
  { value: '51172200', label: 'Drugs that treat interstitial cystitis' },
  { value: '51112503', label: 'Bendamustine' },
  { value: '51113502', label: 'Piposulfan' },
  { value: '51111625', label: 'Azacitidine' },
  { value: '51111629', label: 'Clofarabine' },
  { value: '51111642', label: 'Irinotecan' },
  { value: '51111659', label: 'Topotecan' },
  { value: '51111728', label: 'Cabazitaxel' },
  { value: '51111731', label: 'Bevacizumab' },
  { value: '51111733', label: 'Bleomycin' },
  { value: '51111736', label: 'Cetuximab' },
  { value: '51111740', label: 'Daunorubicin hydrochloride' },
  { value: '51111742', label: 'Eribulin' },
  { value: '51111758', label: 'Panitumumab' },
  { value: '51111761', label: 'Ranibizumab' },
  { value: '51111765', label: 'Trabectedin' },
  { value: '51111766', label: 'Vinblastine' },
  { value: '51111767', label: 'Vincristine' },
  { value: '51111772', label: 'Vinorelbine' },
  { value: '51111830', label: 'Abiraterone' },
  { value: '51111833', label: 'Axitinib' },
  { value: '51111838', label: 'Bortezomib' },
  { value: '51111842', label: 'Degarelix' },
  { value: '51111862', label: 'Nilotinib' },
  { value: '51111863', label: 'Octreotide' },
  { value: '51112804', label: 'Raloxifene' },
  { value: '51112000', label: 'Tyrosine kinase inhibitors' },
  { value: '51112002', label: 'Dasatinib' },
  { value: '51112004', label: 'Gefitinib' },
  { value: '51112006', label: 'Pazopanib' },
  { value: '51112008', label: 'Sorafenib' },
  { value: '51112010', label: 'Vandetanib' },
  { value: '51112012', label: 'Afatinib' },
  { value: '51123501', label: 'Ivabradine' },
  { value: '51131520', label: 'Plerixafor' },
  { value: '51131621', label: 'Apixaban' },
  { value: '51131624', label: 'Dabigatran etexilate mesilate' },
  { value: '51131629', label: 'Clopidogrel' },
  { value: '51131631', label: 'Dabigatran' },
  { value: '51131649', label: 'Prasugrel' },
  { value: '51131650', label: 'Rivaroxaban' },
  { value: '51131740', label: 'Ticagrelor' },
  { value: '51131812', label: 'Romiplostim' },
  { value: '51131817', label: 'Eltrombopag' },
  { value: '51131819', label: 'Coagulation factor ix' },
  { value: '51131820', label: 'Coagulation factor vii' },
  { value: '51131821', label: 'Coagulation factor viii' },
  { value: '51131823', label: 'Moroctocog alfa' },
  { value: '51141563', label: 'Lacosamide' },
  { value: '51141566', label: 'Epsom salt or magnesium sulfate' },
  { value: '51142517', label: 'Amantadine' },
  { value: '51143000', label: 'Nootropics' },
  { value: '51143100', label: 'Salicylates' },
  { value: '51143103', label: 'Acetylsalicylic acid or Aspirin' },
  { value: '51151527', label: 'Metrifonate or trichlorfon' },
  { value: '51151529', label: 'Rivastigmine' },
  { value: '51151625', label: 'Butylscopolamine' },
  { value: '51151657', label: 'Oxybutynin' },
  {
    value: '51152046',
    label: 'Succinylcholine or suxamethonium or suxamethonium chloride'
  },
  { value: '51162702', label: 'Ambroxol' },
  { value: '51162732', label: 'Oxymetazoline' },
  { value: '51162747', label: 'Xylometazoline' },
  { value: '51171641', label: 'Polyethylene glycol' },
  { value: '51171838', label: 'Fosaprepitant or ivemend' },
  { value: '51171839', label: 'Hydroxyzine' },
  { value: '51171840', label: 'Hyoscine or levo-duboisine or scopolamine' },
  { value: '51171932', label: 'Cinitapride' },
  { value: '51171935', label: 'Dexlansoprazole' },
  { value: '51171973', label: 'Alglucosidase alfa' },
  { value: '51171974', label: 'Imiglucerase' },
  { value: '51171980', label: 'Velaglucerase alfa' },
  { value: '51183303', label: 'Sitagliptin' },
  { value: '51183607', label: 'Insulin aspart' },
  { value: '51183608', label: 'Insulin beef' },
  { value: '51183610', label: 'Insulin glargine' },
  { value: '51183613', label: 'Insulin glulisine' },
  { value: '51183614', label: 'Insulin human' },
  { value: '51181614', label: 'Carbimazole' },
  { value: '51182106', label: 'Terlipressin' },
  { value: '51182206', label: 'Atosiban' },
  { value: '51182207', label: 'Carbetocin' },
  { value: '51182426', label: 'Cinacalcet' },
  { value: '51182428', label: 'Denosumab' },
  { value: '51182435', label: 'Teriparatide' },
  { value: '51191549', label: 'Frusemide or furosemide' },
  { value: '51203801', label: 'Abatacept' },
  { value: '51202403', label: 'Belimumab' },
  { value: '51202405', label: 'Certolizumab' },
  { value: '51202306', label: 'Etanercept' },
  { value: '51202603', label: 'Everolimus' },
  { value: '51203302', label: 'Fingolimod' },
  { value: '51203301', label: 'Lenalidomide' },
  { value: '51202204', label: 'Sulfasalazine' },
  { value: '51204001', label: 'Pirfenidone' },
  { value: '51203605', label: 'Teriflunomide' },
  { value: '51201569', label: 'Atlizumab or tocilizumab' },
  { value: '51202414', label: 'Ustekinumab' },
  { value: '51201812', label: 'Mifamurtide' },
  {
    value: '51202000',
    label:
      'Immunomodulating drugs based on tyrosine Inhibitors and kinase Inhibitors'
  },
  { value: '51202002', label: 'Hyaluronan or hyaluronate or hyaluronic acid' },
  { value: '51172319', label: 'Cysteamine or mercaptamine' },
  { value: '51172320', label: 'Deferasirox' },
  { value: '51172321', label: 'Deferiprone' },
  { value: '51172328', label: 'Sugammadex' },
  { value: '51241243', label: 'Dimethyl fumarate' },
  { value: '51241246', label: 'Tacalcitol' },
  { value: '51273900', label: 'Combination anesthetics and adjuncts' },
  { value: '51273921', label: 'Bupivacaine/dextrose' },
  { value: '51273937', label: 'Epinephrine/lidocaine' },
  {
    value: '51172400',
    label:
      'Combination antacid, antidiarrheal, anti-flatulence, digestive, and gastric preparations'
  },
  { value: '51172410', label: 'Aluminum hydroxide/magnesium hydroxide' },
  { value: '51172413', label: 'Aluminum hydroxide/magnesium trisilicate' },
  { value: '51162000', label: 'Combination antiasthma agents' },
  { value: '51162002', label: 'Albuterol/ipratropium' },
  { value: '51162012', label: 'Budesonide/formoterol' },
  { value: '51162030', label: 'Fluticasone/salmeterol' },
  { value: '51102800', label: 'Combination anti-infectives' },
  {
    value: '51102811',
    label: 'Bacitracin/hydrocortisone/neomycin/polymyxin b'
  },
  { value: '51102818', label: 'Bacitracin/diperodon/neomycin/polymyxin b' },
  { value: '51102821', label: 'Bacitracin/neomycin/polymyxin b' },
  { value: '51102822', label: 'Bacitracin/polymyxin b' },
  { value: '51102823', label: 'Bacitracin/polymyxin b/pramoxine' },
  { value: '51102832', label: 'Chloramphenicol/hydrocortisone/polymyxin b' },
  { value: '51102842', label: 'Dalfopristin/quinupristin' },
  { value: '51102844', label: 'Dexamethasone/neomycin/polymyxin b' },
  { value: '51102852', label: 'Gramicidin/neomycin/polymyxin b' },
  { value: '51102856', label: 'Hydrocortisone/neomycin/polymyxin b' },
  { value: '51102858', label: 'Hydrocortisone/polymyxin b' },
  { value: '51102862', label: 'Oxytetracycline/polymyxin b' },
  { value: '51102863', label: 'Neomycin/polymyxin b' },
  { value: '51102864', label: 'Neomycin/polymyxin b/prednisolone' },
  { value: '51102871', label: 'Polymyxin b/trimethoprim' },
  { value: '51102872', label: 'Prednisolone/sulfacetamide' },
  { value: '51132100', label: 'Combination hematolic drugs' },
  { value: '51303600', label: 'Combination antifungals' },
  { value: '51303611', label: 'Benzoic acid/salicylic acid' },
  {
    value: '51241500',
    label:
      'Combination antiglaucoma, ophthalmic, and eye-related agents and preparations'
  },
  { value: '51241513', label: 'Brimonidine/timolol' },
  { value: '51241522', label: 'Dorzolamide/timolol' },
  { value: '51241544', label: 'Phenylephrine/zinc sulfate' },
  { value: '51434900', label: 'Combination antihypertensives' },
  { value: '51434907', label: 'Amlodipine/hydrochlorothiazide/olmesartan' },
  { value: '51434908', label: 'Amlodipine/hydrochlorothiazide/valsartan' },
  { value: '51434909', label: 'Amlodipine/olmesartan' },
  { value: '51434931', label: 'Hydrochlorothiazide/losartan' },
  { value: '51434938', label: 'Hydrochlorothiazide/irbesartan' },
  { value: '51434945', label: 'Hydrochlorothiazide/olmesartan' },
  { value: '51434949', label: 'Hydrochlorothiazide/telmisartan' },
  {
    value: '51474000',
    label: 'Combination antiseptics, ceruminolytics, and disinfectants'
  },
  { value: '51474001', label: 'Formaldehyde/isopropyl alcohol/sodium nitrite' },
  { value: '51386600', label: 'Combination anti-inflammatory agents' },
  { value: '51386609', label: 'Hydrocortisone/lidocaine' },
  { value: '51122600', label: 'Combination antilipemic agents' },
  { value: '51122601', label: 'Aspirin/pravastatin' },
  { value: '51122603', label: 'Ezetimibe/simvastatin' },
  { value: '51102900', label: 'Combination antimalarials' },
  { value: '51102901', label: 'Artemether/lumefantrine' },
  { value: '51102904', label: 'Pyrimethamine/sulfadoxine' },
  { value: '51284900', label: 'Combination antimicrobials' },
  { value: '51284903', label: 'Cilastatin/imipenem' },
  { value: '51284911', label: 'Sulfamethoxazole/trimethoprim' },
  { value: '51143400', label: 'Combination antimigraine agents' },
  { value: '51143406', label: 'Caffeine/ergotamine' },
  { value: '51143500', label: 'Combination antiparkinson agents' },
  { value: '51143501', label: 'Carbidopa/entacapone/levodopa' },
  { value: '51143502', label: 'Carbidopa/levodopa' },
  { value: '51241600', label: 'Combination antipsoriatics' },
  { value: '51241603', label: 'Allantoin/coal tar' },
  { value: '51241605', label: 'Betamethasone/calcipotriene' },
  {
    value: '51182500',
    label: 'Combination antithyroid agents and supplements'
  },
  { value: '51182501', label: 'Levothyroxine/liothyronine' },
  { value: '51162100', label: 'Combination antituberculars' },
  { value: '51162101', label: 'Isoniazid/pyrazinamide/rifampin' },
  { value: '51162103', label: 'Isoniazid/rifampin' },
  { value: '51343800', label: 'Combination antivirals' },
  { value: '51343801', label: 'Abacavir/lamivudine' },
  { value: '51343803', label: 'Efavirenz/emtricitabine/tenofovir' },
  { value: '51343804', label: 'Emtricitabine/tenofovir' },
  { value: '51343806', label: 'Lamivudine/zidovudine' },
  {
    value: '51172800',
    label: 'Combination cariostatics, dental or mouthwashes or oral agents'
  },
  { value: '51172833', label: 'Hydrogen peroxide/sodium bicarbonate' },
  { value: '51162300', label: 'Combination cold remedies' },
  { value: '51352400', label: 'Combination contraceptives and estrogens' },
  { value: '51352404', label: 'Drospirenone/estradiol' },
  { value: '51352413', label: 'Ethinyl estradiol/levonorgestrel' },
  { value: '51352414', label: 'Ethinyl estradiol/norelgestromin' },
  { value: '51241800', label: 'Combination dermatologicals' },
  { value: '51352426', label: 'Estradiol/norethindrone' },
  { value: '51192300', label: 'Combination electrolytes/minerals' },
  { value: '51285000', label: 'Combination extended spectrum penicillins' },
  { value: '51285003', label: 'Piperacillin/tazobactam' },
  { value: '51285100', label: 'Combination H. pylori agents' },
  { value: '51285101', label: 'Amoxicillin/clarithromycin/lansoprazole' },
  { value: '51461600', label: 'Combination herbs/alternative therapies' },
  { value: '51373300', label: 'Combination opioid analgesics' },
  { value: '51192400', label: 'Combination nutritional therapy products' },
  { value: '51192429', label: 'Calcium carbonate/vitamin d' },
  { value: '51192443', label: 'Ferrous sulfate/folic acid' },
  { value: '51132200', label: 'Combination non-opioid analgesics' },
  { value: '51132225', label: 'Acetaminophen/tramadol' },
  { value: '51182700', label: 'Combination oral hypoglycemic agents' },
  { value: '51182709', label: 'Metformin/sitagliptin' },
  { value: '51242200', label: 'Combination otic agents' },
  { value: '51132102', label: 'Aspirin/dipyridamole' },
  { value: '51242300', label: 'Combination sun protectants or screens' },
  { value: '51242302', label: 'Avobenzone/padimate-o' },
  { value: '51132300', label: 'Combination volume expanders' },
  {
    value: '51261700',
    label:
      'Adrenergic blocking agent amino alcohols and aminoquinolines and benzopyrans and bradykinin/analogs and derivatives'
  },
  { value: '51261701', label: 'Arnolol' },
  { value: '51261702', label: 'Abanoquil' },
  { value: '51261704', label: 'Icatibant' },
  {
    value: '51262000',
    label:
      'Adrenergic blocking agent butyrophenones and carbazoles and dibenzazepines and dimethylamines'
  },
  { value: '51262400', label: 'Adrenergic blocking agent ethanolamines' },
  { value: '51262404', label: 'Labetalol' },
  {
    value: '51262900',
    label: 'Adrenergic blocking agent phenoxypropanolamines'
  },
  { value: '51262907', label: 'Propranolol' },
  { value: '51263000', label: 'Adrenergic blocking agent piperazines' },
  { value: '51263100', label: 'Adrenergic blocking agent propanolamines' },
  { value: '51263105', label: 'Bisoprolol' },
  { value: '51263122', label: 'Esmolol' },
  {
    value: '51263400',
    label:
      'Adrenergic blocking agent pyrimidine nucleosides and quaternary ammonium compounds and secologanin tryptamine alkaloids and spiro compounds and sulfonamides'
  },
  { value: '51271600', label: 'Anaesthetic amides' },
  { value: '51272000', label: 'Anaesthetic barbiturates' },
  { value: '51272100', label: 'Anaesthetic benzoates' },
  { value: '51272111', label: 'Amethocaine or tetracaine' },
  { value: '51272200', label: 'Anaesthetic cycloparaffins' },
  {
    value: '51272300',
    label: 'Anaesthetic dioxolanes and gases and imidazoles and isoquinolines'
  },
  { value: '51272400', label: 'Anaesthetic ethers' },
  { value: '51272900', label: 'Anaesthetic phenols' },
  { value: '51273000', label: 'Anaesthetic piperidines' },
  { value: '51273009', label: 'Remifentanil' },
  { value: '51280000', label: 'Antibacterials' },
  { value: '51281600', label: 'Aminoglycosides' },
  { value: '51281624', label: 'Streptomycin' },
  { value: '51281700', label: 'Aminopenicillins' },
  { value: '51281702', label: 'Amoxicillin' },
  { value: '51281800', label: 'Anthracyclines' },
  { value: '51281801', label: 'Idarubicin' },
  { value: '51281805', label: 'Daunorubicin' },
  { value: '51281806', label: 'Doxorubicin' },
  { value: '51281900', label: 'Antineoplastics' },
  { value: '51281902', label: 'Rifabutin' },
  {
    value: '51282000',
    label:
      'Actinomycines and bacitracins and chloramphenicols and cyclic peptide antibacterials and glycylcyclines'
  },
  { value: '51282100', label: 'Beta-lactams' },
  { value: '51282200', label: 'Cephamycins' },
  { value: '51282300', label: 'Erythromycins' },
  { value: '51282400', label: 'Extended-spectrum penicillins' },
  { value: '51282500', label: 'First generation cephalosporins' },
  { value: '51282506', label: 'Cefalotin or cephalothin' },
  { value: '51282600', label: 'Fourth generation cephalosporins' },
  { value: '51282700', label: 'Glycopeptides' },
  { value: '51282703', label: 'Vancomycin hydrochloride' },
  { value: '51282800', label: 'Imidazoles' },
  { value: '51282900', label: 'Quinolone antibacterials' },
  { value: '51282931', label: 'Moxifloxacin' },
  { value: '51283000', label: 'Lincosamides' },
  { value: '51283100', label: 'Lipopeptides' },
  { value: '51283101', label: 'Anidulafungin' },
  { value: '51283106', label: 'Polymyxin b' },
  { value: '51283200', label: 'Macrolides' },
  { value: '51283300', label: 'Oxazolidinones' },
  { value: '51283400', label: 'Penicillins' },
  { value: '51283401', label: 'Penicillin o' },
  { value: '51283402', label: 'Adicillin or penicillin n' },
  { value: '51283403', label: 'Apalcillin' },
  { value: '51283404', label: 'Carfecillin' },
  { value: '51283405', label: 'Benzylpenicillin or penicillin g' },
  { value: '51283406', label: 'Penicillin V or phenoxymethylpenicillin' },
  { value: '51283407', label: 'Pheneticillin or phenethicillin' },
  { value: '51283408', label: 'Prazocillin' },
  {
    value: '51283409',
    label: 'Procaine benzylpenicillin or procaine penicillin g'
  },
  { value: '51283410', label: 'Sarpicillin' },
  { value: '51283411', label: 'Piroxicillin' },
  { value: '51283500', label: 'Penicillinase-resistant penicillins' },
  { value: '51283502', label: 'Dicloxacillin' },
  { value: '51283600', label: 'Rifamycin antibacterials' },
  { value: '51283601', label: 'Rifampicin or rifampin' },
  { value: '51283604', label: 'Rifaximin' },
  { value: '51283700', label: 'Second generation cephalosporins' },
  { value: '51283705', label: 'Cefminox' },
  { value: '51283801', label: 'Dalfopristin' },
  { value: '51283900', label: 'Sulfonamide antibacterials' },
  { value: '51283904', label: 'Silvadene or silver sulfadiazine' },
  { value: '51283908', label: 'Sulfadiazine' },
  { value: '51284000', label: 'Tetracyclines' },
  { value: '51284100', label: 'Third generation cephalosporins' },
  { value: '51285500', label: 'Antibacterials, synthesized' },
  { value: '51283421', label: 'Fenbenicillin' },
  { value: '51283422', label: 'Fumoxicillin' },
  { value: '51283423', label: 'Oxetacillin' },
  { value: '51284300', label: 'Amphenicols' },
  { value: '51282003', label: 'Tigecycline' },
  {
    value: '51284500',
    label:
      'Lincomycins and monobactam antibacterials and polymyxin antibacterials and polysaccharide antibacterials'
  },
  { value: '51284600', label: 'Carbapenems' },
  { value: '51284603', label: 'Ertapenem' },
  { value: '51291700', label: 'Antidepressant alcohols' },
  { value: '51292000', label: 'Antidepressant amines' },
  { value: '51292011', label: 'Fluoxetine' },
  { value: '51292900', label: 'Antidepressant dibenzazepines' },
  { value: '51293000', label: 'Antidepressant dibenzocycloheptenes' },
  { value: '51293002', label: 'Amitriptyline' },
  { value: '51293402', label: 'Bupropion' },
  {
    value: '51293400',
    label:
      'Antidepressant imidazoles and isonicotinic acids and ketones and orphenadrine/analogs and derivatives and phenanthrenes'
  },
  { value: '51294000', label: 'Antidepressant morpholines' },
  { value: '51294400', label: 'Antidepressant piperazines' },
  { value: '51294500', label: 'Antidepressant piperidines' },
  { value: '51301800', label: 'Antifungal amines' },
  {
    value: '51301900',
    label:
      'Antifungal aminoacridines and azoles and benzimidazoles and benzofurans'
  },
  { value: '51302000', label: 'Antifungal carboxylic acids' },
  { value: '51302300', label: 'Antifungal imidazoles' },
  { value: '51302400', label: 'Antifungal macrolides' },
  { value: '51302500', label: 'Antifungal naphthalenes' },
  { value: '51302700', label: 'Antifungal phenols' },
  { value: '51302900', label: 'Antifungal pyridines' },
  { value: '51303000', label: 'Antifungal pyrimidines' },
  {
    value: '51303100',
    label:
      'Antifungal pyrroles and pyrrolidinones and quinazolines and terpenes'
  },
  { value: '51303400', label: 'Antifungal triazoles' },
  { value: '51311900', label: 'Antihistamine or H1 blocker benzopyrans' },
  { value: '51311901', label: 'Cromoglicic acid or cromolyn' },
  {
    value: '51312100',
    label:
      'Antihistamine or H1 blocker cyclic s-oxides and dibenzazepines and dibenzoxepins and glyoxylates'
  },
  {
    value: '51312200',
    label: 'Antihistamine or H1 blocker dibenzocycloheptenes'
  },
  { value: '51312300', label: 'Antihistamine or H1 blocker ethylamines' },
  { value: '51312400', label: 'Antihistamine or H1 blocker ethylenediamines' },
  { value: '51312401', label: 'Chloropyramine' },
  { value: '51313000', label: 'Antihistamine or H1 blocker phthalazines' },
  { value: '51313100', label: 'Antihistamine or H1 blocker piperazines' },
  { value: '51313200', label: 'Antihistamine or H1 blocker piperidines' },
  { value: '51313205', label: 'Levocabastine' },
  { value: '51313300', label: 'Antihistamine or H1 blocker propylamines' },
  { value: '51313400', label: 'Antihistamine or H1 blocker pyridines' },
  {
    value: '51313401',
    label: 'Chlorphenamine or chlorpheniramine or dexchlorpheniramine'
  },
  {
    value: '51313700',
    label:
      'Antihistamine or H1 blocker quinolizines and sulfonamides and thiazines and thiazoles and thiophenes'
  },
  {
    value: '51313800',
    label: 'Antihistamine or H1 blocker terfenadine/analogs and derivatives'
  },
  { value: '51313801', label: 'Fexofenadine' },
  {
    value: '51321500',
    label: 'Antihyperlipidemic and hypocholesterolemic benzene derivatives'
  },
  { value: '51321501', label: 'Eniclobrate' },
  {
    value: '51321600',
    label: 'Antihyperlipidemic and hypocholesterolemic alcohols'
  },
  {
    value: '51321700',
    label: 'Antihyperlipidemic and hypocholesterolemic fatty acids'
  },
  {
    value: '51321800',
    label: 'Antihyperlipidemic and hypocholesterolemic fibric acids'
  },
  { value: '51321804', label: 'Ciprofibrate' },
  {
    value: '51321900',
    label: 'Antihyperlipidemic and hypocholesterolemic amides'
  },
  { value: '51321901', label: 'Bezafibrate' },
  {
    value: '51322200',
    label:
      'Antihyperlipidemic and hypocholesterolemic azetidines and cyclohexanes and fluorobenzenes and furans'
  },
  {
    value: '51322300',
    label:
      'Antihyperlipidemic and hypocholesterolemic macromolecular substances'
  },
  {
    value: '51322700',
    label: 'Antihyperlipidemic and hypocholesterolemic naphthalenes'
  },
  { value: '51331900', label: 'Antipsychotic anions' },
  { value: '51332000', label: 'Antipsychotic azoles' },
  { value: '51332003', label: 'Paliperidone' },
  { value: '51332500', label: 'Antipsychotic butyrophenones' },
  { value: '51332700', label: 'Antipsychotic dibenzazepines' },
  { value: '51332800', label: 'Antipsychotic phenothiazines' },
  { value: '51332814', label: 'Levomepromazine or methotrimeprazine' },
  { value: '51332822', label: 'Perphenazine' },
  { value: '51332831', label: 'Trifluoperazine' },
  { value: '51333200', label: 'Antipsychotic nitrogen mustard compounds' },
  { value: '51333400', label: 'Antipsychotic piperazines' },
  { value: '51333600', label: 'Antipsychotic pyrimidines' },
  { value: '51334200', label: 'Antipsychotic thiepins' },
  { value: '51334204', label: 'Quetiapine' },
  { value: '51334300', label: 'Antipsychotic thioxanthenes' },
  { value: '51334303', label: 'Flupentixol' },
  { value: '51334306', label: 'Zuclopenthixol' },
  { value: '51341500', label: 'Antiviral carboxylic acids' },
  { value: '51341600', label: 'Antiviral adamantanes' },
  { value: '51341800', label: 'Antiviral amides' },
  { value: '51341801', label: 'Darunavir' },
  {
    value: '51341900',
    label: 'Antiviral amidines and amines and benzoxazines and cyclohexanes'
  },
  { value: '51341904', label: 'Maraviroc' },
  { value: '51342300', label: 'Antiviral guanines' },
  { value: '51342304', label: 'Entecavir' },
  { value: '51342309', label: 'Aciclovir or acyclovir' },
  { value: '51342400', label: 'Antiviral isoquinolines' },
  { value: '51342500', label: 'Antiviral nucleosides' },
  { value: '51342501', label: 'Abacavir' },
  { value: '51342700', label: 'Antiviral peptides' },
  { value: '51342701', label: 'Atazanavir' },
  { value: '51342702', label: 'Enfuvirtide' },
  { value: '51342709', label: 'Interferon beta-1a' },
  { value: '51342710', label: 'Interferon beta-1b' },
  { value: '51342900', label: 'Antiviral purines' },
  { value: '51343704', label: 'Etravirine' },
  { value: '51343000', label: 'Antiviral pyridines' },
  { value: '51343100', label: 'Antiviral pyrimidines' },
  { value: '51442007', label: 'TFT or trifluorothymidine or trifluridine' },
  { value: '51343116', label: 'Azidothymidine or zidovudine' },
  {
    value: '51343200',
    label: 'Antiviral pyrrolidinones and quinolines and thiazoles'
  },
  { value: '51343201', label: 'Raltegravir' },
  { value: '51343500', label: 'Antiviral organophosphorus compounds' },
  {
    value: '51343700',
    label: 'Antiviral ketones and morpholines and nitriles and pyridazines'
  },
  {
    value: '51351500',
    label: 'Estrogen, progestin, or internal contraceptive pregnanes'
  },
  { value: '51351509', label: 'Medroxyprogesterone' },
  { value: '51351510', label: 'Megestrol' },
  {
    value: '51351600',
    label: 'Estrogen, progestin, or internal contraceptive estrenes'
  },
  { value: '51351605', label: 'Estradiol or oestradiol' },
  { value: '51351606', label: 'Dienogest' },
  { value: '51351608', label: 'Estriol' },
  { value: '51351613', label: 'Mifepristone' },
  {
    value: '51351800',
    label: 'Estrogen, progestin, or internal contraceptive gonadal hormones'
  },
  {
    value: '51351900',
    label: 'Estrogen, progestin, or internal contraceptive norpregnanes'
  },
  { value: '51351912', label: 'Ulipristal acetate' },
  {
    value: '51352000',
    label: 'Estrogen, progestin, or internal contraceptive norpregnenes'
  },
  { value: '51352005', label: 'Etonogestrel' },
  { value: '51352008', label: 'Norethindrone or norethisterone' },
  { value: '51361500', label: 'Hypnotic alcohols' },
  { value: '51361800', label: 'Hypnotic azoles' },
  { value: '51361900', label: 'Hypnotic barbiturates' },
  { value: '51362600', label: 'Hypnotic acetates' },
  { value: '51370000', label: 'Controlled substance analgesics' },
  { value: '51371600', label: 'Controlled substance analgesic alcohols' },
  { value: '51371601', label: 'Tramadol' },
  { value: '51371900', label: 'Controlled substance analgesic methadones' },
  { value: '51372000', label: 'Controlled substance analgesic morphinans' },
  { value: '51372003', label: 'Buprenorphine' },
  { value: '51372015', label: 'Morphine' },
  {
    value: '51372100',
    label: 'Controlled substance analgesic morphine derivatives'
  },
  { value: '51372107', label: 'Hydromorphone' },
  { value: '51372300', label: 'Controlled substance analgesic piperidines' },
  { value: '51373200', label: 'Controlled substance analgesic phenols' },
  { value: '51381500', label: 'Nonsteroidal anti inflammatory benzoates' },
  { value: '51381700', label: 'Nonsteroidal anti inflammatory acetanilides' },
  { value: '51382700', label: 'Nonsteroidal anti inflammatory indenes' },
  { value: '51383300', label: 'Nonsteroidal anti inflammatory phenylacetates' },
  {
    value: '51383400',
    label: 'Nonsteroidal anti inflammatory indoleacetic acids'
  },
  { value: '51383401', label: 'Acemetacin' },
  { value: '51383500', label: 'Nonsteroidal anti inflammatory indoles' },
  { value: '51383507', label: 'Indometacin or indomethacin' },
  {
    value: '51383900',
    label: 'Nonsteroidal anti inflammatory nicotinic acids'
  },
  { value: '51384500', label: 'Nonsteroidal anti inflammatory propionates' },
  { value: '51384600', label: 'Nonsteroidal anti inflammatory pyrazoles' },
  { value: '51384800', label: 'Nonsteroidal anti inflammatory pyridines' },
  { value: '51385600', label: 'Nonsteroidal anti inflammatory salicylates' },
  { value: '51385800', label: 'Nonsteroidal anti inflammatory thiazines' },
  { value: '51391600', label: 'Sympathomimetic or adrenergic amines' },
  { value: '51391700', label: 'Sympathomimetic or adrenergic amino alcohols' },
  {
    value: '51391722',
    label: 'Levarterenol or noradrenaline or norepinephrine'
  },
  { value: '51391726', label: 'Orciprenaline or metaproterenol' },
  { value: '51391739', label: 'Salmeterol' },
  { value: '51391743', label: 'Adrenalin or adrenaline or epinephrine' },
  { value: '51391746', label: 'Racepinefrine or racepinephrine' },
  { value: '51392400', label: 'Sympathomimetic or adrenergic catecholamines' },
  { value: '51392403', label: 'Dopamine' },
  { value: '51393400', label: 'Sympathomimetic or adrenergic xanthines' },
  {
    value: '51393600',
    label:
      'Sympathomimetic or adrenergic dibenzoxepins and dioxolanes and glycosides and guanidines'
  },
  { value: '51393604', label: 'Indacaterol' },
  {
    value: '51401500',
    label: 'Tranquilizers and antimanic and antianxiety benzodiazepines'
  },
  {
    value: '51401700',
    label:
      'Tranquilizers and antimanic and antianxiety amines and anthracenes and barbiturates and benzazepines and dipeptides'
  },
  {
    value: '51402200',
    label:
      'Tranquilizers and antimanic and antianxiety naphthyridines and oxazines and quinolines and spiro compounds and sulfur compounds'
  },
  { value: '51411600', label: 'Vasodilator alcohols' },
  { value: '51412000', label: 'Vasodilator azoles' },
  {
    value: '51412600',
    label:
      'Vasodilator ferricyanides and hydrazines and isoquinolines and morpholines and phenols'
  },
  { value: '51412601', label: 'Nitroprusside' },
  { value: '51412700', label: 'Vasodilator furans' },
  { value: '51413200', label: 'Vasodilator piperazines' },
  { value: '51413204', label: 'Sildenafil' },
  { value: '51413300', label: 'Vasodilator prostaglandins' },
  { value: '51413500', label: 'Vasodilator pyridines' },
  { value: '51421500', label: 'Corticosteroid androstadienes' },
  { value: '51421800', label: 'Corticosteroid glucocorticoids' },
  { value: '51422100', label: 'Corticosteroid pregnadienediols' },
  { value: '51422200', label: 'Corticosteroid pregnadienes' },
  { value: '51422205', label: 'Flunarizine' },
  { value: '51422300', label: 'Corticosteroid pregnadienetriols' },
  { value: '51422302', label: 'Beclometasone or Beclomethasone' },
  { value: '51422400', label: 'Corticosteroid pregnenediones' },
  {
    value: '51431500',
    label:
      'Antihypertensive acrylates and amides and angiotensins and aromatic amino acids'
  },
  { value: '51431600', label: 'Antihypertensive amines' },
  { value: '51431700', label: 'Antihypertensive benzazepines' },
  {
    value: '51431800',
    label:
      'Antihypertensive benzene derivatives and benzimidazoles and benzofurans and catecholamines'
  },
  { value: '51431900', label: 'Antihypertensive alcohols' },
  { value: '51432300', label: 'Antihypertensive imidazoles' },
  { value: '51432400', label: 'Antihypertensive imino acids' },
  { value: '51432500', label: 'Antihypertensive indoles' },
  { value: '51432600', label: 'Antihypertensive piperazines' },
  { value: '51432700', label: 'Antihypertensive bicyclo compounds' },
  { value: '51432800', label: 'Antihypertensive oligopeptides' },
  { value: '51433300', label: 'Antihypertensive phthalazines' },
  { value: '51433303', label: 'Hydralazine' },
  {
    value: '51433700',
    label:
      'Antihypertensive prostaglandins and pyrazoles and pyrimidines and quinazolines'
  },
  { value: '51433800', label: 'Antihypertensive pyridazines' },
  { value: '51433900', label: 'Antihypertensive pyridines' },
  { value: '51433902', label: 'Amlodipine' },
  { value: '51434500', label: 'Antihypertensive tetrazoles' },
  { value: '51460000', label: 'Herbal drugs' },
  { value: '51451800', label: 'Anthelmintic from other constituents' },
  { value: '51451900', label: 'Anthelmintic amides' },
  { value: '51452100', label: 'Anthelmintic benzene derivatives' },
  { value: '51451600', label: 'Anthelmintic benzimidazoles' },
  { value: '51452200', label: 'Anthelmintic benzoates' },
  {
    value: '51452300',
    label:
      'Anthelmintic benzodioxoles and butyrophenones and copper compounds and cyclohexanes and naphthalenes'
  },
  { value: '51452400', label: 'Anthelmintic carbamates' },
  { value: '51452500', label: 'Anthelmintic chlorinated hydrocarbons' },
  { value: '51452600', label: 'Anthelmintic guanidines' },
  { value: '51452700', label: 'Anthelmintic isoquinolines' },
  { value: '51452800', label: 'Anthelmintic macrolides' },
  { value: '51452900', label: 'Anthelmintic organophosphorus compounds' },
  { value: '51453000', label: 'Anthelmintic piperazines' },
  { value: '51453200', label: 'Anthelmintic quinolines' },
  { value: '51453400', label: 'Anthelmintic terpenes' },
  { value: '51453500', label: 'Anthelmintic thiazoles' },
  { value: '51453600', label: 'Anthelmintic thiocyanates' },
  {
    value: '51471500',
    label:
      'Antiseptic alkenes and amidines and amines and ammonia-based compounds'
  },
  { value: '51472000', label: 'Antiseptic aldehydes' },
  { value: '51472002', label: 'Glutaraldehyde' },
  {
    value: '51472300',
    label:
      'Antiseptic borates and chlorine-based compounds and heavy metals and herbal preparations'
  },
  { value: '51472600', label: 'Antiseptic cresols' },
  { value: '51472601', label: 'Amylmetacresol' },
  { value: '51472800', label: 'Antiseptic guanidines' },
  { value: '51472803', label: 'Chlorhexidine' },
  { value: '51472900', label: 'Antiseptic halogens' },
  { value: '51472901', label: 'Iodine' },
  { value: '51473000', label: 'Antiseptic quaternary ammonium compounds' },
  { value: '51473003', label: 'Cetrimonium' },
  { value: '51473016', label: 'Acetone or alcohol based antiseptics' },
  {
    value: '51473100',
    label: 'Antiseptic imidazoles and ketones and lipids and nitrofurans'
  },
  { value: '51473200', label: 'Antiseptic organometallic compounds' },
  { value: '51473300', label: 'Antiseptic phenols' },
  { value: '51473307', label: 'Phenol or phenolate sodium' },
  {
    value: '51473500',
    label: 'Antiseptic pyrimidines and silvers and ureas and waters'
  },
  { value: '51473600', label: 'Antiseptic quinolines' },
  { value: '51473700', label: 'Antiseptic sulfur compounds' },
  { value: '70171507', label: 'Surface water development' },
  { value: '80101607', label: 'Project impact assessment' },
  { value: '80101606', label: 'Project monitoring and evaluation' },
  { value: '80111511', label: 'Labor training impact assessment' },
  { value: '81111512', label: 'Computer graphics service' },
  {
    value: '81111511',
    label: 'System or application programming management service'
  },
  { value: '81112310', label: 'Computer cabinet maintenance' },
  { value: '81121505', label: 'Economic development consultancy' },
  { value: '84131518', label: 'Bicycle insurance' },
  { value: '84131611', label: 'Medical malpractice insurance' },
  { value: '84131519', label: 'Fire insurance' },
  { value: '86132201', label: 'Training workshop service' },
  { value: '86132200', label: 'Educational support services' },
  { value: '51440000', label: 'Radiopharmaceuticals and contrast media' },
  { value: '10152300', label: 'Legume seeds and seedlings' },
  { value: '10152400', label: 'Tuber seeds and seedlings' },
  { value: '10191519', label: 'Telfubenzuron' },
  { value: '11131610', label: 'Non-edible bird and poultry eggs' },
  { value: '21102500', label: 'Irrigation systems and equipment' },
  { value: '21102503', label: 'Irrigation parts and accessories' },
  { value: '21102600', label: 'Agricultural protection materials' },
  { value: '21102601', label: 'Plastic film for agriculture' },
  { value: '24131516', label: 'Cold storage room' },
  { value: '25101941', label: 'Mobile laboratory' },
  { value: '25175100', label: 'Transportation repair parts kits' },
  { value: '25175101', label: 'Motor vehicle repair part kit' },
  { value: '25175102', label: 'Motorcycle repair part kit' },
  { value: '25175103', label: 'Marine transport repair part kits' },
  { value: '25175106', label: 'Non motorized cycle repair part kit' },
  { value: '40161609', label: 'Water purification system' },
  { value: '43211521', label: 'All in one desktop computer' },
  { value: '46111504', label: 'Abandoned Explosive Ordnance AXO' },
  { value: '46111505', label: 'Explosive remnants of war ERW' },
  { value: '46111506', label: 'Unexploded ordnance UXO' },
  { value: '46211603', label: 'Minefield or mine hazard area marker' },
  { value: '46211604', label: 'Minefield or mine hazard area marking signage' },
  {
    value: '46220000',
    label:
      'Military weapon and ammunition disarmament and disposal equipment and related products'
  },
  { value: '46221500', label: 'Demining equipment and related products' },
  { value: '46221501', label: 'Mine anti-tampering device' },
  { value: '46221502', label: 'Demining machine' },
  { value: '46221503', label: 'Anti explosive device detonator' },
  { value: '46221504', label: 'Mine dispenser' },
  { value: '46221505', label: 'Intrusive demining machine' },
  { value: '46221506', label: 'Non intrusive demining machine' },
  { value: '46221507', label: 'Demining machine parts and accessories' },
  { value: '46221508', label: 'Mine detecting dog MDD' },
  { value: '46221509', label: 'Mine self destruction mechanism' },
  { value: '46221510', label: 'Mine self neutralization equipment' },
  { value: '50501900', label: 'Veterinary nutritional supplements' },
  { value: '50501901', label: 'Mineral salt lick' },
  { value: '50501902', label: 'Veterinary multinutrient block' },
  { value: '50501903', label: 'Veterinary vitamin' },
  { value: '50501904', label: 'Veterinary vitamin and mineral mixture' },
  { value: '51111874', label: 'Tamoxifen citrate' },
  { value: '51112014', label: 'Erlotinib hydrochloride' },
  { value: '51112015', label: 'Imatinib mesiate or mesylate' },
  { value: '51112016', label: 'Sorafenib tosylate' },
  { value: '51112017', label: 'Sunitinib malate' },
  { value: '51112300', label: 'Alkylating agent alkanesulfonic acids' },
  { value: '51112400', label: 'Alkylating agent amides' },
  { value: '51112500', label: 'Alkylating agent nitrogen mustard compounds' },
  { value: '51112508', label: 'Bendamustine hydrochloride' },
  { value: '51112600', label: 'Alkylating agent nitrosourea compounds' },
  { value: '51112700', label: 'Alkylating agent organoplatinum compounds' },
  { value: '51112702', label: 'Cisplatin' },
  { value: '51112800', label: 'Alkylating agent stilbenes' },
  { value: '51112900', label: 'Alkylating agent triazines' },
  { value: '51113000', label: 'Alkylating agent amines' },
  { value: '51113500', label: 'Alkylating agent piperazines' },
  { value: '51121583', label: 'Propafenone hydrochloride' },
  { value: '51123500', label: 'Antiarrythmic benzazepines' },
  { value: '51123600', label: 'Antiarrythmic benzofurans' },
  { value: '51123900', label: 'Antiarrythmic indoles' },
  { value: '51124100', label: 'Antiarrythmic purines' },
  {
    value: '51124400',
    label: 'Antiarrythmic cardiac glycosides or cardenolides'
  },
  { value: '51131827', label: 'Eltrombopag olamine' },
  { value: '51141590', label: 'Donepezil hydrochloride' },
  { value: '51141596', label: 'Phenytoin sodium' },
  { value: '51142315', label: 'Naloxone hydrochloride' },
  { value: '51142423', label: 'Almotriptan malate' },
  { value: '51142433', label: 'Rizatriptan benzoate' },
  { value: '51142435', label: 'Sumatriptan succinate' },
  { value: '51142565', label: 'Pramipexole dihydrochloride monohydrate' },
  { value: '51142568', label: 'Rasagiline mesilate or mesylate' },
  { value: '51143222', label: 'Varenicline tartrate' },
  { value: '51144000', label: 'Stimulant or anorexiant amines' },
  { value: '51144015', label: 'Methylphenidate hydrochloride' },
  { value: '51144600', label: 'Stimulant or anorexiant alkaloids' },
  { value: '51151541', label: 'Galantamine hydrobromide' },
  { value: '51151544', label: 'Pilocarpine hydrochloride' },
  { value: '51151548', label: 'Pyridostigmine bromide' },
  { value: '51151549', label: 'Rivastigmine hydrogen tartrate' },
  { value: '51152051', label: 'Cisatracurium besilate' },
  { value: '51152064', label: 'Orphenadrine citrate' },
  { value: '51152070', label: 'Rocuronium bromide' },
  { value: '51152075', label: 'Vecuronium bromide' },
  { value: '51161569', label: 'Montelukast sodium' },
  { value: '51161574', label: 'Tiotropium bromide' },
  { value: '51162600', label: 'Antitussives and associated cough remedies' },
  { value: '51162700', label: 'Decongestants, expectorants, and mucolytics' },
  { value: '51171724', label: 'Loperamide hydrochloride' },
  { value: '51172333', label: 'Sugammadex sodium' },
  { value: '51181625', label: 'Calcitonin salmon' },
  { value: '51181927', label: 'Clomifene citrate' },
  { value: '51182071', label: 'Testosterone enanthate' },
  { value: '51182108', label: 'Desmopressin acetate' },
  { value: '51182222', label: 'Ergometrine maleate' },
  {
    value: '51183300',
    label: 'Antidiabetic and hyperglycemic dipeptidyl peptidase-4 inhibitors'
  },
  { value: '51183306', label: 'Sitagliptin phosphate' },
  { value: '51183500', label: 'Antidiabetic and hyperglycemic guanidines' },
  { value: '51183510', label: 'Metformin hydrochloride' },
  {
    value: '51183600',
    label: 'Antidiabetic and hyperglycemic peptide hormones'
  },
  { value: '51183615', label: 'Insulin lispro' },
  {
    value: '51183700',
    label: 'Antidiabetic and hyperglycemic thiazolidinediones'
  },
  { value: '51183706', label: 'Pioglitazone hydrochloride' },
  {
    value: '51183800',
    label: 'Antidiabetic and hyperglycemic alpha-glucosidase inhibitors'
  },
  { value: '51184000', label: 'Antidiabetic and hyperglycemic sulfonylureas' },
  { value: '51202200', label: 'Immunosupressant amides' },
  { value: '51202203', label: 'Methotrexate sodium' },
  { value: '51202207', label: 'Dexrazoxane hydrochloride' },
  { value: '51202300', label: 'Immunosupressant amino acids' },
  { value: '51202400', label: 'Immunosupressant antibodies' },
  { value: '51202406', label: 'Certolizumab pegol' },
  { value: '51202413', label: 'Secukinumab' },
  { value: '51202500', label: 'Immunosupressant azoles' },
  { value: '51202600', label: 'Immunosupressant imides' },
  { value: '51202700', label: 'Immunosupressant immunoglobulins' },
  { value: '51202701', label: 'Anti-thymocyte globulin' },
  { value: '51202800', label: 'Immunosupressant macrolides' },
  { value: '51202805', label: 'Tacrolimus anhydrous' },
  { value: '51203100', label: 'Immunosupressant peptides' },
  { value: '51203200', label: 'Immunosupressant phenols' },
  { value: '51203201', label: 'Mycophenolate mofetil' },
  { value: '51203202', label: 'Mycophenolate mofetil hydrochloride' },
  { value: '51203300', label: 'Immunosupressant phthalimides' },
  { value: '51203303', label: 'Pomalidomide' },
  { value: '51203400', label: 'Immunosupressant purines' },
  { value: '51203600', label: 'Immunosupressant carboxylic acids' },
  { value: '51203800', label: 'Immunosupressant immunoconjugates' },
  { value: '51204000', label: 'Immunosupressant pyridines' },
  { value: '51204200', label: 'Veterinary vaccines and virology products' },
  {
    value: '51204201',
    label: 'Veterinary foot and mouth disease (FMD) vaccines'
  },
  { value: '51204202', label: 'Veterinary anthrax vaccines' },
  { value: '51204203', label: 'Veterinary brucella vaccines' },
  {
    value: '51204204',
    label: 'Contagious bovine pleuropneumonia CBPP vaccines'
  },
  {
    value: '51204205',
    label: 'Ovine rinderpest or peste des petit ruminants vaccines'
  },
  { value: '51241133', label: 'Dorzolamide hydrochloride' },
  { value: '51261705', label: 'Icatibant acetate' },
  { value: '51261706', label: 'Nebivolol hydrochloride' },
  { value: '51262410', label: 'Labetalol hydrochloride' },
  { value: '51262414', label: 'Terbutaline sulfate' },
  { value: '51262914', label: 'Propranolol hydrochloride' },
  { value: '51263009', label: 'Prazosin hydrochloride' },
  { value: '51263151', label: 'Bisoprolol fumarate' },
  { value: '51263162', label: 'Esmolol hydrochloride' },
  { value: '51263169', label: 'Metoprolol tartrate' },
  { value: '51263175', label: 'Timolol maleate' },
  { value: '51263406', label: 'Tamsulosin hydrochloride' },
  { value: '51271622', label: 'Bupivacaine hydrochloride' },
  { value: '51271623', label: 'Bupivacaine hydrochloride monohydrate' },
  { value: '51271629', label: 'Lidocaine hydrochloride' },
  { value: '51271636', label: 'Ropivacaine hydrochloride monohydrate' },
  { value: '51272006', label: 'Thiopental sodium' },
  { value: '51272207', label: 'Ketamine hydrochloride' },
  { value: '51281513', label: 'Ethambutol hydrochloride' },
  { value: '51281632', label: 'Amikacin sulfate' },
  { value: '51281651', label: 'Gentamicin sulfate' },
  { value: '51281659', label: 'Neomycin sulfate' },
  { value: '51281675', label: 'Streptomycin sulfate' },
  { value: '51281710', label: 'Amoxicillin anhydrous' },
  { value: '51281711', label: 'Amoxicillin hydrate' },
  { value: '51281712', label: 'Amoxicillin sodium' },
  { value: '51281713', label: 'Amoxicillin trihydrate' },
  { value: '51281714', label: 'Ampicillin anhydrous' },
  { value: '51281716', label: 'Ampicillin sodium' },
  { value: '51281717', label: 'Ampicillin trihydrate' },
  { value: '51281818', label: 'Doxorubicin hydrochloride' },
  { value: '51281819', label: 'Epirubicin hydrochloride' },
  { value: '51281821', label: 'Idarubicin hydrochloride' },
  { value: '51281823', label: 'Mitoxantrone hydrochloride' },
  { value: '51282208', label: 'Cefoxitin sodium' },
  { value: '51282309', label: 'Azithromycin anhydrous' },
  { value: '51282310', label: 'Azithromycin dihydrate' },
  { value: '51282317', label: 'Erythromycin ethyl succinate' },
  { value: '51282327', label: 'Erythromycin stearate' },
  { value: '51282523', label: 'Cefalexin monohydrate' },
  { value: '51282610', label: 'Cefepime hydrochloride' },
  { value: '51283003', label: 'Clindamycin hydrochloride' },
  { value: '51283006', label: 'Clindamycin phosphate' },
  { value: '51283107', label: 'Caspofungin acetate' },
  { value: '51283108', label: 'Colistimethate sodium' },
  { value: '51283412', label: 'Apalcillin sodium' },
  { value: '51283413', label: 'Rotamicillin' },
  { value: '51283414', label: 'Benzylpenicillin potassium' },
  { value: '51283415', label: 'Tobicillin' },
  { value: '51283416', label: 'Benzylpenicillin sodium' },
  { value: '51283417', label: 'Carfecillin sodium' },
  { value: '51283418', label: 'Pheneticillin potassium' },
  { value: '51283419', label: 'Phenoxymethylpenicillin calcium' },
  { value: '51283420', label: 'Phenoxymethylpenicillin potassium' },
  { value: '51283507', label: 'Cloxacillin sodium' },
  { value: '51283509', label: 'Dicloxacillin sodium' },
  { value: '51283717', label: 'Cefminox sodium' },
  { value: '51283718', label: 'Cefonicid monosodium' },
  { value: '51283719', label: 'Cefonicid sodium' },
  { value: '51283724', label: 'Cefuroxime axetil' },
  { value: '51283725', label: 'Cefuroxime pivoxetil' },
  { value: '51283726', label: 'Cefuroxime sodium' },
  { value: '51283804', label: 'Volpristin' },
  { value: '51283941', label: 'Sulfacetamide sodium' },
  { value: '51284027', label: 'Doxycycline hyclate' },
  { value: '51284035', label: 'Minocycline hydrochloride' },
  { value: '51284043', label: 'Tetracycline hydrochloride' },
  { value: '51284135', label: 'Cefoperazone sodium' },
  { value: '51284136', label: 'Cefotaxime sodium' },
  { value: '51284141', label: 'Ceftazidime pentahydrate' },
  { value: '51284150', label: 'Ceftriaxone sodium' },
  { value: '51284610', label: 'Ertapenem sodium' },
  { value: '51284613', label: 'Meropenem trihydrate' },
  { value: '51285300', label: 'Antibacterial nitrofurans' },
  { value: '51285307', label: 'Nitrofurantoin' },
  { value: '51285600', label: 'Antibacterial alcohols' },
  { value: '51285900', label: 'Antibacterial amines' },
  { value: '51286500', label: 'Antibacterial pyrans' },
  { value: '51286800', label: 'Quinoline antibacterials' },
  { value: '51287000', label: 'Antibacterial sulfones' },
  { value: '51291712', label: 'Venlafaxine hydrochloride' },
  { value: '51292019', label: 'Citalopram hydrobromide' },
  { value: '51292023', label: 'Escitalopram oxalate' },
  { value: '51292027', label: 'Sertraline hydrochloride' },
  { value: '51292921', label: 'Imipramine hydrochloride' },
  { value: '51293012', label: 'Amitriptyline hydrochloride' },
  { value: '51302336', label: 'Isoconazole nitrate' },
  { value: '51302337', label: 'Miconazole nitrate' },
  { value: '51302503', label: 'Terbinafine hydrochloride' },
  { value: '51312105', label: 'Epinastine hydrochloride' },
  { value: '51312309', label: 'Diphenhydramine hydrochloride' },
  { value: '51312405', label: 'Chloropyramine hydrochloride' },
  { value: '51313111', label: 'Cetirizine dihydrochloride' },
  { value: '51313112', label: 'Cetirizine hydrochloride' },
  { value: '51313120', label: 'Levocetirizine dihydrochloride' },
  { value: '51313218', label: 'Levocabastine hydrochloride' },
  { value: '51313305', label: 'Promethazine hydrochloride' },
  { value: '51313415', label: 'Betahistine dihydrochloride' },
  { value: '51313421', label: 'Chlorphenamine maleate' },
  { value: '51313422', label: 'Chlorphenamine tannate' },
  { value: '51313424', label: 'Chlorpheniramine maleate' },
  { value: '51313709', label: 'Ketotifen fumarate' },
  { value: '51321708', label: 'Atorvastatin calcium trihydrate' },
  { value: '51322205', label: 'Rosuvastatin calcium' },
  { value: '51332006', label: 'Paliperidone palmitate' },
  { value: '51332840', label: 'Chlorpromazine hydrochloride' },
  { value: '51332855', label: 'Levomepromazine hydrochloride' },
  { value: '51332880', label: 'Trifluoperazine hydrochloride' },
  { value: '51333413', label: 'Ziprasidone hydrochloride' },
  { value: '51334207', label: 'Quetiapine fumarate' },
  { value: '51341803', label: 'Darunavir ethanolate' },
  { value: '51341805', label: 'Oseltamivir phosphate' },
  { value: '51342311', label: 'Aciclovir sodium' },
  { value: '51342313', label: 'Ganciclovir sodium' },
  { value: '51342316', label: 'Valganciclovir hydrochloride' },
  { value: '51342506', label: 'Abacavir sulfate' },
  { value: '51343509', label: 'Tenofovir alafenamide fumarate' },
  { value: '51343511', label: 'Tenofovir disoproxil' },
  { value: '51351627', label: 'Estradiol valerate' },
  { value: '51361809', label: 'Dexmedetomidine hydrochloride' },
  { value: '51371604', label: 'Tramadol hydrochloride' },
  { value: '51371910', label: 'Methadone hydrochloride' },
  { value: '51372022', label: 'Buprenorphine hydrochloride' },
  { value: '51372031', label: 'Morphine hydrochloride' },
  { value: '51372036', label: 'Morphine sulfate' },
  { value: '51372040', label: 'Nalbuphine hydrochloride' },
  { value: '51372125', label: 'Hydromorphone hydrochloride' },
  { value: '51372127', label: 'Oxycodone hydrochloride' },
  { value: '51372321', label: 'Fentanyl citrate' },
  { value: '51373204', label: 'Tapentadol hydrochloride' },
  { value: '51383315', label: 'Diclofenac sodium' },
  { value: '51383510', label: 'Ketorolac tromethamine' },
  { value: '51384627', label: 'Metamizole sodium' },
  { value: '51391616', label: 'Milrinone lactate' },
  { value: '51392408', label: 'Dobutamine hydrochloride' },
  { value: '51392412', label: 'Dopamine hydrochloride' },
  { value: '51393606', label: 'Indacaterol maleate' },
  { value: '51401569', label: 'Midazolam hydrochloride' },
  { value: '51401570', label: 'Midazolam maleate' },
  { value: '51402206', label: 'Duloxetine hydrochloride' },
  { value: '51413207', label: 'Sildenafil citrate' },
  { value: '51413212', label: 'Vardenafil hydrochloride hydrate' },
  { value: '51421504', label: 'Fluticasone furoate' },
  { value: '51421505', label: 'Fluticasone propionate' },
  { value: '51422122', label: 'Mometasone furoate monohydrate' },
  { value: '51422327', label: 'Betamethasone dipropionate' },
  { value: '51422331', label: 'Betamethasone valerate' },
  { value: '51422340', label: 'Dexamethasone phosphate' },
  { value: '51422342', label: 'Dexamethasone sodium phosphate' },
  { value: '51422354', label: 'Methylprednisolone acetate' },
  { value: '51422356', label: 'Methylprednisolone sodium succinate' },
  { value: '51422364', label: 'Prednisolone sodium phosphate' },
  { value: '51422421', label: 'Fludrocortisone acetate' },
  { value: '51422425', label: 'Hydrocortisone acetate' },
  { value: '51422434', label: 'Hydrocortisone sodium succinate' },
  { value: '51431507', label: 'Tirofiban hydrochloride' },
  { value: '51431614', label: 'Verapamil hydrochloride' },
  { value: '51431708', label: 'Diltiazem hydrochloride' },
  { value: '51432313', label: 'Losartan potassium' },
  { value: '51432315', label: 'Olmesartan medoxomil' },
  { value: '51432806', label: 'Enalapril maleate' },
  { value: '51433305', label: 'Hydralazine hydrochloride' },
  { value: '51433924', label: 'Amlodipine besilate' },
  { value: '51433925', label: 'Amlodipine maleate' },
  {
    value: '51442000',
    label: 'Fluorine-based medical imaging agents, media, and tracers'
  },
  { value: '51452307', label: 'Pyrantel pamoate' },
  { value: '51471505', label: 'Povidone iodine' },
  { value: '51472805', label: 'Chlorhexidine digluconate' },
  { value: '51473020', label: 'Cetrimonium bromide' },
  { value: '53131651', label: 'Personal hygiene kit' },
  {
    value: '64000000',
    label: 'Financial Instruments, Products, Contracts and Agreements'
  },
  { value: '64100000', label: 'Bank offered products ' },
  { value: '64101700', label: 'Electronic fund transfer and payment products' },
  { value: '64101701', label: 'Bill paying application' },
  { value: '64101702', label: 'Currency exchange application' },
  { value: '64101703', label: 'Money transfer application' },
  { value: '64110000', label: 'Securities' },
  { value: '64111700', label: 'Derivative securities' },
  { value: '64111705', label: 'Carbon credit permit or certificate' },
  { value: '64120000', label: 'Insurable interest contracts' },
  { value: '64121500', label: 'Property insurance contracts' },
  { value: '64140000', label: 'Governmental property right conferrals' },
  { value: '80121708', label: 'Insurance law service' },
  { value: '85121811', label: 'Laboratory equipment rental service' },
  { value: '92111607', label: 'Battle area clearance BAC service' },
  { value: '92111608', label: 'Explosive ordnance disposal EOD service' },
  {
    value: '92111609',
    label:
      'Mine action center MAC or mine action coordination center MACC service'
  },
  { value: '92111610', label: 'Post mine clearance inspection service' },
  { value: '92111611', label: 'Demining post design service PDS' },
  { value: '92111612', label: 'Demining preliminary development PD service' },
  { value: '92111613', label: 'Mine victim or survivor assistance service' },
  {
    value: '92111614',
    label: 'Physical security and stockpile management PSSM service'
  },
  {
    value: '70121706',
    label: 'Livestock identification and recording service'
  },
  {
    value: '80101513',
    label: 'Process and procedures management consultation service'
  },
  { value: '81101530', label: 'Fisheries engineering' },
  { value: '81102302', label: 'Space engineering service' },
  { value: '81102800', label: 'Minefield and demining services' },
  {
    value: '81102801',
    label: 'Demining environmental impact assessment EIA service'
  },
  { value: '81102802', label: 'Demining feasibility study FS service' },
  { value: '81102803', label: 'General mine action assessment GMAA service' },
  { value: '81102804', label: 'Demining non technical survey NTS service' },
  {
    value: '81102805',
    label: 'Demining operational analysis (OA) or operational research service'
  },
  { value: '81102806', label: 'Post mine clearance assessment service' },
  {
    value: '81102807',
    label: 'Demining risk analysis or assessment or evaluation service'
  },
  { value: '81102808', label: 'Demining technical survey' },
  { value: '81102809', label: 'Mine landmine impact survey LIS' },
  {
    value: '81111709',
    label: 'Demining geographical or geospatial information system GIS'
  },
  { value: '82160000', label: 'Film and theater production support services' },
  { value: '93141715', label: 'Anthropological research service' },
  { value: '95121812', label: 'Floating structure' },
  { value: '10102100', label: 'Birds and fowl hatching eggs' },
  { value: '10102101', label: 'Chicken hatching eggs' },
  { value: '42121518', label: 'Veterinary medical equipment sets' },
  { value: '42131709', label: 'Surgical sleeves' },
  { value: '42131711', label: 'Surgical headcovers' },
  { value: '42131716', label: 'Surgical shoe covers' },
  { value: '42132109', label: 'Hospital bath mats' },
  { value: '42132110', label: 'Hospital bed skirts or dust ruffles' },
  { value: '42132111', label: 'Hospital bio-hazard bags' },
  { value: '42132112', label: 'Hospital blockade or hamper or laundry bags' },
  { value: '42132113', label: 'Hospital cleanup or room turnover kits' },
  { value: '42132114', label: 'Hospital towels ' },
  { value: '42132115', label: 'Hospital wall coverings' },
  { value: '42132116', label: 'Hospital window treatments' },
  { value: '42143109', label: 'Contraceptive device removers' },
  { value: '42143110', label: 'Perineal heaters' },
  { value: '42143111', label: 'Fetal blood samplers or collecting kits' },
  { value: '42143112', label: 'Obstetrical vacuum delivery systems' },
  { value: '42143113', label: 'Obstetric-gynecologic tubal occlusion valves' },
  {
    value: '42143114',
    label: 'Obstetrics-gynecology abdominal decompression chambers'
  },
  { value: '42143115', label: 'Uterine manipulators or injectors' },
  { value: '42143117', label: 'Uterine device accessories' },
  {
    value: '42143119',
    label: 'Contraceptive device accessories for female users'
  },
  { value: '42143122', label: 'Amniocentesis kit accessories' },
  { value: '42143124', label: 'Obstetrical extraction unit accessories' },
  { value: '42143125', label: 'Gynecology drainage kit accessories' },
  {
    value: '42143126',
    label: 'Obstetrical vacuum delivery system accessories'
  },
  {
    value: '42143127',
    label: 'Acute care fetal or maternal monitoring unit accessories'
  },
  { value: '42143128', label: 'Fetal acoustic stimulators' },
  { value: '42143129', label: 'Fetal monitoring transducers' },
  { value: '42143130', label: 'Intrauterine pressure monitoring cables' },
  { value: '42143131', label: 'Intrauterine pressure monitors' },
  { value: '42143132', label: 'Intrauterine pressure recorders' },
  { value: '42143133', label: 'Intrauterine pressure transducers' },
  { value: '42143134', label: 'Culdocentesis kits' },
  { value: '42144400', label: 'Nonsurgical suction products' },
  { value: '42144401', label: 'Tracheostomy and nasal suctioning kits' },
  { value: '42152016', label: 'Dental x ray viewer accessories' },
  {
    value: '42152900',
    label: 'Dental and subspecialty instrument and device accessories'
  },
  { value: '42171810', label: 'Mobile medical services oxygen generators' },
  {
    value: '42172106',
    label: 'Mobile medical services manual resuscitators or ventilators'
  },
  { value: '42181918', label: 'Multiparameter vital sign unit accessories' },
  { value: '42191713', label: 'Surgical compressed air tank accessories' },
  { value: '42192005', label: 'Hospital diaper or dressing tables' },
  { value: '42192006', label: 'Hospital utility tables' },
  { value: '42192108', label: 'Hospital folding cots' },
  { value: '42192110', label: 'Hospital recliner accessories' },
  { value: '42192215', label: 'Patient transport isolation chambers' },
  { value: '42192217', label: 'Patient transport trolley accessories' },
  { value: '42192220', label: 'Patient transport incubator accessories' },
  { value: '42192407', label: 'Medical cart accessories' },
  { value: '42192426', label: 'Medical equipment bag or case accessories' },
  { value: '42201855', label: 'Medical x ray apertures and cone sets' },
  {
    value: '42201858',
    label: 'Medical x ray apparatus cables and cable assemblies'
  },
  {
    value: '42201861',
    label: 'Medical x ray equipment case or cover accessories'
  },
  {
    value: '42201863',
    label: 'Medical x ray table or stand or chair or cabinet accessories'
  },
  { value: '42202902', label: 'Low energy medical x ray unit accessories' },
  { value: '42203711', label: 'Medical x ray film dryers' },
  { value: '42203712', label: 'Medical x ray temperature controllers' },
  {
    value: '42222011',
    label: 'General hospital analytical sampling infusion pumps'
  },
  {
    value: '42271807',
    label: 'Metered dose inhaler spacers or holding chambers'
  },
  { value: '42271919', label: 'Nasopharyngeal airway kits' },
  { value: '42271931', label: 'Mucus extractors and specimen traps' },
  { value: '42272230', label: 'Ventilator circuit accessories' },
  {
    value: '42291632',
    label: 'Surgical scalpel or knife or blade or trephine accessories'
  },
  {
    value: '42293511',
    label: 'Medical or surgical suction or vacuum appliance accessories'
  },
  { value: '42293514', label: 'Medical or surgical suction container liners' },
  { value: '42293515', label: 'Medical or surgical suction tubings' },
  {
    value: '42293525',
    label: 'Medical or surgical floor suction mats or apparatus'
  },
  {
    value: '42295150',
    label:
      'Delivery room or cesarean section patient procedure table accessories'
  },
  {
    value: '42295167',
    label:
      'Surgical suction machine or vacuum extractor or ultrasonic Surgical aspirator or regulator accessories'
  },
  { value: '42295480', label: 'Surgical customizable packs' },
  { value: '42296300', label: 'Endoscopes' },
  { value: '42296324', label: 'ENT Laryngoscopes' },
  { value: '42296400', label: 'Endoscopic equipment' },
  { value: '42296700', label: 'Surgical bone cements and related products' },
  { value: '42296800', label: 'Surgical instruments and accessories' },
  { value: '42311546', label: 'Adhesive dry bandages' },
  { value: '42311606', label: 'Topical hemostatic agents' },
  { value: '42311607', label: 'Surgical hemostatic agents' },
  { value: '46171641', label: 'Dummy security camera' },
  { value: '51111876', label: 'Leuprorelin' },
  { value: '51181629', label: 'Levothyroxine sodium' },
  { value: '51384803', label: 'Phenazopyridine' },
  { value: '51473704', label: 'Sodium hypochlorite' },
  { value: '64141700', label: 'Environmental mitigation banking products' },
  { value: '64141701', label: 'Environmental mitigation credit' },
  { value: '77140000', label: 'Environmental laboratory services' },
  { value: '78111505', label: 'Passenger aircraft rental or leasing service' },
  {
    value: '85210000',
    label: 'Diagnoses of infectious and parasitic diseases-part a'
  },
  {
    value: '85217100',
    label:
      'Diagnoses of human immunodeficiency virus hiv disease and related conditions'
  },
  {
    value: '85260000',
    label: 'Diagnoses of endocrine, nutritional and metabolic diseases  '
  },
  {
    value: '85261600',
    label:
      'Diagnoses of iodine-deficiency-related thyroid disorders and allied conditions'
  },
  {
    value: '85270000',
    label: 'Diagnoses of mental and behavioral disorders  '
  },
  {
    value: '85380000',
    label: 'Diagnoses of certain conditions originating in the perinatal period'
  },
  { value: '85383300', label: 'Diagnoses of neonatal aspiration syndromes' },
  { value: '25101713', label: 'Armored ambulance' },
  { value: '25101714', label: 'Armored recovery vehicle' },
  { value: '46151802', label: 'Armoured Container' },
  { value: '53102718', label: 'Firefighter uniform' },
  { value: '78111506', label: 'Medical evacuation by air ambulance' },
  { value: '78111815', label: 'Medical evacuation by ambulance' },
  {
    value: '78181818',
    label: 'Aircraft fixed wing communication system repair'
  },
  {
    value: '81102810',
    label: 'Counter improvised explosive device IED training'
  },
  { value: '81102811', label: 'Canine explosive detection team' },
  {
    value: '25202907',
    label: 'Aeronautical Information Management System (AIM)'
  },
  { value: '57030400', label: 'Personal protective equipment' },
  { value: '51131524', label: 'Iron Dextran' },
  { value: '27113401', label: 'Table saw' },
  { value: '42271651', label: 'Compressible self-refilling ventilation bag ' },
  { value: '50501715', label: 'Unimix (WFP food convention)' },
  {
    value: '57070105',
    label: 'Personal protection equipment, power and water supply'
  },
  { value: '51191970', label: 'Hydroxocobalamin or vitamin B12a' },
  { value: '45111401', label: 'Interpretation Systems (Audio)' },
  { value: '46191623', label: 'Cartridges For Extinguishers' },
  { value: '51131526', label: 'Methoxy polyethylene glycol-epoetin beta' },
  { value: '50151519', label: 'Rapeseed Oil (WFP food convention)' },
  { value: '46221511', label: 'Mine rollers' },
  { value: '57060301', label: 'Office kits for UN staff' },
  { value: '25131510', label: 'Aircraft, Single Engine' },
  { value: '51111781', label: 'Olaparib' },
  { value: '57030304', label: 'Sudden-onset wash and dignity kit' },
  { value: '86101406', label: 'Training - Satellite Navigation Equipment ' },
  { value: '25202904', label: 'Digital Voice Recording And Playback System ' },
  { value: '51122606', label: 'Ezetimibe/Atorvastatin' },
  { value: '50151517', label: 'Canola Oil (WFP food convention)' },
  { value: '25175201', label: 'Ambulance Accessories' },
  { value: '86101400', label: 'Aviation Specific Training' },
  { value: '46221513', label: 'Mine Detector' },
  {
    value: '25191528',
    label: 'Aircraft And Airborne Equipment For Fire Fighting & Rescue'
  },
  { value: '86101404', label: 'Training - Airfield Lighting' },
  { value: '51181932', label: 'Lutropin alfa' },
  { value: '50501716', label: 'Vitacereal (WFP food convention) ' },
  { value: '39111625', label: 'Approach Lighting System, Mast' },
  { value: '51201660', label: 'Ebola vaccine' },
  { value: '51201575', label: 'Ocrelizumab' },
  {
    value: '57050103',
    label: 'Nutritional kit - inpatient module, medical supplies'
  },
  { value: '50221115', label: 'Hard Wheat (WFP food convention)' },
  { value: '51192459', label: 'Paricalcitol' },
  {
    value: '51162041',
    label: 'Beclometasone dipropionate/formoterol fumarate'
  },
  { value: '25191550', label: 'Preconditioned Air Unit (PCA)' },
  { value: '43232620', label: 'Cospas-Sarsat Software ' },
  { value: '46171645', label: 'Mobile X-Ray Systems ' },
  { value: '50221111', label: 'Buckwheat (WFP food convention)' },
  { value: '42143136', label: 'Obstetric Surgical Kit' },
  { value: '51111786', label: 'Pertuzumab' },
  { value: '53102719', label: 'Work uniforms' },
  { value: '50501713', label: 'Super Cereal (WFP food convention) ' },
  { value: '22101632', label: 'Paint Stripping Machine' },
  { value: '51131665', label: 'Acetylsalicylic acid/Clopidogrel bisulfate' },
  {
    value: '81161714',
    label:
      'Aeronautical Fixed Telecommunication Network/Aeronautical Telecommunications Network Services (AFTN and ATN)'
  },
  { value: '51343813', label: 'Sofosbuvir/Velpatasvir' },
  { value: '50408000', label: 'Roots and Tubers' },
  { value: '48101926', label: 'Folding table' },
  { value: '57030101', label: 'Midwifery kit' },
  { value: '41170000', label: 'Diagnostic and microbiological devices' },
  { value: '41114429', label: 'Hydrogen Generator' },
  { value: '27113205', label: 'Threading Pipe Tool Kit' },
  { value: '41151600', label: 'Clinical laboratory instruments' },
  { value: '51112032', label: 'Crizotinib' },
  { value: '82111403', label: 'Editorial Services - Spanish' },
  { value: '57060400', label: 'Multipurpose emergency kits' },
  {
    value: '51131828',
    label: 'Fibrinogen/Factor XIII+Aprotinin+Thrombin+Calcium chloride'
  },
  { value: '57080104', label: 'IT kit for office' },
  { value: '57020101', label: 'Humanitarian relief flight operations' },
  { value: '57040201', label: 'Recreation kit' },
  { value: '51343816', label: 'Lamivudine/tenofovir DF' },
  { value: '57333200', label: 'Emergency latrine facility' },
  { value: '57040100', label: 'Educational kits' },
  { value: '25191532', label: 'Oxygen Filling Cart ' },
  { value: '46181566', label: 'Unisex visibility vest' },
  {
    value: '39111627',
    label: 'Precision Approach Path Indicator System (PAPI) '
  },
  { value: '50501712', label: 'Likuni Phala (WFP food convention) ' },
  { value: '57030111', label: 'Pediatric kit' },
  { value: '51181578', label: 'Dapagliflozin' },
  { value: '86101416', label: 'Training - Aircraft Structural Repair ' },
  { value: '42132300', label: 'Disease prevention textiles' },
  { value: '51241886', label: 'Cupric sulfate/zinc sulfate/camphor' },
  { value: '51191967', label: 'L-ornitin/ L-aspartate' },
  { value: '51241262', label: 'Almond oil' },
  { value: '51192317', label: 'Dextrose/Sodium chloride' },
  { value: '43211522', label: 'Common User Self Service Kiosk (CUSS)' },
  { value: '57060100', label: 'Emergency clothing kits' },
  { value: '80141635', label: 'Conference fees' },
  { value: '47101576', label: 'Chlorine Granules' },
  { value: '25191554', label: 'Airport or Aviation billing system' },
  { value: '25101947', label: 'Drilling truck/Auger truck' },
  { value: '46171646', label: 'Mobile Backscatter X-Ray System' },
  { value: '51111784', label: 'Atezolizumab' },
  { value: '50151520', label: 'Soya bean Oil (WFP food convention)' },
  { value: '50202312', label: 'Infusions' },
  { value: '25191539', label: 'Baggage Reconciliation System' },
  {
    value: '25173122',
    label:
      'Instrument Landing System And Instrument Landing System/Distance Measuring Equipment (DME) System'
  },
  { value: '25173111', label: 'Satellite Navigation Equipment ' },
  { value: '50424007', label: 'Split Green Peas (WFP food convention)' },
  { value: '41116225', label: 'Multiparametric monitor ' },
  {
    value: '25173116',
    label:
      'Global Positioning System (GPS) Receiver Autonomous Integrity Monitoring'
  },
  {
    value: '25173125',
    label: 'Instrument Landing System (ILS) Glide Path Equipment '
  },
  { value: '24141521', label: 'Fumigation sheet' },
  { value: '51131833', label: 'Fibrinogen+calcium chloride/thrombin' },
  { value: '57030110', label: 'Non communicable diseases kit' },
  { value: '51191607', label: 'Dextrose' },
  { value: '42132301', label: 'Insecticide-treated bed net' },
  { value: '51433707', label: 'Macitentan' },
  { value: '30181519', label: 'Latrine / squatting plate' },
  { value: '57666400', label: 'Multipurpose emergency kits' },
  { value: '78204009', label: 'Capacity Analysis (Aviation)' },
  {
    value: '57020000',
    label: 'Services related to humanitarian relief actions'
  },
  { value: '53131666', label: 'Plastic potty for infant' },
  { value: '51111780', label: 'Durvalumab' },
  { value: '22101633', label: 'Pavement Crack Sealing Equipment' },
  { value: '50421856', label: 'Mixed Beans' },
  { value: '39111630', label: 'Aerodrome Beacon ' },
  { value: '50425408', label: 'Whole Yellow Peas (WFP food convention)' },
  { value: '57030302', label: 'Adult hygiene and personal care set' },
  { value: '51112026', label: 'Cabozantinib' },
  { value: '51171630', label: 'Sodium phosphate/sodium citrate' },
  { value: '50425409', label: 'Split Peas (WFP food convention)' },
  { value: '25191552', label: 'Cart, Baggage (Passenger)' },
  { value: '42192433', label: 'Thermal evacuation bag' },
  { value: '86101417', label: 'Training Course - Airline Operations ' },
  { value: '51434963', label: 'Valsartan/Sacubitrilo' },
  { value: '57040105', label: 'School kit for students' },
  { value: '41115872', label: 'Clinical Chemistry Analyser, portable' },
  { value: '51112034', label: 'Osimertinib' },
  { value: '53101807', label: 'Jacket, Winter' },
  { value: '51102907', label: 'Amodiaquine/artesunate' },
  { value: '51172338', label: 'Calcium folinate' },
  { value: '80141410', label: 'Lead generation' },
  { value: '51111877', label: 'Enzalutamide' },
  { value: '95141713', label: 'Mobile Storage Unit (MSU)' },
  { value: '53103300', label: 'Clothing and Textiles kit for Newborn Infants' },
  { value: '53103300', label: 'Clothing and Textiles kit for Newborn Infants' },
  { value: '51201571', label: 'Blinatumomab' },
  {
    value: '78204013',
    label: 'Quality & Environmental Management System For Airport & ATS'
  },
  { value: '51112020', label: 'Ceritinib' },
  { value: '57070000', label: 'Humanitarian logistics supplies' },
  { value: '25191527', label: 'Helicopter Parts and Accessories' },
  { value: '57050205', label: 'Emergency ration' },
  { value: '51111778', label: 'Alemtuzumab' },
  { value: '25202801', label: 'ATS Message Handling System (AMHS)' },
  { value: '51183623', label: 'Insulin degludec' },
  { value: '80141412', label: 'Offline media buying' },
  { value: '25173129', label: 'Direction Finding Equipment ' },
  { value: '43202223', label: 'Power Supply, Computer ' },
  { value: '50421855', label: 'Red Beans (WFP food convention) ' },
  { value: '57050104', label: 'Nutritional kit - inpatient, module-equipment' },
  {
    value: '51162042',
    label:
      'Beclomethasone dipropionate/formoterol fumarate/glycopyrronium bromide'
  },
  {
    value: '78204002',
    label: 'Flight Operations Automation, Design And Construction'
  },
  { value: '57060202', label: 'Kitchen set, varying sizes' },
  { value: '42231817', label: 'Plumpy Nut (WFP standard)' },
  {
    value: '51131832',
    label: 'Coagulation factor VIII/Von Willebrand Factor Human'
  },
  { value: '43191517', label: 'High Frequency Radio Set, Desktop' },
  { value: '57888100', label: 'Emergency IT equipment kits' },
  { value: '42181547', label: 'Pediatric kit' },
  {
    value: '70190000',
    label:
      'Agricultural machinery and equipment assembly and installation services'
  },
  { value: '43221750', label: 'Cellular Radio-Mobile Systems And Terminals ' },
  { value: '25101943', label: 'Runway Sweeper, Towed ' },
  {
    value: '78181704',
    label: 'Fuel top up card service / Fuel prepaid card service'
  },
  { value: '80141407', label: 'Donor acquisition services' },
  { value: '78204017', label: 'Civil Aviation Master Plan Development' },
  { value: '82111402', label: 'Editorial Services - French' },
  { value: '42201728', label: 'Conductive gel container' },
  { value: '57040104', label: 'Science teaching kit' },
  { value: '57030300', label: 'Water, sanitation and hygiene kits' },
  { value: '51434961', label: 'Amlodipine/Perindopril arginine' },
  { value: '25101715', label: 'Mine Protected Vehicles - Ambulance ' },
  { value: '51191968', label: 'Alpha keto amino acid analogs' },
  { value: '41171600', label: 'Microbiology devices' },
  {
    value: '86101411',
    label: 'Training - Airport Pavement Evaluation And Maintenance '
  },
  { value: '50501711', label: 'Inka Mix (WFP food convention)' },
  {
    value: '42231818',
    label: 'Ready to Use Supplementary Food (WFP convention)'
  },
  { value: '25191541', label: 'Servicing Platform' },
  { value: '25191546', label: 'Fixed Passenger Walkway ' },
  { value: '41116029', label: 'Anti B serum, monoclonal' },
  {
    value: '57070101',
    label: 'Early identification kit for unaccompanied children'
  },
  { value: '51201653', label: 'COVID19 - Moderna' },
  { value: '50221306', label: 'Wheat Flour (WFP food convention)' },
  { value: '42142630', label: 'Syringe, AUTO-DISABLE with needle' },
  { value: '86101718', label: 'Training Course - Humanities And Language ' },
  {
    value: '70190100',
    label:
      'Training services for users of agricultural machinery and equipment '
  },
  { value: '46171648', label: 'Wildlife Control Equipment' },
  { value: '51151674', label: 'Pinaverium bromide' },
  { value: '51201571', label: 'Blinatumomab' },
  { value: '50151521', label: 'Sunflower Oil (WFP food convention)' },
  { value: '25175203', label: 'Search And Rescue Vehicle Accessories' },
  { value: '50501715', label: 'Unimix (WFP food convention)' },
  { value: '43221741', label: 'VHF Transmitters and Associated Equipment ' },
  { value: '57030108', label: 'Reproductive health kit' },
  {
    value: '51182441',
    label: 'Cholecalciferol/Risedronate sodium or risedronic acid'
  },
  { value: '42142629', label: 'Syringe feeding ENFit' },
  { value: '57060101', label: 'Winter clothing kit for children and infants' },
  { value: '41114428', label: 'Meteorological Balloon ' },
  { value: '10191520', label: 'Non-chemical biological pest control solution' },
  { value: '57050000', label: 'Humanitarian relief food' },
  { value: '25101716', label: 'Mine Protected Vehicles – Recovery Vehicle' },
  { value: '50501723', label: 'Indiamix (WFP food convention)' },
  { value: '30241705', label: 'Winterization kit for tent ' },
  { value: '51201573', label: 'Nivolumab' },
  { value: '51343809', label: 'Dolutegravir/Abacavir/Lamivudine' },
  { value: '50221114', label: 'Soft Wheat (WFP food convention)' },
  { value: '43221746', label: 'VHF Transceiver, AM' },
  { value: '25191526', label: 'Aircraft Parts And Accessories' },
  { value: '57030112', label: 'Diarrheal disease kit' },
  { value: '40151585', label: 'Treadle pumps' },
  { value: '51201576', label: 'Tofacitinib' },
  {
    value: '51343811',
    label: 'Emtricitabine/Tenofovir Alafenamide/Bictegravir'
  },
  { value: '42231814', label: 'Plumpy Doz (WFP standard)' },
  { value: '51341807', label: 'Daclatasvir' },
  { value: '41111772', label: 'LCR Meter ' },
  { value: '46182010', label: 'Respiratory mask FFP2/N95' },
  {
    value: '50465307',
    label: 'Canned or jarred Peanut Butter (WFP food convention) '
  },
  {
    value: '51241555',
    label: 'Chloramphenicol or chloramphenicolum/Sulfacetamide sodium'
  },
  { value: '57070103', label: 'Power supply' },
  { value: '80141400', label: 'Fundraising Services' },
  { value: '43191521', label: 'Very High Frequency Radio Set, Handheld' },
  { value: '42182708', label: 'Portable baby/child measuring set' },
  { value: '26131511', label: 'Generating Set, Gasoline ' },
  { value: '51112031', label: 'Lenvatinib' },
  {
    value: '24113110',
    label: 'Customised, Multipurpose Box e.g Baby box, school box'
  },
  { value: '80141633', label: 'Search Engine Marketing ' },
  { value: '25101942', label: 'Runway Sweeper, Self-Propelled ' },
  { value: '51434962', label: 'Atorvastatin/Acetylsalicylic acid/Ramipril' },
  { value: '57040200', label: 'Recreational kits' },
  {
    value: '25101615',
    label: 'Swap truck chassis/Platform lifting system truck'
  },
  { value: '57020100', label: 'Humanitarian operational services' },
  {
    value: '25173117',
    label:
      'Conventional VHF Omnidirectional Range (CVOR) And HF Omnidirectional Range (VOR)/Distance Measuring Equipment (DME) Systems '
  },
  { value: '81102815', label: 'Explosive Detection Dogs (EDD) with handler' },
  { value: '41106315', label: 'Near patient PCR machine' },
  { value: '57060302', label: 'Refugee Registration Package' },
  { value: '57060401', label: 'Tent, for multipurpose use' },
  { value: '42231816', label: 'Ready to Use Therapeutic Food (WFP standard)' },
  { value: '57060203', label: 'Accessories for tent' },
  {
    value: '57050102',
    label: 'Nutritional kit - outpatient, module registration'
  },
  { value: '57030103', label: 'Obstetric, surgical kit' },
  { value: '51242209', label: 'Fluocinolone/lidocaine/neomycin/polymyxin' },
  { value: '92121709', label: 'Human Security Screening System ' },
  { value: '25191538', label: 'Water Servicing Unit ' },
  { value: '81161713', label: 'Aeronautical Information Services' },
  { value: '80141401', label: 'Brand measurement' },
  {
    value: '25101718',
    label: 'Aircraft Rescue And Firefighting, 1500 Gallons (6000 Litres'
  },
  { value: '51171984', label: 'Agalsidase beta' },
  { value: '51434960', label: 'Amlodipine/Indapamide/Perindopril arginine' },
  { value: '41114431', label: 'Transmissometer ' },
  { value: '57040300', label: 'Early childhood development' },
  { value: '51112033', label: 'Baricitinib' },
  { value: '51112028', label: 'Nintedanib' },
  {
    value: '51283424',
    label:
      'Benzylpenicillin sodium/Procaine benzylpenicillin or procaine penicillin G'
  },
  { value: '51183622', label: 'Insulin aspart/Insulin aspart protamine' },
  {
    value: '81112224',
    label: 'ANS & ATM Software Maintenance/Development Services'
  },
  { value: '42192434', label: 'Thermal evacuation bag' },
  { value: '57050203', label: 'Compact food' },
  { value: '53131665', label: 'Female hygiene and personal care set' },
  { value: '25191537', label: 'Baggage Handling System (BHS) ' },
  {
    value: '51143112',
    label: 'Proteolytic peptide derived from porcine brain'
  },
  { value: '57030107', label: 'Primary healthcare kit' },
  { value: '57030200', label: 'Emergency latrine facility' },
  { value: '78203000', label: 'Airline Cabin And Support Services' },
  {
    value: '25173113',
    label:
      'Global navigation satellite system (GNSS) Wide Area Augmentation System (WAAS) '
  },
  { value: '43191518', label: 'High Frequency Radio Set, Mobile' },
  { value: '25191529', label: 'Nitrogen Filling Cart ' },
  { value: '51181931', label: 'Lutropin (LH)/Follitropin (FSH)' },
  { value: '51182710', label: 'Metformin/linagliptin' },
  { value: '51111779', label: 'Carfilzomib' },
  { value: '78204003', label: 'Air Transport Demand Forecast Services' },
  { value: '57030301', label: 'Female hygiene and personal care set' },
  { value: '42311555', label: 'Transparent adhesive plasters wash proof' },
  { value: '50121541', label: 'Canned Fish (WFP food convention)' },
  { value: '57050201', label: 'Supplementary feeding/nursing kit' },
  { value: '39111629', label: 'Obstacle Lighting' },
  { value: '57555200', label: 'Food emergency rations' },
  { value: '25202911', label: 'Multilateration Surveillance (MLAT)' },
  { value: '50151518', label: 'Palmolein Oil (WFP food convention)' },
  { value: '51182711', label: 'Glimepiride/metformin' },
  { value: '25173123', label: 'Instrument Landing System (ILS)' },
  { value: '51112021', label: 'Palbociclib' },
  { value: '51201652', label: 'COVID19 - Pfizer Biontech' },
  { value: '86101401', label: 'Training  - Avionics ' },
  { value: '57050204', label: 'CSB Plus' },
  { value: '81162202', label: 'Cloud storage as a service' },
  { value: '25191537', label: 'Baggage Handling System (BHS) ' },
  {
    value: '25101719',
    label: 'Aircraft Rescue And Firefighting Unit, 150 Gallons (600 litres)'
  },
  { value: '51434964', label: 'Acetylsalicylic acid/ramipril/simvastatin' },
  { value: '51433708', label: 'Riociguat' },
  { value: '25191545', label: 'Reduced Mobility Passenger Loader' },
  { value: '51441619', label: 'Radium-223 dichloride' },
  { value: '46151802', label: 'Armoured Container' },
  {
    value: '57030102',
    label: 'Acute watery diarrhea kits for children and infants'
  },
  { value: '57050200', label: 'Food emergency rations' },
  { value: '51172490', label: 'Laronidase' },
  { value: '57030305', label: 'Water, sanitation and hygiene kit' },
  { value: '25173132', label: 'Direction Finding Equipment, Accessories ' },
  { value: '43211734', label: 'Travel Document Reading Equipment' },
  { value: '25202900', label: 'Air Traffic Management Equipment' },
  { value: '50501718', label: 'High Energy Biscuits (WFP food convention) ' },
  { value: '50221111', label: 'Buckwheat (WFP food convention)' },
  { value: '51241144', label: 'Aflibercept' },
  {
    value: '51461607',
    label:
      'Psyllium hydrophilic mucilloid (Plantago ovata)/Senna glycosides or sennosides'
  },
  { value: '50221113', label: 'Durum Wheat (WFP food convention)' },
  { value: '25202906', label: 'Aeronautical Information System (AIS) ' },
  { value: '50425407', label: 'Pigeon Peas' },
  { value: '25191550', label: 'Preconditioned Air Unit (PCA)' },
  { value: '57070104', label: 'Stationery and PPE' },
  {
    value: '25191544',
    label: 'Trailer, Baggage/Cargo (Towable & Self-Propelled) '
  },
  { value: '41151629', label: 'Lab screening test kit' },
  { value: '50121542', label: 'Dried Fish (WFP food convention)' },
  { value: '25202902', label: 'Automatic Dependent Surveillance (ADS) ' },
  { value: '78204000', label: 'Aviation-Specific Services' },
  { value: '51441619', label: 'Radium-223 dichloride' },
  { value: '50221112', label: 'Bulgur Wheat (WFP food convention)' },
  { value: '51201659', label: 'COVID 19 - Unspecified' },
  { value: '51201657', label: 'Malaria vaccine' },
  { value: '50501713', label: 'Super Cereal (WFP food convention) ' },
  { value: '51321505', label: 'Alirocumab' },
  {
    value: '25202905',
    label: 'Automatic Terminal Information Service (ATIS) Equipment '
  },
  {
    value: '78204010',
    label:
      'Airport And Passenger Terminals Simulation Modelling (Airside And Landside)'
  },
  { value: '51386615', label: 'Collagen/polyvinylpyrrolidone' },
  { value: '78201000', label: 'Airport Maintenance' },
  { value: '43191519', label: 'Very High Frequency Radio Set, Desktop' },
  {
    value: '25202803',
    label: 'Communications Control And Monitoring Equipment '
  },
  {
    value: '43221537',
    label: 'Intercom System Maintenance Tools and Accessories'
  },
  {
    value: '86101419',
    label: 'Training - Ground Support Equipment Operation (On-Site)'
  },
  { value: '46221512', label: 'Mine Protected Vehicles - Interrogation Arm' },
  {
    value: '25101717',
    label: 'Aircraft Rescue And Firefighting, 3000 Gallons (12,000 Litres)'
  },
  { value: '25191543', label: 'Solid Waste Disposal Equipment for Aircraft' },
  { value: '51201655', label: 'COVID19 - AstraZeneca' },
  { value: '46161537', label: 'Taxiing Guidance Sign ' },
  { value: '43231606', label: 'Billing and Invoicing Software' },
  { value: '51201574', label: 'Palivizumab' },
  { value: '78204018', label: 'Airport, Fauna Control Services' },
  { value: '14122108', label: 'Wrapping Crepe' },
  { value: '46161536', label: 'Automated Parking Control System ' },
  { value: '86101405', label: 'Training - Ground Navigational Aids ' },
  { value: '48101921', label: 'Bucket, Food proof plastic' },
  { value: '57080000', label: 'Humanitarian IT equipment' },
  { value: '80141406', label: 'Direct response TV services' },
  { value: '39111624', label: 'Approach Lighting System ' },
  { value: '51285004', label: 'Ceftolozane/Tazobactam' },
  { value: '51172481', label: 'Taliglucerase alfa' },
  { value: '78204004', label: 'Aviation-Related Safety Audits Services' },
  { value: '46182008', label: 'Compressed Air Breathing Apparatus (CABA)' },
  { value: '42142627', label: 'Insulin syringe' },
  { value: '51162038', label: 'Indacaterol/Glycopyrronium bromide' },
  { value: '51181579', label: 'Empagliflozin' },
  { value: '86101407', label: 'Training - Communications Systems ' },
  { value: '57222100', label: 'Humanitarian operational services' },
  { value: '57060303', label: 'Tent, for office use' },
  {
    value: '25202903',
    label: 'Air Traffic Services (ATS) Consoles And Console Equipment '
  },
  { value: '41112523', label: 'Fuel flowmeter' },
  { value: '25131401', label: 'Rescue And Firefighting Aircraft, 150 Gallons' },
  { value: '42271729', label: 'Laryngeal mask airway (LMA)' },
  {
    value: '50501708',
    label: 'Soya-fortified Maize Meal (WFP food convention)'
  },
  { value: '53131662', label: 'Baby/infant shampoo ' },
  { value: '43221531', label: 'Voice Communications System' },
  { value: '57040103', label: 'Arabic student kits and teacher kit' },
  { value: '43221534', label: 'Terrestrial Trunked Radio (TETRA)' },
  { value: '46171644', label: 'Flammable Vapour Detector' },
  { value: '51201656', label: 'COVID19 - Johnson & Johnson' },
  { value: '25191535', label: 'Aircraft Cabin Cleaning Unit ' },
  { value: '25202909', label: 'Selective Calling Equipment (Selcal) ' },
  {
    value: '42192800',
    label: 'Medical and pharmaceutical biohazard disposal products'
  },
  { value: '25191540', label: 'Catering Service Unit ' },
  { value: '57333100', label: 'Health emergency kits' },
  { value: '51131525', label: 'Ferrous fumarate' },
  { value: '50501717', label: 'Corn-Soya Milk (WFP food convention) ' },
  { value: '51201654', label: 'COVID19 - Sputnik V' },
  { value: '25191537', label: 'Baggage Handling System (BHS) ' },
  { value: '46181555', label: 'Protective cloth mask' },
  { value: '51162039', label: 'Vilanterol/Fluticasone furoate' },
  { value: '51321904', label: 'Evolocumab' },
  { value: '57333300', label: 'Water, sanitation and hygiene kits' },
  { value: '43221745', label: 'HF Transceiver' },
  { value: '50501720', label: 'Pea Wheat Blended (WFP food convention) ' },
  { value: '57555300', label: 'Food emergency kits' },
  { value: '27113403', label: 'Carpenter pencil' },
  {
    value: '25173118',
    label: 'Doppler VHF Omnidirectional Range (DVOR) And DVOR/DME Systems'
  },
  {
    value: '25173119',
    label: 'Doppler VHF Omnidirectional Range (DVOR) Complete Ground Station '
  },
  { value: '57030104', label: 'Oral-rehydration-points consumable kit' },
  { value: '25173130', label: 'Direction Finder ' },
  { value: '86101412', label: 'Training - Flight Inspection Equipment ' },
  { value: '51343204', label: 'Dolutegravir' },
  { value: '51132300', label: 'Combination volume expanders' },
  { value: '57010000', label: 'Survival supplies' },
  { value: '57070100', label: 'Logistics emergency kits' },
  { value: '57080102', label: 'Emergency operations room equipment kit' },
  {
    value: '25173120',
    label:
      'Doppler VHF Omnidirectional Range (DVOR)/Distance Measuring Equipment (DME) Systems '
  },
  { value: '25101946', label: 'Water Tanker ' },
  { value: '57444200', label: 'Recreational kits' },
  { value: '57030401', label: 'Ebola, personal protective kit' },
  { value: '25191533', label: 'Airport Information System ' },
  { value: '51172485', label: 'Agalsidase alfa' },
  { value: '41111530', label: 'Electronic weigh scale ' },
  { value: '51112022', label: 'Ibrutinib' },
  { value: '27113402', label: 'Woodturning set' },
  { value: '51434959', label: 'Indapamide/Perindopril arginine' },
  {
    value: '51373341',
    label: 'Naloxone hydrochloride dihydrate/Oxycodone hydrochloride'
  },
  { value: '51171858', label: 'Netupitant/Palonosetron' },
  { value: '51151675', label: 'Butylscopolamine/metamizole sodium (dipyrone)' },
  { value: '46161535', label: 'Visual Docking Guidance System' },
  { value: '12352324', label: 'Calcium HC, chemical pool product' },
  {
    value: '25173126',
    label: 'Instrument Landing System (ILS)/Distance Measuring Equipment (DME)'
  },
  { value: '24112113', label: 'Static fuel drum' },
  {
    value: '25173124',
    label: 'Instrument Landing System (ILS) Localizer Equipment '
  },
  { value: '57444100', label: 'Educational kits' },
  { value: '43221737', label: 'Cospas-Sarsat Ground Stations' },
  { value: '41111529', label: 'Baggage Scale, Check-In Counter ' },
  { value: '41121523', label: 'Sterile Diluents for PPR Vaccine' },
  {
    value: '57050101',
    label: 'Nutritional kit - outpatient, module-equipment'
  },
  { value: '84131613', label: 'Claims payment processing or administration' },
  { value: '24112807', label: 'Storage and transit units' },
  { value: '57080103', label: 'Emergency computer equipment for UN staff' },
  {
    value: '51162357',
    label: 'Acetaminophen/Caffeine/Chlorpheniramine/Phenylephrine'
  },
  { value: '43221739', label: 'HF Transmitter and Associated Equipment ' },
  { value: '60105627', label: 'Medical educational or information material' },
  { value: '43191520', label: 'Very High Frequency Radio Set, Mobile' },
  { value: '57010103', label: 'Individual kit' },
  { value: '25101948', label: 'Workshop truck' },
  { value: '25175202', label: 'Fire Fighting Vehicle Accessories' },
  { value: '92121710', label: 'Controlled Access Identification System ' },
  { value: '83101606', label: 'Supply of fuel' },
  { value: '57666100', label: 'Emergency clothing kits' },
  { value: '57111100', label: 'Rapid response kits' },
  { value: '51172483', label: 'Elosulfase alfa' },
  { value: '51352435', label: 'Cyproterone/Estradiol + Estradiol' },
  { value: '92111616', label: 'Mine Action and ERW: Advocacy' },
  { value: '42272020', label: 'Videolaryngoscope' },
  { value: '57060204', label: 'Plastic / Tarpaulin sheeting' },
  { value: '86101403', label: 'Training - Search and Rescue ' },
  {
    value: '57050105',
    label: 'Nutritional kit - inpatient, module-registration'
  },
  { value: '51201658', label: 'Cholera vaccine' },
  { value: '40151583', label: 'Hydraulic Pump' },
  { value: '50404509', label: 'Whole Red Lentils (WFP food convention)' },
  { value: '57333400', label: 'Personal protective equipment (PPE)' },
  { value: '92121708', label: 'Biometric Security Systems' },
  { value: '24112806', label: 'Static fuel container ' },
  { value: '25191542', label: 'Ground Vehicle Tracking System ' },
  { value: '25173134', label: 'Global Positioning System (GPS) Complete ' },
  { value: '57060000', label: 'Shelter and relief items' },
  { value: '78181842', label: 'Airworthiness And Aircraft Certification ' },
  { value: '51343826', label: 'Sofosbuvir/Velpatasvir' },
  { value: '51241263', label: 'Zinc oxide' },
  { value: '25101944', label: 'Runway Rubber Removal Machine' },
  { value: '78204015', label: 'Airport Maps, Networks (GIS), Studies' },
  { value: '57444300', label: 'Early childhood development' },
  { value: '57010100', label: 'Rapid response kits' },
  { value: '25202908', label: 'Volmet Equipment ' },
  { value: '53103300', label: 'Clothing and Textiles kit for Newborn Infants' },
  { value: '50421857', label: 'White Beans (WFP food convention)' },
  { value: '51112037', label: 'Midostaurin' },
  {
    value: '86101420',
    label: 'Training - Baggage And Cargo Handling Equipment (On-Site) '
  },
  {
    value: '25131403',
    label: 'Rescue And Firefighting Aircraft,1500 Gallons (6,000 Litres)'
  },
  { value: '51351636', label: 'Estradiol/trimegestone' },
  { value: '43221532', label: 'Radio Link Systems ' },
  { value: '27113400', label: 'Carpentry Tools' },
  { value: '57040102', label: 'Math teaching kit' },
  { value: '51131831', label: 'Emicizumab' },
  { value: '50424006', label: 'Whole Green Peas (WFP food convention)' },
  { value: '57555100', label: 'Emergency nutritional kits' },
  {
    value: '25202802',
    label: 'Aeronautical Fixed Telecommunications Network (AFTN)'
  },
  { value: '51113503', label: 'Venetoclax' },
  { value: '43221735', label: 'Very Small Aperture Terminal (VSAT) System' },
  { value: '57070102', label: 'Logistic power supplies' },
  { value: '51162104', label: 'Ethambutol/isoniazid/pyrazinamide/rifampicin' },
  { value: '25191531', label: 'Common Use Terminal Equipment (CUTE) ' },
  { value: '50501721', label: 'Rice Soya Blend (WFP food convention) ' },
  { value: '53101606', label: 'Unisex Sweatshirt' },
  { value: '43221734', label: 'Satellite Communications Equipment ' },
  { value: '25132100', label: 'Unmanned aerial vehicle' },
  { value: '92111615', label: 'Mine Action and ERW : Risk Education ' },
  { value: '51172487', label: 'Magnesium hydroxide' },
  { value: '25173110', label: 'Ground Navigational Aids and Landing Aids' },
  {
    value: '43221736',
    label: 'Cospas/Sarsat System (Search & Rescue Satellite System Complete)'
  },
  { value: '57666200', label: 'Shelter emergency kits' },
  { value: '41111528', label: 'Baggage Scale, Cargo ' },
  {
    value: '39111626',
    label: 'Precision Approach Path Indicator System (Papi) '
  },
  { value: '42141507', label: 'Antiseptic Wipe with Alcohol & Chlorhexidine' },
  { value: '52101517', label: 'Synthetic mat /carpet' },
  { value: '51343820', label: 'Lamivudine/nevirapine/zidovudine' },
  { value: '42132303', label: 'Insectiside-treated fabric' },
  { value: '46191624', label: 'Nitrogen Cylinder ' },
  { value: '57010101', label: 'Personal travel kits for UN staff' },
  { value: '53131663', label: 'Baby&infant moisturizing cream' },
  { value: '51143409', label: 'Caffeine/Dihydroergotamine/Paracetamol' },
  {
    value: '51351637',
    label: 'Estradiol cypionate/medroxyprogesterone acetate'
  },
  {
    value: '25173128',
    label: 'Non-Directional Beacon (Non-Directional Beacon) '
  },
  { value: '39111623', label: 'Airport Lighting Lamp ' },
  { value: '51241565', label: 'Polyvinyl alcohol' },
  { value: '25191551', label: 'Mobile ATA Control Tower' },
  { value: '51343810', label: 'Darunavir/Cobicistat' },
  {
    value: '80141403',
    label:
      'Creative fundraising services - Private Partnerships and Philanthropies'
  },
  { value: '51111785', label: 'Pembrolizumab' },
  { value: '50501710', label: 'Corn Soya Blend (WFP food convention)' },
  { value: '51172486', label: 'Idursulfase' },
  { value: '50501719', label: 'Lacto Soya Blend' },
  { value: '42231812', label: 'Therapeutic Milk (WFP standard)' },
  { value: '50501714', label: 'Super Cereal Plus (WFP food convention) ' },
  { value: '46191625', label: 'Fire Extinguishers Recharge System ' },
  { value: '86101402', label: 'Training  - Aviation Security' },
  { value: '51102905', label: 'Amodiaquine and pyrimethamine/sulfadoxine' },
  { value: '25202800', label: 'Aeronautical Data Communications Equipment' },
  { value: '50501725', label: 'Unilito (WFP food convention) ' },
  { value: '25172614', label: 'Truck cover sheet' },
  {
    value: '78204008',
    label:
      'E-Passport, Id, E-Health Services, Surveys, Planning & Studies (Aviation)'
  },
  { value: '57060300', label: 'Office emergency kits' },
  { value: '82111404', label: 'Editorial Services - Russian' },
  {
    value: '51191609',
    label: 'Sodium bicarbonate or sodium hydrogen carbonate'
  },
  { value: '25191536', label: 'Automated Flight Inquiry Response Systems' },
  { value: '42271728', label: 'Connector biconical' },
  { value: '45111402', label: 'Interpretation Equipment, Wireless  (Audio)' },
  { value: '43211733', label: 'Airline Ticket Validator ' },
  {
    value: '51343811',
    label: 'Emtricitabine/Tenofovir Alafenamide/Bictegravir'
  },
  { value: '78204012', label: 'Airport Operations Readiness ' },
  { value: '51132308', label: 'Idarucizumab' },
  { value: '25173132', label: 'Direction Finding Equipment, Accessories ' },
  { value: '25132101', label: 'Agricultural drone' },
  {
    value: '78204006',
    label: 'ANS & ATM Software Maintenance/Development Services (Aviation)'
  },
  { value: '51281518', label: 'Bedaquiline' },
  { value: '25101945', label: 'Scrubber, Runway ' },
  {
    value: '80141402',
    label: 'Creative fundraising services - Individual Giving'
  },
  { value: '57060200', label: 'Shelter emergency kits' },
  { value: '80141409', label: 'Donor retention services' },
  {
    value: '80141405',
    label: 'Direct marketing/mailing specific to fundraising'
  },
  { value: '42293529', label: 'Suction pump, manual' },
  { value: '50221305', label: 'Sorghum Flour (WFP food convention)' },
  { value: '25191530', label: 'Airport Flight Management System ' },
  { value: '51112035', label: 'Alectinib ' },
  {
    value: '43221748',
    label: 'VHF Transceiver , UHF Single And Multi Channel'
  },
  { value: '25173115', label: 'Navigation Sensor' },
  { value: '57080100', label: 'Emergency IT equipment kits' },
  { value: '57050202', label: 'Immediate response ration (IRR)' },
  { value: '51172340', label: 'Calcium folinate' },
  { value: '95141714', label: 'Flooring system Mobile Storage Unit (MSU)' },
  { value: '95141804', label: 'Emergency Shelter Kit' },
  {
    value: '81102810',
    label: 'Counter improvised explosive device IED training'
  },
  { value: '25191534', label: 'Baggage And Cargo Handling Systems ' },
  { value: '25191549', label: 'Flight Information Display System (FIDS) ' },
  { value: '57010102', label: 'Field Kits for UN staff' },
  { value: '51191806', label: 'Potassium bicarbonate/Potassium bitartrate' },
  { value: '81112223', label: 'Database Management Software Publishing' },
  { value: '51162106', label: 'Ethambutol/isoniazid/pyrazinamide/rifampicin' },
  { value: '42293528', label: 'Suction pump, electrical' },
  {
    value: '41151500',
    label:
      'Clinical laboratory and toxicology testing systems, components, and supplies'
  },
  { value: '25172805', label: 'Hydraulic Servicing Unit ' },
  { value: '25191548', label: 'Navaids Training Systems' },
  { value: '46151509', label: 'Ballistic/Fragmentation Equipment' },
  { value: '51111782', label: 'Obinutuzumab' },
  { value: '51162107', label: 'Isoniazid/rifapentine' },
  { value: '78141510', label: 'Freight including insurance services' },
  { value: '57040301', label: 'Early childhood development kit' },
  { value: '57030105', label: 'Emergency health kit' },
  { value: '57040000', label: 'Educational or recreational items' },
  { value: '25202910', label: 'Flight Progress Strips' },
  {
    value: '42231815',
    label: 'Ready-to-use Food for Children (UNICEF/WFP standard)'
  },
  { value: '51241143', label: 'Travoprost ophthalmic solution' },
  { value: '41111531', label: 'Cargo scale' },
  {
    value: '25202905',
    label: 'Automatic Terminal Information Service (ATIS) Equipment '
  },
  {
    value: '25173114',
    label:
      'Very High Frequency (VHF) Digital Global Navigation Satellite System (GGNSS) Data Link '
  },
  { value: '82111401', label: 'Editorial Services - English' },
  { value: '57030000', label: 'Humanitarian health supplies' },
  { value: '51241560', label: 'Levobunolol/polyvinyl alcohol' },
  { value: '78204001', label: 'Aviation Security Services' },
  {
    value: '82111400',
    label: 'Language Specific Editorial and Writing Services'
  },
  { value: '51341806', label: 'Sofosbuvir' },
  { value: '78202000', label: 'Flight Calibration Services' },
  { value: '43221743', label: 'UHF Transmitters and  Associated Equipment ' },
  {
    value: '25101615',
    label: 'Swap truck chassis/Platform lifting system truck'
  },
  {
    value: '51351635',
    label: 'Conjugated estrogens/medroxyprogesterone acetate'
  },
  {
    value: '57000000',
    label: 'Humanitarian Relief Items, Kits, or Accessories'
  },
  { value: '82111406', label: 'Editorial Services - Chinese' },
  { value: '86101409', label: 'Training Course - Flight ' },
  {
    value: '86101418',
    label:
      'Training, Technical: Crew Resource Management, Emergency Procedures, Dangerous Goods'
  },
  { value: '46151610', label: 'Identification Card Consumables' },
  { value: '51112029', label: 'Abemaciclib' },
  { value: '86101719', label: 'Training - Finance ' },
  { value: '86101404', label: 'Training - Airfield Lighting' },
  { value: '24112603', label: 'Water distribution kit' },
  { value: '24102403', label: 'Industrial shredder' },
  { value: '30181518', label: 'Distribution Water Stand with Taps' },
  { value: '51202417', label: 'Golimumab' },
  { value: '51241660', label: 'Allantoin/clioquinol/coal tar' },
  { value: '78200000', label: 'Aviation-Related Services And Consultancy' },
  { value: '25191553', label: 'Cart, Baggage - Accessories' },
  { value: '81162100', label: 'Cloud-based platform as a service' },
  { value: '24101663', label: 'Container handler/Side loader' },
  { value: '43221749', label: 'Cellular Equipment' },
  { value: '53131664', label: 'Baby body wash' },
  { value: '41114430', label: 'Runway Visual Range System ' },
  { value: '26111614', label: 'Petrol generator' },
  { value: '57060201', label: 'Jerry cans' },
  { value: '81162000', label: 'Cloud-based software as a service' },
  { value: '57030100', label: 'Health emergency kits' },
  { value: '86101410', label: 'Training Course - Flight (Helicopter) ' },
  { value: '39111622', label: 'Runway and Taxiway Lighting System ' },
  { value: '80141411', label: 'Legacy marketing' },
  { value: '42271517', label: 'Colorimetric End Tidal CO2 detector' },
  { value: '43221742', label: 'VHF/VDL Transmitter, AM ' },
  { value: '78204016', label: 'Systems Integrators (Aviation)' },
  { value: '53103005', label: 'Unisex tshirts' },
  { value: '42295484', label: 'Biliary stone dislodgers' },
  { value: '51202416', label: 'Daratumumab' },
  { value: '43221738', label: 'Emergency Locating Transmitter (ELT) ' },
  { value: '51342715', label: 'Peginterferon alfa-2a' },
  { value: '24101751', label: 'Baggage Conveyor' },
  { value: '57080101', label: 'Emergency telecommunications equipment' },
  { value: '50501724', label: 'Nutrimix (WFP food convention) ' },
  { value: '41111771', label: 'Decibel Meter ' },
  { value: '53131667', label: 'Baby bath tub' },
  { value: '22101909', label: 'Slurry Machine' },
  { value: '57030106', label: 'Epidemy health kit' },
  { value: '51343821', label: 'Lopinavir/ritonavir' },
  {
    value: '21101810',
    label:
      'Spare parts and accessories for dispersing and spraying appliances for agriculture'
  },
  { value: '51343814', label: 'Lopinavir/ritonavir' },
  { value: '25181614', label: 'Swap body/Platform lifting system module' },
  { value: '46171643', label: 'Explosive Vapour Detector' },
  { value: '42142628', label: 'Syringe Luer slip' },
  {
    value: '25173127',
    label: 'Non-Directional Beacon (NDB) And Associated Equipment '
  },
  { value: '78204007', label: 'Wgs84 Survey And Pans/Ops Procedure Services' },
  { value: '57050301', label: 'Therapeutic feeding kit' },
  { value: '39111628', label: 'Flood light ' },
  { value: '57060102', label: 'Winter clothing kit for adults' },
  { value: '50501709', label: 'Micronutrition Powder (WFP food convention)' },
  {
    value: '46171650',
    label: 'Flight Inspection Equipment (Airborne and Ground Based)'
  },
  {
    value: '45111400',
    label:
      'Classroom And Language Laboratory Equipment & Interpretation Systems'
  },
  { value: '51172484', label: 'Iduronidase' },
  { value: '43212118', label: 'Strip Printer ' },
  { value: '51343812', label: 'Glecaprevir/Pibrentasvir' },
  { value: '42132302', label: 'Non-insecticide treated bed net' },
  {
    value: '51162040',
    label:
      'Beclometasone dipropionate/Formoterol fumarate/Glycopyrronium bromide'
  },
  { value: '50221307', label: 'Cassava Flour' },
  { value: '57040101', label: 'Adolescent kit' },
  { value: '50501722', label: 'Wheat Soya Blend (WFP food convention) ' },
  { value: '57060208', label: 'Family kit' },
  { value: '86101408', label: 'Training - Meteorological Systems ' },
  { value: '51201580', label: 'Ixekizumab' },
  { value: '51112088', label: 'Acalabrutinib' },
  { value: '51285005', label: 'Amoxicillin trihydrate/Potassium clavulanate' },
  { value: '25173131', label: 'Doppler Direction Finder ' },
  {
    value: '50501727',
    label: 'Supplementary Nursing/Feeding Kit (WFP food convention)'
  },
  { value: '50425610', label: 'Potato Flakes/Granules (WFP food convention)' },
  { value: '51201831', label: 'Lipegfilgrastim' },
  {
    value: '51192318',
    label: 'Potassium chloride/Sodium chloride/Dextrose/Sodium citrate'
  },
  { value: '25131400', label: 'Safety and Rescue Aircraft' },
  { value: '50421854', label: 'Horse Beans (WFP food convention)' },
  { value: '25131513', label: 'Aircraft, Flight Inspection (Fully Equipped) ' },
  { value: '50421853', label: 'Habes Beans (WFP food convention)' },
  { value: '51434958', label: 'Amlodipine besilate/Irbesartan' },
  { value: '57050300', label: 'Food emergency kits' },
  { value: '43191635', label: 'Headset With Microphone' },
  { value: '25172804', label: 'Hydraulic Motor' },
  { value: '53131652', label: 'Personal Hygiene kit for Newborn Infants' },
  { value: '43221744', label: 'VHF Receivers and Associated Equipment ' },
  { value: '51131834', label: 'Phytomenadione or phylloquinone' },
  { value: '42231813', label: 'Supplementary Plumpy (WFP standard)' },
  { value: '57050100', label: 'Emergency nutritional kits' },
  { value: '82111405', label: 'Editorial Services - Arabic' },
  { value: '57666300', label: 'Office emergency kits' },
  { value: '25191547', label: 'Air Traffic Control (ATC) Simulators ' },
  { value: '51111878', label: 'Goserelin acetate' },
  { value: '57060205', label: 'Sleeping mats' },
  { value: '51112030', label: 'Ribociclib' },
  {
    value: '25175200',
    label: 'Specialised, Safety and Rescue Vehicle Accessories'
  },
  { value: '51162043', label: 'Ipratropium bromide/fenoterol hydrobromide' },
  { value: '39111621', label: 'Heliport Lighting Systems ' },
  { value: '43221740', label: 'Satellite Communications Equipment' },
  { value: '51362604', label: 'Pregabalin' },
  { value: '42192435', label: 'Isolation and Treatment Stretcher' },
  { value: '25131512', label: 'Aircraft, Jet Widebody' },
  { value: '80141404', label: 'Digital media buying services' },
  { value: '51131829', label: 'Anti-factor VIII coagulant complex' },
  { value: '50425410', label: 'Split Yellow Peas (WFP food convention)' },
  { value: '40174712', label: 'Threading Pipe Tool Kit' },
  { value: '51112027', label: 'Regorafenib' },
  { value: '57060206', label: 'Winterization kit for tent' },
  { value: '50161816', label: 'Halawa / Halva' },
  { value: '46161538', label: 'Follow Me Sign System' },
  { value: '78204011', label: 'Airport Inspection And Certification ' },
  {
    value: '78204005',
    label: 'Safety Management Systems (Sms) Related Services (Aviation)'
  },
  { value: '51111783', label: 'Brentuximab vedotin' },
  { value: '25202901', label: 'Controller Workstation ' },
  { value: '50501726', label: 'Wheat Soya Milk (WFP food convention) ' },
  { value: '24111825', label: 'Small seed storage silos' },
  { value: '57777100', label: 'Logistics emergency kits' },
  {
    value: '51131830',
    label: 'Aprotinin/Fibrinogen+Calcium chloride/Thrombin'
  },
  { value: '86101413', label: 'Training - Flight Inspection ' },
  {
    value: '86101414',
    label:
      'Training  - Design and Construction Procedures For Flight Instruments'
  },
  {
    value: '86101419',
    label: 'Training - Ground Support Equipment Operation (On-Site)'
  },
  { value: '51352436', label: 'Ethinylestradiol/cyproterone acetate ' },
  { value: '78204014', label: 'Environmental Impact Studies - Airports' },
  { value: '84121608', label: 'Payment gateway services' },
  { value: '81162200', label: 'Cloud-based infrastructure as a service' },
  {
    value: '25173133',
    label: 'Very High Frequency (VHF) Marker Beacon And Associated Equipment '
  },
  { value: '42271730', label: 'Oxgyen plant, pressure swing absorption (PSA)' },
  { value: '51273950', label: 'Felypressin/prilocaine' },
  { value: '51343822', label: 'Atazanavir/ritonavir' },
  { value: '51461606', label: 'Soy flour/Polyvidone' },
  { value: '43233513', label: 'Cloud-based data access and sharing software' },
  { value: '43221533', label: 'Private Automatic Branch Exchange (PABX)' },
  {
    value: '25131402',
    label: 'Rescue And Firefighting Aircraft, 3000 Gallons (12,000 Litres)'
  },
  { value: '41104933', label: 'PCR System' },
  { value: '46171642', label: 'Explosive Detection System, Ctx' },
  { value: '51112023', label: 'Ruxolitinib' },
  { value: '10191708', label: 'Fumigation backpack' },
  { value: '83111606', label: 'Radio Manual' },
  { value: '43221747', label: 'VHF Transceiver, FM' },
  {
    value: '25173135',
    label: 'Differential Global Positioning System (DGPS) - Ground Station'
  },
  {
    value: '57020102',
    label: 'Humanitarian logistic delivery and warehousing'
  },
  { value: '56101547', label: 'Folding table' },
  {
    value: '41150000',
    label:
      'Clinical laboratory and toxicology testing systems, components, and supplies'
  },
  { value: '80141408', label: 'Donor care services' },
  {
    value: '25173112',
    label:
      'Digital global navigation satellite system (DGNSS) Local Area Augmentation System (LAAS) '
  },
  { value: '86101415', label: 'Training - Aircraft Maintenance ' },
  { value: '57030303', label: 'Water distribution kit' },
  { value: '57030109', label: 'Trauma and emergency surgery kit' },
  { value: '25131511', label: 'Aircraft, Twin Engine' },
  { value: '25173121', label: 'Distance Measuring Equipment (DME) System' },
  { value: '51192458', label: 'Calcium carbonate/calcium gluconate  lactate' }
];

export const basePermission = {
  free: {
    videos_perm: { codename: 'videos_perm' },
    my_business_perm: { codename: 'my_business_perm' },
    change_password_perm: { codename: 'change_password_perm' }
  },
  'non-free': {
    association_perm: { codename: 'association_perm' },
    small_business_perm: { codename: 'small_business_perm' },
    primes_perm: { codename: 'primes_perm' },
    my_business_perm: { codename: 'my_business_perm' },
    my_profile_perm: { codename: 'my_profile_perm' },
    change_password_perm: { codename: 'change_password_perm' },
    one_way_contact_perm: { codename: 'one_way_contact_perm' },
    custom_dashboard_perm: { codename: 'custom_dashboard_perm' },
    announcements_perm: { codename: 'announcements_perm' },
    industry_news_perm: { codename: 'industry_news_perm' },
    partial_form_perm: { codename: 'partial_form_perm' },
    videos_perm: { codename: 'videos_perm' }
  }
};

export const dueDiligenceOptions = {
  VERIFIED_CLEAR: 'Verified - Clear',
  VERIFIED_RECORD_FOUND: 'Verified - Record Found',
  NOT_VERIFIED: 'Not Verified'
};
