import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideModal, showModal } from '../../redux/actions/ui';
import ModalComponent from '../../components/ui/ModalComponent';
import PaymentForm from './paymentForm';
import PaymentPackages from './paymentPackages';
import Thankyou from './thankyou';
import TNC from './tnc';

const PaymentContainer = ({ history, showModal, hideModal }) => {
  const [step, setStep] = useState(0);
  const [packageDetails, setPackageDetails] = useState({});
  
  useEffect(() => {
    return hideModal;
    // eslint-disable-next-line
  }, []);

  useEffect(()=>{
    if(history.location.state){
      setPackageDetails({ ...history.location.state });
    }
  },[history.location.state])
  
  const parentHandler = (data) => {
    setPackageDetails(prev => ({ ...prev, ...data}));
  };

  const handleStep = useCallback((value=1) => {
    setStep(value);
  }, []);

  const renderStep = () => {
    showModal();
    switch (step) {
      case 1:
        return <ModalComponent><TNC handleStep={handleStep} data={packageDetails} /></ModalComponent>;
      case 2:
        return <ModalComponent><PaymentForm handleStep={handleStep} data={packageDetails} /></ModalComponent>;
      case 3:
        return <ModalComponent><Thankyou /></ModalComponent>;
      default:
        return null;
    }
  };
  
  return (
    <React.Fragment>
      <PaymentPackages data={packageDetails} handleStep={handleStep} parentHandler={parentHandler} />
      {renderStep()}
    </React.Fragment>
  );
};

PaymentContainer.propTypes = {
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default connect(null, { showModal, hideModal })(PaymentContainer);
