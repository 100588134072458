import { object, string, func } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { Route, Switch, Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { hideLoading, showLoading, showModal } from '../../redux/actions/ui';
import { Space, Table, Tooltip } from 'antd';
import ModalComponent from '../../components/ui/ModalComponent';
import { accessRules } from '../../utils/accessRules';
import { contact } from '../../components/configs/forms/contact.json';
import { getCurrentPageFromLocation } from '../../utils/utils';
import { clearRedirect } from '../../redux/actions/redirect';
import { getContacts } from '../../api/contact';
import { handleNotifications } from '../../utils/notifications';
import { setContactRequests } from '../../redux/actions/contact';
import { columns } from '../../components/configs/tables/contact.json';
import dayjs from 'dayjs';
import moment from 'moment';

import View from './View';
import Add from './Add';
import Delete from './Delete';
import { DeleteOutlined } from '@ant-design/icons';

const Contact = ({
  role,
  redirect,
  onClearRedirect,
  onShowModal,
  contacts,
  setContacts
}) => {
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const innerWidth = window.innerWidth;

  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    fetch({ pagination });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (redirect.path) {
      history.push(redirect.path);
      onClearRedirect();
    }
    // eslint-disable-next-line
  }, [redirect.path]);

  const handleTableChange = (pagination, filters, sorter) => {
    fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters
    });
  };

  const fetch = (params = {}) => {
    setLoading(true);
    getContacts(params)
      .then(({ data }) => {
        setLoading(false);
        if (data.success) {
          setContacts(
            data['data'].map(item => {
              return {
                ...item,
                key: item['id']
              };
            })
          );
        } else {
          handleNotifications('error', 'Could not fetch', data.message);
        }
        setPagination({ ...params.pagination, total: data.pagination.total });
      })
      .catch(error => {
        setLoading(false);
      });
  };

  /* Render action column */
  const renderActions = (text, record) => {
    return (
      <>
        <Space size="small">
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'delete'
          ] ? (
            <Tooltip
              title="Delete"
              onClick={e => {
                e.stopPropagation();
                onShowModal();
              }}
            >
              <Link
                to={{
                  pathname: `${match.path}/delete/${record.id}`,
                  contactId: record.id
                }}
              >
                <DeleteOutlined
                  style={{ fontSize: '18px', color: 'crimson' }}
                />
              </Link>
            </Tooltip>
          ) : null}
        </Space>
      </>
    );
  };

  const tableColumns =
    innerWidth > 556
      ? columns.map(item => ({ ...item, width: 150 }))
      : [columns[0]];

  tableColumns.push({
    title: 'Actions',
    key: 'action',
    displayName: 'action',
    width: 150,
    render: renderActions
  });

  const onClickRow = record => {
    return {
      onClick: () => {
        if (
          accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'view'
          ]
        ) {
          onShowModal();
          history.push(`${match.path}/view/${record.id}`);
        }
      }
    };
  };

  return (
    <div style={{ marginLeft: 15 }}>
      <ModalComponent goBack={true}>
        <Switch>
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'add'
          ] ? (
            <Route
              path={`${match.path}/add`}
              render={() => <Add config={contact} />}
            />
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'view'
          ] ? (
            <Route path={`${match.path}/view/:contactId`} component={View} />
          ) : null}
          {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
            'delete'
          ] ? (
            <Route
              path={`${match.path}/delete/:contactId`}
              component={Delete}
            />
          ) : null}
        </Switch>
      </ModalComponent>

      <Space
        direction="horizontal"
        style={{
          width: '100%',
          padding: '8px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}
      >
        {/* {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
          'add'
        ] ? (
          <Button
            type="primary"
            size="small"
            onClick={e => {
              e.preventDefault();
              onShowModal();
            }}
          >
            <Link to={`${match.path}/add`}>Add</Link>
          </Button>
        ) : null} */}
      </Space>
      <br />

      {accessRules[role][getCurrentPageFromLocation(match.path)]['actions'][
        'add'
      ] ? (
        <h2>
          Please contact us by clicking
          <span
            size="small"
            onClick={e => {
              e.preventDefault();
              onShowModal();
            }}
          >
            <Link to={`${match.path}/add`}> here </Link>
          </span>
          if you have any queries or need any information
        </h2>
      ) : null}

      <Table
        className="table-striped-rows table-hoverable"
        onRow={onClickRow}
        columns={tableColumns}
        rowKey={record => record.id}
        dataSource={contacts.map(c => {
          return {
            ...c,
            created_on: moment
              .utc(c.created_on)
              .utcOffset(dayjs().utcOffset())
              .format('MM/DD/YYYY hh:mm A'),
            updated_on: c.updated_on
              ? moment
                  .utc(c.updated_on)
                  .utcOffset(dayjs().utcOffset())
                  .format('MM/DD/YYYY hh:mm A')
              : ''
          };
        })}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 240 }}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  redirect: state.redirect,
  contacts: state.contact.contact_requests
});

const mapDispatchToProps = dispatch => ({
  onClearRedirect: () => dispatch(clearRedirect()),
  onShowModal: () => dispatch(showModal()),
  onShowLoading: () => dispatch(showLoading()),
  onHideLoading: () => dispatch(hideLoading()),
  setContacts: payload => dispatch(setContactRequests(payload))
});

Contact.propTypes = {
  role: string,
  match: object,
  onShowModal: func,
  onShowLoading: func,
  onHideLoading: func,
  onClearRedirect: func
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
