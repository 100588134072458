import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { func } from 'prop-types';
import { Button, Space } from 'antd';

import { onDeleteSmallBusiness } from '../../redux/actions/smallBusiness';
import { hideModal } from '../../redux/actions/ui';
import { handleNotifications } from '../../utils/notifications';

const Delete = ({ onDelete, onHideModal }) => {
  const param = useParams();
  const history = useHistory();
  const location = useLocation();
  //Rakesh Test
  const onClickDelete = () => {
    if (!param || !param.businessId) {
      handleNotifications('error', 'Failed', 'Did not receive event id');
      return;
    }

    onDelete(param['businessId']);
  };

  const onClickCancel = () => {
    onHideModal();
    history.goBack();
  };

  return (
    <Space direction="vertical" align="center" style={{ width: '100%' }}>
      <Space direction="horizontal" align="center">
        <h1>Delete SmallBusiness &apos;{location.businessName}&apos;?</h1>
        <br />
      </Space>
      <Space direction="horizontal" align="center">
        <Button type="primary" danger htmlType="button" onClick={onClickDelete}>
          Delete
        </Button>
        <Button type="primary" htmlType="button" onClick={onClickCancel}>
          Cancel
        </Button>
      </Space>
    </Space>
  );
};

const mapDispatchToProps = dispatch => ({
  onDelete: businessId => dispatch(onDeleteSmallBusiness(businessId)),
  onHideModal: () => dispatch(hideModal())
});

Delete.propTypes = {
  onDelete: func,
  onHideModal: func
};

export default connect(null, mapDispatchToProps)(Delete);
