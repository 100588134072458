import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { array, bool, string } from 'prop-types';
import { Menu } from 'antd';
import { connect, useDispatch } from 'react-redux';
import SubMenu from 'antd/lib/menu/SubMenu';
import { RESET_TO_INIT, SET_IS_LANDED_FALSE, SET_IS_LANDED_TRUE } from '../../../redux/actionTypes';

const menuItemStyle = {
  marginTop: '10px',
  paddingLeft: '15px',
  paddingRight: '15px',
  marginLeft: '5px',
  marginRight: '5px',
  borderRadius: '5px'
};



const NavMenu = ({ items, role, isValidPayment }) => {
  const [current, setCurrent] = useState(['1']);
  const dispatch = useDispatch();

  const renderMenuItem = item => {
    return (
      <Menu.Item
        onClick={() => {
          // if(item.label == "Diverse Business"){
          //   dispatch({type:SET_IS_LANDED_TRUE})
          // }else if(item.label == "Search"){
          //   dispatch({type:SET_IS_LANDED_FALSE})
          //   dispatch({type:RESET_TO_INIT})
          // }else{
          //   dispatch({type:SET_IS_LANDED_FALSE})
          // }


          dispatch(
            item.label === "Diverse Business"
              ? { type: SET_IS_LANDED_TRUE }
              : item.label === "Search"
                ? (
                  dispatch({ type: SET_IS_LANDED_FALSE }),
                  dispatch({ type: RESET_TO_INIT })
                )
                : { type: SET_IS_LANDED_FALSE }
          );
          //item.label == "Diverse Business"?dispatch({type:SET_IS_LANDED_TRUE}):item.label == "Search"?dispatch({type:SET_IS_LANDED_FALSE}):dispatch({type:SET_IS_LANDED_FALSE})
        }}
        key={item.key}
        style={{
          ...menuItemStyle,
          backgroundColor: 'rgba(255,255,255, 0.2)'
          // backgroundColor: item.access ? 'rgba(255,255,255, 0.2)' : '#43b243'
        }}
        disabled={(item.isPaid ? !isValidPayment : false) && role !== 'admin'}
      >
        <Link to={`${item.path}`}>
          {item.label}{' '}
          {/* {!item.access ? <StarFilled style={{ marginRight: 0 }} /> : null} */}
        </Link>
      </Menu.Item>
    );
  };

  const navMenu = items.map(item => {
    return item.children ? (
      <SubMenu

        key={item.key}
        title={item.label}
        style={{
          ...menuItemStyle,
          backgroundColor: 'rgba(255,255,255, 0.2)'
          // backgroundColor: item.access ? 'rgba(255,255,255, 0.2)' : '#43b243'
        }}
      >
        {item.children.map(sub => renderMenuItem(sub))}
      </SubMenu>
    ) : (
      renderMenuItem(item)
    );
  });

  return (
    <div className="header-container">
      <Menu
        mode="horizontal"
        selectedKeys={current}
        onClick={({ key }) => setCurrent(key)}
        style={{ backgroundColor: 'var(--brandBlueLight)', fontSize: '18px' }}
      >
        {navMenu}
      </Menu>
    </div>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  isValidPayment: state.auth.isValidPayment
});

NavMenu.propTypes = {
  items: array,
  role: string,
  isValidPayment: bool
};

export default connect(mapStateToProps)(NavMenu);
