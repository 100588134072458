import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { hideLoading, showLoading } from '../../redux/actions/ui';
import InfiniteScroll from 'react-infinite-scroll-component';
import { array, func, object, string } from 'prop-types';
import {
  Comment,
  Avatar,
  Form,
  Button,
  List,
  Tooltip,
  Input,
  Typography,
  Skeleton,
  Divider
} from 'antd';
import { hideModal } from '../../redux/actions/ui';
import {
  onCreateNote,
  onDeleteNote,
  onUpdateNote,
  setNotes
} from '../../redux/actions/note';
import { onUpdateSmallBusiness } from '../../redux/actions/smallBusiness';
import { getNotes } from '../../api/notes';
import { getSmallBusiness } from '../../api/smallBusiness';
import { handleNotifications } from '../../utils/notifications';
import { objectToFormData } from '../../utils/utils';
import { dateTimeUtcToLocal } from '../../utils/dateTimeHelper';
import brandLogo from '../../assets/images/brand-logo-light-no-tagline.png';

const { TextArea } = Input;
const { Title } = Typography;

const Notes = ({
  user,
  role,
  notes,
  createNote,
  updateNote,
  deleteNote,
  onSetNotes,
  onShowLoading,
  onHideLoading,
  updateSmallBusiness,
  globalVars
}) => {
  const [focusedNote, setFocusedNote] = useState(null);
  const [value, setValue] = useState('');
  const [entity, setEntity] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const { smallBusinessId } = useParams();

  useEffect(() => {
    if (smallBusinessId) {
      onShowLoading();
      getSmallBusiness(smallBusinessId)
        .then(({ data }) => {
          if (data['success']) {
            setEntity(data['data']);
          } else {
            setEntity(null);
            handleNotifications('error', 'Failed', 'Could not fetch details');
          }
          onHideLoading();
        })
        .catch(error => {
          setEntity(null);
          onHideLoading();
          console.log(error);
        });
    }

    fetchNotes(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smallBusinessId]);

  const fetchNotes = (nextPage = true) => {
    getNotes(smallBusinessId, 'small_business', nextPage && currentPage + 1)
      .then(({ data }) => {
        if (data['success']) {
          onSetNotes(data['data']);
          setCurrentPage(data['pagination']['page']);
          if (
            notes.length >= data['pagination']['total'] ||
            data['pagination']['total'] <= 5
          ) {
            setHasMore(false);
          }
        } else if (data['message'] === 'No more records') {
          setHasMore(false);
        } else {
          handleNotifications('error', 'Failed', 'Could not fetch details');
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  const submit = () => {
    let payload = {};
    if (focusedNote && focusedNote.id) {
      payload = {
        entity_id: focusedNote.entity_id,
        entity_type: focusedNote.entity_type,
        content: value,
        author_id: focusedNote.author_id,
        author: focusedNote.author,
        last_edited_by: user.name
      };
      updateNote(focusedNote.id, objectToFormData(payload));
    } else {
      payload = {
        entity_id: smallBusinessId,
        entity_type: 'small-business',
        content: value,
        author_id: user.id,
        author: user.name
      };
      createNote(objectToFormData(payload));
    }

    setValue('');
    setFocusedNote(null);
  };

  return (
    <div>
      <Title
        level={2}
        style={{
          borderBottom: '1px solid #f0f0f0',
          paddingBottom: '15px'
        }}
      >
        {entity?.company_name} | Notes
      </Title>
      <Comment
        avatar={<Avatar src={globalVars.logo ? globalVars.logo : brandLogo} size={80} className="notes__logo" alt="Bechtel Logo" />}
        content={
          <div>
            <Form.Item>
              <TextArea
                rows={4}
                onChange={e => setValue(e.target.value)}
                value={value}
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                loading={loading}
                onClick={submit}
                type="primary"
              >
                {focusedNote?.id ? 'Update' : 'Add'} Note
              </Button>
            </Form.Item>
          </div>
        }
      />
      <div
        id="scrollableDiv"
        style={{
          height: 400,
          overflow: 'auto',
          padding: '0 16px',
          border: '1px solid rgba(140, 140, 140, 0.35)'
        }}
      >
        <InfiniteScroll
          dataLength={notes.length}
          next={fetchNotes}
          hasMore={hasMore}
          loader={
            <Skeleton
              avatar
              paragraph={{
                rows: 1
              }}
              active
            />
          }
          endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
          scrollableTarget="scrollableDiv"
        >
          <List
            className="comment-list"
            header={`${notes.length} notes`}
            itemLayout="horizontal"
            dataSource={notes}
            renderItem={item => (
              <li>
                <Comment
                  actions={[
                    <>
                      {(function () {
                        if (
                          !(
                            role === 'bechtel-user' &&
                            item.creator !== user.name
                          )
                        ) {
                          return (
                            <>
                              <span
                                key={`${item.id}-item-edit-action`}
                                onClick={() => {
                                  setFocusedNote(item);
                                  setValue(item.content);
                                }}
                              >
                                Edit
                              </span>
                              <span
                                style={{ color: 'crimson' }}
                                key={`${item.id}-item-delete-action`}
                                onClick={() => {
                                  deleteNote(item.id);
                                }}
                              >
                                Delete
                              </span>
                            </>
                          );
                        }
                      })()}
                    </>
                  ]}
                  author={item.author}
                  // avatar={item.avatar}
                  content={item.content}
                  datetime={
                    <Tooltip>
                      <span>
                        {dateTimeUtcToLocal(item.updated_on).format(
                          'MMM DD, YYYY hh:mm A'
                        ) ||
                          dateTimeUtcToLocal(item.created_on).format(
                            'MMM DD, YYYY hh:mm A'
                          )}
                      </span>
                    </Tooltip>
                  }
                />
              </li>
            )}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  role: state.auth.role,
  notes: state.notes.notes,
  globalVars: state.globalVars.globalVars
});

const mapDispatchToProps = dispatch => ({
  createNote: payload => dispatch(onCreateNote(payload)),
  updateNote: (id, payload) => dispatch(onUpdateNote(id, payload)),
  deleteNote: id => dispatch(onDeleteNote(id)),
  onSetNotes: payload => dispatch(setNotes(payload)),
  onCancel: () => dispatch(hideModal()),
  onHideLoading: () => dispatch(hideLoading()),
  onShowLoading: () => dispatch(showLoading()),
  updateSmallBusiness: (smallBusinessId, payload, role) =>
    dispatch(onUpdateSmallBusiness(smallBusinessId, payload, role))
});

Notes.propTypes = {
  user: object,
  role: string,
  notes: array,
  addNote: func,
  updateNote: func,
  deleteNote: func,
  onSetNotes: func,
  onCancel: func,
  globalVars: object,
  onShowLoading: func,
  onHideLoading: func,
  updateSmallBusiness: func
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Notes));
