import { 
  SET_VERIFICATION_STEPS,
  ADD_VERIFICATION_STEP,
  FILTER_VERIFICATION_STEPS,
  UPDATE_VERIFICATION_STEP
} from '../actionTypes';

const initialState = {
  verificationSteps: []
};

export const verificationStepsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VERIFICATION_STEPS:
      return { ...state, verificationSteps: [...action.payload] };
    case ADD_VERIFICATION_STEP:
      return { ...state, verificationSteps: [...state.verificationSteps, action.payload] };
    case UPDATE_VERIFICATION_STEP:
      const newArray = state.verificationSteps.map(step => step.id === action.payload.id ? action.payload : step)
      return { ...state, verificationSteps: newArray };
    case FILTER_VERIFICATION_STEPS:
      return { ...state, verificationSteps: state.verificationSteps.filter(c => c.id !== action.stepId) };
    default:
      return { ...state };
  }
};