import {
    sendMassEmail,
  } from '../../api/massEmail';
  import { hideLoading, showLoading, hideModal } from './ui';
  import { handleNotifications } from '../../utils/notifications';

  export const onSendMassEmail = payload => dispatch => {
    dispatch(showLoading());
    sendMassEmail(payload)
      .then(({ data }) => {
        dispatch(hideLoading());
        if (data.success) {
          handleNotifications('success', 'Success', data.message);
        } else {
            handleNotifications('error', 'Failed', data.message);
        }
        dispatch(hideModal());
      })
      .catch(error => {
        dispatch(hideLoading());
        handleNotifications('error', 'Failed', error.message);
        dispatch(hideModal());
      });
  };