import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bool, func, number, string } from 'prop-types';
import { Button, Col, DatePicker, Form, Image, Input, Row, Space, Spin, Typography } from 'antd';
import { DownloadOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import { getAssociationDetails } from '../../api/associations';
import { addField, updateField } from '../../api/associationFields';
import { hideLoading, hideModal } from '../../redux/actions/ui';
import { onAddAssociation, onUpdateAssociation } from '../../redux/actions/associations';
import { debounce, objectToFormData } from '../../utils/utils';
import ImageUpload from '../common/Form/FormInputs/ImageUpload';
import { useParams } from 'react-router';
import { handleNotifications } from '../../utils/notifications';
import dayjs from 'dayjs';
import FileUpload from '../common/Form/FormInputs/FileUpload';
import Downloader from '../../utils/downloader';
import moment from 'moment';

const { Title } = Typography;
const { TextArea } = Input;

const downloadStyle = {
  width: 150,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  padding: '0 5px',
  backgroundColor: '#1890ff',
  color: 'white',
  border: 'none'
};

const Association = ({ selfMode, businessId, updateAssociation, addAssociation, onCancel, disabled, partial, edit }) => {
  const [form] = Form.useForm();
  // const [loading, setLoading] = useState(false);

  const params = useParams();
  const dispatch = useDispatch();
  const associationId = selfMode ? businessId : params['associationId'];

  const [details, setDetails] = useState(false);
  const [resources, setResource] = useState([]);
  
  const [formValues, setFormValues] = useState({
    logo: null,
    company_name: null,
    phone: null,
    association_contact: [],
    association_event: [],
    description: null
  });

  const getFormValuesFromDetails = details => {
    const newFormValues = { ...formValues };
    const {
      Association_name,
      company_name,
      description,
      association_contact,
      association_event,
      logo_url,
      phone,
      logo_name,
      updated_on,
      website,
    } = details;

    if (logo_url) {
      newFormValues['logo'] = {
        url: logo_url,
        name: logo_name
      };
    }
    if (website) {
      newFormValues['website'] = website;
    }
    if (association_contact?.length) {
      newFormValues['association_contact'] = association_contact.map(item => ({
        id: item['id'],
        name: item['name'],
        title: item['title'],
        phone: item['phone'],
        email: item['email']
      }));
    }
    if (association_event?.length) {
      newFormValues['association_event'] = association_event.map(item => ({
        id: item['id'],
        name: item['name'],
        date: moment.utc(item['date']),
        description: item['description'],
        resource: {
          name: item['resource_name'],
          url: item['resource_url']
        },
      }));
    }
    if (updated_on) {
      newFormValues['profile_last_updated'] = dayjs(updated_on).format(
        'MM/DD/YYYY'
      );
    }
    setFormValues({
      ...newFormValues,
      description,
      phone,
      company_name: Association_name || company_name
    });
    setDetails(true)
  }

  useEffect(() => {
    if(partial){
      getFormValuesFromDetails(partial);
    }else if(associationId) {
      getAssociationDetails(associationId)
        .then(({ data }) => {
          if (data['success']) {
            getFormValuesFromDetails(data['data']);
            dispatch(hideLoading())
          } else {
            handleNotifications('error', 'Failed', 'Could not fetch details');
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associationId, partial]);

  const inputChangedHandler = (key, value) => {
    setFormValues({ ...formValues, [key]: value });
  };

  const debouncedInput = debounce((e, key) => {
    inputChangedHandler(key, e.target.value);
  }, 500);

  const microApiResponseHandler = (key, value, index) => {
    let arr = formValues[key] ? [...formValues[key]] : [];
    arr[index] = value;
    setFormValues({ ...formValues, [key]: arr });
  };

  const submit = () => {
    if (formValues.logo?.url) {
      delete formValues.logo;
    }
    let payload = {};
    for (let key in formValues){
      if (Array.isArray(formValues[key]) && !formValues[key]?.length)
        continue;

      if(['association_contact', 'association_event'].includes(key)){
        payload[key] = JSON.stringify(formValues[key].map(item=> item.id || item));
      }else {
        payload[key] = formValues[key];
      }
    }
    if(associationId){
      updateAssociation(associationId, objectToFormData(payload));
    }else{
      addAssociation(objectToFormData(payload));
    }
  };

  const submitFail = errors => {
    console.log(errors);
  };

  const editImage = () => {
    setFormValues(prev => ({ ...prev, logo: null }));
  };

  const handleDownload = (e, url, name) => {
    e.stopPropagation();
    e.preventDefault();
    Downloader(url, name);
  };

  const resourceHandler = (key, value, index) => {
    const newEvents = [...resources] || [];
    if (newEvents[index]) {
      newEvents[index][key] = value;
    } else {
      newEvents.push({ [key]: value });
    }
    setResource([...newEvents]);
  };

  return (
    <Row align="middle" justify="center" className="association-form">
      <Col span={24}>
        <Title level={2} className="heading-title">Association Profile</Title>
        {details || (!edit && !disabled) ? <Form
          form={form}
          name="add-association"
          layout="horizontal"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          labelAlign="left"
          initialValues={{...formValues}}
          onFinish={submit}
          onFinishFailed={submitFail}
        >
          {/* Association Logo */}
          <Form.Item
            key="logo"
            name="logo"
            hasFeedback
            style={{ textAlign: 'right' }}
            wrapperCol={{ md: { offset: 4 }, sm: { offset: 0 } }}
          >
            {((formValues['logo'] && formValues['logo']?.['url']) || disabled) ? (
              formValues['logo']?.['url'] ? <React.Fragment>
                <Image
                  src={ formValues['logo'] ? formValues['logo']['url'] : '' }
                  alt={ formValues['logo'] ? formValues['logo']['name'] : 'alt-logo'}
                  preview={false}
                  width="100px"
                  height="100px"
                />
                {!disabled && <Button
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />}
                  size="small"
                  style={{ transform: 'translate(-25px, -10px)' }}
                  onClick={editImage}
                />}
              </React.Fragment> :
              null
            ) : (
              <ImageUpload
                keyName="logo"
                label="Association Logo"
                max={1}
                nestedHandler={(key, value) => {
                  inputChangedHandler(key, value[key]);
                }}
              />
            )}
          </Form.Item>

          {/* Association Name */}
          <Form.Item
            key="company_name"
            name="company_name"
            style={{
              border: '1px solid gray',
              marginBottom: '0px',
              paddingLeft: '7px',
              backgroundColor: 'lightgray'
            }}
            label="Association Name"
            hasFeedback
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Association name is required.'
              }
            ]}
          >
            <Input
              name="company_name"
              placeholder="Association Name"
              disabled={disabled}
              type="text"
              bordered={false}
              style={{ borderLeft: '1px solid gray', backgroundColor: 'white' }}
              onChange={e => {
                e.persist();
                debouncedInput(e, 'company_name');
              }}
            />
          </Form.Item>

          {/* Association Phone */}
          <Form.Item
            key="phone"
            name="phone"
            label="Association Phone"
            style={{
              borderBottom: '1px solid gray',
              borderLeft: '1px solid gray',
              borderRight: '1px solid gray',
              marginBottom: '0px',
              paddingLeft: '7px',
              backgroundColor: 'lightgray'
            }}
            hasFeedback
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                required: true,
                validator: async (_, phone) => {
                  if (!phone) {
                    return Promise.reject(
                      new Error('Phone Number is required.')
                    );
                  }
                }
              }
            ]}
          >
            <Input
              name="phone"
              placeholder="Association Phone (10-digit)"
              type="tel"
              disabled={disabled}
              maxLength={10}
              // pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
              bordered={false}
              style={{ borderLeft: '1px solid gray', backgroundColor: 'white' }}
              onChange={e => {
                e.persist();
                debouncedInput(e, 'phone');
              }}
            />
          </Form.Item>

          {/* Website */}
          <Form.Item
            key="website"
            name="website"
            label="Website"
            style={{
              borderBottom: '1px solid gray',
              borderLeft: '1px solid gray',
              borderRight: '1px solid gray',
              marginBottom: '1px',
              paddingLeft: '7px',
              backgroundColor: 'lightgray'
            }}
            hasFeedback
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Association Website is required.'
              },
              {
                type: "url",
                message: "This field must be a valid url."
              }
            ]}  
          >
            <Input
              name="website"
              placeholder="Association Website"
              disabled={disabled}
              bordered={false}
              style={{ borderLeft: '1px solid gray', backgroundColor: 'white' }}
              onChange={e => {
                e.persist();
                debouncedInput(e, 'website');
              }}
            />
          </Form.Item>

          {/* Association Contacts */}
          <Form.List name="association_contact">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    wrapperCol={{ md: { offset: index === 0 ? 0 : 4, span: 20 }, sm: { span: 24 }}}
                    style={{
                      border: '1px solid gray',
                      marginBottom: '0px',
                      paddingLeft: '7px',
                      backgroundColor: 'lightgray'
                    }}
                    required={false}
                    key={`${field.key}_${index}`}
                    label={index === 0 ? 'Association Contacts' : ''}
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, 'name']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`name_${index}`}
                    >
                      <Input
                        disabled={disabled}
                        placeholder="Name"
                        style={{ marginLeft: '-7px', width: '101%' }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'title']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`title_${index}`}
                    >
                      <Input
                        disabled={disabled}
                        placeholder="Title"
                        style={{ marginLeft: '-7px', width: '101%' }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'phone']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`phone_${index}`}
                    >
                      <Input
                        placeholder="Phone"
                        disabled={disabled}
                        type="tel"
                        maxLength={10}
                        // pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                        style={{ marginLeft: '-7px', width: '101%' }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'email']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`email_${index}`}
                    >
                      <Input
                        placeholder="Email"
                        disabled={disabled}
                        type="email"
                        style={{ marginLeft: '-7px', width: '101%' }}
                      />
                    </Form.Item>

                    {!disabled && <Space direction="horizontal" style={{ float: 'right' }}>
                      <Button
                        type="warning"
                        style={{
                          marginTop: '0px',
                          backgroundColor: '#D26A31',
                          color: 'white'
                        }}
                        onClick={() => {
                          formValues['association_contact'].splice(index, 1);
                          return remove(field.name);
                        }}
                      >
                        Remove
                      </Button>
                      <Button
                        type="warning"
                        style={{
                          float: 'right',
                          marginRight: '0px',
                          marginTop: '0px',
                          backgroundColor: '#82CF8F',
                          color: 'white'
                        }}
                        onClick={() => {
                          let id = form.getFieldValue('association_contact')[ index]?.id;
                          let ind = index;
                          if(id){
                            updateField(
                              'contacts',
                              id,
                              objectToFormData(
                                form.getFieldValue('association_contact')[index]
                              )
                            )
                            .then(({ data }) => {
                              if (data.success) {
                                microApiResponseHandler(
                                  'association_contact',
                                  id,
                                  ind
                                );
                                handleNotifications(
                                  'success',
                                  'success',
                                  'Association contact updated successfully.'
                                );
                              } else {
                              }
                            })
                            .catch(error => {
                              console.log(error);
                            });
                          }else {
                            addField(
                              'contacts',
                              objectToFormData(
                                form.getFieldValue('association_contact')[index]
                              )
                            )
                            .then(({ data }) => {
                              if (data.success) {
                                microApiResponseHandler(
                                  'association_contact',
                                  data['data']['id'],
                                  ind
                                );
                                handleNotifications(
                                  'success',
                                  'success',
                                  'Association contact added successfully.'
                                );
                              } else {
                              }
                            })
                            .catch(error => {
                              console.log(error);
                            });
                            }
                        }}
                      >
                        Save
                      </Button>
                    </Space>}
                  </Form.Item>
                ))}
                <Form.Item
                  style={{
                    border: '1px dashed gray',
                    marginBottom: '1px',
                    paddingLeft: '7px'
                  }}
                >
                  {fields.length < 5 ? (
                    <Button
                      style={{ 
                        float: 'left',
                        clear: 'both'
                      }}
                      disabled={disabled}
                      type="primary"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add Association Contact
                    </Button>
                  ) : null}

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          {/* Brief Association Profile */}
          <React.Fragment>
            <div
              className="ant-row ant-form-item"
              style={{
                border: '1px solid gray',
                marginBottom: '0px',
                padding: '7px',
                backgroundColor: 'lightgray',
                color: 'rgba(0, 0, 0, 0.85)',
                fontSize: '14px'
              }}
            >
              Brief Association Profile: (max 300 words)
            </div>
          </React.Fragment>
          <TextArea
            name="description"
            placeholder="Brief Association Profile (max 300 words)"
            rows={3}
            disabled={disabled}
            maxLength={300}
            defaultValue={formValues['description']}
            onChange={e => {
              e.persist();
              debouncedInput(e, 'description');
            }}
          />

          {/* Board of Directors */}
          <Form.List name="association_event">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    wrapperCol={{ md: { offset: index === 0 ? 0 : 4, span: 20 }, sm: { span: 24 }}}
                    style={{
                      border: '1px solid gray',
                      marginBottom: '0px',
                      paddingLeft: '7px',
                      backgroundColor: 'lightgray'
                    }}
                    required={false}
                    key={`${field.key}_${index}`}
                    label={index === 0 ? 'Association Event' : ''}
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, 'name']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`name_${index}`}
                    >
                      <Input
                        disabled={disabled}
                        placeholder="Name"
                        key="name"
                        onChange={(e)=> resourceHandler("name", e.target.value, index)}
                        style={{ marginLeft: '-7px', width: '101%' }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'date']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`title_${index}`}
                    >
                      <DatePicker 
                        format={'MM/DD/YYYY'}
                        style={{float: 'left', clear: 'both'}}
                        disabled={disabled}
                        key="date"
                        onChange={(value)=> resourceHandler("date", value, index)}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'description']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`description_${index}`}
                    >
                      <Input
                        placeholder="description"
                        disabled={disabled}
                        key="description"
                        onChange={(e)=> resourceHandler("description", e.target.value, index)}
                        style={{ marginLeft: '-7px', width: '101%' }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'resource']}
                      style={{
                        borderBottom: '1px solid gray',
                        borderLeft: '1px solid gray',
                        borderRight: '1px solid gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        backgroundColor: 'lightgray'
                      }}
                      key={`resource_${index}`}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <FileUpload
                          keyName="resource"
                          styleObj={{ marginLeft: '-7px', width: '101%' }}
                          disabled={disabled}
                          label={
                            formValues?.['association_event']?.[index]?.[
                              'resource'
                            ]?.['url']
                              ? 'Re_upload'
                              : ''
                          }
                          parentHandler={(key, value) => {
                            resourceHandler(
                              'resource',
                              value ? value[key] : null,
                              index
                            );
                          }}
                        />
                        {formValues?.['association_event']?.[index]?.[
                          'resource'
                        ]?.['url'] ? (
                          <button
                            style={downloadStyle}
                            onClick={e =>
                              handleDownload(
                                e,
                                formValues?.['association_event']?.[index]?.[
                                  'resource'
                                ]?.['url'],
                                formValues?.['association_event']?.[index]?.[
                                  'resource'
                                ]?.['name']
                              )
                            }
                          >
                            <DownloadOutlined />{' '}
                            {
                              formValues?.['association_event']?.[index]?.[
                                'resource'
                              ]?.['name']
                            }
                          </button>
                        ) : null}
                      </div>
                    </Form.Item>

                    {!disabled && <Space direction="horizontal" style={{ float: 'right' }}>
                      <Button
                        type="warning"
                        style={{
                          marginTop: '0px',
                          backgroundColor: '#D26A31',
                          color: 'white'
                        }}
                        onClick={() => {
                          formValues['association_event'].splice(index, 1);
                          return remove(field.name);
                        }}
                      >
                        Remove
                      </Button>
                        <Button
                          type="warning"
                          style={{
                            float: 'right',
                            marginRight: '0px',
                            marginTop: '0px',
                            backgroundColor: '#82CF8F',
                            color: 'white'
                          }}
                          onClick={() => {
                            let id = form.getFieldValue('association_event')[ index]?.id;
                            let ind = index;
                            if(id){
                              updateField(
                                'events',
                                id,
                                objectToFormData(
                                  resources?.[index]
                                )
                              )
                              .then(({ data }) => {
                                if (data.success) {
                                  microApiResponseHandler(
                                    'association_event',
                                    id,
                                    ind
                                  );
                                  handleNotifications(
                                    'success',
                                    'success',
                                    'Association Event updated successfully.'
                                  );
                                } else {
                                }
                              })
                              .catch(error => {
                                console.log(error);
                              });
                            }else{
                              addField(
                                'events',
                                objectToFormData(
                                  resources?.[index]
                                )
                              )
                              .then(({ data }) => {
                                if (data.success) {
                                  microApiResponseHandler(
                                    'association_event',
                                    data['data']['id'],
                                    ind
                                  );
                                  handleNotifications(
                                    'success',
                                    'success',
                                    'Association Event added successfully.'
                                  );
                                } else {
                                }
                              })
                              .catch(error => {
                                console.log(error);
                              });
                            }
                          }}
                        >
                          Save
                        </Button>
                    </Space>}
                  </Form.Item>
                ))}
                <Form.Item
                  style={{
                    border: '1px dashed gray',
                    marginBottom: '1px',
                    paddingLeft: '7px'
                  }}
                >
                  {fields.length <= 8 ? (
                    <Button
                      style={{ 
                        float: 'left',
                        clear: 'both'
                      }}
                      disabled={disabled}
                      type="primary"
                      onClick={() => add()}
                      icon={<PlusOutlined />}
                    >
                      Add Event
                    </Button>
                  ) : null}

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          {/* Action Buttons */}
          {!disabled && <Form.Item
            wrapperCol={{ md: { offset: 6, span: 12 }, sm: { span: 24 }}}
            style={{ textAlign: 'center', marginTop: 15 }}
          >
            <Space direction="horizontal" align="center">
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <Button
                type="primary"
                htmlType="button"
                onClick={() => {
                  onCancel();
                }}
              >
                Cancel
              </Button>
            </Space>
          </Form.Item>}
        </Form>
        : <Spin />}
      </Col>
    </Row>
  );
};

Association.propTypes = {
  role: string,
  selfMode: bool,
  businessId: number,
  addAssociation: func,
  updateAssociation: func,
  onCancel: func
};

const mapStateToProps = state => ({
  role: state.auth.role,
  businessId: state.auth?.user?.businessId
});

const mapDispatchToProps = dispatch => ({
  addAssociation: payload => dispatch(onAddAssociation(payload)),
  updateAssociation: (id, payload) => dispatch(onUpdateAssociation(id, payload)),
  onCancel: () => dispatch(hideModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Association);
