import React from 'react';
import { string , bool} from 'prop-types';
import Search from '../../searchForms/Search';
import Search2 from '../../searchForms/Search2';

const SearchSidebar = ({ pathname, isSearchPage }) => {
  switch (pathname) {
    case 'association':
      return <Search name="association" isSearchPage={true} />;
    case 'small-business':
      return <Search name="smallBusiness" isSearchPage={true} />;
    case 'primes':
      return <Search name="primes" isSearchPage={true} />;
    case 'calendar':
      return <Search name="calendar" isSearchPage={true} />;
    case 'rfp-rfq':
      return <Search name="rfpRfq" isSearchPage={true} />;
    case 'look-ahead':
      return <Search name="lookAhead" isSearchPage={true} />;
    case 'message-center':
      return <Search name="message-center" isSearchPage={true} />;
    case 'users':
      return <Search name="users" isSearchPage={true} />;
   
    default:
      return null;
  }
};

SearchSidebar.propTypes = {
  pathname: string,
  isSearchPage: bool.isRequired, 
};

export default SearchSidebar;
