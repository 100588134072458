import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Image } from 'antd';
import { objectToFormData } from '../../utils/utils';

import { Form, Input, Button, Space } from 'antd';
import {
  updatePasswordAction,
  changePasswordAction
} from '../../redux/actions/auth';
import brandLogo from '../../assets/images/brand-logo.png';

const ChangePassword = props => {
  const [token, setToken] = useState('');

  useEffect(() => {
    if (props.location.search) {
      setToken(props.location.search.split('?')?.[1]);
    }
  }, [props.location.search]);

  const onSubmit = values => {
    if (props.loggedIn) {
      let payload = { ...values, user_id: props.user_id };
      props.changePassword(objectToFormData(payload));
    } else {
      let paylaod = { token, password: values.new_password };
      props.updatePassword(objectToFormData(paylaod));
    }
  };

  return (
    <div className="change-passowrd-container">
      <Image
        src={brandLogo}
        className="change-password"
        alt="BZConnects Logo"
        preview={false}
      />
      <Form
        layout="vertical"
        name="login-passord-form"
        className="login-passord-form"
        onFinish={onSubmit}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
      >
        {props.loggedIn && (
          <Form.Item
            name="old_password"
            label="Enter current Password"
            rules={[
              { required: true, message: 'Current Password is required' },
              { min: 6, message: 'Password too short' }
            ]}
          >
            <Input.Password placeholder="Current Password" />
          </Form.Item>
        )}

        <Form.Item
          name="new_password"
          label="Enter New Password"
          rules={[
            { required: true, message: 'New Password is required' },
            { min: 6, message: 'Password too short' }
          ]}
          hasFeedback
        >
          <Input.Password placeholder="New Password" />
        </Form.Item>

        <Form.Item>
          <Space direction="vertical">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

ChangePassword.propTypes = {
  loggedIn: PropTypes.bool,
  history: PropTypes.object,
  loginFromLocal: PropTypes.func
};

const mapStateToProps = state => ({
  loggedIn: state.auth.loggedIn,
  user_id: state.auth?.user?.user_id
});

const mapDispatchToProps = dispatch => ({
  changePassword: payload => dispatch(changePasswordAction(payload)),
  updatePassword: payload => dispatch(updatePasswordAction(payload))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
);
