import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { func, number, string } from 'prop-types';
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Row,
  Space,
  Typography,
  Spin,
  AutoComplete,
} from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';

import { addField, updateField } from '../../api/smallBusinessFields';
import { getSmallBusiness } from '../../api/smallBusiness';
import { hideModal } from '../../redux/actions/ui';
import {
  onUpdateSmallBusiness,
  verifyCertificatesAction
} from '../../redux/actions/smallBusiness';
import { handleNotifications } from '../../utils/notifications';
import { debounce, objectToFormData } from '../../utils/utils';
import ImageUpload from '../common/Form/FormInputs/ImageUpload';
import Address from '../common/Form/FormInputs/Address';
import Dropdowns from '../common/Form/FormInputs/Dropdowns';
import {
  NaicsCodes,
} from '../../utils/constants';
import dayjs from 'dayjs';
import moment from 'moment';

const { Title, Text } = Typography;
const { TextArea } = Input;

const SmallBusinessEdit = ({
  businessId,
  updateSmallBusiness,
}) => {
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  const smallBusinessId = businessId;

  useEffect(() => {
    if (smallBusinessId) {
      setLoading(true);
      getSmallBusiness(smallBusinessId)
        .then(({ data }) => {
          if (data['success']) {
            setDetails(data['data']);
            getFormValuesFromDetails(data['data']);
          } else {
            setDetails(null);
            handleNotifications('error', 'Failed', 'Could not fetch details');
          }
          setLoading(false);
        })
        .catch(error => {
          setDetails(null);
          setLoading(false);
          console.log(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smallBusinessId]);

  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState({})
  const [formValues, setFormValues] = useState({
    logo: null,
    company_name: null,
    phone: null,
    company_website: null,
    address: [],
    company_contacts: [],
    company_profile: null,
    naics_codes: [],
    company_services: [],
    industry_specialization: [],
    agencies: [],
    unspsc_codes: [],
    updated_on: ''
  });

  const [disableBtns, setDisableBtns] = useState({});

  const getFormValuesFromDetails = details => {
    const newFormValues = { ...formValues };
    const {
      logo_url: logoUrl,
      logo_name: logoName,
      company_name: companyName,
      phone,
      company_website: companyWebsite,
      address,
      company_contacts: companyContacts,
      company_profile: companyProfile,
      unspsc_codes,
      naics_codes: naicsCodes,
      company_services: companyServices,
      industry_specialization: industrySpecialization,
      agencies,
      updated_on: updatedOn
    } = details;

    if (logoUrl) {
      newFormValues['logo'] = {
        url: logoUrl,
        name: logoName
      };
    }
    if (address?.length) {
      newFormValues['address'] = address.map(item => ({
        id: item['id'],
        street: item['address'],
        state: item['state'],
        county: item['county'],
        zipcode: item['zip_code'],
        city: item['city']
      }));
    }
    if (unspsc_codes?.length) {
      newFormValues['unspsc_codes'] = unspsc_codes.map(item => ({
        id: item['id'],
        codes: item['codes'],
        description: item['description']
      }));
    }
    if (companyContacts?.length) {
      newFormValues['company_contacts'] = companyContacts.map(item => ({
        id: item['id'],
        name: item['name'],
        title: item['title'],
        phone: item['phone'],
        email: item['email']
      }));
    }
    if (naicsCodes?.length) {
      newFormValues['naics_codes'] = naicsCodes.map(item => ({
        id: item['id'],
        codes: item['codes'],
        description: item['description']
      }));
    }
    if (companyServices?.length) {
      newFormValues['company_services'] = [...companyServices];
    }
    if (industrySpecialization?.length) {
      newFormValues['industry_specialization'] = [...industrySpecialization];
    }

    setFormValues({
      ...newFormValues,
      company_name: companyName,
      phone,
      company_website: companyWebsite,
      company_profile: companyProfile,
      agencies: agencies || [],
      updated_on: updatedOn
    });
    setInitialValues({
      ...newFormValues,
      company_name: companyName,
      phone,
      company_website: companyWebsite,
      company_profile: companyProfile,
      agencies: agencies || [],
      updated_on: updatedOn
    })
  };

  const inputChangedHandler = (key, value) => {
    if (key === 'revenue') {
      setFormValues({
        ...formValues,
        [key]: { ...formValues[key], id: value }
      });
    } else {
      setFormValues({ ...formValues, [key]: value });
    }
  };

  const debouncedInput = debounce((e, key) => {
    if (key === 'other_demographic') {
      inputChangedHandler('owner_demographic', e.target.value);
      return;
    }
    inputChangedHandler(key, e.target.value);
  }, 500);

  const microApiResponseHandler = (key, value, index) => {
    let cert = [...formValues[key]];
    cert[index] = value;
    setFormValues({ ...formValues, [key]: cert });
    setDisableBtns(prevBtns => ({ ...prevBtns, [key]: false }));
  };

  const submit = values => {
    if (formValues.logo?.url) {
      delete formValues.logo;
    }

    let payload = {};
    for (let key in formValues) {
      if (formValues[key]) {
        if (Array.isArray(formValues[key]) && !formValues[key]?.length)
          continue;

        if (
          [
            'address',
            'company_contacts',
            'naics_codes',
            'unspsc_codes'
          ].includes(key)
        ) {
          payload[key] = JSON.stringify(
            formValues[key].map(item => item.id || item)
          );
        } else if (
          ['agencies', 'company_services', 'industry_specialization'].includes(
            key
          )
        ) {
          payload[key] = JSON.stringify(formValues[key]);
        } else {
          payload[key] = formValues[key];
        }
      }
    }
    console.log(payload);
    updateSmallBusiness(smallBusinessId, objectToFormData(payload));
  };

  const submitFail = errors => {
    console.log(errors);
    handleNotifications(
      'warning',
      'Warning',
      'Some fields require your attention.'
    );
  };

  const editImage = () => {
    setFormValues(prev => ({ ...prev, logo: null }));
  };

  const handleEdit = () => {
    setEdit(prev => !prev);
    edit && setFormValues({ ...initialValues });
  };

  return (
    <Card loading={loading}>
      {formValues && details ? (
        <Row align="middle" justify="center" className="small-business-form">
          <Col span={24}>
            <Title level={2} className="heading-title">
              Diverse Business Profile
            </Title>
            <Form
              form={form}
              name="small-business-free"
              layout="horizontal"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              labelAlign="left"
              onFinish={submit}
              validateTrigger={['']}
              onFinishFailed={submitFail}
              initialValues={{ ...formValues }}
            >
              <Row>
                <Col span={24}>
                  {!edit && (<div style={{ position: 'relative', marginBottom: 25}}>
                    <button
                      style={{
                        background: '#1890ff',
                        color: 'white',
                        border: 'none',
                        padding: '5px 10px',
                        position: 'absolute',
                        right: 10
                      }}
                      onClick={handleEdit}
                    >
                      Edit
                    </button>
                  </div>)}
                  {/* Company Logo */}
                  <Form.Item
                    key="logo"
                    name="logo"
                    hasFeedback
                    style={{ textAlign: 'right' }}
                    wrapperCol={{ md: { offset: 4 }, sm: { offset: 0 } }}
                  >
                    {formValues['logo'] && formValues['logo']?.['url'] ? (
                      <React.Fragment>
                        <Image
                          src={
                            formValues['logo'] ? formValues['logo']['url'] : ''
                          }
                          alt={
                            formValues['logo']
                              ? formValues['logo']['name']
                              : 'No logo uploaded.'
                          }
                          preview={false}
                          width="100px"
                          height="100px"
                        />
                        <Button
                          type="primary"
                          shape="circle"
                          icon={<EditOutlined />}
                          size="small"
                          style={{ transform: 'translate(-25px, -10px)' }}
                          onClick={editImage}
                        />
                      </React.Fragment>
                    ) : edit ? (
                      <ImageUpload
                        keyName="logo"
                        label="Company Logo"
                        max={1}
                        nestedHandler={(key, value) => {
                          inputChangedHandler(key, value[key]);
                        }}
                      />
                    ) : null}
                  </Form.Item>
                </Col>
              </Row>

              {/* Business Name */}
              <Form.Item
                key="company_name"
                name="company_name"
                label="Business Name"
                style={{
                  border: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
                hasFeedback
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Company name is required.'
                  }
                ]}
              >
                <Input
                  name="company_name"
                  placeholder="Business Name"
                  style={{
                    borderLeft: '1px solid gray',
                    backgroundColor: 'white'
                  }}
                  type="text"
                  disabled={!edit}
                  onChange={e => {
                    e.persist();
                    debouncedInput(e, 'company_name');
                  }}
                />
              </Form.Item>

              {/* Business Phone */}
              <Form.Item
                key="phone"
                name="phone"
                label="Business Phone"
                required={true}
                style={{
                  border: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
                hasFeedback
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    validator: async (_, phone) => {
                      if (!phone) {
                        return Promise.reject(
                          new Error('Phone Number is required.')
                        );
                      }
                    }
                  },
                ]}
              >
                <Input
                  name="phone"
                  placeholder="Business Phone (10-digit)"
                  type="tel"
                  // pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                  disabled={!edit}
                  style={{
                    borderLeft: '1px solid gray',
                    backgroundColor: 'white'
                  }}
                  maxLength={10}
                  onChange={e => {
                    e.persist();
                    debouncedInput(e, 'phone');
                  }}
                />
              </Form.Item>

              {/* Website */}
              <Form.Item
                key="company_website"
                name="company_website"
                label="Website"
                style={{
                  border: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
                hasFeedback
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Company Website is required.'
                  },
                  {
                    type: "url",
                    message: "This field must be a valid url."
                  }
                ]}
              >
                <Input
                  name="company_website"
                  placeholder="Company Website"
                  disabled={!edit}
                  style={{
                    borderLeft: '1px solid gray',
                    backgroundColor: 'white'
                  }}
                  onChange={e => {
                    e.persist();
                    debouncedInput(e, 'company_website');
                  }}
                />
              </Form.Item>

              {/* Business Addresses */}
              <Form.List
                name="address"
                rules={[
                  {
                    validator: async (_, address) => {
                      if (!address || address.length < 1) {
                        return Promise.reject(
                          new Error('At least 1 address is required.')
                        );
                      }
                    }
                  }
                ]}
              >
                {(fields, { add }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        wrapperCol={{
                          md: { offset: index === 0 ? 0 : 4, span: 20 },
                          sm: { span: 24 }
                        }}
                        style={{
                          border: '1px solid gray',
                          marginBottom: '0px',
                          paddingLeft: '7px',
                          clear: 'both',
                          backgroundColor: 'lightgray'
                        }}
                        required={true}
                        key={field.key}
                        label={index === 0 ? 'Business Address' : ''}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              validator: async (_, address) => {
                                if (!formValues['address'][index]) {
                                  return Promise.reject(
                                    new Error(
                                      'Please input details and Save or delete this field.'
                                    )
                                  );
                                }
                              }
                            }
                          ]}
                          noStyle
                        >
                          <Address
                            keyName="address"
                            microApi={{ add: addField, update: updateField }}
                            parentHandler={microApiResponseHandler}
                            disabled={!edit}
                            hasCity={true}
                            hasZip={true}
                            index={index}
                            valueProps={formValues['address'][index]}
                          />
                        </Form.Item>

                        {edit && <Button
                          type="warning"
                          style={{
                            float: 'right',
                            marginRight: '3px',
                            marginTop: '0px',
                            backgroundColor: '#D26A31',
                            color: 'white'
                          }}
                          onClick={() => {
                            formValues['address'].splice(index, 1);
                            return form.setFieldsValue({
                              address: formValues?.['address']
                            });
                          }}
                        >
                          Remove
                        </Button>}
                      </Form.Item>
                    ))}
                    <Form.Item
                      style={{
                        border: '1px dashed gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                        float: 'left',
                        clear: 'both'
                      }}
                    >
                      {edit && fields.length < 5 ? (
                        <Button
                          type="primary"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add Address
                        </Button>
                      ) : null}

                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>

              {/* Company Contacts */}
              <Form.List name="company_contacts">
                {(fields, { add }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        wrapperCol={{
                          md: { offset: index === 0 ? 0 : 4, span: 20 },
                          sm: { span: 24 }
                        }}
                        style={{
                          border: '1px solid gray',
                          marginBottom: '0px',
                          paddingLeft: '7px',
                          backgroundColor: 'lightgray',
                          clear: 'both'
                        }}
                        required={false}
                        key={`${field.key}_${index}`}
                        label={index === 0 ? 'Company Contacts' : ''}
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, 'name']}
                          key={`name_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="Name"
                            disabled={!edit}
                            style={{ marginLeft: '-7px', width: '101%' }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'title']}
                          key={`title_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="Title"
                            disabled={!edit}
                            style={{ marginLeft: '-7px', width: '101%' }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'phone']}
                          key={`phone_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="Phone"
                            type="tel"
                            // pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                            maxLength={10}
                            disabled={!edit}
                            style={{ marginLeft: '-7px', width: '101%' }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'email']}
                          key={`email_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="Email"
                            disabled={!edit}
                            type="email"
                            style={{ marginLeft: '-7px', width: '101%' }}
                          />
                        </Form.Item>

                        {edit && <Space
                          direction="horizontal"
                          style={{ float: 'right' }}
                        >
                          <Button
                            type="warning"
                            style={{
                              marginTop: '0px',
                              backgroundColor: '#D26A31',
                              color: 'white'
                            }}
                            onClick={() => {
                              formValues['company_contacts'].splice(index, 1);
                              form.setFieldsValue({
                                company_contacts:
                                  formValues?.['company_contacts']
                              });
                            }}
                          >
                            Remove
                          </Button>
                          <Button
                            type="warning"
                            style={{
                              float: 'right',
                              marginRight: '0px',
                              marginTop: '0px',
                              backgroundColor: '#82CF8F',
                              color: 'white'
                            }}
                            disabled={disableBtns['company_contacts']}
                            onClick={() => {
                              let ind = index;
                              let id =
                                form.getFieldValue('company_contacts')[index]
                                  ?.id;
                              setDisableBtns(prevBtns => ({
                                ...prevBtns,
                                company_contacts: true
                              }));
                              if (id) {
                                updateField(
                                  'company_contacts',
                                  id,
                                  objectToFormData(
                                    form.getFieldValue('company_contacts')[
                                      index
                                    ]
                                  )
                                )
                                  .then(({ data }) => {
                                    if (data.success) {
                                      microApiResponseHandler(
                                        'company_contacts',
                                        id,
                                        ind
                                      );
                                      handleNotifications(
                                        'success',
                                        'success',
                                        'Company contact updated successfully.'
                                      );
                                    }
                                  })
                                  .catch(error => {
                                    console.log(error);
                                    setDisableBtns(prevBtns => ({
                                      ...prevBtns,
                                      naics_codes: false
                                    }));
                                  });
                              } else {
                                addField(
                                  'company_contacts',
                                  objectToFormData(
                                    form.getFieldValue('company_contacts')[
                                      index
                                    ]
                                  )
                                )
                                  .then(({ data }) => {
                                    if (data.success) {
                                      microApiResponseHandler(
                                        'company_contacts',
                                        data['data']['id'],
                                        ind
                                      );
                                      handleNotifications(
                                        'success',
                                        'success',
                                        'Company contact updated successfully.'
                                      );
                                    }
                                  })
                                  .catch(error => {
                                    console.log(error);
                                    setDisableBtns(prevBtns => ({
                                      ...prevBtns,
                                      naics_codes: false
                                    }));
                                  });
                              }
                            }}
                          >
                            Save
                          </Button>
                        </Space>}
                      </Form.Item>
                    ))}
                    <Form.Item
                      style={{
                        border: '1px dashed gray',
                        marginBottom: '1px',
                        paddingLeft: '7px',
                        clear: 'both',
                        float: 'left'
                      }}
                    >
                      {edit && fields.length < 5 ? (
                        <Button
                          type="primary"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add Company Contact
                        </Button>
                      ) : null}

                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>

              {/* UNSPSC Code */}
              <Form.List name="unspsc_codes">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        key={`${field.key}_${index}`}
                        wrapperCol={{
                          md: { offset: index === 0 ? 0 : 4, span: 20 },
                          sm: { span: 24 }
                        }}
                        style={{
                          border: '1px solid gray',
                          marginBottom: '0px',
                          paddingLeft: '7px',
                          clear: 'both',
                          backgroundColor: 'lightgray'
                        }}
                        label={index === 0 ? 'UNSPSC Codes' : ''}
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, 'codes']}
                          key={`codes_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="UNSPSC Code"
                            disabled={!edit}
                            style={{ marginLeft: '-7px', width: '101%' }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'description']}
                          key={`description_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="UNSPSC Description"
                            disabled={!edit}
                            style={{ marginLeft: '-7px', width: '101%' }}
                          />
                        </Form.Item>

                        {edit && <Space
                          direction="horizontal"
                          style={{ float: 'right' }}
                        >
                          <Button
                            type="warning"
                            style={{
                              marginTop: '0px',
                              backgroundColor: '#D26A31',
                              color: 'white'
                            }}
                            onClick={() => {
                              formValues['unspsc_codes'].splice(index, 1);
                              form.setFieldsValue({
                                unspsc_codes: formValues?.['unspsc_codes']
                              });
                            }}
                          >
                            Remove
                          </Button>
                          <Button
                            type="warning"
                            style={{
                              float: 'right',
                              marginRight: '0px',
                              marginTop: '0px',
                              backgroundColor: '#82CF8F',
                              color: 'white'
                            }}
                            disabled={disableBtns['unspsc_codes']}
                            onClick={() => {
                              let ind = index;
                              let id =
                                form.getFieldValue('unspsc_codes')[index]?.id;
                              setDisableBtns(prevBtns => ({
                                ...prevBtns,
                                unspsc_codes: true
                              }));
                              if (id) {
                                updateField(
                                  'unspsc',
                                  id,
                                  objectToFormData(
                                    form.getFieldValue('unspsc_codes')[index]
                                  )
                                )
                                  .then(({ data }) => {
                                    if (data.success) {
                                      microApiResponseHandler(
                                        'unspsc_codes',
                                        id,
                                        ind
                                      );
                                      handleNotifications(
                                        'success',
                                        'success',
                                        'UNSPSC Code updated successfully.'
                                      );
                                    } else {
                                    }
                                  })
                                  .catch(error => {
                                    console.log(error);
                                    setDisableBtns(prevBtns => ({
                                      ...prevBtns,
                                      unspsc_codes: false
                                    }));
                                  });
                              } else {
                                addField(
                                  'unspsc',
                                  objectToFormData(
                                    form.getFieldValue('unspsc_codes')[index]
                                  )
                                )
                                  .then(({ data }) => {
                                    if (data.success) {
                                      microApiResponseHandler(
                                        'unspsc_codes',
                                        data['data']['id'],
                                        ind
                                      );
                                      handleNotifications(
                                        'success',
                                        'success',
                                        'UNSPSC Code updated successfully.'
                                      );
                                    } else {
                                    }
                                  })
                                  .catch(error => {
                                    console.log(error);
                                    setDisableBtns(prevBtns => ({
                                      ...prevBtns,
                                      unspsc_codes: false
                                    }));
                                  });
                              }
                            }}
                          >
                            Save
                          </Button>
                        </Space>}
                      </Form.Item>
                    ))}
                    <Form.Item
                      style={{
                        border: '1px dashed gray',
                        marginBottom: '1px',
                        clear: 'both',
                        float: 'left',
                        paddingLeft: '7px'
                      }}
                    >
                      {edit && fields.length < 2 ? (
                        <Button
                          type="primary"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add UNSPSC Code
                        </Button>
                      ) : null}

                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>

              {/* Brief Company Description */}
              <div
                className="ant-row ant-form-item"
                style={{
                  border: '1px solid gray',
                  marginBottom: '0px',
                  padding: '7px',
                  backgroundColor: 'lightgray',
                  color: 'rgba(0, 0, 0, 0.85)',
                  fontSize: '14px',
                  clear: 'both'
                }}
              >
                Brief Company Description:
              </div>
              <TextArea
                name="company_profile"
                placeholder="Brief Company Description"
                rows={3}
                disabled={!edit}
                defaultValue={formValues['company_profile']}
                maxLength={300}
                onChange={e => {
                  e.persist();
                  debouncedInput(e, 'company_profile');
                }}
              />

              {/* NAICS Code */}
              <Form.List name="naics_codes">
                {(fields, { add }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        wrapperCol={{
                          md: { offset: index === 0 ? 0 : 4, span: 20 },
                          sm: { span: 24 }
                        }}
                        key={`${field.key}_${index}`}
                        label={index === 0 ? 'NAICS Codes' : ''}
                        style={{
                          border: '1px solid gray',
                          marginBottom: '0px',
                          paddingLeft: '7px',
                          backgroundColor: 'lightgray'
                        }}
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, 'codes']}
                          key={`codes_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <AutoComplete
                            style={{ marginLeft: '-7px', width: '101%' }}
                            options={NaicsCodes}
                            disabled={!edit}
                            placeholder="Search Naics Codes"
                            filterOption={(inputValue, option) => {
                              let bool = !isNaN(inputValue)
                                ? option?.value.toString().includes(inputValue)
                                : option?.label
                                    .toUpperCase()
                                    .indexOf(inputValue.toUpperCase()) !== -1;
                              if (bool) {
                                let val = form.getFieldValue('naics_codes');
                                let obj = {
                                  ...val?.[index],
                                  description: option.label
                                };
                                val.splice(index, 1, obj);
                                form.setFieldsValue({ naics_codes: [...val] });
                              }
                              return bool;
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'description']}
                          key={`description_${index}`}
                          style={{
                            borderBottom: '1px solid gray',
                            borderLeft: '1px solid gray',
                            borderRight: '1px solid gray',
                            marginBottom: '0px',
                            paddingLeft: '7px',
                            backgroundColor: 'lightgray'
                          }}
                        >
                          <Input
                            placeholder="NAICS Description"
                            style={{ marginLeft: '-7px', width: '101%' }}
                            disabled={!edit}
                          />
                        </Form.Item>

                        {edit && <Space
                          direction="horizontal"
                          style={{ float: 'right' }}
                        >
                          <Button
                            type="warning"
                            style={{
                              marginTop: '0px',
                              backgroundColor: '#D26A31',
                              color: 'white'
                            }}
                            onClick={() => {
                              formValues['naics_codes'].splice(index, 1);
                              form.setFieldsValue({
                                naics_codes: formValues?.['naics_codes']
                              });
                            }}
                          >
                            Remove
                          </Button>
                          <Button
                            type="warning"
                            style={{
                              float: 'right',
                              marginRight: '0px',
                              marginTop: '0px',
                              backgroundColor: '#82CF8F',
                              color: 'white'
                            }}
                            disabled={disableBtns['naics_codes']}
                            onClick={() => {
                              let ind = index;
                              let id =
                                form.getFieldValue('naics_codes')[index]?.id;
                              setDisableBtns(prevBtns => ({
                                ...prevBtns,
                                naics_codes: true
                              }));
                              if (id) {
                                updateField(
                                  'naics_codes',
                                  id,
                                  objectToFormData(
                                    form.getFieldValue('naics_codes')[index]
                                  )
                                )
                                  .then(({ data }) => {
                                    if (data.success) {
                                      microApiResponseHandler(
                                        'naics_codes',
                                        id,
                                        ind
                                      );
                                      handleNotifications(
                                        'success',
                                        'success',
                                        'Naics Code updated successfully.'
                                      );
                                    } else {
                                      // microApiResponseHandler('naics_codes', null);
                                    }
                                  })
                                  .catch(error => {
                                    console.log(error);
                                    setDisableBtns(prevBtns => ({
                                      ...prevBtns,
                                      naics_codes: false
                                    }));
                                  });
                              } else {
                                addField(
                                  'naics_codes',
                                  objectToFormData(
                                    form.getFieldValue('naics_codes')[index]
                                  )
                                )
                                  .then(({ data }) => {
                                    if (data.success) {
                                      microApiResponseHandler(
                                        'naics_codes',
                                        data['data']['id'],
                                        ind
                                      );
                                      handleNotifications(
                                        'success',
                                        'success',
                                        'Naics Code updated successfully.'
                                      );
                                    } else {
                                      // microApiResponseHandler('naics_codes', null);
                                    }
                                  })
                                  .catch(error => {
                                    console.log(error);
                                    setDisableBtns(prevBtns => ({
                                      ...prevBtns,
                                      naics_codes: false
                                    }));
                                  });
                              }
                            }}
                          >
                            Save
                          </Button>
                        </Space>}
                      </Form.Item>
                    ))}
                    {edit && fields.length < 5 ? (
                      <Form.Item
                        style={{
                          border: '1px dashed gray',
                          marginBottom: '1px',
                          paddingLeft: '7px',
                          float: 'left'
                        }}
                      >
                        <Button
                          type="primary"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add NAICS Code
                        </Button>

                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    ) : null}
                  </>
                )}
              </Form.List>

              {/* Company Core Services */}
              <React.Fragment>
                <div
                  className="ant-row ant-form-item"
                  style={{
                    border: '1px solid gray',
                    marginBottom: '0px',
                    padding: '7px',
                    backgroundColor: 'lightgray',
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontSize: '14px',
                    clear: 'both'
                  }}
                >
                  Company Core Services:
                </div>
              </React.Fragment>
              <Dropdowns
                keyName="service"
                label="service"
                multiSelect={true}
                disabled={!edit}
                valueProps={formValues['company_services']}
                onChange={(value) => {
                  inputChangedHandler('company_services', [...value]);
                }}
              />

              {/* Company Specializations */}
              <React.Fragment>
                <div
                  className="ant-row ant-form-item"
                  style={{
                    border: '1px solid gray',
                    marginBottom: '0px',
                    padding: '7px',
                    backgroundColor: 'lightgray',
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontSize: '14px'
                  }}
                >
                  Company Specialization:
                </div>
              </React.Fragment>
              <Dropdowns
                keyName="specializations"
                label="specializations"
                multiSelect={true}
                disabled={!edit}
                valueProps={formValues['industry_specialization']}
                onChnage={(value) => {
                  inputChangedHandler('industry_specialization', [
                    ...value
                  ]);
                }}
              />

              {/* Profile Last Updated */}
              <br/>
              <br/>
              <strong>Profile Created : </strong>
              {details['created_on'] ? <Text>
                {moment
                  .utc(details['created_on'])
                  .utcOffset(dayjs().utcOffset())
                  .format('MM/DD/YYYY hh:mm A')}
              </Text> : '-'}
              <br />
              <strong>Profile Last Updated : </strong>
              {details['update_on'] ? <Text>
                {moment
                  .utc(details['updated_on'])
                  .utcOffset(dayjs().utcOffset())
                  .format('MM/DD/YYYY hh:mm A')}
              </Text> : '-'}

              <br/>
              <br/>
              {/* Action Buttons */}
              <Form.Item
                wrapperCol={{ md: { offset: 6, span: 12 }, sm: { span: 24 } }}
                style={{ textAlign: 'center' }}
              >
                <Space direction="horizontal" align="center">
                  {edit && (<>
                    <Button type="primary" htmlType="submit">
                      Update
                    </Button>
                    <Button type="primary" onClick={handleEdit}>
                      Reset
                    </Button>
                  </>)}
                </Space>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      ) : (
        <Spin />
      )}
    </Card>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  businessId: state.auth?.user?.businessId
});

const mapDispatchToProps = dispatch => ({
  updateSmallBusiness: (smallBusinessId, payload) =>
    dispatch(onUpdateSmallBusiness(smallBusinessId, payload)),
  verifyCertificates: (smallBusinessId, payload) =>
    dispatch(verifyCertificatesAction(smallBusinessId, payload)),
  onCancel: () => dispatch(hideModal())
});

SmallBusinessEdit.propTypes = {
  role: string,
  businessId: number,
  updateSmallBusiness: func,
  onCancel: func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SmallBusinessEdit));
