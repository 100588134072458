import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card,
  Col,
  Image,
  Input,
  Row,
  Space,
  Typography
} from 'antd';

import { getSmallBusiness } from '../../api/smallBusiness';
import { handleNotifications } from '../../utils/notifications';
import { getcurrentDateTime } from '../../utils/dateTimeHelper';
import dayjs from 'dayjs';

const { Title } = Typography;
const { TextArea } = Input;

const styles = {
  title: {
    padding: '5px',
    border: '0.1px solid gray'
  },
  headerStyle: {
    border: '1px solid gray',
    marginBottom: '0px',
    padding: '7px',
    backgroundColor: '#E8E8E8',
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: '14px'
  },
  company_name: { marginTop: 0, fontWeight: 500 }
}

const SmallBusinessPrintView = () => {
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const { smallBusinessId } = useParams();

  useEffect(() => {
    if (smallBusinessId) {
      setLoading(true);
      getSmallBusiness(smallBusinessId)
        .then(({ data }) => {
          if (data['success']) {
            setDetails(data['data']);
            getFormValuesFromDetails(data['data']);
          } else {
            setDetails(null);
            handleNotifications('error', 'Failed', 'Could not fetch details');
          }
          setLoading(false);
        })
        .catch(error => {
          setDetails(null);
          setLoading(false);
          console.log(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smallBusinessId]);

  const [formValues, setFormValues] = useState({
    logo: null,
    company_name: '',
    phone: '',
    company_website: '',
    due_diligence: '',
    address: [],
    company_contacts: [],
    company_profile: '',
    naics_codes: [],
    unspsc_codes: [],
    service: [],
    specialization: [],
    agencies: [],
    revenue: null,
    no_of_employees: [],
    certification: [],
    soq: null,
    linkedin_profile: '',
    owner_demographic: '',
    liability_insurance: null,
    compensation_insurance: null,
    disability_insurance: null,
    updated_on: '',
    tags: [],
    service_type: '',
    si_name: '',
    verification_step_id:"",
  });

  const getFormValuesFromDetails = details => {
    const newFormValues = { ...formValues };
    const {
      logo_url: logoUrl,
      logo_name: logoName,
      company_name: companyName,
      phone,
      company_website: companyWebsite,
      due_diligence: due_diligence,
      si_name: si_name,
      address,
      tags,
      service_type: service_type,
      company_contacts: companyContacts,
      company_profile: companyProfile,
      naics_codes: naicsCodes,
      company_services: companyServices,
      industry_specialization: industrySpecialization,
      agencies,
      previous_year_revenue: previousYearRevenue,
      no_of_employees: noOfEmployees,
      certification,
      unspsc_codes,
      soq_name: soqName,
      soq_url: soqUrl,
      verification_step_id:verification_step_id,
      linkedin_profile: linkedinProfile,
      owner_demographic: ownerDemographic,
      liability_insurance_proof_name: liabilityInsuranceProofName,
      liability_insurance_proof_url: liabilityInsuranceProofUrl,
      worker_compensation_insurance_proof_name: workerCompensationInsuranceProofName,
      worker_compensation_insurance_proof_url: workerCompensationInsuranceProofUrl,
      disability_insurance_proof_name: disabilityInsuranceProofName,
      disability_insurance_proof_url: disabilityInsuranceProofUrl,
      updated_on: updatedOn
    } = details;
    if (logoUrl) {
      newFormValues['logo'] = {
        url: logoUrl,
        name: logoName
      };
    }
    if (address?.length) {
      newFormValues['address'] = address.map(item => ({
        id: item['id'],
        street: item['address'],
        state: item['state'],
        county: item['county'],
        zipcode: item['zip_code'],
        city: item['city']
      }));
    }
    if (companyContacts?.length) {
      newFormValues['company_contacts'] = companyContacts.map(item => ({
        id: item['id'],
        name: item['name'],
        title: item['title'],
        phone: item['phone'],
        email: item['email']
      }));
    }
    if (naicsCodes?.length) {
      newFormValues['naics_codes'] = naicsCodes.map(item => ({
        id: item['id'],
        codes: item['codes'],
        description: item['description']
      }));
    }
    if (unspsc_codes?.length) {
      newFormValues['unspsc_codes'] = unspsc_codes.map(item => ({
        id: item['id'],
        codes: item['codes'],
        description: item['description']
      }));
    }
    if (companyServices?.length) {
      newFormValues['service'] = [...companyServices];
    }
    if (industrySpecialization?.length) {
      newFormValues['specialization'] = [...industrySpecialization];
    }
    if (previousYearRevenue) {
      newFormValues['revenue'] = {
        id: previousYearRevenue['id'],
        first_year_revenue: [previousYearRevenue['first_year_revenue']],
        second_year_revenue: [previousYearRevenue['second_year_revenue']],
        third_year_revenue: [previousYearRevenue['third_year_revenue']]
      };
    }
    if (noOfEmployees) {
      newFormValues['no_of_employees'] = [noOfEmployees];
    }
    if (certification) {
      newFormValues['certification'] = certification.map(item => ({
        id: item['id'],
        certifications_name: item['certification'],
        certification_agency: item['certification_agency'],
        certification_no: item['certification_no'],
        certification_proof: {
          name: item['proof_name'],
          url: item['proof_url']
        },
        expiration_date: item['expiration_date']
      }));
    }
    if (soqUrl) {
      newFormValues['soq'] = { url: soqUrl, name: soqName };
    }
    if (service_type) {
      newFormValues['service_type'] = [service_type];
    }
    if (liabilityInsuranceProofUrl) {
      newFormValues['liability_insurance'] = {
        url: liabilityInsuranceProofUrl,
        name: liabilityInsuranceProofName
      };
    }
    if (workerCompensationInsuranceProofUrl) {
      newFormValues['compensation_insurance'] = {
        url: workerCompensationInsuranceProofUrl,
        name: workerCompensationInsuranceProofName
      };
    }
    if (disabilityInsuranceProofUrl) {
      newFormValues['disability_insurance'] = {
        url: disabilityInsuranceProofUrl,
        name: disabilityInsuranceProofName
      };
    }
    setFormValues({
      ...newFormValues,
      company_name: companyName,
      phone,
      company_website: companyWebsite,
      due_diligence: due_diligence,
      company_profile: companyProfile,
      agencies,
      tags,
      si_name,
      soqName,
      liability_insurance_proof_name: liabilityInsuranceProofName,
      linkedin_profile: linkedinProfile,
      owner_demographic: ownerDemographic,
      updated_on: updatedOn,
      worker_compensation_insurance_proof_name: workerCompensationInsuranceProofName,
      disability_insurance_proof_name: disabilityInsuranceProofName,
      verification_step_id,
    });
  };

  return (
    <Card loading={loading}>
      {details ? (
        <Row align="middle" justify="center">
          <Col span={24}>
            <Row>
              <Col span={12}>
                <Title level={3} style={{ color: '#4A71A1', fontWeight: 'bold' }}>
                  BZ One Company Profile
                </Title>
                <Title level={2} style={styles.company_name}>
                  {formValues['company_name']}
                </Title>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Space direction="vertical">
                  {/* <Button
                    type="primary"
                    htmlType="button"
                    size="small"
                    icon={<PrinterFilled />}
                    onClick={() => window.print()}
                  >
                    Print
                  </Button> */}
                  <Image
                    src={
                      formValues['logo']?.['url']
                        ? formValues['logo']['url']
                        : ''
                    }
                    preview={false}
                    width="100px"
                    height="100px"
                  />
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={6} style={styles.headerStyle}>
                <Title level={5}>Business Main Address</Title>
                <Title level={5} style={{ marginTop: 0, fontSize: '12px' }}>
                  (Street Address, City, Zip)
                </Title>
              </Col>
              <Col span={18} style={styles.title}>
                <ul>
                  {formValues?.['address']?.map((item, i) => (
                    <li
                      key={i}
                    >{`${item.street} ${item.state} ${item.zipcode}`}</li>
                  ))}
                </ul>
              </Col>
              <Col span={6} style={styles.headerStyle}>
                <Title level={5}>Business Phone</Title>
              </Col>
              <Col span={18} style={styles.title}>
                {formValues['phone']}
              </Col>
              <Col span={6} style={styles.headerStyle}>
                <Title level={5}>Website</Title>
              </Col>
              <Col span={18} style={styles.title}>
                {formValues['company_website']}
              </Col>
              <Col span={6} style={styles.headerStyle}>
                <Title level={5}>Business Type</Title>
              </Col>
              <Col span={18} style={styles.title}>
                <div>
                  <ul>
                    {formValues?.['tags']?.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                </div>
              </Col>
              <Col span={6} style={styles.headerStyle}>
                <Title level={5}>Service Type</Title>
              </Col>
              <Col span={18} style={styles.title}>
                {formValues['service_type']}
              </Col>
              <Col span={6} >
                <div
                  className="ant-row ant-form-item"
                  style={styles.headerStyle}
                >
                  Number of Employees
                </div>

              </Col>
              <Col span={18} style={styles.title}>
                {formValues['no_of_employees']}
              </Col>


            </Row>
            <Row>
              <Col span={24} style={{ border: '0.1px solid' }}>
                {/* UNSPSC Code */}
                <div
                  className="ant-row ant-form-item"
                  style={styles.headerStyle}
                >
                  UNSPSC Codes:
                </div>
                <div>
                  <ul>
                    {formValues?.['unspsc_codes']?.map((item) => (
                      <li key={item.id}>{item.codes} - {item.description}</li>
                    ))}
                  </ul>
                </div>
              </Col>
              <Col span={24}>
                <div className="ant-row" style={styles.headerStyle}>
                  Contact:
                </div>
                <div>
                  {formValues?.['company_contacts']?.map((item, i) => (
                    <Row>
                      <Col key={i} span={6} style={styles.headerStyle}>
                        Key Contact Name:
                      </Col>
                      <Col key={i} span={18} style={styles.title}>
                        {item.name}
                      </Col>
                      <Col key={i} span={6} style={styles.headerStyle}>
                        Title:
                      </Col>
                      <Col key={i} span={18} style={styles.title}>
                        {item.title}
                      </Col>
                      <Col key={i} span={6} style={styles.headerStyle}>
                        Contact Phone:
                      </Col>
                      <Col key={i} span={18} style={styles.title}>
                        {item.phone}
                      </Col>
                      <Col key={i} span={6} style={styles.headerStyle}>
                        Email:
                      </Col>
                      <Col key={i} span={18} style={styles.title}>
                        {item.email}
                      </Col>
                      <Col key="separator" span={24} style={styles.headerStyle}>
                      </Col>
                    </Row>
                  ))}
                </div>
              </Col>
            </Row>

            <Row>
              <Col span={12} style={{ border: '0.1px solid' }}>
                {/* NAICS Code */}
                <div
                  className="ant-row ant-form-item"
                  style={styles.headerStyle}
                >
                  NAICS Codes:
                </div>
                <div>
                  <ul>
                    {formValues?.['naics_codes']?.map((item, i) => (
                      <li key={item.id}>{item.codes} - {item.description}</li>
                    ))}
                  </ul>
                </div>
              </Col>
              <Col span={12} style={{ border: '0.1px solid' }}>
                {/* Company Core Services */}
                <div
                  className="ant-row ant-form-item"
                  style={styles.headerStyle}
                >
                  Company Core Services:
                </div>
                <div>
                  <ul>
                    {formValues?.['service']?.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ border: '0.1px solid' }}>
                {/* Company Specializations */}
                <div
                  className="ant-row ant-form-item"
                  style={styles.headerStyle}
                >
                  Company Specialization:
                </div>
                <div>
                  <ul>
                    {formValues?.['specialization']?.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                </div>
              </Col>
              <Col span={12} style={{ border: '0.1px solid' }}>
                {/* Agency Experience */}
                <div
                  className="ant-row ant-form-item"
                  style={styles.headerStyle}
                >
                  Agency/Company Experience:
                </div>
                <div>
                  <ul>
                    {formValues?.['agencies']?.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>

            {/* <Row>
              <Col span={6} >
                <div
                  className="ant-row ant-form-item"
                  style={styles.headerStyle}
                >
                  Structural Integrity:
                </div>

              </Col>
              <Col span={18} style={styles.title}>
                {formValues['due_diligence']}
              </Col>

            </Row>
            <Row>
              <Col span={6} >
                <div
                  className="ant-row ant-form-item"
                  style={styles.headerStyle}
                >
                  Structural Integrity Doc:
                </div>

              </Col>
              <Col span={18} style={styles.title}>
                {formValues['si_name']}
              </Col>

            </Row> */}

            <Row>
              <Col span={6} >
                <div
                  className="ant-row ant-form-item"
                  style={styles.headerStyle}
                >
                  Structural Integrity:
                </div>

              </Col>
              <Col span={18} style={styles.title}>
                {formValues['due_diligence']}
              </Col>

            </Row>
            <Row>
              <Col span={6} >
                <div
                  className="ant-row ant-form-item"
                  style={styles.headerStyle}
                >
                  Structural Integrity Doc:
                </div>

              </Col>
              <Col span={18} style={styles.title}>
                {formValues['si_name']}
              </Col>

            </Row>

            <Row>
              <Col span={24}>
                {/* Brief Company Description */}
                <div
                  className="ant-row ant-form-item"
                  style={styles.headerStyle}
                >
                  Brief Company Description:
                </div>
                <TextArea
                  rows={3}
                  disabled
                  defaultValue={formValues['company_profile']}
                />
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <div
                  className="ant-row ant-form-item"
                  style={styles.headerStyle}
                >
                  Certifications:
                </div>
                <div style={styles.title}>
                  <ul>
                    {formValues?.['certification']?.map((item, i) => (
                      <li key={i}>{item['certifications_name']}</li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
            <Row>

              <Row>
                <Col span={24}>
                  <div className="ant-row" style={styles.headerStyle}>
                    Additional Information:
                  </div>
                  <div>

                    <Row>
                      <Col span={6} style={styles.headerStyle}>
                        Capability Statement:
                      </Col>
                      <Col span={18} style={styles.title}>
                        {formValues['soqName']}
                      </Col>

                      <Col span={6} style={styles.headerStyle}>
                        Company LinkedIn Profile:
                      </Col>
                      <Col span={18} style={styles.title}>
                        {formValues['linkedin_profile']}
                      </Col>

                      <Col span={6} style={styles.headerStyle}>
                        Owner Demographic:
                      </Col>
                      <Col span={18} style={styles.title}>
                        {formValues['owner_demographic']}
                      </Col>

                      <Col span={6} style={styles.headerStyle}>
                        Liability Insurance:
                      </Col>
                      <Col span={18} style={styles.title}>
                        {formValues['liability_insurance_proof_name']}
                      </Col>

                      <Col span={6} style={styles.headerStyle}>
                        Compensation Insurance:
                      </Col>
                      <Col span={18} style={styles.title}>
                        {formValues['worker_compensation_insurance_proof_name']}
                      </Col>

                      <Col span={6} style={styles.headerStyle}>
                        Disablity Insurances:
                      </Col>
                      <Col span={18} style={styles.title}>
                        {formValues['disability_insurance_proof_name']}
                      </Col>

                      <Col span={6} style={styles.headerStyle}>
                        Verification Step:
                      </Col>
                      <Col span={18} style={styles.title}>
                      {formValues['verification_step_id']}
                      </Col>

                    </Row>

                  </div>
                </Col>

              </Row>
              <Col span={12}>
                Printed On:{' '}
                {dayjs(getcurrentDateTime()).format('MM/DD/YYYY hh:mm A').toString()}
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {/* <Image
                  src={brandLogo}
                  preview={false}
                  width="150px"
                  height="70px"
                /> */}
              </Col>
            </Row>
          </Col>
        </Row>
      ) : null}
    </Card>
  );
};

export default SmallBusinessPrintView;
