import React, { useState, useEffect } from 'react';
import { getContact } from '../../api/contact';
import { handleNotifications } from '../../utils/notifications';
import { Button, Card, Row, Col, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { string, func } from 'prop-types';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { objectToFormData } from '../../utils/utils';
import { hideModal } from '../../redux/actions/ui';
import { onAddContact } from '../../redux/actions/contact';
import dayjs from 'dayjs';
import moment from 'moment';

const { Title } = Typography;

const View = ({ role, updateContact }) => {
  const params = useParams();

  const [contactDetails, setContactDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const innerWidth = window.innerWidth;

  useEffect(() => {
    if (params['contactId']) {
      setLoading(true);
      getContact(params['contactId'])
        .then(({ data }) => {
          setLoading(false);
          if (data.success) {
            setContactDetails(data.data);
          }
        })
        .catch(error => {
          setLoading(false);
          handleNotifications('error', 'Failed', error.message);
        });
    }
    // eslint-disable-next-line
  }, []);

  const markAsCompleted = () => {
    updateContact(
      objectToFormData({
        action: 'complete',
        obj_id: params['contactId']
      })
    );
  };

  return (
    <>
      <Card loading={loading}>
        {contactDetails ? (
          <>
            <Title level={3}>
              {contactDetails.author_name} | {contactDetails.author_email}
            </Title>
            <Row>
              <Col span={24}>
                <h3>Subject: </h3>
                <p>{contactDetails.subject}</p>
              </Col>
              <Col span={24}>
                <h3>Message: </h3>
                <p>{contactDetails.message}</p>
              </Col>
              <Col span={innerWidth < 567 ? 24 : 8}>
                <h3>Created On: </h3>
                <p>
                  {moment
                    .utc(contactDetails.created_on)
                    .utcOffset(dayjs().utcOffset())
                    .format('MM/DD/YYYY hh:mm A')}
                </p>
              </Col>
              <Col span={innerWidth < 567 ? 24 : 8}>
                <h3>Responded On: </h3>
                <p>
                  {contactDetails.updated_on
                    ? moment
                        .utc(contactDetails.updated_on)
                        .utcOffset(dayjs().utcOffset())
                        .format('MM/DD/YYYY hh:mm A')
                    : ''}
                </p>
              </Col>
              <Col span={innerWidth < 567 ? 24 : 8}>
                <h3>Responded by: </h3>
                <p>
                  {contactDetails.responded_by
                    ? contactDetails.responded_by
                    : ''}
                </p>
              </Col>
              {role === 'admin' ? (
                <Col span={24}>
                  <Button
                    onClick={markAsCompleted}
                    hidden={
                      contactDetails.updated_on && contactDetails.responder_id
                    }
                    type="primary"
                    icon={<CheckOutlined />}
                  >
                    Mark as completed
                  </Button>
                </Col>
              ) : null}
            </Row>
          </>
        ) : null}
      </Card>
    </>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role
});

const mapDispatchToProps = dispatch => ({
  updateContact: payload => dispatch(onAddContact(payload)),
  onHideModal: () => dispatch(hideModal())
});

View.propTypes = { role: string, updateContact: func, onHideModal: func };

export default connect(mapStateToProps, mapDispatchToProps)(View);
