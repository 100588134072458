import connection from '../utils/api';

export const getUserList = ({ page, limit, filters }) => {
  let queryString = '';
  const queryParams = [];

  if (page) queryParams.push(`page=${page}`);
  if (limit) queryParams.push(`limit=${limit}`);
  queryParams.push(`company_type=All`);
  if (filters?.length)
    filters.forEach(filter => {
      for (const [key, value] of Object.entries(filter)) {
        queryParams.push(`${key}=${value}`);
      }
    });
  if (queryParams.length) queryString = `?` + queryParams.join('&');
  return connection.get(`users${queryString}`);
};

export const getUsers = params => connection.get(`users?${params}`);

export const getAdditionalUsers = params =>
  connection.get(`get_additional_user?${params}`);

export const AddUser = payload => connection.post(`users`, payload);
