/* Roles: admin, admin-1, bechtel-user, 'small-business' */
/* Page: Small Businesses, Calendar, RFP-RFQ, Look Ahead */
export const accessRules = {
  admin: {
    dashboard: {
      menu: true,
      view: true,
      searchSidebar: false
    },
    search: {
      menu: true,
      view: true,
      searchSidebar: false
    },
    'small-business': {
      menu: true,
      view: true,
      searchSidebar: false,
      actions: {
        add: true,
        view: true,
        chat: false,
        edit: true,
        delete: true,
        import: true,
        export: true,
        'non-vetted': true,
        vetted: true,
        'print-view': true,
        notes: true,
        'send-email': true
      }
    },
    'rfp-rfq': {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: true,
        view: true,
        edit: true,
        delete: true,
        import: true,
        export: true
      }
    },
    'beta-comments': {
      menu: true,
      view: true,
      searchSidebar: false,
      actions: {
        add: false,
        view: true,
        edit: false,
        delete: true,
        import: false,
        export: false
      }
    },
    'my-business': { menu: false, view: false, searchSidebar: false },
    contact: {
      menu: true,
      view: true,
      searchSidebar: false,
      actions: {
        add: false,
        view: true,
        complete: true,
        delete: true,
        import: false,
        export: false
      }
    },
    'site-settings': {
      menu: true,
      view: true,
      searchSidebar: false,
      actions: {
        add: true,
        view: true,
        edit: true,
        delete: true
      }
    },
    users: {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: false,
        view: false,
        edit: false,
        delete: false
      }
    },
    'activity-log': {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: false,
        view: false,
        edit: false,
        delete: false
      }
    },
    'mass-email': {
      menu: true,
      view: true,
      searchSidebar: false,
      actions: {
        add: false,
        view: false,
        edit: false,
        delete: false
      }
    }
  },
  'admin-1': {
    dashboard: {
      menu: true,
      view: true,
      searchSidebar: false
    },
    search: {
      menu: true,
      view: true,
      searchSidebar: false
    },
    'small-business': {
      menu: true,
      view: true,
      searchSidebar: false,
      actions: {
        add: true,
        view: true,
        chat: false,
        edit: true,
        delete: false,
        import: true,
        export: true,
        'non-vetted': true,
        vetted: true,
        'print-view': true,
        notes: true,
        'send-email': true
      }
    },
    calendar: {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: false,
        view: true,
        edit: false,
        delete: false,
        import: false,
        export: false
      }
    },
    'rfp-rfq': {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: true,
        view: true,
        edit: true,
        delete: false,
        import: true,
        export: true
      }
    },
    'beta-comments': {
      menu: true,
      view: true,
      searchSidebar: false,
      actions: {
        add: false,
        view: true,
        edit: false,
        delete: false,
        import: false,
        export: false
      }
    },
    'my-business': { menu: false, view: false, searchSidebar: false },
    contact: {
      menu: true,
      view: true,
      searchSidebar: false,
      actions: {
        add: false,
        view: true,
        complete: true,
        delete: false,
        import: false,
        export: false
      }
    },
    'site-settings': {
      menu: true,
      view: true,
      searchSidebar: false,
      actions: {
        add: true,
        view: true,
        edit: true,
        delete: true
      }
    },
    'user-agreement': {
      menu: true,
      view: true,
      searchSidebar: false,
      actions: {
        add: true,
        view: true,
        edit: false,
        delete: false
      }
    },
    users: {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: false,
        view: false,
        edit: false,
        delete: false
      }
    },
    'activity-log': {
      menu: false,
      view: false,
      searchSidebar: false,
      actions: {
        add: false,
        view: false,
        edit: false,
        delete: false
      }
    },
    'mass-email': {
      menu: true,
      view: true,
      searchSidebar: false,
      actions: {
        add: false,
        view: false,
        edit: false,
        delete: false
      }
    }
  },
  'bechtel-user': {
    dashboard: {
      menu: true,
      view: true,
      searchSidebar: false
    },
    association: {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: true,
        view: true,
        chat: false,
        edit: false,
        delete: true,
        'print-view': true
      }
    },
    'small-business': {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: false,
        view: true,
        chat: false,
        edit: false,
        delete: false,
        import: false,
        export: false,
        'print-view': true,
        notes: true
      }
    },
    primes: {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: true,
        view: true,
        chat: false,
        edit: false,
        delete: true,
        import: true,
        export: true,
        'print-view': true
      }
    },
    calendar: {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: true,
        view: true,
        edit: false,
        delete: true,
        import: true,
        export: true
      }
    },
    'rfp-rfq': {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: true,
        view: true,
        edit: false,
        delete: true,
        import: true,
        export: true
      }
    },
    'look-ahead': {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: true,
        view: true,
        edit: false,
        delete: true,
        import: true,
        export: true
      }
    },
    'beta-comments': {
      menu: true,
      view: true,
      searchSidebar: false,
      actions: {
        add: false,
        view: true,
        edit: false,
        delete: true,
        import: false,
        export: false
      }
    },
    'my-business': { menu: false, view: false, searchSidebar: false },
    'message-center': {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: true,
        view: true,
        edit: false,
        delete: true
      }
    },
    contact: {
      menu: true,
      view: false,
      searchSidebar: false,
      actions: {
        add: true,
        view: true,
        complete: false,
        delete: false,
        import: false,
        export: false
      }
    },
    'user-agreement': {
      menu: true,
      view: true,
      searchSidebar: false,
      actions: {
        add: true,
        view: true,
        edit: false,
        delete: true
      }
    },
    users: {
      menu: false,
      view: true,
      searchSidebar: true,
      actions: {
        add: false,
        view: false,
        edit: false,
        delete: false
      }
    },
    'activity-log': {
      menu: false,
      view: true,
      searchSidebar: true,
      actions: {
        add: false,
        view: false,
        edit: false,
        delete: false
      }
    }
  },
  'hntb-user': {
    dashboard: {
      menu: true,
      view: true,
      searchSidebar: false
    },
    association: {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: true,
        view: true,
        chat: false,
        edit: false,
        delete: true,
        'print-view': true
      }
    },
    'small-business': {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: false,
        view: true,
        chat: false,
        edit: false,
        delete: false,
        import: false,
        export: false,
        'print-view': true,
        notes: true
      }
    },
    primes: {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: true,
        view: true,
        chat: false,
        edit: false,
        delete: true,
        import: true,
        export: true,
        'print-view': true
      }
    },
    calendar: {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: true,
        view: true,
        edit: false,
        delete: true,
        import: true,
        export: true
      }
    },
    'rfp-rfq': {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: true,
        view: true,
        edit: false,
        delete: true,
        import: true,
        export: true
      }
    },
    'look-ahead': {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: true,
        view: true,
        edit: false,
        delete: true,
        import: true,
        export: true
      }
    },
    'beta-comments': {
      menu: true,
      view: true,
      searchSidebar: false,
      actions: {
        add: false,
        view: true,
        edit: false,
        delete: true,
        import: false,
        export: false
      }
    },
    'my-business': { menu: false, view: false, searchSidebar: false },
    'message-center': {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: true,
        view: true,
        edit: false,
        delete: true
      }
    },
    contact: {
      menu: true,
      view: false,
      searchSidebar: false,
      actions: {
        add: true,
        view: true,
        complete: false,
        delete: false,
        import: false,
        export: false
      }
    },
    'user-agreement': {
      menu: true,
      view: true,
      searchSidebar: false,
      actions: {
        add: true,
        view: true,
        edit: false,
        delete: true
      }
    },
    users: {
      menu: false,
      view: true,
      searchSidebar: true,
      actions: {
        add: false,
        view: false,
        edit: false,
        delete: false
      }
    },
    'activity-log': {
      menu: false,
      view: true,
      searchSidebar: true,
      actions: {
        add: false,
        view: false,
        edit: false,
        delete: false
      }
    }
  },
  'small-business': {
    dashboard: {
      // Menu set to false for beta-testing
      menu: false,
      view: true,
      searchSidebar: false
    },
    association: {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: true,
        view: true,
        chat: false,
        edit: false,
        delete: true,
        'print-view': true
      }
    },
    'small-business': {
      menu: false,
      view: true,
      searchSidebar: true,
      actions: {
        add: false,
        view: true,
        chat: true,
        edit: false,
        delete: false,
        import: false,
        export: false,
        'print-view': true
      }
    },
    primes: {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: false,
        view: true,
        chat: false,
        edit: false,
        delete: false,
        import: false,
        export: false,
        'print-view': true
      }
    },
    calendar: {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: false,
        view: true,
        edit: false,
        delete: false,
        import: false,
        export: false
      }
    },
    'rfp-rfq': {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: false,
        view: true,
        edit: false,
        delete: false,
        import: false,
        export: false
      }
    },
    'look-ahead': {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: false,
        view: true,
        edit: false,
        delete: false,
        import: false,
        export: false
      }
    },
    'beta-comments': {
      menu: true,
      view: false,
      searchSidebar: false,
      actions: {
        add: true,
        view: false,
        edit: false,
        delete: false,
        import: false,
        export: false
      }
    },
    'my-business': { menu: false, view: true, searchSidebar: false },
    'message-center': {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: true,
        view: true,
        edit: false,
        delete: false
      }
    },
    contact: {
      menu: true,
      view: false,
      searchSidebar: false,
      actions: {
        add: true,
        view: true,
        complete: false,
        delete: false,
        import: false,
        export: false
      }
    },
    'user-agreement': {
      menu: false,
      view: false,
      searchSidebar: false,
      actions: {
        add: false,
        view: false,
        edit: false,
        delete: false
      }
    },
    videos: {
      menu: true,
      view: true,
      searchSidebar: false,
      free_user: true,
      actions: {
        add: true,
        view: true,
        edit: true,
        delete: true
      }
    },
    users: {
      menu: false
    },
    'activity-log': {
      menu: false
    }
  },
  null: {
    dashboard: {
      // Menu set to false for beta-testing
      menu: false,
      view: false,
      searchSidebar: false
    },
    association: {
      menu: true,
      view: true,
      searchSidebar: true,
      actions: {
        add: true,
        view: true,
        chat: false,
        edit: true,
        delete: true,
        'print-view': true
      }
    },
    'small-business': {
      menu: true,
      view: true,
      searchSidebar: false,
      actions: {
        add: false,
        view: false,
        edit: false,
        delete: false,
        import: false,
        export: false
      }
    },
    primes: {
      menu: true,
      view: true,
      searchSidebar: false,
      actions: {
        add: false,
        view: false,
        edit: false,
        delete: false,
        import: false,
        export: false
      }
    },
    calendar: {
      menu: true,
      view: true,
      searchSidebar: false,
      actions: {
        add: false,
        view: false,
        edit: false,
        delete: false,
        import: false,
        export: false
      }
    },
    'rfp-rfq': {
      menu: true,
      view: true,
      searchSidebar: false,
      actions: {
        add: false,
        view: false,
        edit: false,
        delete: false,
        import: false,
        export: false
      }
    },
    'look-ahead': {
      menu: true,
      view: true,
      searchSidebar: false,
      actions: {
        add: false,
        view: false,
        edit: false,
        delete: false,
        import: false,
        export: false
      }
    },
    'beta-comments': {
      menu: true,
      view: false,
      searchSidebar: false,
      actions: {
        add: false,
        view: false,
        edit: false,
        delete: false,
        import: false,
        export: false
      }
    },
    'message-center': {
      menu: true,
      view: true,
      searchSidebar: false,
      actions: {
        add: false,
        view: true,
        edit: false,
        delete: true
      }
    },
    contact: {
      menu: true,
      view: false,
      searchSidebar: false,
      actions: {
        add: false,
        view: true,
        complete: false
      }
    },
    'user-agreement': {
      menu: false,
      view: false,
      searchSidebar: false,
      actions: {
        add: false,
        view: false,
        edit: false,
        delete: false
      }
    },
    users: {
      menu: false
    },
    'activity-log': {
      menu: false
    }
  }
};
