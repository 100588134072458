import connection from '../utils/api';

export const getStats = query => {
  let queryString = '';
  // if (!query) {
  //   queryString = '';
  // }
  // let queryParams = [];
  // if (query.page) {
  //   queryParams.push(`page=${query.page}`);
  // }
  // if (query.limit) {
  //   queryParams.push(`limit=${query.limit}`);
  // }
  // queryString = `?` + queryParams.join('&');
  return connection.get(`stats?${queryString}`);
};
