import React, { useState } from 'react';
import { Button, Input, Select } from 'antd';
import { connect } from 'react-redux';
import { func } from 'prop-types';
import { objectToFormData } from '../../utils/utils';
import {
  onUpdateUserCredentials,
  findUser
} from '../../redux/actions/smallBusiness';
import { useEffect } from 'react';

const CredentialsManager = ({ updateUserCredentials, getUser, user }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailVerified, setEmailVerified] = useState('');

  const submitUpdateCredentials = () => {
    updateUserCredentials(
      objectToFormData({
        user_id: user.id,
        email_verified: emailVerified,
        ...(!!password && { password: password })
      })
    );
    setEmail('');
    setPassword('');
    setEmailVerified('');
  };

  useEffect(() => {
    if (user) {
      setEmailVerified(user.email_verified);
    }
  }, [user]);

  return (
    <div className="credential__manager">
      <div>
        <Input
          style={{ marginTop: 10, width: 300 }}
          name="email"
          placeholder="email"
          autoComplete="new-password"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <Button style={{ marginTop: 20 }} onClick={() => getUser(email)}>
          Find user
        </Button>
      </div>
      {!!user && (
        <div className="cm__row">
          <div className="cm__col">
            <label htmlFor="">Password: </label>
            <Input.Password
              className="w-25"
              style={{ marginTop: 10, width: 300 }}
              name="password"
              placeholder="Password"
              onChange={e => setPassword(e.target.value)}
            />
          </div>

          <div className="cm__col">
            <label htmlFor="">Email Verification: </label>
            <Select
              style={{ marginTop: 10, width: 300 }}
              options={[
                { value: true, label: 'Verified' },
                { value: false, label: 'Not Verified' }
              ]}
              value={emailVerified}
              onChange={setEmailVerified}
            />
          </div>

          <div className="cm__col">
            <Button onClick={() => submitUpdateCredentials()}>
              Update user
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.smallBusiness.user
});

const mapDispatchToProps = dispatch => ({
  updateUserCredentials: payload => dispatch(onUpdateUserCredentials(payload)),
  getUser: payload => dispatch(findUser(payload))
});

CredentialsManager.propTypes = {
  updateUserCredentials: func
};

export default connect(mapStateToProps, mapDispatchToProps)(CredentialsManager);
