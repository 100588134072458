import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { string } from 'prop-types';

import { getMenuItems } from '../../configs/menu/menuBarItems';
import NavMenu from './NavMenu';

const MenuBar = ({ role }) => {
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    if (role) {
      const menuItems = getMenuItems(role);
      setMenu(menuItems);
    }
  }, [role]);

  return <NavMenu items={menu ? menu : []} />;
};

const mapStateToProps = state => ({
  role: state.auth.role
});

MenuBar.propTypes = {
  role: string
};

export default connect(mapStateToProps)(MenuBar);
