import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bool, func, number, string } from 'prop-types';
import { Button, Card, Col, Form, Input, Row, Typography, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { addField, updateField } from '../../api/primesFields';
import { onUpdatePrime } from '../../redux/actions/primes';
import { debounce, objectToFormData } from '../../utils/utils';
import Address from '../../components/common/Form/FormInputs/Address';
import Dropdowns from '../../components/common/Form/FormInputs/Dropdowns';

const { Title } = Typography;

const PrimesPartial = ({ updatePrime, details, businessId }) => {
  useEffect(() => {
    if (details) {
      getFormValuesFromDetails(details);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [form] = Form.useForm();

  const [formValues, setFormValues] = useState(null);

  const getFormValuesFromDetails = details => {
    const newFormValues = { ...formValues };
    const {
      address,
      company_name,
      company_specialization: companySpecialization,
      phone,
      website_info: websiteInfo
    } = details;

    if (websiteInfo) {
      newFormValues['website_info'] = websiteInfo;
    }
    if (address?.length) {
      newFormValues['address'] = address.map(item => ({
        id: item['id'],
        street: item['street'],
        state: item['state'],
        county: item['county'],
        city: item['city'],
        zipcode: item['zip_code']
      }));
    }

    if (companySpecialization?.length) {
      newFormValues['company_specialization'] = [...companySpecialization];
    }

    setFormValues({
      ...newFormValues,
      company_name,
      phone
    });
  };

  const inputChangedHandler = (key, value) => {
    setFormValues({ ...formValues, [key]: value });
  };

  const debouncedInput = debounce((e, key) => {
    inputChangedHandler(key, e.target.value);
  }, 500);

  const microApiResponseHandler = (key, value, index) => {
    let arr = formValues[key] ? [...formValues[key]] : [];
    arr[index] = value;
    setFormValues({ ...formValues, [key]: arr });
  };

  const submit = () => {
    let payload = {};
    for (let key in formValues) {
      if (formValues[key]) {
        if (Array.isArray(formValues[key]) && !formValues[key]?.length)
          continue;

        if (['address'].includes(key)) {
          payload[key] = JSON.stringify(
            formValues[key].map(item => item.id || item)
          );
        } else if (['company_specialization'].includes(key)) {
          payload[key] = JSON.stringify(formValues[key]);
        } else {
          payload[key] = formValues[key];
        }
      }
    }
    updatePrime(businessId, objectToFormData(payload));
  };

  const submitFail = errors => {
    console.log(errors);
  };

  return (
    <Card>
      {formValues && formValues.company_name ? (
        <Row align="middle" justify="center">
          <Col span={24}>
            <Title level={2} className="heading-title">
              Corporation Profile
            </Title>
            <Form
              form={form}
              name="edit-primes"
              layout="horizontal"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              labelAlign="left"
              onFinish={submit}
              onFinishFailed={submitFail}
              initialValues={{ ...formValues }}
            >
              {/* Business Name */}
              <Form.Item
                key="company_name"
                name="company_name"
                label="Business Name"
                style={{
                  border: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
                hasFeedback
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Company name is required.'
                  }
                ]}
              >
                <Input
                  name="company_name"
                  placeholder="Business Name"
                  type="text"
                  bordered={false}
                  style={{
                    borderLeft: '1px solid gray',
                    backgroundColor: 'white'
                  }}
                  onChange={e => {
                    e.persist();
                    debouncedInput(e, 'company_name');
                  }}
                />
              </Form.Item>

              {/* Business Phone */}
              <Form.Item
                key="phone"
                name="phone"
                label="Business Phone"
                required={true}
                style={{
                  borderBottom: '1px solid gray',
                  borderLeft: '1px solid gray',
                  borderRight: '1px solid gray',
                  marginBottom: '0px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
                hasFeedback
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    validator: async (_, phone) => {
                      if (!phone) {
                        return Promise.reject(
                          new Error('Phone Number is required.')
                        );
                      }
                    }
                  },
                ]}
              >
                <Input
                  name="phone"
                  placeholder="Business Phone (10-digit)"
                  type="tel"
                  maxLength={10}
                  // pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                  onChange={e => {
                    e.persist();
                    debouncedInput(e, 'phone');
                  }}
                />
              </Form.Item>

              {/* Website */}
              <Form.Item
                key="website_info"
                name="website_info"
                label="Website"
                style={{
                  borderBottom: '1px solid gray',
                  borderLeft: '1px solid gray',
                  borderRight: '1px solid gray',
                  marginBottom: '1px',
                  paddingLeft: '7px',
                  backgroundColor: 'lightgray'
                }}
                hasFeedback
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Company Website is required.'
                  },
                  {
                    type: "url",
                    message: "This field must be a valid url."
                  }
                ]}
              >
                <Input
                  name="website_info"
                  placeholder="Company Website"
                  // type="url"
                  style={{
                    borderLeft: '1px solid gray',
                    backgroundColor: 'white'
                  }}
                  onChange={e => {
                    e.persist();
                    debouncedInput(e, 'website_info');
                  }}
                />
              </Form.Item>

              {/* Business Addresses */}
              <Form.List
                name="address"
                rules={[
                  {
                    validator: async (_, address) => {
                      if (!address || address.length < 1) {
                        return Promise.reject(
                          new Error('At least 1 address is required.')
                        );
                      }
                    }
                  }
                ]}
              >
                {(fields, { add }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        wrapperCol={{ offset: index === 0 ? 0 : 4, span: 20 }}
                        required={true}
                        key={field.key}
                        style={{
                          border: '1px solid gray',
                          marginBottom: '0px',
                          paddingLeft: '7px',
                          backgroundColor: 'lightgray'
                        }}
                        label={index === 0 ? 'Business Address' : ''}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          name={[field.name, 'address']}
                          style={{ marginLeft: 7 }}
                          rules={[
                            {
                              validator: async () => {
                                if (!formValues['address'][index]) {
                                  return Promise.reject(
                                    new Error(
                                      'Please input details and Save or delete this field.'
                                    )
                                  );
                                }
                              }
                            }
                          ]}
                          noStyle
                        >
                          <Address
                            keyName="address"
                            microApi={{ add: addField, update: updateField }}
                            parentHandler={microApiResponseHandler}
                            hasCity={true}
                            hasZip={true}
                            index={index}
                            valueProps={formValues['address']?.[index]}
                          />
                        </Form.Item>

                        <Button
                          type="warning"
                          style={{
                            float: 'right',
                            marginRight: '3px',
                            marginTop: '0px',
                            backgroundColor: '#D26A31',
                            color: 'white'
                          }}
                          onClick={() => {
                            formValues['address'].splice(index, 1);
                            return form.setFieldsValue({
                              address: formValues?.['address']
                            });
                          }}
                        >
                          Remove
                        </Button>
                      </Form.Item>
                    ))}
                    <Form.Item
                      style={{
                        border: '1px dashed gray',
                        marginBottom: '0px',
                        paddingLeft: '7px',
                      }}
                      >
                      {fields.length < 5 ? (
                        <Button
                          type="primary"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                          style={{
                            float: 'left'
                          }}
                        >
                          Add Address
                        </Button>
                      ) : null}

                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>

              {/* Company Specializations */}
              <Form.Item
                wrapperCol={{ span: 24 }}
                validateTrigger={['onChange', 'onBlur']}
                name="company_specialization"
                rules={[
                  {
                    validator: async () => {
                      if (!formValues['company_specialization'] || formValues['company_specialization'].length < 1) {
                        return Promise.reject(
                          new Error('At least 1 specialization is required.')
                        );
                      }
                    }
                  }
                ]}
              >
                <>
                  <div
                    className="ant-row ant-form-item"
                    style={{
                      border: '1px solid gray',
                      marginBottom: '0px',
                      padding: '7px',
                      backgroundColor: 'lightgray',
                      color: 'rgba(0, 0, 0, 0.85)',
                      fontSize: '14px'
                    }}
                  >
                    Company Specialization: (Select all that applies)
                  </div>
                  <Dropdowns
                    keyName="specializations"
                    label="specializations"
                    multiSelect={true}
                    valueProps={formValues['company_specialization']}
                    onChange={(value) => {
                      inputChangedHandler('company_specialization', [
                        ...value
                      ]);
                    }}
                  />
                </>
              </Form.Item>

              {/* Action Buttons */}
              <Form.Item
                wrapperCol={{ offset: 6, span: 12 }}
                style={{ textAlign: 'center', marginTop: 10 }}
              >
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      ) : (
        <Spin />
      )}
    </Card>
  );
};

const mapStateToProps = state => ({
  role: state.auth.role,
  businessId: state.auth?.user?.businessId
});

const mapDispatchToProps = dispatch => ({
  updatePrime: (primeId, payload) => dispatch(onUpdatePrime(primeId, payload)),
});

PrimesPartial.propTypes = {
  role: string,
  selfMode: bool,
  businessId: number,
  updatePrime: func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PrimesPartial));
