import React, { useState } from 'react';
import { connect } from 'react-redux';
import { func } from 'prop-types';
import { Button, Row, Space } from 'antd';

import FileUpload from '../../components/common/Form/FormInputs/FileUpload';
import { onImportSmallBusinesses } from '../../redux/actions/smallBusiness';

const Import = ({ onImport }) => {
  const [upload, setUpload] = useState(null);

  const fileSelectorHandler = (key, value) => {
    setUpload(value['small_business_file']);
  };

  const uploadFile = () => {
    const formData = new FormData();
    formData.append('small_business_file', upload);
    onImport(formData);
  };

  return (
    <>
      <Row justify="center">
        <h2>Import Diverse Businesses</h2>
      </Row>
      <Row justify="center">
        <Space direction="vertical" align="center">
          <FileUpload
            keyName="small_business_file"
            label="Select File"
            parentHandler={fileSelectorHandler}
          />
          <Button type="primary" onClick={uploadFile} disabled={!upload}>
            Import
          </Button>
        </Space>
      </Row>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  onImport: payload => dispatch(onImportSmallBusinesses(payload))
});

Import.propTypes = { onImport: func };

export default connect(null, mapDispatchToProps)(Import);
