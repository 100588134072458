import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, company_admin, isValidPayment, ...rest }) => {
  return (
    // (isValidPayment && company_admin) ? 
    <Route {...rest} component={Component} />
    // :
    // <Redirect to="/payments" />
  );
};

const mapStateToProps = (state) => {
  return ({
    isValidPayment: state.auth.isValidPayment,
    businessId: state.auth.user?.businessId,
    company_admin: state.auth.company_admin
  })
}

ProtectedRoute.propTypes = {
  component: PropTypes.node.isRequired,
  isValidPayment: PropTypes.bool.isRequired,
  company_admin: PropTypes.bool
}
export default connect(mapStateToProps)(ProtectedRoute);
