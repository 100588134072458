export const associationSearchFields = [
  { company_name: 'Association Name' },
  { website: 'Website' }
];

export const smallBusinessSearchFields = [
  { company_name: 'Company Name' },
  { county: 'County' },
  { industry_specialization: 'Industry Specialization' },
  { naics_codes: 'NAICS Codes' },
  { unspsc_codes: 'UNSPSC Codes' },
  { state: 'State' },
  { city: 'City' }
];

export const primeSearchFields = [
  { company_name: 'Company Name' },
  { county: 'County' },
  { company_specialization: 'Company Specializations' },
  { state: 'State' }
];

export const calendarSearchFields = [
  { agency: 'Agency' },
  { location: 'Location' },
  { title: 'Title' },
  { type_event: 'Type' }
];

export const MessageCenterSearchFields = [
  { title: 'Title' },
  { sender_name: 'Sender' }
];

export const rfpRfqSearchFields = [
  { agency: 'Agency' },
  { county: 'County' },
  { number: 'Number' },
  { scope: 'Scope Description' },
  { type_rfp_rfq: 'Type' }
];

export const lookAheadSearchFields = [
  { agency: 'Agency' },
  { county: 'County' },
  { number: 'Number' },
  { scope: 'Scope Description' },
  { state: 'State' },
  { type_of_work: 'Type' }
];

export const userSearchFields = [
  { firstname: 'First Name' },
  { lastname: 'Last Name' },
  { email: 'Email' },
  { company_name: 'Company Name' }
];
