import { accessRules } from '../../../utils/accessRules';


export const menuItems = [
  {
    key: 1,
    id: 'dashboard',
    label: 'Dashboard',
    path: '/dashboard',
    codename: 'custom_dashboard_perm',
    isPaid: false
  },
  {
    key: 2,
    id: 'dashboard',
    label: 'Diverse Business',
    path: '',
    codename: 'custom_dashboard_perm',
    isPaid: false,
    children: [
      {
        key: 2.2,
        id: 'small-business',
        label: 'Diverse Business',
        path: '/small-business',
        codename: 'small_business_perm',
        isPaid: false
      }
    ]
  },

  {
    key: 4,
    id: 'rfp-rfq',
    label: 'Administration',
    path: '',
    codename: 'rfp_perm',
    isPaid: false,
    children: [
      {
        key: 4.1,
        id: 'users',
        label: 'Users',
        path: '/users',
        codename: 'users_table_perm',
        isPaid: false
      },
      {
        key: 4.2,
        id: 'contact',
        label: 'Contact Us',
        path: '/contact',
        codename: 'technical_support_perm',
        isPaid: false
      },
      {
        key: 4.3,
        id: 'activity-log',
        label: 'Activity Log',
        path: '/activity-log',
        codename: 'activity_log_perm',
        isPaid: false
      },
      {
        key: 4.4,
        id: 'mass-email',
        label: 'Mass Email',
        path: '/mass-email',
        codename: 'mass_email_perm',
        isPaid: false
      }
    ]
  } ,
  {
    key: 5,
    id: 'search',
    label: 'Search',
    path: '/search',
    codename: 'custom_search_perm',
    isPaid: false
  }
];

export const getMenuItems = role => {
  const menu = [];

  menuItems
    .sort((a, b) => (a.key > b.key ? 1 : -1))
    .forEach(item => {
      if (accessRules[role][item['id']]?.['menu']) {
        item.children
          ? menu.push({
              ...item,
              children: item.children.filter(
                i => accessRules[role][i['id']]?.['menu']
              )
            })
          : menu.push(item);
      }
    });
  return menu;
};



