import { SET_ACTIVITY_LOGS, ADD_ACTIVITY_LOG } from '../actionTypes';

const initialState = {
  activityLogs: []
};

export const activityLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVITY_LOGS:
      return { ...state, activityLogs: action.payload };
    case ADD_ACTIVITY_LOG:
      return {
        ...state,
        activityLogs: [...state.activityLogs, ...action.payload]
      };
    default:
      return { ...state };
  }
};
