import { createNote, updateNote, deleteNote } from '../../api/notes';
import { handleNotifications } from '../../utils/notifications';
import { hideLoading, showLoading } from './ui';
import { SET_NOTES, ADD_NOTE, FILTER_NOTES, UPDATE_NOTE } from '../actionTypes';

export const setNotes = payload => dispatch => {
  dispatch({ type: SET_NOTES, payload });
};

const addNote = payload => dispatch => {
  dispatch({ type: ADD_NOTE, payload });
};

const updateNoteAction = payload => dispatch => {
  dispatch({ type: UPDATE_NOTE, payload });
};

export const onCreateNote = payload => dispatch => {
  dispatch(showLoading);
  createNote(payload)
    .then(({ data }) => {
      dispatch(hideLoading);
      if (data.success) {
        dispatch(addNote(data.data));
        // dispatch(hideModal());
        // dispatch(redirect('/site-settings'));
        handleNotifications('success', 'Success', data.message);
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading);
      handleNotifications('error', 'Failed', error.message);
    });
};

export const onUpdateNote = (noteId, payload) => dispatch => {
  dispatch(showLoading);
  updateNote(noteId, payload)
    .then(({ data }) => {
      dispatch(hideLoading);
      if (data.success) {
        dispatch(updateNoteAction(data.data));
        // dispatch(hideModal());
        // dispatch(redirect('/site-settings'));
        handleNotifications('success', 'Success', data.message);
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading);
      handleNotifications('error', 'Failed', error.message);
    });
};

export const onDeleteNote = noteId => dispatch => {
  dispatch(showLoading);
  deleteNote(noteId)
    .then(({ data }) => {
      dispatch(hideLoading);
      if (data.success) {
        dispatch({ type: FILTER_NOTES, noteId });
        handleNotifications('success', 'Success', data.message);
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading);
      handleNotifications('error', 'Failed', error.message);
    });
};
