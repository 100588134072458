import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Input, Button, Select, Row, Col } from 'antd';
import InputMask from 'react-input-mask';
import { emailPattern, websitePattern } from '../../utils/constants';
import { onRegister } from '../../redux/actions/auth';
import { handleNotifications } from '../../utils/notifications';
import { ArrowRightOutlined } from '@ant-design/icons';

const { Option } = Select;

const RegisterForm = props => {
  if (props.path) {
    setTimeout(() => {
      return <Redirect to={props.path} />;
    }, 1000);
  }

  const onFinish = values => {
    props.onRegister({ ...values, email: values.email.toLowerCase() });
  };

  const onFail = () => {
    handleNotifications(
      'error',
      'Invalid form',
      'Some fields may be missing or incorrect.'
    );
  };

  return (
    <Form
      layout="vertical"
      name="register-form"
      onFinish={onFinish}
      onFinishFailed={onFail}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <Row gutter={[8]}>
        <Col md={12} sm={24}>
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true, message: 'First Name is required' }]}
            hasFeedback
          >
            <Input placeholder="First Name" />
          </Form.Item>
        </Col>
        <Col md={12} sm={24}>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true, message: 'Last Name is required' }]}
            hasFeedback
          >
            <Input placeholder="Last Name" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: 'Email is required.' },
          {
            pattern: emailPattern,
            message: 'Please enter a valid email.'
          }
        ]}
        hasFeedback
      >
        <Input placeholder="Email" type="email" />
      </Form.Item>

      <Form.Item
        name="companyName"
        label="Business Name"
        rules={[{ required: true, message: 'Business Name is required.' }]}
        hasFeedback
      >
        <Input placeholder="Business Name" type="text" />
      </Form.Item>

      {/* <Form.Item
        key="phone"
        name="phone"
        label="Business Phone"
        hasFeedback
        validateTrigger={['onChange', 'onBlur']}
        rules={[
          {
            required: true,
            validator: async (_, phone) => {
              if (!phone) {
                return Promise.reject(new Error('Phone Number is required.'));
              }
            }
          }
        ]}
      >
        <Input
          name="phone"
          placeholder="123-456-7890"
          type="tel"
          maxLength={12} // Adjusted maxLength to accommodate dashes
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" // Added pattern for validation
          bordered={false}
          style={{ borderLeft: '1px solid gray', backgroundColor: 'white' }}
        />
      </Form.Item> */}
      <Form.Item
        key="phone"
        name="phone"
        label="Business Phone"
        hasFeedback
        validateTrigger={['onChange', 'onBlur']}
        rules={[
          {
            required: true,
            validator: async (_, phone) => {
              if (!phone) {
                return Promise.reject(new Error('Phone Number is required.'));
              }
            }
          }
        ]}
      >
        <InputMask mask="999-999-9999" maskChar="_" alwaysShowMask={false}>
          {inputProps => (
            <Input
              name="phone"
              placeholder="123-456-7890"
              type="tel"
              {...inputProps}
              bordered={false}
              style={{
                borderLeft: '1px solid gray',
                backgroundColor: 'white'
              }}
            />
          )}
        </InputMask>
      </Form.Item>

      <Form.Item
        name="website"
        label="Website"
        rules={[
          { required: true, message: 'Website is required.' },
          {
            pattern: websitePattern,
            message:
              'Please enter a valid website. (e.g., https://www.example.com)'
          }
        ]}
        hasFeedback
      >
        <Input placeholder="https://www.example.com" type="url" />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          { required: true, message: 'Password is required' },
          { min: 6, message: 'Password too short' }
        ]}
        hasFeedback
      >
        <Input type="password" placeholder="Password" />
      </Form.Item>
      <Form.Item
        name="accountType"
        label="Account Type"
        rules={[{ required: true, message: 'Account Type is required.' }]}
      >
        <Select placeholder="Select account type">
          <Option key="small-business" value="small-business">
            Diverse Business
          </Option>
        </Select>
      </Form.Item>

      <div id="submit-container">
        <Form.Item>
          <Link to="/">Already registered? Login</Link>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            <ArrowRightOutlined />
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

const mapStateToProps = state => ({
  path: state.redirect.path
});

const mapDispatchToProps = dispatch => ({
  onRegister: payload => dispatch(onRegister(payload))
});

RegisterForm.propTypes = {
  onRegister: PropTypes.func,
  path: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
