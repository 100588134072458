import React from 'react';
import EmailForm from './EmailForm';

const MassEmailView = () => {
  return (
    <section className="mass__email__view">
      <EmailForm />
    </section>
  );
};

export default MassEmailView;
