import {
  SMALL_BUSINESS_REFRESH,
  DELETE_SMALL_BUSINESS_SUCCESS,
  IMPORT_SMALL_BUSINESS_SUCCESS,
  SET_USER_INFO
} from '../actionTypes';

const initialState = {
  refresh: false,
  deleteSuccess: false,
  importSuccess: false,
  user: null
};

export const smallBusinessReducer = (state = initialState, action) => {
  switch (action.type) {
    case SMALL_BUSINESS_REFRESH:
      return { ...state, refresh: action.payload };
    case DELETE_SMALL_BUSINESS_SUCCESS:
      return { ...state, deleteSuccess: true };
    case IMPORT_SMALL_BUSINESS_SUCCESS:
      return { ...state, importSuccess: true };
    case SET_USER_INFO:
      return { ...state, user: action.payload };
    default:
      return { ...state };
  }
};
