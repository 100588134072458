import { 
    SET_GLOBAL_VAR,
    GET_GLOBAL_VARS
   } from '../actionTypes';
  
  const initialState = {
    globalVars: {},
  };
  
  export const globalVarsReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_GLOBAL_VARS:
        return { ...state, globalVars: action.payload };
      case SET_GLOBAL_VAR:
        return { ...state, globalVars: {...state.globalVars, logo: action.payload.logo} };
      default:
        return { ...state };
    }
  };