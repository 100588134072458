import axios from 'axios';
// import urls from '../urls.json';
import { errorHandler } from './errorHandler';

const connection = axios.create({
  baseURL: process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : 'https://api.stg.diversebusinessbechtel.com/'
});

connection.interceptors.response.use(
  response => response,
  error => {
    errorHandler(error);
  }
);

export default connection;
