import connection from '../utils/api';

export const getNotes = (entity_id, entity_type, page) => {
  let queryString = '';
  const queryParams = [];

  if (page) queryParams.push(`page=${page}`);
  queryParams.push('limit=10');
  if (entity_id && entity_type)
    queryParams.push(`entity_id=${entity_id}&entity_type=${entity_type}`);
  if (queryParams.length) queryString = `?` + queryParams.join('&');

  return connection.get(`note${queryString}`);
};

export const getOneNote = id => connection.get(`note/${id}`);
export const createNote = payload => connection.post(`note`, payload);
export const updateNote = (id, payload) =>
  connection.put(`note/${id}`, payload);
export const deleteNote = id => connection.delete(`note/${id}`);
