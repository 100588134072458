import { setItem } from '../../api/globalVars';
import { handleNotifications } from '../../utils/notifications';
import { hideLoading, hideModal, showLoading } from './ui';
import { redirect } from './redirect';
import { SET_GLOBAL_VAR, GET_GLOBAL_VARS } from '../actionTypes';

const setGlobalVar = payload => dispatch => {
  dispatch({ type: SET_GLOBAL_VAR, payload });
};

export const getGlobalVars = payload => dispatch => {
  dispatch({ type: GET_GLOBAL_VARS, payload });
};

export const onSetGlobalVar = globalVarPayload => dispatch => {
  dispatch(showLoading);
  setItem('logo', globalVarPayload)
    .then(({ data }) => {
      dispatch(hideLoading);
      if (data.success) {
        dispatch(setGlobalVar(data.data));
        // dispatch(hideModal());
        // dispatch(redirect('/site-settings'));
        handleNotifications('success', 'Success', data.message);
      } else {
        handleNotifications('error', 'Failed', data.message);
      }
    })
    .catch(error => {
      dispatch(hideLoading);
      handleNotifications('error', 'Failed', error.message);
      console.log(error);
    });
};
