import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/index';

const initialState = {};
const composeEnhancers =
  process.env.NODE_ENV !== 'production' && typeof window === 'object'
    ? compose(
        applyMiddleware(thunk),
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    : compose(applyMiddleware(thunk));

const store = createStore(rootReducer, initialState, composeEnhancers);

export default store;
