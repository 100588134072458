import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Image } from 'antd';

import LoginForm from '../../components/auth/LoginForm';
import SupportLoginForm from '../../components/auth/SupportLoginForm';
import { onLoginFromLocal } from '../../redux/actions/auth';
import brandLogo from '../../assets/images/brand-logo.png';
import bg from '../../assets/images/background.png';

const Login = ({ loginFromLocal, loggedIn }) => {
  const history = useHistory();

  useEffect(() => {
    if (loggedIn || loginFromLocal()) {
      history.push('/dashboard');
    }
  });

  return (
    <div className="login-container" style={{ backgroundImage: `url(${bg})` }}>
      <div className="form-container">
        <Image src={brandLogo} alt="BZConnects Logo" preview={false} />
        {history.location.pathname === '/support-login' ? (
          <SupportLoginForm />
        ) : (
          <LoginForm />
        )}
      </div>
      <Link to="/register">Create New Account</Link>
    </div>
  );
};

Login.propTypes = {
  loggedIn: PropTypes.bool,
  history: PropTypes.object,
  loginFromLocal: PropTypes.func
};

const mapStateToProps = state => ({
  loggedIn: state.auth.loggedIn
});

const mapDispatchToProps = dispatch => ({
  loginFromLocal: user => dispatch(onLoginFromLocal(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
